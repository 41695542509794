<template>
	<div class="apply">
		<div class="form" style="">
			<van-row> 
				<van-col span="5">
					<div class="left">
						真实姓名
					</div>
				</van-col>
				<van-col span="19">
					<input type="text" placeholder="请输入姓名" v-model="activity.name">
				</van-col>
			</van-row>
			<van-row class="row" stylem="margin:10px 0">
				<van-col span="5">
					<div class="left">
						性别
					</div>
				</van-col>
				<van-col span="19">
					<van-radio-group v-model="activity.sex" style="display: flex;height: 36px;">
						<van-radio name="1" style="margin-right: 30px;">
							男
							<img slot="icon" slot-scope="props" v-bind:src="props.checked ? activeIcon : inactiveIcon" style="width:20px;height:20px;vertical-align : top;">
						</van-radio>
						<van-radio name="2">
							女
							<img slot="icon" slot-scope="props" :src="props.checked ? activeIcon1 : inactiveIcon1" style="width:20px;height:20px;vertical-align : top;">
						</van-radio>
					</van-radio-group>
				</van-col>
			</van-row>
			<van-row class="row">
				<van-col span="5">
					<div class="left">
						年龄
					</div>
				</van-col>
				<van-col span="19" @click="showPicker= true;">
					<!-- <input type="text" placeholder="请选择" v-model="" disabled="false"> -->
					<div class="flex" style="justify-content: space-between;background-color: #F4F4F4;height: 36px;line-height: 36px;padding:0 10px;border-radius: 6px;">
						<div v-text="activity.age?activity.age:'请选择'" :class="activity.age?'':'hui'">
		
						</div>
						<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" style="width: 16px;height: 10px;">
					</div>
				</van-col>
			</van-row>
			<van-row class="row">
				<van-col span="5">
					<div class="left">
						手机号码
					</div>
				</van-col>
				<van-col span="19">
					 <!-- <van-field
					    v-model="activity.phone"
					    placeholder="请输入常用手机号"
					    error-message="手机号格式错误"
					  /> -->
					<input type="text" placeholder="请输入常用手机号" v-model="activity.phone" class="after">
				</van-col>
			</van-row>
			<van-row class="row">
				<van-col span="5">
					<div class="left" style="line-height: inherit;">
						收件地址
					</div>
				</van-col>
				<van-col span="19">
					<div class="flex">
		
						<div class="btn1" @click="provinceClick">
							<span v-text="train_add.province.name"></span>
							<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" v-if="isAreaList!=='城市'" style="height: 20px;width: 20px;">
							<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" v-if="isAreaList=='城市'" style="height: 20px;width: 20px;">
						</div>
						<div class="btn1" @click="provinceClick">
							<span v-text="train_add.city.name"></span>
							<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" v-if="isAreaList!=='城市'" style="height: 20px;width: 20px;">
							<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" v-if="isAreaList=='城市'" style="height: 20px;width: 20px;">
						</div>
						<div class="btn1" @click="provinceClick">
							<span v-text="train_add.area.name"></span>
							<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" v-if="isAreaList!=='城市'" style="height: 20px;width: 20px;">
							<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" v-if="isAreaList=='城市'" style="height: 20px;width: 20px;">
						</div>
					</div>
					
				</van-col>
			</van-row>
			<div class="row" style="font-size: 14px;">
				认证证书上传
			</div>
			<div>
				<img src="../../assets/Train/icon_tianjia_200@2x.png" alt="" width="100">
			</div>
			
			
		
		</div>
		<div class="bottom" style="box-shadow: 0 0 6px -1px #ddd;">
		
			<div class="btn" @click="formClick" style="padding: 0;" :style="!activity.sex?'background:#C6C8CA;':!activity.name?'background:#C6C8CA;':!activity.age?'background:#C6C8CA;':!activity.phone?'background:#C6C8CA;':!activity.city?'background:#C6C8CA;':''">
				提交
			</div>
		</div>
		<van-popup v-model="showPicker" position="bottom">
			<van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
		</van-popup>
		<van-popup v-model="isAreaList" position="bottom">
			<div style="width: 100%;">
				<van-area :area-list="areaList" :columns-num="3" title="地址" @confirm="confirm" @cancel="cancel" />
			</div>
		</van-popup>
	</div>
</template>

<script>
// import { } from '@/api';
import { Toast } from 'vant'
	import areaList from '../../area.js'

	export default {
  name: 'setPasswrod',
  components: {
  },
  data(){
	  return{
		  
		  columns: [],
		  // 年龄
		  showPicker:false,
		  // 详细地址
		  acct: '',
		  areaList: areaList,
		  show: false,
		  radio: '1',
		  activeIcon: require('../../assets/Train/icon_nan_pre@2x.png'),
		  inactiveIcon: require('../../assets/Train/icon_nan_nor@2x.png'),
		  activeIcon1: require('../../assets/Train/icon_nv_pre@2x.png'),
		  inactiveIcon1: require('../../assets/Train/icon_nv_nor@2x.png'),
		  train_add: {
		  	province: {
		  		name: '广东',
		  		code: '440000'
		  	},
		  	city: {
		  		name: '广州',
		  		code: '440100'
		  	},
		  	area: {
		  		name: "天河区",
		  		code: '440106'
		  	},
		  	isMust: true,
		  },
		  activity: {
		  	name: '',
		  	sex: '',
		  	age: '',
		  	phone: '',
		  	city: ''
		  },
		  isAreaList:false,
	  }
  },

  methods:{
	  // 现在年龄段
	  onConfirm(value) {
	  	this.activity.age = value;
	  	this.showPicker = false;
	  },
	  // 提交数据
	  formClick() {
	  	console.log(this.activity)
	  	// this.$router.push('ApplicationDet')
	  },
	  confirm(res) {
	  	console.log(res)
	  	// this.province=res[0].name
	  	this.activity.city=res[0].name+res[1].name+res[2].name
	  	// this.cityid=res[1].code
	  	// this.SubmitData.provinceid = res[0].code
	  	// this.SubmitData.cityid = res[1].code
	  	// this.SubmitData.areaid = res[2].code
	  	this.train_add.province = res[0]
	  	this.train_add.city = res[1]
	  	this.train_add.area = res[2]
	  
	  
	  
	  	this.isAreaList = false
	  },
	  provinceClick() {
	  
	  	this.isAreaList = true
	  },
	  cancel() {
	  	console.log('222')
	  	this.isAreaList = false
	  },
  },
  mounted() {
	
	  	document.title ='导师申请';   
		for(let i = 1;i<100;i++){
			this.columns.push(i)
		}
  }
}
</script>
<style lang="scss">
	.apply{
		background-color: #fff;
		min-height: 100vh;
		text-align: left;
		padding: 20px 15px 0;
		.btn1 {
			border-radius: 0.375rem;
			padding: 9px 10px;
			background: #F4F4F4;
			color: #919CB0;
			border: 1px solid transparent;
			margin-right: 5px;
			// margin-top: 1rem;
			width: 31%;
			position: relative;
		
			img {
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
			}
		}
		
		
		.btn.activation {
			border-color: #0081FF;
			background: #FFFFFF;
			color: #0081FF;
		}
		.content {
			position: relative;
			height: calc(100% - 80px);
			overflow: auto;
		
		}
		.after{
			position: relative;
		}
		.after::after{
			display: block;
			content: '请填写正确的中国大陆手机号码！';
			color: #FF524D;
			position: absolute;
			bottom: 10px;
			left: 0;
			width: 100px;
			height: 15px;
		}
		
		input {
			background-color: #F4F4F4;
			width: 100%;
			height: 36px;
			border-radius: 6px;
			padding: 0 13px;
		}
		
		input::-webkit-input-placeholder {
			color: #919CB0;
		}
		
		input::-moz-placeholder {
			/* Mozilla Firefox 19+ */
			color: #919CB0;
		}
		
		input:-moz-placeholder {
			/* Mozilla Firefox 4 to 18 */
			color: #919CB0;
		}
		
		input:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: #919CB0;
		}
		
		.form {
			padding-bottom: 20px;
		
			.left {
				height: 36px;
				line-height: 36px;
			}
		}
		
		.row {
			margin: 25px 0;
		}
		
		.formBtn {
			padding-top: 20px;
		
			.btn1 {
				border-radius: 21px;
				width: 8.75rem;
				height: 2.625rem;
				background-color: #0081FF;
				color: #fff;
				line-height: 2.625rem;
				text-align: center;
				margin: 0 auto;
			}
		
		}
		
		p {
			margin-bottom: 28px;
		}
	}
	
	
	
</style>
