<template>
	<div class="GoodBook" v-if="isJ">
		<div class="GoodBook_Content">
			<div style="line-height:24px;">微信转发分享《现场急救行动指南》这本书给朋 友和朋友圈，每成功推销一本，会有销售价10% 的推荐费，推荐费每个月会月结一次，会直接转 到您在当前公众号的钱包。</div>
			<div class="GoodBook_Content_zhu">
				<p class="zhu">注意事项：</p>
				<div class="flex GoodBook_Content_zhu_content"><span style="width:15px">1、</span>
					<p style="width:calc(100% - 15px)">仅支持通过推荐到微信，不支持其他平台；</p>
				</div>
				<div class="flex GoodBook_Content_zhu_content"><span style="width:15px">2、</span>
					<p style="width:calc(100% - 15px)">本次推荐活动仅支持本人推荐，即是在当前页面点 击“分享”按钮发起分享推荐，发起后其他人可帮你 转发分享；</p>
				</div>
				<div class="flex GoodBook_Content_zhu_content"><span style="width:15px">3、</span>
					<p style="width:calc(100% - 15px)">本次推荐活动的结束时间会在结束时另行通知。</p>
				</div>
			</div>
			<div class="zhutuImg"  v-if="showCan">
				<!-- <img src="../../assets/user/pic_zhutu@2x.png" alt="" class="auto-img"> -->
				<vue-canvas-poster :widthPixels="1000" :painting="painting" @success="success" @fail="fail"></vue-canvas-poster>
        <img :src="posterImg" style="width: 100%;">
				<!-- <canvas-post ref="tt" v-if="showCan"></canvas-post> -->
			</div>
			<div class="recommendationBox">
				<div>本月成功推荐：<span v-text="month_num"></span>本</div>
				<div>历史推荐总数：<span class="lvNumber" v-text="total_num"></span>本</div>
			</div>
		</div>
		<!-- 底部 -->
		<div class="bottom">
			<div class="btn" @click="primaryShow">
				分享
			</div>
		</div>
		<!-- <van-popup v-model="show" position="bottom" :style="{ height: '280px' }" >
			<div class="bottomBox">
				<div class="bottomBox_content">
					<div class="flex bottomBox_content_fen">
						<img src="../../assets/user/pic_xfengm80@2x.png" alt="" width="40">
						<div>好书推荐《现场急救行动指南》</div>
						
					</div>
					<div class="fen"></div>
					<div class="flex fenBox">
						<div @click="shareClick">
							<img src="../../assets/user/icon_fspy@2x.png" alt="" width="50">
							<p>发送给朋友</p>
						</div>
						<div>
							<img src="../../assets/user/icon_pyq@2x.png" alt="" width="50">
							<p>发送到朋友圈</p>
						</div>
					</div>
				</div>
				<div class="btnBox">取消</div>
			</div>
		</van-popup> -->
		<!-- <van-overlay :show="primaryShow">
			<div class="flex" style="justify-content: flex-end;margin: 15px 30px;"><img src="../../assets/user/icon_zhishi@2x.png"
				 alt="" width="80" style="">
			</div>
			<div class="flex bottomBox_content_fen">
				<img src="../../assets/user/pic_xfengm80@2x.png" alt="" width="40">
				<div>好书推荐《现场急救行动指南》</div>

			</div>
			<div class="text1">立即分享给好友吧</div>
			<div class="text2">点击屏幕右上角将页面分享给好友</div>
			<div style="margin-top:60px;" @click="primaryShow = false">
				<img src="../../assets/user/hstj_icon_close@2x.png" alt="" width="32">
			</div>
		</van-overlay> -->
		<div>
		</div>
	</div>
</template>

<script>
	// import vConsole from '@/util/vconsole.js'
	// import canvasPost from './canvasPost.vue'
	import {
		getSign,getIntroduceCount
	} from '@/api';
	import {
		Toast
	} from 'vant'
	import areaList from '../../area.js'

	export default {
		name: 'GoodBook',
		components: {
			// canvasPost
		},
		data() {
			return {
				url_link:'',
				show: false,
				// primaryShow: false,
				month_num:0,
				total_num:0,
				isJ:false,

				showCan: false,
				posterImg: '', // 生成的图片+二维码
      	painting: {
          width: '550px',
          height: '520px',
          background: require('@/assets/user/pic_zhutu1.jpg'),
          views: [
						// {
            //   type: 'image',
            //   url: 'http://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIxbEZu0fBQOYlTLTpqtoezkNiaCBQ4ZXZAKhvPiaFAdI91vBsUkpiaDH4mp4qGvcVo2EaxRrp5fMia8w/132',
            //   css: {
            //       top: '20px',
            //       left: '36px',
            //       borderRadius: '40px',
            //       width: '80px',
            //       height: '80px',
            //   },
          	// },
						{
							type: 'qrcode',
							content: '',
							css: {
								bottom: '60px',
								right: '46px',
								color: '#000',
								background: '#fff',
								width: '125px',
								height: '125px',
								borderWidth: '5px',
								borderColor: '#fff'
							},
						}
					],
				}
			}
		},

		methods: {
			success(src) {
			this.posterImg = src;
			// console.log(src)
		},
		fail(err) {
			console.log(err)
		},
			primaryShow(){
				window.location.href=this.url_link
			},
			shareClick() {
				return
				let href = location.href.split('?')[0]
				this.show = false
				this.primaryShow = true
				let _this=this
				wx.ready(resx => { // 微信SDK准备就绪后执行的回调。
				
					wx.updateAppMessageShareData({
						title: '好书推荐《现场急救行动指南》', // 分享标题
						desc: '【现场急救行动指南】公众号', // 分享描述
						link: _this.url_link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/20200428/5732b699421ead180f40e37f0c599086.png', // 分享图标
						success: function(resq) {
							// 设置成功
							// alert('设置成功')
						},
						error: function(resw) {
							alert(JSON.stringify(resw))
						}
					})
					// 分享朋友圈
					wx.updateTimelineShareData({
						title: '好书推荐《现场急救行动指南》', // 分享标题
						link: _this.url_link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/20200428/5732b699421ead180f40e37f0c599086.png', // 分享图标
						success: function() {
							// 设置成功
						}
					});
				});
			},
			
			getIntroduceCount(){
				getIntroduceCount().then(res=>{
					this.month_num=res.data.month_num
					this.total_num=res.data.total_num
				})
			}
		},
		mounted() {
			document.title = '线上购书';
			let user_type=localStorage.getItem('user_type')
			
			if(user_type<2){
				this.$router.push({name:'goodBookDetails'})
				
			}else{
				this.getIntroduceCount()
				let href = location.href.split('?')[0]
				let _this = this
				this.isJ=true
				console.log(this.$route.query)
				if(!this.$route.query.tips&&!this.$route.query.uniqid){
					getSign({
							page_url: href
						})
						.then(res => {
							if (res.data.appId) {
								_this.url_link = res.data.url_link
								// _this.painting.views[0].content = res.data.url_link
								if(res.data.url_link) {
									_this.$nextTick(() => {
										_this.painting.views[0].content = res.data.url_link
										setTimeout(() => {
											_this.showCan= true
										},100)
									})
								}
								console.log(_this.url_link)
							}
						});
				}else{
					_this.url_link =location.href
				}
				
			}
		
		}
	}
</script>
<style lang="scss">
	.GoodBook {
		width: 100%;
		// height: 100vh;
		background-color: #F7F8FF;
		padding: 15px;
		padding-bottom: 90px;

		.van-popup--bottom {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			background-color: #EAEAEA;

			.bottomBox {
				width: 100%;
				height: 100%;
				position: relative;

				.btnBox {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 50px;
					background: rgba(255, 255, 255, 1);
					line-height: 50px;
					text-align: center;

				}

				.bottomBox_content {
					height: calc(100% - 50px);
					padding: 20px 15px;
				}

				.fenBox {
					div:first-child {
						margin-right: 32px;
					}

					p {
						font-size: 9px;
						font-family: PingFang SC;
						font-weight: 500;
						color: rgba(40, 51, 74, 1);
						line-height: 24px;
					}
				}
			}
		}

		.bottomBox_content_fen {
			width: 84%;
			margin: 15px auto;
			height: 60px;
			background: rgba(255, 255, 255, 1);
			border-radius: 6px;
			padding: 0 12px;

			img {
				margin-right: 12px;
			}
		}

		.fen {
			width: 100%;
			height: 1px;
			background: rgba(211, 210, 209, 1);
			margin: 28px 0 20px;
		}

		.bottom {
			box-shadow: 0px -6px 10px 0px rgba(145, 156, 176, 0.1);
		}

		.GoodBook_Content {
			width: 100%;
			background: rgba(255, 255, 255, 1);
			border-radius: 6px;
			padding: 15px;
			text-align: justify;
		}

		.GoodBook_Content_zhu {
			padding: 10px;
			background: rgba(245, 245, 245, 1);
			border-radius: 3px;
			margin-top: 7px;
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(40, 51, 74, 1);
			line-height: 20px;

			.zhu {
				margin-bottom: 10px;
			}

			.GoodBook_Content_zhu_content {
				align-items: inherit;
				margin-bottom: 5px
			}
		}

		.zhutuImg {
			width: 100%;
			margin: 20px 0 0px;
			padding-bottom: 15px;
		}

		.fen {
			margin: 16px 0;
			width: 100%;
			height: 1px;
			background: rgba(229, 229, 229, 1);
		}

		.recommendationBox {
			height: 50px;
			background: rgba(0, 129, 255, 0.05);
			color: #28334A;
			border-radius: 6px;
			line-height: 50px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		.text1 {
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: bold;
			color: rgba(255, 255, 255, 1);
			line-height: 24px;
		}

		.text2 {
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(255, 255, 255, 1);
			line-height: 24px;
		}
	}
</style>
