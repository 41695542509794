<template>
	<div class="recharge">
		<div v-if="$route.query.type==1">
			<div>
				<img src="../../assets/user/icon_czcg@2x.png" alt="" style="width: 5rem;height: 5rem;margin: 28px auto 20px;">
			</div>
			<p style="font-size: 18px;margin-bottom: 1.75rem;">充值成功</p>
			<van-cell>
				<template slot="title">
					<div class="flex" style="text-align: left;">

						<span class="custom-title listTitle" style="padding-left: 5px;"> 充值方式</span>
					</div>
				</template>
				<div class="right">微信支付</div>

			</van-cell>
			<div style="padding: 0 15px;">
				<div style="width: 100%;border-bottom: 1px solid #E5E5E5;"></div>
			</div>
			<van-cell>
				<template slot="title">
					<div class="flex" style="text-align: left;">

						<span class="custom-title listTitle" style="padding-left: 5px;"> 充值金额（元）</span>
					</div>
				</template>
				<div class="right" style="    letter-spacing: 0px;">￥<span v-text="money"></span></div>

			</van-cell>

			<div class="btn" @click="determine">
				完成
			</div>
		</div>
		<div v-if="$route.query.type==2">
			<div>
				<img src="../../assets/user/icon_czcg@2x.png" alt="" style="width: 5rem;height: 5rem;margin: 28px auto 20px;">
			</div>
			<p style="font-size: 18px;margin-bottom: 1.75rem;">已成功提现到您的微信钱包</p>
			
			<van-cell >
				<template slot="title">
					<div class="flex" style="text-align: left;">
		
						<span class="custom-title listTitle" style="padding-left: 5px;"> 提现金额（元）</span>
					</div>
				</template>
				<div class="right" style="    letter-spacing: 0px;">￥<span v-text="money"></span></div>
		
			</van-cell>
		
			<div class="btn" @click="determine">
				完成
			</div>
		</div>
	</div>
</template>

<script>
	export default {
  name: 'wallet',
  components: {
  },
  data(){
	  return{
		title:'充值',
		value:'',
		money:''
	  }
  },
  methods:{
	determine(){
		this.$router.back(-1)
	}
  },
  
  mounted() {
	   let title=this.$route.query.type==1?'充值':"提现"
  	document.title = title;   
	this.money=this.$route.query.money
	// this.gethotcity()
	// this.getActivityDataCount()
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.recharge {
		width: 100%;
		min-height: 100vh;
		background-color: #F7F8FF;
		
		.right {
			color: #28334A;
			font-size: 1rem;
			font-weight: bold;
		}
		.van-cell{
			height: 63px;
			    line-height: 63px;
			    padding: 0 15px;
		}

		.tis {
			color: #919CB0;
			font-size: 12px;
			text-align: left;
			padding-left: 2.25rem;
			position: relative;
			margin-top: 16px;

			&::before {
				display: block;
				content: '';
				width: 0.75rem;
				height: 0.75rem;
				background: url(../../assets/Train/icon_tips@2x.png) no-repeat center;
				background-size: 100% 100%;
				position: absolute;
				top: 3px;
				left: 15px;
			}
		}

		.btn {
			background-color: #0081FF;
			width: 78%;
			height: 42px;
			text-align: center;
			line-height: 42px;
			border-radius: 20px;
			margin: 28px auto 0;
			color: #fff;
		}
	}
</style>
