<template>
	<div class="evaluate">
		
		<!-- <div style="width: 100%; background: #F7F8FF; height: 10px;"></div> -->
		<div style="text-align: left;padding: 0px 0px 20px;background-color: #fff;" class="commentBox">
			<div style="" class="comment">给培训课程评分</div>
			<div class="flex" style="margin-top: 12px;    flex-direction: column;">
				<div v-for="(item,i) in subject_list" :key="i" style="text-align: center;">
					<div style="margin-bottom: 15px;" v-text="item.title"></div>
					<van-rate v-model="avgscoreList[i]" gutter="20" 
	  void-icon="star" readonly style="margin-bottom: 15px;" />
					
				</div>
				
			</div>

		</div>
		<div class="commentBox">
			<div class="comment">
				评价详情
			</div>
			<div class="commentlist">
			<div class="commentListBox" style="display: flex;padding: 20px 0;" v-for="(item,i) in commentList" :key="i">
				<div>
					<img :src="item.user_head_img" alt="" style="border-radius: 50%;width: 32px;margin-right: 8.5px;" v-if="item.is_anonymous==0">
				</div>
				<div>
					<img src="../../assets/icon_niming@2x.png" alt="" style="border-radius: 50%;width: 32px;margin-right: 8.5px;" v-if="item.is_anonymous==1">
				</div>
				<div>
					<div v-text="item.is_anonymous?'匿名':item.user_name"></div>
					<div style="font-size: 11px;margin: 8px 0 8px;color: #919CB0;" v-text="item.createtime"></div>
					<p style="font-size: 14px;color: #767E8D;letter-spacing: 0;" v-text="item.content"> </p>
				</div>
			</div>
			</div>
		</div>
		<!-- <div class="bottom">
			<div class="bottom_input" @click="$router.push({name:'release'})">
				发表评价
			</div>
		</div> -->

		<!-- <van-calendar v-model="isdata" @confirm="onConfirm" color="#0081FF" /> -->
		<!-- <van-calendar v-model="isdata_Section" type="range" @confirm="onConfirm_Section" color="#0081FF" /> -->
		<!-- <transition name="van-slide-up">
		  <div v-show="isAreaList" style="z-index: 3;position: fixed;bottom: 0;left: 0;width: 100%; ">
			 
		  </div>
		</transition> -->

	</div>
</template>

<script>
	import {getActivityCommentLists,getActivityCommentSubjectList} from '@/api';
	import {dateFormat} from '@/util/setTime.js'
	import {unique} from '@/util/unique.js'
export default {
  name: 'userTrainEvaluate_i',
  components: {
  },
  data(){
	  return{
		  // 成绩
		  avgscoreList:[],
		  // 题目
		  subject_list:[],
		  commentList:[],
		   cover: "https://xcjj.tysos.cn/gzh/backend/public//uploads/act_default.png",
		  actList:[],
		  value:'4',
		  value1:'1',
		  value2:'1',
		title:'查看评论',
		message:'天鹰救援培训中心非常好，老师很专业，设备很新，培训老师专业过硬，讲课逻辑清晰，生动有趣，大家都能真真正正的学到知识',
		list:
			{
                "id": 8,
                "act_name": "测试1234",
                "complete_addr": "123",
                "act_start_time": "2019-01-01 10:10:10",
                "act_end_time": "2019-01-01 10:10:10",
                "act_type": 4,
                "city": "",
                "act_status": 4,
               
                "has_comment": 0,
                "is_cancel": 0,
            
		},
		
	  }
  },
  methods:{
	dateFormat(fmt,data){
		return dateFormat(fmt,data)
	},
	unique(arr){
		return unique(arr)
	}
  },
  
  mounted() {
  	document.title = this.title;  
	 getActivityCommentSubjectList({act_id:this.$route.query.act_id}).then(res=>{
		 this.subject_list=res.data.subject_list
		 console.log(this.subject_list)
		 getActivityCommentLists({act_id:this.$route.query.act_id}).then(res=>{
		 	this.commentList=unique(res.data.list,['user_id']) 
		 	this.commentList.forEach(item=>{
				// dateFormat('Y-m-d',item.createtime)
				item.createtime=item.createtime.split(' ')[0]
			})
			for (let var1 in res.data.avgscore) {
				this.avgscoreList.push( res.data.avgscore[var1].avgscore)
			}
		 })
	 })
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.evaluate {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		padding-bottom: 60px;
		background-color: #F7F8FF;
		.commentBox{
			text-align: left;
			background-color: #fff;
			.comment{
				width: 100%;
				padding: 13px 15px;
				background-color: #F4F4F4;
				text-align: left;
				font-weight: bold;
			}
			.commentlist{
				padding: 0 15px;
			}
			.commentListBox{
				border-bottom: 1px solid #E5E5E5;
			}
			.commentListBox:last-child{
				border-bottom: none;
			}
		}
		.ping {
			width: 4.375rem;
			height: 1.625rem;
			border: 1px solid #0081FF;
			border-radius: 20px;
			color: #fff;
			background-color: #0081FF;
			font-size: 0.75rem;
			line-height: 1.5rem;
			text-align: center;
			box-sizing: border-box;
			float: right;
			margin-left: 16px;
		}

		.Ping_hui {
			color: #919CB0;
			border-color: #919CB0;
			background-color: #fff;

		}

		.Ping_blue {
			color: #0081FF;
			border-color: #0081FF;
			background-color: #fff;
		}

		.vanSwipe {
			width: 92%;
			margin: 0 auto 0.9375rem;
			height: 7.5rem;

			img {
				border-radius: 8px;
			}

		}

		.btnBox {
			.btn {
				width: 7.1875rem;
				height: 5.625rem;
				background: no-repeat center;
				background-size: 109% 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: #FFFFFF;
				font-size: 12px;

				div.title {
					margin-bottom: 6px;
					margin-top: -10px;
				}

				div.btn_number {
					font-size: 28px;
					font-weight: bold;
				}
			}

			.btn1 {
				background-image: url(../../assets/Train/bg_yellow@2x.png);
			}

			.btn2 {
				background-image: url(../../assets/Train/bg_red@2x.png);
			}

			.btn3 {
				background-image: url(../../assets/Train/bg_blue@2x.png);
			}
		}

		.list_title {
			justify-content: space-between;
			text-align: left;

			// margin: 20px 0 20px;
			.list_title_left {
				width: 0.1875rem;
				height: 1.125rem;
				background: #0081FF;
				border-radius: 0 0.09375rem 0.09375rem 0;
				margin-right: 0.75rem;
			}

			.list_title_img {
				width: 0.4375rem;
				height: 0.6875rem;
				margin-right: 0.90625rem;
				margin-left: 0.28125rem;
			}

			.left {
				font-size: 1.125rem;
				color: #28334A;
				font-weight: bold;
			}

			.right {
				font-size: 0.9375rem;
				color: #0081FF;
			}
		}

		.list {
			flex-wrap: inherit;
			padding: 15px;

			.list_img {
				margin-right: 0.71875rem;
			}

			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}

			.text {
				font-size: 0.75rem;
				color: #919CB0;
				margin-left: 0.5rem;
			}

			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}

			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}

			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}

			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}

			.btnimg_span {
				margin-right: -5px;

			}
		}

		.bottom {
			position: relative;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 3.75rem;
			padding: 12px 0;
			background: #F7F8FF;

			.bottom_input {
				width: 75%;
				height: 2.625rem;
				margin: 0 auto;
				background: #0081FF;
				border-radius: 50px;
				position: relative;
				text-align: left;
				line-height: 2.625rem;
				text-align: center;
				color: #fff;

				.bottom_input_img {
					width: 16px;
					height: 16px;
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		.Slide_up {
			z-index: 111;
			position: fixed;
			bottom: 0;
			height: 90vh;
			background: #fff;
			width: 100%;
			padding: 0.9375rem;
			text-align: left;

			// overflow: auto;
			.Slide_up_header {
				position: relative;
				font-size: 14px;
				text-align: center;

				.Slide_up_img {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					width: 30px;
					height: 30px;
					cursor: pointer;
				}
			}

			.btn {
				border-radius: 0.375rem;
				padding: 9px 13px;
				background: #F4F4F4;
				color: #919CB0;
				border: 1px solid transparent;
				margin-right: 10px;
				margin-top: 1rem;

			}

			.btn.activation {
				border-color: #0081FF;
				background: #FFFFFF;
				color: #0081FF;
			}

			.Slide_up_title {
				margin: 27px 0 0;
			}

			.btnSeach {
				width: 8.75rem;
				height: 42px;
				text-align: center;
				line-height: 42px;
				background: #0081FF;
				color: #fff;
				margin: 20px auto;
				border-radius: 21px;
			}
		}

	}
</style>
