<template>
	<div class="goodBookOrder">
		<van-cell is-link center>
			<!-- 使用 title 插槽来自定义标题 -->
			<template #title>
				<div v-if="AddressInfo.name" @click="show=true">
					<p class="custom-title">
						收件人：<span v-text="AddressInfo.name" style="margin-right: 10px;"></span><span v-text="AddressInfo.tel"></span>
					</p>
					<p class="custom-title">
						收货地址：<span v-text="AddressInfo.province+AddressInfo.city+AddressInfo.country+AddressInfo.addressDetail"></span>
					</p>
				</div>
				<div v-if="!AddressInfo.name" @click="show=true">
					<p class="newAddrHUO">暂未获取到收货地址</p>
					<p class="newAddr">请新建收货地址</p>
				</div>
			</template>
		</van-cell>
		<van-card :num="$route.query.orderNumebr" :price="price" title="现场急救行动指南" thumb="https://xcjj.tysos.cn/gzh/backend/public/uploads/20200428/5732b699421ead180f40e37f0c599086.png" />
		<div class="vancellBox">
			<van-cell title="现金">
				<template #default>
					<span class="van-cell__value2">￥</span><span class="van-cell__value1" v-text="price * $route.query.orderNumebr"></span><span
					 class="van-cell__value2">元</span>
				</template>
			</van-cell>
			<van-cell title="邮费">
				<template #default>

					<span class="van-cell__value2">￥</span><span class="van-cell__value1" v-text="Postage"></span><span class="van-cell__value2">元</span>
				</template>
			</van-cell>
			<van-cell title="发票" >
				<!-- 使用 right-icon 插槽来自定义右侧图标 -->
				  <template #right-icon >
					  <span @click="isInvoice=true" v-text="invoice==0?'不开发票':invoice==1?'普通发票':invoice==2?'一般纳税人发票':''"></span>
					  <van-icon name="arrow" style="line-height: inherit;" />
				  </template>
			</van-cell>
			<van-field rows="1" :autosize="{ minHeight: 26 }" label="留言" type="textarea" v-model="text" class="van-cell1" placeholder="点击给商家留言" />
			
		</div>
		<div class="GoodBook_Content_zhu">
			<p class="zhu">注意事项：</p>
			<div class="flex GoodBook_Content_zhu_content"><span style="width:15px">1、</span>
				<p style="width:calc(100% - 15px)">下单后无法取消订单，请认真核对收件信息</p>
			</div>
		</div>
		<div class="bottom">
			<div>
				合计：
				<span class="van-cell__value2">￥</span><span class="van-cell__value1" v-text="(price * $route.query.orderNumebr + Postage).toFixed(2)"></span><span
				 class="van-cell__value2">元</span>
			</div>
			<div class="btn1" @click="purchaseClick" v-if="!isover">
				立即购买
			</div>
			<div class="btn1" v-if="isover" @click="purchaseClick1">
				立即购买
			</div>
		</div>
		<van-popup v-model="show" position="bottom" :style="{ height: 'auto' }" closeable>
			<div class="title">编辑收货地址</div>
			<van-address-edit  :address-info="AddressInfo" :area-list="areaList" show-postal show-search-result :search-result="searchResult"
			 :area-columns-placeholder="['请选择', '请选择', '请选择']" @save="onSave" @change-detail="onChangeDetail" @change-area="confirm" />
		</van-popup>
		<van-popup v-model="isInvoice" position="bottom" :style="{ height: 'auto',minHeight:'300px',maxHeight:'95vh' }"  @click-overlay="close" :close-on-click-overlay="false">
			<div class="title">
				<span>发票</span>
				<img src="../../assets/Train/icon_close@2x.png" alt="" class="colseIcon" @click="close">
			</div>
			<div class="invoiceBox" style="padding-bottom: 70px;">
			<div>
				发票类型
			</div>
			<div class="flex invoiceType">
				<div class="btn" @click="invoice=1" :class="invoice==1?'invoiceblue':'invoicehui'">
					普通发票
				</div>
				<div class="btn" @click="invoice=2" :class="invoice==2?'invoiceblue':'invoicehui'">
					一般纳税人发票
				</div>
				<div class="btn" @click="invoice=0" :class="invoice==0?'invoiceblue':'invoicehui'">
					不开发票
				</div>
			</div>
			<div v-if="invoice!=0" style="margin-top: 20px;">
				<div>电子邮箱<span style="color: red;">*</span></div>
				<van-field
					clearable
					@focus="focus"
					ref="fieldref"
				  v-model="arr.email"
				  placeholder="电子发票将会发送到您留的这个电子邮箱"
				/>
			</div>
			<div style="margin-top: 20px;" v-if="invoice!=0">
				发票抬头
			</div>
			<div class="flex invoiceType" style="justify-content: inherit;" v-if="invoice==1">
				<div class="btn" style="margin-right: 20px;" :class="arr.normal_type==1?'invoiceblue':'invoicehui'" @click="arr.normal_type=1">
					个人
				</div>
				<div class="btn" :class="arr.normal_type==2?'invoiceblue':'invoicehui'"  @click="arr.normal_type=2">
					单位
				</div>
			</div>
			<van-cell-group style="margin-top: 10px;" v-if="invoice==1&&arr.normal_type==2">
				<div>
					<div>单位名称<span style="color: red;">*</span></div>
					<van-field
						clearable
						@focus="focus"
						ref="fieldref"
					  v-model="arr.company_name"
					  placeholder="请输入单位名称"
					/>
				</div>
				<div>
					<div>纳税人识别号<span style="color: red;">*</span></div>
					<van-field
						clearable
						@focus="focus"
						ref="fieldref"
					  v-model="arr.person_bill_code"
					  placeholder="请输入纳税人识别号"
					/>
				</div>
			 
			  <div style="margin: 10px 0;font-weight: bold;">选填项</div>
			  <div>
			  	<div>单位地址</div>
			  	<van-field
					clearable
					@focus="focus"
					ref="fieldref"
			  	  v-model="arr.company_address"
			  	  placeholder="请输入单位地址"
			  	/>
			  </div>
			 <div>
			 	<div>单位电话</div>
			 	<van-field
					clearabl
					@focus="focus"
					ref="fieldref"
			 	  v-model="arr.company_phone"
			 	  placeholder="请输入单位电话"
			 	/>
			 </div>
			 <div>
			 	<div>开户银行</div>
			 	<van-field
					clearabl
					@focus="focus"
					ref="fieldref"
			 	  v-model="arr.kh_bank"
			 	  placeholder="请输入开户银行"
			 	/>
			 </div>
			  <div>
			  	<div>银行账号</div>
			  	<van-field
					clearabl
					@focus="focus"
					ref="fieldref"
			  	  v-model="arr.bank_account"
			  	  placeholder="请输入银行账号"
			  	/>
			  </div>
			</van-cell-group>
			<van-cell-group style="margin-top: 10px;" v-if="invoice==1&&arr.normal_type==1">
				<div>
					<div>个人名称<span style="color: red;">*</span></div>
					<van-field
						clearable
						@focus="focus"
						ref="fieldref"
					  v-model="arr.self_name"
					  placeholder="请输入个人名称"
					/>
				</div>
			</van-cell-group>
			<van-cell-group style="margin-top: 10px;" v-if="invoice==2">
				<div>
					<div>单位名称<span style="color: red;">*</span></div>
					<van-field
						clearable
						@focus="focus"
						ref="fieldref"
					  v-model="arr.company_name"
					  placeholder="请输入单位名称"
					/>
				</div>
			 <div>
			 	<div>纳税人识别号<span style="color: red;">*</span></div>
			 	<van-field
					clearabl
					@focus="focus"
					ref="fieldref"
			 	  v-model="arr.person_bill_code"
			 	  placeholder="请输入纳税人识别号"
			 	/>
			 </div>
			 <div>
			 	<div>单位地址<span style="color: red;">*</span></div>
			 	<van-field
					clearabl
					@focus="focus"
					ref="fieldref"
			 	  v-model="arr.company_address"
			 	  placeholder="请输入单位地址"
			 	/>
			 </div>
			<div>
				<div>单位电话<span style="color: red;">*</span></div>
				<van-field
					clearabl
					@focus="focus"
					ref="fieldref"
				  v-model="arr.company_phone"
				  placeholder="请输入单位电话"
				/>
			</div>
			<div>
				<div>开户银行<span style="color: red;">*</span></div>
				<van-field
					clearabl
					@focus="focus"
					ref="fieldref"
				  v-model="arr.kh_bank"
				  placeholder="请输入开户银行"
				/>
			</div>
			 <div>
			 	<div>银行账号<span style="color: red;">*</span></div>
			 	<van-field
					clearabl
					@focus="focus"
					ref="fieldref"
			 	  v-model="arr.bank_account"
			 	  placeholder="请输入银行账号"
			 	/>
			 </div>
			 
			  
			</van-cell-group>
			</div>
			<div class="bottom" style="position: absolute;box-shadow:none;height: 70px;">
				<div class="btn " @click="submitClick">确定</div>
			</div>
		</van-popup>
		<van-popup v-model="showOver"  class="dudao">
			<div class="popupBox">
				<div style="text-align: left;">
					购买成功，书籍预计在三天内能快递 到您的手上，请留意快递信息。
				</div>
				<div class="flex btnBox">
					<!-- <div @click="show=false">取消</div> -->
					<div class="suss" @click="closeOK">好的</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	// import vConsole from '@/util/vconsole.js'
	import {
		getSign,
		shareOrder,
		getBookMoney,
		userRechargeUniOrder,
		getLastAddress,
		getInfo
	} from '@/api';
	import {
		Toast
	} from 'vant'
	import areaList from '../../area.js'
	export default {
		name: 'goodBookOrder',
		components: {},
		watch:{
			'invoice'(){
				for(var item in this.arr){
					this.arr[item]=''
					// console.log(this.arr[item])
				}
			}
		},
		data() {
			return {
				submitArr:{},
				arr:{
					// 电子邮箱
					email:'',
					// 1个人发票，2.单位发票
					normal_type:0,
					// 公司名称
					company_name:'',
					// 纳税识别号
					person_bill_code:'',
					// 单位电话
					company_phone:'',
					// 单位地址
					company_address:'',
					// 开户银行
					kh_bank:'',
					// 开户银行账号
					bank_account:'',
					// 个人名称
					self_name:''
				},
				company:0,
				isInvoice:false,
				invoice:0,
				showOver:false,
				isover:false,
				aclist: [],
				areaList,
				searchResult: [],
				// 显示选择地址
				show: false,
				// 邮费
				Postage: 0,
				// 备注
				text: '',
				// 金额
				price: 0,
				value: 0,
				show: false,
				primaryShow: false,
				AddressInfo:{//收货人信息初始值
					name:'',//姓名
					tel:'',//电话
					province:'',//省份
					city:'',//城市
					country:'',//区县
					areaCode:'',//地址code：ID
					addressDetail:'',//详细地址
					postalCode:''
				},
			}
		},

		methods: {
			close(){
				this.submitClick()
			},
			focus(){
				// this.isAct=true
			},
			submitClick(){
				let arr ={}
				let isover=false
				var regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
				switch (this.invoice){
					case 0:
					isover=true
					arr={
						need_bill:0,
						bill_type:0,
					}
						break;
					case 1:
					// 选择了普通发票中的个人
					if(this.arr.email == ''){
						this.$Toast.fail('请填写邮箱');
						return
					}
					if(this.arr.normal_type==1){
						if(this.arr.email != '' && !regEmail.test(this.arr.email)){
							this.$Toast.fail('邮箱格式不正确');
							this.arr.email = ''
							return
						}
						else if(this.arr.self_name){
							arr={
								need_bill:1,
								bill_type:1,
								normal_type:this.arr.normal_type,
								self_name:this.arr.self_name,
								email:this.arr.email
							}
							isover=true
						}else{
							this.$Toast('请选择个人名称')
							isover=false
						}
					}else if(this.arr.normal_type==2){
						if(this.arr.email != '' && !regEmail.test(this.arr.email)){
							this.$Toast.fail('邮箱格式不正确');
							this.arr.email = ''
							isover=false
							return
							}
						// 选择了普通发票中的单位
						else if(this.arr.company_name&&this.arr.person_bill_code&&this.arr.email){
							arr={
								need_bill:1,
								bill_type:1,
								normal_type:this.arr.normal_type,
								company_name:this.arr.company_name,
								person_bill_code:this.arr.person_bill_code,
								company_phone:this.arr.company_phone,
								company_address:this.arr.company_address,
								kh_bank:this.arr.kh_bank,
								bank_account:this.arr.bank_account,
								email:this.arr.email
							}
							isover=true
						}else{
							this.$Toast('请完善必填项')
							isover=false
						}
					}else{
						// 没有选择个人或者单位的
						this.$Toast('请选择个人或者单位')
						isover=false
					}
						break;
					case 2:
					if(this.arr.email == ''){
						this.$Toast.fail('请填写邮箱');
						return
					}
						if(this.arr.email != '' && !regEmail.test(this.arr.email)){
							this.$Toast.fail('邮箱格式不正确');
							this.arr.email = ''
							isover=false
							return
						}
						arr={
							need_bill:1,
							bill_type:2,
							company_name:this.arr.company_name,
							person_bill_code:this.arr.person_bill_code,
							company_phone:this.arr.company_phone,
							company_address:this.arr.company_address,
							kh_bank:this.arr.kh_bank,
							bank_account:this.arr.bank_account,
							email:this.arr.email
						};
						for (let key in arr) {
							if(arr[key]==''){
								this.$Toast('请完善必填项1')
								isover=false
								return
							}else{
								isover=true
							}
						}
						break;
				}
				if(isover){
					this.submitArr=arr
					this.isInvoice=false
				}
			},
			purchaseClick1(){
				// this.$router.push({name:'404'})
				alert('请勿重复提交')
			},
			closeOK(){
				this.showOver=false
				this.$router.go(-1)
			},
			jsApiCall(jsApiParameters){
				// console.log(jsApiParameters);return;
				// alert(2);
				let _this=this
				let jsondata = JSON.parse(jsApiParameters);
				// console.log('thisssss'+jsApiParameters);
				WeixinJSBridge.invoke(
					'getBrandWCPayRequest',
					 jsondata,
					function(res){
						_this.isover=false
						if(res.err_msg == "get_brand_wcpay_request:ok"){
								// _this.showOver=true
								_this.$router.push({name:'purSuccess'})
						}else if(res.err_msg == "get_brand_wcpay_request:cancel"){
							 Toast("用户取消支付!");
							 
						}else{
							Toast("支付失败!");
						}
					}
				);
			},
			// 立刻购买
			purchaseClick() {
				this.isover=true
				let _this=this
				let tips = this.$route.query.tips
				let Base64 = require('js-base64').Base64
				let noncestr = this.$route.query.uniqid
				let user_id = tips?Base64.decode(tips):''
				let arr={}
				console.log(user_id)
				getInfo({user_id:user_id}).then(res =>{
					console.log(res)
					if(res.code==1){
						let user_type = res.data.user_type
						if(user_type<2){
							tips=''
							noncestr=''
							arr = {
								// url里面的tips参数
								teacher_id: tips,
								// url里面的uniqid参数
								noncestr: noncestr,
								// 书本金额
								money: this.price,
								// 备注
								remark: this.text,
								// 购买数量
								buy_num: this.$route.query.orderNumebr,
								// 邮费
								post_money: this.Postage,
								// 真是姓名
								real_name: this.AddressInfo.name,
								// 	收件人手机号
								phone: this.AddressInfo.tel,
								// 省
								province: this.AddressInfo.province,
								// 	市
								city: this.AddressInfo.city,
								// 区
								area: this.AddressInfo.country,
								// 	邮政编码
								code: this.AddressInfo.postalCode,
								// 	详细地址
								detail: this.AddressInfo.addressDetail,
								total_money:(this.price * this.$route.query.orderNumebr + this.Postage).toFixed(2)
							}
							if(arr.real_name&&arr.phone&&arr.province&&arr.detail){
								shareOrder({...arr,...this.submitArr}).then(res => {
									userRechargeUniOrder({
										total_fee: (this.price * this.$route.query.orderNumebr + this.Postage).toFixed(2),
										select_type: 3,
										order_id:res.data,
									}).then(res => {
										// console.log(res)
										_this.jsApiCall(res.data)
									}).catch(res=>{
										alert('创建订单失败')
									})
								})
							}else{
								this.isover=false
								Toast('填写收货地址')
							}
							
						}
						else{
							arr = {
								// url里面的tips参数
								teacher_id: tips,
								// url里面的uniqid参数
								noncestr: noncestr,
								// 书本金额
								money: this.price,
								// 备注
								remark: this.text,
								// 购买数量
								buy_num: this.$route.query.orderNumebr,
								// 邮费
								post_money: this.Postage,
								// 真是姓名
								real_name: this.AddressInfo.name,
								// 	收件人手机号
								phone: this.AddressInfo.tel,
								// 省
								province: this.AddressInfo.province,
								// 	市
								city: this.AddressInfo.city,
								// 区
								area: this.AddressInfo.country,
								// 	邮政编码
								code: this.AddressInfo.postalCode,
								// 	详细地址
								detail: this.AddressInfo.addressDetail,
								total_money:(this.price * this.$route.query.orderNumebr + this.Postage).toFixed(2)
							}
							if(arr.real_name&&arr.phone&&arr.province&&arr.detail){
								shareOrder({...arr,...this.submitArr}).then(res => {
									userRechargeUniOrder({
										total_fee: (this.price * this.$route.query.orderNumebr + this.Postage).toFixed(2),
										select_type: 3,
										order_id:res.data,
									}).then(res => {
										// console.log(res)
										_this.jsApiCall(res.data)
									}).catch(res=>{
										alert('创建订单失败')
									})
								})
							}else{
								this.isover=false
								Toast('填写收货地址')
							}
							
						}
					}
				})
				
				
			},
			// 获得地址
			confirm(res) {
				console.log(res)
				this.aclist = res
			},
			shareClick() {
				this.show = false
				this.primaryShow = true
			},
			onSave(res) {
				
				console.log(res)
				this.AddressInfo={
					name:res.name,//姓名
					tel:res.tel,//电话
					province:res.province,//省份
					city:res.city,//城市
					country:res.country,//区县
					areaCode:res.areaCode,//地址code：ID
					addressDetail:res.addressDetail,//详细地址
					postalCode:res.postalCode
				}
				this.show = false
			},
			onDelete() {
				Toast('delete');
			},

			onChangeDetail(val) {
				// let _this = this
				// if (val) {
				// 	var ls = new BMap.LocalSearch(this.aclist[1].name);
				// 	ls.search(val);
				// 	ls.setSearchCompleteCallback(function(rs) {
				// 		_this.searchResult = rs.Ir
				// 	});
				// } else {
				// 	_this.searchResult = [];
				// }
			},
		},
		mounted() {
			document.title = '好书推荐';
			getBookMoney().then(res => {
				this.Postage = Number(res.data.postmoney)
				this.price = Number(res.data.bookmoney).toFixed(2)
			})
			getLastAddress().then(res=>{
				if(res.code==1){
					this.AddressInfo={//收货人信息初始值						
					name:res.data.lastAddress.real_name,//姓名						
					tel:res.data.lastAddress.phone,//电话						
					province:res.data.lastAddress.province,//省份						
					city:res.data.lastAddress.city,//城市						
					country:res.data.lastAddress.area,//区县						
					// areaCode:res.data.lastAddress.real_name,//地址
					// code：ID						
					addressDetail:res.data.lastAddress.detail,//详细地址						
					postalCode:res.data.lastAddress.code		
								}
				}
				
			})
			
			
		}
	}
</script>
<style lang="scss">
	.goodBookOrder {
		width: 100%;
		// height: 100vh;
		background-color: #F7F8FF;
		padding-bottom: 90px;
		text-align: left;
		padding: 15px;
		.dudao {
			border-radius: 6px;
			.popupBox {
				width: 300px;
				height: 150px;
				background-color: #fff;
				border-radius: 6px;
				padding: 40px 20px 28px;
				text-align: center;
				position: relative;
				display: flex;
				flex-flow: column;
				justify-content: space-between;
				align-items: center;
				.van-cell {
				    padding: 11px 16px;
				}
				.title{
					font-size: 18px;
					color: #28334A;
					text-align: center;
					width: 100%;
					padding: 20px 0;
					font-weight: bold;
					
				}
		
				.btnBox {
					justify-content: center;
					align-items: inherit;
					color: #919CB0;
		
					div:first-child {
					}
		
					.suss {
						color: #0081FF;
					}
				}
			}
		}
		.GoodBook_Content_zhu {
			padding: 10px;
			background: rgba(255, 255, 255, 1);
			border-radius: 6px;
			margin-top: 10px;
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(40, 51, 74, 1);
			line-height: 20px;

			.zhu {
				margin-bottom: 10px;
			}

			.GoodBook_Content_zhu_content {
				align-items: inherit;
				margin-bottom: 5px
			}
		}

		.bottom {
			height: 50px;
			line-height: 50px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 15px;
			text-align: center;
			box-shadow: 0px -6px 10px 0px rgba(145, 156, 176, 0.1);

			.van-stepper__minus,
			.van-stepper__plus {
				width: 32px;
				height: 32px;
				line-height: 32px;
			}

			.van-stepper__input {
				width: 40px;
				height: 32px;
			}

			.btn1 {
				width: 120px;
				height: 36px;
				line-height: 36px;
				background: rgba(0, 129, 255, 1);
				border-radius: 18px;
				font-size: 15px;
				font-family: PingFang SC;
				font-weight: 500;
				color: rgba(255, 255, 255, 1);
				line-height: 36px;
			}
		}

		.van-cell {
			background: rgba(255, 255, 255, 1);
			border-radius: 6px;
		}

		.newAddrHUO {
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(40, 51, 74, 1);
			line-height: 24px;
		}

		.newAddr {
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: bold;
			color: rgba(40, 51, 74, 1);
			line-height: 24px;
		}

		.van-card {
			background: rgba(255, 255, 255, 1);
			border-radius: 6px;
		}

		.van-card:not(:first-child) {
			margin-top: 10px;
		}

		.van-card__title {
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(40, 51, 74, 1);
			line-height: 24px;
			margin-top: 5px;
		}

		.vancellBox {
			padding-left: 12px;
			padding-right: 12px;
			background-color: #fff;
			margin-top: 10px;
			border-radius: 6px;

			.van-cell {
				background: rgba(255, 255, 255, 1);
				border-radius: 0;
				border-bottom: 1px solid #ddd;
				// padding:0 12px;

			}

			.van-cell1 {
				border-bottom: none;
			}

			.van-field__label {
				width: 50px;
			}

		}

		.van-cell__value1 {
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: bold;
			color: rgba(0, 129, 255, 1);
			line-height: 24px;
			margin-right: 5px;
		}

		.van-field__control {
			font-size: 15px;
		}

		.van-cell__value2 {
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: bold;
			color: rgba(0, 129, 255, 1);
			line-height: 24px;
		}

		.van-address-edit__buttons {
			padding-bottom: 0;
		}

		.van-button--danger {
			background-color: #0081FF;
			border: 0;
		}

		.title {
			padding-top: 15px;
			width: 100%;
			text-align: center;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(40, 51, 74, 1);
			position: relative;
			.colseIcon{
				position: absolute;
				top: 25%;
				right: 10px;
				width: 35px;
				height: 35px;
				// transform: translateY(-50%);
			}
		}

		.van-popup.van-popup--bottom {
			.van-cell {
				// padding-bottom:0 ;
			}
		}

		.van-address-edit {
			height: calc(100% - 36px);
			display: flex;
			flex-flow: column;
			justify-content: space-around;
		}
		.invoiceBox{
			height: calc(95vh - 60px);
			overflow: auto;
			padding: 15px;
			.van-cell{
				// padding-left: 0;
				background:rgba(244,244,244,1);
				padding: 8px 10px ;
				margin: 16px 0;
			}
			.invoiceType{
				justify-content: space-between;
				margin-top: 15px;
				.btn{
					flex:  0 0 30%;
					height:33px;
					border-radius: 6px;
					line-height:33px;
					text-align: center;
					font-size: 12px;
				}
			}
			.invoiceblue{
				border:1px solid #0081FF;
				color: #0081FF;
			}
			.invoicehui{
				color: #C6C8CA;
				background:rgba(244,244,244,1);
			}
			
		}
		.van-cell:focus{
			background-color: #fff !important;
		}
		
	}
</style>
