<template>
	<div class="personalUser">
		<div class="imgBox">
			<img src="../../assets/user/pic_pctop@2x.png" alt="" class="auto-img" v-if="$store.state.user_type<2">
			<img src="../../assets/user/pic_pctopdaoshi@2x.png" alt="" class="auto-img" v-if="$store.state.user_type==2">
			<img src="../../assets/user/pic_pctop3zrdaoshi@2x.png" alt="" class="auto-img" v-if="$store.state.user_type==3">
		</div>
		<div class="container1" :style="(usering.user_type!==0&&usering.user_type!==1)?'height:173px':'height:130px'">
			<div style="padding-top: 20px;">
				<van-row class='rowHeader'>
					<van-col span="6">
						<van-image :src="usering.user_head_img" style="height: 3.75rem;width: 3.75rem;border-radius: 50%;" @click="tagg" round>
							<template v-slot:error>
								<img src="../../assets/user/icon_mrtx_nan@2x.png" alt="" style="width: 3.75rem;border-radius: 50%;">
							</template>
						</van-image>
					</van-col>
					<van-col span="18">
						<div class="flex" style="height: 2.6rem;">
							<span v-text='usering.user_name' class="name van-ellipsis" style="max-width: 60%"></span>
							<div class="flex" v-if="usering.user_type!==0">
								<div class="tag flex"  v-if="usering.user_type!==0" :style="$store.state.user_type==2?'color:#FCA135':$store.state.user_type==3?'color:#FF625D':''">
									<!-- <img src="../../assets/user/icon_daoshi@2x.png" alt="" class="xin" v-if="$store.state.user_type==1"> -->
									<img src="../../assets/user/icon_daoshi@2x.png" alt="" class="xin" v-if="$store.state.user_type==2">
									<img src="../../assets/user/icon_zrdaoshi@2x.png" alt="" class="xin" v-if="$store.state.user_type==3">
									<span v-text="usering.user_type==1?'学员':usering.user_type==2?'导师':usering.user_type==3?'主任导师':''" style="transform: scale(0.83);"></span>
								</div>
							</div>
						</div>
						<div class="flex">
							<div>
								ID: <span v-text="usering.client_id" style="letter-spacing: 0;"></span>
							</div>
							<div style="margin-left: 20px;color: #666;">{{ usering.user_type==2 ? usering.cert_endtime : ''}}</div>
						</div>
					</van-col>
				</van-row>
				<!-- v-if="usering.!==0&&usering.user_type!==1" -->
				<van-row style='margin-top: 20px;' v-if="usering.user_type!==0&&usering.user_type!==1">
					<van-col span="8">
						<div class="bor">
							<div class='yellow' v-text="usering.fans_num" ></div>
							<div>被关注数</div>
						</div>

					</van-col>
					<van-col span="8">
						<div class="bor">
							<div class='yellow' v-text="usering.act_count" ></div>
							<div>培训场数</div>
						</div>
					</van-col>
					<van-col span="8">
						<div style="font-size: 12px;">
							<div class='yellow' v-text="usering.student_count" ></div>
							<div>我的学员</div>
						</div>
					</van-col>
				</van-row>
			</div>
		</div>
		<div v-for="(item,i) in list" :key="i" >
			<div v-for="(items,index) in item" :key="index" @click="listClick(i,index)">
			<van-cell>
				<template slot="title">
					<div class="flex"  style="text-align: left;">
					<div class="listImg ">
						<img :src=" require('../../assets/user/'+items.icon+'.png')" alt="" class="auto-img">
					</div>
					<span class="custom-title listTitle" v-text="items.title"></span>
					</div>
				</template>
				<span v-text="items.number" class="redTag" :style="items.number>10?'padding:0 8px;':'padding:0;width:20px'" v-if="items.number"></span>
				<van-icon slot="right-icon" name="arrow" style="line-height: inherit;" />
				
			</van-cell>
			</div>
			<div @click="qincgcuClick" v-if="usering.user_id==320">
			<van-cell>
				<template slot="title">
					<div class="flex"  style="text-align: left;">
					<div class="listImg ">
						<!-- <img :src=" require('../../assets/user/'+'.png')" alt="" class="auto-img"> -->
					</div>
					<span class="custom-title listTitle"> 清除缓存</span>
					</div>
				</template>
				<!-- <span v-text="items.number" class="redTag" :style="items.number>10?'padding:0 8px;':'padding:0;width:20px'" v-if="items.number"></span> -->
				<van-icon slot="right-icon" name="arrow" style="line-height: inherit;" />
				
			</van-cell>
			</div>
			 <div style="height: 10px;"></div>
		</div>
		</transition>
	</div>
</template>

<script>
	// import vConsole from '@/util/vconsole.js'
	import { user,getLetterLists,getSign } from '@/api';
	export default {
		name: 'personalUser',
		components: {},
		data() {
			return {
				unread_num:0,
				usering:{},
				fileList:[],
				isgen:false,
				id: '2019170003542',
				grade: '导师',
				userImg:'https://img.yzcdn.cn/vant/cat.jpeg',
				// 标题
				title: '我的宝藏',
				name: '李冰',
				issud:2,
				list:[],
				// 学员
				list1:[
					[
						{
							title:'线上购书',
							icon:'icon_hstj@2x'
						},
					],
					[
							{
								title:'个人资料',
								icon:'icon_grzl@2x'
							},
							{
								title:'本人证书信息',
								icon:'icon_brzsxx@2x'
							},
							{
								title:'线上课堂',
								icon:'icon_xskt@2x'
							},
							{
								title:'知识更新',
								icon:'icon_zsgx@2x'
							},
							{
								title:'我的关注',
								icon:'icon_wdgz@2x'
							},
							{
								title:'私信',
								icon:'icon_wdsx@2x',
								number:0
							},
							{
								title:'钱包',
								icon:'icon_qb@2x'
							}
						],
					[
						{
							title:'我报名的培训',
							icon:'icon_bmpx@2x'
						},
						{
							title:'培训证书',
							icon:'icon_pxzs@2x'
						}
					],
					// [
					// 	{
					// 		title:'线上购书',
					// 		icon:'icon_hstj@2x'
					// 	}
					// ]
				],
				// 导师
				list2:[
					[
						{
							title:'线上购书',
							icon:'icon_hstj@2x'
						},
					],
					[
						{
							title:'个人资料',
							icon:'icon_grzl@2x'
						},
						{
							title:'本人证书信息',
							icon:'icon_brzsxx@2x'
						},
						{
							title:'线上课堂',
							icon:'icon_xskt@2x'
						},
						{
							title:'知识更新',
							icon:'icon_zsgx@2x'
						},
						{
							title:'我的关注',
							icon:'icon_wdgz@2x'
						},
						{
							title:'私信',
							icon:'icon_wdsx@2x',
							number:0
						},
						{
							title:'钱包',
							icon:'icon_qb@2x'
						}
					],
					[
						{
							title:'我的培训',
							icon:'icon_wdpx@2x'
						},
						{
							title:'已加入的发证机构',
							icon:'icon_yjrdfzjg@2x'
						},
						{
							title:'我的学员',
							icon:'icon_wdxy@2x'
						},
						{
							title:'培训统计',
							icon:'icon_pxtj@2x'
						}
						
					],
					[
						{
							title:'我报名的培训',
							icon:'icon_bmpx@2x'
						},
						{
							title:'培训证书',
							icon:'icon_pxzs@2x'
						}
					],
					// [
					// 	{
					// 		title:'线上购书',
					// 		icon:'icon_hstj@2x'
					// 	}
					// ]
				],
				list3:[
					[
						{
							title:'线上购书',
							icon:'icon_hstj@2x'
						},
					],
					[
						{
							title:'个人资料',
							icon:'icon_grzl@2x'
						},
						{
							title:'本人证书信息',
							icon:'icon_brzsxx@2x'
						},
						{
							title:'线上课堂',
							icon:'icon_xskt@2x'
						},
						{
							title:'知识更新',
							icon:'icon_zsgx@2x'
						},
						{
							title:'我的关注',
							icon:'icon_wdgz@2x'
						},
						{
							title:'私信',
							icon:'icon_wdsx@2x',
							number:0
						},
						{
							title:'钱包',
							icon:'icon_qb@2x'
						}
					],
					[
						{
							title:'我的培训',
							icon:'icon_wdpx@2x'
						},
						{
							title:'已加入的发证机构',
							icon:'icon_yjrdfzjg@2x'
						},
						{
							title:'我的导师班培训',
							icon:'icon_dsbpx@2x'
						},
						{
							title:'督导预约',
							icon:'icon_dudaoyuyue@2x'
						},
						{
							title:'我的学员',
							icon:'icon_wdxy@2x'
						},
						{
							title:'培训统计',
							icon:'icon_pxtj@2x'
						}
						
					],
					[
						{
							title:'我报名的培训',
							icon:'icon_bmpx@2x'
						},
						{
							title:'培训证书',
							icon:'icon_pxzs@2x'
						}
					],
					// [
					// 	{
					// 		title:'线上购书',
					// 		icon:'icon_hstj@2x'
					// 	},
					// ]
				]
			}
		},
		methods: {
			listClick(i,index){
				let title=this.list[i][index].title
						
				let user_type=JSON.parse(localStorage.getItem('user_type'))
				let user_id = localStorage.getItem('user_id')
				let userData=JSON.parse(localStorage.getItem('user_data'))
				switch (title){
					case '个人资料':
						this.$router.push({name:'user_pic_style'})
						break;
					case '线上课堂':
						
						if(userData.is_securitycode==0){
							this.$router.push({name:'onlineClassroom'})
						}else{
							this.$router.push({name:'answer',query:{user_id:user_id}})
						}
						break;
					case '我的培训':
					
						this.$router.push({name:'userTrain'})
						
						break;
					case '我报名的培训':
						this.$router.push({name:'signUpTrain'})
						break;
					case '私信':
						this.$router.push({name:'letterList',query:{unread_num:this.unread_num}})
						break;
					case '督导预约':
						this.$router.push({name:'supervisor'})
						break;
					case '已加入的发证机构':
						// if(userData.user_id==288||userData.user_id==320||userData.user_id==1002||userData.user_id==31||userData.user_id==10){
							this.$router.push({name:'mechanism'})
						// }
						break;
					case '我的关注':
						this.$router.push({name:'follow'})
						break;
						
					case '培训统计':
						if(JSON.parse(localStorage.getItem('user_type'))==2){
							this.$router.push({name:'statistics'})
						}else if(JSON.parse(localStorage.getItem('user_type'))==3){
							this.$router.push({name:'statisticsTotor'})
						}
						
						break;
					case '培训证书':
						this.$router.push({name:'certificate_train'})
						break;
					case '清除缓存':
						sessionStorage.removeItem('token')
						break;
					case '钱包':
						this.$router.push({name:'wallet'})
						break;
					case '我的学员':
						this.$router.push({name:'myTrainee'})
						break;
					case '我的导师班培训':
						this.$router.push({name:'tutorTrain'})
						break;
					case '本人证书信息':
						this.$router.push({name:'UserCertificate'})
						break;
					case '线上购书':
						if(user_type>1){
							localStorage.setItem('F5',0)
							this.$router.push({name:'GoodBook'})
						}else{
							this.$router.push({name:'goodBookDetails',query:{tips:user_id}})
						}
						break;
					case '知识更新':
						this.$router.push({name:'toUpdate'})
						break;
					default:
						break;
				}
			},
			qincgcuClick(){
				sessionStorage.removeItem('token')
			},
			  // 返回布尔值
			    beforeRead(file) {
			      if (file.type !== 'image/jpeg') {
			         console.log('请上传 jpg 格式图片');
			        return false;
			      }
			    
			      return true;
			    },
			
			    // 返回 Promise
			    asyncBeforeRead(file) {
			      return new Promise((resolve, reject) => {
			        if (file.type !== 'image/jpeg') {
			          console.log('请上传 jpg 格式图片');
			          reject();
			        } else {
			          resolve();
			        }
			      });
			    },
			open() {
				this.isopen = !this.isopen
			},
			tagg(){
				this.$router.push('user_pic_style')
			}
		},
		mounted() {
			let href = location.href.split('?')[0]
			let _this = this
			user().then(res=>{
				this.usering=res.data
				// this.usering.user_type=3
				// 列表渲染，通过身份来渲染不同列表
				switch (this.usering.user_type){
					case 0:
						this.list=this.list1
						break;
					case 1:
						this.list=this.list1
						break;
					case 2:
						this.list=this.list2
						break;
					case 3:
						this.list=this.list3
						break;
					default:
						break;
				}
				// this.list=this.usering.user_type<1?this.list1:this.usering.user_type==2?this.list2:this.list3
				localStorage.setItem('user_type',res.data.user_type)
				localStorage.setItem('user',res.data.user_name)
				localStorage.setItem('user_data',JSON.stringify(res.data) )
				this.$store.state.user_type = res.data.user_type
				this.list[0][5].number=res.data.unread_num
				this.unread_num=res.data.unread_num
			})
			
			document.title = this.title;
			
			// this.$wx.ready(res => {  // 微信SDK准备就绪后执行的回调。
			// 	this.$wx.onMenuShareAppMessage({
			// 		title: '现场急救行动指南', // 分享标题
			// 		desc: '现场急救行动指南', // 分享描述
			// 		link: 'https://xcjj.tysos.cn/personalUser', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			// 		imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
			// 		type: 'link', // 分享类型,music、video或link，不填默认为link
			// 		dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
			// 		success: function () {
			// 		// 用户点击了分享后执行的回调函数
			// 		}
			// 	});
			// 	this.$wx.updateAppMessageShareData({
			// 		title: '现场急救行动指南', // 分享标题
			// 		desc: 'updateAppMessageShareData', // 分享描述
			// 		link: 'https://xcjj.tysos.cn/onlineClassroom_gen', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			// 		imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
			// 		success: function(resq) {
			// 			// 设置成功
			// 			alert('设置成功')
			// 		},
			// 		error: function(resw) {
			// 			alert(JSON.stringify(resw))
			// 		}
			// 	})
			// 	// 分享朋友圈
			// 	this.$wx.updateTimelineShareData({
			// 		title: '现场急救行动指南', // 分享标题
			// 		link:'https://xcjj.tysos.cn', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			// 		imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
			// 		success: function() {
			// 			// 设置成功
			// 			alert('设置成功')
			// 		}
			// 	});
			// });
			
		}
	}
</script>

<style lang="scss" scoped>
	
	
	.personalUser {
		.van-image__error,
		.van-image__loading {
			top: 6px;
			border-radius: 50%;
		}
		.van-cell{
			padding: 20px 16px;
		}
		.rowHeader img{
			border-radius: 50%;
		}
		.van-image__img{
			border-radius: 50%;
		}
		.maskMin{
			position: fixed;
			top: 0;
			left: 0;
			background-color: #000000;
			width: 100%;
			height: 100%;
			.maskMinImg{
				height: calc(100% - 82px);
				position: relative;
				img{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}
				
			}
			div.btnBox{
				height: 5.125rem;
				width: 100%;
				background: #fff;
				position: fixed;
				bottom: 0;
				div.btn{
					
					height: 2.625rem;
					width: 80%;
					border-radius: 30px;
					color: #fff;
					line-height:2.625rem ;
					text-align: center;
					background: #0081FF;
					    position: absolute;
					    top: 50%;
					    left: 50%;
					    transform: translate(-50%,-50%);
				}
			}
		}
		.redTag{
			// min-width: 30px;
			color: #fff;
			// padding: .2em .5em;
			background: #FF524D;
			border-radius: 10px;
			display: inline-block;
			height: 20px;
			text-align: center;
			line-height: 22px;
			margin-right: 5px;
			
			
		}
		.listImg{
			width: 1.5625rem;
			height: 1.5625rem;
			margin-right: 1rem;
		}
		.listTitle{
			font-size: 0.9375rem;
		}
		.bor {
			position: relative;
			font-size: 12px;

			&::after {
				content: '';
				display: block;
				width: 1px;
				height: 1.25rem;
				background-color: #E5E5E5;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}
		}

		.yellow {
			color: #FED200;
			font-size: 1.125rem;
		}

		.name {
			color: #28334A;
			font-weight: bold;
			margin-right: 0.9375rem;
			font-size: 1.125rem;
		}

		.imgBox {
			width: 100%;
			position: absolute;
			top: 0;
			z-index: -1;
			height: 150px;
		}

		.container1 {
			width: 92%;
			height: 11rem;
			margin: 1.25rem auto 0.625rem;
			background-color: #fff;
			border-radius: 0.375rem;


		}

		.tag {
			// width: 3.5rem;
			height: 1.25rem;
			color: #0081FF;
			background: #F7F8FF;
			border-radius: 0.625rem;
			text-align: center;
			line-height: 1.25rem;
			margin-right: 0.625rem;
			justify-content: space-evenly;
			padding: 0 5px;

			.xin {
				width: 0.75rem;
				height: 0.75rem;
				margin-right: 5px;
			}
		}
	}
</style>
