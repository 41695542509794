<template>
	<div class="courseDetails">
		<div class="conten " id="courseDetails">
			<div v-text="activityContent.act_name" class="title"></div>
			<van-row class="header">
				<van-col span="8">
					<!-- <div v-text="activityContent.act_start_time"></div> -->
					<div v-text="activityContent.createtime"></div>
				</van-col>
				<van-col span="6">
					<div v-text="activityContent.act_author" style="font-size: 16px;text-align: center;"></div>
				</van-col>
				<van-col span="10">
					<!-- <div class="pt">现场急救行动指南</div> -->
				</van-col>
			</van-row>
			<div style="			font-size: 13px;">
				<van-row style="margin-top: 15px;border-bottom:1px solid #ddd;padding-bottom: 10px ;">
					<van-col span="9">
						<div>培训开始时间：</div>
					</van-col>
					<van-col span="15">
						<div v-text="activityContent.act_start_time"></div>
					</van-col>
				</van-row>
				<!-- <van-row style="margin-top: 10px;border-bottom:1px solid #ddd;padding-bottom: 10px ;" v-if="activityContent.act_type==1">
					<van-col span="9">
						<div>考核开始时间：</div>
					</van-col>
					<van-col span="15">
						<div v-text="activityContent.check_start_time"></div>
					</van-col>
				</van-row> -->
				<van-row style="margin-top: 10px;border-bottom:1px solid #ddd;padding-bottom: 10px ;">
					<van-col span="9">
						<div>培训结束时间：</div>
					</van-col>
					<van-col span="15">
						<div v-text="activityContent.act_end_time"></div>
					</van-col>
				</van-row>
				<van-row style="margin-top: 10px;border-bottom:1px solid #ddd;padding-bottom: 10px ;" v-if="activityContent.org_name">
					<van-col span="9">
						<div>承办机构：</div>
					</van-col>
					<van-col span="15">
						<div v-text="activityContent.org_name"></div>
					</van-col>
				</van-row>
				<van-row style="margin-top: 10px;border-bottom:1px solid #ddd;padding-bottom: 10px ;">
					<van-col span="9">
						<div>培训地点：</div>
					</van-col>
					<!-- <van-col span="15" @click="sweatClick"> -->
					<van-col span="15" @click="scanQRCodeClick">
						<div v-text="activityContent.act_addr"></div>
					</van-col>
				</van-row>
				<van-row style="margin-top: 10px;border-bottom:1px solid #ddd;padding-bottom: 10px ;">
					<van-col span="9">
						<div>报名费用：</div>
					</van-col>
					<!-- <van-col span="15" @click="sweatClick"> -->
					<van-col span="15" @click="scanQRCodeClick">
						<div v-text="activityContent.cost+'元'"></div>
					</van-col>
				</van-row>
				<van-row style="margin-top: 10px;border-bottom:1px solid #ddd;padding-bottom: 10px ;">
					<van-col span="11">
						<div>学员最大报名人数：</div>
					</van-col>
					<van-col span="13" class="flex">

						<div v-text="
							activityContent.limit_num == -1
							  ? '不限制'
							  : activityContent.limit_num
						  "></div>
						<div style="margin-left: 10px;">
							已报名：<span v-text="activityContent.signup_num"></span>人
						</div>
					</van-col>
				</van-row>
				<van-row style="margin-top: 10px;border-bottom:1px solid #ddd;padding-bottom: 10px ;">
					<van-col span="11">
						<div>助教导师最大报名人数：</div>
					</van-col>
					<van-col span="13" class="flex">

						<div v-text="
							activityContent.limit_signup_tutor_num == -1
							  ? '不限制'
							  : activityContent.limit_signup_tutor_num
						  "></div>
						<div style="margin-left: 10px;">
							已报名：<span v-text="activityContent.signup_tutor_num"></span>人
						</div>
					</van-col>
				</van-row>
				<van-row style="margin-top: 10px;border-bottom:1px solid #ddd;padding-bottom: 10px ;">
					<van-col span="9">
						<div>备注：</div>
					</van-col>
					<van-col span="15">
						<div v-text="activityContent.act_remark"></div>
					</van-col>
				</van-row>
				<van-row style="margin-top: 10px;border-bottom:1px solid #ddd;padding-bottom: 10px ;">
					<van-col span="9">
						<div>截止报名日期：</div>
					</van-col>
					<van-col span="15">
						<div v-text="activityContent.deadline_time"></div>
					</van-col>
				</van-row>
			</div>
		</div>
		<div>
			<div v-if="activityContent && activityContent.scene_image && activityContent.scene_image.length > 0">

				<img :src="activityContent.scene_image[0]" style="height: 100%;width: 100%;" />
				<img :src="activityContent.scene_image[1]" style="height: 100%;width: 100%;" />
				<img :src="activityContent.scene_image[2]" style="height: 100%;width: 100%;" />
			</div>
			<longImg @goAnchor="goAnchor" v-else :train_course="train_course" :act_type="act_type" :list="list"></longImg>
		</div>

		<div class="commentBox">
			<div class="comment">
				评价详情
			</div>
			<div v-if="!commentList.length" style="text-align: center;padding-bottom: 130px;">
				<img src="@/assets/default/icon_zanwusx@2x.png" alt="" class="default_img" style="width: 150px;height: 150px;" />
				<p>暂无评论</p>
			</div>
			<div class="commentlist">
				<div class="" style="display: flex;padding: 20px 0;border-bottom: 1px solid #E5E5E5;" v-for="(item, i) in commentList"
				 :key="i">
					<div>
						<img :src="item.user_head_img" alt="" style="border-radius: 50%;width: 32px;margin-right: 8.5px;" v-if="item.is_anonymous==0" />
						<img :src="userNi" alt="" style="border-radius: 50%;width: 32px;margin-right: 8.5px;" v-if="item.is_anonymous==1" />
					</div>
					<div>
						<div v-text="item.is_anonymous==1?'匿名用户':item.user_name" style="font-weight: bold;"></div>

						<div style="font-size: 11px;margin: 8px 0 8px;color: #919CB0;" v-text="item.createtime"></div>
						<p style="font-size: 14px;color: #767E8D;letter-spacing: 0;" v-text="item.content"></p>
					</div>
				</div>
			</div>
		</div>
		<!-- 没有报名的状态 -->
		<!-- v-if="is_signup==1" -->
		<div v-if="!activityContent.scene_image" >
			<div class="bottom" :style="
					activityContent.act_status == 1
						? 'background:#fff'
						: 'background:#F4F4F4'
				"
			v-if="
					activityContent.is_signup == 0 || activityContent.is_signup_tutor == 0
				">
				<!-- activityType 活动的状态 -->
				<div v-text="
					activityContent.act_status == 1
						? '申请助教导师报名'
						: activityContent.act_status == 2
						? ''
						: activityContent.act_status == 3
						? '活动已下线': activityContent.act_status == 4
						?'课程精彩反馈': activityContent.act_status == -1
						?'我要报名': ''
					"
				:class="activityContent.act_status <2?'btn':'btn1'" @click="btnClick" v-if="user_type > 1 && activityContent.act_status != 2" style="padding: 0;"></div>
				<div v-text="
						activityContent.act_status ==1 
						? '我要报名'
						: activityContent.act_status == 2
						? ''
						: activityContent.act_status == 3
						? '活动已下线': activityContent.act_status == 4
						?'课程精彩反馈': activityContent.act_status == -1
						?'我要报名': ''
					"
				:class="activityContent.act_status ==1 ? 'btn' :activityContent.act_status ==2||activityContent.act_status ==4? 'btn1':activityContent.act_status ==-1?'btn hui':''"
				@click="btnClick" v-if="user_type <= 1 && activityContent.act_status != 2" style="padding: 0;"></div>
			</div>
			<!-- 报名后的状态 -->
			<div class="bottom" :style="
					activityContent.act_status == 1
						? 'background:#fff'
						: 'background:#F4F4F4'
				"
			v-else>
				<!-- activityType 活动的状态 -->
				<div v-text="
						activityContent.act_status < 2
							? '申请助教导师报名成功'
							: activityContent.act_status == 2
							? ''
							: activityContent.act_status == 3
							? '活动已下线': '课程精彩反馈'
					"
				:class="activityContent.act_status < 2 ? 'btn2' : 'btn1'" @click="btnClick" v-if="user_type > 1 && activityContent.act_status != 2" style="padding: 0;"></div>
				<div v-text="
						activityContent.act_status < 2
							? '报名成功'
							: activityContent.act_status == 2
							? ''
							: activityContent.act_status == 3
							? '活动已下线'
							: '课程精彩反馈'
					"
				:class="activityContent.act_status < 2 ? 'btn2' : 'btn1'" @click="btnClick" v-if="user_type <= 1 && activityContent.act_status != 2" style="padding: 0;"></div>
			</div>
		</div>

		<van-popup v-model="show" closeable position="bottom" :style="{ height: '66vh' }">
			<div class="popupConten">
				<div class="header" style="padding-bottom: 5px;">请输入报名信息</div>

				<div class="form" style="height:calc(60vh - 30px - 52px ) ;overflow: auto;padding: 0 15px;">
					<van-row class="row">
						<van-col span="5">
							<div class="left">
								真实姓名
							</div>
						</van-col>
						<van-col span="19">
							<input type="text" placeholder="请输入姓名" v-model="activity.sign_name" />
						</van-col>
					</van-row>
					<van-row class="row" stylem="margin:10px 0">
						<van-col span="5">
							<div class="left">
								性别
							</div>
						</van-col>
						<van-col span="19">
							<van-radio-group v-model="activity.sex" style="display: flex;height: 36px;">
								<van-radio name="1" style="margin-right: 30px;">
									男
									<img slot="icon" slot-scope="props" v-bind:src="props.checked ? activeIcon : inactiveIcon" style="width:20px;height:20px;vertical-align : top;" />
								</van-radio>
								<van-radio name="2">
									女
									<img slot="icon" slot-scope="props" :src="props.checked ? activeIcon1 : inactiveIcon1" style="width:20px;height:20px;vertical-align : top;" />
								</van-radio>
							</van-radio-group>
						</van-col>
					</van-row>
					<van-row class="row">
						<van-col span="5">
							<div class="left">
								年龄段
							</div>
						</van-col>
						<van-col span="19" @click="showPicker = true">
							<!-- <input type="text" placeholder="请选择" v-model="" disabled="false"> -->
							<div class="flex" style="justify-content: space-between;background-color: #F4F4F4;height: 36px;line-height: 36px;padding:0 10px;border-radius: 6px;">
								<div v-text="activity.age ? activity.age : '请选择'" :class="activity.age ? '' : 'huiColor'"></div>
								<img src="@/assets/Train/icon_xiala_nor@2x.png" alt="" style="width: 16px;height: 10px;" />
							</div>
						</van-col>
					</van-row>
					<van-row class="row">
						<van-col span="5">
							<div class="left">
								手机号码
							</div>
						</van-col>
						<van-col span="19">
							<input type="text" placeholder="请输入常用手机号" v-model="activity.sign_phone" />
						</van-col>
					</van-row>
					<!-- <van-row class="row">
						<van-col span="5">
							<div class="left" style="line-height: inherit;">
								收件地址
							</div>
						</van-col>
						<van-col span="19">
							<div class="flex">
								<div class="btn" @click="provinceClick">
									<span v-text="train_add.province.name"></span>
									<img src="@/assets/Train/icon_xiala_nor@2x.png" alt="" v-if="isAreaList !== '城市'" style="height: 20px;width: 20px;" />
									<img src="@/assets/Train/icon_xiala_pre@2x.png" alt="" v-if="isAreaList == '城市'" style="height: 20px;width: 20px;" />
								</div>
								<div class="btn" @click="provinceClick">
									<span v-text="train_add.city.name"></span>
									<img src="@/assets/Train/icon_xiala_nor@2x.png" alt="" v-if="isAreaList !== '城市'" style="height: 20px;width: 20px;" />
									<img src="@/assets/Train/icon_xiala_pre@2x.png" alt="" v-if="isAreaList == '城市'" style="height: 20px;width: 20px;" />
								</div>
								<div class="btn" @click="provinceClick">
									<span v-text="train_add.area.name"></span>
									<img src="@/assets/Train/icon_xiala_nor@2x.png" alt="" v-if="isAreaList !== '城市'" style="height: 20px;width: 20px;" />
									<img src="@/assets/Train/icon_xiala_pre@2x.png" alt="" v-if="isAreaList == '城市'" style="height: 20px;width: 20px;" />
								</div>
							</div>
							<div style="margin-top: 12px;">
								<input type="text" placeholder="详细地址（精确到门牌号）" v-model="activity.address" style="background-color: #fff;padding: 0;" />
							</div>
							<van-divider />
							<div style="margin-top: -10px;">
								<span style="font-size: 12px;">
									*
									报名成功后，将会将教材课本寄给您，教材课本用于培训前学习和培训时使用。
								</span>
							</div>
						</van-col>
					</van-row> -->
					<!-- <van-row class="row">
						<van-col span="6">
							<div class="left" style="line-height: inherit;">
								培训班类型
							</div>
						</van-col>
						<van-col span="18">
							<van-radio-group v-model="activity.train_type">
								<van-radio name="1" style="align-items: normal;">
									<div class="huiColor Small">
										全天班
									</div>
									<div class="huiColor Small">
										(导师教学+考证)
									</div>
								</van-radio>
								<van-radio name="2" style="align-items: normal;margin-top: 13px;">
									<div class="huiColor Small">
										考证班
									</div>
									<div class="huiColor Small"> -->
					<!-- (课程知识和技能操作需自行提前用教材学习，并且需要在线上课堂参加答题，答题合格通过方可直接考证。线上课堂入口：公众号首页
										>急救广场>急救大汇
										>线上课堂) -->
					<!--</div>
								</van-radio>
							</van-radio-group>
						</van-col>
					</van-row> -->
					<van-row class="row" style="margin-bottom: 0;">
						<van-col span="5">
							<div class="left" style="line-height: inherit;">
								报名费用
							</div>
						</van-col>
						<van-col span="19">
							<div class="huiColor" v-text="activityContent.cost + '元'"></div>
							<!-- <div class="Small">
								* 报名成功后，无法取消报名，报排名费用也无法退回，请知悉
							</div> -->
						</van-col>
					</van-row>
				</div>
				<!-- <div v-if="!activityContent.IsCost" style="
				width:95%;
				margin: 0 auto 20px;
				height:1px;
				background:rgba(229,229,229,1);
				"></div>
				<div class="formBtn" v-if="!activityContent.IsCost">
					<div class="btn" @click="formClick" style="padding: 0;">
						提交
					</div>
				</div>
				<div v-if="activityContent.IsCost"  style="
				width:95%;
				margin: 0 auto 20px;
				height:1px;
				background:rgba(229,229,229,1);
				"></div>
				<div class="formBtn" v-if="activityContent.IsCost">
					<div class="btn" @click="formClick" style="padding: 0;">
						提交
					</div>
				</div> -->

				<div style="
				width:95%;
				margin: 0 auto 20px;
				height:1px;
				background:rgba(229,229,229,1);
				"></div>
				<div class="formBtn">
					<div class="btn" @click="formClick" style="padding: 0;">
						提交
					</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="show1" closeable position="bottom" :style="{ height: '95%' }">
			<div class="popupConten">
				<div class="header" style="">申请助教导师报名</div>
				<div class="content">
					<div style="font-weight: bold;margin: 28px 0 28px;">说明</div>
					<p>1、为保证培训质量，在一场培训中，主讲和助教导师最多负责6位学员；</p>
					<p>
						2、导师申请助教导师报名后，不等于立即成为本场培训的助教导师，需看学员报名情况来安排；安排原则：先报名先安排；
					</p>
					<p>
						3、报名的学员人数少于等于6位的时候，报名的助教导师不需要到达现场，培训全部由主讲导师负责；报名人数超过6位的时候才需要安排助教导师；
					</p>
					<p>
						4、报名学员超过6位的时候，前6位学员由主讲导师负责剩下的学员由助教导师负责，全部的学员人数小于等于12，只需要一位助教导师，全部的学员人数小于等于18，只需要两位助教导师，以此类推；全部的报名学员减去主讲负责的6位学员，剩下的学员由助教导师平均分配负责，不平均的个别学员由先报名的助教导师负责；
					</p>
					<p>
						5、助教导师报名申请后，导师因为特殊原因不能参加，可以取消或找替换的助教导师导师，条件如下：如果实际需要的助教导师人数没达到最大的助教导师报名人数，可以取消助教导师申请；如果实际需要的助教导师人数达到了最大的助教导师报名人数，则不能取消申请，只能自己找到替换的助教导师导师，替换自己的位置；
					</p>
					<div style="padding: 20px 0;border-top: 1px solid #E5E5E5;">
						<div class=" tjBtn" style="" @click="postSignupTutorClick">
							提交
						</div>
					</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="isAreaList" position="bottom">
			<div style="width: 100%;">
				<van-area :area-list="areaList" :columns-num="3" title="地址" @confirm="confirm" @cancel="cancel" />
			</div>
		</van-popup>
		<van-popup v-model="showPicker" position="bottom">
			<van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
		</van-popup>
		<van-popup v-model="isQualified" position="bottom" :style="{ height: 'auto' }" closeable>
			<div class="qualifiedBox">
				<div class="title"> 提示 </div>
				<div class="qualifiedtext">
					<span style="font-weight: bold;margin-top: 25px;font-size: 15px;">您未获得<span v-text="train_courseID==1?'心肺复苏术及自动体外除颤仪使用课程':train_courseID==2?'急症急救及创伤救护课程':train_courseID==3?'心肺复苏术及自动体外除颤仪使用课程和急症急救及创伤救护课程':''"></span>的《现场急救线上教学课程合格证》，暂时未能报名，获得该合格证的流程是：</span>
					<p>1、购买教材书籍：《现场急救行动指南》；</p>
					<p>2、在[现场急救行动指南]微信公众号主页，点击"我的宝藏"页面，点击打开线上课堂，扫描或输入《现场急救行动指南》书本封底左上角粘贴的数字激活码，激活现场课程功能；</p>
					<p>3、输入激活码激活线上课堂功能后，在线上课堂观看教学视频并答题，全部试题答题正确率达到80%以上即可获得<span v-text="train_courseID==1?'心肺复苏术及自动体外除颤仪使用课程':train_courseID==2?'急症急救及创伤救护课程':train_courseID==3?'心肺复苏术及自动体外除颤仪使用课程和急症急救及创伤救护课程':''"></span>的《现场急救线上教学课程合格证》；</p>
					<div class="" style="display: flex;margin-top: 25px;">
						<div>
							<img src="../../assets/icon_tips_red@2x.png" alt="" style="width: 12px;height: 12px;margin-right: 5px;">

						</div>
						<div style="font-size: 12px;text-align: left;width: calc(100% - 19px);">获得<span v-text="train_courseID==1?'心肺复苏术及自动体外除颤仪使用课程':train_courseID==2?'急症急救及创伤救护课程':train_courseID==3?'心肺复苏术及自动体外除颤仪使用课程和急症急救及创伤救护课程':''"></span>的《现场急救线上教学课程合格证》后的2个月内都可以报名当前课程</div>
					</div>
				</div>

				<div class="qualifiedBtn">
					<div class="qualifiedBtn_btn" @click="$router.push({name:'goodBookDetails'})">购买教材</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="isQualified1" :style="{ width:'80%' , borderRadius:'6px'}">
			<div class="qualifiedBox" style="padding: 22px 20px;">
				<div class="title" style="margin-bottom: 22px 0;"> 提示 </div>

				<div class="qualifiedtext" style="line-height: 26px;" v-if="qualifiedtext==1">
					您<span v-text="train_courseID==1?'心肺复苏术及自动体外除颤仪使用课程':train_courseID==2?'急症急救及创伤救护课程':train_courseID==3?'心肺复苏术及自动体外除颤仪使用课程和急症急救及创伤救护课程':''"></span>
					的《现场急救线上教学课程合格证》 已过期失效,请重新在线上课堂学习并答 题,获得合格证后方可报名当前课程。
				</div>
				<div class="qualifiedtext" v-if="qualifiedtext==2">
					您上一次在线上课堂学习答题到现在已超过两个月，为保障您的培训质量，请重新在线上课堂观看教学视频并答题，全部试题答题正确率达到80%以上方可报名当前课程。
				</div>
				<div class="qualifiedBtn">
					<div class="que" style="" @click="isQualified1=false">确定</div>

				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	// import vConsole from '@/util/vconsole.js'
	import {unique} from '@/util/unique.js'
	import {
		Toast
	} from "vant";
	import longImg from "@/components/long-img/index.vue";
	import {
		dateFormat
	} from '@/util/setTime.js'
	import {
		getActivity_t,
		getActivity_is,
		postSignup,
		postSignupTutor,
		getActivityCommentLists,
		getActivityCanSignup,
		getSign
	} from "@/api";
	import areaList from "@/area.js";

	export default {
		name: "tutorHomepage",
		components: {
			longImg
		},
		data() {
			return {
				train_courseID: 0,
				userNi: require("@/assets/icon_niming@2x.png"),
				list: [],
				act_type: 1,
				train_course: [],
				qualifiedtext: 2,
				isQualified1: false,
				isQualified: false,
				date1: '',
				iscommentListLength: false,
				commentList: [],
				// 助教导师报名
				show1: false,
				is_signup: 0,
				// 报名费用
				Cost: 300,
				// 是否已报名
				issignUp: false,
				// 身份
				user_type: 0,
				laoRadio: "1",
				// 详细地址
				acct: "",
				areaList: areaList,
				train_add: {
					province: {
						name: "广东",
						code: "440000"
					},
					city: {
						name: "广州",
						code: "440100"
					},
					area: {
						name: "天河区",
						code: "440106"
					},
					isMust: true
				},
				isAreaList: false,
				value: "",
				showPicker: false,
				columns: [
					"0-8",
					"8-16",
					"17-24",
					"25-32",
					"33-40",
					"41-48",
					"48-56",
					"57-64",
					"65+"
				],
				// 学员报名
				show: false,
				radio: "1",
				activeIcon: require("@/assets/Train/icon_nan_pre@2x.png"),
				inactiveIcon: require("@/assets/Train/icon_nan_nor@2x.png"),
				activeIcon1: require("@/assets/Train/icon_nv_pre@2x.png"),
				inactiveIcon1: require("@/assets/Train/icon_nv_nor@2x.png"),
				// 提交
				activity: {
					sign_name: "",
					sex: "",
					age: "",
					sign_phone: "",
					address: "",
					// 详细地址
					provinceid: "440000",
					// 省份id
					cityid: "440100",
					// 城市id
					areaid: "440106",
					// 区域id
					act_id: "",
					// 活动id
					train_type: "1"
					// 培训班类型
				},
				// 活动状态
				/**
				 * 1:未报名
				 * 2.已报名
				 * 3.活动结束 课程精彩反馈
				 * */
				activityType: 1,
				title: "培训课程详情",
				name: "120急救培训",
				time: "2019-01-05",
				teacher: "我是老师",
				platform: "互救吧平台",
				requestIds: "",
				act_author: "发起者",
				activityContent: {},
				list1: [
					'线上教学视频答疑',
					'急症急救及创伤救护理论知识重点讲解',
					'止血术',
					'包扎术',
					'固定术',
					'搬运术',
					'创伤救护流程',
					'课程考核'

				],
				list2: [
					'线上教学视频答疑',
					'心肺复苏和自动体外除颤仪理论知识重点讲解',
					'成人心肺复苏术',
					'儿童心肺复苏术',
					'婴儿心肺复苏术',
					'自动体外除颤仪使用术',
					'气道异物梗阻解救术',
					'课程考核'
				],
				list3: [
					'线上教学视频答疑',
					'心肺复苏和自动体外除颤仪理论知识重点讲解',
					'成人心肺复苏术',
					'儿童心肺复苏术',
					'婴儿心肺复苏术',
					'自动体外除颤仪使用术',
					'气道异物梗阻解救术',
					'急症急救及创伤救护理论知识重点讲解',
					'止血术',
					'包扎术',
					'固定术',
					'搬运术',
					'创伤救护流程',
					'课程考核'
				],
				list4: [
					'项目介绍',
					'项目行政管理',
					'教学技巧',
					'教学工具',
					'线上教学视频答疑',
					'课程考核'
				],
				list5: [
					'项目介绍',
					'主任导师项目行政管理',
					'导师培训技巧',
					'教学工具使用',
					'课程考核'
				],
			};
		},
		methods: {
			dateFormat(fmt, date) {
				return dateFormat(fmt, date)
			},
			// 导航地址
			sweatClick() {
				let _this = this
				if (this.activityContent.longitude) {

					var latitude = parseFloat(this.activityContent.latitude); //纬度
					var longitude = parseFloat(this.activityContent.longitude); //经度
					console.log(latitude, longitude);
					_this.$wx.ready(resx => { // 微信SDK准备就绪后执行的回调。

						_this.$wx.openLocation({
							latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
							longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
							name: '培训地点', // 位置名
							address: _this.activityContent.act_addr, // 地址详情说明
							scale: 25, // 地图缩放级别,整形值,范围从1~28。默认为最大
							infoUrl: 'http://www.baidu.com' // 在查看位置界面底部显示的超链接,可点击跳转
						});
					});
				}
			},
			//当前时间加3天之前的时间
			getThreeDaysBeforeDate(data) {
				// 解决ios中不支持y-m-d 转换形式 。更改为 y/m/d形式
				var format = data.replace(/-/g, '/')
				var date = new Date(format);
				this.date1 = date
				var timestamp = date.getTime();
				// 获取三天前的日期
				var newDate = new Date(timestamp - 3 * 24 * 3600 * 1000);
				var year = newDate.getFullYear()
				var month = newDate.getMonth() + 1;
				var day = newDate.getDate();
				var hours = newDate.getHours();
				var minutes = newDate.getMinutes();
				var mm = "'" + month + "'";
				var dd = "'" + day + "'";
				if (day < 10) { // 如果日小于10，前面拼接0
					day = '0' + day;
				}
				if (month < 10) { // 如果月小于10，前面拼接0
					month = '0' + month;
				}
				var time = date.getFullYear() + "-" + month + "-" + day + " " + hours + ":" + minutes + ":00";
				return time;
			},
			postSignupTutorClick() {
				postSignupTutor({
					act_id: this.$route.query.act_id
				}).then(res => {
					Toast(res.msg);
					if (res.code == 1) {
						this.activityContent.is_signup_tutor = 1;
						this.show1 = false;
					}
				});
			},
			btnClick() {

				// 有活动状态来判断入口
				/**
				 * this.activityContent.act_status 活动状态，1报名中，2已结束，3已下线 4已反馈
				 * user_type 用户类型 0：普通用户，1：学员，2：导师，3：主任导师
				 * 用户为0和1可以参加报名。用户为2和3报名助教导师
				 * */
				//  if(this.activityContent.act_status==-1){ 
				// 	 Toast('报名时间已截止') 
				// return
				// } 
				if (this.activityContent.act_status == 2) {
					return
				}
				if (
					this.activityContent.is_signup == 1 ||
					this.activityContent.is_signup_tutor == 1
				) {
					return;
				} else {
					// 判断学员身份，助教导师或者导师
					if (this.user_type > 1) {
						// 报名助教导师
						// ：1报名中，2已结束，3已下线 4已反馈
						switch (this.activityContent.act_status) {
							case 1:
								this.show1 = true;
								break;
							case 2:
								break;
							case 3:
								break;
							case 4:
								this.$router.push({
									name: "lookFeedbackFrom",
									query: {
										act_id: this.$route.query.act_id
									}
								});
								break;
							default:
								break;
						}
					} else {
						switch (this.activityContent.act_status) {
							case 1:
								/**
								 * 1没有证书
								 * 2有证书已过期
								 * 3有证书超两个月
								 * 
								 * */
								console.log(this.activityContent)
								if (this.activityContent.act_type == 2) {
									this.show = true;
								} else if (this.activityContent.act_type == 1) {
									let arr = this.train_course.length == 2 ? [1, 2] : this.train_course[0] == 2 ? 1 : this.train_course[0] == 3 ?
										2 : this.train_course[0] == 4 ? '' : this.train_course[0] == 5 ? '' : ''
									console.log(arr)
									if (this.train_course.length > 1) {

										getActivityCanSignup({
											type: 1,
											cfrom: 'signup'
										}).then(res => {
											this.train_courseID = 3
											switch (res.data.status) {
												case 1:
													getActivityCanSignup({
														type: 2,
														cfrom: 'signup'
													}).then(res => {
														this.train_courseID = 3
														console.log(res.data.status)
														switch (res.data.status) {
															case 1:
																this.show = true;
																break;
															case 2:
																this.isQualified = true

																break;
															case 3:
																this.isQualified1 = true
																this.qualifiedtext = 1
																break;
															case 4:
																this.isQualified1 = true
																this.qualifiedtext = 2
																break;
															case 5:
																this.isQualified = true
																break;
														}
													})
													break;
												case 2:
													this.isQualified = true
													break;
												case 3:
													this.isQualified1 = true
													this.qualifiedtext = 1
													break;
												case 4:
													this.isQualified1 = true
													this.qualifiedtext = 2
													break;
												case 5:
													this.isQualified = true
													break;
											}
										})
										console.log(this.train_courseID)
									} else {
										getActivityCanSignup({
											type: arr,
											cfrom: 'signup'
										}).then(res => {
											this.train_courseID = arr
											console.log(res.data.status)
											switch (res.data.status) {
												case 1:
													this.show = true;
													break;
												case 2:
													this.isQualified = true

													break;
												case 3:
													this.isQualified1 = true
													this.qualifiedtext = 1
													break;
												case 4:
													this.isQualified1 = true
													this.qualifiedtext = 2
													break;
												case 5:
													this.isQualified = true
													break;
											}
										})
									}
								}


								// getActivityCanSignup().then(ress=>{
								// 	switch (ress.data.status){
								// 		case 1:
								// 			this.show = true;

								// 			break;
								// 		case 3:
								// 			this.isQualified1=true
								// 			this.qualifiedtext=1
								// 			break;
								// 		case 5:
								// 			this.isQualified1=true
								// 			this.qualifiedtext=2
								// 			break;
								// 	}

								// })
								break;
							case 2:
								break;
							case 3:
								break;
							case 4:
								this.$router.push({
									name: "lookFeedbackFrom",
									query: {
										act_id: this.$route.query.act_id
									}
								});
								break;
							default:
								break;
						}
					}
				}
			},
			provinceClick() {
				this.isAreaList = true;
			},
			cancel() {
				console.log("222");
				this.isAreaList = false;
			},
			confirm(res) {
				console.log(res);

				this.activity.provinceid = res[0].code;
				this.activity.cityid = res[1].code;
				this.activity.areaid = res[2].code;
				this.train_add.province = res[0];
				this.train_add.city = res[1];
				this.train_add.area = res[2];
				this.isAreaList = false;
			},
			// 提交数据
			formClick() {
				
				if (
					this.activity.sign_name &&
					this.activity.sex &&
					this.activity.age &&
					this.activity.sign_phone &&
					this.activity.train_type
					// 培训班类型
				) {
					this.$Toast.loading({
						message: '报名中...',
						forbidClick: true,
						mask: true,
						forbidClick: false,
						duration: 0
					});
					if (this.activityContent.IsCost) {
						
						postSignup(this.activity).then(res => {
							Toast(res.msg);
							if (res.code == 1) {
								// this.$Toast.clear()
								this.activityContent.is_signup = 1;
								this.$router.push({
									name: "ApplicationDet",
									query: {
										signin_id: res.data.signin_id,
										place: this.activityContent.org_name,
										keName: this.activityContent.act_name,
										time: this.activityContent.act_start_time,
										site: this.activityContent.act_addr,
										cost: this.activityContent.cost,
										act_id: this.$route.query.act_id
									}
								});
							}
						});
					} else {
						postSignup(this.activity).then(res => {
							Toast(res.msg);
							if (res.code == 1) {
								this.activityContent.is_signup = 1;
								let arr = {
									name: this.activity.sign_name,
									phone: this.activity.sign_phone,
									img: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/act_default.png',
									place: this.activityContent.org_name,
									keName: this.activityContent.act_name,
									time: this.activityContent.act_start_time,
									site: this.activityContent.act_addr,
									cost: this.activityContent.cost
								}
								localStorage.setItem('arr', JSON.stringify(arr))
								this.$router.push({
									name: 'signSuccess'
								})

							}
						});
					}
				} else {
					Toast('完善必填项');

				}
			},
			// 现在年龄段
			onConfirm(value) {
				this.activity.age = value;
				this.showPicker = false;
			},
			// 点击跳转锚点
			goAnchor(obj) {
				console.log(this.$el.querySelector(`#${obj.idName}`));
				this.$el.querySelector(`#${obj.idName}`).scrollIntoView();
				// document.querySelector(".courseDetails").scrollTop = 0
			},
			// 打开地点
			scanQRCodeClick() {
				let _this = this
				//  location.href
				if (this.activityContent.longitude) {
					getSign({
							page_url: location.href
						})
						.then(res => {
							if (res.data.appId) {
								this.$wx.config({
									debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
									appId: res.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
									timestamp: res.data.timestamp, // 必填，生成签名的时间戳
									nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
									signature: res.data.signature, // 必填，签名，见附录1
									jsApiList: ['openLocation', 'getLocation'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
								});


								var latitude = parseFloat(this.activityContent.latitude); //纬度
								var longitude = parseFloat(this.activityContent.longitude); //经度
								//      	alert(typeof latitude);
								// alert(typeof longitude);
								this.$wx.ready(resx => { // 微信SDK准备就绪后执行的回调。
									this.$wx.openLocation({
										latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
										longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
										name: '培训地点', // 位置名
										address: this.activityContent.act_addr, // 地址详情说明
										scale: 25, // 地图缩放级别,整形值,范围从1~28。默认为最大
										infoUrl: 'http://www.baidu.com' // 在查看位置界面底部显示的超链接,可点击跳转
									});

									this.$wx.getLocation({
										type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
										success: function(res) {
											// alert(JSON.stringify(res));
											// var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
											// // $("#latitude").val(latitude);
											// var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
											// $("#longitude").val(longitude);
											var speed = res.speed; // 速度，以米/每秒计
											// $("#speed").val(speed);
											var accuracy = res.accuracy; // 位置精度
											// $("#accuracy").val(accuracy);
										},
										cancel: function(res) {
											alert('用户拒绝授权获取地理位置');
										}
									});
								});
							} else {}
						});
				}
			}

		},
		mounted() {
			let href = location.href.split('?')[0];
			this.activity.act_id = this.$route.query.act_id;
			this.user_type = JSON.parse(localStorage.getItem("user_data")).user_type;
			document.title = this.title;
			getActivityCommentLists({
				act_id: this.$route.query.act_id
			}).then(res => {
				if (res.code == 1) {
					this.commentList=unique(res.data.list,['user_id'])
					this.commentList.forEach(item=>{
						item.createtime=item.createtime.split(' ')[0]
					})
				}

			});

			this.$nextTick(function() {
				//获取传递的参数
				this.requestIds = this.$route.query.act_id;
				// console.log(this.requestIds);

				getActivity_is({
					act_id: this.requestIds
				}).then(res => {
					// res.data.act_statusOver=this.getThreeDaysBeforeDate(res.data.act_start_time)
					this.activityContent = res.data;
					this.activityContent.cost = Number(this.activityContent.cost)
					this.activityContent.IsCost = this.activityContent.cost > 0 ? 1 : 0
					if(this.activityContent.scene_image) {
						this.activityContent.scene_image = this.activityContent.scene_image.toString().split(',')
					}
					// 创建一个报名截止时间（前一天晚上8点）
					let newDate = res.data.act_start_time.split('-')
					let lowDate =res.data.act_start_time.split('-')[2].split(' ')[0] - 1
					let time = ` ${res.data.act_start_time.split('-')[0]}-${res.data.act_start_time.split('-')[1]}-${lowDate>10?lowDate:'0'+lowDate} 20:00:00`
					
					this.activityContent.deadline_time=time
					let san = 3 * 24 * 3600 * 1000
					this.activityContent.act_author = res.data.act_author;
					this.activityType = res.data.act_status;
					this.activityType = 1;
					// if(this.activityContent.act_status==1){
					// 	this.activityContent.act_status=newDate-lowDate>san?-1:this.activityContent.act_status	
					// }
					let arr = this.activityContent.train_course.split('|');
					arr.forEach((item, i) => {
						if (item) {
							this.train_course.push(item)
						}
					})
					this.train_course = this.train_course.sort()
					this.act_type = this.activityContent.act_type
					// this.train_course=[4]
					// this.act_type=3
					// console.log(this.act_type)
					if (this.train_course[0] == 2 && this.train_course.length == 1 && this.act_type == 1) {
						this.list = this.list2
					} else if (this.train_course[0] == 3 && this.train_course.length == 1 && this.act_type == 1) {
						this.list = this.list1
					} else if (this.train_course.length == 2 && this.act_type == 1) {
						this.list = this.list3
					} else if (this.act_type == 2) {
						this.list = this.list4
					} else if (this.act_type == 3) {
						this.list = this.list5
					}
					// console.log(this.list)
				});

				// getActivity_is({
				// 	act_id: this.requestIds
				// }).then(res => {
				// 	if (this.user_type > 1) {
				// 		this.is_signup = res.data.is_signup_tutor
				// 	} else {
				// 		this.is_signup = res.data.is_signup
				// 	}

				// })
			});
		}
	};
</script>

<style lang="scss" scoped>
	.courseDetails {
		background-color: #fff;
		min-height: 100vh;
		padding-bottom: 82px;

		.commentBox {
			text-align: left;

			.comment {
				width: 100%;
				padding: 13px 15px;
				background-color: #f4f4f4;
				text-align: left;
				font-weight: bold;
			}

			.commentlist {
				padding: 0 15px;
			}
		}

		.conten {
			color: #666666;
			padding: 20px;
			background-color: #fff;
			text-align: left;

			.title {
				font-size: 1.125rem;
				font-weight: bold;
			}

			.header {
				margin-top: 15px;
			}

			.pt {
				color: #ff6633;
				font-size: 16px;
				text-align: right;
			}
		}

		// 弹出层
		.popupConten {
			padding: 10px 0px 00px;
			text-align: left;
			font-size: 16px;
			position: relative;
			height: 100%;

			.header {
				text-align: center;
				font-size: 16px;
			}

			.content {
				height: 95%;
				overflow: auto;
				padding: 0 15px;
			}

			input {
				background-color: #f4f4f4;
				width: 100%;
				height: 36px;
				border-radius: 6px;
				padding: 0 13px;
				border: 1px solid transparent;
			}

			input::-webkit-input-placeholder {
				color: #919cb0;
			}

			input::-moz-placeholder {
				/* Mozilla Firefox 19+ */
				color: #919cb0;
			}

			input:-moz-placeholder {
				/* Mozilla Firefox 4 to 18 */
				color: #919cb0;
			}

			input:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: #919cb0;
			}

			.form {
				// padding-bottom: 20px;

				.left {
					height: 36px;
					line-height: 36px;
				}

			}

			input:focus {
				background-color: #fff;
				border: 1px solid #0081FF;
			}

			.row {
				margin: 25px 0;
			}

			.formBtn {
				padding: 10px 0;

				.btn {
					border-radius: 21px;
					width: 8.75rem;
					height: 2.625rem;
					background-color: #0081ff;
					color: #fff;
					line-height: 2.625rem;
					text-align: center;
					margin: 0 auto;
				}
			}

			p {
				margin-bottom: 28px;
			}
		}

		.btn {
			border-radius: 0.375rem;
			padding: 9px 10px;
			background: #f4f4f4;
			color: #919cb0;
			border: 1px solid transparent;
			margin-right: 5px;
			// margin-top: 1rem;
			width: 31%;
			position: relative;

			img {
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
			}
		}

		.btn.activation {
			border-color: #0081ff;
			background: #ffffff;
			color: #0081ff;
		}

		.tjBtn {
			text-align: center;
			background-color: #0081ff;
			color: #fff;
			border-radius: 50px;
			margin: 0 auto;
			width: 140px;
			height: 42px;
			line-height: 42px;
		}

		.bottom {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 5.125rem;
			background: #fff;
			box-shadow: 0px -6px 10px 0px rgba(145, 156, 176, 0.1);

			.btn {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 78%;
				transform: translate(-50%, -50%);
				height: 2.625rem;
				background: #0081ff;
				color: #fff;
				line-height: 2.625rem;
				text-align: center;
				border-radius: 21px;
			}

			.btn1 {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 78%;
				transform: translate(-50%, -50%);
				height: 2.625rem;
				background: #fff;
				color: #0081ff;
				line-height: 2.625rem;
				text-align: center;
				border-radius: 21px;
				border: 1px solid #0081ff;
			}

			.btn2 {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 78%;
				transform: translate(-50%, -50%);
				height: 2.625rem;
				background: #c6c8ca;
				color: #ffffff;
				line-height: 2.625rem;
				text-align: center;
				border-radius: 21px;
				border: 1px solid transparent;
			}
		}

		.hui {
			background-color: #ddd !important;
		}

		.huiColor {
			color: #919CB0;
		}

		.qualifiedBox {
			padding: 10px 15px;
			border-radius: 6px;

			.title {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 10px;
			}

			.qualifiedtext {
				text-align: justify;

				p {
					margin: 15px 0;
				}
			}

			.qualifiedBtn {
				display: flex;
				justify-content: space-around;
				margin-top: 10px;

				.qualifiedBtn_btn {
					width: 80%;
					height: 42px;
					line-height: 42px;
					// padding:8px 15px;
					border-radius: 50px;
					background-color: #0081FF;
					color: #fff;
					margin: 30px 0;
				}

				.que {
					text-align: right;
					color: #0081FF;
					width: 90%;
					font-weight: bold;
					font-size: 15px;
					margin-top: 10px;
				}
			}
		}
	}
</style>
