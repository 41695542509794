<template>
	<div class="courseDetails">
		<div class="conten">
			<div v-text="Activity_t.act_name" class="title"></div>
			<van-row class="header">
				<van-col span="8">
					<!-- <div v-text="activityContent.act_start_time"></div> -->
					<div v-text="time"></div>
				</van-col>
				<van-col span="6">
					<div v-text="activityContent.act_author" style="font-size: 16px;text-align: center;"></div>
				</van-col>
				<van-col span="10">
					<div class="pt">现场急救行动指南</div>
				</van-col>
			</van-row>
		</div>
		<div class="contenImg conten">
			
			<img :src="conten.teacherpic" alt="">
			<div v-text="conten.teacherpiccomment">
				
			</div>
			<img :src="conten.stupic1" alt="">
			<img :src="conten.stupic2" alt="">
			<img :src="conten.stupic3" alt="">
			<div v-text="conten.stupiccomment">
				
			</div>
			<img :src="conten.wholepic" alt="">
			<div v-text="conten.wholepiccomment">
				
			</div>
		</div>
	</div>

</template>

<script>
	import {
		Toast
	} from 'vant';
	import {
		getActivityFeedbackList,getActivity_t
	
	} from '@/api'

	export default {
		name: 'lookFeedbackFrom',
		components: {},
		data() {
			return {
				name:'131321',
				time:'132132',
				activityContent:{
					act_author:'13213'
				},
				title:'活动精彩反馈',
				conten:'',
				Activity_t:{}
			}
		},
		methods: {
			
			},
		mounted() {
			document.title=this.title
			getActivityFeedbackList({act_id:this.$route.query.act_id}).then(res=>{
				this.conten=res.data.list[0]
				this.time=this.conten.createtime.split(' ')[0]
			})
			getActivity_t({act_id:this.$route.query.act_id}).then(res=>{
				this.Activity_t=res.data
			})
		}

	}
</script>

<style lang="scss" scoped>
	.courseDetails {
		background-color: #fff;
		min-height: 100vh;

		.commentBox {
			text-align: left;

			.comment {
				width: 100%;
				padding: 13px 15px;
				background-color: #F4F4F4;
				text-align: left;
				font-weight: bold;
			}

			.commentlist {
				padding: 0 15px;
			}
		}
		.contenImg{
			text-align: left;
			img{
				width: 100%;
			}
		}

		.conten {
			color: #666666;
			padding: 20px;
			background-color: #fff;
			text-align: left;

			.title {
				font-size: 1.125rem;
				font-weight: bold;
			}

			.header {
				margin-top: 15px;
			}

			.pt {
				color: #FF6633;
				font-size: 15px;
				text-align: right;
			}
		}

		
	}
</style>
