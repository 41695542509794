<template>
	<div class="feedbackFrom">
		<div>
			<div class="hairline-bottom">
				先来一张导师讲课的照片吧
			</div>
			<div>
				<van-uploader :after-read="afterRead" v-model="fileList1" :max-count="1" @delete="beforedelete"/>
				<van-field v-model="dataIng.teacherpiccomment" rows="3" :autosize="{ minHeight: 78 }" type="textarea" placeholder="请输入留言" />
			</div>
		</div>
		<div>
			<div class="hairline-bottom">
				先来一张导师讲课的照片吧
			</div>
			<div>
				<van-uploader :after-read="afterRead" v-model="fileList2" :max-count="3"  @delete="beforedelete"/>
				<van-field v-model="dataIng.stupiccomment" rows="3" :autosize="{ minHeight: 78 }" type="textarea" placeholder="请输入留言" />
			</div>
		</div>
		<div>
			<div class="hairline-bottom">
				先来一张导师讲课的照片吧
			</div>
			<div>
				<van-uploader :after-read="afterRead" v-model="fileList3" :max-count="1"  @delete="beforedelete"/>
				<van-field v-model="dataIng.wholepiccomment" rows="3" :autosize="{ minHeight: 78 }" type="textarea" placeholder="请输入留言" />
			</div>
		</div>
		<div class="bottom">
			<div class="btn" @click="feedbackClick" :class="fileList1.length&&fileList2.length&&fileList3.length&&issan?'':'hui'">
				提交
			</div>
		</div>
	</div>
</template>

<script>

	import { Toast } from 'vant';
	import { postFeedbackImg,postActivityFeedback } from '@/api'
	export default {
  name: 'feedbackFrom',
  components: {
  },
  data(){
	  return{
		  fileList:[],
		title:'活动反馈',
		message1:'',
		message2:'',
		message3:'',
		fileList1:[],
		fileList2:[],
		fileList3:[],
		dataIng:{
			act_id:this.$route.query.id,
			teacherpic:'',
			teacherpiccomment:'',
			stupic1:'',
			stupic2:'',
			stupic3:'',
			stupiccomment:'',
			wholepic:'',
			wholepiccomment:''
		},
		issan:false
		
	  }
  },
  methods:{
	  feedbackClick(){
		  if(this.fileList1.length&&this.fileList2.length&&this.fileList3.length&&this.issan){
				this.dataIng.teacherpic=this.fileList1[0].content
				this.dataIng.wholepic=this.fileList3[0].content
				this.dataIng.stupic1=this.fileList2[0].content
				this.dataIng.stupic2=this.fileList2[1].content
				this.dataIng.stupic3=this.fileList2[2].content
				// console.log(this.dataIng)
				postActivityFeedback(this.dataIng).then(res=>{
					// console.log(res)
					Toast(res.msg)
					if(res.code==1){
						this.$router.back(-1)
					}
				})
		  }
		
		  
	  },
	  beforedelete(file){
		  if(this.fileList2.length==3){
		  	this.issan=true
		  }
	  },
	 // 返回布尔值
	    // beforeRead(file) {
	    //  if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
	    //     Toast('请上传 jpg 格式图片');
	    //     return false;
	    //   }
	    //   return true;
	    // },
	    // 返回 Promise
	   afterRead(file) {
		   // console.log(file)
		   
	        // 此时可以自行将文件上传至服务器
	  //       console.log(file.content);
			// console.log(this.fileList)
			var _this=this
			file.status = 'uploading';
			file.message = '上传中...';
			// 如果上传成功
			if (file.file) {
				const data = new FormData()
				data.append('imgurl', file.file)
				data.append('token', sessionStorage.getItem('token'))
				this.$axios.post('/gzh/backend/public/index.php/api/user/postFeedbackImg', data,{
								headers:{'Content-Type':'multipart/form-data'}
				}).then(function (res) {
					file.content=res.data
				})
				.catch(function (error) {
				  console.log(error);
				});
			} else {
				// 提示信息，需引入Message
				Message.error('图片插入失败')
			}
			if(this.fileList2.length==3){
				this.issan=true
			}
			
			
	      }
  },
  
  mounted() {
  	document.title = this.title;   
	
	
  }
}
</script>
<style lang="scss" type="text/css">
	.feedbackFrom {
		width: 100%;
		min-height: 100vh;
		background-color: #fff;
		text-align: left;
		padding: 15px 15px 85px;
		.van-cell{
			padding-left: 0;
		}
		.hui {
			background-color: #ddd;
		}

		.hairline-bottom {
			padding-bottom: 5px;
			border-bottom: 1px solid rgba(229, 229, 229, 1);
			margin-bottom: 10px;
		}

		.bigBox {
			padding: 20px 15px;
			background-color: #fff;
			padding-bottom: 20px;

			img {
				width: 100%;
			}

			.title {
				font-size: 17px;
				text-align: left;
				padding: 20px 0;
				border-bottom: 1px solid #E5E5E5;
				margin-bottom: 15px;
			}
		}

		.van-col {
			text-align: left;
		}

		.bottom {
			box-shadow: 1px 1px 10px 5px #E5E5E5;
		}

		.van-image__img {
			border-radius: 8px;
		}

		.video {
			width: 100%;
			height: 220px;
			// background-color: red;
			position: relative;

			.bof {
				width: 50px;
				height: 50px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
</style>
