<template>
	<div class="up-image" style="padding-bottom: 80px;">

		<!-- 图片1 -->
		<div id="up">
			<img :src="fileUrl" alt="" id="videoId" v-show="fileUrl&&!loadding" class="train-img">
			<div class="loaddingBox flex" v-if="loadding">
				<div class="loadding flex" style="color: #fff;justify-content: center;flex-flow: column;">
					<div>正在上传</div>
				</div>
			</div>
			<div class="img-forword">
				<form v-if="!loadding&&!fileUrl">
					<label class="opo">
						<img src="../../assets/Train/icon_tianjia_200@2x.png" alt="" class='tianjias' v-if="!fileUrl">
						<input type="file" accept="image/*" name="file" @change="uploadUp($event, 1)" enctype="multipart/form-data" style="opacity: 0;visibility: hidden;">
					</label>
				</form>
			<div style="margin-top: 127px;" v-if="!fileUrl">请选择培训图片1</div>
			</div>
		</div>
		

		<!-- 图片2 -->
		<div id="up1">
			<img :src="fileUrl1" alt="" id="videoId1" v-show="fileUrl1&&!loadding1" class="train-img">
			<div class="loaddingBox flex" v-if="loadding1">
				<div class="loadding flex" style="color: #fff;justify-content: center;flex-flow: column;">
					<div>正在上传</div>
				</div>
			</div>
			<div class="img-forword">
				<form v-if="!loadding1&&!fileUrl1">
					<label class="opo">
						<img src="../../assets/Train/icon_tianjia_200@2x.png" alt="" class='tianjias' v-if="!fileUrl1">
						<input type="file" accept="image/*" name="file" @change="uploadUp($event, 2)" enctype="multipart/form-data" style="opacity: 0;visibility: hidden;">
					</label>
				</form>
			<div style="margin-top: 127px;" v-if="!fileUrl1">请选择培训图片2</div>
			</div>
		</div>
		

		<!-- 图片3 -->
		<div id="up2">
			<img :src="fileUrl2" alt="" id="videoId2" v-show="fileUrl2&&!loadding2" class="train-img">
			<div class="loaddingBox flex" v-if="loadding2">
				<div class="loadding flex" style="color: #fff;justify-content: center;flex-flow: column;">
					<div>正在上传</div>
				</div>
			</div>
			<div class="img-forword">
				<form v-if="!loadding2&&!fileUrl2">
					<label class="opo">
						<img src="../../assets/Train/icon_tianjia_200@2x.png" alt="" class='tianjias' v-if="!fileUrl2">
						<input type="file" accept="image/*" name="file" @change="uploadUp($event, 3)" enctype="multipart/form-data" style="opacity: 0;visibility: hidden;">
					</label>
				</form>
			<div style="margin-top: 127px;" v-if="!fileUrl2">请选择培训图片3</div>
			</div>
		</div>
		

		<div class="bottom" style="box-shadow: 1px 5px 10px 1px rgb(145, 156, 176) ;">
			<div class="btnts btn" @click="change" :style="
				 fileUrl && fileUrl1 && fileUrl2 ?'background-color: #0081FF;':'background-color: #C6C8CA;'
				 ">提交</div>
		</div>
	</div>
</template>

<script>
import { postVideoImgAndReturnUrl, postSceneActivityImage } from '@/api'
import { Toast } from 'vant'
export default {
	name: 'upTrainImage',
	data() {
		return {
			share_ArticleImgUrl:'',
			sfz_image: [],
			imgFlie1: [],
			fileUrl: '',
			fileUrl1: '',
			fileUrl2: '',
			loadding:false,
			loadding1:false,
			loadding2:false,
			toUrlArr: [],
		}
	},
	mounted() {
		document.title = '上传培训图片';  
	},
	methods: {
		uploadUp(event, number) {
			console.log(number)
			let files = event.target.files;
			let file = event.target.files[0]
			// let fileUrl = ''
			let _this=this
			this.getFileURL(files[0]).then(res=>{
				if(number == 1) {
					_this.fileUrl=res
					_this.fileObj=files[0]
					if(_this.fileUrl){
						_this.$nextTick(()=>{
							var video = document.getElementById('videoId')
							video.src=res
							setTimeout(() => {
								_this.getVideoBase641(video, 1).then((res,rest)=>{
									postVideoImgAndReturnUrl({imgurl:res,folder:'jjvideo'}).then(ress=>{
										console.log(ress.msg)
										_this.toUrlArr.push(ress.msg)
									})
								})
							}, 1000)
						})
					}

				} else if(number == 2) {
					_this.fileUrl1=res
					_this.fileObj=files[0]
					var video1 = document.getElementById('videoId1')
					if(_this.fileUrl1){
						_this.$nextTick(()=>{
							video1.src=res
						})
						setTimeout(() => {
							_this.getVideoBase641(video1, 2).then((res,rest)=>{
								postVideoImgAndReturnUrl({imgurl:res,folder:'jjvideo'}).then(ress=>{
									console.log(ress.msg)
									_this.toUrlArr.push(ress.msg)
								})
							})
						}, 1000)
					}

				} else if(number == 3) {
					_this.fileUrl2=res
					_this.fileObj=files[0]
					var video2 = document.getElementById('videoId2')
					if(_this.fileUrl2){
						_this.$nextTick(()=>{
							video2.src=res
						})
					}
					setTimeout(() => {
						_this.getVideoBase641(video2, 3).then((res,rest)=>{
							postVideoImgAndReturnUrl({imgurl:res,folder:'jjvideo'}).then(ress=>{
								console.log(ress.msg)
								_this.toUrlArr.push(ress.msg)
							})
						})
					}, 1000)
				}
				
			});//把当前的 files[0] 传进去
		},
		getFileURL(file) {
			console.log(file)
			return new Promise(function (resolve) {
				var getUrl = null;
				if (window.createObjectURL != undefined) { // basic
					getUrl = window.createObjectURL(file);
				} else if (window.URL != undefined) { // mozilla(firefox)
					getUrl = window.URL.createObjectURL(file);
				} else if (window.webkitURL != undefined) { // webkit or chrome
					getUrl = window.webkitURL.createObjectURL(file);
				}
				resolve(getUrl)
			})
		},
	  // 提交事件
	  change() {
			if(!this.fileUrl || !this.fileUrl2 || !this.fileUrl2) {
				return;
			}

			let _this=this
			// console.log(_this.toUrlArr)
			// let aa = _this.toUrlArr.join(',')
			// console.log(aa)
			// // 		console.log(ress)
			postSceneActivityImage({ 
				act_id: this.$route.query.act_id,
				act_image: _this.toUrlArr.join(',')
			}).then((rees,rett) => {
				console.log(rees)
				if(rees.code == 1) {
					Toast.success(rees.msg);
					setTimeout(() => {
						this.$router.go(-1)
					}, 1000)
				} else {
					Toast(rees.msg);
				}
			})
		},
		//拿到封面图  
		getVideoBase641(video, number) {
			return new Promise(function (resolve) {
				var widths = ''
				var heights = ''
				if(number == 1) {
					let videoss = document.getElementById('up')
					widths = videoss.getBoundingClientRect().width
					heights = videoss.getBoundingClientRect().height
				} else if(number == 2) {
					let videoss = document.getElementById('up1')
					widths = videoss.getBoundingClientRect().width
					heights = videoss.getBoundingClientRect().height
				} else if(number == 3) {
					let videoss = document.getElementById('up2')
					widths = videoss.getBoundingClientRect().width
					heights = videoss.getBoundingClientRect().height
				}
				
				//创建canvas对象
				const canvas = document.createElement("canvas")
				canvas.width = widths
				canvas.height = heights
					// 利用canvas对象方法绘图
				console.log( widths, heights)
				canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
				// 转换成base64形式
				const img = canvas.toDataURL("image/jpeg") // 这个就是图片的base64
				resolve(img);
				
			})
		},
	}
}
</script>

<style lang="scss">
	.btnts {
		font-family: PingFang SC;
		font-weight: bold;
		color: rgba(0, 129, 255, 1);
		height: 30%;
		line-height: 30%;
		position: absolute;
		bottom: 0;
		left: 0;
		border-top: 1px solid #E5E5E5;
		text-align: center;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.opo {
		display: flex;
		justify-content: center;
	}
	.img-forword {
		position: relative;
	}
	.tianjias {
		width: 100px;
		height: 100px;
		position: absolute;
		top: 28px;
		// left: 154px;
	}
	.train-img {
		height: 100%;
		width: 100%;
		margin-top: 50px;	
	}
</style>