<template>
	<div class="hotText">
		<div class="topBox" style="margin-bottom: 0;">
			<div class="vanSwipe">
				<van-swipe :autoplay="3000" >
					<van-swipe-item v-for="(image, index) in images" :key="index">
						<img v-lazy="image.pic" class="auto-img"  @click="jump(image.url)"/>
						<!-- <div style="background-color: #0081FF;width: 100%;height: 100%;"></div> -->
					</van-swipe-item>
				</van-swipe>
			</div>
			<div class="flex" style="justify-content: space-between;margin-top: 15px;">
				<van-tabs v-model="active" style="padding: 0;">
					<van-tab title="最新文章"></van-tab>
					<van-tab title="精选文章"></van-tab>
				</van-tabs>
				<div>
					<img src="../../assets/bigSink/icon_xiaoxi@2x.png" alt="" style="width: 28px;" @click="$router.push({name:'news'})">
					<img src="../../assets/bigSink/icon_wode@2x.png" alt="" style="width: 28px;margin-left: 15px;" @click="$router.push({name:'userSink'})">
				</div>
			</div>
		</div>
		<div class="bigBox"  v-if="active==0">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list
			  v-model="loading"
			  :finished="finished"
			  finished-text="没有更多了"
			  @load="onLoad"
			>
			<div v-for="(item,i) in list1" :key="i" @click="$router.push({name:'viewArticles',query: { id: item.id}})">
				<div class="flex topBox">
					<div class="flex" style="justify-content: space-between;width: 100%;">
					<div class="flex">
						<div style="margin-right: 10px;">
							<img :src="item.userinfo.user_head_img" alt="" style="width: 41px;border-radius: 50%;">
						</div>
						<div>
							<p style="font-weight: Bold;"><span v-text="item.userinfo.user_name"></span></p>
							<p class="time" ><span v-text="setTime(item.createtime)"></span></p>
						</div>
					</div>
					<div class="btn" :class="item.is_follow?'btn1':''" @click.stop="followClick(i,active)" v-if="user_id!==item.user_id">
						<span v-text="item.is_follow?'已关注':'+ 关注'"></span>
					</div>
					</div>
					<div style="margin-top: 18px;width: 100%;" v-if="item.type==1"> <span v-text="item.title" style="font-weight: bold;"></span></div>
					<div class="van-multi-ellipsis--l2" style="margin: 16px 0;color: #767E8D;" v-if="item.type==1">
						<span v-text="deleteHtmlTag(item.content)"></span>
					</div>
					<div class="van-multi-ellipsis--l2" style="margin: 16px 0 0;color: #767E8D;" v-if="item.type==2">
						<span v-text="item.thank"></span>
					</div>
					<div v-if="item.type==1&&item.cover_picture">
						<!-- <img src="../../assets/123.jpg" alt="" style="height: 170px;width: 100%;border-radius: 6px;"> -->
						<img :src="item.cover_picture" alt="" style="height: 170px;width: 100%;">
					</div>
					<div v-if="item.type==2" class="article flex" @click="urlClick.stop(i)">
					
							<img :src="item.cover_picture?item.cover_picture:require('@/assets/bigSink/icon_moren200_pre@2x.png')" alt="" style="width: 40px;height: 40px;">
					
						<div style="padding:0px 12.5px;width: calc(100% - 50px);">
							<div v-text="item.share_title" style="font-weight: bold;margin-bottom: 7.5px;"></div>
							<div v-text="item.share_url" style="font-size: 10px;color: #767E8D;"></div>
						</div>
					</div>
					<div class="flex kan">
						<div>
							<span v-text="item.browse_num" class="number_0"></span> 人看过 · <span v-text="item.comment_num" class="number_0"></span> 评论
						</div>
						<div class="flex" >
							<span v-text="item.praise_num" class="number_0" v-if="item.praise_num"></span>
							<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;height: 20px;margin-left: 10px;" v-if="!item.is_praise" @click.stop="zanClick(i,1)">
							<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;height: 20px;margin-left: 10px;" v-if="item.is_praise" @click.stop="zanClick(i,2)">
						</div>
					</div>
				</div>
				
			</div>
			</van-list></van-pull-refresh>
		</div>
		<div class="bigBox" v-if="active==1">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list
			  v-model="loading1"
			  :finished="finished1"
			  finished-text="没有更多了"
			  @load="onLoad1"
			>
			<div v-for="(item,i) in list" :key="i" @click="$router.push({name:'viewArticles',query: { id: item.id}})">
				<div class="flex topBox">
					<div class="flex" style="justify-content: space-between;width: 100%;">
					<div class="flex">
						<div style="margin-right: 10px;">
							<img :src="item.userinfo.user_head_img" alt="" style="width: 41px;border-radius: 50%;">
						</div>
						<div>
							<p style="font-weight: Bold;"><span v-text="item.userinfo.user_name"></span></p>
							<p class="time" ><span v-text="setTime(item.createtime)"></span></p>
						</div>
					</div>
					<div class="btn" :class="item.is_follow?'btn1':''" @click.stop="followClick(i,active)" v-if="user_id!==item.user_id">
						<span v-text="item.is_follow?'已关注':'+ 关注'"></span>
					</div>
					</div>
					<div style="margin-top: 18px;width: 100%;" v-if="item.type==1"> 
						<span v-text="item.title" style="font-weight: bold;"></span>
					</div>
					<div class="van-multi-ellipsis--l2" style="margin: 16px 0;color: #767E8D;width: 100%;" v-if="item.type==1">
						<span v-text="item.content" style="width: 100%;"></span>
					</div>
					<div class="van-multi-ellipsis--l2" style="margin: 16px 0 0;color: #767E8D;width: 100%;" v-if="item.type==2">
						<span v-text="item.thank"></span>
					</div>
					<div v-if="item.type==1&&item.cover_picture">
						<!-- <img src="../../assets/123.jpg" alt="" style="height: 170px;width: 100%;border-radius: 6px;"> -->
						<img :src="item.cover_picture" alt="" style="height: 170px;width: 100%;">
					</div>
					<div v-if="item.type==2" class="article flex" @click="urlClick(i)">
					
						<img :src="item.share_picture?item.share_picture:require('@/assets/bigSink/icon_moren200_pre@2x.png')" alt="" style="width: 40px;height: 40px;">
					
						<div style="padding:0px 12.5px;">
							<div v-text="item.share_title" style="font-weight: bold;margin-bottom: 7.5px;"></div>
							<div v-text="item.share_url" style="font-size: 10px;color: #767E8D;"></div>
						</div>
					</div>
					<div class="flex kan">
						<div>
							<span v-text="item.browse_num" class="number_0"></span> 人看过 · <span v-text="item.comment_num" class="number_0"></span> 评论
						</div>
						<div class="flex">
							<span v-text="item.praise_num" class="number_0" v-if="item.praise_num"></span>
							<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;height: 20px;margin-left: 10px;" v-if="!item.is_praise" @click.stop="zanClick(i,1)">
							<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;height: 20px;margin-left: 10px;" v-if="item.is_praise"  @click.stop="zanClick(i,2)">
						</div>
					</div>
				</div>
				
			</div>
			</van-list>
			</van-pull-refresh>
		</div>
		<div style="position: fixed;bottom: 40px;right: 20px;" @click.stop="$router.push({name:'FBArticle'})">
			<img src="../../assets/bigSink/icon_fabu@2x.png" alt="" style="width: 60px;">
		</div>
	</div>
<!-- 	<van-popup v-model="show1" closeable position="bottom" :style="{ height: '82%' }">
		<div style="margin: 16px 0 8.5px;">查看分享文章</div>
		<div style="text-align: left;padding: 0 15px;height: 90%;overflow: auto;">
			<div style="padding: 27px 0;font-weight: bold;border-bottom: 1px solid #EEEEEE;">
				<span v-text="article.title"></span>
			</div>
			<div style="font-size: 11px;padding: 9.5px 0 20px;">
				<span v-text="article.time" class="hui number_0" style="margin-right: 10px;" ></span>
				<span v-text="article.form" class="blue" ></span>
			</div>
			<img :src="article.img" alt="" style="width: 100%;">
			<div style="margin-top: 18px;color: #767E8D;">
				<p v-text="article.conten"></p>
			</div>
		</div>
	</van-popup> -->
		
	</div>
</template>

<script>
import {getWeixinAidHotArticleList ,getWeixinAidHotArticleEssenceList ,postFollow,postWeixinAidHotArticlePraise,getBanner} from '@/api'
import { Toast } from 'vant';
	import { setTime } from '@/util/setTime.js'
	export default {
  name: 'hotText',
  components: {
  },
  data(){
	  return{
		  // 自己的id
		  user_id:0,
		  loading1:false,
		  
		  finished1:false,
		  show1:false,
		  // 分页
		  page:0,
		  page1:0,
		  // 总共
		  count:1,
		  count1:1,
		  // 加载状态
		 loading: false,
		 // 全部加载结束
		finished: false,
		images: [
		  // 'https://img.yzcdn.cn/vant/apple-1.jpg',
		  // 'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		// 0默认热文，1精品热文
		active:0,
		// 精品热文
		list:[],
		// 默认热文
		list1:[],
		article:{},
		refreshing:false
	  }
  },
  watch:{
	  active(){
	  }
  },
  methods:{
	 
	  //去除html标签
	  deleteHtmlTag(str){
	   str = str.replace(/<[^>]+>|&[^>]+;/g,"").trim();//去掉所有的html标签和&nbsp;之类的特殊符合
	   return str;
	  },
	    onRefresh() {
			// 清空列表数据
			this.finished = false;
			this.page=0
			this.list=[]
			this.list1=[]
			// 重新加载数据
			// 将 loading 设置为 true，表示处于加载状态
			this.loading = true;
			this.onLoad();
	      },
		  // 格式化时间
	  setTime(time){
			return setTime(time)
		},
		// 点赞
	  zanClick(i,type){
		  
		  switch (this.active){
		  	case 0:
			postWeixinAidHotArticlePraise({hot_article_id:this.list1[i].id,type:type}).then(res=>{
				if(res.code==1&&type==1){
					Toast('点赞成功')
					this.list1[i].is_praise=1
					this.list1[i].praise_num=this.list1[i].praise_num+1
				}else if(res.code==1&&type==2){
					Toast('取消点赞成功')
					this.list1[i].is_praise=0
					this.list1[i].praise_num=this.list1[i].praise_num-1
				}else{
					Toast(res.msg)
				}		
			})
		  		break;
		  	case 1:
			postWeixinAidHotArticlePraise({hot_article_id:this.list[i].id,type:type}).then(res=>{
					Toast(res.msg)
					if(res.code==1&&type==1){
						Toast('点赞成功')
						this.list1[i].is_praise=1
						this.list1[i].praise_num=this.list1[i].praise_num+1
					}else if(res.code==1&&type==2){
						Toast('取消点赞成功')
						this.list1[i].is_praise=0
						this.list1[i].praise_num=this.list1[i].praise_num-1
					}else{
						Toast(res.msg)
					}			  
			})
		  		break;
		  }
		 
	  },
	  jump(url){
	  		  window.location.href = url
	  },
	  // 轮播
	  getBanner(){
		  getBanner({page_id:6}).then(res=>{
			  this.images=res.data.list
		  })
	  },
	  postFollow(id,isfollow){
		  let follow=isfollow?1:0
		  console.log(id,follow)
		  postFollow({tutor_id:id,is_follow:follow}).then(res=>{
			  Toast(res.msg)
		  })
	  },
	  followClick(i,active){
		  switch (active){
		  	case 0:
				this.list1[i].is_follow=!this.list1[i].is_follow
				this.postFollow(this.list1[i].user_id,this.list1[i].is_follow)
				console.log(this.list1[i])
		  		break;
		  	case 1:
				this.list[i].is_follow=!this.list[i].is_follow
				this.postFollow(this.list[i].user_id,this.list[i].is_follow)
				console.log(this.list[i].is_follow)
		  		break;
		  }
	  },
	  onLoad1(){
		  this.page1++
		  this.getWeixinAidHotArticleEssenceList()
	  },
	  urlClick(i){
		  this.show1=true
		  this.article={
			  title:this.list[i].share_title,
			  time:this.list[i].createtime,
			  form:this.list[i].userinfo.user_name,
			  img:this.list[i].share_picture,
			  conten:this.list[i].thank,
		  }
	  },
	 getWeixinAidHotArticleEssenceList(){
		 getWeixinAidHotArticleEssenceList({limit:'8',page:this.page1}).then(res=>{
			
			 this.list.push(...res.data.list)
			  this.loading1 = false;
			  this.count1=res.data.count
			   if (this.list.length >= this.count1) {
			        this.finished1 = true;
			    }
		 })
	 },
	 getWeixinAidHotArticleList(){
	 		 getWeixinAidHotArticleList({limit:'8',page:this.page}).then(res=>{
				
	 			 this.list1.push(...res.data.list)
				 
				 console.log(this.list1)
	 			  this.loading = false;
	 			  this.count=res.data.count
	 			   if (this.list1.length >= this.count) {
	 			            this.finished = true;
	 			    }
	 		 })
	 },
	 onLoad() {
		if (this.refreshing) {
			this.list = [];
			this.refreshing = false;
		}
		 this.page++
		 this.getWeixinAidHotArticleList()
	 }
  },
  mounted() {
  	document.title = '花样文章';   
	this.user_id=JSON.parse(localStorage.getItem('user_data')).user_id
	this.onLoad1()
	this.getBanner()
	// this.getWeixinAidHotArticleList()
  }
}
</script>
<style lang="scss" type="text/css">
	.hotText {
		min-height: 100vh;
		background-color: #F7F8FF;
		[class*=van-hairline]::after{
			border: 0;
		}

		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}

		.van-tab {
			min-width: 85px;
		}

		.van-tab--active {
			font-size: 17px;
		}
		.van-multi-ellipsis--l2{
			width: 100%;
		}

		.topBox {
			padding: 15px;
			background-color: #fff;
			margin-bottom: 15px;
			height: auto;

		}

		.bigBox {
			width: 100%;
			padding: 15px;
			background-color: transparent;
			text-align: left;

			.topBox {
				justify-content: space-between;
				background-color: #fff;
				padding: 20px 15px 24px;
				border-radius: 6px;
				
				.btn {
					padding: 5px;
					background-color: #0081FF;
					border-radius: 50px;
					color: #fff;
					font-size: 12px;
					border: 1px solid transparent;
				}
				.btn1{
					background-color: #fff;
					color: #0081FF;
					border: 1px solid #0081FF;
				}
			}
		}

		.time {
			color: #919CB0;
			font-size: 12px;
			transform: scale(.9);
		}

		.kan {
			justify-content: space-between;
			width: 100%;
			font-size: 12px;
			color: #919CB0;
			margin-top: 25px;
		}
		.article {
			width: 100%;
			background-color: #F4F4F4;
			border-radius: 2px;
			text-align: left;
			padding: 5px;
			margin-top:16px;
		}

	}
</style>
