<template>
	<div class="recharge">
		<div v-if="$route.query.type==1">
			<van-cell>
				<!-- <div style="border-bottom: 1px solid #ddd;padding-bottom: 24.5px;"> -->
				<template slot="title">
					<div class="flex" style="text-align: left;">

						<span class="custom-title listTitle" style="padding-left: 5px;"> 充值方式</span>
					</div>
				</template>
				<div class="right">微信支付</div>
				<!-- </div> -->
			</van-cell>
			<div style="height: 1px;width: 100%;background-color: #fff;">
				<div style="height: .5px;width: 93%;background-color: #E5E5E5;margin: 0 auto ;"></div>
			</div>
			<van-field v-model="value_cz" label="充值金额(元)" placeholder="请输入充值金额" input-align="right" />
			<div class="tis">
				点击去充值，即表示已阅读并同意 <span style="color: #0081FF;" @click="show1=true">《充值协议》</span>。“现场急救行动指南”微信公众号不会以任何形式要求你输入银行账号和密码
			</div>
			<div class="btn" @click="czLCick">
				去充值
			</div>
		</div>
		<div v-if="$route.query.type==2">
			<van-cell>
				<template slot="title">
					<div class="flex" style="text-align: left;">

						<span class="custom-title listTitle" style="padding-left: 5px;"> 可提现金额（元）</span>
					</div>
				</template>

				<div class="right" style="letter-spacing: 0px;" v-text="balance"></div>
			</van-cell>
			<div style="height: 1px;width: 100%;background-color: #fff;">
				<div style="height: .5px;width: 93%;background-color: #E5E5E5;margin: 0 auto ;"></div>
			</div>
			<van-field v-model="value" label="提现金额(元)" placeholder="请输入提现金额" input-align="right" />
			<div class="tis1 tis">
				备注：提现成功后将会转账到您的微信钱包
			</div>
			<div class="btn flex" @click="nestClick" style="justify-content: center;" :style="Number(value) <= Number(balance)&&Number(value)>0?'':'background:#ddd;'">
				<img src="../../assets/user/icon_loading@2x.png" alt="" style="width: 1.5rem;height: 22px;margin-right: 7.5px;"
				 v-if="isfu==1" class="rotate">
				{{isfu==0?'下一步':'申请中...'}}
			</div>
			<!-- <div class="btn flex" @click="nestClick" style="justify-content: center;" :style="Number(value) < Number(balance)&&Number(value)>0?'':'background:#ddd;'" v-if="isclick==0">
				<img src="../../assets/user/icon_loading@2x.png" alt="" style="width: 1.5rem;height: 22px;margin-right: 7.5px;"
				 v-if="isfu==1" class="rotate">
				{{isfu==0?'下一步':'申请中...'}}
			</div> -->
		</div>

		<div :class="show?'mask':''" @click="show=false"></div>
		<div :class="show?'show':''" style="" class="popup">
			<div class="title">请输入支付密码</div>
			<div style="color: #919CB0;margin: 20px 0;">提现</div>
			<div class="qian" style="letter-spacing: -1px;">￥<span v-text="value"></span></div>
			<van-password-input :value="password" :focused="showKeyboard" @focus="showKeyboard = true" />
		</div>
		<!-- 数字键盘 -->
		<van-number-keyboard :show="showKeyboard" @input="onInput" @delete="onDelete" @blur="showKeyboard = false" style="z-index: 10000;" />
	
		<van-popup v-model="show1" closeable :style="{ height: '95%' ,borderRadius:'8px',width:'90%' }">
			<div class="popupConten" v-show="show1">
				<div class="header" style="">充值协议</div>
				<div class="content">
					<!-- <div style="font-weight: bold;margin: 28px 0 28px;">说明</div> -->
					<p style="padding-top: 10px;">尊敬的用户，为保障您的合法权益，请您在充值前仔细阅读本《充值协议》(以下简称“本协议”），以了解“现场急救行动指南”微信公众号的充值、返现及余额使用规则并避免产生任何误解。当您点击“充值”按钮，即视为您已阅读、理解本协议，并同意按照本协议约定的规则进行充值、参与提现/或使用账户余额。</p>
					<p>
						<p style="font-weight: bold;">1、定义</p>
						<p>充值本金：您通过“现场急救行动指南”微信公众号向您已注册的用户账号实际支付的金额。</p>
						<p>账户余额：您的用户账号中显示的金额，包括①充值本金，②取消报名培训课程而退回的报名费，③购买商品后发生退货行为而退回的金额，④公益急救导师补助费用。</p>
					</p>
					<p>
						<p style="font-weight: bold;">2、账户余额有效期：</p>
						<p>用户账号余额的有效期为自充值之日起至使用完毕为止。</p>
					</p>
					<p>
						<p style="font-weight: bold;">3、账号余额的使用规则：</p>
						<p>1）账户余额可用于支付培训课程报名费、购买商品、支付押金。</p>
						<p>2）账户余额中未使用部分，可申请提现，但提现金额必须是1元的整数倍。</p>
					</p>
					<p>
						<p style="font-weight: bold;">4、特别说明：</p>
						<p style="font-weight: bold;">您完全理解并同意，“现场急救行动指南”微信公众号有权随时修改本协议内容，届时将通过“现场急救行动指南”微信公众号上公布修改后的协议，该公布将被视为“现场急救行动指南”微信公众号已通知用户；同时，“现场急救行动指南”微信公众号也可通过其他适当方式通知用户。如果您选择继续充值即表示您同意并接受修改后的协议并受其约束；如果您不同意我们对本协议的修改，请立即放弃充值或停止使用本服务。在适用法律法规允许的范围内，本协议充值及账户余额的使用规则的最终解释权归“现场急救行动指南”微信公众号所有。</p>
						
					</p>
					
					<!-- <div style="padding: 20px 0;border-top: 1px solid #E5E5E5;">
						<div class=" tjBtn" style="" @click="postSignupTutorClick">
							提交
						</div>
					</div> -->
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import {userRechargeUniOrder,usersWithdraw,getClientUsersAccount } from '@/api'
	import { Toast } from 'vant';
	export default {
  name: 'wallet',
  components: {
  },
  data(){
	  return{
		  value_cz:'',
		  show:false,
		title:'充值',
		value:'',
		password:'',
		showKeyboard:false,
		isPasswrod:123456,
		isfu:0,
		// 1.提现中
		// 0未提现
		// 余额
		balance:0,
		isclick:0,
		show1:false
	  }
  },
  methods:{
	  nestClick(){
		  console.log(this.value,this.balance)
		  console.log(Number(this.value) < Number(this.balance)&&Number(this.value)>0)
		  if(Number(this.value) <= Number(this.balance)&&Number(this.value)>0){
			  this.isclick=1
			  this.show=true
		  }else{
			  return
		  }
	  },
	  jsApiCall(jsApiParameters){
	  	// console.log(jsApiParameters);return;
	  	// alert(2);
		let _this = this
	  	let jsondata = JSON.parse(jsApiParameters);
	  	// console.log('thisssss'+jsApiParameters);
	  	WeixinJSBridge.invoke(
	  		'getBrandWCPayRequest',
	  		 jsondata,
	  		function(res){
	  			if(res.err_msg == "get_brand_wcpay_request:ok"){
					Toast("充值成功!");
	  			 _this.$router.push({name:'recharge_ok',query:{type:1,money:this.value_cz}})
	  			}else if(res.err_msg == "get_brand_wcpay_request:cancel"){
	  				 Toast("用户取消支付!");
	  				 
	  			}else{
	  				Toast("支付失败!");
	  			}
	  		}
	  	);
	  },
	  czLCick(){
		  // this.$router.push({name:'recharge_ok',query:{type:1}})
		  userRechargeUniOrder({total_fee:this.value_cz,select_type:1}).then(res=>{
			  // console.log(res)
			  this.jsApiCall(res.data)
		  })
	  },
 onInput(key) {
      this.password = (this.password + key).slice(0, 6);
	  // 密码到6位数
	  if(this.password.length==6){
		  // 密码正确关闭
		  // if(this.password==this.isPasswrod){
			  this.show=false
			  this.showKeyboard=false
			  this.isfu=1
			  // setTimeout(()=>{
				 //  this.$router.push({name:'recharge_ok',query:{type:this.$route.query.type}})
			  // },5000)
			  usersWithdraw({money:this.value,password:this.password}).then(res=>{
				  Toast(res.msg)
				  this.isfu=0
				  this.isclick=1
				  if(res.code==1){
					  this.$router.push({name:'recharge_ok',query:{type:this.$route.query.type,money:this.value}})
				  }
			  })
		  // }else{
			 //  Toast.fail('密码错误');
		  // }
	  }
    },
    onDelete() {
      this.password = this.password.slice(0, this.password.length - 1);
	  console.log(this.password,'ssasd')
    }
  },
  
  mounted() {
	  let title=this.$route.query.type==1?'充值':"提现"
  	document.title = title;   
	getClientUsersAccount().then(res=>{
		this.balance=res.data.loose_change
	})
	// this.gethotcity()
	// this.getActivityDataCount()
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.recharge {
		width: 100%;
		min-height: 100vh;
		background-color: #F7F8FF;

		.van-password-input__security::after {
			border-radius: 0;
		}

		.van-password-input__security li {
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
			border-left: 1px solid #ccc;
		}

		.van-password-input__security li:last-child {
			border-right: 1px solid #ccc;
		}

		.van-password-input__security {
			height: 42px;
		}

		.van-cell {
			padding: 24.5px 15px;
		}

		// .van-popup--center {
		// 	transform: translate3d(-50%, -90%, 0);
		// }

		.right {
			color: #28334A;
			font-size: 1rem;
			font-weight: bold;
		}

		.rotate {
			transform: rotate(0deg);
			animation: load 1.2s linear 0s infinite;
		}

		@-webkit-keyframes load {
			0% {
				transform: rotate(0deg);
			}

			100% {
				transform: rotate(360deg);
			}
		}

		.tis {
			color: #919CB0;
			font-size: 12px;
			text-align: left;
			padding-left: 2.25rem;
			padding-right: 1rem;
			position: relative;
			margin-top: 16px;
			line-height: 20px;

			&::before {
				display: block;
				content: '';
				width: 0.75rem;
				height: 0.75rem;
				background: url(../../assets/Train/icon_tips@2x.png) no-repeat center;
				background-size: 100% 100%;
				position: absolute;
				top: 6px;
				left: 15px;
			}
		}

		.tis1 {
			margin-top: 16px;
			padding-left: 15px;

			&::before {
				display: none;
			}
		}

		.btn {
			background-color: #0081FF;
			width: 78%;
			height: 42px;
			text-align: center;
			line-height: 42px;
			border-radius: 20px;
			margin: 28px auto 0;
			color: #fff;
		}

		.popup {
			visibility: hidden;
			opacity: 0;
			width: 281px;
			height: 210px;
			border-radius: 8px;
			background-color: #fff;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -90%);

			.title {
				padding: 12px 0;
				border-bottom: 1px solid #E5E5E5;
				font-weight: bold;
			}

			.qian {
				font-size: 24px;
				color: #28334A;
				margin-bottom: 12px;
			}
		}

		.show {
			visibility: visible;
			opacity: 1;
			transition: .5s all;

		}

		.mask {
			display: block;
			content: '';
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			background-color: rgba(0, 0, 0, .6);
		}

		[class*=van-hairline]::after {
			border-color: #CCCCCC;
		}

		input::-webkit-input-placeholder {
			color: #919CB0
		}
		// 弹出层
		.popupConten {
			padding: 10px 0px 00px;
			text-align: left;
			font-size: 16px;
			position: relative;
			height: 100%;
			.header {
				height: 30px;
				text-align: center;
				font-size: 16px;
				font-weight: bold;
			}
		
			.content {
				    height: 95%;
				    overflow: auto;
				    padding: 0 15px;
			}
		
			input {
				background-color: #f4f4f4;
				width: 100%;
				height: 36px;
				border-radius: 6px;
				padding: 0 13px;
			}
		
			input::-webkit-input-placeholder {
				color: #919cb0;
			}
		
			input::-moz-placeholder {
				/* Mozilla Firefox 19+ */
				color: #919cb0;
			}
		
			input:-moz-placeholder {
				/* Mozilla Firefox 4 to 18 */
				color: #919cb0;
			}
		
			input:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: #919cb0;
			}
		
			.form {
				padding-bottom: 20px;
		
				.left {
					height: 36px;
					line-height: 36px;
				}
			}
		
			.row {
				margin: 25px 0;
			}
		
			.formBtn {
				padding: 10px 0;
		
				.btn {
					border-radius: 21px;
					width: 8.75rem;
					height: 2.625rem;
					background-color: #0081ff;
					color: #fff;
					line-height: 2.625rem;
					text-align: center;
					margin: 0 auto;
				}
			}
		
			p {
				margin-bottom: 15px;
			}
		}
		
	}
</style>
