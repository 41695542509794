<template>
	<div class="release">
		<div v-if="!isMap">
			<div class="Box" style="border-bottom: 1px solid #F4F4F4;">
				<!-- 标题 -->
				<div class="title">培训名称 <span v-if="train_name.isMust">*</span></div>
				<div class="container" style="padding: 10px 0;color: #919CB0;">
					<van-row v-if="$route.query.act_type==1">
						<!-- <van-col span="11"> -->
						<!-- <div class="text">现场应急救护员培训-</div> -->
						<!-- </van-col> -->
						<van-col span="24">
							<van-field v-model="SubmitData.act_name" clearable placeholder="请输入课程名称" style="padding: 5px 0;font-size: 15px;" />
							</van-cell-group>
						</van-col>
					</van-row>
					<van-row v-if="$route.query.act_type==2">
						<!-- <van-col span="11"> -->
						<!-- <div class="text">现场应急救护员培训-</div> -->
						<!-- </van-col> -->
						<van-col span="24">
							<van-field v-model="SubmitData.act_name" clearable placeholder="请输入课程名称" readonly style="padding: 5px 0;font-size: 15px;" />
							</van-cell-group>
						</van-col>
					</van-row>

				</div>

				<!-- 	<div class="container" style="padding: 0px 0 10px;color: #919CB0;">
				示例：现场应急救护员培训-广州第八十六中初三班
			</div> -->
			</div>
			<div class="Box" style="border-bottom: 1px solid #F4F4F4;" v-if="$route.query.act_type==2">
				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">培训中心 <span>*</span></div>
				<div class="container" style="padding: 10px 0;color: #919CB0;">

					<van-row>
						<!-- <van-col span="11"> -->
						<!-- <div class="text">现场应急救护员培训-</div> -->
						<!-- </van-col> -->
						<van-col span="24">
							<van-field v-model="SubmitData.train_center_name" clearable placeholder="培训中心最后会展示在导师证书上，请填写完整正确的培训中心名称" class="placeholder"
							 style="padding: 5px 0;font-size: 15px;" type="textarea" autosize rows="1" />
							</van-cell-group>
						</van-col>
					</van-row>

				</div>
			</div>
			<!-- <van-popup v-model="showPicker" position="bottom">
			<van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
		</van-popup> -->

			<div class="Box" style="border-bottom: 1px solid #F4F4F4;">
				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">培训地点 <span>*</span></div>
				<div class="flex">

					<div class="btn" @click="provinceClick">
						<span v-text="train_add.province.name"></span>
						<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" v-if="isAreaList!=='城市'" style="height: 20px;width: 20px;">
						<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" v-if="isAreaList=='城市'" style="height: 20px;width: 20px;">
					</div>
					<div class="btn" @click="provinceClick">
						<span v-text="train_add.city.name"></span>
						<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" v-if="isAreaList!=='城市'" style="height: 20px;width: 20px;">
						<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" v-if="isAreaList=='城市'" style="height: 20px;width: 20px;">
					</div>
					<div class="btn" @click="provinceClick">
						<span v-text="train_add.area.name"></span>
						<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" v-if="isAreaList!=='城市'" style="height: 20px;width: 20px;">
						<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" v-if="isAreaList=='城市'" style="height: 20px;width: 20px;">
					</div>
				</div>
				<div class="container hui flex van-fieldBox" style="padding: 10px 0;    flex-wrap: nowrap;">
					<!-- <input type="text" placeholder="请输入具体地址" v-model="SubmitData.act_addr" :style="!SubmitData.act_addr?'width: 106px;':''"> -->
					<van-field v-model="SubmitData.act_addr" rows="1" :autosize="{ minHeight: 26 }" type="textarea" placeholder="请输入具体地址"
					 :style="!SubmitData.act_addr?'width: 116px;height:24px':''" clearable />
					<!-- 请输入具体地址 <span style="font-size: 12px;"> -->
					<span v-if="!SubmitData.act_addr" style="font-size: 12px;">（例：xxxl路+xxx号+地标名称+房间号）</span>
					</span>
				</div>
			</div>
			<div class="Box" style="border-bottom: 1px solid #F4F4F4;">
				<!-- <div class="checkbox">
				<van-checkbox v-model="checked" @change="checked_BZ">
					<img slot="icon" slot-scope="props" :src="props.checked ? activeIcon : inactiveIcon" style="width: 20px;height: 20px;vertical-align: top;">
					<span style="color: #919CB0;">
						设置地点标注
					</span>
					<span style="font-size: 12px;color: #919CB0;">
						（非必选，用于学员导航到培训地点）
					</span>
				</van-checkbox>
			</div> -->
				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">
					培训开始时间
					<span>*</span></div>
				<div class="flex " :class="SubmitData.act_start_time?'':'hui'" style="padding: 19px 0;" @click="px_startTimeClick('培训开始时间')">
					<div v-text="SubmitData.act_start_time?SubmitData.act_start_time:'请选择活动开始时间'"></div>
				</div>
			</div>
			<!-- <div class="Box" style="border-bottom: 1px solid #F4F4F4;" v-if="$route.query.act_type==1">
			<div class="title" style="margin-top: 28px;">
				考核开始时间
				<span>*</span></div>
			<div class="flex " :class="SubmitData.check_start_time?'':'hui'" style="padding: 19px 0;" @click="px_startTimeClick('考核开始时间')">
				<div v-text="SubmitData.check_start_time?SubmitData.check_start_time:'请选择考核开始时间'"></div>
			</div>
		</div> -->
			<div class="Box" style="border-bottom: 1px solid #F4F4F4;">

				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">
					培训结束时间
					<span>*</span></div>
				<div class="flex " :class="SubmitData.act_end_time?'':'hui'" style="padding: 19px 0;" @click="px_startTimeClick('培训结束时间')">
					<div v-text="SubmitData.act_end_time?SubmitData.act_end_time:'请选择培训结束时间'"></div>
				</div>
			</div>

			<div class="Box" style="border-bottom: 1px solid #F4F4F4;" v-if="$route.query.act_type==1">

				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">
					培训课程（可多选）
					<span>*</span></div>
				<div class="flex hui" style="padding: 19px 0;">
					<van-checkbox-group v-model="result" @change="checkClick">
						<van-checkbox :name="i" v-for="(item,i) in trainingCourse" :key="i" style="margin-bottom:22px ;"><span v-text="item.name"
							 class="hui"></span></van-checkbox>

					</van-checkbox-group>
				</div>
			</div>
			<div class="Box" style="border-bottom: 1px solid #F4F4F4;" v-if="$route.query.act_type==2">

				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">
					培训课程
					<span>*</span></div>
				<div class="flex hui" style="padding: 19px 0;">
					<van-radio-group v-model="result" @change="radioClick">
						<van-radio :name="i" v-for="(item,i) in trainingCourse" :key="i" :style="i==trainingCourse.length-1?'':'margin-bottom:22px ;'"><span
							 v-text="item.name" class="hui"></span></van-radio>
					</van-radio-group>
				</div>
			</div>
			<!-- <div class="Box" style="border-bottom: 1px solid #F4F4F4;position: relative;"> -->

			<!-- 标题 -->
			<!-- <div class="title" style="margin-top: 28px;">
				考核课程
				<span>*</span></div>
			<div class="flex hui" style="padding: 19px 0;justify-content: space-between;">
				<div>
					<div v-for="(item,i) in kh_curriculum" v-if="kh_curriculum.length" :key="i">
						<span v-text="item"></span>
					</div>
					<div v-else>
						<span class="hui">请选择</span>
					</div>
				</div>
				<div @click="iskh_curriculum=!iskh_curriculum">
					<img src="../../assets/Train/icon_xuanze_blue@2x.png" alt="" style="width: 20px;height: 20px;vertical-align: top;">
				</div>

			</div> -->
			<!-- 弹窗内容 -->
			<!-- <div class="PopupBox" :style="!iskh_curriculum?'opacity: 0;visibility: hidden;':'opacity: 1;visibility:visible;'">
				<div class="PopupBox1" style="">
					<div class="titlePopup">考核课程选择</div>
					<div style="width: 100%;overflow: auto;padding-top: 20px;">
						<van-checkbox-group v-model="keListact">
							<van-checkbox :name="item.id" v-for="(item,i) in keList" :key="i" style="margin-bottom:15px ;"><span v-text="item.name"
								 class="hui"></span></van-checkbox>
						</van-checkbox-group>
						<div style="margin-bottom: 15px;" @click="kh_currClick">
							<div class="indexbtn1">确定</div>
						</div>
					</div>

				</div>
			</div>
		</div> -->
			<div class="Box" style="border-bottom: 1px solid #F4F4F4;" v-if="$route.query.act_type==2">
				<div class="title" style="margin-top: 28px;">
					承办机构
					<span>*</span></div>
				<div class="flex hui" style="padding: 19px 0;">
					<input type="text" placeholder="请输入承办机构名称" v-model="SubmitData.org_name" readonly>
				</div>
			</div>
			<div class="Box" style="border-bottom: 1px solid #F4F4F4;">

				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">
					培训讲师
					<span>*</span></div>
				<div class="flex hui" style="padding: 19px 0;">
					<input type="text" placeholder="请输入发起人" v-model="SubmitData.act_author">
				</div>
			</div>

			<div class="Box" style="border-bottom: 1px solid #F4F4F4;">
				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">
					允许报名的最大人数
					<span>*</span></div>
				<div class="flex hui" style="padding: 19px 0;">
					<input type="number" placeholder="0" v-model="SubmitData.limit_num" style="width: 60px;">
					<span v-text="'(需要助教导师的最大人数：'+ max +')'"> </span>
				</div>
			</div>
			<div class="Box" style="border-bottom: 1px solid #F4F4F4;">

				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">
					发证机构
					<span>*</span></div>
				<div class="flex" style="padding: 19px 0;" @click="isfaList=true">
					<div :class="faVlaue?'':'hui'" v-text="faVlaue?faVlaue:'请输入发证机构'"></div>
					<!-- <input type="number" placeholder="请输入发证机构" v-model="fzjg"> -->
				</div>
			</div>
			<div class="Box" style="border-bottom: 1px solid #F4F4F4;">
				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">
					报名费用（元）
					<span>*</span></div>
				<div class="flex hui" style="padding: 19px 0;">
					<input type="number" placeholder="0" v-model="SubmitData.cost" style="width: 60px;" max="500">
					<span v-text="'(范围：0-'+ maxCost +'元)'"> </span>
				</div>
			</div>
			<!-- <div class="Box" style="border-bottom: 1px solid #F4F4F4;" v-if="$route.query.act_type==2">
		
			<div class="title" style="margin-top: 28px;">
				发证机构
				<span>*</span></div>
			<div class="flex" style="padding: 19px 0;" >
				<div :class="faVlaue?'':'hui'" v-text="faVlaue?faVlaue:'请输入发证机构'"></div>
			</div>
		</div> -->
		<div class="Box" style="border-bottom: 1px solid #F4F4F4;" v-if="$route.query.act_type==1 || $route.query.act_type==2">
		
			<!-- 标题 -->
			<div class="title" style="margin-top: 28px;">
				是否是内部培训（内部培训不显示在培训报名功能板块，如选择是内部培训，请在培训详情及管理页面分享报名二维码给学员和助教导师报名）
				<span>*</span></div>
			<div class="flex hui" style="padding: 19px 0;">
				<van-radio-group v-model="SubmitData.is_inward" style="display: flex;">
					<van-radio name="0" style="margin-right: 3.125rem;">公开</van-radio>
					<van-radio name="1">内部培训</van-radio>
				</van-radio-group>
			</div>
		</div>
			<div class="Box" style="border-bottom: 1px solid #F4F4F4;" v-if="$route.query.act_type==1&&user_type==2">

				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">
					是否需要主任导师督导（如需要，请在培训详情及管理页面自行邀约)
					<span>*</span></div>
				<div class="flex hui" style="padding: 19px 0;">
					<van-radio-group v-model="SubmitData.need_tutor" style="display: flex;">
						<van-radio name="1" style="margin-right: 3.125rem;">需要</van-radio>
						<van-radio name="0">不需要</van-radio>
					</van-radio-group>
				</div>
			</div>
			
			<div class="Box" style="border-bottom: 1px solid #F4F4F4;">

				<!-- 标题 -->
				<div class="title" style="margin-top: 28px;">
					备注（提示学员来参加培训时的一些必要准备提示信息）
				</div>
				<div class="flex hui" style="padding: 0px 0;">
					<!-- <input type="bz" placeholder="请填写备注信息" v-model="SubmitData.act_remark"> -->
					<van-field v-model="SubmitData.act_remark" rows="1" :autosize="{ minHeight: 26 }" type="textarea" placeholder="请填写备注信息"
					 clearable style="padding-left: 0;" />
				</div>
			</div>
			<div class="bottom" style="position: relative;" v-if="$route.query.act_type==1">
				<div class="btn" :class="
			SubmitData.act_name&&
			SubmitData.institution_id&&
			SubmitData.act_addr&&
			SubmitData.act_start_time&&
			SubmitData.act_end_time&&
			SubmitData.act_type&&
			SubmitData.act_author&&
			SubmitData.provinceid&&
			SubmitData.cityid&&
			SubmitData.areaid&&
			SubmitData.limit_num&&
			SubmitData.cost!==''?'':'bluyBtn'"
				 @click="SubmitDataClick">立即发布</div>
			</div>
			<div class="bottom" style="position: relative;" v-if="$route.query.act_type==2">
				<div class="btn" :class="
			SubmitData.train_center_name&&
			SubmitData.act_name&&
			SubmitData.act_addr&&
			SubmitData.act_start_time&&
			SubmitData.act_end_time&&
			SubmitData.act_type&&
			SubmitData.act_author&&
			SubmitData.provinceid&&
			SubmitData.cityid&&
			SubmitData.areaid&&
			SubmitData.limit_num&&
			SubmitData.cost!==''?'':'bluyBtn'"
				 @click="SubmitDataClick">立即发布</div>
			</div>
		</div>
		<!-- 弹窗内容 -->
		<van-popup v-model="isAreaList" position="bottom">
			<div style="width: 100%;">
				<van-area :area-list="areaList" :value="SubmitData.areaid" :columns-num="3" title="地址" @confirm="confirm" @cancel="cancel" />
			</div>
		</van-popup>
		<van-popup v-model="isfaList" position="bottom">
			<div style="width: 100%;">
				<van-picker show-toolbar title="发证机构" :columns="faList" @cancel="onCancel" @confirm="onConfirm" />
			</div>
		</van-popup>
		<van-popup v-model="isDate" position="bottom">
			<div style="width: 100%;">
				<van-datetime-picker v-model="currentDate" type="datetime" :min-date="minDate" :max-date="maxDate" :formatter="formatter"
				 @confirm="dateConfirm" @cancel="dateCancel" />
			</div>
		</van-popup>
		<div class="" style="width: 100%;height: 100vh;position: absolute;top: 0;left: 0;z-index: 55;" v-if="isMap">
			<mapWarper @colse="colse" @postAddrDate="postAddrDate" v-if="isMap"></mapWarper>
		</div>
	</div>

</template>

<script>
	// import vConsole from '@/util/vconsole.js'
	import mapWarper from '@/components/mapWarper.vue'
	import {
		Toast,
		Dialog,
	} from 'vant';
	import {
		getOfficialCourse,
		getInstitutions,
		postActivity
	} from '@/api'
	import areaList from '../../area.js'
	export default {
		name: 'tutorHomepage',
		components: {
			mapWarper
		},
		watch: {
			isover() {

			},
			'SubmitData.limit_num'() {
				let s = parseInt((this.SubmitData.limit_num / 6) - 0.1)
				this.max = s
			},
			'SubmitData.cost'() {
				let s = this.SubmitData.cost
				if (s > this.maxCost) {
					this.SubmitData.cost = 0
					this.$Toast('最大值' + this.maxCost + '元')
				} else if (s < 0) {
					this.SubmitData.cost = 0
					this.$Toast('最小值0元')
				}
			},
		},
		data() {
			return {
				user_type: 0,
				maxCost: this.$route.query.act_type == 1 ? 500 : 600,
				// 显示地图
				isMap: false,
				activeIcon: require('../../assets/Train/icon_selectbox_pre@2x.png'),
				inactiveIcon: require('../../assets/Train/icon_selectbox_nor@2x.png'),
				lan: '',
				lat: '',
				isfaList: false,
				faListid: [],
				faList: [],
				faVlaue: this.$route.query.act_type == 1 ? '' : '广州天鹰救援技术服务公司',
				max: 0,
				isDate: false,
				minDate: new Date(),
				maxDate: new Date(2025, 10, 1),
				currentDate: new Date(),
				bz: '',
				radio: '1',
				fzjg: '',
				number: '',
				fqr: '',
				cbjg: '',
				keListact: [],
				kh_curriculum: [],
				// 考核课程选择
				iskh_curriculum: false,
				result: [],
				// 是否设置地点标注
				checked: false,
				areaList: areaList,
				showPicker: false,
				title: '发布培训通知',
				trainingCourse: [{
						id: 1,
						name: '心肺复苏术'
					},
					{
						id: 2,
						name: '自动体外除颤器（AED）使用方法'
					},
					{
						id: 3,
						name: '气道梗阻及海姆立克急救法'
					},
					{
						id: 4,
						name: '球囊面罩通气技术'
					},
					{
						id: 5,
						name: '创伤包扎'
					}
				],
				train_name: {
					name: '',
					isMust: true
				},
				train_add: {
					province: {
						name: '广东',
						code: '440000'
					},
					city: {
						name: '广州',
						code: '440100'
					},
					area: {
						name: "天河区",
						code: '440106'
					},
					isMust: true,
				},
				isAreaList: false,
				px_startTime: 0,
				px_endTime: 0,
				kh_startTime: 0,
				keList: [],
				type: '',
				lastTime: 0,
				// 提交数据
				SubmitData: {
					// 是否为内部培训
					is_inward:'1',
					// 导师班培训地点
					train_center_name: '',
					// 费用
					cost: 0,
					// 发证机构id
					institution_id: this.$route.query.act_type == 1 ? 0 : 1,
					// 名称
					act_name: this.$route.query.act_type == 1 ? '现场急救行动指南线下培训' : '现场急救行动指南导师线下培训课程',
					// 活动地点
					act_addr: '',
					// 活动开始时间
					act_start_time: '',
					// 考核开始时间
					check_start_time: '',
					// 活动结束时间
					act_end_time: '',
					// 活动类型，1学员班，2导师班
					act_type: this.$route.query.act_type,
					// 发布者
					act_author: '',
					// 省份id
					provinceid: '440000',
					// 城市id
					cityid: '440100',
					// 区域id
					areaid: '440106',
					// 最大报名人数
					limit_num: '',
					// 是否需要导师督导，1需要，0不需要
					need_tutor: '0',
					// 考核课程，如：[2,3,4],234是指父级里的id
					course: [],
					// 培训课程，如：[2,3,4],234是指父级里的id
					train_course: [],
					// 备注
					act_remark: '',
					// 经度
					longitude: '',
					// 纬度
					latitude: '',
					// 承办机构/站点
					org_name: this.$route.query.act_type == 1 ? '' : '现场急救科普工作室',
				},
			}
		},
		methods: {
			// 选择位置
			colse() {
				this.isMap = false
			},
			postAddrDate(loc) {
				this.isMap = false
				let _this = this
				console.log(loc)
				if (this.checked && loc) {
					this.SubmitData.longitude = loc.latlng.lng
					this.SubmitData.latitude = loc.latlng.lat
					this.SubmitData.act_addr = loc.poiaddress
					var data = {
						location: loc.latlng.lat + "," + loc.latlng.lng,
						/*换成自己申请的key*/
						key: "TO2BZ-EXTYI-47RG5-5QFZM-X6W6O-JBBYG",
						get_poi: 0
					}
					var url = "https://apis.map.qq.com/ws/geocoder/v1/?";
					data.output = "jsonp";
					$.ajax({
						type: "get",
						dataType: 'jsonp',
						data: data,
						jsonp: "callback",
						jsonpCallback: "QQmap",
						url: url,
						success: function(res) {
							/*json对象转为文本 var aToStr=JSON.stringify(a);*/
							let adcode = res.result.ad_info.adcode
							let province = {
								code: adcode.slice(0, 2) + '0000',
								name: res.result.address_component.province
							}
							let city = {
								code: adcode.slice(0, 4) + '00',
								name: res.result.address_component.city
							}
							let area = {
								code: adcode,
								name: res.result.address_component.district
							}
							console.log(province, city, area)
							_this.SubmitData.provinceid = province.code
							_this.SubmitData.cityid = city.code
							_this.SubmitData.areaid = area.code
							_this.train_add.province = province
							_this.train_add.city = city
							_this.train_add.area = area

						},
						error: function(err) {
							alert("服务端错误，请刷新浏览器后重试")
						}
					})
				} else {
					this.SubmitData.longitude = ''
					this.SubmitData.latitude = ''
				}
			},
			checked_BZ(res) {
				// console.log(res)
				if (res) {
					this.isMap = true
					// this.SubmitData.longitude = this.lan
					// this.SubmitData.latitude = this.lat
				} else {
					this.SubmitData.longitude = ''
					this.SubmitData.latitude = ''
				}
			},
			onCancel() {
				this.isfaList = false
			},
			// 拿到发证机构 的下标
			onConfirm(value, index) {
				this.isfaList = false
				this.SubmitData.institution_id = this.faListid[index]
				this.faVlaue = value
			},
			SubmitDataClick() {

				// 防止多数点击
				let now = new Date().valueOf();
				if(this.lastTime == 0) {
					this.lastTime = now
				} else {
					if((now - this.lastTime) > 2000) {
						this.lastTime = now
					} else {
						return false;
					}
				}
			
				if (this.$route.query.act_type == 1) {

					if (
						this.SubmitData.institution_id &&
						this.SubmitData.act_name &&
						this.SubmitData.act_addr &&
						this.SubmitData.act_start_time &&
						this.SubmitData.act_end_time &&
						this.SubmitData.act_type &&
						this.SubmitData.act_author &&
						this.SubmitData.provinceid &&
						this.SubmitData.cityid &&
						this.SubmitData.areaid &&
						this.SubmitData.limit_num &&
						this.SubmitData.train_course &&
						this.SubmitData.cost >= 0
						// this.SubmitData.course
					) {
						// console.log(this.keListact,'考证')
						// this.SubmitData.course = this.keListact
						console.log(this.SubmitData)
						postActivity(this.SubmitData).then(res => {
							if (res.code == 1) {
								Dialog.alert({
									title: '提示',
									message: '发布成功，请耐心等待，审核通过后可在培训列表查看',
								}).then(() => {
									this.$router.go(-1);
								});
							} else {
								Toast.success(res.msg);
							}
						})
					} else {
						Toast('请完善必填项');
					}
				} else {

					if (
						this.SubmitData.act_name &&
						this.SubmitData.act_addr &&
						this.SubmitData.act_start_time &&
						this.SubmitData.act_end_time &&
						this.SubmitData.act_type &&
						this.SubmitData.act_author &&
						this.SubmitData.provinceid &&
						this.SubmitData.cityid &&
						this.SubmitData.areaid &&
						this.SubmitData.limit_num &&
						this.SubmitData.is_inward &&
						this.SubmitData.cost >= 0
						// this.SubmitData.course
					) {
						// console.log(this.keListact,'考证')
						// this.SubmitData.course = this.keListact
						let arr = {
							act_name: this.SubmitData.act_name,
							act_addr: this.SubmitData.act_addr,
							act_start_time: this.SubmitData.act_start_time,
							act_end_time: this.SubmitData.act_end_time,
							act_type: this.SubmitData.act_type,
							act_author: this.SubmitData.act_author,
							provinceid: this.SubmitData.provinceid,
							cityid: this.SubmitData.cityid,
							areaid: this.SubmitData.areaid,
							limit_num: this.SubmitData.limit_num,
							is_inward: this.SubmitData.is_inward,
							// course:this.SubmitData.course,
							train_course: this.SubmitData.train_course,
							org_name: this.SubmitData.org_name,
							institution_id:this.SubmitData.institution_id ,
							train_center_name: this.SubmitData.train_center_name
						}
						console.log(this.SubmitData)
						postActivity(arr).then(res => {
							if (res.code == 1) {
								Dialog.alert({
									title: '提示',
									message: '发布成功，请耐心等待，审核通过后可在培训列表查看',
								}).then(() => {
									this.$router.go(-1);
								});
							} else {
								Toast.success(res.msg);
							}

						})
					} else {
						Toast('请完善必填项');
					}
				}
			},
			format(date) {
				let year = date.getFullYear().toString()
				let mouth = (date.getMonth() + 1).toString()
				let day = date.getDate().toString()
				let hours = date.getHours().toString()
				let minutes = date.getMinutes().toString()
				return `${year}-${this.bu(mouth)}-${this.bu(day)} ${this.bu(hours)}:${this.bu(minutes)}`
			},
			timeContrast(num) {
				let now = new Date();
				now.setDate(now.getDate() + num);
				now.setHours(7);
				now.setMinutes(59);
				now.setMilliseconds(59);
				return Math.round(new Date(now) / 1000);
			},
			bu(date) {
				return date < 10 ? '0' + date : date
			},
			// 时间选择事件函数
			dateConfirm(res) {
				let date = this.format(res)
				switch (this.type) {
					case '培训开始时间':
						this.px_startTime = Math.round(new Date(date) / 1000)
						console.log('培训开始时间:', Math.round(new Date(date) / 1000))
						this.SubmitData.act_start_time = date

						// 学员班+4小时，导师班+8小时
						let curTime = new Date(parseInt(this.px_startTime * 1000))
						let hours = this.$route.query.act_type == 1 ? 4 : 8;
						curTime.setHours(curTime.getHours() + hours)
						this.SubmitData.act_end_time = this.format(curTime)

						// 今天19点之前最早能发布明天的活动，今天19点之后最早能发布后天的活动
						let nowHours = new Date().getHours();
						if(nowHours >= 19) {
							if(Math.round(new Date(date) / 1000) < this.timeContrast(2)) {
								Toast('最早能发布后天早上8点的活动');
								this.SubmitData.act_start_time = ''
								this.SubmitData.act_end_time = ''
							}
						} else {
							if(Math.round(new Date(date) / 1000) < this.timeContrast(1)) {
								Toast('最早能发布明天早上8点的活动');
								this.SubmitData.act_start_time = ''
								this.SubmitData.act_end_time = ''
							}
						}

						if (this.kh_startTime && this.px_endTime) {
							if (this.px_startTime > this.kh_startTime) {
								Toast('应小于考核时间');
								this.SubmitData.act_start_time = ''
							} else if (this.px_startTime > this.px_endTime) {
								Toast('应小于结束时间');
								this.SubmitData.act_start_time = ''
							}
						} else if (this.kh_startTime && !this.px_endTime) {
							if (this.px_startTime > this.kh_startTime) {
								Toast('应小于考核时间');
								this.SubmitData.act_start_time = ''
							}
						} else if (this.px_endTime && !this.kh_startTime) {
							if (this.px_startTime > this.px_endTime) {
								Toast('应小于结束时间');
								this.SubmitData.act_start_time = ''
							}
						}
						break;
					case '考核开始时间':
						this.kh_startTime = Math.round(new Date(date) / 1000)
						this.SubmitData.check_start_time = date
						if (this.px_startTime && this.px_endTime) {
							if (this.kh_startTime < this.px_startTime) {
								this.SubmitData.check_start_time = ''
								Toast('应大于开始时间');
							} else if (this.kh_startTime > this.px_endTime) {
								this.SubmitData.check_start_time = ''
								Toast('应小于结束时间');
							}
						} else if (this.px_startTime && !this.px_endTime) {
							if (this.kh_startTime < this.px_startTime) {
								this.SubmitData.check_start_time = ''
								Toast('应大于开始时间');
							}
						} else if (this.px_endTime && !this.px_startTime) {
							if (this.kh_startTime > this.px_endTime) {
								this.SubmitData.check_start_time = ''
								Toast('应小于结束时间');
							}
						}
						console.log(Math.round(new Date(date) / 1000))

						break;
					case '培训结束时间':
						this.px_endTime = Math.round(new Date(date) / 1000)
						this.SubmitData.act_end_time = date
						if (this.px_startTime && this.kh_startTime) {
							if (this.px_endTime < this.px_startTime) {
								this.SubmitData.act_end_time = ''
								Toast('应大于开始时间');
							} else if (this.px_endTime < this.kh_startTime) {
								this.SubmitData.act_end_time = ''
								Toast('应大于考核时间');
							}
						} else if (this.px_startTime && !this.kh_startTime) {
							if (this.px_endTime < this.px_startTime) {
								this.SubmitData.act_end_time = ''
								Toast('应大于开始时间');
							}
						} else if (this.kh_startTime && !this.px_startTime) {
							if (this.px_endTime < this.kh_startTime) {
								this.SubmitData.act_end_time = ''
								Toast('应大于考核时间');
							}
						}

						break;
					default:
						break;
				}
				this.isDate = false
			},
			dateCancel(res) {
				this.isDate = false
			},
			formatter(type, value) {
				if (type === 'year') {
					return `${value}年`;
				} else if (type === 'month') {
					return `${value}月`
				} else if (type === 'day') {
					return `${value}日`
				} else if (type === 'hour') {
					return `${value}时`
				} else if (type === 'minute') {
					return `${value}分`
				}
				return value;
			},
			px_startTimeClick(type) {
				this.type = type
				this.isDate = true
			},
			// 培训课程选择
			checkClick() {
				let train_course = []
				let keList = []
				for (let i = 0; i < this.result.length; i++) {
					keList.push(...this.trainingCourse[this.result[i]].course)
					// console.log(this.trainingCourse[this.result[i]])
					train_course.push(this.trainingCourse[this.result[i]].id)
				}
				this.keList = keList
				this.SubmitData.train_course = train_course
				console.log(this.SubmitData)
				// this.SubmitData.train_course=this.result
				// console.log(this.result)

			},
			radioClick() {

				this.SubmitData.train_course = this.trainingCourse[this.result].id
				console.log(this.SubmitData)
			},
			provinceClick() {

				this.isAreaList = true
			},
			cancel() {
				console.log('222')
				this.isAreaList = false
			},
			confirm(res) {
				console.log(res)
				// this.province=res[0].name
				// this.city=res[1].name
				// this.cityid=res[1].code
				this.SubmitData.provinceid = res[0].code
				this.SubmitData.cityid = res[1].code
				this.SubmitData.areaid = res[2].code
				this.train_add.province = res[0]
				this.train_add.city = res[1]
				this.train_add.area = res[2]



				this.isAreaList = false
			},
			kh_currClick() {
				this.kh_curriculum = []
				let kh_curriculum = this.keListact
				this.iskh_curriculum = false
				// this.SubmitData.course=[]
				for (let i = 0; i < this.keList.length; i++) {
					for (let j = 0; j < kh_curriculum.length; j++) {
						if (this.keList[i].id == kh_curriculum[j]) {
							this.kh_curriculum.push(this.keList[i].name)
							this.SubmitData.course.push(this.keList[i].id)
							console.log(this.SubmitData.course)
							// this.SubmitData.course.push(this.trainingCourse[this.result[i]].id)/*  */
						}
					}

				}
				console.log(this.kh_curriculum)
			},
			addressDetail() { //获取地理位置 
				var self = this;
				//全局的this在方法中不能使用，需要重新定义一下
				var geolocation = new BMap.Geolocation();
				//调用百度地图api 中的获取当前位置接口
				geolocation.getCurrentPosition(function(r) {
					if (this.getStatus() == BMAP_STATUS_SUCCESS) {
						//获取当前位置经纬度
						var myGeo = new BMap.Geocoder();
						myGeo.getLocation(new BMap.Point(r.point.lng, r.point.lat), function(result) {
							// alert(result.addressComponents.district + result.addressComponents.street)
							if (result) {
								self.lan = result.point.lng
								self.lat = result.point.lat
								// //根据当前位置经纬度解析成地址
								// self.ADDRESS_DETAIL(result.addressComponents.district + result.addressComponents.street); //在vuex中存入区、街道地址信息。其他地方需要使用直接调用

								// self.geohash = result.point.lat + "," + result.point.lng;
								// self.SAVE_GEOHASH(self.geohash);

								// self.latitude = result.point.lat;
								// self.longitude = result.point.lng;
								// console.log(result.point.lat); self.RECORD_ADDRESS({'latitude':result.point.lat,'longitude':result.point.lng});
							}
						});
					}
				});
			},


		},
		mounted() {
			var _this = this;
			this.addressDetail()
			document.title = this.title;
			this.user_type = localStorage.getItem('user_type')
			getOfficialCourse({
				act_type: this.$route.query.act_type
			}).then(res => {
				this.trainingCourse = res.data
				// console.log(this.trainingCourse)
			})
			getInstitutions().then(res => {
				// this.faList=res.data
				// console.log(res.data,'res.data')
				for (let i = 0; i < res.data.length; i++) {
					// console.log(res.data[i].name,i)
					this.faList.push(res.data[i].name)
					this.faListid.push(res.data[i].id)

				}
			})
			// 默认时间当天20点前-->第二天早8点；当天20点后-->第三天早8点
			let minDate = new Date();
			let nowHours = new Date().getHours();
			let dayNum =  nowHours >= 20 ? 2 : 1;
			minDate.setDate(minDate.getDate() + dayNum);
			minDate.setHours(8);
			minDate.setMinutes(0);
			minDate.setMilliseconds(0);
			this.minDate = new Date(minDate);
			// console.log(BMap)
			// //获取城市列表接口设置中心点
			// let citylocation=null
			//    citylocation = new qq.maps.CityService({
			//        complete : function(result){
			// 		console.log(result)
			//            // map.setCenter(result.detail.latLng);
			//        }
			//    });
			//    //调用searchLocalCity();方法    根据用户IP查询城市信息。
			//    citylocation.searchLocalCity();
			//根据浏览器定位，之后显示当前位置
			// var geolocation = new BMap.Geolocation();
			// geolocation.getCurrentPosition(function(r){
			// 	if(this.getStatus() == BMAP_STATUS_SUCCESS){
			// 		var mk = new BMap.Marker(r.point);
			// 		map.addOverlay(mk);
			// 		map.panTo(r.point);
			// 		var latCurrent = r.point.lat;
			// 		var lngCurrent = r.point.lng;
			// 		alert('您的位置：'+r.point.lng+','+r.point.lat);
			// 		//设置导航终点，起始位置到终点位置
			// 		location.href = "http://api.map.baidu.com/direction?origin=" + latCurrent + "," + lngCurrent + 
			// 		"&destination=30.4325,111.182311&mode=driving&region=随便写的一个地址&output=html";
			// 	}
			// 	else {
			// 		alert('failed'+this.getStatus());
			// 	}        
			// });
		}

	}
</script>

<style lang="scss" scoped>
	.release {
		background-color: #fff;
		padding: 20px 15px;
		font-size: 0.9375rem;

		input {
			color: #28334A;
		}

		::-webkit-input-placeholder {
			/* WebKit, Blink, Edge */
			color: #919CB0;
			font-size: 15px;
		}

		:-moz-placeholder {
			/* Mozilla Firefox 4 to 18 */
			color: #919CB0;
			font-size: 15px;
		}

		::-moz-placeholder {
			/* Mozilla Firefox 19+ */
			color: #919CB0;
			font-size: 15px;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: #919CB0;
			font-size: 15px;
		}

		.placeholder {
			::-webkit-input-placeholder {
				/* WebKit, Blink, Edge */
				color: #919CB0;
				font-size: 15px;
			}

			:-moz-placeholder {
				/* Mozilla Firefox 4 to 18 */
				color: #919CB0;
				font-size: 15px;
			}

			::-moz-placeholder {
				/* Mozilla Firefox 19+ */
				color: #919CB0;
				font-size: 15px;
			}

			:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: #919CB0;
				font-size: 15px;
			}
		}

		.PopupBox {
			position: absolute;
			bottom: 120%;
			left: 0;
			// transform: translate(-50%,-50%);
			background-color: #fff;
			border-radius: 12px;
			width: 100%;
			box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, .1);
			transition: all .5s;

			.PopupBox1 {
				position: relative;
				padding-top: 40px;
				border: 1px solid #E5E5E5;
				border-radius: 12px;
				padding: 40px 0px 0px 20px;
			}

			.titlePopup {
				height: 40px;
				width: 100%;
				background-color: #F4F4F4;
				text-align: center;
				line-height: 40px;
				position: absolute;
				top: 0;
				border-radius: 12px;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
				left: 0;

			}

		}

		.Box {
			text-align: left;

			.title {
				font-size: 0.9375rem;
				font-weight: bold;

				span {
					color: red;
				}
			}

			.text {
				height: 2.25rem;
				line-height: 2.25rem;
			}

			.btn {
				border-radius: 0.375rem;
				padding: 9px 13px;
				background: #F4F4F4;
				color: #919CB0;
				border: 1px solid transparent;
				margin-right: 10px;
				margin-top: 1rem;
				width: 30%;
				position: relative;

				img {
					position: absolute;
					top: 50%;
					right: 10px;
					transform: translateY(-50%);
				}
			}


			.btn.activation {
				border-color: #0081FF;
				background: #FFFFFF;
				color: #0081FF;
			}

			.checkbox {
				margin: 23px 0 30px;
			}
		}

		.bluyBtn {
			background-color: #C6C8CA;
			color: #fff;
		}

		.van-fieldBox {
			.van-cell {
				padding-left: 0;
				padding-right: 0;
				padding: 0;
			}
		}
	}
</style>
