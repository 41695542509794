<template>
	<div class="chapter">
		<div class="titleBox" v-text="$route.query.chapter_title"></div>
		<div style="width: 100%;" v-if="istoUpdate">
			<div class="bigBox" v-for="(item, i) in subject" style="width: 100%;margin-bottom: 10px;padding: 0;" :key="i" v-if="isshua">
				<div class="ylcBox" v-show="!item.isplay">
					<img :src="item.course_cover" alt="" class="bg" style="position: relative;"/>
					<!-- <div class="flex top">
							<div><span v-text="item.course_name"></span></div>
						</div> -->
					<div class="flex bot">
						<div class="time">
							<span v-text="setTime(item.course_time)" style="font-size: 10px;color: #fff;"></span>
						</div>
						<div class="btnBo" @click="needTutorClick(i,item)">
							播放
						</div>
					</div>
					<!-- <img src="../../assets/user/bofan.png" alt="" class="conter" @click="playClick(i,item)"> -->
				</div> 
				<!-- v-if="item.isplay" -->
				<div class="ylcBox" v-if="item.isplay">
					<!-- <video :src="item.course_video" controls="controls" :id="'video' + item.id" preload="auto"></video> -->
					<video-player class="video-player vjs-custom-skin" ref="videoPlayer"  @play="onPlayerPlay($event,i)" :playsinline="playsinline" :id="'videoPlayer'+item.id" :options="playerOptions"  @playerStateChanged="playerStateChanged($event,i)" @timeupdate="onPlayerTimeupdate($event,i)" @loadeddata="onPlayerLoadeddata($event,i)"  @pause="onPlayerPause($event)" @ended="onPlayerEnded($event)"  @canplaythrough="onPlayerCanplay($event)"></video-player>
				</div>

				<div class="flex box">
					<!-- <div class="btn" v-if="!item.is_pass==1" @click="$router.push({name:'test',query:{id:item.id}})">
							开始答题
						</div>
						<div class="btn1" v-if="item.is_pass==1">
							合格
						</div> -->
					<div class="boxI flex" style="justify-content: space-between;">
						<div>
						本节学习进度：
						<span v-text="
							item.rate == '100.00'
							  ? '100%'
							  : item.rate
							  ? item.rate + '%'
							  : item.rate == 0
							  ? '0%'
							  : ''
						  "></span>
						  </div>
						  <div>
						  本节视频播放进度：
						  <span v-text="(item.stop_timeBai?item.stop_timeBai:0)+'%'"></span>
						  </div>
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="show" class="zhuren" @click-overlay="clickOverlay">
			<div class="popupBox">
				<div class="title">这节视频已观看一部分，是否继续观看？</div>
				<div class="flex btnBox">
					<div @click="playClick(index_i,item_i,0)">重新播放</div>
					<div class="suss" @click="playClick(index_i,item_i,1)">继续观看</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>
<!-- playClick(i, item) -->
<script>
	var setVal=null
	import { setMinute } from "@/util/setTime.js";
import {
  getOnlineCourseList,
  getOnlineCourseChapterById,
  postOnlineCourseLearningrate
} from "@/api";
export default {
  name: "chapter",
  components: {},
  data() {
    return {
		playsinline:false,
		playerOptions : {
		    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
		    autoplay: true, //如果true,浏览器准备好时开始回放。
		    muted: false, // 默认情况下将会消除任何音频。
		    loop: false, // 导致视频一结束就重新开始。
		    preload: '', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
		    language: 'zh-CN',
		    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
		    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
		    sources: [{
		      type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
		      src: "https://xcjjvideo.oss-cn-shenzhen.aliyuncs.com/upload/online_course_video/%E7%AC%AC%E4%B8%80%E7%AB%A01%EF%BC%9A%E4%B8%80%E8%88%AC%E6%A6%82%E5%BF%B5-%20%E7%8E%B0%E5%9C%BA%E6%80%A5%E6%95%91%E7%9A%84%E7%9B%AE%E7%9A%84-%E6%96%BD%E6%95%91%E8%80%85%E7%9A%84%E8%81%8C%E8%B4%A3.mp4" //url地址
		    }],
		    poster: "https://xcjj.tysos.cn/gzh/backend/public/uploads/online_course_coverpic/1 课程封面：第一篇第一节.png", //你的封面地址
		    // width: document.documentElement.clientWidth, //播放器宽度
		    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
		    controlBar: {
		      timeDivider: true,
		      durationDisplay: true,
		      remainingTimeDisplay: false,
		      fullscreenToggle: true  //全屏按钮
		    }
		},
		show:false,
      isshua: true,
      // 激活码
      activationCode: "",
      // 激活是否成功
      isda: false,
      // 激活提醒
      delShowDui: false,
      delShow: false,
      isjihuo: 1,
      subject: [],
      // 假激活码
      jihuoCode: "123456",
      playObj: {
        effectiveDuration: 0,
        totalDuration: 0
      },
      videoAss: {},
      istoUpdate: true,
      currentTime: 0,
	  index:0,
	  item_i:{},
	  index_i:0,
	  // 是否重新播放
	  newReplay:0,
	  startPlay:0,
	  one:0,
	  currentTime1:0,
	 // 原始临时学习进度 ,用户快进视频
	 rateLearning:0,
	 isover:true,
	 cancel:null
    };
  },
  // computed: {
  //  player() {
  //          return this.$refs.videoPlayer.player
  //        }
  //   },
  watch:{
	'index_i'(){
		console.log('改变了')
		this.one=0
	}  
  },
  methods: {
	  onPlayerCanplay(player){
			//解决自动全屏
			var ua = navigator.userAgent.toLocaleLowerCase();
			//x5内核
			if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {

				$('body').find('video').attr('x-webkit-airplay',true).attr('x5-playsinline',true).attr('webkit-playsinline',true).attr('playsinline',true)
			}else{
			//ios端
				// $('body').find('video').attr('webkit-playsinline',"true").attr('playsinline',"true") 
			}
	  },
	  onPlayerPause(player){
		  console.log('课程暂停')
		   if ((player.duration() - player.currentTime()) < 0) {
			   let chapter_id = this.$route.query.chapter_id;
			   let course_id = this.subject[this.index_i].id;
			    this.videoAss={}
			    postOnlineCourseLearningrate({
			   			  chapter_id: chapter_id,
			   			  course_id: course_id,
			   			  rate: this.subject[this.index_i].rate,
			   			  stop_time:player.currentTime()
			    })
		   }
		  
	  },
	  onPlayerEnded(player){
		  console.log('课程结束')
		  let chapter_id = this.$route.query.chapter_id;
		  let course_id = this.subject[this.index_i].id;
		  this.videoAss={}
		  postOnlineCourseLearningrate({
			  chapter_id: chapter_id,
			  course_id: course_id,
			  rate: 100,
			  stop_time:player.duration()
		  })
		   // this.subject[this.index_i].stop_time = a
	  },
	  onPlayerLoadeddata(player){
		
	  }, 
	  playerStateChanged(player){
	  },
	  
	  onPlayerPlay(player){
	  },
	  onPlayerTimeupdate(player){
		let _this=this
		this.videoAss=player
		if(this.one==1 && this.newReplay){
			if(_this.subject[_this.index_i].stop_timeBai!=100){
				player.currentTime(this.item_i.stop_time)
			}
			this.one=2
		}else if(this.one==1&&!this.newReplay){
			this.one=2
		}else if(this.one==2){
		 
		if(this.startPlay){
			let chapter_id = _this.$route.query.chapter_id;
			let course_id = _this.subject[this.index_i].id;
			// 更新机制
			_this.istoUpdate = false;
			
			
			 // 定时存数据
			 if(player.currentTime() > 0){
				 _this.subject[this.index_i].stop_time=player.currentTime()
				_this.item_i.stop_time = player.currentTime()
				_this.subject[this.index_i].stop_timeBai=((parseInt(this.item_i.stop_time)/this.item_i.course_time)*100).toFixed(2)
			 }
			 // 禁止快进
			 _this.onTimeUpdate(player,this.index_i);
			_this.istoUpdate = true;
		 }
		}
		
	  },
	   showVideo(type) {
	        document.querySelector(".video-player-box").style.display = type;
	        if (type == "none") {
	          const Player = document.getElementById(`${videoPlayer+this.item_i}`).player;
	          Player.pause();
	        }
	      },
		  // 点击遮罩层
		  clickOverlay(){
			  this.one=2
		  },
	  // 显示弹窗
	 needTutorClick(i,item){
		 
		 this.index_i=i
		 this.item_i=item
		 let _this = this;
		 let chapter_id,
		   course_id,
		   rate = null;
		// 获得章节id和篇章id
		chapter_id = _this.$route.query.chapter_id;
		course_id = _this.subject[i].id;
		rate = Number(_this.subject[i].rate) 
		if(rate>0){
			this.show=true
		}else{
			this.playClick(this.index_i,this.item_i,0)
		}
	 },
    setTime(time) {
      return setMinute(time);
    },
    onTimeUpdate(Player,i) {
			
			
		// 获得整数的 进度和总视频长度
		let num1 = parseInt(Player.duration());
		let num2 = parseInt(Player.currentTime());
		// 获得百分比
		let tol = parseInt((num2 / num1) * 100);
		let chapter_id,
		course_id,
		rate = null;
		chapter_id = this.$route.query.chapter_id;
		course_id = this.subject[i].id;
      // 学习中
		// console.log((Player.duration() - Player.currentTime()),'总时间减去当前时间')
	 let _this =this
      if (Player.currentTime() < Player.duration() ) {
        // 禁止快进
        if (Player.currentTime() - this.lastTimeString > 2 && this.subject[i].stop_timeBai > this.subject[i].rate) {
				Player.currentTime(this.lastTimeString);
        } else {
          this.lastTimeString = Player.currentTime();
		  let CancelToken = this.$axios.CancelToken
		  // 如果是大于原始的百分比则继续增加。
		  if (_this.item_i.rate < tol) {
		  	 _this.subject[this.index_i].rate = tol;
		  	// 逢五的时候就提交一次
		  	if(tol % 5 == 0 ){
				if(this.cancel){
					this.cancel()
				}
		  		postOnlineCourseLearningrate({
					chapter_id: chapter_id,
					course_id: course_id,
					rate: _this.subject[this.index_i].rate,
					stop_time:Player.currentTime(),
					 cancelToken: new CancelToken(function executor(c) {
							_this.cancel = c
							console.log(c)
							// 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
						  })
				})
		  	}
		  }else{
		  	if(tol % 5 == 0 && tol!=0){
		  		_this.item_i.stop_time = Player.currentTime()
		  		_this.subject[this.index_i].stop_timeBai=((parseInt(this.item_i.stop_time)/this.item_i.course_time)*100).toFixed(2)
		  		if(this.cancel){
					console.log('ss')
		  			this.cancel()
		  		}
		  		postOnlineCourseLearningrate({
		  			chapter_id: chapter_id,
		  			course_id: course_id,
		  			rate: _this.subject[this.index_i].rate,
		  			stop_time:Player.currentTime(),
		  			 cancelToken: new CancelToken(function executor(c) {
		  					_this.cancel = c
		  					console.log(c)
		  					// 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
		  				  })
		  		})
		  	}
		  }
        }
      } else if ((Player.duration() - Player.currentTime()) == 0) {
			this.istoUpdate = false;
			// 视频结束
			console.log(this.subject[i])
			console.log('视频结束',this.subject[i].rate)
			postOnlineCourseLearningrate({
				chapter_id: chapter_id,
				course_id: course_id,
				rate: 100,
				stop_time:Player.currentTime()
			}).then(res=>{ 
				if(res.code==1){
					_this.subject[i].stop_time=Player.duration()
				}
			});
        // this.subject[i].isplay = false;
        this.istoUpdate = true;
      }
    },

	//判断是否安卓设备
	 isAndroid(){
		var u = navigator.userAgent;
		if(u.indexOf('Android') > -1 || u.indexOf('Linux') > -1){
			return true;
		}
	},
    // 播放
    playClick(i, item,isContinue) {
		let _this = this;
		this.show=false
		this.newReplay=isContinue
		this.startPlay=1
		this.playerOptions.sources[0].src=this.subject[i].course_video
		this.playerOptions.poster=this.subject[i].course_cover
		
	  let chapter_id,
	    course_id,
	    rate = null;
		// 获得章节id和篇章id
		chapter_id = _this.$route.query.chapter_id;
		course_id = _this.subject[i].id;
		if(_this.subject[this.index_i].rate==0){
			postOnlineCourseLearningrate({
				chapter_id: chapter_id,
				course_id: course_id,
				rate: _this.subject[this.index_i].rate,
				stop_time: _this.subject[this.index_i].stop_time
			})
		}
	  let isSet=true//做是否已经设置
      this.isshua = false;
      var setVal = null;
      this.subject.forEach(item => {
        item.isplay = false;
      });
      this.subject[i].isplay = !item.isplay;
      this.isshua = true;
	  
	  // 防止拿不到视频实例
	  setTimeout(()=>{
		  this.one=1
		  this.videoAssobj=this.$refs.videoPlayer[0].player
		   this.videoAssobj.play()
		   // this.videoAssobj.currentTime(100)
		 console.log('this is current player instance object', this.videoAssobj)
	  },1000)
    },
	
	timeFilter(seconds) {
      let ss = Math.floor(seconds); // 秒
      let mm = 0; // 分
      let hh = 0; // 小时
      if (ss > 60) {
        mm = Math.floor(ss / 60);
        ss = Math.floor(ss % 60);
      }
      if (mm > 60) {
        hh = Math.floor(mm / 60);
        mm = Math.floor(mm % 60);
      }

      let result = ("00" + Math.floor(ss)).slice(-2);
      if (mm > 0) result = ("00" + Math.floor(mm)).slice(-2) + ":" + result;
      else result = "00:" + result;

      if (hh > 0) result = ("00" + Math.floor(hh)).slice(-2) + ":" + result;
      return result;
    },
    duiClick() {
      this.isda = true;
      this.delShowDui = false;
    },
    jihuoClick() {
      if (this.activationCode) {
        if (this.activationCode == this.jihuoCode) {
          this.delShowDui = true;
        } else {
          this.delShow = true;
        }
      }
    },
	getOnlineCourseChapterById(){
		let _this =this
		getOnlineCourseChapterById({
		  chapter_id: this.$route.query.chapter_id
		}).then(res => {
			this.subject = res.data;
			this.subject.forEach((item, index) => {
				item.rate = parseInt(item.rate);
				item.stop_timeBai=((parseInt(item.stop_time)/item.course_time)*100).toFixed(2)
				
				item.format_time = _this.timeFilter(item.course_time);
				item.isplay = false;
		  });
		});
	},
	onBridgeReady(){
		WeixinJSBridge.call('hideOptionMenu');
	},
	WeixinJSBridgeCode(){
		let _this=this
		if (typeof WeixinJSBridge == "undefined") {
			if (document.addEventListener) {
				document.addEventListener('WeixinJSBridgeReady', _this.onBridgeReady(), false);
			} else if (document.attachEvent) {
				document.attachEvent('WeixinJSBridgeReady', _this.onBridgeReady());
				document.attachEvent('onWeixinJSBridgeReady', _this.onBridgeReady());
			}
		} else {
			_this.onBridgeReady();
		}
	}
	
  },
  mounted() {
	let _this=this
    document.title = this.$route.query.chapter_name;
	this.getOnlineCourseChapterById()
	this.WeixinJSBridgeCode()
	var ua = navigator.userAgent.toLocaleLowerCase();
		 //x5内核
	if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
		this.playsinline= false
	}else{
		//ios端
		this.playsinline= true				
	}
	
  }
};
</script>
<style lang="scss" type="text/css">
	.chapter {
		min-height: 100vh;
		background-color: #f7f8ff;
		text-align: left;
		// display: flex;
		// flex-wrap: wrap;
		// justify-content: space-between;

		.zhuren {
			z-index:9999;
			border-radius: 6px;

			.popupBox {
				width: 300px;
				height: 180px;
				background-color: #fff;
				border-radius: 6px;
				padding: 0px 20px 28px;

				.title {
					padding: 42px 0;
					line-height: 23px;
				}

				.btnBox {
					justify-content: space-around;
					color: #919CB0;

					div:first-child {
						margin-right: 40px;
					}

					.suss {
						color: #0081FF;
					}
				}
			}
		}

		.titleBox {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 800;
			color: rgba(40, 51, 74, 1);
			padding: 15px;
			position: relative;
			&:after {
				content: "";
				display: block;
				width: 3px;
				height: 20px;
				background: rgba(0, 129, 255, 1);
				border-radius: 0px 3px 3px 0px;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}
		}

		.jihuoBtn {
			background-color: #c6c8ca;
			width: 70px;
			height: 40px;
			border-radius: 6px;
			color: #fff;
			text-align: center;
			line-height: 40px;
		}

		.ylcBox {
			width: 100%;
			// min-height: 231px;
			position: relative;

			
			.top {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 5;
			}

			.bot {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 6;
				font-size: 12px;
			}

			.bg {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}

			.flex {
				position: absolute;

				z-index: 6;
				color: #fff;
				justify-content: space-between;
				width: 100%;
				padding: 10px 15px;
			}

			.conter {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 50px;
				height: 50px;
			}

			.time {
				width: 50px;
				height: 26px;
				background: rgba(40, 51, 74, 0.5);
				border-radius: 13px;
				line-height: 26px;
				font-size: 12px;
				text-align: center;
			}

			.btnBo {
				text-align: center;
				line-height: 32px;
				width: 60px;
				height: 32px;
				background: rgba(0, 129, 255, 1);
				border-radius: 16px;
				font-size: 15px;
			}
		}

		.btn {
			border: 1px solid #0081ff;
			color: #0081ff;
			border-radius: 52px;
			width: 70px;
			font-size: 12px;
			height: 27px;
			line-height: 27px;
			text-align: center;
		}

		.btn1 {
			border: 1px solid #919cb0;
			color: #919cb0;
			border-radius: 52px;
			width: 70px;
			font-size: 12px;
			height: 27px;
			line-height: 27px;
			text-align: center;
		}

		.delBox {
			.van-popup {
				width: 300px;
				height: 190px;
				border-radius: 6px;
				padding: 0 1.25rem;
			}
		}

		.Bigbox {
			position: relative;
			width: 92%;
			height: 296px;
			margin: 100px auto 0;
			background-color: #fff;
			z-index: 3;
			border-radius: 6px;
			padding: 49px 15px 0;
			text-align: left;

			input {
				width: calc(100% - 24px);
			}

			.box {
				width: calc(100% - 15px - 70px);
			}
		}


		.box {
			padding: 12px 8px 12px;
			background-color: #fff;
			font-size: 12px;

			.boxI {
				text-align: left;
				width: 100%;
				padding: 9px 8px;
				background: rgba(245, 245, 245, 1);
				border-radius: 6px;

				span {
					color: #fed200;
				}
			}
		}

		.vjs-custom-skin>.video-js .vjs-big-play-button {
			transform: translate(-50%) !important;
			margin-left: 0 !important;
		}

		.vjs-custom-skin>.video-js .vjs-big-play-button {
			/*
		      播放按钮换成圆形
		     */
			height: 2em !important;
			width: 2em !important;
			line-height: 2em !important;
			border-radius: 50% !important;
		}
		.vjs-custom-skin > .video-js .vjs-control-bar .vjs-playback-rate{
			display: none !important;
		}
		.video-js .vjs-volume-panel{
			display: none !important;
		}
		.vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal{
			display: none !important;
		}
		video{
			// z-index:9990;
			width: 100%;
		}
	}
</style>
