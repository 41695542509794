<template>
	<div class="certificate">
		<div class="list" v-for="(items,index) in AssessmentList" :key="index">
			<van-row class="flex">
			  <van-col span="16">
				  <van-row v-for="(item,i) in items.list" :key="i" style="margin: 10px 0;">
				    <van-col span="7" style="color:#919CB0;margin-right:16px ;"><span v-text="item.name"></span></van-col>
				    <van-col span="10"><span v-text="item.value"></span></van-col>
				  </van-row>
			  </van-col>
			  <van-col span="7">
				  <div class="flex" style="justify-content: flex-end;">
					  <img src="../../assets/user/icon_dkh@2x.png" alt="" style="width: 70px;height: 60px;margin-right: 16px;" v-if="items.state==1">
					  <img src="../../assets/user/icoan_dabiao@2x.png" alt="" style="width: 70px;height: 60px;margin-right: 16px;" v-if="items.state==2">
					  <img src="../../assets/user/icon_more@2x.png" alt="" style="width: 7px;height: 11px;">
				  </div>
			  </van-col>
			</van-row>
			
		</div>
	</div>
</template>

<script>
export default {
  name: 'cancel',
  components: {
  },
  data(){
	  return{
		title:'考核记录',
		AssessmentList:[{
			list:[{
				name:'考核学员',
				value:'急救侠'
			},
			{
				name:'学习时间',
				value:'8320938091'
			},
			{
				name:'学习课程',
				value:'李雪'
			},
			{
				name:'笔试成绩',
				value:'8320938091'
			}],
			state:1
		},
		{
			list:[{
				name:'考核学员',
				value:'急救侠'
			},
			{
				name:'学习时间',
				value:'8320938091'
			},{
				name:'学习课程',
				value:'8320938091'
			},
			{
				name:'笔试成绩',
				value:'李雪'
			}],
			state:2
		}]
		
		
		
	  }
  },
  methods:{
	
  },
  
  mounted() {
  	document.title = this.title;   
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.certificate {
		width: 100%;
		min-height: 100vh;
		background: #F7F8FF;
		padding-bottom: 60px;
		padding: 25px 15px 0;
		
		.van-col{
			text-align: left;
		}
		.list{
			background-color: #fff;
			padding: 0 0 0 13px ;
			border-radius: 6px;
			margin-bottom: 10px;
		}
	}
</style>
