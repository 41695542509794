<template>
  <div class="bigSink">
	  <div class="bigSinkBox">
		  <div class="box"  v-for="(item,i) in list" :key="i" @click="tiao(item.url)">
		  	<!-- <div class="title"><span v-text="item.title"></span></div>
		  	<div class="text"><span v-text="item.text"></span></div> -->
			<img :src="item.bgImg" alt="" width="100%" >
		  </div>
	  </div>
		
		
  </div>
</template>

<script>

export default {
  name: 'bigSink',
  components: {
  },
  data(){
	  return{
		 list:[
			 {
				 bgImg:require('@/assets/bigSink/pic_jjxc_bg@2x.png'),
				 bgName:'jjxc',
				 title:'急救秀场',
				 text:'秀出您的急救视频',
				 url:'ylc_list'
			 },
			 {
				 bgImg:require('@/assets/bigSink/pic_hywz_bg@2x.png'),
				 bgName:'hwgx',
				 title:'花样文章',
				 text:'急救好文共享',
				 url:'hotText'
							 
			 }, 
			 {
				 bgImg:require('@/assets/bigSink/icon_jjzd_bg@2x.png'),
				 bgName:'jjzd',
				 title:'急救知道',
				 text:'寻找答案、解答问题',
				 url:'questions'
			 },
			 {
				 bgImg:require('@/assets/bigSink/pic_jjyy_bg@2x.png'),
				 bgName:'jjyy',
				 title:'急救影院',
				 text:'急救精品发布、分享',
				 url:'FirstAidVideo_list'
			 },
			 ]
	  }
  },
  methods:{
	  tiao(url){
		  if(url=='onlineClassroom'){
			  let userData=JSON.parse(localStorage.getItem('user_data'))
			  if(userData.is_securitycode==0){
			  	this.$router.push({name:'onlineClassroom'})
			  }else{
			  	this.$router.push({name:'answer'})
			  }
		  }else{
			  this.$router.push({name:url})
		  }
	  }
	
  },
  mounted() {
  	document.title = '五味急救';   
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.bigSink{
		
		background-color: #fff;
		min-height: 100vh;
		.bigSinkBox{
			padding: 1.25rem 0.9375rem;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		.box{
			width: calc(calc(100% / 2) - 8px);
			float: left;
			margin-bottom: 15px;
			
			color:#fff;
			.title{
				margin: 20px 0;
				font-size: 17px;
				font-weight: bold;
			}
			.text{
				font-size: 12px;
			}
		}
		.jjzd{
			background: url(../../assets/bigSink/icon_jjzd_bg@2x.png) no-repeat;
			background-size: 100% 100%;
			// margin-right: 15px;
		}
		.hwgx{
			background: url(../../assets/bigSink/pic_hywz_bg@2x.png) no-repeat;
			background-size: 100% 100%;
		}
		.xskt{
			background: url(../../assets/bigSink/pic_jjyy_bg@2x.png) no-repeat;
			background-size: 100% 100%;
		}
		.ylc{
			background: url(../../assets/bigSink/pic_jjxc_bg@2x.png) no-repeat;
			background-size: 100% 100%;
			// margin-right: 15px;
		}
	}
</style>
