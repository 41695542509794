<template>
	<div class="certificateDet_user">
		<!-- <van-skeleton title  :row="3" :loading="loading"> -->
		<div class="nav flex" >
			<div  v-if="isPoAndNega==1">证书正面：</div>
			<div  v-if="isPoAndNega==2">证书反面：</div>
			<div class="btn" :class="[obj.cert_status == 2 ? 'disable' : '']" v-if="isPoAndNega==1" @click="clickPo">
				看反面
			</div>
			<div class="btn" v-if="isPoAndNega==2"  @click="isPoAndNega=1">
				看正面
			</div>
		</div>
			<van-image width="100%" :src="obj.frontimg" style="margin: 0 0 28px;" radius="18" v-if="isPoAndNega==1">
				<div v-if="obj.cert_status == 2" style="position: absolute;
				top: 0;
				height: 100%;
				width: 100%;
				background-color: #000000;
				border-radius: 20px;
				opacity: 0.5;
				color: #FFFFFF;">
				<p style="margin-top: 60px;">状态：预发证</p>
			请前往【线上课堂】完成学习并获得“现场急救线上教学课程合格证”证书，即可正式获得当前证书
			</div>
			</van-image>
			<van-image width="100%" :src="obj.backimg" style="margin: 0 0 28px;" radius="18" v-if="isPoAndNega==2"/>
			<!-- <van-row v-for="(item,i) in list" style="margin-bottom: 12px;" :key="i">
				<van-col span="5" style="color:#919CB0"><span v-text="item.name"></span></van-col>
				<van-col span="19" style="height: 19px;line-height: 19px;"><span v-text="item.value"></span></van-col>
			</van-row>
			<div class="bottom">
				<div class="btn" @click="$router.push({name:'certificate_vid'})">培训记录</div>

			</div> -->
		<!-- </van-skeleton> -->
		<div v-if="obj.cert_status == 1" class="bottom" style="box-shadow: none;line-height: 7.125rem;color:#28334A;font-size: 12px;">
			提示：长按可保存证书
		</div>
	</div>
</template>

<script>
	import { getMyOfficialCerts } from '@/api'
export default {
  name: 'certificateDet_user',
  components: {
  },
  data(){
	  return{
		  isPoAndNega:1,
		  loading:true,
		  img:require('../../assets/1234.jpg'),
		title:'证书详情',
		obj:{},
		list:[{
			name:'证书名称',
			value:'急救侠'
		},
		{
			name:'证书编号',
			value:'8320938091'
		},
		{
			name:'姓名',
			value:'李雪'
		},
		{
			name:'课程',
			value:'成人心肺复苏术'
		},
		{
			name:'发证机构',
			value:'天营救援培训中心'
		},
		{
			name:'发证日期',
			value:'2019-11-12'
		},
		{
			name:'有效期至',
			value:'2021-11-12'
		}]
		
		
	  }
  },
  methods:{
	 clickPo() {
		 if(this.obj.cert_status == 2) { return;}
		 this.isPoAndNega=2
	 }
  },
  
  mounted() {
		 
	getMyOfficialCerts({cert_id:this.$route.query.cert_id,act_id:this.$route.query.act_id,type:this.$route.query.type}).then(res=>{
		if(res.code==1){
			this.obj=res.data
		}
	})
  	document.title = this.title;   
	
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.certificateDet_user {
		width: 100%;
		min-height: 100vh;
		background:#fff;
		padding-bottom: 60px;
		padding: 15px;
		padding-top: 75px;
		.nav{
			width:100%;
			background:rgba(244,244,244,1);
			justify-content: space-between;
			position: fixed;
			top: 0;
			left: 0;
			padding: 18px 15px;
			.btn{
				width:60px;
				height:26px;
				background:rgba(54,153,255,1);
				border-radius:50px; 
				line-height: 26px;
				font-size:12px;
				font-family:PingFang SC;
				font-weight:500;
				color:rgba(255,255,255,1);
				&.disable {
					background-color: #CCCCCC;
				}
			}
		}
		.van-col {
			text-align: left;
		}

		.bottom {
			box-shadow: 1px 1px 10px 5px #E5E5E5;
		}

		.van-image__img {
			border-radius: 8px;
		}
	}
</style>
