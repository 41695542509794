<template>
	<div class="viewArticles">

		<div class="viewArticlesTop">
			<div class="viewArticlesTop_flex" style="">
				<div class="flex" style="justify-content: space-between;">


					<div class="flex">
						<div style="margin-right: 10px;">
							<img :src="userinfo.user_head_img" alt="" style="width: 41px;">
						</div>
						<div>
							<p style="font-weight: Bold;    align-items: flex-end;" class="flex"><span v-text="userinfo.user_name"></span>
								<span class="time" v-text="setTime(article.createtime)" style="margin-left: 10px;"></span></p>
							<p style="color: #919CB0;font-size: 10px;" v-text="userinfo.user_title"></p>
						</div>
					</div>
				
				</div>
				<div style="margin: 16px 0;color: #767E8D;" v-text="article.thank" v-if="article.type==2"></div>
				<div style="margin-top: 23px;font-weight: bold;" v-text="article.title" v-if="article.type==1"></div>
				<div style="margin: 16px 0;color: #767E8D;" v-html="article.content" v-if="article.type==1"></div>
				<div v-if="article.type==2" class="article flex" @click="show1=true">

					<img :src="article.imgurl?article.imgurl:require('@/assets/bigSink/icon_moren200_pre@2x.png')" alt="" style="width: 40px;height: 40px;">
					<div style="padding:0px 12.5px;width: calc(100% - 60px);" class="outerChain">
						<div v-text="article.share_title" style="font-weight: bold;margin-bottom: 7.5px;"></div>
						<div v-text="article.share_url" style="font-size: 10px;color: #767E8D;"></div>
					</div>
				</div>
				<!-- <div v-if="article.type==1">
					<img src="../../assets/123.jpg" alt="" style="height: 170px;width: 100%;">
				</div> -->
				<!-- <div class="flex" style="justify-content: space-between;width: 100%;font-size: 12px;color: #919CB0;margin-top: 25px;">
					<div>
						<span class="number_0" v-text="article.browse_num"> </span>人看过 · <span class="number_0" v-text="article.comment_num"></span>
						评论
					</div>
					<div class="flex">
						<span class="number_0" v-text="article.praise_num"></span>
						<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 16px;height: 15px;margin-left: 10px;">
					</div>
				</div> -->
			</div>
		</div>
		<div class="bottom" style="box-shadow:0px -6px 10px 0px rgba(145,156,176,0.1);">
			<div class="btn" style="padding: 0;" @click="cancelClick">取消预览</div>
		</div>
	</div>
	

</template>

<script>
	import {postWeixinAidHotArticleCommentPraise,getWeixinAidHotArticleCommentOrder, getWeixinAidHotArticleDetail ,getWeixinAidHotArticleCommentReplyList,postWeixinAidHotArticleComment,postFollow} from '@/api'
	import { Toast } from 'vant';
	import { setTime } from '@/util/setTime.js'
	export default {
  name: 'viewArticles',
  components: {
  },
  data(){
	  return{
		  CommentIndex:0,
		  isCommentList:0,
		  // 自己的id
		  user_id:0,
		  page:1,
		  limit:8,
		  questionsTo:'',
		  iszuixin:true,
		  show1:false,
		  article:{
			  url:'https://www.sohu.com/a/190048557_349978',
			  title:'冠心病患者如何健康午睡？',
			  time:'2018-04-18',
			  form:'疾病库',
			  img:'http://img3.imgtn.bdimg.com/it/u=3089137238,4050596303&fm=11&gp=0.jpg',
			  conten:'你是不是看了好多篇“医疗人工智能应用十大方向”是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新'
		  },
		  show:false,
		  isshu:false,
		images: [
		  'https://img.yzcdn.cn/vant/apple-1.jpg',
		  'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		active:'',
		userinfo:{},
		replylist:[],
		replycount:0,
		index:0,
		replylistList_rep:[]
	  }
  },
  methods:{
	  // 取消预览
	 cancelClick(){
			this.$router.go(-1)
	 },
	  // 获得文章详情
	getWeixinAidHotArticleDetail(){
		this.article=JSON.parse(localStorage.getItem('preview'))
		this.userinfo=JSON.parse(localStorage.getItem('user_data'))
	} ,
	setTime(time){
		return setTime(time)
	}
	
  },
  mounted() {
  	document.title = '预览';  
	 this.getWeixinAidHotArticleDetail()
  }
}
</script>
<style lang="scss" type="text/css">
	.viewArticles {
		min-height: 100vh;
		background-color: #fff;
		padding: 0 0 46px;
		.input {
			width: calc(100% - 77px);
			min-height: 33px;
			line-height: 33px;
			background-color: #F4F4F4;
			option: none;
			border-radius: 6px;
			padding:0 10px;
			transform: all 1s;
				
		}
		.viewArticlesTop {
			width: 100%;
			text-align: left;

			.viewArticlesTop_flex {

				justify-content: space-between;
				padding: 20px 15px 24px;
				border-radius: 6px;

				.time {
					color: #919CB0;
					font-size: 10px;
					font-weight: 100;
					display: block;
				}
			}
		}

		.btn {
			padding: 5px 8.5px;
			background-color: #0081FF;
			border-radius: 50px;
			color: #fff;
			font-size: 12px;
			border: 1px solid transparent;
		}
		.btn1 {
			padding: 5px 8.5px;
			background-color: transparent;
			border-radius: 50px;
			color: #0081FF;
			font-size: 12px;
			border: 1px solid #0081FF;
		}

		.outerChain {}

		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}

		.van-tab {
			min-width: 85px;
		}

		.van-tab--active {
			font-size: 17px;
		}

		.topBox {
			padding: 15px;
			background-color: #fff;

		}

		.bottomBox {
			padding: 6.5px 15px;

			img {
				width: 20px;
				height: 20px;
			}

			input {
				width: 50%;
				height: 33px;
				line-height: 33px;
				background-color: #F4F4F4;
				option: none;
				border-radius: 6px;
				padding-left: 12px;
				transform: all 1s;

			}

			.follow {
				position: absolute;
				top: -7px;
				left: 100%;
				font-size: 10px;
				color: #919CB0;
			}
		}

		.article {
			width: 100%;
			background-color: #F4F4F4;
			border-radius: 2px;
			text-align: left;
			padding: 5px;
			margin: 20px 0;
		}

		.ifameClass {
			text-align: left;
			padding: 0 15px;
			height: calc(100% - 20px - 16px - 8.5px);
			overflow: auto;
		}
		img{
			max-width: 100%;
		}
	}
</style>
