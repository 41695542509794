<template>
	<div class="traindal">
		<div class="imgBox">
			<img src="../../assets/Train/bg_bmtop@2x.png" alt="" class="auto-img">
		</div>
		<div style="padding: 15px 15px 10px;">
			<div class="container1">
				<!-- <div class="header">现场急救培训-广东软件园</div> -->
				<div class="header_title">
					<span v-text="conData.act_name"></span>
					<!-- <van-divider /> -->
				</div>
				<div class="conData">

					<van-row style='margin: 0;'>
						<van-col span="4">
							<span class="title">时间</span>
						</van-col>
						<van-col span="18">
							<div class="flex">
								<div>
									<div>
										<span v-text="conData.act_start_time"></span>
									</div>
									<div>
										<span v-text="conData.act_end_time"></span>
									</div>
								</div>
								<div class="zhi">至</div>
							</div>
						</van-col>
					</van-row>
					<!-- <van-row>
						<van-col span="9">
							<span class="title">考核开始时间</span>
						</van-col>
						<van-col span="15">
							<span v-text="conData.check_start_time"></span>
						</van-col>
					</van-row> -->
					<van-row>
						<van-col span="6">
							<span class="title">培训地点</span>
						</van-col>
						<van-col span="18">
							<span v-text="conData.act_addr"></span>
						</van-col>
					</van-row>
					<van-row>
						<van-col span="6">
							<span class="title">培训课程</span>
						</van-col>
						<van-col span="18">
							<span v-for="(item,i) in courseList" :key="i" v-text="i==courseList.length-1?item:item+','"></span>
						</van-col>
					</van-row>

				<!-- 	<van-row>
						<van-col span="6">
							<span class="title">考核课程</span>
						</van-col>
						<van-col span="18">
							<span v-for="(item,i) in courseList_kao" :key="i" v-text="i==courseList_kao.length-1?item:item+','"></span>
						</van-col>
					</van-row> -->

					<div class="dashed">
						<van-divider :style="{ borderColor: '#F7F8FF'}" :hairline="false"></van-divider>
					</div>
					<div class="flex" style="justify-content: space-around;" v-if="conData.tutor_res_status==1">
						<div class="btn" @click="show=true">拒绝</div>
						<div class="btn" @click="acceptClick">接受</div>
					</div>

					<div class="flex" style="justify-content: space-around;" v-if="conData.tutor_res_status==2&&conData.tutor_status!=5" @click="btnClick(conData.tutor_status==4,conData)">
						<div class="btn1" :style="conData.tutor_status==4?'background:#0081FF;color:#fff;':'background:#FFBC00;color:#fff;'">
							<span v-text="conData.tutor_status==4?'督导评分':'已接受，邀约人未付款，请等待'"></span>
						</div>
					</div>
					<div class="flex" style="justify-content: space-around;" v-if="conData.tutor_res_status==2&&conData.tutor_status==5" @click="btnClickOK(conData.tutor_status==4,conData)">
						<div class="btn1" style="border: 1px solid #0081FF;color: #0081FF;">
							<span>已评分，查看督导评分</span>
						</div>
					</div>
					<div class="flex" style="justify-content: space-around;" v-if="conData.tutor_res_status==3">
						<div class="btn1" style="background-color: #FF524D;color: #fff;">
							<span>已拒绝</span></div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div style="width: 100%;height: 15px;"></div> -->
		<div>
			<van-tabs title-inactive-color="#919CB0" @click="onClick" v-model="tabIndex">

				<van-tab v-for="index in list_title" :title="index" :key="index">
					<div v-if="!tab_list.length">
						暂无数据
					</div>
					<van-row v-for="(item,i) in tab_list" class="List_row" v-if="index!=='证书发放'&&tab_list.length" :key="i">
						<van-col span="8" style="text-align: left;"><span v-text="item.sign_name"></span></van-col>
						<van-col span="8"><span v-text="item.sign_phone"></span></van-col>
						<van-col span="8" style="text-align: right;">
							<span v-text="item.sex==1?'男':item.sex==2?'女':''" v-if="index!=='助教导师报名'"></span>
							<span v-text="i+1" v-if="index=='助教导师报名'"></span>
						</van-col>
					</van-row>
					<van-row v-for="(item,i) in tab_list" class="List_row" v-if="index=='证书发放'&&tab_list.length" :key="i">
						<van-col span="6" style="text-align: left;"><span v-text="item.sign_name"></span></van-col>
						<van-col span="6"><span v-text="item.sign_phone"></span></van-col>
						<van-col span="6" style="text-align: right;"></van-col>
						<van-col span="6" style="text-align: right;" @click="stateClick(i)"><span v-text="item.is_certs?'已发证':'未发证'"
							 :style="item.is_certs?'color:#0081FF':'color:#919CB0'"></span>
							<van-icon name="arrow" :color="item.is_certs?'#0081FF':'#919CB0'" />
						</van-col>
					</van-row>
				</van-tab>
			</van-tabs>
		</div>
		<div class="bottom" v-if="tabIndex==0">
			报名总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人
		</div>
		<div class="bottom" v-if="tabIndex==1">
			助教导师报名总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人
		</div>
		<div class="bottom" v-if="tabIndex==2">
			签到总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人
		</div>
		<div class="bottom" v-if="tabIndex==3">
			签到总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人，主讲导师：<span class="fontWeight" v-text="list1.count1?list1.count1:0"></span>人，助教导师：<span
			 class="fontWeight" v-text="list1.count2?list1.count2:0"></span>人
		</div>
		<div class="bottom" v-if="tabIndex==4">
			签到总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人，已发证：<span class="fontWeight" v-text="list1.count1?list1.count1:0"></span>人，未发证：<span
			 class="fontWeight" v-text="list1.count2?list1.count2:0"></span>人
		</div>
		<!-- <div class="fu flex">
			<img src="../../assets/Train/icon_tips@2x.png" alt="">
			<div>
				付费协议
			</div>
		</div>
		<div class="bottom flex" style="padding:0 30px ;">
			<div style="width: 50%;position: relative;">
			<div class="btn cancel" @click="$router.go(-1)">
				取消
			</div>
			</div>
			<div style="width: 50%;position: relative;">
			<div class="btn" @click="Success">
				立即付费
			</div>
			</div>
		</div> -->
		<van-popup v-model="show" :round="true" class="dudao">
			<div class="popupBox">
				<div class="title">
					拒绝原因
				</div>
				<van-field
				  v-model="message"
				  rows="2"
				  type="textarea"
				  placeholder="请填写拒绝原因"
				  style="border-radius: 6px;background-color: #F4F4F4;"
				/>
				<div class="flex btnBox">
					<div @click="show=false">暂不取消</div>
					<div class="suss" @click="refuseClick">确定</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import {
  getActivitySigninLists,
  getActivitySigninTutorLists,
  getActivity_t,
  getActivityCommentLists,
  getActivitySignupLists,
  getActivitySignupTutorLists,
  changeActivityStatus,
  getOfficialCourse,
  getCancelSignupTutor,
  getChangeOfficialSignupTutor,
  getSearchAndSendMessage,
  postOfficialActivityTutorApply,
  getActivitySigninCertsLists,
  postOfficialActivityTutorApplyAccept,
  postOfficialActivityTutorApplyCheck
} from '@/api/index.js'
	import {dateFormat} from '@/util/setTime.js'
import {
  Toast
} from 'vant';
export default {
  name: 'traindal',
  components: {},
  data() {
    return {
      // 替换助教导师
      isdaoshi: false,
      // 是否没有这么导师
      isseach: false,
      searchList: {
        act_id: this.$route.query.act_id,
        user_name: '',
        user_phone: ''
      },
      // 取消导师弹窗
      show1: false,
      // 标签页标识符
      tabIndex: 0,
      // isOver
      act_id: 0,
      // 申请监督
      show: false,

      title: '培训详情及管理',
      conData: {
        start: '2019-12-10 16:00',
        end: ' 2019-12-10 13:00',
        kaoStart: ' 2019-12-10 13:00',
        supervisor: 1,
        PXcourse: '心肺复苏术、AED使用、创伤包扎',
        KHcourse: '心肺复苏术、AED使用、创伤包扎'

      },
      list_title: ['学员报名', '助教报名', '学员签到', '导师签到'],
      tab_list: [{
        name: 'xxxx',
        phone: 123456789,
        type: '123',
        state: 0
      },
      {
        name: 'xxxx',
        phone: 123456789,
        type: '123',
        state: 1
      },
      {
        name: 'xxxx',
        phone: 123456789,
        type: '12',
        state: '阿斯顿'
      },
      {
        name: 'xxxx',
        phone: 123456789,
        type: '312',
        state: 1
      },
      {
        name: 'xxxx',
        phone: 123456789,
        type: '123',
        state: 0
      }
      ],
      list1: {
        img: 'https://img.yzcdn.cn/vant/cat.jpeg',
        place: '广州市萝岗区科学城科珠路201号广东软件园G栋401室',
        keName: '成人心肺复苏及自动体外除颤器（AED）使用方法 ',
        time: '2017-12-07  13:30',
        site: '天鹰救援培训中心-广州分中心',
        cost: 132
      },
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      list5: [],
      // 培训课程
      courseList: [],
      // 考核课程
      courseList_kao: [],
      isres: 2,
	  message: '',
    }
  },
  methods: {
	  btnClick(item){
	  		 let date = new Date()
	  		 let time = dateFormat('Y-m-d',date)
	  		 this.$router.push({name:'supervisorTheAssessment',query:{sign_name:this.conData.act_author,time:time,course:'',courseId:this.conData.train_course,sign_id:this.$route.query.user_id,act_id:this.$route.query.act_id,train_course_id: 4,courseId:5,course_name:'',act_type:2}})
	  },
	  btnClickOK(item){
	  		let date = new Date()
	  		let time = dateFormat('Y-m-d',date)
	  		this.$router.push({name:'supervisorTheAssessmentOK',query:{sign_name:this.conData.act_author,time:time,course:'',courseId:this.conData.train_course,sign_id:this.$route.query.user_id,act_id:this.$route.query.act_id,train_course_id: 4,courseId:5,course_name:'',act_type:2}})
	  },
	  push(is,item){
		  if(is){
			  console.log(item)
			  
			  let arr = {
				train_course:this.conData.train_course,
				sign_name:item.act_author,
				course:4,
				sign_id:320,
				act_id:this.$route.query.act_id}
			  this.$router.push({name:'tutorInAassessment',query:arr})
		  }
	  },
    acceptClick() {
      postOfficialActivityTutorApplyCheck({ act_id: this.$route.query.act_id, action_type: 1 }).then(res => {
		 Toast(res.msg);
        if (res.code==1) {
          this.conData.tutor_status=3
		  this.conData.tutor_res_status=2
        }
      })
    },
    refuseClick() {
	  postOfficialActivityTutorApplyCheck({ act_id: this.$route.query.act_id, action_type: 2, tutor_reason: this.message }).then(res => {
		 Toast(res.msg);
	    if (res.code==1) {
	      this.conData.tutor_res_status=3
	    }
	  })
    },
    // 培训详情的证书发放列表
    getActivitySigninCertsLists() {
      getActivitySigninCertsLists({
        act_id: this.$route.query.act_id,
        act_type: 1
      }).then(res => {
       
        if (res.code == 1) {
          this.list1 = res.data
          this.tab_list = this.list1.list
        } else {
          this.tab_list = []
          this.list1 = []
        }
      })
    },
    // 督导申请
    needTutorClick() {
      postOfficialActivityTutorApply({
        act_id: this.$route.query.act_id
      }).then(res => {
        Toast(res.msg);
        if (res.code == 1) {
          this.conData.tutor_status = 2
          this.show = false
        }
      })
    },
    // 暂不取消
    quxiao() {
      this.show1 = false
      this.isseach = false
      this.isdaoshi = false
    },
    // 发送替换申请
    replaceClick() {
      for (let item in this.searchList) {
        if (!this.searchList[item]) {
          switch (item) {
          case 'act_id':
            Toast('缺少活动id');
            return
            break;
          case 'user_name':
            Toast('缺少导师名字');
            return
            break;
          case 'user_phone':
            Toast('缺少导师手机号');
            return
            break;
          }
        }
      }
      getSearchAndSendMessage(this.searchList).then(res => {
        if (res.code == 0) {
          this.isseach = true
        }
      })
    },
    user_phoneClick() {
      console.log('点击')
      this.isphone = true
    },
    ownClick() {
      getCancelSignupTutor({
        act_id: this.$route.query.act_id
      }).then(res => {
        Toast(res.msg);
        if (res.code == 1) {
          this.isres = res.data.res
          this.isdaoshi = res.data.res == 2
          // res.data.res
        } else {

        }
      })
    },
    changeActivityStatus() {
      let act_statusIng;
      act_statusIng = this.conData.act_status == 1 ? 3 : this.conData.act_status == 3 ? 1 : 0;
      changeActivityStatus({
        act_id: this.$route.query.act_id,
        act_status: act_statusIng
      }).then(res => {
        this.conData.act_status = res.data
        Toast(res.msg);
      })
    },
    onClick(name, title) {
      // this.tab_list=[]
      switch (name) {
      case 0:
        this.getActivitySignupLists()
        break;
      case 1:
        this.getActivitySignupTutorLists()
        break;
      case 2:
        this.getActivitySigninLists()
        break;
      case 3:
        this.getActivitySigninTutorLists()
        break;
      case 4:
        this.getActivitySigninCertsLists()
        break;
      }
    },
    // 培训详情的学员报名数据
    getActivitySignupLists() {
      getActivitySignupLists({
        act_id: this.$route.query.act_id,
		act_type:this.$route.query.act_type
      }).then(res => {
        console.log(res)
        if (res.code == 1) {
          this.list1 = res.data
          this.tab_list = this.list1.list
        } else {
          this.tab_list = []
          this.list1 = []
        }

      })
    },
    // 培训详情的学员签到列表
    getActivitySigninLists() {
      getActivitySigninLists({
        act_id: this.$route.query.act_id,
		act_type:this.$route.query.act_type
      }).then(res => {
        if (res.code == 1) {
          this.list1 = res.data
          this.tab_list = this.list1.list
        } else {
          this.tab_list = []
          this.list1 = []
        }
      })
    },
    // 培训详情的助教导师签到列表
    getActivitySigninTutorLists() {
      getActivitySigninTutorLists({
        act_id: this.$route.query.act_id,
		act_type:this.$route.query.act_type
      }).then(res => {
        if (res.code == 1) {
          this.list1 = res.data
          this.tab_list = this.list1.list
        } else {
          this.tab_list = []
          this.list1 = []
        }
      })
    },
    // 培训详情的助教导师报名列表
    getActivitySignupTutorLists() {
      console.log(this.$route.query.act_id)
      getActivitySignupTutorLists({
        act_id: this.$route.query.act_id,
		act_type:this.$route.query.act_type
      }).then(res => {
        if (res.code == 1) {
          this.list1 = res.data
          this.tab_list = this.list1.list
        } else {
          this.tab_list = []
          this.list1 = []
        }

      })
    },
    stateClick(i) {
      if (this.tab_list[i].state) {
        this.$router.push({
          name: 'userCertificate',
          query: {
            id: 1
          }
        })
      }
    },
    editClick() {
      console.log(this.conData)
      this.$router.push({
        name: 'setTrain',
        query: {
          act_id: this.act_id
        }
      })
    },
    Success() {},
    showPopup() {
      this.show = true;
    },
    // 去除空白数组下标
    skipEmptyElementForArray(arr) {
      var a = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]) {
          a.push(arr[i])
        }
      }
      return a;
    },
    // courseList
    // courseList_kao
    // 先获得课程。避免课程慢得到
    getOfficialCourse() {
      getOfficialCourse({act_type:this.$route.query.act_type}).then(resd => {
        const list = resd.data
        console.log(list)
        getActivity_t({
          act_id: this.act_id
        }).then((res) => {
          this.conData = res.data
		   this.conData.tutor_status=this.conData.exam_result==1?5:this.conData.tutor_status
		  console.log(this.conData.tutor_status,'this.conData.tutor_status')
		  // this.conData.tutor_status=2
          // 培训课程
          // let list1=this.skipEmptyElementForArray(this.conData.train_course.split('|'))
          let list1 = this.conData.train_course
          // 考核课程
          let list2 = this.conData.course
          for (let i = 0; i < list.length; i++) {
            // 先判断是否有选中的培训课程
            for (let j = 0; j < list1.length; j++) {
              if (list[i].id == list1[j]) {
                this.courseList.push(list[i].name)
              }
            }
            for (let j = 0; j < list[i].course.length; j++) {
              for (let q = 0; q < list2.length; q++) {

                if (list[i].course[j].id == list2[q]) {
                  this.courseList_kao.push(list[i].course[j].name)
                }
              }
            }


          }
          console.log(this.courseList, this.courseList_kao)
        })
      })
    }
  },
  mounted() {
    document.title = this.title;
    this.act_id = this.$route.query.act_id
    this.getOfficialCourse()

    this.getActivitySignupLists()


  }
}
</script>

<style lang="scss">
	// .van-divider::after,
	// .van-divider::before {
	// 	border-width: 2px 0 0;
	// }

	input.van-field__control::-webkit-input-placeholder {

		color: #919CB0;
	}

	input.van-field__control::-moz-placeholder {

		color: #919CB0;
	}

	input.van-field__control:-moz-placeholder {

		color: #919CB0;
	}

	input.van-field__control:-ms-input-placeholder {

		color: #919CB0;

	}

	.set .van-field__body {
		position: relative;
	}

	.phoneClass .van-field__body::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: left;
		padding: 2px 10px;
		display: block;
		content: "请输入该导师在当前公众号绑定的手机号码";
		font-size: 10px;
		line-height: 15px;
		box-sizing: border-box;
		z-index: 0;
		color: #919CB0;
	}


	.traindal {
		padding-bottom: 30px;

		.van-popup--center.van-popup--round {
			border-radius: 6px;
			text-align: left;
		}

		.van-tab--active {
			transform: scale(1.1);
		}

		.fontWeight {
			font-weight: bold;
		}


		.zhuren {
			.popupBox {
				width: 300px;
				height: 11.875rem;
				background-color: #fff;
				border-radius: 8px;
				padding: 0px 20px 28px;

				.title {
					padding: 42px 0;
					line-height: 23px;
				}

				.btnBox {
					justify-content: flex-end;
					color: #919CB0;

					div:first-child {
						margin-right: 40px;
					}

					.suss {
						color: #0081FF;
					}
				}
			}
		}

		.search {
			.popupBox {
				width: 300px;
				// height: 11.875rem;
				background-color: #fff;
				border-radius: 8px;
				padding: 10px 15px 28px 20px;
				text-align: center;
				position: relative;

				.nosearch {
					color: #FF524D;
					border-bottom: 1px solid #E5E5E5;
					padding: 4px 0 14px;
				}

				.title {
					padding: 16px 0 16px;
					line-height: 23px;
					text-align: left;
				}

				.van-field__control {
					padding: 6px 10px;
					z-index: 1;
				}

				.van-field__body {
					background-color: #F4F4F4;
					border-radius: 6px;
				}

				.van-field__label {
					width: 75px;
				}

				.van-cell {
					padding-left: 0;
					padding-right: 0;
				}

				.input {
					padding: 6px 0 28px;


				}


				.btnBox {
					position: absolute;
					bottom: 28px;
					right: 34px;
					justify-content: flex-end;
					color: #919CB0;

					div:first-child {
						margin-right: 40px;
					}

					.suss {
						color: #0081FF;
					}
				}
			}
		}

		.dudao {
			.popupBox {
				width: 300px;
				height: 11.875rem;
				background-color: #fff;
				border-radius: 8px;
				padding: 0 20px 28px;
				text-align: center;
				position: relative;
				.van-cell {
				    padding: 0px 16px;
				}
				.title{
					font-size: 18px;
					color: #28334A;
					text-align: center;
					width: 100%;
					padding: 20px 0;
					font-weight: bold;
				}

				.btnBox {
					position: absolute;
					bottom: 20px;
					right: 34px;
					justify-content: flex-end;
					color: #919CB0;

					div:first-child {
						margin-right: 40px;
					}

					.suss {
						color: #0081FF;
					}
				}
			}
		}

		.bottom {
			position: fixed;
			bottom: 0;
			left: 0;
			background: #F4F4F4;
			width: 100%;
			height: 30px;
			font-size: 12px;
			color: #28334A;
			line-height: 30px;
			text-align: left;
			padding-left: 15px;
		}

		.van-tabs__line {
			background-color: #0081FF;
			width: 1.125rem !important;
		}

		.van-tabs__content {
			background-color: #fff;
		}

		.van-tab__text {
			font-weight: bold;
			font-size: 15px;
		}

		.List_row {
			padding: 16px 0;
			margin: 0 15px;
			border-bottom: 1px solid #E5E5E5;
			color: #767E8D;
		}

		.imgBox {
			// position: absolute;
			// top: 0;
			// left: 0;
			// height: 200px;
			// width: 100%;
			z-index: -1;
			position: absolute;
			top: 0;
		}

		.list {
			flex-wrap: inherit;
			padding: 15px;
			padding-top: 0;

			.list_img {
				margin-right: 0.71875rem;
				position: relative;
			}

			.list_img:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 6px;
				right: 0;
				width: 1.375rem;
				height: 1.25rem;
				background: no-repeat center;
				background-size: 100% 100%;
			}

			.list_img.list_img_1:after {

				background-image: url(../../assets/Train/icon_1@2x.png);

			}

			.list_img.list_img_2:after {

				background-image: url(../../assets/Train/icon_2@2x.png);

			}

			.list_img.list_img_3:after {

				background-image: url(../../assets/Train/icon_3@2x.png);

			}


			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}

			.text {
				font-size: 0.75rem;
				color: #919CB0;
				// margin-left: 0.5rem;
			}

			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}

			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}

			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}

			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}

			.btnimg_span {
				margin-right: -5px;

			}
		}

		.container1 {
			// margin: 15px;
			background-color: #fff;
			// height: 280px;
			z-index: 2;
			border-radius: 0.375rem;
			text-align: left;
			position: relative;
			width: 100%;

			// position: absolute;
			// top: 15px;
			// left: 50%;
			// transform: translateX(-50%);
			// margin-top: -50px;
			padding-bottom: 10px;

			// margin-bottom: 15px;
			.header {
				text-align: left;
				font-size: 1.125rem;
				font-weight: bold;
				padding-top: 20px;
			}

			.header_title {
				font-weight: bold;
				font-size: 17px;
				padding: 15px 11px;

			}

			.conData {
				// padding: 28px 0px;
				font-size: 14px;

			}

			.cost {
				color: #0081FF;
			}

			.title {
				color: #919CB0;
			}

			.van-row {
				margin: 14px 0;
				padding: 0 15px;
			}

			.dashed {
				position: relative;

				// &::after {
				// 	position: absolute;
				// 	top: -8px;
				// 	left: 0px;
				// 	width: 8px;
				// 	height: 16px;
				// 	content: '';
				// 	display: block;
				// 	background: url(../../assets/Train/bg_yuan_left@2x.png) no-repeat center;
				// 	background-size: 100% 100%;
				// }

				// &::before {
				// 	position: absolute;
				// 	top: -8px;
				// 	right: 0px;
				// 	width: 8px;
				// 	height: 16px;
				// 	content: '';
				// 	display: block;
				// 	background: url(../../assets/Train/bg_yuan_right@2x.png) no-repeat center;
				// 	background-size: 100% 100%;
				// }
			}
		}

		.fu {
			padding: 15px;
			color: #919CB0;

			img {
				width: 0.75rem;
				height: 0.75rem;
				margin-right: 0.5625rem;
			}
		}

		.cancel {
			background-color: #FFFFFF;
			border: 1px solid #0081FF;
			color: #0081FF;
			font-size: 15px;
		}

		.zhi {
			margin-left: 17px;
			color: #919CB0;
			font-size: 15px;
		}

		.btn {
			border: 1px solid #0081FF;
			width: 8.75rem;
			height: 40px;
			color: #0081FF;
			font-size: 15px;
			text-align: center;
			line-height: 38px;
			border-radius: 50px;
		}

		.applyBox {
			.applyBtn {
				width: 70px;
				height: 26px;
				line-height: 24px;
				font-size: 12px;
				margin-left: 26px;
			}
		}

		.btn1{
			color: #0081FF;
			font-size: 15px;
			text-align: center;
			line-height: 2.625rem;
			border-radius: 50px;
			font-size: 13px;
			min-width: 91%;
		}
		
	}
</style>
