<template>
	<div class="demonstration">
		<div style="position: relative;">
			<div class="titleBox1"><span style="font-weight: 100;">课程名称：</span><span v-text="$route.query.course_id==2?'心肺复苏术及自动体外除颤器仪使用课程':'急症急救及创伤救护课程'"></span></div>
		</div>
		<div style="position: relative;height: 10px;">
			
		</div>
		<div class="bigBox" style="width: 100%;padding: 0;" v-for="(item,i) in list" :key="i">
			<div style="position: relative;height: 50px;">
				<div class="titleBox"><span v-text="item.title"></span></div>
			</div>
			<div class="ylcBox" v-show="!item.isplay">
				<img :src="item.cover_image" alt="" class="bg" />
				<!-- <div class="flex top">
						<div><span v-text="item.course_name"></span></div>
					</div> -->
				<div class="flex bot">
					<div class="time">
						<span v-text="setTime('211')" style="font-size: 10px;color: #fff;"></span>
					</div>
					<div class="btnBo" @click="playClick(i,item.url)">
						播放
					</div>
				</div>
				<!-- <img src="../../assets/user/bofan.png" alt="" class="conter" @click="playClick(i,item)"> -->
			</div>
			
		<!-- <span>现场急救序章</span> -->
			<div class="ylcBox" v-if="item.isplay">
				<video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
			</div>
		
		</div>
		
	</div>
</template>
<!-- playClick(i, item) -->
<script>
import { setMinute } from "@/util/setTime.js";
import {getExampleVideo} from "@/api"
	export default {
  name: "demonstration",
  components: {},
  data() {
    return {
		list:[],
		url:'https://xcjjvideo.oss-cn-shenzhen.aliyuncs.com/upload/video_org/%E7%8E%B0%E5%9C%BA%E6%80%A5%E6%95%91%E8%A1%8C%E5%8A%A8%E6%8C%87%E5%8D%97%E5%BA%8F%E7%AB%A0.mp4?x-oss-process=video/snapshot,t_1000,m_fast',
		overUrl:'https://xcjjvideo.oss-cn-shenzhen.aliyuncs.com/upload/online_course_video/%E7%BB%88%E7%AB%A0.mp4?x-oss-process=video/snapshot,t_1000,m_fast',
		isplay:false,
		playerOptions : {
		    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
		    autoplay: true, //如果true,浏览器准备好时开始回放。
		    muted: false, // 默认情况下将会消除任何音频。
		    loop: true, // 导致视频一结束就重新开始。
		    preload: '', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
		    language: 'zh-CN',
		    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
		    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
		    sources: [{
		      type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
		      src: "https://xcjjvideo.oss-cn-shenzhen.aliyuncs.com/upload/video_org/%E7%8E%B0%E5%9C%BA%E6%80%A5%E6%95%91%E8%A1%8C%E5%8A%A8%E6%8C%87%E5%8D%97%E5%BA%8F%E7%AB%A0.mp4" //url地址
		    }],
		    poster: "https://xcjjvideo.oss-cn-shenzhen.aliyuncs.com/upload/online_course_video/%E7%BB%88%E7%AB%A0.mp4?x-oss-process=video/snapshot,t_1000,m_fast", //你的封面地址
		    // width: document.documentElement.clientWidth, //播放器宽度
		    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
		    controlBar: {
		      timeDivider: true,
		      durationDisplay: true,
		      remainingTimeDisplay: false,
		      fullscreenToggle: true  //全屏按钮
		    }
		},
		videoAssobj:{}
		}
  },
  methods: {
	 playClick(i,url){
		 this.list.forEach((item,i)=>{
			 item.isplay = false;
		 })
		 this.list[i].isplay = true;
			 this.playerOptions.sources[0].src=url
		 // 防止拿不到视频实例
		 setTimeout(()=>{
			  this.videoAssobj=this.$refs.videoPlayer.player
			   this.videoAssobj.play()
			   // this.videoAssobj.currentTime(100)
			 console.log('this is current player instance object', this.videoAssobj)
		 },1000)
	 },
	setTime(time) {
	  return setMinute(time);
	},
	getVideoBase64(url) {
	        return new Promise(function (resolve, reject) {
	            let dataURL = '';
	            let video = document.createElement("video");
	            // video.setAttribute('crossOrigin', 'anonymous');//处理跨域
	            video.setAttribute('src', url);
	            video.setAttribute('width', 400);
	            video.setAttribute('height', 240);
	            video.addEventListener('loadeddata', function () {
	                let canvas = document.createElement("canvas"),
	                    width = video.width, //canvas的尺寸和图片一样
	                    height = video.height;
	                canvas.width = width;
	                canvas.height = height;
	                canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
	                dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
					console.log(dataURL)
	                resolve(dataURL);
	            });
	        })
	    }
  },
  mounted() {
	  let _this=this
	getExampleVideo({course_id : this.$route.query.course_id}).then(res=>{
		res.data.forEach((item,i)=>{
			item.isplay=false
		})
		this.list=res.data
		
	})
    document.title ='线下课程操作考核示范视频';
  }
};
</script>
<style lang="scss" type="text/css">
	.demonstration {
		min-height: 100vh;
		background-color: #fff;
		text-align: left;
		padding-bottom: 15px;
		// display: flex;
		// flex-wrap: wrap;
		// justify-content: space-between;

		.zhuren {
			z-index: 9999;
			border-radius: 6px;

			.popupBox {
				width: 300px;
				height: 180px;
				background-color: #fff;
				border-radius: 6px;
				padding: 0px 20px 28px;

				.title {
					padding: 42px 0;
					line-height: 23px;
				}

				.btnBox {
					justify-content: space-around;
					color: #919CB0;

					div:first-child {
						margin-right: 40px;
					}

					.suss {
						color: #0081FF;
					}
				}
			}
		}

		.titleBox {
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: 800;
			color: rgba(40, 51, 74, 1);
			padding: 15px;
			position: relative;

			&:after {
				content: "";
				display: block;
				width: 3px;
				height: 20px;
				background: rgba(0, 129, 255, 1);
				border-radius: 0px 3px 3px 0px;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}
		}
	
		.titleBox1{
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 800;
			color: rgba(40, 51, 74, 1);
			padding: 10px;
			padding-bottom: 0;
			position: relative;
			
			
		}
		@media screen and (max-width: 370px) {
		    .titleBox {
		        font-size: .8rem;
		    }
		}
		.jihuoBtn {
			background-color: #c6c8ca;
			width: 70px;
			height: 40px;
			border-radius: 6px;
			color: #fff;
			text-align: center;
			line-height: 40px;
		}

		.ylcBox {
			width: 100%;
			// height: 231px;
			position: relative;


			.top {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 5;
			}

			.bot {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 6;
				font-size: 12px;
			}

			.bg {
				width: 100%;
				height: 100%;
				position: relative;
				top: 0;
				left: 0;
			}

			.flex {
				position: absolute;

				z-index: 6;
				color: #fff;
				justify-content: space-between;
				width: 100%;
				padding: 10px 15px;
			}

			.conter {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 50px;
				height: 50px;
			}

			.time {
				width: 50px;
				height: 26px;
				background: rgba(40, 51, 74, 0.5);
				border-radius: 13px;
				line-height: 26px;
				font-size: 12px;
				text-align: center;
			}

			.btnBo {
				text-align: center;
				line-height: 32px;
				width: 60px;
				height: 32px;
				background: rgba(0, 129, 255, 1);
				border-radius: 16px;
				font-size: 15px;
			}
		}

		.btn {
			border: 1px solid #0081ff;
			color: #0081ff;
			border-radius: 52px;
			width: 70px;
			font-size: 12px;
			height: 27px;
			line-height: 27px;
			text-align: center;
		}

		.btn1 {
			border: 1px solid #919cb0;
			color: #919cb0;
			border-radius: 52px;
			width: 70px;
			font-size: 12px;
			height: 27px;
			line-height: 27px;
			text-align: center;
		}

		.delBox {
			.van-popup {
				width: 300px;
				height: 190px;
				border-radius: 6px;
				padding: 0 1.25rem;
			}
		}

		.Bigbox {
			position: relative;
			width: 92%;
			height: 296px;
			margin: 100px auto 0;
			background-color: #fff;
			z-index: 3;
			border-radius: 6px;
			padding: 49px 15px 0;
			text-align: left;

			input {
				width: calc(100% - 24px);
			}

			.box {
				width: calc(100% - 15px - 70px);
			}
		}


		.box {
			padding: 12px 15px 12px;
			background-color: #fff;
			font-size: 12px;

			.boxI {
				text-align: left;
				width: 100%;
				padding: 9px 12px;
				background: rgba(245, 245, 245, 1);
				border-radius: 6px;

				span {
					color: #fed200;
				}
			}
		}

		.vjs-custom-skin>.video-js .vjs-big-play-button {
			transform: translate(-50%) !important;
			margin-left: 0 !important;
		}

		.vjs-custom-skin>.video-js .vjs-big-play-button {
			/*
		      播放按钮换成圆形
		     */
			height: 2em !important;
			width: 2em !important;
			line-height: 2em !important;
			border-radius: 50% !important;
		}

		.vjs-custom-skin>.video-js .vjs-control-bar .vjs-playback-rate {
			display: none !important;
		}

		.video-js .vjs-volume-panel {
			display: none !important;
		}

		.vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal {
			display: none !important;
		}

		video {
			// z-index:9990;
			width: 100%;
		}
	}
</style>
