<template>
	<div class="traindal" :style=" tabIndex==1?' padding-bottom: 60px;':''  ">
		<div class="imgBox">
			<img src="../../assets/Train/bg_bmtop@2x.png" alt="" class="auto-img">
		</div>
		<div style="padding: 15px 15px 10px;">
			<div class="container1">
				<!-- <div class="header">现场急救培训-广东软件园</div> -->
				<div class="header_title">
					<span v-text="conData.act_name"></span>
					<!-- <van-divider /> -->
				</div>
				<div class="conData">

					<van-row style='margin: 0;'>
						<van-col span="4">
							<span class="title">时间</span>
						</van-col>
						<van-col span="18">
							<div class="flex">
								<div>
									<div>
										<span v-text="conData.act_start_time"></span>
									</div>
									<div>
										<span v-text="conData.act_end_time"></span>
									</div>
								</div>
								<div class="zhi">至</div>
							</div>
						</van-col>
					</van-row>
					<!-- 	<van-row>
						<van-col span="9">
							<span class="title">考核开始时间</span>
						</van-col>	
						<van-col span="15">
							<span v-text="conData.check_start_time"></span>
						</van-col>
					</van-row> -->
					<van-row>
						<van-col span="6">
							<span class="title">培训地点</span>
						</van-col>
						<van-col span="18" @click="sweatClick">
							<span v-text="conData.act_addr"></span>
						</van-col>
					</van-row>
					<van-row>
						<van-col span="6">
							<span class="title">培训课程</span>
						</van-col>
						<van-col span="18">
							<span v-for="(item,i) in courseList" :key="i" v-text="i==courseList.length-1?item:item+','"></span>
						</van-col>
					</van-row>

					<van-row>
						<van-col span="6">
							<span class="title">考核课程</span>
						</van-col>
						<van-col span="18">
							<span v-for="(item,i) in courseList_kao" :key="i" v-text="i==courseList_kao.length-1?item:item+','"></span>
						</van-col>
					</van-row>
					<van-row  >
						<van-col span="8">
							<span class="title">主任导师督导</span>
						</van-col>
						<van-col span="16">
							<div class="applyBox" style="display: flex; justify-content: space-between;">
								<span v-if="conData.need_tutor==0">不需要</span>
								<!-- 未申请 -->
								<span v-text="conData.tutor_status==0?'需要，未申请':conData.tutor_status==2?'需要，已公开申请':conData.tutor_status==3?'预约'+ conData.tutor_user_name +'，待付款':conData.tutor_status==4?'预约了'+ conData.tutor_user_name +'主任导师成功':conData.tutor_status==5?'暂无人接受':''"
								 v-if="conData.need_tutor==1&&conData.tutor_apply_type==0"></span>
								<!-- 公开申请 -->
								<span v-text="conData.tutor_status==0?'需要，未申请':conData.tutor_status==2?'需要，已公开申请':conData.tutor_status==3?'预约'+ conData.tutor_user_name +'，待付款':conData.tutor_status==4?'预约了'+ conData.tutor_user_name +'主任导师成功':conData.tutor_status==5?'暂无人接受':''"
								 v-if="conData.need_tutor==1&&conData.tutor_apply_type==2"></span>
								 <!-- 定向申请 -->
								 <span v-text="conData.tutor_status==0?'需要，未申请':conData.tutor_status==2?'需要，已定向申请':conData.tutor_status==3?'预约'+ conData.tutor_user_name +'，待付款':conData.tutor_status==4?'预约了'+ conData.tutor_user_name +'主任导师成功':conData.tutor_status==5?'暂无人接受':''"
								  v-if="conData.need_tutor==1&&conData.tutor_apply_type==1"></span>
								  <div v-if="conData.act_status==1&&$route.query.isown==1&&conData.tutor_apply_type==0">
								  	<div class="btn applyBtn" @click="showPopup" v-if="conData.need_tutor==1&&conData.tutor_status==0">申请督导</div>
								  	<div class="btn applyBtn" @click="successClick" v-if="conData.need_tutor==1&&conData.tutor_status==2||conData.tutor_status==3"
								  	 :style="conData.tutor_status==2?'color:#919CB0;border-color:#919CB0;':''">付款</div>
								  </div>
								<div v-if="conData.act_status==1&&$route.query.isown==1&&conData.tutor_apply_type==1">
									<div class="btn applyBtn" @click="showPopup" v-if="conData.need_tutor==1&&conData.tutor_status==0">申请督导</div>
									<div class="btn applyBtn" @click="successClick" v-if="conData.need_tutor==1&&conData.tutor_status==2||conData.tutor_status==3"
									 :style="conData.tutor_status==2?'color:#919CB0;border-color:#919CB0;':''">付款</div>
								</div>
								<div v-if="conData.act_status==1&&$route.query.isown==1&&conData.tutor_apply_type==2">
									<div class="btn applyBtn" @click="showPopup" v-if="conData.need_tutor==1&&conData.tutor_status==0||conData.tutor_status==2">申请督导</div>
									<div class="btn applyBtn" @click="successClick" v-if="conData.need_tutor==1&&conData.tutor_status==3"
									 :style="conData.tutor_status==2?'color:#919CB0;border-color:#919CB0;':''">付款</div>
								</div>
							</div>
						</van-col>
					</van-row>
					<van-row v-if="conData.exam_result">
						<van-col span="6">
							<span class="title">督导结果</span>
						</van-col>
						<van-col span="18" class="applyBox">
							<div class="btn applyBtn" @click="btnClickOK(conData.tutor_status==4,conData)">查看</div>
						</van-col>
					</van-row>
					<div class="img">
						<VueQr :logSrc="config.logo" :text="config.value" :margin="0" style="width: 100%;height: 100%;"></VueQr>
					</div>
					<div style="text-align: center;margin-top: 10px;">
						<p>报名二维码</p>
					  <p>长按保存图片分享</p> 
					</div>
					<div class="dashed">
						<van-divider :style="{ borderColor: '#F7F8FF'}" :hairline="false"></van-divider>
					</div>
					<div v-if="activityAuthCheck.is_zhujiang_teacher==1">
						<div class="flex" style="justify-content: space-around;" v-if="conData.act_status==1||conData.act_status==3">
							<div class="btn" v-if="conData.act_status==1" @click="offlineShow=true"><span v-text="conData.act_status==1?'下线':''"></span></div>
							<div class="btn" v-if="conData.act_status==3" :style="conData.act_status==3?'border-color:#ddd;color:#ddd':''"><span
								 v-text="conData.act_status==3?'上线':''"></span></div>
							<div class="btn" v-if="conData.act_status==1" @click="editClick" :style="conData.act_status==3?'border-color:#ddd;color:#ddd':''">编辑</div>
							<div class="btn" v-if="conData.act_status==3" :style="conData.act_status==3?'border-color:#ddd;color:#ddd':''">编辑</div>
						</div>
						<!-- 	<div class="flex" style="justify-content: space-around;" v-if="conData.act_status==2">
							<div class="btn" @click="$router.push({name:'feedbackFrom',query:{id:$route.query.act_id}})" style="background-color: #0081FF;color: #fff;">发布培训反馈</div>
						</div>
						<div class="flex" style="justify-content: space-around;" v-if="conData.act_status==4">

							<div class="btn" style="background-color: #0081FF;color: #fff; " @click="$router.push({name:'lookFeedbackFrom',query:{act_id:$route.query.act_id}})">查看反馈</div>
						</div> -->
					</div>
					<div v-if="$route.query.isown==0&&conData.act_status==1&&conData.is_cancel==1" class="flex" style="justify-content: center;">
						<div class="btn" style="background-color: #0081FF;color: #fff; width: 295px;border-color: transparent;" @click="show1=true">取消助教导师资格申请</div>
					</div>
					<div v-if="$route.query.isown==0&&conData.act_status==1&&conData.is_cancel==2" class="flex" style="justify-content: center;">
						<div class="btn" style="background-color: #C6C8CA;color: #fff; width: 295px;border-color: transparent;">等待导师接受替换</div>
					</div>
					<div v-if="$route.query.isown==0&&conData.act_status==1&&conData.is_cancel==0" class="flex" style="justify-content: center;">
						<div class="btn" style="background-color: #C6C8CA;color: #fff; width: 295px; border-color: transparent;">已取消助教导师资格</div>
					</div>

				</div>
			</div>
		</div>
		<!-- <div style="width: 100%;height: 15px;"></div> -->
		<div>
			<van-tabs title-inactive-color="#919CB0" @click="onClick" v-model="tabIndex">

				<van-tab v-for="index in list_title" :title="index" :key="index">
					<div v-if="!tab_list.length">
						暂无数据
					</div>

					<div v-for="(item,i) in tab_list" class="List_row" v-if="index!=='证书发放'&&tab_list.length&&index!=='学员签到'" :key="i">
						<div class="list_row_row">
							<div><span v-text="item.sign_name"></span></div>
							<div><span v-text="item.sign_phone"></span></div>
							<div v-if="index=='导师签到' && list1.is_zhujiang_teacher == 1"><span v-text="item.dis_counts == 0 ? '未分配' : item.dis_counts + '人'"></span></div>
							<div style="text-align: right;">
								<span v-text="
								item.teacher_type==0?'助教导师':item.teacher_type==1?'主讲导师':''
								" v-if="index=='导师签到'"></span>
								<span v-text="i+1" v-if="index=='助教导师报名'"></span>
								<span v-else v-text="item.sex==1?'男':item.sex==2?'女':''"></span>
							</div>
						</div>
					</div>
					<van-checkbox-group v-model="result" @change="checkClick" class="List_row" v-if="index=='学员签到'&&tab_list.length">
						<van-checkbox :name="i" v-for="(item,i) in tab_list" :key="i">
							<div class="list_row_row" style="margin-left: 15px;margin-bottom: 7px;">
								<div><span v-text="item.sign_name"></span></div>
								<div><span v-text="item.sign_phone"></span></div>
								<div style="text-align: right;">
									<div class="kaobtn " @click="kao(item)" v-if="!item.is_complete">考核</div>
									<div class="kaobtn kaobtn_Act " @click="kao_act(item)" v-if="item.is_complete">已考核</div>
								</div>
							</div>
							
						</van-checkbox>
					</van-checkbox-group>

					<!-- <van-row v-for="(item,i) in tab_list" class="List_row" v-if="index=='导师签到'&&tab_list.length" :key="i">
						<van-col span="8" style="text-align: left;"><span v-text="item.sign_name"></span></van-col>
						<van-col span="8"><span v-text="item.sign_phone"></span></van-col>
						<van-col span="8" style="text-align: right;">
							<div class="kaobtn " @click="kao(item)" v-if="!item.is_complete">考核</div>
							<div class="kaobtn kaobtn_Act " @click="kao_act(item)"  v-if="item.is_complete">已考核</div>
						</van-col>
					</van-row> -->
					<van-row v-for="(item,i) in tab_list" class="List_row" v-if="index=='证书发放'&&tab_list.length" :key="i">
						<van-col span="6" style="text-align: left;"><span v-text="item.sign_name"></span></van-col>
						<van-col span="6"><span v-text="item.sign_phone"></span></van-col>
						<van-col span="6" style="text-align: right;">
							<span></span>
						</van-col>
						<van-col span="6" style="text-align: right;" @click="stateClick(i,item)" v-if="!item.is_certs"><span
							 :style="item.is_certs?'color:#00D97E':'color:#919CB0'">未发证</span>
							<van-icon name="arrow" :color="item.is_certs?'#00D97E':'#919CB0'" />
						</van-col>
						<van-col span="6" style="text-align: right;" v-if="item.is_certs"><span :style="item.is_certs?'color:#00D97E':'color:#919CB0'">已发证</span>
							<van-icon name="arrow" :color="item.is_certs?'#00D97E':'#919CB0'" />
						</van-col>
					</van-row>
				</van-tab>
			</van-tabs>
		</div>
		<div class="bottom" v-if="tabIndex==0">
			报名总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人
		</div>
		<div class="totorBottom  flex" v-if="tabIndex==1" style="height: 60px;justify-content: space-between;width: 100%;">
			<div style="width: calc(100% - 140px)">
				<div> 学员已报名人数：<span class="fontWeight" v-text="list1.signUpStudents?list1.signUpStudents:0"></span>人  需助教<span class="fontWeight" v-text="list1.needs?list1.needs:0"></span>人</div>
				<div>系统会根据助教导师报名顺序安排助教导师</div>
			</div> 
			<div class="jdsbtn" @click="totorClick" v-if="list1.is_zhujiang_teacher==1">
				助教导师报名
			</div>
		</div>
		<!-- <div class="bottom flex" v-if="tabIndex==1" >
			助教导师报名总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人
		</div> -->
		<div class="bottom bot-flex" v-if="tabIndex==2">
			<div>
				签到总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人
			</div>
			<div class="signup-button" v-if="isShowStudentGroupButtom && list1.is_zhujiang_teacher == 1" @click.stop="$router.push({
				name: 'distributionAssistant',
				query: { act_id, resultGroupStr }
				})">学员分组</div>
		</div>
		<div class="bottom" v-if="tabIndex==3">
			签到总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人，主讲导师：<span class="fontWeight" v-text="list1.count1?list1.count1:0"></span>人，助教导师：<span
			 class="fontWeight" v-text="list1.count2?list1.count2:0"></span>人
		</div>
		<div class="bottom" v-if="tabIndex==4">
			签到总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人，已发证：<span class="fontWeight" v-text="list1.count1?list1.count1:0"></span>人，未发证：<span
			 class="fontWeight" v-text="list1.count2?list1.count2:0"></span>人
		</div>
		<!-- <div class="fu flex">
			<img src="../../assets/Train/icon_tips@2x.png" alt="">
			<div>
				付费协议
			</div>
		</div>
		<div class="bottom flex" style="padding:0 30px ;">
			<div style="width: 50%;position: relative;">
			<div class="btn cancel" @click="$router.go(-1)">
				取消
			</div>
			</div>
			<div style="width: 50%;position: relative;">
			<div class="btn" @click="Success">
				立即付费
			</div>
			</div>
		</div> -->
		<van-popup v-model="show" closeable position="bottom" style="height: 95%" @close="onClose" class="search1" ref="vanPopup1">
			<div class="popupBox" style="padding-top: 0;width: 100%;text-align: left;padding-bottom:0 ;">
				<div ref="totorTop">
					<div class="title" style="text-align:center;font-weight: bold;font-size: 18px;">申请督导</div>
					<van-tabs active="active" @click="tabclick" >
						<van-tab title="公开申请">
							<div style="font-size: 12px;margin-top: 12px;">
								选择公开申请的话，会将申请发给所有主任导师，主任导师根据
								自己的时间、位置、计划安排等选择是否接受督导申请
							</div>
							<div class="kong1" style="text-align: left;margin-top: 40px;font-size: 15px;">确定要申请主任导师督导吗？申请后如果有主任导师
								接受了督导邀请，您还需要对督导费进行付费</div>
								<div ref="totorBottom" class="bottom" style="background-color: #fff;height: 60px;">
									<div class="btn" style="color: #fff;width: 80%;" @click="needTutorClick1">
										确定
									</div>
								
								</div>
						</van-tab>
						<van-tab title="定向申请">
							<div ref="totorTop1">
								<div style="font-size: 12px;margin-top: 20px;">选择定向申请的话，请先跟主任导师沟通好是否接受申请，发出申
									请后会将申请发给你筛选出来的主任导师，主任导师根据自己的时
									间、位置、计划安排等选择是否接受督导邀请。申请后如果主任导
									师接受了督导邀请，您还需要对督导费进行付费</div>
								<div class="flex" style="justify-content: space-between;margin-top: 17px;margin-bottom: 15px;">
									<div style="font-size: 14px;">主任导师姓名</div>
									<input type="text" class="jdsinput" v-model="totorName" style="width: 50%;">
									<div class="jdsBaobtn" @click="searchTutor">搜索</div>
								</div>
							</div>
							<div  :style="'height:'+ totorListHeight +'px; overflow: auto;position: relative;'">
								<div class="kong" v-if="!totorList.length">未搜索到匹配的主任导师</div>
								<van-radio-group v-model=" radio"   style="height: 100%;">
									<van-radio :name="item.user_id" class="totorBox" v-for="(item,i) in totorList" :key="i">
										<div class="flex " style="flex-wrap: nowrap;">
											<div style="width: 50px;height: 50px;border-radius: 50%;">
												<van-image :src="item.user_head_img" class="auto-img" style="border-radius: 50%;" round>
													<template v-slot:error>
														<img src="../../assets/user/icon_mrtx_nan@2x.png" alt="" style="width: 3.75rem;border-radius: 50%;">
													</template>
												</van-image>
											</div>
											<div style="margin-left: 15px;max-width: 80%;">
												<div class="flex totorName" style="flex-wrap: nowrap;">
													<div style="margin-right: 15px;max-width:50% ;" v-text="item.user_name" ></div>
													<div v-text="item.user_phone"></div>
												</div>
												<div class="totorId">ID:<span v-text="item.daoshi_client_id"></span></div>
											</div>
										</div>
									</van-radio>
								</van-radio-group>
							</div>
							
							<div ref="totorBottom1" class="bottom" style="background-color: #fff;height: 60px;">
								<div class="btn" style="color: #fff;width: 80%;" v-if="radio&&isshowRadio"  @click="needTutorClick" >
									发出申请
								</div>
								<div class="btn" style="color: #fff;width: 80%;background-color: #C6C8CA;border-color:transparent ;" v-if="!radio&&isshowRadio">
									发出申请
								</div>
							</div>
						</van-tab>

					</van-tabs>


				</div>
				
				
			</div>
		</van-popup>

		<van-popup v-model="offlineShow" :round="true" class="zhuren offline">
			<div class="popupBox " style="height: auto;">
				<div class="title">确定下线吗？下线后该活动废除。</div>
				<div class="flex btnBox">
					<div @click="offlineShow=false">暂不取消</div>
					<div class="suss" @click="changeActivityStatus" v-if="isone">确定</div>
					<div class="suss" v-if="!isone">确定</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="tutorShow" :round="true" class="zhuren">
			<div class="popupBox">
				<div class="title">
					已发送助教导师替换邀请通知给<span v-text="searchList.user_name"></span>导师，等他确认接受后才算成功替换
				</div>
				<div class="flex btnBox">
					<!-- <div @click="tutorShow=false">确定</div> -->
					<div class="suss" @click="closeClickdaoshi" style="margin: 0;">确定</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="tutor" closeable position="bottom" style="height: 90%" @close="onClose" class="search1" ref="vanPopup">
			<div class="popupBox" style="padding-top: 0;width: 100%;text-align: left;padding-bottom:0 ;">
				<div ref="totorTop">
					<div class="title" style="text-align:center;font-weight: bold;font-size: 18px;">助教导师报名</div>
					<div style="font-size: 12px;">
						提示：若您有提前安排沟通好的导师作为助教导师，可将导师拉入
						助教导师报名队列的前排
					</div>
					<div class="flex" style="justify-content: space-between;margin-top: 35px;margin-bottom: 15px;">
						<div>导师姓名</div>
						<input type="text" class="jdsinput" v-model="totorName">
						<div class="jdsBaobtn" @click="searchClick">搜索</div>
					</div>
				</div>
				<div  :style="'height:'+ totorListHeight +'px; overflow: auto;position: relative;'">
					<div class="kong" v-if="!totorList.length">未搜索到匹配的导师</div>
					<van-radio-group v-model=" radio"   style="height: 100%;">
						<van-radio :name="item.user_id" class="totorBox" v-for="(item,i) in totorList" :key="i">
							<div class="flex " style="flex-wrap: nowrap;">
								<div style="width: 50px;height: 50px;border-radius: 50%;">
									<van-image :src="item.user_head_img" class="auto-img" style="border-radius: 50%;" round>
										<template v-slot:error>
											<img src="../../assets/user/icon_mrtx_nan@2x.png" alt="" style="width: 3.75rem;border-radius: 50%;">
										</template>
									</van-image>
								</div>
								<div style="margin-left: 15px;max-width: 80%;">
									<div class="flex totorName" style="flex-wrap: nowrap;">
										<div style="margin-right: 15px;max-width:50% ;" v-text="item.user_name" ></div>
										<div v-text="item.user_phone"></div>
									</div>
									<div class="totorId">ID:<span v-text="item.daoshi_client_id"></span></div>
								</div>
							</div>
						</van-radio>
					</van-radio-group>
				</div>
				<div ref="totorBottom" class="bottom" style="background-color: #fff;height: 60px;">
					<div class="btn" style="color: #fff;width: 80%;" v-if="radio&&isshowRadio" @click="postSignupSearchTutor">
						确定报名
					</div>
					<div class="btn" style="color: #fff;width: 80%;background-color: #C6C8CA;border-color:transparent ;" v-if="!radio&&isshowRadio">
						确定报名
					</div>

				</div>
			</div>
		</van-popup>


		<van-popup v-model="isdaoshi" :round="true" class="search1">
			<div class="popupBox" style="height: auto;">
				<div class="nosearch" v-if="isseach"><span v-text="tutorMsg"></span></div>
				<div class="title">
					当前需要的助教导师人数达到了最大的助教导师报名人数，不能直接取消助教导师申请，请先找好替换的助教导师导师</div>
				<div>替换的助教导师导师</div>
				<div class="input">
					<van-field v-model="searchList.user_name" label="姓名" />
					<van-field type='number' ref="focus" class="set " :class="searchList.user_phone?'':'phoneClass'" v-model="searchList.user_phone"
					 label="手机号码" @click="focus" />
				</div>
				<div class="flex btnBox">
					<div @click="quxiao">暂不取消</div>
					<div class="suss" @click="replaceClick" v-if="isone">发送替换邀请通知</div>
					<div class="suss" v-if="!isone">发送替换邀请通知</div>
				</div>
			</div>
			<!-- @focus="focusInput" @blur="blurInput" -->
		</van-popup>
		<van-popup v-model="show1" :round="true" class="daoshi">
			<div class="popupBox">
				<div class="title">确定要取消助教导师资格申请？</div>
				<div class="flex btnBox">
					<div @click="show1=false">暂不取消</div>
					<div class="suss" @click="ownClick" v-if="isone">确定</div>
					<div class="suss" v-if="!isone">确定</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import VueQr from 'vue-qr'
	import {
		dateFormat
	} from '@/util/setTime.js'
	import {
		starPhone
	} from '@/util/phoneNum.js'
	import {
		searchTutor,
		getActivitySigninLists,
		getActivitySigninTutorLists,
		getActivity_is,
		getActivityCommentLists,
		getActivitySignupLists,
		getActivitySignupTutorLists,
		changeActivityStatus,
		getOfficialCourse,
		activityAuthCheck,
		getCancelSignupTutor,
		getChangeOfficialSignupTutor,
		getSearchAndSendMessage,
		postOfficialActivityTutorApply,
		getActivitySigninCertsLists,
		postOfficialActivityTutorApplySuccess,
		getOfficialActivityTutorApplyDetail,
		userRechargeUniOrder,
		getSign,
		searchTeacher,
		postSignupSearchTutor,
	} from '@/api/index.js'
	import {
		Toast
	} from 'vant';
	export default {
		name: 'traindal',
		components: {VueQr},
		data() {
			return {
				isshowRadio:true,
				active: '1',
				// 搜索助教名字
				totorName: '',
				// 用户id
				user_id: 0,
				// 替换助教导师
				isdaoshi: false,
				// 是否没有这么导师
				isseach: false,
				searchList: {
					act_id: this.$route.query.act_id,
					user_name: '',
					user_phone: ''
				},
				// 报名弹窗
				tutor: false,
				// 搜索返回值
				tutorMsg: '',
				// 导师名字
				tutorName: '',
				// 替换导师发送成功
				tutorShow: false,
				// 取消导师弹窗
				show1: false,
				// 标签页标识符
				tabIndex: 0,
				// isOver
				act_id: 0,
				// 申请监督
				show: false,

				title: '培训详情及管理',
				conData: {
					start: '2019-12-10 16:00',
					end: ' 2019-12-10 13:00',
					kaoStart: ' 2019-12-10 13:00',
					supervisor: 1,
					PXcourse: '心肺复苏术、AED使用、创伤包扎',
					KHcourse: '心肺复苏术、AED使用、创伤包扎'

				},
				list_title: ['学员报名', '助教报名', '学员签到', '导师签到', '证书发放'],
				tab_list: [{
					name: '暂无',
					phone: 0,
					type: '暂无',
					state: 0
				}],
				list1: {
					img: 'https://img.yzcdn.cn/vant/cat.jpeg',
					place: '广州市萝岗区科学城科珠路201号广东软件园G栋401室',
					keName: '成人心肺复苏及自动体外除颤器（AED）使用方法 ',
					time: '2017-12-07  13:30',
					site: '天鹰救援培训中心-广州分中心',
					cost: 132
				},
				list1: [],
				list2: [],
				list3: [],
				list4: [],
				list5: [],
				// 培训课程
				courseList: [],
				// 考核课程
				courseList_kao: [],
				isres: 2,
				offlineShow: false,
				// 做点击事件一次处理
				isone: true,
				SigninCertsLists: [],
				radio: '',
				totorList: [],
				totorListHeight: 0,
				// 二维码
				config:{
					  logo:'',
					  value:'https://xcjj.tysos.cn/courseDetails?act_id='+this.$route.query.act_id
				},
				result: [],
				isShowStudentGroupButtom: false,
				resultGroupStr: '',
				activityAuthCheck: {},
			}
		},
		watch: {
			tutor(news, low) {
				// if(news){
				// 	this.totorListHeight=this.$refs.vanPopup.$el.offsetHeight - this.$refs.totorBottom.offsetHeight - this.$refs.totorTop.offsetHeight - 15
				// }
			}
		},
		methods: {
			tabclick(e){
				this.radio=''
				console.log(e)
				let _this=this
				if(e==1){
					this.$nextTick(()=>{
						_this.totorListHeight = _this.$refs.vanPopup1.$el.offsetHeight - _this.$refs.totorTop1.offsetHeight - _this.$refs.totorBottom1.offsetHeight -120
						console.log(_this.totorListHeight)
					})
				}
			},
			// 助教弹窗
			totorClick(){
				this.tutor=true
				this.totorList=[]
				let _this=this
				this.$nextTick(()=>{
					_this.totorListHeight = _this.$refs.vanPopup.$el.offsetHeight - _this.$refs.totorBottom.offsetHeight - _this.$refs.totorTop.offsetHeight - 15
					console.log(_this.totorListHeight)
				})
			},
			// 报名助教
			postSignupSearchTutor() {
				console.log(this.radio)
				if(this.radio){
					this.$Toast.loading({
						mask: true,
						duration: 0,
						message: '报名中...',
						forbidClick: true,
					});
					postSignupSearchTutor({
						act_id: this.$route.query.act_id,
						user_id: this.radio
					}).then(res => {
						this.$Toast(res.msg)
					}).catch(() => {
						this.$Toast('报名失败')
					})
				}else{
					this.$Toast('请选择报名的助教')
				}
				
			},
			// 搜索主任导师
			searchTutor(){
				let _this=this
				this.isshowRadio=false
				this.radio=''
				this.$Toast.loading({
					mask: true,
					duration: 0,
					message: '查找中...',
					forbidClick: true,
				});
					this.totorListHeight = this.$refs.vanPopup1.$el.offsetHeight - this.$refs.totorTop1.offsetHeight - this.$refs.totorBottom1.offsetHeight -120
				this.$nextTick(()=>{
					_this.isshowRadio=true
						// _this.totorListHeight = _this.$refs.vanPopup1.$el.offsetHeight - _this.$refs.totorTop1.offsetHeight - _this.$refs.totorBottom1.offsetHeight -120
						console.log(_this.totorListHeight)
						searchTutor({
							tname: _this.totorName
						}).then(res => {
							_this.totorList = res.data
							_this.totorList.forEach((item, i) => {
								item.user_phone = starPhone(item.user_phone)
							})
							_this.radio = ''
							_this.totorListHeight = _this.$refs.vanPopup1.$el.offsetHeight - _this.$refs.totorTop1.offsetHeight - _this.$refs.totorBottom1.offsetHeight -120
							_this.$Toast.clear()
						}).catch(res => {
					this.$Toast('查找失败')
				})
					})
			},
			// 助教搜索
			searchClick() {
				this.radio=''
				this.isshowRadio=false
				this.$Toast.loading({
					mask: true,
					duration: 0,
					message: '查找中...',
					forbidClick: true,
				});
				// this.totorListHeight = this.$refs.vanPopup.$el.offsetHeight - this.$refs.totorBottom.offsetHeight - this.$refs.totorTop.offsetHeight - 15
				searchTeacher({
					tname: this.totorName
				}).then(res => {
					this.isshowRadio=true
					// this.totorListHeight = this.$refs.vanPopup.$el.offsetHeight - this.$refs.totorBottom.offsetHeight - this.$refs.totorTop.offsetHeight - 15
					this.totorList = res.data
					this.totorList.forEach((item, i) => {
						item.user_phone = starPhone(item.user_phone)
					})
					this.radio = ''
					this.$Toast.clear()
				}).catch(res => {
					this.$Toast('查找失败')
				})
			},
			btnClickOK(item) {
				let date = new Date()
				let time = dateFormat('Y-m-d', date)
				this.$router.push({
					name: 'supervisorTheAssessmentOK',
					query: {
						sign_name: this.conData.act_author,
						time: time,
						course: '',
						courseId: this.conData.train_course,
						sign_id: this.user_id,
						act_id: this.$route.query.act_id,
						train_course_id: 4,
						courseId: 5,
						course_name: '',
						act_type: 2
					}
				})
			},
			focus() {
				this.$refs.focus.focus()
			},
			// 导航地址
			sweatClick() {
				let _this = this
				let href = location.href.split('?')[0]
				getSign({
						page_url: href
					})
					.then(res => {
						if (res.data.appId) {
							_this.url_link = res.data.url_link
							_this.$wx.config({
								debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
								appId: res.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
								timestamp: res.data.timestamp, // 必填，生成签名的时间戳
								nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
								signature: res.data.signature, // 必填，签名，见附录1
								jsApiList: ['openLocation'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
							});
							_this.$wx.ready(resx => { // 微信SDK准备就绪后执行的回调。
								_this.$wx.openLocation({
									latitude: _this.conData.latitude, // 纬度，浮点数，范围为90 ~ -90
									longitude: _this.conData.longitude, // 经度，浮点数，范围为180 ~ -180。
									name: _this.conData.act_addr, // 位置名
									address: _this.conData.act_addr, // 地址详情说明
									scale: 1, // 地图缩放级别,整形值,范围从1~28。默认为最大
									infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
								});
							});

						} else {}
					});

			},
			closeClickdaoshi() {
				this.tutorShow = false
				this.isdaoshi = false
			},
			jsApiCall(jsApiParameters) {
				// console.log(jsApiParameters);return;
				// alert(2);
				let jsondata = JSON.parse(jsApiParameters);
				// console.log('thisssss'+jsApiParameters);
				WeixinJSBridge.invoke(
					'getBrandWCPayRequest',
					jsondata,
					function(res) {
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							Toast("微信支付成功!");
							this.getOfficialCourse()
						} else if (res.err_msg == "get_brand_wcpay_request:cancel") {
							Toast("用户取消支付!");

						} else {
							Toast("支付失败!");
						}
						// this.getOfficialActivityTutorApplyDetail()
					}
				);
			},
			successClick() {
				getOfficialActivityTutorApplyDetail({
					act_id: this.$route.query.act_id
				}).then(res => {
					if (res.code == 1) {
						// res.data.order_id
						res.data.cost = 0.01
						userRechargeUniOrder({
							total_fee: res.data.cost,
							select_type: 3,
							order_id: res.data.order_id
						}).then(res => {
							// console.log(res)
							this.jsApiCall(res.data)
						})
					}
				})
				//  postOfficialActivityTutorApplySuccess({ act_id: this.$route.query.act_id}).then(res => {
				//    Toast(res.msg);
				//    if (res.code == 1) {
				// this.conData.tutor_status = 4
				//    }
				//  })

			},
			// 培训详情的证书发放列表
			getActivitySigninCertsLists() {
				getActivitySigninCertsLists({
					act_id: this.$route.query.act_id,
					act_type: 1
				}).then(res => {
					if (res.code == 1) {
						this.list1 = res.data
						this.tab_list = res.data.list
						console.log(this.tab_list)
					} else {
						this.tab_list = []
						this.list1 = []
					}
				})
			},
			// 督导申请
			needTutorClick() {
				this.isone = false
				if(this.radio){
					postOfficialActivityTutorApply({
						act_id: this.$route.query.act_id,
						user_id :this.radio
					}).then(res => {
						Toast(res.msg);
						this.isone = true
						if (res.code == 1) {
							this.conData.tutor_status = 2
							this.conDatatutor_res_status=this.radio?1:0
							this.show = false
						}
					})
				}else{
					this.$Toast('请选择发出申请人')
				}
				
			},
			// 督导申请
			needTutorClick1() {
				this.radio=''
				this.isone = false
				postOfficialActivityTutorApply({
					act_id: this.$route.query.act_id,
					user_id :this.radio
				}).then(res => {
					Toast(res.msg);
					this.isone = true
					if (res.code == 1) {
						this.conData.tutor_status = 2
						this.conDatatutor_res_status=this.radio?1:0
						this.show = false
					}
				})
				
				
			},
			// 暂不取消
			quxiao() {
				this.show1 = false
				this.isseach = false
				this.isdaoshi = false
			},
			// 发送替换申请
			replaceClick() {
				this.isone = false
				for (let item in this.searchList) {
					if (!this.searchList[item]) {
						switch (item) {
							case 'act_id':
								Toast('缺少活动id');
								return
								break;
							case 'user_name':
								Toast('缺少导师名字');
								return
								break;
							case 'user_phone':
								Toast('缺少导师手机号');
								return
								break;
						}
					}
				}
				// 搜索导师并发送信息
				getSearchAndSendMessage(this.searchList).then(res => {
					this.isone = true
					if (res.code == 0) {
						this.isseach = true
						this.tutorMsg = res.msg

					} else if (res.code == 1) {
						// getChangeOfficialSignupTutor({act_id:this.$route.query.act_id,org_user_id:res.data.user_id}).then(res=>{
						// 	console.log(res)
						// 	// if(re)
						// })
						this.conData.is_cancel = 2
						this.tutorShow = true
						this.show1 = false
						this.tutorName = res.data.user_name
					}

				})
			},
			user_phoneClick() {
				console.log('点击')
				this.isphone = true
			},
			ownClick() {
				this.isone = false
				getCancelSignupTutor({
					act_id: this.$route.query.act_id
				}).then(res => {
					// Toast(res.msg);
					this.isone = true
					if (res.code == 1) {
						this.isres = res.data.res
						this.isdaoshi = res.data.res == 2
						this.show1 = false
						if (res.data.res == 1) {
							this.conData.is_cancel == 0
							console.log(this.conData)
							this.$Toast('取消成功')
						}
					} else {

					}
				})
			},
			changeActivityStatus() {
				this.isone = false
				let act_statusIng;
				this.offlineShow = false
				act_statusIng = this.conData.act_status == 1 ? 3 : this.conData.act_status == 3 ? 1 : 0;
				this.$Toast.loading({
					message: '下线中...',
					forbidClick: true,
					duration: 'toast',
					overlay: true
				});
				changeActivityStatus({
					act_id: this.$route.query.act_id,
					act_status: act_statusIng
				}).then(res => {
					this.$Toast.clear();
					this.isone = true
					if (res.code == 1) {
						this.conData.act_status = res.data
						Toast(res.msg);
					}
				})
			},
			onClick(name, title) {
				console.log(name)
				this.tab_list = []
				this.SigninCertsLists = []
				switch (name) {
					case 0:
						this.getActivitySignupLists()
						break;
					case 1:
						this.getActivitySignupTutorLists()
						break;
					case 2:
						this.getActivitySigninLists()
						break;
					case 3:
						this.getActivitySigninTutorLists()
						break;
					case 4:
						this.getActivitySigninCertsLists()
						break;
				}
			},
			// 培训详情的学员报名数据
			getActivitySignupLists() {
				getActivitySignupLists({
					act_id: this.$route.query.act_id,
					act_type: 1
				}).then(res => {
					console.log(res)
					if (res.code == 1) {
						this.list1 = res.data
						this.tab_list = this.list1.list
					} else {
						this.tab_list = []
						this.list1 = []
					}

				})
			},
			// 培训详情的学员签到列表
			getActivitySigninLists() {
				getActivitySigninLists({
					act_id: this.$route.query.act_id,
					act_type: 1
				}).then(res => {
					if (res.code == 1) {

						this.list1 = res.data
						this.tab_list = this.list1.list
					} else {
						this.tab_list = []
						this.list1 = []
					}
				})
			},
			// 培训详情的助教导师签到列表
			getActivitySigninTutorLists() {
				getActivitySigninTutorLists({
					act_id: this.$route.query.act_id,
					act_type: 1
				}).then(res => {
					if (res.code == 1) {
						this.list1 = res.data
						this.tab_list = this.list1.list
					} else {
						this.tab_list = []
						this.list1 = []
					}
				})
			},
			// 培训详情的助教导师报名列表
			getActivitySignupTutorLists() {
				console.log(this.$route.query.act_id)
				getActivitySignupTutorLists({
					act_id: this.$route.query.act_id,
					act_type: 1
				}).then(res => {
					if (res.code == 1) {
						this.list1 = res.data
						this.tab_list = this.list1.list
					} else {
						this.tab_list = []
						this.list1 = []
					}

				})
			},
			// 发证
			stateClick(i, item) {
				console.log(this.conData)
				console.log(item.is_comment)
				if(this.conData.scene_image == null) {
					this.$router.push({
						path: '/upTrainImage',
						query: {
							act_id: this.act_id
						}
					})
					return;
				}
				if(item.is_comment == 0) {
					Toast('该学员未反馈，暂时不能发证')
					return;
				}
				if (item.is_complete == 0) {
					alert('未考核')

					return
				} else {
					console.log(this.tab_list.is_certs)
					if (this.tab_list.is_certs) {
						this.$router.push({
							name: 'emUserCertificate',
							query: {
								user_id: this.tab_list.user_id,
								act_id: this.$route.query.act_id,
								act_type: this.tab_list.act_type,
								user_name: this.tab_list.sign_name,
								train_course: this.conData.train_course
							}
						})
					} else {
						this.$router.push({
							name: 'confirmation_card',
							query: {
								user_id: this.tab_list[i].user_id,
								act_id: this.$route.query.act_id,
								act_type: this.tab_list[i].act_type,
								user_name: this.tab_list[i].sign_name,
								train_course: this.conData.train_course,
								act_addr: this.conData.act_addr
							}
						})
					}
				}
			},
			editClick() {
				console.log(this.conData)
				this.$router.push({
					name: 'setTrain',
					query: {
						act_id: this.act_id,
						act_type: 1,

					}
				})
			},
			Success() {},
			showPopup() {
				this.show = true;
				this.totorList=[]
				this.radio=''
			},
			// 去除空白数组下标
			skipEmptyElementForArray(arr) {
				var a = [];
				for (let i = 0; i < arr.length; i++) {
					if (arr[i]) {
						a.push(arr[i])
					}
				}
				return a;
			},
			// 多选报名学员
			checkClick() {
				// console.log(this.result);
				let resultGroup = [];
				for (let i = 0; i < this.result.length; i++) {
					resultGroup.push(this.tab_list[this.result[i]].user_id);
				}
				console.log(resultGroup);
				this.isShowStudentGroupButtom = resultGroup.length > 0 ? true : false;
				this.resultGroupStr = resultGroup.join(',');
			},
			// courseList
			// courseList_kao
			// 先获得课程。避免课程慢得到
			getOfficialCourse() {
				activityAuthCheck({
					act_id: this.act_id
				}).then(rest => {
					this.activityAuthCheck = rest.data;
					if(rest.data.auth_check == 0) {
						Toast('暂无权限访问')
							setTimeout(() => {
								this.$router.push({name:'404'})
							}, 1000)
							return;
					} else {
						this.getActivitySignupLists()

						getOfficialCourse({
							act_type: 1
						}).then(resd => {
							const list = resd.data
							console.log(list)
							getActivity_is({
								act_id: this.act_id
							}).then((res) => {

								console.log(res)
								if(res.code == 0) {
									Toast(res.msg)
									setTimeout(() => {
										this.$router.push({name:'404'})
									}, 1000)
								}

								this.conData = res.data

								// this.conData.is_cancel=2
								// 培训课程
								// let list1=this.skipEmptyElementForArray(this.conData.train_course.split('|'))
								let list1 = this.conData.train_course
								console.log(list1)
								// 考核课程
								let list2 = this.conData.course
								for (let i = 0; i < list.length; i++) {
									// 先判断是否有选中的培训课程
									for (let j = 0; j < list1.length; j++) {
										if (list[i].id == list1[j]) {
											this.courseList.push(list[i].name)
										}
									}
									for (let j = 0; j < list[i].course.length; j++) {
										for (let q = 0; q < list2.length; q++) {

											if (list[i].course[j].id == list2[q]) {
												this.courseList_kao.push(list[i].course[j].name)
											}
										}
									}
								}
								/**
								 * 判断是否为助教导师。是的话不显示发证入口
								 * */
								let a = ['学员报名', '助教报名', '学员签到', '导师签到', '证书发放']
								let b = ['学员报名', '助教报名', '学员签到', '导师签到']
								if (this.$route.query.isown == 0 && this.conData.is_cancel == 1) {
									this.list_title = a  //临时方案-->助教可以自己发证书 改自2022-6-10
								} else {
									this.list_title = a
								}
								// console.log(this.courseList, this.courseList_kao)
							})
						})

					}
				})
			},

			kao(item) {
				if (this.conData.act_status == 1) {
					this.$router.push({
						name: 'inAassessment',
						query: {
							train_course: this.conData.train_course,
							sign_name: item.sign_name,
							course: this.conData.course,
							sign_id: item.user_id,
							act_id: this.$route.query.act_id,
							act_type: 1
						}
					})
				} else {
					alert('活动已结束')
				}

			},
			kao_act(item) {

				this.$router.push({
					name: 'inAassessment',
					query: {
						train_course: this.conData.train_course,
						sign_name: item.sign_name,
						course: this.conData.course,
						sign_id: item.user_id,
						act_id: this.$route.query.act_id
					}
				})
			},
			onClose() {}

		},
		mounted() {
			document.title = this.title;
			this.act_id = this.$route.query.act_id
			this.user_id = JSON.parse(localStorage.getItem('user_data')).user_id
			this.getOfficialCourse()

		}
	}
</script>

<style lang="scss">
	// .van-divider::after,
	// .van-divider::before {
	// 	border-width: 2px 0 0;
	// }




	.traindal {
		padding-bottom: 30px;

		.van-popup--center.van-popup--round {
			border-radius: 6px;
			text-align: left;
		}

		.van-tab--active {
			transform: scale(1.1);
		}

		.fontWeight {
			font-weight: bold;
		}


		.zhuren {
			.popupBox {
				width: 300px;
				height: 11.875rem;
				background-color: #fff;
				border-radius: 8px;
				padding: 0px 20px 28px;

				.title {
					padding: 42px 0;
					line-height: 23px;
				}

				.btnBox {
					justify-content: flex-end;
					color: #919CB0;

					div:first-child {
						margin-right: 40px;
					}

					.suss {
						color: #0081FF;
					}
				}
			}
		}

		.search1 {
			.van-cell__value {
				background-color: #F4F4F4 !important;
				border-radius: 6px;
			}

			.popupBox {
				width: 300px;
				height: auto;
				// height: 11.875rem;
				background-color: #fff;
				border-radius: 8px;
				padding: 10px 15px 28px 20px;
				text-align: center;
				position: relative;

				.nosearch {
					color: #FF524D;
					border-bottom: 1px solid #E5E5E5;
					padding: 4px 0 14px;
				}

				.title {
					padding: 16px 0 16px;
					line-height: 23px;
					text-align: left;
				}

				.van-field__control {
					padding: 6px 10px;
					z-index: 1;
				}

				.van-field__body {
					background-color: #F4F4F4;
					border-radius: 6px;
				}

				.van-field__label {
					width: 75px;
				}

				.van-cell {
					padding-left: 0;
					padding-right: 0;
				}

				.input {
					padding: 6px 0 28px;

				}

				.van-field__control {
					background: rgba(244, 244, 244, 1);
					border-radius: 12px;
				}

				.btnBox {
					width: 100%;
					position: relative;
					justify-content: space-around;
					color: #919CB0;

					div:first-child {
						// margin-right: 40px;
					}

					.suss {
						color: #0081FF;
					}
				}
			}
		}

		.daoshi {
			.popupBox {
				width: 300px;
				height: 11.875rem;
				background-color: #fff;
				border-radius: 8px;
				padding: 0 20px 28px;
				text-align: center;
				position: relative;

				.title {
					padding: 42px 0;
					line-height: 23px;
				}

				.btnBox {
					position: absolute;
					bottom: 28px;
					right: 34px;
					justify-content: flex-end;
					color: #919CB0;

					div:first-child {
						margin-right: 40px;
					}

					.suss {
						color: #0081FF;
					}
				}
			}
		}

		.bottom {
			position: fixed;
			bottom: 0;
			left: 0;
			background: #F4F4F4;
			width: 100%;
			height: 30px;
			font-size: 12px;
			color: #28334A;
			line-height: 30px;
			text-align: left;
			padding-left: 15px;
		}
		.bot-flex {
			display: flex;
			justify-content: space-between;
			align-items:center;
		}

		.signup-button {
			height: 22px;
			width: 77px;
			background-color:#0081FF;
			color: #fff;
			border-radius: 15px;
			line-height: 22px;
			text-align: center;
			margin-right: 17px;
		}

		.van-tabs__line {
			background-color: #0081FF;
			width: 1.125rem !important;
		}

		.van-tabs__content {
			background-color: #fff;
		}

		.van-tab__text {
			font-weight: bold;
			font-size: 15px;
		}

		.List_row {
			padding: 16px 0;
			margin: 0 15px;
			border-bottom: 1px solid #E5E5E5;
			color: #767E8D;
		}
		.list_row_row {
			display:flex;
			justify-content: space-between;
			align-items: center;
		}

		.imgBox {
			// position: absolute;
			// top: 0;
			// left: 0;
			// height: 200px;
			// width: 100%;
			z-index: -1;
			position: absolute;
			top: 0;
		}

		.list {
			flex-wrap: inherit;
			padding: 15px;
			padding-top: 0;

			.list_img {
				margin-right: 0.71875rem;
				position: relative;
			}

			.list_img:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 6px;
				right: 0;
				width: 1.375rem;
				height: 1.25rem;
				background: no-repeat center;
				background-size: 100% 100%;
			}

			.list_img.list_img_1:after {

				background-image: url(../../assets/Train/icon_1@2x.png);

			}

			.list_img.list_img_2:after {

				background-image: url(../../assets/Train/icon_2@2x.png);

			}

			.list_img.list_img_3:after {

				background-image: url(../../assets/Train/icon_3@2x.png);

			}


			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}

			.text {
				font-size: 0.75rem;
				color: #919CB0;
				// margin-left: 0.5rem;
			}

			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}

			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}

			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}

			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}

			.btnimg_span {
				margin-right: -5px;

			}
		}

		.container1 {
			// margin: 15px;
			background-color: #fff;
			// height: 280px;
			z-index: 2;
			border-radius: 0.375rem;
			text-align: left;
			position: relative;
			width: 100%;

			// position: absolute;
			// top: 15px;
			// left: 50%;
			// transform: translateX(-50%);
			// margin-top: -50px;
			padding-bottom: 10px;

			// margin-bottom: 15px;
			.header {
				text-align: left;
				font-size: 1.125rem;
				font-weight: bold;
				padding-top: 20px;
			}

			.header_title {
				font-weight: bold;
				font-size: 17px;
				padding: 15px 11px;

			}

			.conData {
				// padding: 28px 0px;
				font-size: 14px;

			}

			.cost {
				color: #0081FF;
			}

			.title {
				color: #919CB0;
			}

			.van-row {
				margin: 14px 0;
				padding: 0 15px;
			}

			.dashed {
				position: relative;

				// &::after {
				// 	position: absolute;
				// 	top: -8px;
				// 	left: 0px;
				// 	width: 8px;
				// 	height: 16px;
				// 	content: '';
				// 	display: block;
				// 	background: url(../../assets/Train/bg_yuan_left@2x.png) no-repeat center;
				// 	background-size: 100% 100%;
				// }

				// &::before {
				// 	position: absolute;
				// 	top: -8px;
				// 	right: 0px;
				// 	width: 8px;
				// 	height: 16px;
				// 	content: '';
				// 	display: block;
				// 	background: url(../../assets/Train/bg_yuan_right@2x.png) no-repeat center;
				// 	background-size: 100% 100%;
				// }
			}
		}

		.fu {
			padding: 15px;
			color: #919CB0;

			img {
				width: 0.75rem;
				height: 0.75rem;
				margin-right: 0.5625rem;
			}
		}

		.cancel {
			background-color: #FFFFFF;
			border: 1px solid #0081FF;
			color: #0081FF;
			font-size: 15px;
		}

		.zhi {
			margin-left: 17px;
			color: #919CB0;
			font-size: 15px;
		}

		.btn {
			border: 1px solid #0081FF;
			width: 8.75rem;
			height: 40px;
			color: #0081FF;
			font-size: 15px;
			text-align: center;
			line-height: 38px;
			border-radius: 50px;
		}

		.applyBox {
			.applyBtn {
				width: 70px;
				height: 26px;
				line-height: 24px;
				font-size: 12px;
				margin-left: 26px;
			}
		}

		input.van-field__control::-webkit-input-placeholder {

			color: #919CB0;
		}

		input.van-field__control::-moz-placeholder {

			color: #919CB0;
		}

		input.van-field__control:-moz-placeholder {

			color: #919CB0;
		}

		input.van-field__control:-ms-input-placeholder {

			color: #919CB0;

		}

		.set {
			align-items: center;
		}

		.set .van-field__body {
			position: relative;
		}

		.phoneClass .van-field__body::after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			text-align: left;
			padding: 2px 10px;
			display: block;
			content: "请输入该导师在当前公众号绑定的手机号码";
			font-size: 10px;
			line-height: 15px;
			box-sizing: border-box;
			z-index: 1;
			color: #919CB0;
		}

		.kaobtn {
			width: 70px;
			height: 26px;
			background: rgba(255, 255, 255, 1);
			border: 1px solid rgba(0, 129, 255, 1);
			border-radius: 13px;
			margin: 0;
			text-align: center;
			float: right;
			line-height: 24px;
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(0, 129, 255, 1);
		}

		.kaobtn_Act {
			background: rgba(0, 217, 126, .11);
			border: 1px solid transparent;
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(0, 217, 126, 1);
		}

		// 助教导师报名按钮
		.jdsbtn {
			padding: 0 20px;
			height: 42px;
			background-color: #0081FF;
			line-height: 42px;
			border-radius: 50px;
			color: #fff;
			margin-right: 15px;

		}

		.jdsinput {
			width: 60%;
			height: 40px;
			background: #FFFFFF;
			border: 1px solid #0081FF;
			border-radius: 6px;
			padding: 0 6px;
		}

		.jdsBaobtn {
			max-width: 15%;
			width: 70px;
			height: 40px;
			background: #0081FF;
			border-radius: 6px;
			color: #FFFFFF;
			text-align: center;
			line-height: 40px;
		}

		.totorId {
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #28334A;
		}

		.totorName {

			font-size: 15px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #28334A;
		}

		.totorBox {
			width: 100%;
			padding: 15px 7px;
			border-bottom: 1px solid #E5E5E5;
		}

		.kong {
			// margin-top: 60px;
			width: 100%;
			text-align: center;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 800;
			color: #28344A;
			position: absolute;
			    top: 50%;
			    left: 50%;
			    transform: translate(-50%,-50%);
		}
		.kong1 {
			// margin-top: 60px;
			text-align: center;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 800;
			color: #28344A;
			// position: absolute;
			//     top: 50%;
			//     left: 50%;
			//     transform: translate(-50%,-50%);
		}
		.img{
			width: 50%;
			margin: 0 auto;
		}
		.totorBottom{
			position: fixed;
			bottom: 0;
			left: 0;
			background: #F4F4F4;
			width: 100%;
			/* height: 30px; */
			font-size: 12px;
			color: #28334A;
			/* line-height: 30px; */
			text-align: left;
			padding-left: 15px;
		}
		.van-checkbox__label {
			width: 100%;
		}
	}
</style>
