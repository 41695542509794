<template>
	<div class="purSuccess">
		<div class="imgBox">
			<img src="../../assets/pic_topbg_gmcg@2x.png" alt="" class="auto-img">
		</div>
		<div class="Contentbox">
			<p>购买成功，书籍预计在三个工作日内能快递到 您的手上。如想查询物流信息，请关注【现场 急救行动指南】微信公众号，发货后将会通过 【现场急救行动指南】微信公众号发送快递信 息给您。</p>
			<p class="dot">长按关注【现场急救行动指南】微信公众号</p>
			<img src="../../assets/icon_downloadcode@2x.png" alt="" width="120">
		</div>
	</div>
</template>

<script>

import {
  Toast
} from 'vant';
export default {
  name: 'purSuccess',
  components: {},
  data() {
    return {

      title: '购买成功',
    
    }
  },
  methods: {
   
  },
  mounted() {
    document.title = this.title;
  


  }
}
</script>

<style lang="scss">
	.purSuccess{
		.imgBox{
			width: 100%;
		}
		.Contentbox{
			width: 92%;
			margin: 0 auto;
			background:rgba(255,255,255,1);
			border-radius:12px;
			position: fixed;
			top: 90px;
			left: 50%;
			transform: translateX(-50%);
			line-height: 26px;
			text-align: justify;
			padding: 32px 20px 50px;
			p{
				&:first-child{
					margin-bottom: 35px;
				}
			}
			.dot{
				font-size:15px;
				font-family:Source Han Sans CN;
				font-weight:bold;
				color:rgba(40,51,74,1);
				line-height:48px;
				position: relative;
				&::after{
					width:6px;
					height:6px;
					background:rgba(255,188,0,1);
					border-radius:50%;
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: -10px;
				}
				
			}
			img{
				text-align: center;
				margin: 0 auto;
				display: inline-block;
				display: flex;
				justify-content: center;
				margin-top: 18px;
			}
		}
	}
</style>
