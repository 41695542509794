<template>
	<div class="confirmation_card">
		<div class="qualifiedBox">
			
			<div v-if="">
				<div class="qualifiedtext">
					学员证书信息确认
				</div>
				<div class="qualifiedtextflex">
					<div class="qualifiedtextflex_text">学员证件相片：</div>
					<div style="width: 69px;height: 93px;">
					<img :src="infoArr.headimgurl" alt="" class="auto-img">
					</div>
				</div>
				<div class="flex" style="margin-top: 20px;">
					<div style="flex:1;text-align: left;">
						<span>真实姓名：</span><span v-text="infoArr.user_name"></span>
					</div>
					<div style="flex:1;text-align: left;">
						<span>性别：</span><span v-text="infoArr.sex==1?'男':infoArr.sex==2?'女':'暂无'"></span>
					</div>
				</div>
				<div class="qualifiedtext">
					证书信息确认
				</div>
				<div class="qualifiedtextflex" style="display: flex;align-items: center;">
					<div>发证机构：</div>
					<div class="quali_kuang" @click="isfaList=true">
						<div v-text="faVlaue?faVlaue:'请输入发证机构'"></div>
							<img src="@/assets/Train/icon_xiala_pre@2x.png" alt="" style="height: 20px;width: 20px;" />
					</div>
				</div>
				<div class="qualifiedtextflex" style="display: block;">
					<div class="flex" style="justify-content: space-between;">
					<div class="qualifiedtextflex_text">培训中心：</div>
					<!-- <div class="xiuBtn" @click="$router.push({
							name: 'setTrain',
							query: {
								act_id: $route.query.act_id,
								act_type: $route.query.act_type,
						
							}
					})">修改</div> -->
					</div>
					<van-field v-model="ActivityArr.train_center_name"  type="textarea" placeholder="请输入培训中心名称" :show-word-limit="true" maxlength='20' />
				</div>
				
				<div class="qualifiedtextflex" style="display: block;">
					<div class="flex" style="justify-content: space-between;">
					<div class="qualifiedtextflex_text">培训地点：</div>
					<!-- <div class="xiuBtn" @click="$router.push({
							name: 'setTrain',
							query: {
								act_id: $route.query.act_id,
								act_type: $route.query.act_type,
						
							}
					})">修改</div> -->
					</div>
					<van-field v-model="ActivityArr.complete_addr"  autosize type="textarea" placeholder="请输入培训地点名称" :show-word-limit="true" maxlength='20' />
				</div>
				
				<div class="flex" style="margin-top: 20px;color: red;text-align: left;font-size: 12px;">
					提示：培训中心和培训地点名称不能超过20个字，请修改
				</div>
				
				<div class="qualifiedBtn">
					<div class="qualifiedBtn_btn qualifiedBtn_btn1" @click="isQualified=true">信息有误</div>
					<div class="qualifiedBtn_btn" :class="Incomplete?'':'qualifiedBtn_btn2'" @click="send">信息无误，发证</div>
				</div>
			</div>
			
		</div>
		<van-popup v-model="isQualified" position="bottom" :style="{ height: 'auto' }" closeable>
			<div class="qualifiedBox">
				<!-- 弹窗提示证书不齐全 -->
				
				<div class="title"> 生成合格证 </div>
				<div v-if="!isgenerate">
					<div class="" style="display: flex;margin-top: 25px;">
						<div>
							<img src="../../assets/icon_tips_red@2x.png" alt="" style="width: 12px;height: 12px;margin-right: 5px;">
							
						</div>
						<div style="font-size: 12px;text-align: left;width: calc(100% - 19px);">若学员证书信息有误或缺漏，可发送如下通知内容给学员，告 知其及时修改。通知内容可自定义息</div>
					</div>
					<div class="flex" style="margin-top: 15px;">
						<span style="margin-right: 10px;">有误项：</span>
						<!-- <van-radio-group v-model="radio" direction="horizontal" style="display: flex;width: calc(100% - 100px);justify-content:space-between">
								  <van-radio name="1" >相片</van-radio>
								  <van-radio name="2" >姓名</van-radio>
								  <van-radio name="3">性别</van-radio>
						</van-radio-group> -->
						<van-checkbox-group v-model="radio" style="display: flex;width: calc(100% - 100px);justify-content:space-between">
							<van-checkbox name="相片">相片</van-checkbox>
							<van-checkbox name="姓名">姓名</van-checkbox>
							<van-checkbox name="性别">性别</van-checkbox>
						</van-checkbox-group>
					</div>
					<van-field v-model="text" rows="3" :autosize="{ minHeight: 78 }" type="textarea" :placeholder="'您参加的现场急救行动指南线下培训课程已通过考核，但您的证书信息有误'+  (this.radio.length?`(${radio})`:'') +'，请点击详情修改。'" />
					
					<div class="qualifiedBtn">
						
						<div class="qualifiedBtn_btn" style="width: 90%;" @click="generate">通知学员修改证书信息</div>
					</div>
				</div>
				<div v-if="isgenerate">
					<div class="qualifiedtext">
						<span style="font-weight: bold;margin-top: 25px;font-size: 15px;">生成成功，您可以在【我的宝藏】<van-icon name="down" style="transform: rotate(-90deg) translateX(-2px);"/>【培训证书】页面查看完整的合格证</span>
					</div>
					<img src="../../assets/pic_zsmoban@2x.png" alt="" style="width: 100%;margin-top: 10px;">
					<div class="qualifiedBtn">
						<div class="qualifiedBtn_btn" style="width: 90%;" @click="$router.push({name:'certificate_train'})">查看证书</div>
					</div>
				</div>
			</div>
		</van-popup>

	<!-- 弹窗--发证机构 -->
		<van-popup v-model="isfaList" position="bottom">
			<div style="width: 100%;">
				<van-picker show-toolbar title="发证机构" :columns="faList" @cancel="onCancel" @confirm="onConfirm" />
			</div>
		</van-popup>
		
	</div>
</template>

<script>
	import { getOfficialActivityCertListByUser ,getOtherUserCertsInfo,getOnlineCourseCertSendMessage,postOnlineCourseCertOffLine,getActivity,getInstitutions} from '@/api'
	
	export default {
  name: 'confirmation_card',
  components: {
  },
  data(){
	  return{
		text:'',
		radio:[],
		isQualified:false,
		title:'发证',
		isgenerate:false,
		Incomplete:true,
		infoArr:{},
		ActivityArr:{},
		isfaList: false,
		faVlaue: '',
		faListid: [],
		faList: [],
		faIded: ''
	  }
  },
  methods:{
		onCancel() {
			this.isfaList = false
		},
		// 拿到发证机构 的下标
		onConfirm(value, index) {
			this.isfaList = false
			console.log( this.faListid[index])
			this.faIded = this.faListid[index]
			// this.SubmitData.institution_id = this.faListid[index]
			this.faVlaue = value
		},
	  // 去除空白数组下标
	  skipEmptyElementForArray(arr) {
	    var a = [];
	    for (let i = 0; i < arr.length; i++) {
	      if (arr[i]) {
	        a.push(arr[i])
	      }
	    }
	    return a;
	  },
	generate(){
		let text=this.text?this.text:'您参加的现场急救行动指南线下培训课程已通过考核，但您的证书信息有误'+ (this.radio.length?`(${this.radio})`:'') +'，请点击详情修改。'
		getOnlineCourseCertSendMessage({act_id:this.$route.query.act_id,uid:this.$route.query.user_id,note:text}).then(res=>{
			if(res.code==1){
				this.isQualified=false
				alert('发送成功')
			}
		})
	},
	send(){
		console.log(this.faIded)
		console.log(this.ActivityArr.train_center_name)
		console.log(this.ActivityArr.complete_addr)
		let _this = this
		let arr = 0
		let isover = false
		let list1= this.$route.query.train_course.indexOf('|')>-1? this.skipEmptyElementForArray(this.$route.query.train_course.split('|')):this.$route.query.train_course
		console.log( list1.length==2)
		this.$Toast.loading({
		  message: '证书生成中...',
		  forbidClick: true,
		  mask:true,
		  forbidClick:false,
		  duration:0
		});
		if(list1.length==2){
			postOnlineCourseCertOffLine({
				user_id:this.$route.query.user_id,
				course_id:2,
				headimgurl:this.infoArr.headimgurl,
				user_name:this.infoArr.user_name,
				sex:this.infoArr.sex,
				act_id:this.$route.query.act_id,
				institution_id: this.faIded,
				train_center_name: this.ActivityArr.train_center_name,
				complete_addr: this.ActivityArr.complete_addr,
			}).then(res=>{
				
				if(res.code==1){
					postOnlineCourseCertOffLine({
						user_id:this.$route.query.user_id,
						course_id:3,
						headimgurl:this.infoArr.headimgurl,
						user_name:this.infoArr.user_name,
						sex:this.infoArr.sex,
						act_id:this.$route.query.act_id,
						institution_id: this.faIded,
						train_center_name: this.ActivityArr.train_center_name,
						complete_addr: this.ActivityArr.complete_addr,
					}).then(res=>{
						if(res.code==1){
							this.$Toast.clear()
							_this.$Toast('发证成功')
							setTimeout(()=>{
								 _this.$router.go(-1)
							},500)
						}else{
							_this.$Toast(res.msg)
						}
					})
				}else{
					_this.$Toast(res.msg)
				}
			})
		}else if(list1.length==1){
			list1.forEach((item,i)=>{
				postOnlineCourseCertOffLine({
					user_id:this.$route.query.user_id,
					course_id:item,
					headimgurl:this.infoArr.headimgurl,
					user_name:this.infoArr.user_name,
					sex:this.infoArr.sex,
					act_id:this.$route.query.act_id,
					institution_id: this.faIded,
					train_center_name: this.ActivityArr.train_center_name,
					complete_addr: this.ActivityArr.complete_addr,
				}).then(res=>{
					if(res.code==1){
						this.$Toast.clear()
							_this.$Toast('发证成功')
							setTimeout(()=>{
								 _this.$router.go(-1)
							},500)
					}else{
						_this.$Toast(res.msg)
					}
				})
			
			})
		}
	}
  },
  
  mounted() {
		var _this = this;
  	document.title = this.title;  
		getInstitutions().then(res => {
			res.data.forEach(item => {
					this.faList.push(item.name)
				 this.faListid.push(item.id)
			})
		})
	getOtherUserCertsInfo({student_id:this.$route.query.user_id}).then(res=>{
		if(!res.data.headimgurl&&!res.data.headimgurl&&!res.data.headimgurl){
			this.isQualified=true
		}
		this.infoArr=res.data
	})
	 getActivity({act_id:this.$route.query.act_id}).then(res=>{
		 console.log(res)
		 this.ActivityArr=res.data
			this.faIded = this.ActivityArr.institution_id
		 for (let i = 0; i < this.faListid.length; i++) {
				if(this.faListid[i]==this.ActivityArr.institution_id){
					this.faVlaue=this.faList[i]
					console.log(this.faVlaue)
				}
			}
	 })
	// this.$Dialog({ message: '已发送微信公众号消息通知学员修改证书信息',confirmButtonText:'好' });
	// this.$Dialog({ message: '发证成功',confirmButtonText:'好' });
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.confirmation_card {
		.qualifiedBox {
			background-color: #fff;
			padding: 10px 15px;
			border-radius: 6px;
		
			.title {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 10px;
			}
		
			.qualifiedtext {
				font-size:18px;
				font-family:PingFang SC;
				font-weight:bold;
				color:rgba(40,51,74,1);
				line-height:48px;
				text-align: center;
			}
		
			.qualifiedtextflex_text {
				width: calc(100% - 220px);
				text-align: left;
			}

			.quali_kuang {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 70%;
				padding: 3px 12px;
				background: #F4F4F4;
				text-align: left;
				border-radius: 4px;
				font-size: 14px;
			}
		
			.qualifiedtextflex {
				display: flex;
				margin-top: 15px;
		
			}
		
			.qualifiedBtn {
				display: flex;
				justify-content: space-around;
				margin-top: 10px;
		
		
				.qualifiedBtn_btn {
					width: 45%;
					height: 42px;
					line-height: 42px;
					// padding:8px 15px;
					border-radius: 50px;
					background-color: #0081FF;
					color: #fff;
					margin: 30px 0;
				}
				.qualifiedBtn_btn1{
					background-color: #fff;
					border: 1px solid #0081FF;
					color: #0081FF;
				}
				.qualifiedBtn_btn2{
					background-color: #C6C8CA;
				}
		
				.btns {
					text-align: right;
					color: #0081FF;
					width: 95%;
					font-weight: bold;
					font-size: 15px;
					margin-top: 10px;
				}
			}
		}
		.van-cell {
			background:rgba(244,244,244,1);
			border-radius:6px;
			margin-top: 20px;
			// border-bottom: 1px solid #ddd;
			// padding:0 12px;
	
		}
		.xiuBtn{
			    padding: 5px 14px;
			    border-radius: 16px;
			    background-color: #0081FF;
			    color: #fff;
			    font-size: 12px;
		}
	}
</style>
