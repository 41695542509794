<template>
	<div class="certificate">
		<div v-if="!AssessmentList.length" class="default">
			<img src="../../assets/default/icon_zanwuwdzs.png" alt="" class="default_img">
			<p>暂未取得证书</p>
		</div>
		<div v-if="AssessmentList.length">
			<div class="list" v-for="(items,index) in AssessmentList" :key="index" @click="$router.push({path:'/certificateDet_user',query:{cert_id:items.id,type:items.type}})">
				<van-row class="flex" style="align-items: inherit;">
					<van-col span="9">
						<div class="flex" style="justify-content: flex-end;width: 110px;position: relative;">
							<img :src="items.frontimg" alt="" style="width: 110px;height: 70px;box-shadow:0px -2px 10px 0px rgba(145,156,176,0.2);">
							<img src="../../assets/icon_ygq_red@2x.png" alt="" width="36" style="position: absolute;top: 0;right: 0;border-radius: 0;" v-if="items.is_valid==0">
						</div>
					</van-col>
					<van-col span="15" class="flexs" v-if="items.type==1">
						<van-row>
							<!-- <van-col span="8" style="color:#919CB0;" v-if="item.name"><span v-text="item.name"></span></van-col> -->
							<span v-text="items.certificate_name" style="font-size: 15px;font-weight: bold;"></span>
						</van-row>
						<van-row  style="margin: 15px 0 10px;">
							<van-col span="5" style="color:#919CB0;"><span>课程</span></van-col>
							<van-col span="19"><span v-text="items.coursename"></span></van-col>
						</van-row>
						<van-row v-if="items.type!=1">
							<van-col span="8" style="color:#919CB0;"><span>发证机构</span></van-col>
							<van-col span="15"><span v-text="items.org_name"></span></van-col>
						</van-row>
					</van-col>
					<van-col span="15" class="flexs" v-if="items.type==2">
						<van-row>
							<!-- <van-col span="8" style="color:#919CB0;" v-if="item.name"><span v-text="item.name"></span></van-col> -->
							<span v-text="items.certificate_name" style="font-size: 15px;font-weight: bold;"></span>
						</van-row>
						<van-row  style="margin: 15px 0 10px;">
							<van-col span="5" style="color:#919CB0;"><span>课程</span></van-col>
							<van-col span="19"><span v-text="items.course_name"></span></van-col>
						</van-row>
						<van-row v-if="items.type!=1">
							<van-col span="8" style="color:#919CB0;"><span>发证机构</span></van-col>
							<van-col span="15"><span v-text="items.institution_name"></span></van-col>
						</van-row>
					</van-col>
					
				</van-row>
			</div>
		</div>
	</div>
</template>

<script>
	import { getMyOfficialCertsLists } from '@/api'
export default {
  name: 'cancel',
  components: {
  },
  data(){
	  return{
		   img:require('../../assets/1234.jpg'), 
		title:'培训证书',
		AssessmentList:[] 
	  }
  },
  methods:{
	
  },
  
  mounted() {
  	document.title = this.title;   
	getMyOfficialCertsLists({act_id:this.$route.query.act_id}).then(res=>{
		if(res.code==1){
			this.AssessmentList=res.data
			// res.data.forEach((item,i)=>{
			// 	item.forEach((items,index)=>{
			// 		this.AssessmentList.push(items)
			// 	})
			// })
			console.log(this.AssessmentList)
		}else{
			this.AssessmentList=[]
		}
		
	})
	// this.gethotcity()
	// this.getActivityDataCount()
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.certificate {
		width: 100%;
		min-height: 100vh;
		background: #F7F8FF;
		padding-bottom: 60px;
		padding: 15px;

		img {
			border-radius: 4px;
		}

		.van-col {
			text-align: left;
		}

		.list {
			background-color: #fff;
			padding: 16px 10px;
			border-radius: 6px;
			margin-bottom: 10px;
			// height: 102px;
		}

		.flexs {
			font-size: 12px;
			display: flex;
			flex-flow: column;
			justify-content: space-between;
		}
	}
</style>
