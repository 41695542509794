<template>
	<div class="longImg">
		<div class="floatBox">
			<div v-for="(item,i) in listAct" :key="i" :class="item.isact?'act':''" @click="goAnchor(item.jump,i)">
				<span v-text="item.text"></span>
			</div>
			
			<div @click="goAnchor('courseDetails')">
				<img src="@/assets/longImg/icon_top@2x.png" alt="" >
			</div>
			
		</div> 
		<img src="@/assets/longImg/newImg/pic_toptitlle_jz@2x.png" alt="" v-if="train_course[0]==3&&train_course.length==1&&act_type==1">
		<img src="@/assets/longImg/newImg/pic_toptitlle_cpraed@2x.png" alt="" v-if="train_course[0]==2&&train_course.length==1&&act_type==1">
		<img src="@/assets/longImg/newImg/pic_toptitlle_cpraedjz@2x.png" alt="" v-if="train_course[0]==2&&train_course[1]==3&&train_course.length==2&&act_type==1">
		<img src="@/assets/longImg/newImg/pic_toptitlle_daoshi@2x.png" alt="" v-if="act_type==2">
		<img src="@/assets/longImg/newImg/pic_toptitlle_zrdaoshi@2x.png" alt="" v-if="act_type==3">
		<div class="content" id="id1">
			
			<img src="@/assets/longImg/title1@2x.png" alt="" class="title1">
			<div  v-if="train_course[0]==3&&train_course.length==1&&act_type==1">
				创伤极为常见。我国死亡原因统计数据显示，创伤在城市中是第五位死因，在农村则为第四位死因，可见创伤对人类的生存和健康已构成了巨大的威胁。根据调查发现，缩短伤者得到救治的时间，可以有效地降低患者的死亡率和致残率。据统计，因创伤而死亡的患者50%死于创伤现场，30%死于创伤早期，20%死于创伤后期并发症。因此，伤后尽快开始处理伤员对伤员的存活至关重要。有学者认为：若能在伤后5分钟内给予救命性措施，伤后30分钟内给予医疗急救，则18～25%患者生命可因此得到挽救。所以，在创伤现场，“第一目击者”能否对伤员实施正确的初步急救，往往直接影响患者的预后。
			</div>
			<div v-if="act_type==2||act_type==3">
				只要您获得了《现场急救行动指南》培训课程的所有学员证书《现场急救线上教学课程合格证》《心肺复苏术及自动体外除颤仪使用课程合格证》《急症急救及创伤救护课程合格证》，并且致力于急救知识的传播，那么，就请报名，加入我们，一起行动吧！
			</div>
			<img src="@/assets/longImg/newImg/pic_ketang@2x.png" alt="" class="pic_cusi"  v-if="act_type==2||act_type==3">
			<img src="@/assets/longImg/newImg/pic_changs@2x.png" alt="" class="pic_cusi"  v-if="train_course[0]==3&&train_course.length==1&&act_type==1">
			<div v-if="train_course[0]==2&&train_course.length==1&&act_type==1||train_course[0]==2&&train_course[1]==3&&train_course.length==2&&act_type==1">
				<div> 无论过去、现在还是将来，人类最紧急、最严重、最危险的疾病只有一个——猝死。
					据统计，我国每年心脏性猝死的人数约54万人，居全球之首。</div>
				<img src="@/assets/longImg/pic_cusi@2x.png" alt="" class="pic_cusi">
				<div>
					对于这类突发意外，心肺复苏时间是挽救生命的关键，如果在心跳停止<span class="orange">4分钟内</span>正确实施心肺复苏急救，抢救成功率可达<span class="orange">50%</span>。如果心跳停止后超过10分钟才实施急救则生存机会渺茫。
				</div>
				<img src="@/assets/longImg/pic_time@2x.png" alt="" class="pic_cusi">
				<div>相关统计显示，猝死等急症约87.7%发生在医院外，没有医护人员及时参与抢救。 据不完全统计，我国每年意外伤害死亡患者约70万人，是居民死亡原因的第4位。 意外事件中，搬运不当导致的“二次伤害”也是致命的。</div>
			</div>
		</div>
	
		<!-- <div class="content" id="id2" v-if="act_type==1">
			<img src="@/assets/longImg/title_2@2x.png" alt="" class="title1">
			<div>如果家中的至亲，身边的挚友，工作上的伙伴突发意外，心脏骤停，你该怎么做？</div>
			<div>打电话120求救？绝对正确！然后呢？只会束手无策地等待救护车？</div>
			<div>但救护车能在<span class="orange">“黄金4分钟”</span>内赶到吗？ </div>
			<div> 生命系于千钧一发，我们必须转变观念——意外发生时，能在第一时间为伤病者提供有效救护的人就是我们自己！</div>
			<div>生死只在一瞬间，赢得时间意味着留住生命。</div>
			<img src="@/assets/longImg/pic_cusirq@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
		</div>
		<div class="content"  id="id3" v-if="act_type==1">
			<img src="@/assets/longImg/title_3@2x.png" alt="" class="title1" style="width: 62%;">
			<div>
				如果你愿意为了自己、家人、挚友以及身边的人，花点时间，学点急救技能，那欢迎参加
				<span class="lightBlue">现场急救行动</span>
				培训课程，学习科学的急救知识，掌握急救技能，为生命争分夺秒，让脆弱的生命坚强起来。
			</div>
	
			<img src="@/assets/longImg/gif_cpr.gif" alt="" class="pic_cusi" style="margin-bottom: 0;">
		</div> -->
		<div class="content content1" style="padding: 20px 0;"  >
			<img src="@/assets/longImg/title_1_blue@2x.png" alt="" class="title1_abo" id="id4">
			<div style="padding:0 15px;font-size: 15px;"  v-if="act_type==2">
			
			</div>
			<div style="padding:0 15px;font-size: 15px;"  v-if="train_course[0]==2&&train_course[1]==3&&train_course.length==2&&act_type==1">
			本课程为线下教学，学员通过实践操作，切实掌握心肺复苏术、AED使用术、气道异物梗阻解救术、急症急救、创伤救护等救命技能。通过考核，还可以获得培训合格证书。
			</div>
			<div v-if="train_course[0]==3&&train_course.length==1&&act_type==1||train_course[0]==2&&train_course[1]==3&&train_course.length==2&&act_type==1">
				<div   style="padding:0 15px;font-size: 15px;"  v-if="train_course[0]==3&&train_course.length==1&&act_type==1">
				本课程为线下教学，学员通过实践操作，切实掌握急症急救、创伤救护等技术。通过考核，还可以获得培训合格证书。
				</div>
				<img src="@/assets/longImg/newImg/pic_jizhengzm@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
				<img src="@/assets/longImg/newImg/pic_jizhengbm@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
				<div class="center">【急症急救及创伤救护课程证书样版】</div>
			</div>
			<div v-if="act_type==2">
				<div style="padding:0 15px;font-size: 15px;" >
				本课程为线下教学，学员通过导师培训，切实掌握项目情况、项目行政管理、教学技巧、教学工具使用等内容。通过考核，还可以获得培训导师证书。
				</div>
				<img src="@/assets/longImg/newImg/pic_daoshi_zm@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
				<img src="@/assets/longImg/newImg/pic_daoshi_bm@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
				<div class="center">【现场急救行动培训导师课程证书样版】</div>
			</div>
			<div v-if="act_type==3">
				<div style="padding:0 15px;font-size: 15px;" >
				本课程为线下教学，学员通过主任导师培训，切实掌握项目介绍、主任导师项目行政管理、导师培训技巧、教学工具使用等内容。通过考核，还可以获得主任导师培训证书。
				</div>
				<img src="@/assets/longImg/newImg/pic_zrdaoshi_zm@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
				<img src="@/assets/longImg/newImg/pic_zrdaoshi_bm@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
				<div class="center">【现场急救行动培训主任导师课程证书样版】</div>
			</div>
			<div v-if="train_course[0]==2&&train_course.length==1&&act_type==1||train_course[0]==2&&train_course[1]==3&&train_course.length==2&&act_type==1">
				<div  style="padding:0 15px;font-size: 15px;" v-if="train_course[0]==2&&train_course.length==1&&act_type==1">
				本课程为线下教学，学员通过实践操作，切实掌握心肺复苏术、AED使用术、气道异物梗阻解救术等救命技能。通过考核，还可以获得培训合格证书。
				</div>
				<img src="@/assets/longImg/newImg/pic_cpraedzm@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
				<img src="@/assets/longImg/newImg/pic_cpraedbm@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
				<div class="center">【心肺复苏术及自动体外除颤仪使用课程证书样版】</div>
			</div>
			
			
			<!-- <div style="padding:0 15px;">
				本课程包含常见内科急症、中毒、动物咬伤、环境因素损伤、灾难逃生与急救等基础急救的理论知识培训课，和心肺复苏术、自动体外除颤仪使用术、气道异物梗阻解救术、创伤救护等系列技能实操体验课。学员可按照自己的需求选择学习全部或部分内容课程。并按照课程发布的技能课程实际信息情况，选择相应的技能课程参加考核，通过课程规定的实践测试者均可获得相应的合格证书。
			</div> -->
			
			<!-- <img src="@/assets/longImg/pic_cprhgz@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
			<div class="center">【心肺复苏术课程证书样版】</div>
			
			<img src="@/assets/longImg/pic_aedhgz@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
			<div class="center">【AED操作课程证书样版】</div>
			<img src="@/assets/longImg/pic_csjjhgz@2x.png" alt="" class="pic_cusi" style="margin-bottom: 0;">
			<div class="center">【创伤急救课程证书样版】</div> -->
		</div>
		<div class="content content1"  >
			<img src="@/assets/longImg/title_2_blue@2x.png" alt="" class="title1_abo" id="id5">
			<div class="flex center small" v-if="train_course[0]==3&&train_course.length==1&&act_type==1">
				<div style="flex: 1;">
					<img src="@/assets/longImg/newImg/pic_aed_kcpx@2x.png" alt="">
					<div class="lightBlue small">急症急救</div>
				</div>
				<div style="flex: 0 0 15px;"></div>
				<div style="flex: 1;">
					<img src="@/assets/longImg/newImg/pic_csjj_kcpx@2x.png" alt="">
					<div class="lightBlue small">创伤急救</div>
				</div>
			</div>
			<div v-if="train_course[0]==2&&train_course.length==1&&act_type==1">
				<div class="flex center small" >
					<div style="flex: 1;">
						<img src="@/assets/longImg/newImg/pic_cpr_kcpx@2x.png" alt="">
						<div class="lightBlue small">心肺复苏术</div>
					</div>
					<div style="flex: 0 0 15px;"></div>
					<div style="flex: 1;">
						<img src="@/assets/longImg/newImg/pic_etcpr_kcpx@2x.png" alt="">
						<div class="lightBlue small">儿童心肺复苏术</div>
					</div>
					
				</div>
				<div class="flex center small">
					<div style="flex: 1;">
						<img src="@/assets/longImg/newImg/pic_aed_kcpx@2x.png" alt="">
						<div class="lightBlue small">体外自动除颤仪使用术</div>
					</div>
					<div style="flex: 0 0 15px;"></div>
					<div style="flex: 1;">
						<img src="@/assets/longImg/newImg/pic_qdyw_kcpx@2x.png" alt="">
						<div class="lightBlue small">气道异物梗阻解救术</div>
					</div>
				</div>
			</div>
			<div v-if="train_course[0]==2&&train_course[1]==3&&train_course.length==2&&act_type==1">
				<div class="flex center small" >
					<div style="flex: 1;">
						<img src="@/assets/longImg/newImg/pic_cpr_kcpx@2x.png" alt="">
						<div class="lightBlue small">心肺复苏术</div>
					</div>
					<div style="flex: 0 0 15px;"></div>
					<div style="flex: 1;">
						<img src="@/assets/longImg/newImg/pic_aed_kcpx@2x.png" alt="">
						<div class="lightBlue small">体外自动除颤仪使用术</div>
					</div>
				</div>
				<div class="flex center small" >
					<div style="flex: 1;">
						<img src="@/assets/longImg/newImg/pic_aed_kcpx@2x.png" alt="">
						<div class="lightBlue small">急症急救</div>
					</div>
					<div style="flex: 0 0 15px;"></div>
					<div style="flex: 1;">
						<img src="@/assets/longImg/newImg/pic_csjj_kcpx@2x.png" alt="">
						<div class="lightBlue small">创伤急救</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 32px;border:1px solid rgba(54,153,255,1);border-radius:10px;" >
				<div v-for="(item,i) in list" :key="i" class=" flex_list">
					<!-- <div class="flex_title Small flex"><span v-text="item.title"></span></div>
					<div style="flex: 0 0 2px;"></div>
					<div class="flex flex_conten Small">
						<div v-for="(items,index) in item.content" v-text="items"></div>
					</div> -->
					<div style="padding: 6px 0;">
						<img src="@/assets/longImg/newImg/icon_label@2x.png" alt="" style=" height: 12px; width: 12px;margin-right: 15px;">
					</div>
					<div style="width: calc(100% - 12px - 15px);">{{item}}</div>
				</div>
				
				
			</div>
			<!-- <div style="color: #4F8DFA;">
					《指南》教材包括一上全部内容，但实际课程 导师可能结合课程时间只节选部分内容详细讲 授。
				</div> -->
		</div>
		<div class="content content1" style="padding: 20px 0;"  >
			<img src="@/assets/longImg/title_3_blue@2x.png" alt="" class="title1_abo" id="id6">
			<img src="@/assets/longImg/pic_jybjx@2x.png" alt="" class="pic_cusi" v-if="act_type==1">
			<img src="@/assets/longImg/newImg/pic_xbzjx@2x.png" alt="" class="pic_cusi" v-if="act_type==2||act_type==3">
			<img src="@/assets/longImg/pic_tysxx@2x.png" alt="" class="pic_cusi">
			<img src="@/assets/longImg/pic_spjx@2x.png" alt="" class="pic_cusi">
		</div>
		<div class="content content1" style="padding: 20px 15px;" v-if="act_type==1">
			<img src="@/assets/longImg/title_4_blue@2x.png" alt="" class="title1_abo"  id="id7">
			<div class="flex title_4_blue_box" v-for="(item,i) in title_4_contensList" :key="i">
				<img src="@/assets/longImg/icon_label_yellow@2x.png" alt="" style="width: 16px;margin-top: 5px;" v-if="item.istrue">
				<img src="@/assets/longImg/icon_label_blue@2x.png" alt="" style="width: 16px;margin-top: 5px;" v-if="!item.istrue">
				<div class="title_4_blue_contens"><span v-text="item.text" style="line-height: 26px;"></span></div>
			</div>
			
		</div>
		<div class="content content1" style="padding: 20px 15px 5px;" v-if="act_type==2">
			<img src="@/assets/longImg/title_4_blue@2x.png" alt="" class="title1_abo"  id="id7">
			<div class="flex title_4_blue_box" v-for="(item,i) in title_4_contensList1" :key="i">
				<img src="@/assets/longImg/icon_label_yellow@2x.png" alt="" style="width: 16px;margin-top: 5px;" v-if="item.istrue">
				<img src="@/assets/longImg/icon_label_blue@2x.png" alt="" style="width: 16px;margin-top: 5px;" v-if="!item.istrue">
				<div class="title_4_blue_contens"><span v-text="item.text" style="line-height: 26px;"></span></div>
			</div>
			
		</div>
		<div class="content content1" style="padding: 20px 15px;"  >
			<img src="@/assets/longImg/title_5_blue@2x.png" alt="" class="title1_abo" id="id8">
			<div class="flex title_4_blue_box" v-for="(item,i) in title_5_contensList" :key="i">
		
				<div class="title_4_blue_contens"><span v-text="item" style="line-height: 26px;"></span></div>
			</div>
			
		</div>
		<img src="@/assets/pic_tuigbanner@2x.png" alt="" style="padding: 5px 15px 15px;">
		<img src="@/assets/longImg/pic_bottom@2x.png" alt="">
	</div>
</template>

<script>
	export default {
		props: {
			act_type:{
				type:Number,
				
			},
			train_course: {
				type: Array
			},
			list:{
				type:Array
			}
		},
			data() {
				return {
					title_5_contensList:[
						'1、交费成功即为报名成功。因课程是按照学 员数量配置导师和教具，报名成功后不得退 出（退出者恕不退费）。如有特殊情况，可 以申请延期学习。',
						'2、课程内容丰富，安排紧凑，请务必准时到 场。',
						'3、课程实操训练多，学员尽量穿着宽松舒适 的便装或运动服参加，女性尽量不穿短裙或 低领的衣服。',
						'4、培训当天因身体状况有无法完成某些练习、 或者对橡胶类制品过敏的情况时，请在课前 告知导师。'
					],
					title_4_contensList1:[
						{
							istrue:true,
							text:'通过培训，成为急救知识的传播者'
						},
					],
					title_4_contensList:[
						{
							istrue:true,
							text:'如果您是孩子的父母，老人的儿女，系统 地学习了急救知识，掌握了急救技能，您 就如同给至亲撑起了一把保护伞；'
						},
						{
							istrue:false,
							text:'如果您是一名教师，系统地学习了急救知 识，在学生遇到突发情况的时候，您就不 会束手无策；；'
						},
						{
							istrue:true,
							text:'如果您是一个团队领导者，队友在您身边 倒下时就有能力争取抢救时间，减少悲剧 发生；'
						},
						{
							istrue:false,
							text:'如果您是公益人，经过培训取得合格证书、 具备急救专业技能，对急、危、重伤病员 按照操作规范实施紧急现场救护，将受法 律保护。'
						},
						{
							istrue:true,
							text:'总之，掌握急救知识和技能，或许你就能 随时随地挽救他人生命。'
						}
					],
					list1:[
						'线上教学视频答疑',
						'急症急救及创伤救护理论知识重点讲解',
						'止血术',
						'包扎术',
						'固定术',
						'搬运术',
						'创伤救护流程',
						'课程考核'
						
					],
					list2:[
						'线上教学视频答疑',
						'心肺复苏和自动体外除颤仪理论知识重点讲解',
						'成人心肺复苏术',
						'儿童心肺复苏术',
						'婴儿心肺复苏术',
						'自动体外除颤仪使用术',
						'气道异物梗阻解救术',
						'课程考核'
					],
					list3:[
						'线上教学视频答疑',
						'心肺复苏和自动体外除颤仪理论知识重点讲解',
						'成人心肺复苏术',
						'儿童心肺复苏术',
						'婴儿心肺复苏术',
						'自动体外除颤仪使用术',
						'气道异物梗阻解救术',
						'急症急救及创伤救护理论知识重点讲解',
						'止血术',
						'包扎术',
						'固定术',
						'搬运术',
						'创伤救护流程',
						'课程考核'
					],
					list4:[
						'项目介绍',
						'项目行政管理',
						'教学技巧',
						'教学工具',
						'线上教学视频答疑',
						'课程考核'
					],
					list5:[
						'项目介绍',
						'主任导师项目行政管理',
						'导师培训技巧',
						'教学工具使用',
						'课程考核'
					],
					listAct:[{
						text:'学习目的',
						isact:true,
						jump:'id1'
					},
					{
						text:'课程简介',
						isact:false,
						jump:'id4'
					},
					{
						text:'培训内容',
						isact:false,
						jump:'id5'
					},
					{
						text:'培训方式',
						isact:false,
						jump:'id6'
					},
					{
						text:'课程作用',
						isact:false,
						jump:'id7'
					},
					{
						text:'温馨提示',
						isact:false,
						jump:'id8'
					},
					]
				}
			},
			methods:{
				goAnchor(idNames,i){
					if(idNames!=='courseDetails'){
						this.listAct.forEach((item,index)=>{
							this.listAct[index].isact=false
						})
						this.listAct[i].isact=true
					}
					
					
					console.log(idNames)
					   this.$emit('goAnchor',{ idName:idNames })
				}
			},
			mounted() {
				console.log(this.train_course)
			}
			
		}
</script>

<style>
	@import url("../../assets/css/longImg.css");
</style>
