<template>
	<div class="certificateDet">
		<div style="padding: 20px 15px 00px;">
			<van-row>
				<van-col span="15"><span class="hui">
						学员：
					</span><span v-text="name">

					</span></van-col>
			</van-row>
		</div>
		<div v-for="(item,i) in list" :key="i">
			<div class="padding" style="padding: 20px 15px 15px;">
				<van-row>
					<van-col span="6" style="color:#919CB0"><span>考核内容：</span></van-col>
					<van-col span="6"><span v-text="item.course_name">
						</span></van-col>
					<van-col span="6" style="color:#919CB0;margin-left: 15px;"><span>
							考核结果：
						</span></van-col>
					<van-col span="4"><span class="blue" v-text="item.state==0?'未通过':'通过'">

						</span></van-col>
				</van-row>
			</div>
			<video width="100%" height="220" controls v-if="isvid" :id="'video'+i">
				<source :src="item.videoUrl" type="video/mp4">
				<source src="movie.ogg" type="video/ogg">
				您的浏览器不支持Video标签。
			</video>
			<div class="video" v-if="!isvid" @click="VideoClick(i)">
				<img :src="item.course_videocover" alt="" class="auto-img">
				<div class="bof">
					<img src="../../assets/user/bofan.png" alt="" class="auto-img">
				</div>
			</div>

			<div class="bigBox">
				<img :src="item.certificate_img" alt="" style="border-radius: 8px;">

			</div>
			<div class="division"></div>
		</div>
		<div class="bottom">
			<div class="btn">
				发证
			</div>
		</div>
	</div>
</template>

<script>
	import { getOfficialActivityCertListByUser } from '@/api'
	
	export default {
  name: 'certificateDet',
  components: {
  },
  data(){
	  return{
		  isvid:false,
		title:'证书发放',
		
		remarks:'',
		name:'李三',
		list:[{
			
			conten:'心肺复苏术',
			state:1,
			videoUrl:'https://vdept.bdstatic.com/506d416c4d6c666842595251616d7741/6c70684535535532/246abafe32db0efb243bc3efe0c006c339c42df89acaf832acbcb54e22fbdb4114f0dc87ed89f2049faf80c47498b9fc.mp4?auth_key=1583141059-0-0-2f18cf3a33eec0d6a58831748722f632',
			videoimg:'https://pic.rmb.bdstatic.com/87c1792897ead823565f28b2b762f9d6.jpeg@s_2,w_454,h_256,q_100',
			  img:require('../../assets/1234.jpg'),
		},{
			
			conten:'心肺复苏术',
			state:1,
			videoUrl:'https://vdept.bdstatic.com/506d416c4d6c666842595251616d7741/6c70684535535532/246abafe32db0efb243bc3efe0c006c339c42df89acaf832acbcb54e22fbdb4114f0dc87ed89f2049faf80c47498b9fc.mp4?auth_key=1583141059-0-0-2f18cf3a33eec0d6a58831748722f632',
			videoimg:'https://pic.rmb.bdstatic.com/87c1792897ead823565f28b2b762f9d6.jpeg@s_2,w_454,h_256,q_100',
			  img:require('../../assets/1234.jpg'),
		}]
	  }
  },
  methods:{
	VideoClick(){
		var myVideo=document.getElementById(`video${i}`);
		this.isvid=true
		 myVideo.play(); 
		
	}
  },
  
  mounted() {
  	document.title = this.title;   
	getOfficialActivityCertListByUser({act_id:this.$route.query.act_id,cert_type:this.$route.query.act_type,user_id:this.$route.query.user_id}).then(res=>{
		if(res.code==1){
			this.list = res.data.list
		}
	})
	this.name=this.$route.query.user_name
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.certificateDet {
		width: 100%;
		min-height: 100vh;
		background-color: #fff;
		padding-bottom: 80px;
		.bigBox {
			padding: 20px 15px;
			background-color: #fff;
			padding-bottom: 20px;
			img {
				width: 100%;
			}
			.title {
				font-size: 17px;
				text-align: left;
				padding: 20px 0;
				border-bottom: 1px solid #E5E5E5;
				margin-bottom: 15px;
			}
		}

		.van-col {
			text-align: left;
		}

		.bottom {
			box-shadow: 1px 1px 10px 5px #E5E5E5;
		}

		.van-image__img {
			border-radius: 8px;
		}

		.video {
			width: 100%;
			height: 220px;
			// background-color: red;
			position: relative;

			.bof {
				width: 50px;
				height: 50px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
</style>
