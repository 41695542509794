<template>
  <div class="audit-wrap">
    <div class="activity-info">
      <div class="activity-row">
        <span>名称</span>
        <span v-text="activity.act_name"></span>
      </div>
      <div class="activity-row">
        <span>培训课程</span>
        <span v-text="activity.train_course"></span>
      </div>
      <div class="activity-row">
        <span>培训类型</span>
        <span v-text="activity.act_type"></span>
      </div>
      <div class="activity-row">
        <span>培训讲师</span>
        <span v-text="activity.act_author"></span>
      </div>
      <div class="activity-row">
        <span>培训地址</span>
        <span v-text="activity.complete_addr"></span>
      </div>
      <div class="activity-row">
        <span>开始时间</span>
        <span v-text="activity.act_start_time"></span>
      </div>
      <div class="activity-row">
        <span>结束时间</span>
        <span v-text="activity.act_end_time"></span>
      </div>
    </div>

    <div class="bottom">
        <div class="common" :class="[activity.act_status == 0 ? 'bottom_input' : 'disable']" v-text="activity.act_status == 0 ? '审核' : '已审核'" @click="audit">
        </div>
      </div>

  </div>
</template>


<script>
import { getAuditingGetActivityDetail, changeAuditingGetActivityComplete } from '@/api';
import { Toast } from 'vant';
export default {
  name: 'activityAudit',
  data(){
	  return{
      title: "审核",
      act_id: '',
      activity: {},
	  }
  },
  methods:{
	  getActivityInfo() {
      getAuditingGetActivityDetail({act_id: this.act_id }).then(res => {
        if(res.code === 1) {
          this.activity = res.data.info;
        }
      })
    },
    audit() {
      if(this.activity.act_status != 0) return;
      changeAuditingGetActivityComplete({act_id: this.act_id }).then(res => {
        Toast(res.msg)
        if(res.code == 1) {
          this.getActivityInfo();
        }
      })
    }
  },
  
  mounted() {
    this.act_id = this.$route.query.act_id;
  	document.title = this.title;
    this.getActivityInfo();
  }
}
</script>
<style lang="scss" type="text/css" scoped>
.audit-wrap {
  padding-top: 1rem;
  min-height: 100vh;
	background: #F7F8FF;
  .activity-info {
    padding: 0.5rem;
    width: 90%;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    .activity-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.4rem;
    }
  }
  .bottom {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			background: #fff;
			height: 5rem;
			z-index: 66;
			box-shadow: 0px 3px 15px 0px #919CB0;
      .common {
        font-size: 14px;
				width: 75%;
				height: 2.625rem;
				margin: 0 auto;
        border-radius: 50px;
				position: relative;
				text-align: left;
				line-height: 2.625rem;
				text-align: center;
				color: #fff;
				transform: translateY(50%);
      }
			.bottom_input {
				background: #0081FF;
			}
      .disable {
				background: #919CB0;
			}
		}
}
</style>