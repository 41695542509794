<template>
	<div class="setMaterial">
		<div class="box" :style="title=='修改所在城市'?'padding:0':title=='修改导师资质'?'padding-bottom:15px':''">
			<div v-if="title=='修改真实姓名'">
				<van-row>
				  <van-col span="4">姓名</van-col>
				  <van-col span="20"><input type="text" class="inputBox" v-model="usering.user_name" placeholder="请输入姓名"></van-col>
				</van-row>
			</div>
			<div v-if="title=='修改性别'">
				<van-row>
				  <van-col span="4">性别</van-col>
				  <van-col span="20">
					  <van-radio-group v-model="usering.user_sex" style="display: flex;height: 36px;">
					    <van-radio name="1" style="margin-right: 30px;">
					      男
					      <img
					        slot="icon"
					        slot-scope="props"
					        v-bind:src="props.checked ? activeIcon : inactiveIcon" style="width:20px;height:20px;vertical-align : top;"
					      >
					    </van-radio>
					    <van-radio name="2">
					      女
					      <img
					        slot="icon"
					        slot-scope="props"
					        :src="props.checked ? activeIcon1 : inactiveIcon1" style="width:20px;height:20px;vertical-align : top;"
					      >
					    </van-radio>
						</van-radio-group>
				  </van-col>
				</van-row>
			</div>
			<div v-if="title=='绑定手机号码'">
				<van-row>
				  <van-col span="5">手机号码</van-col>
				  <van-col span="19" style="position: relative;">
					  <input type="text" class="inputBox" v-model="newPhone" placeholder="请输入常用手机号">
					 <div class="abs abs1" @click="miBilecode1" v-if="newPhone.length==11">{{Daojis==60?'获取验证码':Daojis+'S后重新发送'}}</div>
					 <div class="abs abs1" style="color: #767E8D;border-color: #767E8D;" v-if="newPhone.length!=11">{{Daojis==60?'获取验证码':Daojis+'S后重新发送'}}</div>
				</van-col>
				</van-row>
				<div style="height: 28px;">
					
				</div>
				<van-row>
				  <van-col span="5">验证码</van-col>
				  <van-col span="19"><input type="text" class="inputBox" v-model="code" placeholder="请输入验证码"></van-col>
				</van-row>
			</div>
			<div v-if="title=='修改手机号码'">
				<van-row>
				  <van-col span="6">旧手机号码</van-col>
				  <van-col span="18" style="position: relative;">
					  <input type="number" class="inputBox" v-model="usering.user_phone" placeholder="请输入常用手机号">
					  
				</van-col>
				</van-row>
				<div style="height: 28px;">
					
				</div>
				<van-row>
				  <van-col span="6">新手机号码</van-col>
				  <van-col span="18" style="position: relative;">
					  <input type="number" class="inputBox" v-model="newPhone" placeholder="请输入常用手机号" style="caret-color:#0081FF;">
					 <div class="abs abs1" @click="miBilecode1" v-if="newPhone.length==11">{{Daojis==60?'获取验证码':Daojis+'S后重新发送'}}</div>
					 <div class="abs " style="color: #767E8D;border-color: #767E8D;" v-if="newPhone.length!=11">{{Daojis==60?'获取验证码':Daojis+'S后重新发送'}}</div>
				</van-col>
				</van-row>
				<div style="height: 28px;">
					
				</div>
				<van-row>
				  <van-col span="6">验证码</van-col>
				  <van-col span="18"><input type="number" class="inputBox" v-model="code" placeholder="请输入验证码"></van-col>
				</van-row>
			</div>
			<div v-if="title=='修改密码'">
				<van-row style="margin-bottom: 28px;">
				  <van-col span="6">原始密码</van-col>
				  <van-col span="18"><input type="password" class="inputBox" v-model="old_user_passwd" placeholder="请输入原密码"></van-col>
				</van-row>
				<van-row style="margin-bottom: 28px;">
				  <van-col span="6">新密码</van-col>
				  <van-col span="18"><input type="password" class="inputBox" v-model="new_user_passwd1" placeholder="请输入密码（6-18）位英文或数字"></van-col>
				</van-row>
				<van-row>
				  <van-col span="6">确认密码</van-col>
				  <van-col span="18"><input type="password" class="inputBox" v-model="new_user_passwd" placeholder="请再次输入密码"></van-col>
				</van-row>
			</div>
			<div v-if="title=='设置密码'">
				<van-row>
				  <van-col span="6">手机号码</van-col>
				  <van-col span="18" style="position: relative;">
					  <input type="text" :disabled="Daojis!=60" name="newPhone" class="inputBox" v-model="newPhone" :placeholder="usering.user_phone?'请输入手机号码':'未绑定手机号码'" style="caret-color:#0081FF;">
					 
					  <div class="abs abs1" @click="miBilecode1" v-if="newPhone.length==11">{{Daojis==60?'获取验证码':Daojis+'S后重新发送'}}</div>
					  <div class="abs abs1" style="color: #767E8D;border-color: #767E8D;" v-if="newPhone.length!=11">{{Daojis==60?'获取验证码':Daojis+'S后重新发送'}}</div>
				</van-col>
				</van-row>
				<div style="height: 28px;">
					
				</div>
				<van-row style="margin-bottom: 28px;">
				  <van-col span="6">验证码</van-col>
				  <van-col span="18"><input type="number" class="inputBox" v-model="code" placeholder="请输入验证码"></van-col>
				</van-row>
				
				<van-row style="margin-bottom: 28px;">
				  <van-col span="6">新密码</van-col>
				  <van-col span="18"><input type="password" class="inputBox" v-model="new_user_passwd1" placeholder="请输入密码（6-18）位英文或数字"></van-col>
				</van-row>
				<van-row style="margin-bottom: 28px;">
				  <van-col span="6">确认密码</van-col>
				  <van-col span="18"><input type="password" class="inputBox" v-model="new_user_passwd" placeholder="请再次输入密码"></van-col>
				</van-row>
			</div>
			<div v-if="title=='修改所在城市'">
				<!-- 省份 -->
				<van-list
				  v-model="loading"
				  :finished="finished"
				  finished-text="没有更多了"
				  @load="onLoad"
				  v-if="iscity==1"
				>
				  <van-cell
				    v-for="(item,index) in list"
				    :key="index"
				   @click="city(index)"
				  >
				  {{item.province}}
				  </van-cell>
				</van-list>
				<!-- 城市 -->
				<van-list
				  v-model="loading"
				  :finished="finished"
				  finished-text="没有更多了"
				  @load="onLoad"
				  v-if="iscity==2"
				>
				  <van-cell
				    v-for="(item,index) in list1"
				    :key="index"
				   @click="city(index)"
				  >
				  {{item.city}}
				  </van-cell>
				</van-list>
			</div>
			<div v-if="title=='修改签名'" style="margin-top: -10px;">
				<div style="text-align: left;margin-bottom: 12px;">
					我的签名
				</div>
				<van-field
				  v-model="usering.user_signature"
				  rows="8"
				  :autosize="{ minHeight: 208 }"
				  type="textarea"
				  placeholder="请输入内容"
				  style="background-color: #F4F4F4;"
				/>
			</div>
			<div v-if="title=='修改所属机构'">
				<van-row>
				  <van-col span="6">所属机构 </van-col>
				  <van-col span="18"><input type="text" class="inputBox" v-model="usering.institution" placeholder="请输入所属机构"></van-col>
				</van-row>
			</div>
			<div v-if="title=='修改导师资质'">
				<van-row v-for="(item,i) in qualifications" :key="i" style="margin-bottom: 28px;position: relative;transition:all .5s;" :style="isdel?'left:33px':'left:0px'">
					<div class="icon_del" @click="delClick(i)">
						<img src="../../assets/Train/icon_delete@2x.png" alt="" class="icon_shiliu">
					</div>
				  <van-col span="3" style="text-align: left;"> <span v-text="'资质'+(i+1)"></span></van-col>
				  <van-col span="21">
					  <van-field
							v-model="qualifications[i]"
						  rows="1"
						  :autosize=" { minHeight: 26}"
						  type="textarea"
						  placeholder="请输入导师资质内容"
						  style="background-color: #F4F4F4;border-radius: 6px;min-height: 36px;padding: 5px 11px;"
						:class="isfocus&&isfocusIndex==i?'focus':''"
						  @focus="focus(i)"
						  @blur="blur(i)"
						/>
				</van-col>
				</van-row>
				<van-divider />
				<div>
					<van-row>
					  <van-col span="12">
						  <div class="flex"  style="justify-content: center;" @click="del">
							<img src="../../assets/user/icon_deleteblue_nor@2x.png" alt="" class="icon_shiliu" v-if="!isdel">  
							<img src="../../assets/user/icon_deleteblue_pre@2x.png" alt="" class="icon_shiliu" v-if="isdel">  
							<div style="margin-left: 9px;" :style="isdel?'color:#919CB0':''">删除资质</div>
						  </div>
					  </van-col>
					  <van-col span="12">
						  <div class="flex"  style="justify-content: center;" @click="add">
								<img src="../../assets/user/icon_deleteblue_nor@2x.png" alt="" class="icon_shiliu">  
								<div  style="margin-left: 9px;">添加资质</div>
						  </div>
					</van-col>
					</van-row>
				</div>
				
			</div>
			<div v-if="title=='修改个人简介'" style="margin-top: -10px;">
				<div style="text-align: left;margin-bottom: 12px;">
					个人简介
				</div>
				<van-field
				  v-model="usering.user_info"
				  rows="8"
				  :autosize="{ minHeight: 208 }"
				  type="textarea"
				  placeholder="请输入内容"
				  style="background-color: #F4F4F4;"
				/>
			</div>
		</div>
		<div class="bottom" style="position: relative;background-color: transparent;" v-if="isdel">
			<div class="btn" @click="complete">完成</div>
		</div>
		<div class="bottom" style="position: relative;background-color: transparent;" v-if="title!=='修改所在城市'">
			<div class="btn" @click="seach"  :style="isdel?'background:#C6C8CA':''">提交</div>
		</div>
		<div style="color: #0081FF;" @click="$router.push({name:'setMaterial1',query:{type1:'重置密码'}})" v-if="title=='修改密码'">忘记密码</div>
	</div>
</template>

<script>
import { postClientUserscode,user,postUserInfo,postSetUserPhone,getMobileCode,getProvinces,getCities,getAreas,postQualifications ,postChangeClientUserscode } from '@/api';
import { Toast } from 'vant'

	export default {
  name: 'setMaterial',
  components: {
  },
  data(){
	  return{
		  new_user_passwd1:'',
		  old_user_passwd:'',
		  new_user_passwd:'',
		  // 输入状态
		  isfocus:false,
		  isfocusIndex:0,
		  // 列表
		  loading: false,
		  finished: false,
		  // 验证码
		  code:'',
		  // 倒计时
		  Daojis:60,
		  // 新手机号码
		  newPhone:'',
		  // 性别,图标
		  activeIcon: require('../../assets/Train/icon_nan_pre@2x.png'),
		  inactiveIcon: require('../../assets/Train/icon_nan_nor@2x.png'),
		  activeIcon1: require('../../assets/Train/icon_nv_pre@2x.png'),
		  inactiveIcon1: require('../../assets/Train/icon_nv_nor@2x.png'),
		  // 个人资料
		  usering:{},
		  // 标题
		  title:'',
		  getProvinces:[],
		  getgetCities:[],
		  getAreas:[],
		  // 控制城市列表数据
		  list1:[],
		  // 渲染城市列表
		  list:[],
		  // 省份提交
		  provinceid:'',
		  // 城市提交
		  cityValue:'',
		  iscity:1,
		  // 删除资质状态
		  isdel:false,
		  qualifications: [
		              
		          ]
		  
	  }
  },
  watch:{
	  // 'newPhone'(a,b){
		 //  if(this.newPhone.length==11){
			// this.newPhone= this.starPhone(this.newPhone)
		 //  }else{
			//   this.newPhone=this.newPhone
		 //  }
	  // }
  },
  methods:{
	  starPhone(p){
		  
		  if(p.length==11){
			  console.log('sss')
			  return this.$starPhone(p)
		  }else{
			  return p
		  }
		  
	  },
	  // 
	  postQualifications(arr){
		  
		  postQualifications({qualifications:arr})
			  .then(res=>{
				 if(res.msg=='提交成功'){
					 Toast.success(res.msg);
					
					 
				 }else{
					 Toast.fail(res.msg);
				 }
			  })
	  },
	  // 增加资质
	  add(){
		  this.qualifications.push('')
	  },
	  // 删除资质
	  delClick(i){
		  this.qualifications.splice(i,1)
		  console.log(this.qualifications)
	  },
	  // 完成
	  complete(){
		  this.isdel=false
		  let quali=this.qualifications.toString()
		  
		  this.postQualifications(quali)
	  },
	  // 删除
	  del(){
		  this.isdel=true
	  },
	  // 资质获取焦点
	  focus(i){
		  this.isfocus=true
		  this.isfocusIndex=i
	  },
	  // 失去焦点
	  blur(i){
		   this.isfocus=false
		   this.isfocusIndex=i
	  },
	  // 跟改所在城市
	  city(index){
		if(this.iscity==1){
			// 选择省份
			this.provinceid=this.list[index].provinceid
			this.cityList(this.list[index].provinceid)
			postUserInfo({type:'user_provinceid',value:this.provinceid})
			this.iscity=2
			console.log(this.provinceid,this.cityValue)
		}else if(this.iscity==2){
			this.cityValue=this.list1[index].cityid
			this.postUserInfo('user_cityid',this.cityValue,true)
			// 选择城市
			// this.iscity=1
			// this.list1=this.getgetCities
		}
	  },
	  // 列表更新
	  onLoad() {
		
		let index=this.list1.length
		 // this.list1.length==3?index=4:index=this.list1.length
		   // 异步更新数据
		 
		  setTimeout(() => {
			for (let i = 0; i < index; i++) {
			  this.list.push(this.list1[i]);
			}
		  
			// 加载状态结束
			this.loading = false;
			// 数据全部加载完成
			if (this.list.length >= this.list1.length) {
			 this.finished = true;
			}
		  }, 500);
		},
	  miBilecode(){
		  
		  if(this.Daojis<60)return
		
			getMobileCode({user_phone:this.usering.user_phone}).then(res=>{
			  // console.log(res)
			  if(res.code==1){
				  this.dao()
				  Toast.success(res.msg);
			  }
		  })
	  },
	  dao(){
		  this.Daojis = 60
		  // console.log(this.Daojis)
		  let set = setInterval(()=>{
				 if(this.Daojis==1){
					 this.Daojis=60
					 // localStorage.setItem('Daojis',this.Daojis)
					 clearInterval(set)
				 }else{
					 this.Daojis--
					 // localStorage.setItem('Daojis',this.Daojis)
					 }
		  					 // console.log(this.Daojis)
		  },1000)
	  },
	  miBilecode1(){
		  console.log()
			 let formatPhone=0
			 if(this.newPhone.indexOf('*')==-1){
			 			  formatPhone=this.newPhone
			 }else{
			 			  formatPhone = this.$overPhone(this.newPhone,this.usering.user_phone)
			 } 
		 
		  
		  if(this.Daojis<60)return
		  // console.log(Daojis)
		 
		  getMobileCode({user_phone:formatPhone}).then(res=>{
			  // console.log(res)
			  
			  if(res.code==1){
				   this.dao()
				   Toast.success(res.msg);
			  }else{
					Toast(res.msg);
			  }
		  })
	  },
	  postUserInfo(type,value,isgo){
		  postUserInfo({type:type,value:value})
		  .then(res=>{
			 if(res.msg=='修改成功'){
				 Toast.success(res.msg);
				 // isgo 是否返回上一页
				 if(isgo){
					 setTimeout(()=>{
					 	this.$router.go(-1)
					 },2000)
				 }
				 
			 }else{
				 Toast.fail(res.msg);
			 }
		  	
		  })
	  },
	  postSetUserPhone(user_phone,code){
	  		  postSetUserPhone({user_phone:user_phone,code:code})
	  		  .then(res=>{
	  			 if(res.msg=='保存成功'){
	  				 Toast.success(res.msg);
	  				 setTimeout(()=>{
	  				 	this.$router.go(-1)
	  				 },2000)
	  			 }else{
	  				 Toast.fail(res.msg);
	  			 }
	  		  	
	  		  })
	  },
	  postChangeClientUserscode(){
		  if(this.new_user_passwd===this.new_user_passwd1){
		  postChangeClientUserscode({old_user_passwd:this.old_user_passwd,new_user_passwd:this.new_user_passwd}).then(res=>{
			  
			  if(res.code==1){
				  Toast('修改成功')
				  setTimeout(()=>{
					  this.$router.go(-1)
				  },1000)
			  }else{
				  Toast(res.msg)
			  }
		  })
		  }else{
			  Toast('两次密码不相同')
		  }
	  },
	 seach(){
		 console.log(this.$route.query.type1)
		 switch (this.$route.query.type1){
		 	case '修改真实姓名':
				
				this.postUserInfo('user_name',this.usering.user_name,true)
		 		break;
			case '修改性别':
				
				this.postUserInfo('user_sex',this.usering.user_sex,true)
				break;
			case '绑定手机号码':
				
				this.postSetUserPhone(this.newPhone,this.code,true)
				break;
			case '修改手机号码':
				
				this.postSetUserPhone(this.newPhone,this.code,true)
				break;
			case '修改签名':
				
				this.postUserInfo('user_signature',this.usering.user_signature,true)
				break;
			case '修改个人简介':
				
				this.postUserInfo('user_info',this.usering.user_info,true)
				break;
			case '修改所属机构':
				
				this.postUserInfo('institution',this.usering.institution,true)
				break;
			case '修改密码':
				
				this.postChangeClientUserscode()
				break;
			case '设置密码':
				
				this.postClientUserscode()
				break;
			case '修改导师资质':
				
				let quali=this.qualifications.toString()
				
				this.postQualifications(quali)
				setTimeout(()=>{
					this.$router.go(-1)
				},3000)
				break;
		 	default:
		 		break;
		 }
	 },
	 postClientUserscode(){
		 let formatPhone=0
		 if(this.newPhone.indexOf('*')==-1){
			  formatPhone=this.newPhone
		 }else{
			  formatPhone = this.$overPhone(this.newPhone,this.usering.user_phone)
		 } 
		 if(this.new_user_passwd1==this.new_user_passwd){
		 let arr={
			 mobile:formatPhone,
			 code:this.code,
			 user_passwd:this.new_user_passwd
		 }
		 console.log(arr)
		 postClientUserscode(arr).then(res=>{
			 Toast(res.msg)
			 if(res.code==1){
				 setTimeout(()=>{
				 	this.$router.go(-1)
				 },1000)
			 }
		 })
		 }else{
			 Toast('密码不一致')
		 }
	 },
	 cityList(Cities){
		
		 if(Cities){
			 console.log('进来了')
			// 获取城市
			getCities({provinceid:Cities}).then(res=>{
					this.getgetCities=res.data
					this.list1= this.getgetCities
					this.cityValue=this.list1[0].cityid
					// postUserInfo({type:'user_cityid',value:this.cityValue})
			}) 
		 }else{
			// 获取省份
			getProvinces().then(res=>{
					  this.getProvinces=res.data
							  this.list1=this.getProvinces
			}) 
		 }
		 
	 }
  },
  mounted() {
	
	  this.title=this.$route.query.type1
	  console.log(this.title)
	  // 在修改城市页面才会获取数据
	  if(this.title=='修改所在城市'){
	  	this.cityList()
	  }
	  // if(localStorage.getItem('Daojis')!==60){
		 //  this.dao()
	  // }
	  user().then(res=>{
	  	this.usering=res.data
		if(this.$route.query.type1=='修改手机号码'){
			this.newPhone = ''
			this.usering.user_phone=''
		}else{
			this.newPhone = this.starPhone(res.data.user_phone)
		}
		this.qualifications=res.data.qualifications
		console.log(this.qualifications)
	  })
	  	document.title = this.$route.query.type1;   
  }
}
</script>
<style lang="scss">
	.van-cell:not(:last-child)::after{
		left:0;
		width: 95%;
		margin: 0 auto;
	}
	.van-field__control{
		color: #919CB0;
	}
	.focus{
		.van-field__control{
			color: #28334A;
			font-weight: bold;
		}
	}
	.setMaterial{
		.box{
			padding: 2.625rem 0.9375rem;
			background-color: #fff;
			.van-col{
				min-height: 36px;
				line-height: 36px;
				white-space: nowrap;
			}
			.inputBox{
				background-color: #F4F4F4;
				color: '#28334A';
				padding:0 13px;
				height: 36px;
				line-height: 36px;
				width: 100%;
				border-radius: 0.375rem;
			}
			.abs{
				position: absolute;top: 50%;
				right: 10px;
				border: 1px solid #767E8D;
				color: #767E8D;
				font-size: 12px;
				min-width: 5rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.5rem;
				transform: translateY(-50%);
				border-radius: 6px;
				padding: 0 5px;
				
				
			}
			.abs1{
				border-color:#0081FF ;
				color: #0081FF;
			}
		}
		.icon_del{
			position: absolute;
			left: -33px;
			top: 50%;
			transform: translateY(-50%);
		}
		
	}
</style>
