<template>
	<div class="user_pic_style">

		<van-cell is-link @click="showPopup1(usering.user_head_img)" style="height: 60px;align-items: center;">
			<div class="color_hei" slot="title" style="text-align: left;">头像</div>
			<div slot='default'>
				<img :src="usering.user_head_img" alt="" style="width: 40px;height: 40px;border-radius: 50%;">
			</div>
		</van-cell>
		<van-cell is-link @click="showPopup('修改真实姓名')" style="height: 50px;align-items: center;">
			<div slot="title" class="color_hei" style="text-align: left;">真实姓名</div>
			<div slot='default'>
				<span v-text="usering.user_name"></span>
			</div>
		</van-cell>
		<van-cell is-link @click="showPopup('修改性别')" style="height: 50px;align-items: center;">
			<div slot="title" class="color_hei" style="text-align: left;">性别</div>
			<div slot='default'>
				<span v-text="usering.user_sex==1?'男':usering.user_sex==2?'女':''"></span>
			</div>
		</van-cell>
		<van-cell is-link @click="showPopup(usering.user_phone?'修改手机号码':'绑定手机号码')" style="height: 50px;align-items: center;">
			<div slot="title" class="color_hei" style="text-align: left;">手机号码</div>
			<div slot='default'>
				<span v-text="usering.user_phone?$starPhone(usering.user_phone):usering.user_phone"></span>
			</div>
		</van-cell>
		<van-cell is-link style="height: 50px;align-items: center;" @click="show = true">
			<div slot="title" class="color_hei" style="text-align: left;">出生年月</div>
			<div slot='default'>
				<span v-text="usering.user_birthday"></span>
			</div>
		</van-cell>
		<van-cell is-link @click="showPopup('修改所在城市')" style="height: 50px;align-items: center;" :border="false">
			<div slot="title" class="color_hei" style="text-align: left;">所在城市</div>
			<div slot='default'>
				<span v-text="usering.province + usering.city"></span>
			</div>
		</van-cell>
		<van-cell is-link @click="showPopup(usering.is_passwd==0?'设置密码':'修改密码')" style="height: 50px;align-items: center;"
		 v-if="usering.user_type>1">
			<div slot="title" class="color_hei" style="text-align: left;">密码</div>

		</van-cell>
		<div style="height: 10px;background-color: #F7F8FF;"></div>
		<div v-if="usering.user_type>1">
			<van-cell is-link @click="showPopup('修改签名')" style="height: 50px;align-items: center;">
				<div slot="title" class="color_hei" style="text-align: left;">签名</div>

			</van-cell>
			<van-cell is-link @click="showPopup('修改所属机构')" style="height: 50px;align-items: center;">
				<div slot="title" class="color_hei" style="text-align: left;">所属机构</div>

			</van-cell>
			<van-cell is-link @click="showPopup('修改导师资质')" style="height: 50px;align-items: center;">
				<div slot="title" class="color_hei" style="text-align: left;">导师资质</div>

			</van-cell>
			<van-cell is-link @click="showPopup('修改个人简介')" style="height: 50px;align-items: center;">
				<div slot="title" class="color_hei" style="text-align: left;">个人简介</div>

			</van-cell>
		</div>
		<van-popup v-model="show" position="bottom" :style="{ height: '50%' }">
			<van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate" @confirm="confirm"
			 @cancel="cancel" :formatter="formatter" />
		</van-popup>

	</div>
</template>

<script>
	import { user ,postUserInfo} from '@/api';
// 弹窗
import {Toast } from 'vant'
	export default {
  name: 'user_pic_style',
  components: {
  },
  data(){
	  return{
		   minDate: new Date(1900, 0, 1),
		   maxDate: new Date(),
		  usering:{},
		   title:'个人资料',
		   show:false,
		   date: '',
		   currentDate: new Date()
	  }
  },
  methods:{
	  /* 修改个人资料接口函数*/
	  postUserInfo(type,value){
	  		  postUserInfo({type:type,value:value})
	  		  .then(res=>{
	  			 if(res.msg=='修改成功'){
	  				 Toast.success(res.msg);
	  				 // setTimeout(()=>{
	  				 // 	this.$router.go(-1)
	  				 // },2000)
					 this.user()
	  			 }else{
	  				 Toast.fail(res.msg);
	  			 }
	  		  	
	  		  })
	  },
	  // 确认日期按钮
	  confirm(res){
		 let time = this.formatDate(res)
		 this.show=false
		 this.postUserInfo('user_birthday',time)
		 console.log(time)
	  },
	  // 把选择器加上年月日
	    formatter(type, value) {
	        if (type === 'year') {
	          return `${value}年`;
	        } else if (type === 'month') {
	          return `${value}月`
	        }
			else if (type === 'day') {
			  return `${value}日`
			}
	        return value;
	      },
		  // 取消按钮
	  cancel(){
		  this.show=false
	  },
	  // 头像点击 
	  showPopup(type){
		  console.log(type)
			this.$router.push({name:'setMaterial',query:{ type1:type}})
		// this.$router.push({ name: 'news', params: { userId: 123 }})
	  },
	  showPopup1(type){
	  			this.$router.push({name:'setHead',query:{ type1:type}})
	  		// this.$router.push({ name: 'news', params: { userId: 123 }})
	  },
		formatDate(date) {
		let mouth =  date.getMonth()+1
		let day= date.getDate()
		mouth= mouth>10?mouth:'0'+mouth
		day=day>=10?day:'0'+day
		console.log(mouth,day)
		return `${date.getFullYear()}-${mouth}-${day}`;
		},
		onConfirm(date) {
		this.show = false;
		this.date = this.formatDate(date);
		console.log(this.date)
	 },
	 user(){
		 user().then(res=>{
		 	this.usering=res.data
			
		 })
	 }
	 
  },
  mounted() {
  	document.title = this.title;   
	this.user()
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.van-cell:not(:last-child)::after {
		left: 0;
		width: 95%;
		margin: 0 auto;
	}

	.user_pic_style {
		background-color: #fff;
		min-height: 100vh;
	}
</style>
