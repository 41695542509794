<template>
  <div class="letterList">
    <div v-if="!count" class="default">
      <img
        src="../../assets/default/icon_zanwusx@2x.png"
        alt=""
        class="default_img"
      />
      <p>暂无私信</p>
    </div>
    <div v-if="count">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=" "
        @load="onLoad"
      >
        <van-cell
          v-for="(item, index) in list"
          :key="index"
          style="padding: 0;"
        >
          <div slot="default" class="flex list" @click="letterClick(index)">
            <div
              class="list_img list_img_pai "
              :class="['list_img_' + item.ranking]"
            >
              <van-image
                width="41"
                height="41"
                :src="item.user_head_img"
                radius="50%"
              />
              <!-- item.unread_num>10? ' ': -->
              <span
                class="dot"
                v-text="item.unread_num > 100 ? '' : item.unread_num"
                :class="item.unread_num < 10 ? ' ' : 'dot10'"
                v-if="item.unread_num"
              ></span>
            </div>
            <div style="width: 100%;">
              <div class="list_title" style="width: 100%;">
                <span v-text="item.user_name" style="font-size: 15px;"></span>
                <span
                  style="float: right;color: #C6C8CA;font-size: 0.75rem;font-weight: 100;"
                  v-text="item.createtime"
                ></span>
              </div>

              <div class="flex" style="justify-content: space-between;">
                <span v-text="item.content" style="color: #919CB0;"></span>
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
      <div class="bottom">
        <div
          class="bottom_input"
          @click="whole"
          :style="!isunread_num ? 'background:rgba(198,200,202,1);' : ''"
        >
          全部已读
        </div>
      </div>
    </div>

    <!-- 弹窗内容 -->
    <!-- <van-popup v-model="isAreaList" position="bottom">
			<div style="width: 100%;">
				<van-area :area-list="areaList" :columns-num="2" title="地址" @confirm="confirm" @cancel="cancel" />
			</div>
		</van-popup> -->
    <!-- <van-calendar v-model="isdata" @confirm="onConfirm" color="#0081FF" /> -->
    <!-- <van-calendar v-model="isdata_Section" type="range" @confirm="onConfirm_Section" color="#0081FF" /> -->
    <!-- <transition name="van-slide-up">
		  <div v-show="isAreaList" style="z-index: 3;position: fixed;bottom: 0;left: 0;width: 100%; ">
			 
		  </div>
		</transition> -->
  </div>
</template>

<script>
import { getBanner, getLetterLists, postLetterReadStatus } from "@/api";
// eslint-disable-next-line no-unused-vars
import areaList from "../../area.js";
export default {
  name: 'letterList',
  components: {},
  data() {
    return {
      isunread_num: false,
      /*未读的值*/
      unread_num: this.$route.query.unread_num,
      // 控制搜索显示隐藏
      visible: false,
      title: '私信',
      images: [
        "https://img.yzcdn.cn/vant/apple-1.jpg",
        "https://img.yzcdn.cn/vant/apple-2.jpg"
      ],
      total_number: 100,
      signUp_number: 100,
      student_number: 1000,
      /*list为放到页面渲染的，list1为请求的所有数据做懒加载处理*/
      list: [],
      /**
       * identity:身份
       * autograph：签名
       * follow：关注
       * name：姓名
       * img：头像
       * addres：地址
       * company：公司
       *
       * */
      list1: [],
      loading: false,
      finished: false,
      count: 1,
      page: 1,
      limit: "8",
      secah: {}
    };
  },
  watch: {
    value(a, b) {
      console.log(a, b);
      this.page = "1";
      this.list = [];
      (this.loading = false),
        (this.finished = false),
        this.getLetterLists(this.secah, {
          page: String(this.page),
          limit: this.limit,
          user_name: a
        });
    }
  },
  methods: {
    whole() {
      console.log(this.list);
      let arr = [];
      this.list.forEach((item, index) => {
        arr.push(item.leter_id);
      });
      postLetterReadStatus({ leter_id: arr }).then(res => {
        console.log(res);
		if(res.code==1){
			this.unread_num = 0;
			this.list.forEach((item, index) => {
			  this.list[index].unread_num = 0;
			});
			this.isunread_num = false;
		}
      });
    },
    letterClick(i) {
      let arr = [];
      arr.push(this.list[i].leter_id);
      postLetterReadStatus({ leter_id: arr }).then(res => {
        console.log(res);
      });
      localStorage.setItem("leter_id", this.list[i].leter_id);
      this.$router.push({
        path: "/letter",
        query: { id: this.list[i].leter_id, name: this.list[i].user_name }
      });
    },
    getFocus() {
      window.scroll(0, 0);
    },
    // 短信
    getLetterLists(issecr, obj) {
      // console.log(obj)
      getLetterLists(obj).then(res => {
        this.count = res.data.count;
        if (res.data && this.list.length < this.count) {
          if (issecr) {
            this.list = res.data.list ? res.data.list : [];
          } else {
            this.list.push(...res.data.list);
          }
          // 加载状态结束
          this.loading = false;

          if (this.list.length >= this.count) {
            this.finished = true;
          }
        } else {
          this.finished = true;
          this.list = [];
        }
        this.list.forEach((item, i) => {
          if (item.unread_num) {
            this.isunread_num = true;
          }
        });
      });
    },

    // 日期区间选择
    data_SectionClick() {
      if (this.activation !== "开课时间段") return;
      this.isdata_Section = true;
    },
    onConfirm_Section(res) {
      this.isdata_Section = false;

      this.oneDay1 = this.formatDate(res[0]);
      this.oneDay2 = this.formatDate(res[1]);
    },
    // 单个日期选择
    dataClick() {
      if (this.activation !== "开课日期") return;
      this.isdata = true;
    },
    formatDate(date) {
      console.log(date);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.isdata = false;
      this.oneDay = this.formatDate(date);
    },
    // 选择城市做是否选择处理
    provinceClick() {
      if (this.activation !== "城市") return;
      this.isAreaList = true;
    },
    open() {
      this.isopen = !this.isopen;
    },
    onLoad() {
      // 每页条数
      console.log("sss");
      // 页码
      getLetterLists({ page: this.page,limit: this.limit}).then(res => {
		res.data=res.data ? res.data : {
			list:[],
			count:0
		};
		if(res.code==1){
			this.page++
			this.count = res.data.count;
			this.list.push(...res.data.list);
			this.list.forEach((item, i) => {
			  if (item.unread_num) {
			    this.isunread_num = true;
			  }
			});
			// 加载状态结束
			this.loading = false;
			
			if (this.list.length >= this.count) {
			  this.finished = true;
			}
		}else{
			this.count=0
			this.loading = false;
			 this.finished = true;
			this.list=[]
		}
		
      
       
      });
    },
    activationClick(text) {
      this.activation = text;
    },
    cancel() {
      console.log("222");
      this.isAreaList = false;
    },
    confirm(res) {
      // console.log(res)
      this.province = res[0].name;
      this.city = res[1].name;
      this.isAreaList = false;
    }
  },

  mounted() {
    document.title = this.title;
    
  }
};
</script>
<style lang="scss" type="text/css" scoped>
.letterList {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  padding: 0 15px;
  padding-bottom: 60px;
  .dot10 {
    font-size: 10px !important;
    letter-spacing: 0;
  }
  .van-image__error,
  .van-image__loading {
    top: 0;
  }

  .btnBox {
    .btn {
      width: 7.1875rem;
      height: 5.625rem;
      background: no-repeat center;
      background-size: 109% 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 12px;

      div.title {
        margin-bottom: 6px;
        margin-top: -10px;
      }

      div.btn_number {
        font-size: 28px;
        font-weight: bold;
      }
    }

    .btn1 {
      background-image: url(../../assets/Train/bg_yellow@2x.png);
    }

    .btn2 {
      background-image: url(../../assets/Train/bg_red@2x.png);
    }

    .btn3 {
      background-image: url(../../assets/Train/bg_blue@2x.png);
    }
  }

  .list_title {
    justify-content: space-between;

    // margin: 20px 0 20px;
    .list_title_left {
      width: 0.1875rem;
      height: 1.125rem;
      background: #0081ff;
      border-radius: 0 0.09375rem 0.09375rem 0;
      margin-right: 0.75rem;
    }

    .list_title_img {
      width: 0.4375rem;
      height: 0.6875rem;
      margin-right: 0.90625rem;
      margin-left: 0.28125rem;
    }

    .left {
      font-size: 1.125rem;
      color: #28334a;
      font-weight: bold;
    }

    .right {
      font-size: 0.9375rem;
      color: #0081ff;
    }
  }

  .list {
    flex-wrap: inherit;
    padding: 17px 0 10px;
    border-bottom: 1px solid #e5e5e5;

    .list_img {
      margin-right: 0.71875rem;
      position: relative;
    }

    .dot {
      position: absolute;
      top: 0px;
      right: -1px;
      background: red;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      color: #fff;
      font-size: 0.75rem;
      line-height: 16px;
      text-align: center;
    }

    .list_img:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 6px;
      right: 0;
      width: 1.375rem;
      height: 1.25rem;
      background: no-repeat center;
      background-size: 100% 100%;
    }

    .list_img.list_img_1:after {
      background-image: url(../../assets/Train/icon_1@2x.png);
    }

    .list_img.list_img_2:after {
      background-image: url(../../assets/Train/icon_2@2x.png);
    }

    .list_img.list_img_3:after {
      background-image: url(../../assets/Train/icon_3@2x.png);
    }

    .van-image__loading {
      top: 0;
    }

    .list_title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-weight: bold;
      font-size: 0.9375rem;
      color: #28334a;
    }

    .text {
      font-size: 0.75rem;
      color: #919cb0;
      margin-left: 0.5rem;
    }

    .btnimg {
      width: 3.3125rem;
      height: 1.625rem;
      text-align: center;
      line-height: 1.625rem;
      background: no-repeat center;
      background-size: 100% 100%;
      font-size: 0.6875rem;
      color: #fefefe;
    }

    .btnimg1 {
      background-image: url(../../assets/Train/label_blue@2x.png);
    }

    .btnimg2 {
      background-image: url(../../assets/Train/label_red@2x.png);
    }

    .btnimg3 {
      background-image: url(../../assets/Train/label_gray@2x.png);
      color: #919cb0;
    }

    .btnimg_span {
      margin-right: -5px;
    }
  }

  .bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    height: 82px;
    // padding: 12px 0;
   box-shadow:0px -3px 5px 0px rgba(145,156,176,0.1);

    .bottom_input {
      width: 75%;
      height: 2.625rem;
      margin: 0 auto;
      background: #0081ff;
      border-radius: 30px;
      position: relative;
      text-align: left;
      line-height: 2.625rem;
      text-align: center;
      color: #fff;
      transform: translateY(50%);
      // padding-left: 15px;

      .bottom_input_img {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .Slide_up {
    z-index: 2;
    position: fixed;
    bottom: 0;
    height: 90vh;
    background: #fff;
    width: 100%;
    padding: 0.9375rem;
    text-align: left;

    // overflow: auto;
    .Slide_up_header {
      position: relative;
      font-size: 14px;
      text-align: center;

      .Slide_up_img {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }

    .btn {
      border-radius: 0.375rem;
      padding: 9px 13px;
      background: #f4f4f4;
      color: #919cb0;
      border: 1px solid transparent;
      margin-right: 10px;
      margin-top: 1rem;
    }

    .btn.activation {
      border-color: #0081ff;
      background: #ffffff;
      color: #0081ff;
    }

    .Slide_up_title {
      margin: 27px 0 0;
    }

    .btnSeach {
      width: 8.75rem;
      height: 42px;
      text-align: center;
      line-height: 42px;
      background: #0081ff;
      color: #fff;
      margin: 20px auto;
      border-radius: 21px;
    }
  }
}
</style>
