<template>
	<div class="ylc_release">
		<div style="position: fixed;top: 0;left: 0;width: 100%;height: 50px;background-color: #fff;z-index: 99;">
			<van-tabs v-model="active" style="padding: 0 80px;">
				<van-tab title="发布"></van-tab>
				<van-tab title="已发布"></van-tab>
			</van-tabs>
		</div>
		<div style="height:50px"></div>
		<!-- <van-tabs v-model="active"> -->
		<div class="van-tab1" v-if="active==0">
			<div class="flex explain" style="font-weight: bold;">
				<div class="left">发布前请先阅读发布须知说明，避免违规</div>
				<div class="right" @click="show=true">发布须知>></div>
			</div>
			<div class="box">
				<div style="margin: 28px 0 20px;">标题</div>
				<van-field maxlength="20" v-model="message" rows="2" :autosize="{ minHeight: 52 }" type="textarea" placeholder="标题将会公开显示，请勿填写不相关的内容，20字以内" />
			</div>
			<div style="margin-top: 28px;" class="box">
				<van-row class="flex">
					<van-col span="6">
						演练分类
					</van-col>
					<van-col span="18">
						<div class="option">
							<select :name="opValue" v-model="opValue" :style="opValue=='请选择'?'color: #919CB0;':''">
								<option value="请选择">请选择</option>
								<option :value="item.typename" v-for="(item,i) in optionArr" :key="i" v-text="item.typename"></option>
							</select>
						</div>
					</van-col>

				</van-row>
			</div>
			<div style="margin-top: 28px;" class="box">
				<van-row class="flex">
					<van-col span="6">
						上传视频
					</van-col>
					<van-col span="18" style="position: relative;display: flex;">
<!-- 						<img src="coverImgUrl" alt=""  style="max-width:calc(100% - 80px ) ; text-align: left;height: 200px;" @click="bo"  v-if="fileUrl&&!loadding&&!isplay">
						<img src="../../assets/user/bofan.png" alt=""  class="conter" style="width: 50px;height: 50px;" @click="bo"  v-if="fileUrl&&!loadding&&!isplay"> -->
						<!-- :src="fileUrl" -->
						<video src id="videoId"  controls webkit-playsinline playsinline="true"
						 style="text-align: left;height: 200px;max-width: 100%;" v-if="fileUrl&&!loadding" ></video>
						<div class="loaddingBox flex" v-if="loadding">
							<div class="loadding flex" style="color: #fff;    justify-content: center;flex-flow: column;">
								<div>正在上传</div>
								<div v-text="complete" style="margin-top: 20px;"></div>
							</div>
							<div class="loaddingBtn" @click="cancel"> 取消上传</div>
						</div>
						<form v-if="!loadding&&!fileUrl">
							<label>
								<img src="../../assets/Train/icon_tianjia_200@2x.png" alt="" class='tianjia' v-if="!fileUrl">
								<input type="file" accept="video/*" name="file" @change="uploadUp($event)" enctype="multipart/form-data" style="opacity: 0;visibility: hidden;">
							</label>
						</form>
					</van-col>

				</van-row>
			</div>
			<div class="bottom" style="box-shadow: 1px 5px 10px 1px rgb(145, 156, 176) ;" v-if="!isload">
				<div class="btn" :style="
				message&&
				opValue&&fileUrl?'background-color: #0081FF;':'background-color: #C6C8CA;'
				"
				v-if="!isupload"
				 @click="change">提交</div>
				 <div class="btn" :style="
				 message&&
				 opValue&&fileUrl?'background-color: #0081FF;':'background-color: #C6C8CA;'
				 "
				 v-if="isupload">提交</div>
			</div>
			<van-popup v-model="show">
				<div class="boxMask">
					<div class="title">发布须知</div>
					<p>1、仅能发布自己录制的急救技能操作展示、演
						练相关视频，包括个人技能操作展示、团体急救
						演练展示，不能发布急救知识宣传、讲解等视频；
					</p>
					<p>2、标题请勿填写不相关内容，否则会被下线处理；</p>
					<p>3、录制视频建议录制480P分辨率的视频，视频分辨率过大上传视频的时候需要较多的时间，分辨率过小则会影响观看。</p>
					<div @click="show=false" class="btn1">我已阅读</div>
				</div>
			</van-popup>
			<van-popup v-model="showsecc" style="height: 180px;width: 80%;" :close-on-click-overlay="false">
				<div class="boxMask">
					<div class="titleScee">发布演练视频成功！</div>
					<div @click="btnClick" class="btn">确定</div>
				</div>
			</van-popup>
		</div>
		<div v-if="active==1" class="can-tab2" style="margin-top: 20px;">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
				<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
					<div class="" v-for="(item,i) in list" v-if="list.length">
						<div class="ylcBox">
							<img src="../../assets/bigSink/bg_zhezhao@2x.png" alt="" class="top" style="width: 100%;">
							<div class="filter" :style="'background-image: url('+ item.coverpic +');'" >
							</div>
							<img :src="item.coverpic " alt="" class="bg" v-if="!item.isplay">
							<video :src="item.url" class="bg" v-show="item.isplay" :class="'video'+i" controls style="z-index: 98;"></video>
							<div class="flex top">
								<div><span v-text="item.title"></span></div>
								<div><span v-text="item.type"></span></div>
							</div>
							<div class="flex bot">
								<div>
									<span v-text="item.view_num" class="number_0"></span> 次播放
								</div>
								<div class="time">
									<span v-text="setMinute(item.video_time)" class="number_0" style="color: #fff;"></span>
								</div>
							</div>
							<img src="../../assets/user/bofan.png" alt="" class="conter" @click="bofanClick(i)" v-if="!item.isplay">
						</div>
						<div class="pingBox flex" style="justify-content: space-between;padding: 10px 15px;">
							<div class="flex">
								<div>
								<div class="flex" v-if="item.status==1&&item.title_check==1">
									<img  src="../../assets/bigSink/icon_zhuy_red@2x.png" alt="" class="head" style="margin-right: 8px;">
								
									<span  style="color:#FF524D;font-size: 12px;">标题违规，请重新修改标题</span>
								</div>
								<div class="flex" v-if="item.status==1&&item.url_check==1&&item.state==1">
									<img  src="../../assets/bigSink/icon_zhuy_red@2x.png" alt="" class="head" style="margin-right: 8px;">
								
									<span  style="color:#FF524D;font-size: 12px;">视频内容违规，已下线</span>
								</div>
								</div>
							</div>
							<div class="flex">
								<div class=" btn1" v-if="item.status==1&&item.title_check==1||item.status==1&&item.title_check==0" style="margin-right: 12px;" @click="openPopup(i,'编辑标题',false)">
									标题编辑
								</div>
								<div class=" btn1" v-if="item.status==2||item.url_check==1" style="margin-right: 12px;" @click="openPopup(i,'删除视频',false)">
									删除
								</div>
								<div class=" btn1" v-if="item.status==3" style="margin-right: 12px;" @click="openPopup(i,'删除视频',false)">
									删除
								</div>
								<div class="btn1" style="margin-right: 8px;" v-if="item.status==1&&item.title_check!==1&&item.status==1&&item.url_check!==1" :style="item.status==2?'color:#919CB0;border-color:#919CB0;':'color:#0081FF;border-color:#0081FF;'">
									<span v-if="item.status==2">已下线</span>
									<span @click="openPopup(i,'下线视频',false)" v-if="item.status==1">下线</span>
								</div>
								<div :style="item.status==0?'color: #FF524D;font-size: 12px;':'font-size: 12px;'" v-text="item.status==0?'待审核':item.status==2?'已下线':''"></div>
								<div :style="item.status==1&&item.url_check==1||item.status==1&&item.title_check?'font-size: 12px;':'color: #FF524D;font-size: 12px;'" v-text="item.status==1&&item.title_check||item.status==1&&item.url_check?'已审核':item.status==1&&item.title_check==0||item.status==1&&item.url_check==0?'上线中':''"></div>
								
							</div>
						</div>
						<div style="width: 100%;background-color: #F7F8FF;height: 10px;"></div>
					</div>
				</van-list>
			</van-pull-refresh>
			<!-- <div v-if="!count" style="padding: 0 41px;">
				<img src="../../assets/bigSink/pic_nodota@2x.png" alt="" style="margin-top: 110px;width: 150px;margin-bottom: 40px;">
				<div style="text-align: center;font-size: 13px;">
					听说第一个吃螃蟹的人尝到的味道最鲜美，您来
					发布第一个急救技能操作演练视频吧！
				</div>
			</div> -->
			<van-popup v-model="show1">
				<div><span v-text="type"></span></div>
				<div style="text-align: left;" v-if="type=='下线视频'">
					确定要下线演练视频吗？下线后将不能重新上线。
				</div>
				<div style="text-align: left;" v-if="type=='删除视频'">
					确定要删除演练视频吗？删除视频后将不会显示在当前列表。
				</div>
				<div v-if="type=='编辑标题'">
					<van-field v-model="isvalue" placeholder="请输入用户名" />
				</div>
				<div class="flex" style="justify-content: flex-end;">
					<div style="margin-right: 40px;" @click="show1=false">取消</div>
					<div style="color: #0081FF;" @click="determine">确定</div>
				</div>
			</van-popup>
		</div>

		<!-- </van-tabs> -->
	</div>
</template>

<script>
	// import vConsole from '@/util/vconsole.js'
import {getUserVideoList,postVideoEdit ,postVideoAdd,getVideoTypeList,postFeedbackImg,postVideoImgAndReturnUrl } from '@/api'
import { setMinute } from '@/util/setTime.js'
import { Toast } from 'vant';
export default {
  name: 'ylc_release',
  components: {
  },
  // watch:{
	 //  active(){
		//   console.log(this.active)
		//   if(this.active==1){
		// 	  this.getUserVideoList()
		//   }
	 //  }
  // },
  data(){
	  return{
		  isload:false,
		  isplay:false,
		source:null,//取消上传
		coverImgUrl:'',
		// 下拉刷新
		refreshing: false,
		// 加载状态	
		loading: false,
		finished: false,
		page:1,
		limit:10,
		count:1,

		// 已发布弹窗
		showsecc:false,
		show:false,
		show1:false,
		// 发布弹窗
		show:false,
		active: 0,
		message:'',
		opValue:'请选择',
		optionArr:['心肺复苏和AED','创伤救护','灾难救援','常见急症急救'],
		list:[

		],
		index:'',
		type:'',
		isvalue:'',
		fileUrl:'',
		fileObj:{},
		// 上传状态
		loadding:false,
		// 上传进度
		complete:0,
		data:{},
		isupload:false,
		coverpic:''
	  }
  },
  methods:{
	  getVideoTypeList(){
		  getVideoTypeList().then(res=>{
			  console.log(res.data)
			  this.optionArr=res.data
			  console.log( this.optionArr)
		  })
	  },
	  btnClick(){
		  this.showsecc=false 
		  this.active=1
		  this.message=''
		  this.fileObj=''
		  this.opValue=0
		  this.duration=''
		  this.complete=0
		  this.loadding=false
		  this.fileUrl=''
		  this.onRefresh()
	  },
	  setMinute(time){
	  		  return setMinute(time)
	  },
	  // 播放
	  bofanClick(i){
		  this.list[i].isplay=true
		  let video_id=document.getElementsByClassName( 'video'+i)[0]
		  this.list.forEach((item,index)=>{
			  if(i!==index){
				  this.list[index].isplay=false
			  }
		  })
		  // console.log(video_id[0])
		 video_id.play()
	  },
	  // 编辑和修改
	  postVideoEdit(type,video_id,title){
		  postVideoEdit({type:type,video_id:video_id,title:title}).then(res=>{
			  Toast(res.msg)
			  if(res.code==1){
				  if(type==1){
					this.list[this.index].title=this.isvalue
					this.list[this.index].title_check=0
				  }
				  if(type==2){
				  		this.list[this.index].status=2
				  }
				  if(type==3){
				  		this.list.splice(this.index,1)
				  }
			  }
		  })
	  },
	   onRefresh() {
	        // 清空列表数据
			this.list=[]
			this.page=1
	        this.finished = false;
	        // 重新加载数据
	        // 将 loading 设置为 true，表示处于加载状态
	        this.loading = true;
	        this.onLoad();
	      },
	   onLoad() {
	        // 异步更新数据
	         getUserVideoList({page:this.page,limit:this.limit}).then(res=>{
				 res.data.list.forEach(item=>{
					 item.isplay=false
				 })
				
				 this.list.push(...res.data.list);
				
				 console.log( this.list)
				 this.page++
				 this.count=res.data.count
				  // 加载状态结束
				  this.loading = false;
				  this.refreshing=false
				console.log(this.loading)
				  // 数据全部加载完成
				  if (this.list.length >=  this.count) {
					this.finished = true;
				  }
			    })
	      },
	   
	  // getUserVideoList(){
		 //  getUserVideoList().then(res=>{
			//   this.list=res.data.list
		 //  })
	  // },
	  // 取消事件
	   cancel(){
	         let that = this;
	          if(that.source){//我先判断soucre是否存在，因为如果我打开弹框不作任何操作，点击取消按钮没有这一层判断的话，that.source.cancel('取消上传');会报错。
	            that.source.cancel('取消上传');//"取消上传"这几个字，会在上面catch（）的error中输出的，可以console看一下。
	          }
	          this.loadding=false
	         },
			 // 提交事件
	   change() {
		   let video = document.getElementById('videoId')
		   let _this=this
		   /**
			* ----介于ios没办法直接用上传的方法来拿到封面图---
			* 1.使用了再提交的时候触发事件播放下视频
			* 2.播放已成功立刻停止播放，以便于拿到第一帧
			* 3.拿到第一帧后(图片为base64)转换图片为服务器地址
			* 4.上传视频、标题、封面、分类
			* ---完成整个上传流程----
			* **/
			this.isload=true
		    this.bo(video).then(res1=>{
				video.pause()
				
				_this.getVideoBase641( video).then((res,rest)=>{
					console.log(video)
						postVideoImgAndReturnUrl({imgurl:res}).then(ress=>{
							_this.coverpic=ress.msg
							if(_this.coverpic){
									let duration =''
									
									duration=video.duration
									let cancelToken = _this.$axios.CancelToken;//Axios使我修改axios原型链了。
									_this.source = cancelToken.source();
								if(
										_this.message&&
										_this.opValue&&_this.fileUrl
										&&_this.opValue!='请选择'
								){
										   _this.isupload=true
										  // let video = document.getElementById('videoId')
										  // const video = document.createElement('video');
										  // video.src=_this.fileUrl
										  // video.onloadedmetadata = event => {
											 //  console.log(event)
										  //   const path = event.path || (event.composedPath && event.composedPath());
										  //   duration = path[0].duration;
											_this.loadding=true
											// 上传部分
											let url = '/gzh/backend/public/index.php/api/Video/postVideoAdd' //你的后台上传地址
											let data = new FormData()
											data.append('title', _this.message)
											data.append('file', _this.fileObj)
											data.append('type', _this.opValue)
											data.append('coverpic', _this.coverpic)
											data.append('video_time', duration)
								
											data.append('token', sessionStorage.getItem('token'))
											_this.$axios({
												 url,
												 method: 'post',
												 data,
												 timeout: 60000000,
												 cancelToken:_this.source.token,//取消事件
												 headers: {
												   'Content-Type': 'multipart/form-data',
												   'Authorization': sessionStorage.getItem('token')
												 },
												//原生获取上传进度的事件
												/**
												 * 因为上传监听事件的百分之百不一定代表文件上传成功。所以把百分比压在99
												 * */
												 onUploadProgress:function(progressEvent){ 
													_this.complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
													 console.log(_this.complete)
													_this.complete=_this.complete=='100%'?'99%':_this.complete
													
												}
												}).then(res => {
													console.log(res)
													if(res.data.code==1){
														_this.isupload=false
														_this.complete='100%'
														_this.showsecc=true
														
													}
													this.isload=false
												}).catch(err => {
													 if(_this.complete=='99%'){
														 _this.isupload=false
														 _this.complete='100%'
														 _this.showsecc=true
													 }else{
														 this.isload=false
														 alert('上传失败')
													 }
												})
										  // };
										  
								}else{
										   Toast('请完善必填项')
								}
							}
						}) 
				})
			})
			   		 
	    },
	  determine(){
		  /**
		   * 编辑标题
			下线视频
			删除视频
		   * */
		  this.show1=false
		  let type=this.type=='编辑标题'?1:this.type=='下线视频'?2:this.type=='删除视频'?3:0
		  let video_id=this.list[this.index].id
		  switch (type){
		  	case 1:
				this.postVideoEdit(type,video_id,this.isvalue)
		  		break;
			
		  	default:
				this.postVideoEdit(type,video_id)
		  		break;
		  }
		  
	  },
	 openPopup(index,type,is){
		 if(is){
			 
		 }else{
			 console.log(index,type)
			 this.show1=true
			 this.index=index
			 this.type=type
			 this.isvalue=this.list[index].title
		 }
	 },
	 eventTester(e){
		 let Media =document.getElementById('videoId')
	         Media.addEventListener(e,function(){
	             console.log((new Date()).getTime(),e);
	         });
	     },
	 
	  //拿到封面图  
	 getVideoBase641(video) {
		 video.currentTime = 1
			 return new Promise(function (resolve) {
				 let dataURL = '';
				 video.addEventListener('canplay', function () {
					 let canvas = document.createElement("canvas"),
						 width = this.videoWidth, //canvas的尺寸和图片一样
						 height = this.videoHeight;
					 canvas.width = width;
					 canvas.height = height;
					 canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
					 dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
					 resolve(dataURL);
				 });
			 })
		 },
		 // 换成临时路径
		 getFileURL(file) {
			 return new Promise(function (resolve) {
				 var getUrl = null;
				 if (window.createObjectURL != undefined) { // basic
				 		 			getUrl = window.createObjectURL(file);
				 } else if (window.URL != undefined) { // mozilla(firefox)
				 		 			getUrl = window.URL.createObjectURL(file);
				 } else if (window.webkitURL != undefined) { // webkit or chrome
				 		 			getUrl = window.webkitURL.createObjectURL(file);
				 }
				 resolve(getUrl)
			 })
		 },
	 uploadUp(event) {
		// ,file
	       let files = event.target.files;
		   let file = event.target.files[0]
		   let fileUrl = ''
		   let _this=this
		   // let fileSize = (file.size / 1024).toFixed(0);
		   // let filesize = 200000
		   // if (fileSize > filesize) {
		   //     alert('上传文件超出限制大小');
		   //     return false;
		   // }
		  this.getFileURL(files[0]).then(res=>{
			  _this.fileUrl=res
			  _this.fileObj=files[0]
			  if(_this.fileUrl){
				  _this.$nextTick(()=>{
				  var video = document.getElementById('videoId')
				    video.src=res
					})
			  }
		   });//把当前的 files[0] 传进去
		  
		 //    let reader = new FileReader();//新建一个FileReader
			// reader.readAsText(files[0], "UTF-8");//读取文件 
			// reader.onload = function(evt){ //读取完文件之后会回来这里
			// let fileString = evt.target.result; // 读取文件内容
		   // }
	     },
		 bo(video){
			 // let video = document.getElementById('videoId')
			 video.play()
			  return new Promise(resolve => {
			     resolve(video)
			   })
		 },
		findvideocover() {
		        let size = 160
		        // 获取video节点
		        const video = document.createElement('video');
		        video.src=this.fileUrl
		        video.onloadedmetadata = event => {
					event.width = size
					event.height = size
					event.currentTime = 10 // 第一帧
					//创建canvas对象
					const canvas = document.createElement("canvas")
					canvas.width = size
					canvas.height = size
					console.log(size)
					this.$nextTick(()=>{
					  // 利用canvas对象方法绘图
					  canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
					  // 转换成base64形式
					  const img = canvas.toDataURL("image/jpeg") // 这个就是图片的base64
					  this.coverImgUrl = img
					  console.log(this.coverImgUrl)
					})
				}
		        
		      }
  },
  mounted() {
	  this.getVideoTypeList()
  	document.title = '发布演练视频';   
  }
}
</script>
<style lang="scss" type="text/css">
	.ylc_release {
		min-height: 100vh;
		background-color: #fff;
		padding-bottom: 100px;
		.loaddingBox {
			width: 80%;
			justify-content: center;
			flex-flow: column;
		}

		.loadding {
			width: 100px;
			height: 100px;
			background: rgba(0, 0, 0, 1);
			opacity: 0.3;
			border-radius: 6px;
			// position: absolute;
			// top: 28px;
			// left: 60px;

		}

		.loaddingBtn {
			width: 90px;
			height: 31px;
			line-height: 28px;
			background: rgba(255, 255, 255, 1);
			border: 1px solid rgba(0, 129, 255, 1);
			border-radius: 15px;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(0, 129, 255, 1);
			margin-top: 20px;
			text-align: center;
		}

		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}

		.van-tab {
			min-width: 85px;
		}

		.van-tab__text {
			font-weight: bold;
		}

		.van-tab--active {
			font-size: 17px;
		}

		.topBox {
			padding: 15px;
			background-color: #fff;

		}

		.van-tab--active {
			font-weight: Bold !important;
		}

		.van-tab1 {

			// 修改弹窗
			.van-popup--center {
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				background: transparent;
				padding: 15px;

				.boxMask {
					color: #28334A;
					width: 100%;
					height: 100%;
					background-color: #fff;
					padding: 20px 15.5px 28px;
					position: relative;
					border-radius: 6px;

					.titleScee {
						width: 100%;
						height: 70%;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 18px;
						font-family: PingFang SC;
						font-weight: bold;
						color: rgba(40, 51, 74, 1);
					}

					.title {
						margin-bottom: 28px;
						font-weight: bold;
						font-size: 18px;
					}

					p {
						text-align: justify;
						margin-bottom: 28px;
					}

					.btn1 {
						box-sizing: border-box;
						width: 140px;
						height: 42px;
						line-height: 42px;
						position: absolute;
						bottom: 28px;
						left: 50%;
						transform: translateX(-50%);
						background-color: #0081FF;
						color: #fff;
						border-radius: 50px;
					}

					.btn {
						font-family: PingFang SC;
						font-weight: bold;
						color: rgba(0, 129, 255, 1);
						height: 30%;
						line-height: 30%;
						position: absolute;
						bottom: 0;
						left: 0;
						border-top: 1px solid #E5E5E5;
						text-align: center;
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}

		.can-tab2 {
			.van-popup--center {
				width: 300px;
				height: 190px;
				padding: 28px 20px;
				border-radius: 6px;
				display: flex;
				flex-flow: column;
				justify-content: space-between;
			}
		}

		.van-field {
			background-color: #F4F4F4;
			border-radius: 6px;

		}

		.explain {
			width: 100%;
			background-color: #F7F8FF;
			padding: 5px 15px;
			justify-content: space-between;
			margin-top: 20px;

			.left {
				font-size: 10px;
				text-align: left;
				// left: -20px;
				position: relative;
			}

			.right {
				font-size: 12px;
				transform: scale(.8);
				color: #FF524D;
			}
		}

		.box {
			padding: 0 15px;
			text-align: left;
		}

		.option {
			/*用div的样式代替select的样式*/
			// margin: 100px;
			width: 100%;
			height: 36px;
			/*border-radius: 5px;*/
			/*盒子阴影修饰作用,自己随意*/
			/* box-shadow: 0 0 5px #ccc;*/
			position: relative;
		}

		.option select {
			border-radius: 6px;
			background-color: #F4F4F4;
			/*清除select的边框样式*/
			border: none;
			/*清除select聚焦时候的边框颜色*/

			/*将select的宽高等于div的宽高*/
			width: 100%;
			height: 34px;
			line-height: 36px;
			/*隐藏select的下拉图标*/
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			/*通过padding-left的值让文字居中*/
			padding-left: 20px;
			outline: #0081FF;
		}

		/*使用伪类给select添加自己想用的图标*/
		.option:after {
			content: "";
			width: 17px;
			height: 17px;
			background: url(../../assets/Train/icon_xiala_nor@2x.png) no-repeat center;
			background-size: 100% 100%;
			/*通过定位将图标放在合适的位置*/
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			/*给自定义的图标实现点击下来功能*/
			pointer-events: none;
		}

		.tianjia {
			width: 100px;
			height: 100px;
			position: absolute;
			top: 28px;
			left: 60px;
		}

		.ylcBox {
			width: 100%;
			height: 211px;
			position: relative;
			overflow: hidden;

			.top {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 5;

			}

			.bot {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 6;
				font-size: 12px;

			}

			.bg {
				// width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%	);
				
			}

			.flex {
				position: absolute;

				z-index: 6;
				color: #fff;
				justify-content: space-between;
				width: 100%;
				padding: 10px 15px;
			}

			.conter {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 50px;
				height: 50px;
			}

			.time {
				background-color: rgba(0, 0, 0, .5);
				border-radius: 50px;
				padding: 1px 7px;
				transform: scale(.9);
			}
		}

		.pingBox {
			.head {
				border-radius: 50%;
				width: 12px;
				height: 12px;



			}
		}

		.btn1 {
			min-width: 60px;
			height: 26px;
			padding: 0 10px;
			text-align: center;
			line-height: 24px;
			border: 1px solid #0081FF;
			border-radius: 50px;
			color: #0081FF;
			font-size: 12px;
		}
		.filter{
			// width: 100%;
			height: 100%;
			filter: blur(3px);
			background-position: -50px;
			overflow: hidden;
		}
	}
</style>
