<template>
	<div class="FBArticle">
		<div style="position: fixed;top: 0;left: 0;width: 100%;height: 50px;background-color: #fff;">
			<van-tabs v-model="active" style="padding: 0 80px;">
				<van-tab title="发布文章"></van-tab>
				<van-tab title="发布分享"></van-tab>
			</van-tabs>
		</div>
		<div v-show="active==0" style="		padding-top: 50px;">
			<div class="bigBox">
				<div class="title">文章标题</div>
				<div class="inputBox1">
					<input type="text" placeholder="请输入文章标题（不少于4个字）" v-model="wen_title">
				</div>
				<div class="content" @click="showClick">
					<div class="hui" v-html="message1?message1:'<span>请输入正文内容</span>'"></div>
				</div>

			</div>
			<div style="width: 100%;height: 10px;background-color: #F7F8FF;"></div>
			<div class="bigBox">
				<div>
					添加封面图片<span class="hui" style="font-size: 12px;">（只能添加一张图片，可不添加）</span>
				</div>
				<div style="margin-top: 20px;margin-bottom: 20px">
					<van-uploader :max-count="1" v-model="imgFlie1" style="display: block;" :after-read="afterReadlj"  class="uploader" @delete="deleteClick" />
				</div>
			</div>
			<!-- btnYuLan btnFaBu -->
			<div class="bottom flex">
				<div class="btn1" style="width: 140px;" :class="
				wen_title&&
				message1?'btnYuLan':''
				"
				@click="releaseClick(2)">预览</div>
				<div class="btn1" style="width: 140px;" @click="releaseClick(1)"
				:class="
				wen_title&&
				message1?'btnFaBu':''
				"
				>确认发布</div>
			</div>
			<van-popup v-model="show" closeable position="bottom" :style="{ height: '92%' }">
				<div style="margin: 16px 0 8.5px;">输入正文</div>

				<div class="vanPopupBox" >
					<Editor :value="message1" @input="onEditorChangeInput"></Editor>

				</div>
			</van-popup>
		</div>
		<div v-show="active==1" class="active1"  style="		padding-top: 50px;">
			<div class="bigBox">
				<div class="title">分享的感想</div>
				<div class="inputBox1 fx">
					<van-field v-model="message2" rows="1" :autosize="{ minHeight: 26 }" type="textarea" placeholder="请输入您的分享感想" />
				</div>
				<div class="title">文章标题</div>
				<div class="inputBox1  bt">
					<van-field v-model="message3" rows="1" :autosize="{ minHeight: 26 }" type="textarea" placeholder="请输入文章标题（不少于4个字）" />
				</div>
				<div class="title">网址</div>
				<div class="inputBox1 wz">
					<van-field v-model="message4" rows="1" :autosize="{ minHeight: 26 }" type="textarea" placeholder="请在此处粘贴网址链接" />
				</div>

			</div>
			<div style="width: 100%;height: 10px;background-color: #F7F8FF;"></div>
			<div class="bigBox">
				<div>
					添加封面图片<span class="hui" style="font-size: 12px;">（只能添加一张图片，可不添加）</span>
				</div>
				<div style="margin: 20px 0;">
					<van-radio-group v-model="radio" class="flex">
						<van-radio name="2">
							<span :style="radio==2?'color:rgba(0, 129, 255, 1)':'color:rgba(145, 156, 176, 1)'">默认</span>
							<img slot="icon" slot-scope="props" :src="props.checked ? activeIcon : inactiveIcon" class="imgIcon">

						</van-radio>

						<van-radio name="1" checked-color="rgba(0, 129, 255, 1)">
							<span :style="radio==1?'color:rgba(0, 129, 255, 1)':'color:rgba(145, 156, 176, 1)'">自定义</span>
							<img slot="icon" slot-scope="props" :src="props.checked ? activeIcon : inactiveIcon" class="imgIcon">
						</van-radio>
					</van-radio-group>
				</div>
				<div style="margin-top: 20px;margin-bottom: 20px;" class="flex">
					<div style="width: 50%;">
						<img src="../../assets/bigSink/icon_moren200_pre@2x.png" alt="" width="100px" style="display: block;" v-if="radio==2">
						<img src="../../assets/bigSink/icon_moren200_nor@2x.png" alt="" width="100px" style="display: block;" v-if="radio==1">
					</div>
					<div style="width: 50%;">
						<img src="../../assets/Train/icon_tianjia_200@2x.png" alt="" width="100px" v-if="radio==2">
						<van-uploader :max-count="1" v-model="imgFlie" style="display: block;" :after-read="afterReadlj" :delete="deleteClick" class="uploader" v-if="radio==1" />
					</div>
				</div>
			</div>
			<div class="bottom flex">
				<div class="btn1" style="width: 140px;" 
				:class="
				message3&&
				message4&&
				message2?'btnYuLan':''"
				@click="releaseClick(2)"
				>预览</div>
				<div class="btn1" style="width: 140px;" @click="releaseClick(1)"
				:class="
				message3&&
				message4&&
				message2?'btnFaBu':''"
				>确认发布</div>
			</div>
		</div>
		
	<!-- 	<form id="uploadImg" style="display: none;">
			<input type="file" name="edit_picture" id="file"></form> -->
	</div>
</template>

<script>
	import { postWeixinAidHotArticleShare,postWeixinAidHotArticle } from '@/api'
	import Editor from '@/components/Editor.vue'
	export default {
  name: 'FBArticle',
  components: {
	  Editor
  },
  watch:{
	imgFlie1(){
		
	}
  },
  data(){
	  return{
		  wen_title:'',
		  imgFlie1:[],
		  imgFlie:[],
		  message1:'',
		  message2:'',
		  message3:'',
		  message4:'',
		  radio: '2',
		inactiveIcon: require('../../assets/Train/icon_selectbox_nor@2x.png'),
		activeIcon: require('../../assets/Train/icon_selectbox_pre@2x.png'),
		 show:false,
		images: [
		  'https://img.yzcdn.cn/vant/apple-1.jpg',
		  'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		active:0,
		// list:[{
		// 	id:1,
		// 	name:'李冰',
		// 	headimg:'https://dss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=1409542008,2775622124&fm=111&gp=0.jpg',
		// 	comment:123,
		// 	seen:123,
		// 	fabulous:123,
		// 	time:'15分钟前',
		// 	follow:true,
		// 	title:'我们定一个时间范畴：立足于可见的数年来看，医疗AI到底能做什么？',
		// 	content:' 在现代社会发展和人类生活新的模式结构下，利在现代社会发展和人类生活新的模式结构下，利在现代社会发展和人类生活新的模式结构下，利',
		// 	img:'https://dss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1320188674,3508605328&fm=26&gp=0.jpg'
		// }],
		// 发布分享或者发布文章的封面
		share_ArticleImgUrl:'',
		
	  }
  },
  watch:{
	  imgFlie1(){
		  if(!this.imgFlie1.length){
			  this.share_ArticleImgUrl=''
		  }
	  },
	  imgFlie(){
	  		  if(!this.imgFlie.length){
	  			  this.share_ArticleImgUrl=''
	  		  }
	  }
  },
  methods:{
	  // 删除预览图片删除后执行函数
	  deleteClick(file){
		 console.log(this.share_ArticleImgUrl)
		 console.log(this.imgFlie1)
	  },
	 // 选择文件加载完成后执行函数
	  afterReadlj(file){
		  console.log(file)
		  var _this=this
		 file.status = 'uploading';
		 file.message = '上传中...';
		 // 如果上传成功
		 if (file.file) {
		 	const data = new FormData()
		 	data.append('imgurl', file.file)
		 	data.append('token', sessionStorage.getItem('token'))
		 	this.$axios.post('/gzh/backend/public/index.php/api/user/postFeedbackImg', data,{
		 					headers:{'Content-Type':'multipart/form-data'}
		 	}).then(function (res) {
				console.log(res.data)
		 			_this.share_ArticleImgUrl=res.data
		 	})
		 	.catch(function (error) {
		 	  console.log(error);
		 	});
		 } else {
		 	// 提示信息，需引入Message
		 	Message.error('图片插入失败')
		 }
		  
	  },
	  releaseClick(type){
		  console.log(this.active)
		  switch (this.active){
		  	case 0:
				let arr1={
					title:this.wen_title,
					content:this.message1,
					imgurl:this.share_ArticleImgUrl,
					type:type
				}
				// let arr2=new FormData()
				// arr2.append('title',this.wen_title)
				// arr2.append('content',this.message1)
				// arr2.append('imgurl',this.imgFlie1.length?this.imgFlie1[0].content:'')
				// arr2.append('type',1)
				// console.log(arr2.getAll())
				 if(type==2){
					 arr1.type=1
					localStorage.setItem('preview',JSON.stringify(arr1))
					if(this.imgFlie1){
						localStorage.setItem('preview_cover',JSON.stringify(this.imgFlie1))
					}
					this.$router.push('preview')
				}else{
					postWeixinAidHotArticle(arr1).then(res=>{
						if(type==1&&res.code==1){
							this.$router.go(-1)
						}
					})
				}
					
		  		break;
			case 1:
			
			let arr={
				share_title:this.message3,
				share_url:this.message4,
				thank:this.message2,
				share_picture_type:this.radio,
				imgurl:this.share_ArticleImgUrl,
				type:type
			}
			switch (type){
				case 1:
				postWeixinAidHotArticleShare(arr).then(res=>{
					if(res.code==1){
						this.$router.go(-1)
					}
				})
					break;
				default:
				arr.type=2
				localStorage.setItem('preview',JSON.stringify(arr))
				if(this.imgFlie1){
					localStorage.setItem('preview_cover',JSON.stringify(this.imgFlie))
				}
				this.$router.push('preview')
					break;
			}
			// postWeixinAidHotArticle(arr).then(res=>{
			// 	if(type==1&&res.code==1){
			// 		this.$router.go(-1)
			// 	}else if(type==2&&res.code==1){
			// 		localStorage.setItem('preview',JSON.stringify(res.data.info))
			// 		this.$router.push('preview')
			// 	}
			// })
				break;
		  }
	  },
	 showClick(){
		 this.show=true
		
	 },
	 onEditorChangeInput(content){
		 this.message1=content
	 }
  },
  mounted() {
  	document.title = '发布'; 
	
	if(JSON.parse(localStorage.getItem('preview'))){
		// 判断是否有这个缓存，更新数据
		// 判断是文章还是分享
		this.active=JSON.parse(localStorage.getItem('preview')).type==2?1:0
		switch (this.active){
			case 0:
			// 文章
			if(localStorage.getItem('preview_cover')){
				this.imgFlie1=JSON.parse(localStorage.getItem('preview_cover'))
			}
			this.wen_title=JSON.parse(localStorage.getItem('preview')).title
			this.message1=JSON.parse(localStorage.getItem('preview')).content
			this.share_ArticleImgUrl=JSON.parse(localStorage.getItem('preview')).imgurl
			
			console.log(this.active)
			if(this.wen_title&&this.message1){
				if(this.imgFlie1){
					localStorage.removeItem('preview_cover')
				}
				localStorage.removeItem("preview")
			}
				break;
			default:
			// 分享
			if(localStorage.getItem('preview_cover')){
				this.imgFlie=JSON.parse(localStorage.getItem('preview_cover'))
			}
			this.message2=JSON.parse(localStorage.getItem('preview')).thank
			this.message3=JSON.parse(localStorage.getItem('preview')).share_title
			this.message4=JSON.parse(localStorage.getItem('preview')).share_url
			this.radio=JSON.parse(localStorage.getItem('preview')).share_picture_type
			
			console.log(this.active)
			if(
			this.message2&&
			this.message3&&
			this.message4&&
			this.radio
			){
				if(this.imgFlie1){
					localStorage.removeItem('preview_cover')
				}
				localStorage.removeItem("preview")
			}
				break;
		}
		
		
	}else{
		console.log('没有')
	}
  }
}
</script>
<style lang="scss" type="text/css">
	.uploader{
		.van-uploader__upload{
			margin: 0;
			width: 100px;
			height: 100px;
			background-color: #F5F4F7;
			border: none;
		}
		.van-uploader__preview-image{
			width: 100%;
			height: 200px;
		}
		.van-uploader__preview{
			margin: 0;
			width: 100%;
		}
		.van-uploader__upload-icon{
			font-size: 30px;
		}
	}
	.ql-snow .ql-picker.ql-expanded .ql-picker-options{
		    // bottom: 100% !important;
			top: auto;
			bottom: 100% !important;
			
	}
	.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter{
		stroke:#666;
	}
	.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected{
		    background: #FFE6A1;
		    border: 1px solid #C7A46E;
		    color: #666;
	}
	.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button{
		margin-right: 4px;
	}
	.FBArticle {
		min-height: 100vh;
		background-color: #fff;
		padding-bottom: 82px;
		.ql-editor{
			padding-top:0 ;
			padding-bottom: 0;
		}
		.ql-editor{
			padding: 0;
		}
		.ql-container{
			padding: 0 10px 0px;
		}
		.quill-editor{
			border: 0;
			padding-bottom: 40px;
		}
		.ql-container.ql-snow{
			border: none;
		}
		.ql-toolbar.ql-snow{
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			border: none;
			border-top: 1px solid #ccc;
			    z-index: 1111;
				background: #fff;
				// padding-bottom: 30px;
				
		}
		.vanPopupBox {
			text-align: left;
			// padding: 0 15px;
			height: calc(100% - 20px - 16px - 8.5px);
			overflow: auto;
			position: relative;
		}

		[class*=van-hairline]::after {
			border: 0;
		}



		.van-radio__label {
			color: rgba(145, 156, 176, 1);
		}

		.wz {
			.van-field__control {
				color: #0081FF;
			}
		}

		.fx {
			.van-field__control {
				color: #767E8D;
			}
		}

		.bt {
			.van-field__control {
				// font-weight: bold;
			}
		}

		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}

		.van-tab {
			min-width: 85px;
		}

		.van-tab__text {
			font-weight: bold;
		}

		.van-tab--active {
			font-size: 17px;
		}

		.topBox {
			padding: 15px;
			background-color: #fff;

		}

		.bigBox {
			width: 100%;
			padding: 15px;
			background-color: transparent;
			text-align: left;

			.title {}

			.inputBox1 {
				padding: 15px 0;
				border-bottom: 1px solid #E5E5E5;

				input {
					// border-left: 2px solid #0081FF;
					padding-left: 2px;
					// height: 17px;
					font-size: 16px;

				}

				input::-webkit-input-placeholder {
					font-size: 16px;
				}

			}

			.content {
				width: 100%;
				min-height: 250px;
				padding: 28px 0px;
				font-size: 15px;
				img{
					max-width: 100%;
				}
			}
		}

		.bottom {
			justify-content: space-around;
			padding: 0 30px;
			box-shadow: 1px 5px 15px 1px #919CB0;

			.btn1 {
				width: 140px;
				background-color: #C6C8CA;
				height: 42px;
				line-height: 42px;
				text-align: center;
				border-radius: 50px;
				color: #fff;
			}
		}

		.active1 {
			.title {
				// margin-top: 27.5px;
			}

			.inputBox1 {
				margin-bottom: 28px;

				.van-cell {
					padding: 0;
				}
			}

			.imgIcon {
				width: 20px;
				height: 20px;
				vertical-align: top;
			}

			.van-radio {
				width: 50%;
			}
		}
		.btnYuLan{
			background:rgba(255,255,255,1) !important;
			border:1px solid rgba(0,129,255,1) !important;
			color:rgba(0,129,255,1) !important;
			}
		.btnFaBu{
			background:rgba(0,129,255,1) !important;
		}
	}
</style>
