export const setTime = function setTime(valueTime) {
	// let valueTime=1585904733000
	valueTime = valueTime * 1000
	//时间显示问题（几天前、几分钟前）
	// Vue.filter('fomatTime', function (valueTime) {

	if (valueTime) {
		var newData = Date.parse(new Date());
		var diffTime = Math.abs(newData - valueTime);

		if (diffTime > 7 * 24 * 3600 * 1000) {
			var date = new Date(valueTime);
			var y = date.getFullYear();
			var m = date.getMonth() + 1;
			m = m < 10 ? ('0' + m) : m;
			var d = date.getDate();
			d = d < 10 ? ('0' + d) : d;
			var h = date.getHours();
			h = h < 10 ? ('0' + h) : h;
			var minute = date.getMinutes();
			var second = date.getSeconds();
			minute = minute < 10 ? ('1' + minute) : minute;
			second = second < 10 ? ('0' + second) : second;
			return y + '-' + m + '-' + d + ' ' + h + ':' + minute;

		} else if (diffTime < 7 * 24 * 3600 * 1000 && diffTime > 24 * 3600 * 1000) {
			// //注释("一周之内");

			// var time = newData - diffTime;
			var dayNum = Math.floor(diffTime / (24 * 60 * 60 * 1000));
			return dayNum + "天前";

		} else if (diffTime < 24 * 3600 * 1000 && diffTime > 3600 * 1000) {
			// //注释("一天之内");
			// var time = newData - diffTime;
			var dayNum = Math.floor(diffTime / (60 * 60 * 1000));
			return dayNum + "小时前";

		} else if (diffTime < 3600 * 1000 && diffTime > 60 * 1000) {
			// console.log(diffTime)
			// //注释("一小时之内");
			// var time = newData - diffTime;
			var dayNum = Math.floor(diffTime / (60 * 1000));
			return dayNum + "分钟前";

		} else if (diffTime < 60 * 1000) {
			// console.log(diffTime)
			// //注释("一小时之内");
			// var time = newData - diffTime;
			var dayNum = Math.floor(diffTime / (1000));
			return dayNum + "秒前";

		}
	}
	// });
}

function PadZero(str) {
	//补零
	return new RegExp(/^\d$/g).test(str) ? `0${str}` : str;
}
export const setMinute = function setMinute(_seconds) {

	_seconds = parseInt(_seconds);
	let hours, mins, seconds;
	let result = '';
	seconds = parseInt(_seconds % 60);
	mins = parseInt(_seconds % 3600 / 60)
	hours = parseInt(_seconds / 3600);

	if (hours)
		result = `${PadZero(hours)}:${PadZero(mins)}:${PadZero(seconds)}`
	else
		result = `${PadZero(mins)}:${PadZero(seconds)}`
	return result;
}


export const dateFormat = function dateFormat(fmt, date) {
    let ret;
	date=new Date(date)
	// console.log(date)
    let opt = {
        "Y+": `${PadZero(date.getFullYear().toString())}`,        // 年
        "m+": `${PadZero((date.getMonth() + 1).toString())}`,     // 月
        "d+": `${PadZero(date.getDate().toString())}`,            // 日
        "H+": `${PadZero(date.getHours().toString())}`,           // 时
        "M+": `${PadZero(date.getMinutes().toString())}`,         // 分
        "S+": `${PadZero(date.getSeconds().toString())}`          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}