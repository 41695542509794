<template>
	<div class="questions">
		<div class="topBox">
			<div class="vanSwipe">
				<van-swipe :autoplay="3000" style="height: 7.5rem;">
					<van-swipe-item v-for="(image, index) in images" :key="index">
						<img v-lazy="image.pic" class="auto-img" @click="jump(image.url)"/>
						<!-- <div style="background-color: #0081FF;width: 100%;height: 100%;"></div> -->
					</van-swipe-item>
				</van-swipe>
			</div>
			<div class="flex" style="justify-content: space-between;margin-top: 23px;">
				<div style=" font-size: 18px;font-weight: bold;">急救知道</div>
				<div class="flex" @click="userQuestions">
					<div style="width: 8px;height: 8px;border-radius: 50%;background-color: #FF524D;" v-if="status"></div>
					<div style="margin: 0 5px 0 4px;color: #0081FF;font-size: 15px;">我的提问</div>
					<div style="width: 7px;height: 11px;">
						<img src="../../assets/Train/icon_more_blue@2x.png" alt="" class="auto-img" style="vertical-align: top;"></div>
				</div>
			</div>

		</div>
		<div class="bigBox" style="padding-bottom: 50px;">
			<div v-for="(item,i) in list" :key="i" @click="$router.push({name:'questionsDel',query:{ask_id:item.id}})" style="margin-bottom: 15px;">
				<div class="flex topBox">

					<div class="flex" style="flex-wrap: initial;align-items: end; border-bottom:1px solid #E5E5E5 ;padding: 16px 0;width: 100%;">

						<img src="../../assets/bigSink/icon_wen@2x.png" alt="" style="width: 20px;height: 20px;display: block;margin-right: 8.5px;">
						<div>
							<p v-text="item.wx_name"></p>
							<p style="font-weight: bold;padding-top: 16px;" v-text="item.contents">

							</p>
						</div>
					</div>
					<div style="margin: 18.5px 0 18px;" v-if="!item.reply_num">暂无回答</div>
					<div class="flex" style="flex-wrap: initial;align-items: end;padding: 16px 0;" v-if="item.reply_num">

						<img src="../../assets/bigSink/icon_da@2x.png" alt="" style="width: 20px;height: 20px;display: block;margin-right: 8.5px;">
						<div>
							<p style="font-weight: bold;" v-text="item.reply.r_contents">

							</p>
						</div>
					</div>
					<div class="flex kan">
						<div>
							<span v-text="item.click_num"></span> 人看过 · <span v-text="item.reply_num"></span> 回答
						</div>
						<div class="flex">
							更新于 <span v-text="item.update_time"></span>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="flex bottom" style="justify-content: space-between;padding: 10px 15px;z-index: 6;" v-if="!isshu">
			<div class="flex" style="position: relative;" @click="isseach=!isseach">
				<div class="seach" :class="isseach?'isseach':'isseach1'">
					<div style="border-bottom: 1px solid #E5E5E5;" @click="seachText='时间'">时间</div>
					<div style="border-bottom: 1px solid #E5E5E5;" @click="seachText='回答数'">回答数</div>
					<div @click="seachText='热度'">热度</div>
				</div>
				<img src="../../assets/bigSink/icon_paixu@2x.png" alt="" style="width: 16px;margin:0 8.5px;">
				<div style="color: #0081FF;"><span v-text="seachText"></span></div>
			</div>
			<div class="btn1" @click="isshu=true">提问</div>
		</div>
		<van-overlay :show="isshu" @click="isshu = false" />
		<div class="bottom " style="box-shadow: rgb(148, 149, 155) 1px 5px 15px 1px;z-index: 2;min-height: 50px !important;" v-if="isshu">
			<div class="flex bottomBox" v-if="isshu" style="justify-content: space-between;">
				<!-- <input type="text" placeholder="向大家提问" v-model="questionsTo"> -->
				<van-field
				  v-model="questionsTo"
				  rows="1"
				   :autosize="{maxHeight: 100}"
				  type="textarea"
				  placeholder="向大家提问"
				  class="input"
				/>
				<div style="width: 65px;" class="btn1"  @click="questionsToClick">
					提问
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getQuestionAskList, postQuestionAsk, postUnReadQuestionAskReply,getUserQuestionAskList , getBanner} from '@/api'
	import {
		Toast
	} from 'vant';
	export default {
  name: 'questions',
  components: {
  },
  watch:{
	  seachText(){
		  console.log('变了')
		  this.getQuestionAskList()
	  }
  },
  data(){
	  return{
		  questionsTo:'',
		  isshu:false,
		  isseach:false,
		  seachText:'时间',
		images: [
		  'https://img.yzcdn.cn/vant/apple-1.jpg',
		  'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		active:1,
		list:[{
			id:1,
			wenName:'邝文芬',
			questions:'打羽毛球脚扭了，怎么处理比较好？休息一会觉得没事，但是一站起来走路都有困难。',
			da:'',
			seen:123,
			hui:12,
			time:'2019-12-28'
		}],
		status:0
	  }
  },
  methods:{
	  
	  jump(url){
	  		  window.location.href = url
	  },
	  // 轮播
	  getBanner(){
		  getBanner({page_id:7}).then(res=>{
			  this.images=res.data.list
		  })
	  },
	  // 获得是否有未读信息
	  getUserQuestionAskList(){
		  getUserQuestionAskList().then(res=>{
			  this.status=res.data.status
		  })
	  },
	  // 我的提问设为已读/全部已读
	 userQuestions() {
		 this.$router.push({name:'userQuestions'})
		 postUnReadQuestionAskReply().then(res=>{
			 if(res.code==1){
			 }else{
				 // Toast(res.msg)
			 }
		 })
	  },
	  // 发布提问
	  questionsToClick(){
		  postQuestionAsk({contents:this.questionsTo}).then(res=>{
			  Toast(res.msg)
			  if(res.code==1){
				  this.isshu=false
				  this.questionsTo=''
				  this.getQuestionAskList()
			  }
		  })
		  console.log(this.questionsTo)
	  },
	  // 列表
	 getQuestionAskList(){
			/**
			* 时间 time
			*回答数 num
			*热度 hot
			* */
			let s
		 getQuestionAskList({order:this.seachText=='时间'?'time':this.seachText=='回答数'?'num':this.seachText=='热度'?'hot':''}).then(res=>{
			 this.list=res.data.list
			 this.list.forEach((item,index)=>{
				 s=item.update_time.split(' ')
				 this.list[index].update_time=s[0]
			 })
		 })
	 }
  },
  mounted() {
  	document.title = '急救知道';   
	this.getQuestionAskList()
	this.getUserQuestionAskList()
	this.getBanner()
  }
}
</script>
<style lang="scss" type="text/css">
	.questions {
		min-height: 100vh;
		background-color: #F7F8FF;
		.bottomBox {
			padding: 0px 15px;
			height: 100%;
			
			img {
				width: 20px;
				height: 20px;
			}
		
			.input {
				width: calc(100% - 77px);
				min-height: 33px;
				line-height: 33px;
				background-color: #F4F4F4;
				option: none;
				border-radius: 6px;
				padding:0 10px;
				transform: all 1s;
		
			}
		
			.follow {
				position: absolute;
				top: -7px;
				right: -20px;
				font-size: 12px;
				transform: scale(.7);
				color: #919CB0;
			}
		}
		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}

		.van-tab {
			min-width: 85px;
		}

		.van-tab--active {
			font-size: 17px;
		}

		.topBox {
			padding: 15px;
			background-color: #fff;

		}

		.bigBox {
			width: 100%;
			padding: 10px 15px;
			background-color: transparent;
			text-align: left;

			.topBox {
				justify-content: space-between;
				background-color: #fff;
				padding: 0px 15px 24px;
				border-radius: 6px;

				.btn {
					padding: 5px;
					background-color: #0081FF;
					border-radius: 50px;
					color: #fff;
					font-size: 12px;
					border: 1px solid transparent;
				}

				.btn1 {
					background-color: #fff;
					color: #0081FF;
					border: 1px solid #0081FF;
				}
			}
		}

		.time {
			color: #919CB0;
			font-size: 12px;
			transform: scale(.9);
		}

		.kan {
			justify-content: space-between;
			width: 100%;
			font-size: 12px;
			color: #919CB0;
			margin-top: 4px;
		}

		.bottom {
			height: auto;
			padding: 10px 0;
			box-shadow: 1px 5px 14px 1px rgba(35, 35, 35, 0.6);

			.btn1 {
				width: 63px;
				height: 30px;
				line-height: 30px;
				background-color: #0081FF;
				color: #fff;
				border-radius: 50px;

			}
		}

		.seach {
			position: absolute;
			top: -95px;
			left: -15px;
			padding: 0 10px;
			min-width: 100px;
			min-height: 70px;
			background-color: #F1F1F1;
			transition: all .5s;
			z-index: 5;
			color: #919CB0;

			div {
				padding: 10px 0;
				background-color: #F1F1F1;

			}
		}

		.isseach {
			visibility: visible;
			opacity: 1;
			top: -137px;
		}

		.isseach1 {
			visibility: hidden;
			opacity: 0;
			top: 95px;
		}
	}
</style>
