<template>
	<div class="allowance">
		<defaultVue v-if="!list.length"
			:defaultImg="require('@/assets/default/icon_zanwujtffjl.png')"
			defaultText="暂无津贴发放记录"
></defaultVue>
		<div class="container1" v-for="(item,i) in list" :key="i" v-if="list.length" style="margin-bottom: 15px;">
			
			<div class="header_title">
				120急救普及培训- <span v-text="item.act_name"></span>
			</div>
			<div class="conData">
				
				<van-row style='margin: 0;'>
				  <van-col span="6">
					  <span class="title">培训时间</span>
					</van-col>
				  <van-col span="18" class="title">
					  <span v-text="item.act_start_time"></span>
				  </van-col>
				</van-row>
				<van-row>
				  <van-col span="6">
					  <span class="title">培训地点</span>
					</van-col>
				  <van-col span="18" class="title">
					  <span v-text="item.complete_addr"></span>
				  </van-col>
				</van-row>
				<div style="padding: 0px 15px 0;">
					<table border="1" width="100%" height="70px" style="text-align: center;" bordercolor="#ddd">
					    <tr>
					        <td>报名人数</td>
					        <td>签到人数</td>
							<td>发证人数</td>
					    </tr>
					    <tr>
					        <td><span v-text="item.signup_num"></span></td>
					        <td><span v-text="item.signin_num"></span></td>
							<td><span v-text="item.cert_num"></span></td>
					    </tr>
						
					</table>
				</div>
				<div class="dashed">
					<van-divider dashed :style="{ borderColor: '#F7F8FF'}" :hairline="false"></van-divider>
				</div>
				<div class="header_title" style="color: #919CB0;padding: 0px 15px 20px; ">
					 津贴金额：<span style="color: #0081FF;">
                                ￥<span v-text="item.loose_change"></span>
                            </span>
					<!-- <van-divider /> -->
				</div>
				
			</div>
		</div>	
		<!-- <div class="fu flex">
			<img src="../../assets/Train/icon_tips@2x.png" alt="">
			<div>
				付费协议
			</div>
		</div>
		<div class="bottom flex" style="padding:0 30px ;">
			<div style="width: 50%;position: relative;">
			<div class="btn cancel" @click="$router.go(-1)">
				取消
			</div>
			</div>
			<div style="width: 50%;position: relative;">
			<div class="btn" @click="Success">
				立即付费
			</div>
			</div>
		</div> -->
	</div>
</template>

<script>	
	import defaultVue from '@/components/default/index.vue'
	import { getClientUsersAccountLogList } from '@/api'
	export default {
		name: 'allowance',
		components: {defaultVue},
		data() {
			return {
				title:'津贴发放记录',
				conData:{
					time:'2019-11-16  09:00',
					place:'广东广州市黄埔区科学城科珠路201号广东软件科学园G栋401'
					
				},
				list1: {
						img:'https://img.yzcdn.cn/vant/cat.jpeg',
						place:'广州市萝岗区科学城科珠路201号广东软件园G栋401室',
						keName:'成人心肺复苏及自动体外除颤器（AED）使用方法 ',
						time:'2017-12-07  13:30',
						site:'天鹰救援培训中心-广州分中心',
						cost:132
					 }	
				,
				list:[]
			}
		},
		methods: {
			  Success(){}
		},
		mounted() {
			document.title = this.title;
			getClientUsersAccountLogList().then(res=>{
	
				this.list=res.data
			})
		}
	}
</script>

<style lang="scss">
	.van-divider::after, .van-divider::before{
		border-width:2px 0 0;
	}
	.allowance{
		width: 100%;
		min-height: 100vh;
		padding: 15px;
		.imgBox{
			// position: absolute;
			// top: 0;
			// left: 0;
			// height: 200px;
			// width: 100%;
			z-index: -1;
			position: relative;
		}
		.list {
			flex-wrap: inherit;
			padding: 15px;
			padding-top: 0;
			.list_img {
				margin-right: 0.71875rem;
				position: relative;
			}
			.list_img:after{
				content: '';
				display: block;
				position: absolute;
				bottom: 6px;
				right: 0;
				width: 1.375rem;
				height: 1.25rem;
				background:  no-repeat center;
				background-size: 100% 100%;
			}
			.list_img.list_img_1:after{
				
				background-image: url(../../assets/Train/icon_1@2x.png);
				
			}
			.list_img.list_img_2:after{
				
				background-image: url(../../assets/Train/icon_2@2x.png);
				
			}
			.list_img.list_img_3:after{
				
				background-image: url(../../assets/Train/icon_3@2x.png);
				
			}
			
		
			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}
		
			.text {
				font-size: 0.75rem;
				color: #919CB0;
				// margin-left: 0.5rem;
			}
		
			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}
		
			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}
		
			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}
		
			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}
		
			.btnimg_span {
				margin-right: -5px;
		
			}
		}
		.container1{
			background-color: #fff;
			// height: 280px;
			z-index: 2;
			border-radius: 0.375rem;
			text-align: left;
			
			.header{
				text-align: center;
				font-size: 14px;
				font-weight: bold;
				padding-top: 20px;
			}
			.header_title{
				font-weight: bold;
				font-size: 13px;
				    padding: 15px 11px 11px;
			}
			.conData{
				// padding: 28px 0px;
				font-size: 12px;
				
			}
			.cost{
				color: #0081FF;
			}
			.title{
				color: #919CB0;
			}
			.van-row{
				margin: 14px 0;
				padding: 0 15px;
			}
			.dashed{
				position: relative;
				&::after{
					position: absolute;
					top: -8px;
					left: 0px;
					width: 8px;
					height: 16px;
					content: '';
					display: block;
					background: url(../../assets/Train/bg_yuan_left@2x.png) no-repeat center;
					background-size: 100% 100%;
				}
				&::before{
					position: absolute;
					top: -8px;
					right: 0px;
					width: 8px;
					height: 16px;
					content: '';
					display: block;
					background: url(../../assets/Train/bg_yuan_right@2x.png) no-repeat center;
					background-size: 100% 100%;
				}
			}
		}
		.fu{
			padding: 15px;
			color:#919CB0;
			img{
				width: 0.75rem;
				height: 0.75rem;
				margin-right: 0.5625rem;
			}
		}
		.cancel{
			background-color: #FFFFFF;
			border: 1px solid #0081FF;
			color: #0081FF;
			font-size: 15px;
		}
		.container1 .van-row {
		    margin: 8.5px 0 16px;
		    padding: 0 15px;
		}
	}
</style>
