var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chapter"},[_c('div',{staticClass:"titleBox",domProps:{"textContent":_vm._s(_vm.$route.query.chapter_title)}}),(_vm.istoUpdate)?_c('div',{staticStyle:{"width":"100%"}},_vm._l((_vm.subject),function(item,i){return (_vm.isshua)?_c('div',{key:i,staticClass:"bigBox",staticStyle:{"width":"100%","margin-bottom":"10px","padding":"0"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.isplay),expression:"!item.isplay"}],staticClass:"ylcBox"},[_c('img',{staticClass:"bg",staticStyle:{"position":"relative"},attrs:{"src":item.course_cover,"alt":""}}),_c('div',{staticClass:"flex bot"},[_c('div',{staticClass:"time"},[_c('span',{staticStyle:{"font-size":"10px","color":"#fff"},domProps:{"textContent":_vm._s(_vm.setTime(item.course_time))}})]),_c('div',{staticClass:"btnBo",on:{"click":function($event){return _vm.needTutorClick(i,item)}}},[_vm._v(" 播放 ")])])]),(item.isplay)?_c('div',{staticClass:"ylcBox"},[_c('video-player',{ref:"videoPlayer",refInFor:true,staticClass:"video-player vjs-custom-skin",attrs:{"playsinline":_vm.playsinline,"id":'videoPlayer'+item.id,"options":_vm.playerOptions},on:{"play":function($event){return _vm.onPlayerPlay($event,i)},"playerStateChanged":function($event){return _vm.playerStateChanged($event,i)},"timeupdate":function($event){return _vm.onPlayerTimeupdate($event,i)},"loadeddata":function($event){return _vm.onPlayerLoadeddata($event,i)},"pause":function($event){return _vm.onPlayerPause($event)},"ended":function($event){return _vm.onPlayerEnded($event)},"canplaythrough":function($event){return _vm.onPlayerCanplay($event)}}})],1):_vm._e(),_c('div',{staticClass:"flex box"},[_c('div',{staticClass:"boxI flex",staticStyle:{"justify-content":"space-between"}},[_c('div',[_vm._v(" 本节学习进度： "),_c('span',{domProps:{"textContent":_vm._s(
						item.rate == '100.00'
						  ? '100%'
						  : item.rate
						  ? item.rate + '%'
						  : item.rate == 0
						  ? '0%'
						  : ''
					  )}})]),_c('div',[_vm._v(" 本节视频播放进度： "),_c('span',{domProps:{"textContent":_vm._s((item.stop_timeBai?item.stop_timeBai:0)+'%')}})])])])]):_vm._e()}),0):_vm._e(),_c('van-popup',{staticClass:"zhuren",on:{"click-overlay":_vm.clickOverlay},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"popupBox"},[_c('div',{staticClass:"title"},[_vm._v("这节视频已观看一部分，是否继续观看？")]),_c('div',{staticClass:"flex btnBox"},[_c('div',{on:{"click":function($event){return _vm.playClick(_vm.index_i,_vm.item_i,0)}}},[_vm._v("重新播放")]),_c('div',{staticClass:"suss",on:{"click":function($event){return _vm.playClick(_vm.index_i,_vm.item_i,1)}}},[_vm._v("继续观看")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }