<template>
	<div class="UserCertificate">

		<van-cell is-link @click="showPopup1(usering.user_head_img)" style="height: 80px;align-items: center;">
			<div class="color_hei" slot="title" style="text-align: left;">本人证件相片</div>
			<div slot='default'>
				<img :src="usering.headimgurl" alt="" style="width: 40px;height: 54px;">
			</div>
		</van-cell>
		<van-cell is-link @click="show1=true" style="height: 50px;align-items: center;">
			<div slot="title" class="color_hei" style="text-align: left;">真实姓名</div>
			<div slot='default'>
				<span v-text="usering.user_name"></span>
			</div>
		</van-cell>
		<van-cell is-link @click="show=true" style="height: 50px;align-items: center;">
			<div slot="title" class="color_hei" style="text-align: left;">性别</div>
			<div slot='default'>
				<span v-text="usering.sex==1?'男':usering.sex==2?'女':''"></span>
			</div>
		</van-cell>
		<div class="Certificate">
			<div class="" style="display: flex;"><img src="../../assets/bigSink/icon_zhuy_red@2x.png" alt="" style="width: 12px;height: 12px;margin: 5px 8px 0 0;"><div style="width: calc(100% -  20px );">注：本人证书信息用于生成培训证书，请填写真实信息，如下图所示：</div></div>
			<img src="../../assets/pic_zsmoban@2x.png" alt="" style="width: 80%;margin: 25px auto;">
		</div>
		<van-action-sheet v-model="show" title="性别" :round="false" close-icon="cross">
		  <div class="content" >
			  	  <van-radio-group v-model="user_sex" style="display: flex;height: 36px;font-weight: bold;margin: 10px 50px; ">
			  	    <van-radio name="1" style="margin-right: 60px;">
			  	      <span :style="user_sex==1 ? 'color:#0081FF' : ''">男</span>
			  	     
			  	    </van-radio>
			  	    <van-radio name="2">
			  	      <span :style="user_sex==2 ? 'color:#0081FF' : ''">女</span>
			  	      
			  	    </van-radio>
			  		</van-radio-group>
					<div style="position: relative;height: 5.125rem;">
						<div class="bottom" style="position: absolute;">
						<div class="btn" @click="submit('sex')">
							提交
						</div>
					</div>
					</div>
					
		  </div>
		</van-action-sheet>
		<van-action-sheet v-model="show1" title="真实姓名" :round="false" close-icon="cross">
		  <div class="content" >
			  	 <van-field v-model="realName" label="姓名" placeholder="请输入真实姓名" />
					<div style="position: relative;height: 5.125rem;">
						<div class="bottom" style="position: absolute;">
						<div class="btn" @click="submit('realName')">
							提交
						</div>
					</div>
					</div>
					
		  </div>
		</van-action-sheet>

	</div>
</template>

<script>
	import { user ,postUserInfo  ,getUserCertsInfo,postUserCertsInfo} from '@/api';
// 弹窗
import {Toast } from 'vant'
	export default {
  name: 'UserCertificate',
  components: {
  },
  data(){
	  return{
		realName:'',
		user_sex:'1',
		minDate: new Date(1900, 0, 1),
		maxDate: new Date(),
		usering:{},
		title:'本人证书信息',
		show:false,
		show1:false,
		date: '',
		currentDate: new Date()
	  }
  },
  methods:{
	  submit(type){
		   let arr
		  if(type=='realName'){
			  if(this.realName){
			 arr = {user_name:this.realName}
			 this.postUserCertsInfo(arr)
			 }else{
				 this.$Toast('不能为空')
			 }
		  }else{
			  console.log(this.user_sex)
			  if(this.user_sex){
				  arr = {sex:this.user_sex}
				  this.postUserCertsInfo(arr)
				}else{
					this.$Toast('不能为空')
				}
			 
		  }
	  },
	  postUserCertsInfo(arr){
		  postUserCertsInfo(arr).then(res=>{
			  this.$Toast(res.msg)
			  if(res.code==1){
				  this.getUserCertsInfo()
				  this.show=false
				  this.show1=false
			  }
		  })
	  },
	  /* 修改个人资料接口函数*/
	  postUserInfo(type,value){
	  		  postUserInfo({type:type,value:value})
	  		  .then(res=>{
	  			 if(res.msg=='修改成功'){
	  				 Toast.success(res.msg);
	  				 // setTimeout(()=>{
	  				 // 	this.$router.go(-1)
	  				 // },2000)
					 this.user()
	  			 }else{
	  				 Toast.fail(res.msg);
	  			 }
	  		  	
	  		  })
	  },
	  // 确认日期按钮
	  confirm(res){
		 let time = this.formatDate(res)
		 this.show=false
		 this.postUserInfo('user_birthday',time)
		 console.log(time)
	  },
	  // 把选择器加上年月日
	    formatter(type, value) {
	        if (type === 'year') {
	          return `${value}年`;
	        } else if (type === 'month') {
	          return `${value}月`
	        }
			else if (type === 'day') {
			  return `${value}日`
			}
	        return value;
	      },
		  // 取消按钮
	  cancel(){
		  this.show=false
	  },
	  // 头像点击 
	  showPopup(type){
		  console.log(type)
			this.$router.push({name:'setMaterial1',query:{ type1:type}})
		// this.$router.push({ name: 'news', params: { userId: 123 }})
	  },
	  showPopup1(type){
	  			this.$router.push({name:'certificateHead',query:{ type1:type}})
	  		// this.$router.push({ name: 'news', params: { userId: 123 }})
	  },
	   formatDate(date) {
		   let mouth =  date.getMonth()+1
		   let day= date.getDate()
		 mouth= mouth>10?mouth:'0'+mouth
		  day=day>10?day:'0'+day
		  console.log(mouth,day)
	        return `${date.getFullYear()}-${mouth}-${day}`;
	      },
	      onConfirm(date) {
	        this.show = false;
	        this.date = this.formatDate(date);
			console.log(this.date)
	 },
	 // user(){
		//  user().then(res=>{
		//  	this.usering=res.data
			
		//  })
	 // },
	 getUserCertsInfo(){
		 getUserCertsInfo().then(res=>{
			 this.usering=res.data
			 this.user_sex=String( res.data.sex)
		 })
	 },
	 
  },
  mounted() {
  	document.title = this.title;   
	this.getUserCertsInfo()
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	

	.UserCertificate {
		background-color: #fff;
		min-height: 100vh;
		.Certificate{
			padding: 15px;
			text-align: left;
			img{
				display: block;
			}
		}
		.van-cell:not(:last-child)::after {
			left: 0;
			width: 95%;
			margin: 0 auto;
		}
		.van-action-sheet__header{
			    font-size: 18px;
			    font-weight: bold;
		}
		.van-field__body{
			    border: 1px solid #0081FF;
			    padding: 7px;
			    border-radius: 6px;
			    font-size: 15px;
			    font-weight: bold;
		}
		.van-field__label{
			width: 60px;
			    line-height: 40px;
		}
	}
</style>
