<template>
  <div class="deliveryByOrderid">
	  <div class="container">
		  <div style="border-bottom: 1px solid #F7F8FF;padding-bottom: 15px;">
			  <div style="margin-bottom: 5px;">
				  快递公司：<span v-text="post_company"></span>
			  </div>
			  <div>
				  运单号：<span v-text="post_mailno"></span>
			  </div>
		  </div>
		  <van-steps direction="vertical" :active="0" active-color="#0081FF" v-if="Order.length">
		    <van-step v-for="(item,i) in Order" :key="i" >
		      <p v-text="item.time" ></p>
		      <p v-text="item.context"  ></p>
		    </van-step>
		   
		  </van-steps>
		  <div v-if="!Order.length" style="margin-top: 15px;">
			  暂无物流信息
		  </div>
		</div> 
  </div>
</template> 

<script>
import { getDeliveryByOrderid } from '@/api'
export default {
  name: 'deliveryByOrderid',
  components: {
  },
  data(){
	  return{
		  Order:[],
		  post_company:'',
		  post_mailno:'',
	  }
  },
  methods:{
	  open(){
		  this.isopen=!this.isopen
	  }
  },
  mounted() {
  	document.title = '快递详情';   
	getDeliveryByOrderid({orderid:this.$route.query.orderid}).then(res=>{
		if(res.code==1){
			this.Order=res.data.data
			this.post_company=res.data.post_company
			this.post_mailno=res.data.post_mailno
		}else{
			this.$Toast('信息有误')
		}
	
	})
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.deliveryByOrderid{
		
	}
</style>
