<template>
	<div class="toUpdate">
		<div class="popupConten">
			<div class="header" style="padding-bottom: 5px;">现场急救行动指南</div>
			<div class="header_name" v-text="$route.query.act_name"></div>
			<div class="header_time" v-text="$route.query.act_time"></div>
			<div class="form" v-if="Obj.act_type == 1">
				<div class="" style="display: flex;margin-top: 25px;">
					<div>
						<img src="../../assets/icon_tips_red@2x.png" alt="" style="width: 12px;height: 12px;margin-right: 10px;">

					</div>
					<div style="font-size: 12px;text-align: justify;width: calc(100% - 19px);">以下信息用于培训后生成合格证，请填写真实信息，本
						人证件相片若现在无法提供，可在培训后尽快补充修改，修改位置【我的宝藏】
						<van-icon name="down" style="transform: rotate(-90deg) translateX(-2px);" />【本人证书信息】</div>

				</div>
				<div class="qualifiedtextflex">
					<div class="qualifiedtextflex_text">本人证件相片：</div>
					<img src="../../assets/icon_tianjia138@2x.png" style="width: 69px;height: 93px;" v-if="!imgsrc" @click="uploadHeadImg">
					<img :src="imgsrc" style="width: 69px;height: 93px;" v-else>
					<!-- <div style="width: 69px;height: 93px;border: 1px solid #0081FF;">
				
					</div> -->

				</div>
				<van-row class="row">
					<van-col span="6">
						<div class="left">
							姓名
						</div>
					</van-col>
					<van-col span="18">
						<input type="text" placeholder="请输入姓名" v-model="activity.sign_name" />
					</van-col>
				</van-row>
				<van-row class="row" stylem="margin:10px 0">
					<van-col span="6">
						<div class="left">
							性别
						</div>
					</van-col>
					<van-col span="18">
						<van-radio-group v-model="activity.sex" style="display: flex;height: 36px;">
							<van-radio name="1" style="margin-right: 30px;">
								男
								<img slot="icon" slot-scope="props" v-bind:src="props.checked ? activeIcon : inactiveIcon" style="width:20px;height:20px;vertical-align : top;" />
							</van-radio>
							<van-radio name="2">
								女
								<img slot="icon" slot-scope="props" :src="props.checked ? activeIcon1 : inactiveIcon1" style="width:20px;height:20px;vertical-align : top;" />
							</van-radio>
						</van-radio-group>
					</van-col>
				</van-row>
				<van-row class="row">
					<van-col span="6">
						<div class="left">
							年龄段
						</div>
					</van-col>
					<van-col span="18" @click="showPicker = true">
						<!-- <input type="text" placeholder="请选择" v-model="" disabled="false"> -->
						<div class="flex" style="justify-content: space-between;background-color: #F4F4F4;height: 36px;line-height: 36px;padding:0 10px;border-radius: 6px;">
							<div v-text="activity.age ? activity.age : '请选择'" :class="activity.age ? '' : 'hui'"></div>
							<img src="@/assets/Train/icon_xiala_nor@2x.png" alt="" style="width: 16px;height: 10px;" />
						</div>
					</van-col>
				</van-row>
				<van-row class="row" style="margin-bottom:0">
					<van-col span="6">
						<div class="left">
							手机号码
						</div>
					</van-col>
					<van-col span="18">
						<input type="text" placeholder="请输入常用手机号" v-model="activity.sign_phone" />
					</van-col>
				</van-row>
				<div class="bottom" style="position: relative;">
					<div class="btn" @click="formClick" style="padding: 0;width: 85%;">
						立刻签到
					</div>
				</div>
			</div>

			<div class="form" v-if="Obj.act_type == 2">
				<van-row class="row" stylem="margin:10px 0">
					<van-col span="6">
						<div class="left">
							身份选择
						</div>
					</van-col>
					<van-col span="18">
						<van-radio-group v-model="activity.sex" style="display: flex;height: 36px;">
							<van-radio name="1" style="margin-right: 30px;">
								<img slot="icon" slot-scope="props" v-bind:src="props.checked ? TecActiveIcon : TecInactiveIcon" style="width:20px;height:20px;vertical-align : top;" />
								<span :style="
                    activity.sex == 1
                      ? 'color:rgba(40,51,74,1);'
                      : 'color:rgba(145,156,176,1);'
                  ">主讲</span>
							</van-radio>
							<van-radio name="2">
								<img slot="icon" slot-scope="props" :src="props.checked ? TecActiveIcon1 : TecInactiveIcon1" style="width:20px;height:20px;vertical-align : top;" />
								<span :style="
                    activity.sex == 2
                      ? 'color:rgba(40,51,74,1);'
                      : 'color:rgba(145,156,176,1);'
                  ">助教导师</span>
							</van-radio>
						</van-radio-group>
					</van-col>
				</van-row>
				<van-row class="row">
					<van-col span="6">
						<div class="left">
							姓名
						</div>
					</van-col>
					<van-col span="18">
						<input type="text" placeholder="请输入姓名" v-model="activity.sign_name" />
					</van-col>
				</van-row>

				<div class="bottom" style="position: relative;">
					<div class="btn" @click="formClick" style="padding: 0;width: 85%;">
						立刻签到
					</div>
				</div>

			</div>
		</div>
		<van-popup v-model="showPicker" position="bottom">
			<van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
		</van-popup>
		<van-popup v-model="isAreaList" position="bottom">
			<div style="width: 100%;">
				<van-area :area-list="areaList" :columns-num="3" title="地址" @confirm="confirm" @cancel="cancel" />
			</div>
		</van-popup>
		<!-- <transition name="van-fade">
		  <div v-show="visible" class="vanFade">
			  <img src="../../assets/pic_qdcg@2x.png" alt="" class="imgSecc">
		  </div>
		</transition> -->
		<van-overlay :show="visible" @click="visible = false">
			<!-- <div class="wrapper" @click.stop> -->
			<div v-show="visible" class="vanFade">
				<div class="popupBox_guo">
					<span class="qualified">课程签到成功！</span>
				</div>
				<!-- <img src="" alt="" class="imgSecc"> -->
				<img src="../../assets/icon_close_qiandao@2x.png" alt="" class="imgclose" @click.stop="fadecloseClick">
			</div>
			<!-- </div> -->
		</van-overlay>
		<h5-cropper class="hiddenInput" :option="option" @getbase64Data="getbase64Data" @getblobData="getblobData" @getFile="getFile"></h5-cropper>


	</div>
</template>

<script>
	import areaList from "../../area.js";
import { postSignin,postTutorSignin ,getActivity_is,postTeacherSignin} from "@/api";
	import { Toast } from 'vant';
export default {
  name: "toUpdate",
  components: {},
  data() {
    return {
		imgsrc:'',
		option:{
			fixedBox:true,
			centerBox:false,
			fixedNumber:[1, 1.4],
			autoCropHeight:186,
			autoCropWidth:138
		},
		visible:false,
      iscommentListLength: false,
      commentList: [
        {
          name: "lisa",
          time: "2018-01-16",
          content:
            "导师非常专业，教得很好。实操的时间很多，真正让人掌握了心肺复苏术。",
          headimg:
            "https://dss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3256100974,305075936&fm=26&gp=0.jpg"
        },
        {
          name: "lisa",
          time: "2018-01-16",
          content:
            "导师非常专业，教得很好。实操的时间很多，真正让人掌握了心肺复苏术。",
          headimg:
            "https://dss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3256100974,305075936&fm=26&gp=0.jpg"
        }
      ],
      // 助教导师报名
      show1: false,
      is_signup: 0,
      // 报名费用
      Cost: 300,
      // 是否已报名
      issignUp: false,
      // 身份
      user_type: 0,
      laoRadio: "1",
      // 详细地址
      acct: "",
      areaList: areaList,
      train_add: {
        province: {
          name: "广东",
          code: "440000"
        },
        city: {
          name: "广州",
          code: "440100"
        },
        area: {
          name: "天河区",
          code: "440106"
        },
        isMust: true
      },
      isAreaList: false,
      value: "",
      showPicker: false,
      columns: [
        "0-8",
        "8-16",
        "17-24",
        "25-32",
        "33-40",
        "41-48",
        "48-56",
        "57-64",
				"65+"
      ],
      // 弹出层
      show: false,
      radio: "1",
      TecActiveIcon: require("../../assets/icon_fuxuank_pre.png"),
      TecInactiveIcon: require("../../assets/icon_fuxuank_nor.png"),
      TecActiveIcon1: require("../../assets/icon_fuxuank_pre.png"),
      TecInactiveIcon1: require("../../assets/icon_fuxuank_nor.png"),
      activeIcon: require("../../assets/Train/icon_nan_pre@2x.png"),
      inactiveIcon: require("../../assets/Train/icon_nan_nor@2x.png"),
      activeIcon1: require("../../assets/Train/icon_nv_pre@2x.png"),
      inactiveIcon1: require("../../assets/Train/icon_nv_nor@2x.png"),
      
      // 活动状态
      /**
       * 1:未报名
       * 2.已报名
       * 3.活动结束 课程精彩反馈
       * */
      activityType: 1,
      title: "培训课程详情",
      name: "120急救培训",
      time: "2018-01-05",
      teacher: "我是老师",
      platform: "互救吧平台",
      requestIds: "",
      act_author: "发起者",
      activityContent: {},
	  actArr:{},
	Obj:{},
	// 提交
	activity: {
	  sign_name: "",
	  sex: "",
	  age: "",
	  sign_phone: "",
	  act_id: '',
	  // 活动id
	},
    };
  },
  methods: {
	  // 打开图片上传
	  uploadHeadImg() {
	  	this.$el.querySelector('.upbtn>.upbtn').click()
	  	// this.visible = true
	  	// this.imgsrc1 = 'http://img1.imgtn.bdimg.com/it/u=293643936,2020316857&fm=26&gp=0.jpg'
	  	// console.log(this.imgsrc1, '父组件')
	  },
	  // 拿到blod
	  getblobData(){},
	  // 拿到flie
	  getFile(){},
	  // 拿到base64
	  getbase64Data(data) {
	        this.imgsrc = data;
	  	  this.over=true
	      },
	  fadecloseClick(){
		  wx.closeWindow();
	  },
    // 现在年龄段
    onConfirm(value) {
      this.activity.age = value;
      this.showPicker = false;
    },
    confirm(res) {
      console.log(res);

      this.activity.provinceid = res[0].code;
      this.activity.cityid = res[1].code;
      this.activity.areaid = res[2].code;
      this.train_add.province = res[0];
      this.train_add.city = res[1];
      this.train_add.area = res[2];
      this.isAreaList = false;
    },
    // 提交数据
    formClick() {
			let act_type =Number( this.Obj.act_type)
				console.log(this.activity)
				switch (act_type){
					case 1:
						if(this.activity.sign_name&&this.activity.sex&&this.activity.age&&this.activity.sign_phone){
							postSignin(this.activity).then(res => {
							  Toast(res.msg);
							  if (res.code == 1) {
								  this.visible=true
							    // wx.closeWindow();
							  }
							  // this.$router.push({name:'ApplicationDet',query:{signin_id:res.data.signin_id}})
							});
						}else{
							this.$Toast('请填写内容')
						}
						
						break;
					case 2:
					let arr ={
						act_id:this.Obj.act_id,
						sign_name:this.activity.sign_name,
						tutor_type:this.activity.sex
					}
					if(arr.sign_name&&arr.tutor_type){
						if(arr.tutor_type==1){
							postTeacherSignin(arr).then(res=>{
								Toast(res.msg);
								if (res.code == 1) {
									this.visible=true
								}
							})
						}else{
							postTutorSignin(arr).then(res=>{
								Toast(res.msg);
								if (res.code == 1) {
									this.visible=true
								}
							})
						}
					}else{
						this.$Toast('请填写内容')
					}
						break;
				}
    },
    provinceClick() {
      this.isAreaList = true;
    },
    cancel() {
      console.log("222");
      this.isAreaList = false;
    }
  },
  mounted() {
	 this.Obj=JSON.parse(this.$route.query.Obj) 
    document.title = this.Obj.act_type==1?"学员签到":"导师签到";
	let act_id =this.Obj.act_id
	this.activity.act_id=act_id
	getActivity_is({act_id:act_id}).then(res=>{
				 this.actArr=res.data
	})
  }
};
</script>
<style lang="scss" type="text/css" scoped>
	.toUpdate {
		min-height: 100vh;
		background-color: #f8f8ff;
		// padding: 0 15px;
		text-align: left;

		// 弹出层
		.popupConten {
			padding: 10px 15px 00px;
			text-align: left;
			font-size: 16px;
			position: relative;

			.header {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				color: rgba(40, 51, 74, 1);
				text-align: center;
				padding: 10px 0 16px;
				margin-bottom: 6px;
			}

			.header_name {
				font-size: 15px;
				font-family: PingFang SC;
				font-weight: 500;
				color: rgba(40, 51, 74, 1);
				text-align: center;
			}

			.header_time {
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: 500;
				color: rgba(145, 156, 176, 1);
				text-align: center;
				margin-top: 6px;
				margin-bottom: 16px;
			}

			.content {
				position: relative;
				height: calc(100% - 80px);
				overflow: auto;
			}

			input {
				background-color: #f4f4f4;
				width: 100%;
				height: 36px;
				border-radius: 6px;
				padding: 0 13px;
			}

			input::-webkit-input-placeholder {
				color: #918cb0;
			}

			input::-moz-placeholder {
				/* Mozilla Firefox 18+ */
				color: #918cb0;
			}

			input:-moz-placeholder {
				/* Mozilla Firefox 4 to 18 */
				color: #918cb0;
			}

			input:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: #918cb0;
			}

			.form {
				background: #fff;
				padding: 1px 15px;
				border-radius: 6px;
				padding-bottom: 0;

				.left {
					height: 36px;
					line-height: 36px;
				}
			}

			.row {
				margin: 25px 0;
			}

			.formBtn {
				padding: 10px 0;

				.btn {
					border-radius: 21px;
					width: 85%;
					height: 2.625rem;
					background-color: #0081ff;
					color: #fff;
					line-height: 2.625rem;
					text-align: center;
					margin: 0 auto;
				}
			}

			p {
				margin-bottom: 28px;
			}
		}

		.btn1 {
			border-radius: 0.375rem;
			padding: 9px 10px;
			background: #f4f4f4;
			color: #918cb0;
			border: 1px solid transparent;
			margin-right: 5px;
			// margin-top: 1rem;
			width: 31%;
			position: relative;

			img {
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
			}
		}

		.btn1.activation {
			border-color: #0081ff;
			background: #ffffff;
			color: #0081ff;
		}

		.qualifiedtextflex {
			display: flex;
			margin-top: 15px;

		}

		.vanFade {
			width: 100%;
			position: fixed;
			top: 50%;
			transform: translateY(-50%);
			left: 0;

			// height: 100vh;
			.imgSecc {
				width: 67%;

				display: block;
				margin: 0px auto;
			}

			.imgclose {
				width: 32px;
				height: 32px;
				margin: 0px auto;
				margin-top: 32px;
				display: block;
			}
		}

		.popupBox_guo {
			width: 253px;
			height: 200px;
			background: url(../../assets/pic_qdcg@2x.png) no-repeat;
			background-size: 100% 100%;
			margin: 0 auto;
			z-index: 11;
			position: relative;

			.qualified {
				width: 100%;
				color: #fff;
				position: absolute;
				bottom: 60px;
				left: 50%;
				transform: translateX(-50%);
				font-size: 15px;
				color: #28334A;
				text-align: center;
			}

			.popupBox_guo_number {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -70%);
				font-size: 2.5rem;
				color: #FFFFFF;

			}


		}
	}
</style>
