<template>
  <div class="cooperation-join-wrap">
    <div class="content">
      <p class="p-text">我国每年有117万人心源性猝死(平均每分钟心源性猝死2人以上)，居全球之首;85%的心脏骤停患者发生在家中、办公室、校园，地铁站、公交车站、体育场馆、机场、高铁站、商场或无人问津的小巷。</p>
      <p class="p-text">心脏骤停的黄金抢救时间仅为5分钟内，目前我国院前能够抢救存活的患者不到1%，远低于发达国家15%以上的抢救成功率；全社会生命救助的痛点，不敢救、不会救、不能救！！！</p>
      <div class="logo-img">
        <img src="../../assets/user/icon_gflogo.png" alt="">
        <div class="boi"></div>
        <div class="logo-text">
          <p>救在身边</p>
          <p>守护生命</p>
        </div>
      </div>
      <p class="explain" style="text-align: center;margin-top: -15px;">公众急救知识的普及现状</p>
      <!-- <table border="1">
        <tr>
          <th colspan="2">急救普及者（具备基础急救知识的比率）</th>
          <th colspan="2">急救员（具备专业急救技能的比率）</th>
          <th>国内</th>
        </tr>
        <tr>
          <td>美国</td>
          <td>4人中有1人</td>
          <td>美国</td>
          <td>3人中有1人</td>
          <td rowspan="4" width="100">具备基础急救知识的比率，广州、天津不足1%。对120急教电话的知晓率，北京58%、上海50.85%、安阳19.75.</td>
        </tr>
        <tr>
          <td>新加坡</td>
          <td>8人中有1人</td>
          <td>新加坡</td>
          <td>5人中有1人</td>
        </tr>
        <tr>
          <td>澳大利亚</td>
          <td>20人中有1人</td>
          <td>澳大利亚</td>
          <td>100人中有1人</td>
        </tr>
        <tr>
          <td>中国</td>
          <td>100人中不足1人</td>
          <td>中国</td>
          <td>1000人中没有1人</td>
        </tr>
      </table> -->
      <table border="1">
        <tr>
          <th>急救普及者<br/><span style="font-size: 9px;">(具备基础急救知识的比率)</span></th>
          <th>急救员<br/><span style="font-size: 9px;">(具备专业急救技能的比率)</span></th>
          <th >国内</th>
        </tr>
        <tr>
          <td>
            <span style="margin-right: 35px;">美国</span>
            <span>4人中有1人</span>
          </td>
          <td>
            <span style="margin-right: 35px;">美国</span>
            <span>3人中有1人</span>
          </td>
          <td rowspan="4" width="100">具备基础急救知识的比率，广州、天津不足1%。对120急教电话的知晓率，北京58%、上海50.85%、安阳19.75。</td>
        </tr>
        <tr>
          <td>
            <span style="margin-right: 25px;">新加坡</span>
            <span>8人中有1人</span>
          </td>
          <td>
            <span style="margin-right: 25px;">新加坡</span>
            <span>5人中有1人</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>澳大利亚</span>
            <span>20人中有1人</span>
          </td>
          <td>
            <span style="margin-right: 5px;">澳大利亚</span>
            <span>100人中有1人</span>
          </td>
        </tr>
        <tr>
          <td>
            <span style="margin-right: 10px;">中国</span>
            <span>1000人中不足1人</span>
          </td>
          <td>
            <span style="margin-right: 10px;">中国</span>
            <span>1000人中没有1人</span>
          </td>
        </tr>
      </table>
      <p class="p-text" style="margin-top: 20px;">按中国14亿人口，而急救知识的普及率不到1%，急救培训需求巨大。中国已属于老龄化国家，全国急救培训教育已成为我国面临的紧迫任务。</p>
      <p class="p-text">我们为公众提供线上、线下培训模式，完善的急救培训管理平台，成熟的培训激励模式，让您轻松负责区域开展急救培训活动。</p>
      <p class="p-text">诚邀您将加入，一起铸就急救普及事业。</p>
      <p class="p-text">培训合作负责人:刘孝华</p>
      <p class="p-text">联系电话:13924212999 (微信同号)</p>
    </div>
    <p class="explain">请填写合作意向表格</p>
    <div class="in-form">
      <van-form @submit="onSubmit">
        <van-cell-group>
          <div class="operaction-area flex">
            <span>合作区域</span>
          </div>
          <div class="flex area-btns">
            <div class="btn" @click="provinceClick">
              <span v-text="broad_add.province.name"></span>
              <img
                src="../../assets/Train/icon_xiala_nor@2x.png"
                alt
                v-if="isAreaList!=='城市'"
                style="height: 20px;width: 20px;"
              />
              <img
                src="../../assets/Train/icon_xiala_pre@2x.png"
                alt
                v-if="isAreaList=='城市'"
                style="height: 20px;width: 20px;"
              />
            </div>
            <div class="btn" @click="provinceClick" v-show="isShowCA">
              <span v-text="broad_add.city.name"></span>
              <img
                src="../../assets/Train/icon_xiala_nor@2x.png"
                alt
                v-if="isAreaList!=='城市'"
                style="height: 20px;width: 20px;"
              />
              <img
                src="../../assets/Train/icon_xiala_pre@2x.png"
                alt
                v-if="isAreaList=='城市'"
                style="height: 20px;width: 20px;"
              />
            </div>
            <div class="btn" @click="provinceClick" v-show="isShowCA">
              <span v-text="broad_add.area.name"></span>
              <img
                src="../../assets/Train/icon_xiala_nor@2x.png"
                alt
                v-if="isAreaList!=='城市'"
                style="height: 20px;width: 20px;"
              />
              <img
                src="../../assets/Train/icon_xiala_pre@2x.png"
                alt
                v-if="isAreaList=='城市'"
                style="height: 20px;width: 20px;"
              />
            </div>
          </div>
          <van-field
            v-model="formObj.uname"
            name="uname"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请输入姓名' }]"
          />
          <van-field
            v-model="formObj.uphone"
            name="uphone"
            label="联系电话"
            placeholder="请输入联系电话"
            :rules="[{ pattern, required: true, message: '请输入11位手机电话' }]"
          />
          <van-field
            v-model="formObj.address"
            name="address"
            label="联系地址"
            placeholder="请输入联系地址"
          />
          <van-field v-model="formObj.remark" name="remark" label="备注" rows="3" type="textarea" placeholder="请输入备注" />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
    <!-- 弹窗内容 -->
    <van-popup v-model="isAreaList" position="bottom">
      <div style="width: 100%;">
        <van-area
          :area-list="areaList"
          :columns-num="3"
          title="地址"
          :value="formObj.areaid"
          @confirm="confirm"
          @cancel="cancel"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { cooperateSign } from "@/api";
import areaList from "../../area.js";
import { Toast } from "vant";
export default {
  name: "trainInvitation",
  data() {
    return {
      title: "报名参加",
      ins_id: 0,
      pattern: /^1[3-9]\d{9}$/,
      formObj: {
        uname: "",
        uphone: "",
        address: "",
        remark: "",
        provinceid: '440000',
        cityid: '440100',
        areaid: '440106',
      },
      isAreaList: false,
      isShowCA: true,
      areaList: areaList,
      broad_add: {
        province: {
          name: "广东",
          code: "440000"
        },
        city: {
          name: "广州",
          code: "440100"
        },
        area: {
          name: "天河区",
          code: "440106"
        },
        isMust: true
      }
    };
  },
  methods: {
    provinceClick() {
      this.isAreaList = true;
    },
    cancel() {
      this.isAreaList = false;
    },
    confirm(res) {
      if(res[0].code === '100009') {
        this.isShowCA = false
        this.broad_add.province = res[0];
        this.formObj.provinceid = res[0].code;
        this.formObj.cityid = '';
        this.formObj.areaid = '';
      } else {
        this.isShowCA = true
        this.broad_add.province = res[0];
        this.broad_add.city = res[1];
        this.broad_add.area = res[2];

        this.formObj.provinceid = res[0].code;
        this.formObj.cityid = res[1].code;
        this.formObj.areaid = res[2].code;
      }

      this.isAreaList = false;
    },
    onSubmit(values) {
      const { provinceid, cityid, areaid, uname, uphone, address, remark } = this.formObj;
      cooperateSign({
        is_all_area: provinceid === '100009' ? 1 : '',
        province_id: provinceid === '100009' ? '' : provinceid,
        city_id: cityid,
        area_id: areaid,
        name: uname,
        phone: uphone,
        address,
        remark
      }).then(res => {
        if (res.code === 1) {
          Toast("提交成功");
          this.formObj.uname = '';
          this.formObj.uphone = '';
          this.formObj.address = '';
          this.formObj.remark = '';
          // setTimeout(() => {
          //   this.$router.go(-1);
          // }, 1000);
        } else {
          Toast(res.msg);
        }
      });
    },
  },
  mounted() {
    document.title = this.title;
    this.ins_id = this.$route.query.id;
    this.areaList.province_list[100009] = '全国';
    this.areaList.city_list[100109] = '';
    this.areaList.county_list[100109] = '';
  }
};
</script>

<style lang="scss" scoped>
.cooperation-join-wrap {
  width: 100%;
  min-height: 100vh;
  background: #f7f8ff;

  .content {
    color: #333;
    padding: 9px 13px;
    background: #fff;
    .p-text {
      text-indent: 35px;
			line-height: 27px;
      text-align: left;
      font-size: 17px;
    }
    .logo-img {
      margin-right: 10px;
      display: flex;
      align-items: center;
      img {
        width: 45px;
      }
      .boi {
        border-right: 2px solid #fa7e70;
        height: 38px;
        width: 4px;
      }
      .logo-text {
        margin: 20px 0px;
        margin-left: 10px;
        p {
          font-size: 12px;
          font-weight: 600;
          color: #fa7e70;
          &:last-child {
            color: #2e59ff;
          }
        }
      }
    }
    table {
      border-color: #fff;
      width: 100%;
      // border-collapse: collapse;
      // border-spacing: 0;
      border: 0;
      tr {
        td, th {
          font-size: 10px;
          text-align: left;
          background: #F9CBAC;
          span {
            &:first-child {
              margin-right: 10px;
            }
          }
        }
        th {
          text-align: center;
          background: #EE7F31;
          color: #fff;
          font-weight: 400;
          padding: 5px 0;
        }
        td {
          padding: 5px 0;
          padding-left: 3px;
        }
      }
    }
  }

  .explain {
    font-size: 1.1rem;
    font-weight: 600;
    padding: 1rem;
    background: #fff;
  }
  .in-form {
    width: 100%;
  }
  .btn {
    border-radius: 0.375rem;
    padding: 9px 13px;
    background: #f4f4f4;
    color: #919cb0;
    border: 1px solid transparent;
    margin-right: 10px;
    // margin-top: 1rem;
    width: 30%;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  .operaction-area {
    margin-bottom: 10px;
    span {
        text-align: left;
        color: #646566;
        padding-left: 12px;
        font-size: 14px;
        margin-right: 40px;
    }
  }
  .area-btns {
    padding: 0 12px;
    margin-bottom: 10px;
  }
  .van-cell {
    padding: 16px;
  }
}
</style>