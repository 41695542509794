<template>
	<div class="supervisionNotice">
		<div class="super">
			<van-row class="vanRowBox"> 
			  <van-col span="8"> <span class="hui">督导预约状态</span></van-col>
			  <van-col span="16" v-if="ApplyDetail.tutor_res_status!==3"><span class="bold" v-text="ApplyDetail.tutor_status==2?'未预约':ApplyDetail.tutor_status==3?'已接受，预约人未付款':ApplyDetail.tutor_status==4?'预约成功':''"></span></van-col>
			  <van-col span="16" v-if="ApplyDetail.tutor_res_status==3"><span class="bold refuse">已拒绝</span></van-col>
			</van-row>
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">活动名称</span></van-col>
			  <van-col span="16">【<span v-text="ApplyDetail.act_name"></span>】</van-col>
			</van-row>
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">活动时间</span></van-col>
			  <van-col span="16"><span v-text="ApplyDetail.act_start_time"></span></van-col>
			</van-row>
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">开课培训地点</span></van-col>
			  <van-col span="16"><span v-text="ApplyDetail.act_addr"></span></van-col>
			</van-row>
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">督导主任导师</span></van-col>
			  <van-col span="16"><span v-text="ApplyDetail.act_author"></span></van-col>
			</van-row>
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">预约费用</span></van-col>
			  <van-col span="16"><span v-text="ApplyDetail.cost"></span></van-col>
			</van-row>
			
		</div>
		<van-popup v-model="show" :round="true" class="dudao">
			<div class="popupBox">
				<div class="title">
					拒绝原因
				</div>
				<van-field
				  v-model="message"
				  rows="2"
				  type="textarea"
				  placeholder="请填写拒绝原因"
				  style="border-radius: 6px;background-color: #F4F4F4;"
				/>
				<div class="flex btnBox">
					<div @click="show=false">暂不取消</div>
					<div class="suss" @click="refuseClick">确定</div>
				</div>
			</div>
		</van-popup>
		<!-- 顶部 -->
		<div class="bottom" style="box-shadow:0px -6px 10px 0px rgba(145,156,176,0.1);" v-if="tutor_status==3">
			
			<div class="btn" @click="acceptClick">
				立即付款
			</div>
		</div>
	</div>
</template>

<script>
import { getOfficialActivityTutorApplyDetail,postOfficialActivityTutorApplyCheck,userRechargeUniOrder } from '@/api';
import { Toast } from 'vant'
import areaList from '../../area.js'

export default {
  name: 'supervisionNotice',
  components: {
  },
  data(){
	  return{
		  message:'',
		show:false,
		// 主任导师督导状态
		tutor_res_status:1,
		// 活动督导状态
		tutor_status:1,
		ApplyDetail:[]
		
	  }
  },

  methods:{
	  jsApiCall(jsApiParameters){
	  	// console.log(jsApiParameters);return;
	  	// alert(2);
		let _this=this
	  	let jsondata = JSON.parse(jsApiParameters);
	  	// console.log('thisssss'+jsApiParameters);
	  	WeixinJSBridge.invoke(
	  		'getBrandWCPayRequest',
	  		 jsondata,
	  		function(res){
	  			if(res.err_msg == "get_brand_wcpay_request:ok"){
				 _this.getOfficialActivityTutorApplyDetail()
				}else if(res.err_msg == "get_brand_wcpay_request:cancel"){
					 Toast("用户取消支付!");
					 
				}else{
					Toast("支付失败!");
				}
	  		}
	  	);
	  },
	  acceptClick() {
		  console.log(this.ApplyDetail.cost)
		  userRechargeUniOrder({total_fee:this.ApplyDetail.cost,select_type:3,order_id:this.ApplyDetail.order_id}).then(res=>{
		  			  // console.log(res)
		  			  this.jsApiCall(res.data)
		  })
			// postOfficialActivityTutorApplyCheck({ act_id: this.$route.query.act_id, action_type: 1 }).then(res => {
			//  Toast(res.msg);
			//   if (res.code==1) {
			// 	this.ApplyDetail.tutor_status=3
			//   }
			// })
	  },
	  refuseClick() {
	    postOfficialActivityTutorApplyCheck({ act_id: this.$route.query.act_id, action_type: 2, tutor_reason: this.message }).then(res => {
	  	 Toast(res.msg);
	      if (res.code==1) {
			  this.show=false
	        this.ApplyDetail.tutor_res_status=3
			
	      }
	    })
	  },
	  getOfficialActivityTutorApplyDetail(){
		  getOfficialActivityTutorApplyDetail({act_id:this.$route.query.act_id}).then(res=>{
		  	console.log(res)
			this.ApplyDetail=[]
		  	this.ApplyDetail=res.data
			this.tutor_status=res.data.tutor_status
			console.log(res.data.tutor_status)
			this.ApplyDetail.cost=this.ApplyDetail.cost==0.00?0.01:this.ApplyDetail.cost
		  	
		  })
	  }
  },
  mounted() {
	  	document.title ='督导预约';   
		this.getOfficialActivityTutorApplyDetail()
  }
}
</script>
<style lang="scss">

	.supervisionNotice{
		width: 100%;
		height: 100vh;
		background-color: #F7F8FF;
		padding: 15px;
		.bold{
			font-weight: bold;
		}
		.van-col{
			text-align: justify;
		}
		.refuse{
			color: #FF524D;
		}
		.van-popup--center.van-popup--round{
			border-radius: 6px;
		}
		.dudao {
			.popupBox {
				width: 300px;
				height: 200px;
				background-color: #fff;
				border-radius: 6px;
				padding: 0 20px 28px;
				text-align: center;
				position: relative;
				.van-cell {
				    padding: 11px 16px;
				}
				.title{
					font-size: 18px;
					color: #28334A;
					text-align: center;
					width: 100%;
					padding: 20px 0;
					font-weight: bold;
				}
		
				.btnBox {
					position: absolute;
					bottom: 20px;
					right: 34px;
					justify-content: flex-end;
					color: #919CB0;
		
					div:first-child {
						margin-right: 40px;
					}
		
					.suss {
						color: #0081FF;
					}
				}
			}
		}
		.bottom{
			display: flex;
			box-shadow:0px -6px 10px 0px rgba(145,156,176,0.1);
			justify-content: space-around;
			align-items: center;
			.btn1{
				position: relative;
				top: 0;
				left: 0;
				transform: translate(0);
				max-width:140px ;
			}
			.btnBor{
				background-color: #fff;
				border: 1px solid #0081FF;
				color: #0081FF;
			}
		}
		.super{
			width: 100%;
			background-color: #fff;
			border-radius: 6px;
			text-align: left;
			padding: 20px 15px;
			.vanRowBox{
				margin-bottom: 16px;
			}
		}
	}
	
	
</style>
