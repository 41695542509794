<template>
	<div class="supervisionNotice">
		<div class="super">
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">督导预约状态</span></van-col>
			  <van-col span="16" v-if="ApplyDetail.tutor_res_status!==3"><span class="bold" v-text="ApplyDetail.tutor_status==2?'未预约':ApplyDetail.tutor_status==3?'已接受，预约人未付款':ApplyDetail.tutor_status==4?'预约成功':''"></span></van-col>
			  <van-col span="16" v-if="ApplyDetail.tutor_res_status==3"><span class="bold refuse">已拒绝</span></van-col>
			</van-row>
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">活动名称</span></van-col>
			  <van-col span="16">【<span v-text="ApplyDetail.act_name"></span>】</van-col>
			</van-row>
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">活动时间</span></van-col>
			  <van-col span="16"><span v-text="ApplyDetail.act_start_time"></span></van-col>
			</van-row>
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">开课培训地点</span></van-col>
			  <van-col span="16"><span v-text="ApplyDetail.act_addr"></span></van-col>
			</van-row>
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">主讲导师</span></van-col>
			  <van-col span="16"><span v-text="ApplyDetail.act_author"></span></van-col>
			</van-row>
			
		</div>
		<van-popup v-model="show" :round="true" class="dudao">
			<div class="popupBox">
				<div class="title">
					拒绝原因
				</div>
				<van-field
				  v-model="message"
				  rows="2"
				  type="textarea"
				  placeholder="请填写拒绝原因"
				  style="border-radius: 6px;background-color: #F4F4F4;"
				/>
				<div class="flex btnBox">
					<div @click="show=false">暂不取消</div>
					<div class="suss" @click="refuseClick">确定</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="showTrue" :round="true" class="dudao">
			<div class="popupBox">
				<div class="title">
					接受预约
				</div>
				<div style="text-align: left;">
					您确定真的要接受这场培训的督导预约吗，接受后无法取消
				</div>
				<div class="flex btnBox">
					<div @click="showTrue=false">取消</div>
					<div class="suss" @click="acceptClick">确定</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="showOver" :round="true" class="dudao">
			<div class="popupBox">
				<div class="title">
					接受预约成功
				</div>
				<div style="text-align: left;">
					接受预约成功，请等待预约人付款，预 约人付款成功后才算完成督导预约
				</div>
				<div class="flex btnBox">
					<!-- <div @click="show=false">取消</div> -->
					<div class="suss" @click="showOver=false">确定</div>
				</div>
			</div>
		</van-popup>
		<!-- 顶部 -->
		<div class="bottom">
			<div class="btn btn1 btnBor"  @click="show=true" v-if="ApplyDetail.tutor_status==2">
				拒绝
			</div>
			<div class="btn btn1 btnBor" :style="ApplyDetail.tutor_status==2?'':'color:#ddd;border-color:#ddd;'"  v-if="ApplyDetail.tutor_status!=2">
				拒绝
			</div>
			<div class="btn btn1" @click="showTrue=true" v-if="ApplyDetail.tutor_status==2">
				接受
			</div>
			<div class="btn btn1"  :style="ApplyDetail.tutor_status==2?'':'background:#ddd;'"  v-if="ApplyDetail.tutor_status!=2">
				接受
			</div>
		</div>
	</div>
</template>

<script>
import { getOfficialActivityTutorApplyDetail,postOfficialActivityTutorApplyCheck } from '@/api';
import { Toast } from 'vant'
import areaList from '../../area.js'

export default {
  name: 'supervisionNotice',
  components: {
  },
  data(){
	  return{
		  showOver:false,
		  showTrue:false,
		  message:'',
		show:false,
		// 主任导师督导状态
		tutor_res_status:1,
		// 活动督导状态
		tutor_status:1,
		ApplyDetail:[]
		
	  }
  },

  methods:{
	  acceptClick() {
		  
	    postOfficialActivityTutorApplyCheck({ act_id: this.$route.query.act_id, action_type: 1 }).then(res => {
	  	 Toast(res.msg);
	      if (res.code==1) {
	        this.ApplyDetail.tutor_status=3
	      }
		  this.showOver=true
		  this.showTrue=false
	    })
	  },
	  refuseClick() {
	    postOfficialActivityTutorApplyCheck({ act_id: this.$route.query.act_id, action_type: 2, tutor_reason: this.message }).then(res => {
	  	 Toast(res.msg);
	      if (res.code==1) {
			this.show=false
	        this.ApplyDetail.tutor_res_status=3
			
	      }
	    })
	  },
  },
  mounted() {
	  	document.title ='督导预约';   
		getOfficialActivityTutorApplyDetail({act_id:this.$route.query.act_id}).then(res=>{
			console.log(res)
			this.ApplyDetail=res.data
		})
  }
}
</script>
<style lang="scss">

	.supervisionNotice{
		width: 100%;
		height: 100vh;
		background-color: #F7F8FF;
		padding: 15px;
		.bold{
			font-weight: bold;
		}
		.van-col{
			text-align: justify;
		}
		.refuse{
			color: #FF524D;
		}
		.van-popup--center.van-popup--round{
			border-radius: 6px;
		}
		.dudao {
			.popupBox {
				width: 300px;
				height: 200px;
				background-color: #fff;
				border-radius: 6px;
				padding: 0 20px 28px;
				text-align: center;
				position: relative;
				.van-cell {
				    padding: 11px 16px;
				}
				.title{
					font-size: 18px;
					color: #28334A;
					text-align: center;
					width: 100%;
					padding: 20px 0;
					font-weight: bold;
				}
		
				.btnBox {
					position: absolute;
					bottom: 20px;
					right: 34px;
					justify-content: flex-end;
					color: #919CB0;
		
					div:first-child {
						margin-right: 40px;
					}
		
					.suss {
						color: #0081FF;
					}
				}
			}
		}
		.bottom{
			display: flex;
			box-shadow:0px -6px 10px 0px rgba(145,156,176,0.1);
			justify-content: space-around;
			align-items: center;
			.btn1{
				position: relative;
				top: 0;
				left: 0;
				transform: translate(0);
				max-width:140px ;
			}
			.btnBor{
				background-color: #fff;
				border: 1px solid #0081FF;
				color: #0081FF;
			}
		}
		.super{
			width: 100%;
			background-color: #fff;
			border-radius: 6px;
			text-align: left;
			padding: 20px 15px;
			.vanRowBox{
				margin-bottom: 16px;
			}
		}
	}
	
	
</style>
