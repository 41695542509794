<template>
	<div class="ylc_list">
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad" class="flex">
				<div class="" v-for="(item,i) in list" v-if="list.length" :key="i" @click="$router.push({name:'lyc_details',query:{id:item.id}})"
				 style="width: 50%;" class="ylcBox_b">
					<div class="ylcBox">
						<!-- <img src="../../assets/bigSink/bg_zhezhao@2x.png" alt="" class="top"> -->
						<!-- <div class="filter" :style="'background-image: url('+ item.coverpic +');'">
						</div> -->
						<!-- <img :src="item.coverpic" alt="" class="bg"> -->
						<van-image
						  width="100%"
						  style="height: calc((100vh - 50px)/2);"
						  fit="cover"
						  :src="item.coverpic"
						  class="bg"
						/>
						<div class="botZhezhao"></div>
						<!-- <img src="../../assets/user/bofan.png" alt="" class="conter"> -->
						<div class="bot" style="width: 100%;">
							<!-- <div class="botzhezhao">
								<img src="../../assets/bg_zhezhao@2x.png" alt="" >
							</div> -->
							<div class="van-multi-ellipsis--l2 titleBot" style="text-indent:-8px;">
								<span style="font-size: 13px" v-text="'【'+item.type+'】'"></span>
								<div class="van-ellipsis" v-text="item.title" style="    padding-left: 8px;"></div>
							</div>
							<!-- <div class="flex">
								<div>
									<span v-text="item.view_num" class="number_0"></span> 次播放
								</div>
								<div class="time">
									<span v-text="setMinute(item.video_time)" style="color: #fff;"></span>
								</div>
							</div> -->
							<div class="pingBox flex" style="position: relative;padding: 5px 9px 7px;font-size:10px;">
								<div class="flex" style="position: relative;justify-content: initial;" >
									<div class="head" style="margin-right: 8px;">
										<img :src="item.userinfo.user_head_img" alt="" class="auto-img">
									</div>
									<div class="van-ellipsis" style="width:55px;text-align: left;">
										<span v-text="item.userinfo.user_name"></span>
									</div>
								</div>
								<div class="flex" style="color: #919CB0;letter-spacing:0px;font-size: 10px;">
									<div class="flex" >
										<!-- <img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="!item.iszan">
										<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="item.iszan"> -->
										<span v-text="item.view_num"></span>次播放·
									</div>
									<div class="flex" >
										<!-- <img src="../../assets/bigSink/icon_pinlun_s@2x.png" alt="" style="width: 20px;margin-right: 5px;"> -->

										<span v-text="item.praise_num"></span>赞
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- <div style="width: 100%;background-color: #F7F8FF;height: 10px;"></div> -->
				</div>
			</van-list>
		</van-pull-refresh>
		<div v-if="!list.length" style="padding: 0 41px;">

			<img src="../../assets/bigSink/pic_nodota@2x.png" alt="" style="margin-top: 110px;width: 150px;margin-bottom: 40px;">
			<div style="text-align: center;font-size: 13px;">
				听说第一个吃螃蟹的人尝到的味道最鲜美，您来
				发布第一个急救技能操作演练视频吧！
			</div>
		</div>
		<div class="flex bottom" style="justify-content: space-between;padding: 10px 15px;			z-index: 6;">
			<div class="flex" style="position: relative;" @click="isseach=!isseach">
				<div class="seach" :class="isseach?'isseach':'isseach1'">
					<div style="border-bottom: 1px solid #E5E5E5;" @click="seachText='time'">时间</div>
					<div @click="seachText='hot'">热度</div>
				</div>
				<img src="../../assets/bigSink/icon_paixu@2x.png" alt="" style="width: 16px;margin:0 8.5px;">
				<div style="color: #0081FF;" v-text="seachText=='time'?'时间':'热度'"></div>
			</div>
			<div class="btn1" @click="$router.push({name:'ylc_release'})">发布</div>
		</div>
	</div>
</template>

<script>
	import {getVideoList} from '@/api'
	import { setMinute } from '@/util/setTime.js'
	export default {
  name: 'ylc_list',
  components: {
  },
  data(){
	  return{
		  // 搜索
		  seachText:'time',
		  // 下拉刷新
		   refreshing: false,
		   // 加载状态
		   loading: false,
		   finished: false,
		   page:1,
		   limit:10,
		   count:0,
		  isseach:false,
		list:[
			
		]
	  }
  },
  watch:{
	  seachText(){
		  this.onRefresh()
	  }
  },
  methods:{
	  setMinute(time){
		  return setMinute(time)
	  },
	 onRefresh(seachText) {
	      // 清空列表数据
	 			this.list=[]
	 			this.page=1
	      this.finished = false;
	      // 重新加载数据
	      // 将 loading 设置为 true，表示处于加载状态
	      this.loading = true;
	      this.onLoad();
	    },
	 onLoad() {
	      // 异步更新数据
	       getVideoList({page:this.page,limit:this.limit,order:this.seachText}).then(res=>{
	 				 this.list.push(...res.data.list);
	 				 this.page++
	 				 this.count=res.data.count
	 				  // 加载状态结束
	 				  this.loading = false;
	 				  this.refreshing=false
	 				console.log(this.loading)
	 				  // 数据全部加载完成
	 				  if (this.list.length >=  this.count) {
	 					this.finished = true;
	 				  }
	 			    })
	    },
		
  },
  mounted() {
  	document.title = '急救视频';   
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.ylc_list {
		min-height: 100vh;
		background-color: #fff;
		padding-bottom: 50px;
		.ylcBox_b{
			border-radius: 8px;
			overflow: hidden;
			&:nth-child(1){
				// margin-top: 2px;
			}
			&:nth-child(odd){
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
				border-top: 2px solid #fff;
			}
			&:nth-child(even){
				border-left: 2px solid #fff;
				border-bottom: 2px solid #fff;
				border-top: 2px solid #fff;
			}
			&:nth-child(1),&:nth-child(2){
				border-top:none;
			}
		}
		.ylcBox {
			width: 100%;
			height: calc((100vh - 50px)/2);
			position: relative;
			background: #fff;
			overflow: hidden;

			.top {
				// position: absolute;
				// top: 0;
				// left: 0;
				// z-index: 5;

			}

			.flexTitle {
				align-items: initial;
				text-align: left;

			}
			.botZhezhao{
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 6;
				font-size: 12px;
				width: 100%;
				height: 100px;
				background:linear-gradient(0deg,rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
			}
			.bot {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 6;
				font-size: 12px;
				

				.titleBot {
					padding: 0px 8px;
					color: #FFFFFF;
					text-align: left;
					font-size: 15px;
					font-weight: bold;
				}

			}

			.bg {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}

			.flex {

				z-index: 6;
				color: #fff;
				justify-content: space-between;
				// width: 100%;
				// padding: 10px 15px;
			}

			.conter {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 50px;
				height: 50px;
			}

			.time {
				background-color: rgba(0, 0, 0, .5);
				border-radius: 50px;
				padding: 1px 7px;
				transform: scale(.9);
			}
		}

		.pingBox {
			// position: absolute;

			.head {
				border-radius: 50%;
				width: 22px;
				height: 22px;

				img {
					border-radius: 50%;
				}
			}
		}

		.bottom {
			height: 50px;
			box-shadow: 1px 5px 14px 1px rgba(35, 35, 35, 0.6);

			.btn1 {
				width: 63px;
				height: 30px;
				line-height: 30px;
				background-color: #0081FF;
				color: #fff;
				border-radius: 50px;

			}
		}

		.seach {
			position: absolute;
			top: -95px;
			left: -15px;
			padding: 0 10px;
			min-width: 100px;
			min-height: 70px;
			background-color: #F1F1F1;
			transition: all .5s;
			z-index: 5;
			color: #919CB0;

			div {
				padding: 10px 0;
				background-color: #F1F1F1;

			}
		}

		.isseach {
			visibility: visible;
			opacity: 1;
			top: -95px;
		}

		.isseach1 {
			visibility: hidden;
			opacity: 0;
			top: 95px;
		}

		.filter {
			// width: 100%;
			height: 100%;
			filter: blur(3px);
			background-position: -50px;
		}
	}
</style>
