<template>
  <div class="conser-list-wrap">
    <defaultVue v-if="!list.length"
			:defaultImg="require('@/assets/default/icon_zanwuwdpx@2x.png')"
			defaultText="暂无数据"
		></defaultVue>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
				<div v-for="(item,index) in list" :key="index">
					<van-cell @click="lookMsgDetail(item.id)">
						<div slot="default" class="flex list" >
              <div style="width: 100%">
                <div class="flex">
                  <span class="title">{{item.question}}</span>
                  <div class="label-tag" :class="{'ping-col': item.status == 1}">{{item.status == 1 ? '已回复' : '未回复'}}</div>
                </div>
                <p>{{item.create_time}}</p>
              </div>
              <van-icon slot="right-icon" name="arrow" style="line-height: inherit;" />
						</div>
					</van-cell>
				</div>
			</van-list>
		</van-pull-refresh>
    <van-action-sheet v-model="showMsg" title="消息详情">
      <div class="msg-content">
        <p v-for="(item, index) in replyList" :key="index">
         <span class="role">{{item.from == 'ins' ? '机构' : '我'}}：</span>
         <span>{{item.content}}</span>
         <span class="contime">{{item.time}}</span>
        </p>
      </div>
      <div class="bom-btm flex">
        <input v-model="value" placeholder="输入回复消息" style="background-color: transparent;" />
        <div class="buttons" @click="focus">回复</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import defaultVue from '@/components/default/index.vue';
import { getMyConsultant, consultantDetail,  continueConsultant } from '@/api/';
export default {
 name: 'consultingServiceList',
 components: { defaultVue },
 data() {
  return {
    list:[],
		refreshing: false,
		loading: false,
		finished: false,
		page:0,
		limit:'10',
    title: '消息列表',
    showMsg: false,
    replyList: [],
    conId: '',
    value: '',
  }
 },
 methods: {
  focus() {
    if(!this.value) return this.$toast("请输入回复消息");
    continueConsultant({ con_id: this.conId, content: this.value}).then(res => {
      this.value = '';
      this.lookMsgDetail(this.conId);
    })
  },
  lookMsgDetail(id) {
    this.conId = id;
    consultantDetail({ con_id: id }).then(res => {
      if(res.code === 1) {
        let { reply } = res.data.info;
        if(!reply) return this.$toast("无更多回复");
        this.replyList = reply
        this.showMsg = true;
      }
    })
  },
  onRefresh() {
		this.finished = false;
		this.list=[]
		this.page=0
		this.loading = true;
		this.onLoad();
	},
  onLoad() {
		this.page++;
		getMyConsultant({page: this.page, limit: this.limit}).then(res => {
			if(res.code === 1) {
				if (this.refreshing) {
				  this.list = [];
				  this.refreshing = false;
				}
				this.list.push(...res.data)
				this.refreshing=false
				this.loading = false;
				if (res.data == 0) {
				 this.finished = true;
				}
			} else{
				this.list = [];
				this.finished = true;
			}
		})
	}
 },
 mounted() {
  document.title = this.title;
 }
}
</script>

<style lang="scss" scoped>
.conser-list-wrap {
  .list {
    justify-content: space-between;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #cbcbcb;
    .title {
      font-weight: 600;
      font-size: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 15rem;
    }
    .label-tag {
			display: flex;
			justify-content: center;
			align-items: center;
			background: #ffb366;
			border-radius: 1rem;
			text-align: center;
			color: #fff;
			padding: 0.015rem 0.35rem;
			font-size: 0.75rem;
			margin-left: 1rem;
		}
    .ping-col {
			background: #0081FF;
		}
  }
  /deep/ .van-cell {
    padding-top: 0px;
  }
  .msg-content {
    text-align: left;
    padding: 1rem 1rem 10rem;
    .role {
      font-weight: 600;
    }
    .contime {
      margin-left: 1rem;
      color: rgb(115, 185, 255);
    }
  }
  .bom-btm {
    margin: 5px;
    input {
      width: 80%;
      padding: 0.6rem;
      background: #d6d5d5;
      border: 1px solid #f4f4f4;
    }
    .buttons {
      width: 20%;
      height: 2.3rem;
      line-height: 2.3rem;
      border-radius: 5px;
      color: #fff;
      background: #1989fa;
    }
  }
}
</style>