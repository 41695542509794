export const unique = function unique(arr, paramList) {
	let arr_z = []
	arr.forEach(item=>{
		if(item.content){
			arr_z.push(item)
		}
	})
  const res = new Map()
  for (let i = 0; i < paramList.length; i++) {
    arr_z = arr_z.filter(temp => !res.has(temp[paramList[i]]) && res.set(temp[paramList[i]]))
  }
  return arr_z
}
export const isStatic = function isStatic(value) {
    return(
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        typeof value === 'undefined' ||
        value === null
    )
}