<template>
	<div class="tutorHomepage hotText" :style="count?'background-color: #F7F8FF;':'background-color: #fff;'">
		<div class="imgBox">
			<img src="../../assets/user/bg_zrdaoshigeren@2x.png" alt="" class="auto-img" v-if="details.user_type==3">
			<img src="../../assets/user/bg_daoshigeren@2x.png" alt="" class="auto-img" v-if="details.user_type==2">
			<img src="../../assets/bg_gerenzx@2x.png" alt="" class="auto-img" v-if="details.user_type==1||details.user_type==0">
		</div>
		<div class="bg">
			
		</div>
		<div class="containers">
			<div class="flex">
				<div style="position: relative;height: 150px;width: 117px;">
					<div class="imageVan" :class="details.user_sex==1?'imageVanNan':details.user_sex==2?'imageVannv':''">
						<van-image  :src="userImg" class="auto-img" radius="50%" @click="tagg">
							<template v-slot:error>
								<img src="../../assets/user/icon_mrtx_nan@2x.png" alt="" style="width: 3.75rem;border-radius: 50%;">
							</template>
						</van-image>
					</div>
						
				</div>
				<div class="right">
				<div class="top">
					<van-row>
					  <van-col span="8">
						  <div class='number'>
							  <span v-text="details.fans_num"></span>
						  </div>
						  <div style="font-size: 12px;">
							  被关注数
						  </div>
					  </van-col>
					  <van-col span="8" v-if="details.user_type==3||details.user_type==2">
						  <div class='number'>
								 <span v-text="details.act_count"></span>
						  </div>
						  <div style="font-size: 12px;">
								培训场数
						  </div>
					  </van-col>
					  <van-col span="8" v-if="details.user_type==3||details.user_type==2">
						  <div class='number'>
								 <span v-text="details.student_count"></span>
						  </div>
						  <div style="font-size: 12px;">
								<span v-text="details.user_sex==1?'他':'她'"></span>的学员
						  </div>
					  </van-col>
					  <van-col span="8" v-if="details.user_type==1||details.user_type==0">
						  <div class='number'>
								 <span v-text="details.article_count"></span>
						  </div>
						  <div style="font-size: 12px;">
								发布文章
						  </div>
					  </van-col>
					  <van-col span="8" v-if="details.user_type==1||details.user_type==0">
						  <div class='number'>
								 <span v-text="details.video_count"></span>
						  </div>
						  <div style="font-size: 12px;">
								演练视频
						  </div>
					  </van-col>
					</van-row>
				</div>
				<div class="bottom1">
					<div :class="isFollow?'No_addBtn':'addBtn'" v-text="isFollow?'已关注':'+ 关注'" @click="follow" v-if="details.user_id!=user_id">
					</div>
					<div class="private" @click="$router.push({ path: '/letter', query: { id: details.user_id,name:details.user_name }});" :style="details.user_id!=user_id?'opacity:1':'opacity:0'">
						
					</div>
				</div>
			</div>
			</div>
			<div class="conten">
				<div class="name_top">
					<div class="flex" style="				margin-bottom: 10px;">
						<div class="name" v-text="details.user_name">
							
						</div>
						<div class="flex">
							<div class="tag flex" :style="details.user_type==2?'color:#FCA135':details.user_type==3?'color:#FF625D':''">
								<!-- <img src="../../assets/user/icon_daoshi@2x.png" alt="" class="xin" v-if="details.user_type==1"> -->
								<img src="../../assets/user/icon_daoshi@2x.png" alt="" class="xin" v-if="details.user_type==2">
								<img src="../../assets/user/icon_zrdaoshi@2x.png" alt="" class="xin" v-if="details.user_type==3">
								<span v-text="details.user_type==1?'学员':details.user_type==2?'导师':details.user_type==3?'主任导师':''" style="transform: scale(0.83);"></span>
							</div>
						</div>
					</div>
					<div class="autograph" v-text="'签名：'+(details.user_signature?details.user_signature:'暂无')">
					</div>
					<div class="autograph" style="margin-top: 5px;" v-text="'证书有效期：'+(details.cert_endtime?details.cert_endtime:'暂无')">
					</div>
				</div>
				<div class="biao_top">
					<div class="flex" style="align-items: inherit;"  v-if="zizhi.length">
						<img src="../../assets/Train/icon_zizhi@2x.png" alt="" style="width: 16px;height: 16px;margin-right: 9px;" >
						<div class="autograph">
							<div v-for="(item,i) in zizhi" :key="i" v-text="item">
								
							</div>
						</div>
					</div>
					<div class="flex margins" style="align-items: inherit;" >
						<img src="../../assets/Train/icon_jigou@2x.png" alt="" style="width: 16px;height: 16px;margin-right: 9px;" >
						<div class="autograph">
							<div v-text="details.institution?details.institution:'暂无'">
								
							</div>
						</div>
					</div>
					<div class="flex margins" style="align-items: inherit;">
						<img src="../../assets/Train/icon_jieshao@2x.png" alt="" style="width: 16px;height: 16px;margin-right: 9px;" >
						<div class="autograph" style="width: calc(100% - 16px - 9px);">
							<div v-text="details.user_info?details.user_info:'暂无'" style="color: #28334A;">
								
							</div>
						</div>
					</div>
				</div>
				<van-tabs v-model="active">
				  <van-tab v-for="(item,index) in tab" :key="index">
				    <div slot="title" >
						<span v-text="item"	style="font-size: 15px;font-weight: bold;color: #919CB0;" class="van-tab-activation"></span>
				    </div>
				   
				  </van-tab>
				</van-tabs>
			</div>
			<!-- 内容 {{ index }} -->
			<div v-if="tab[active]=='培训课程'" class="px" >
				<div v-if="count==0" class="white">
					<img src="../../assets/default/icon_nodota.png" alt="" width="150">
					<p style="margin-top: 15px;">暂无培训课程</p>
				</div>
				<van-list v-model="loading" :finished="finished" finished-text=" " @load="onLoad" v-if="count>0">
					<van-cell v-for="(item,index) in list" :key="index" @click="$router.push({name:'courseDetails',query:{act_id:item.id}})">
						<div slot="default" class=" list" style="display: flex;">
							<div class="list_img" style="position: relative;">
								<img src="../../assets/PX/icon_baomz@2x.png" alt="" width="45" class="icon"  v-if="item.act_status==1">
								<img src="../../assets/PX/icon_yijieshu@2x.png" alt="" width="45" class="icon" v-else-if="item.act_status==2">
								<img src="../../assets/PX/icon_yifankui@2x.png" alt="" width="45" class="icon" v-else>
								<van-image width="100" height="100" :src="item.cover_img" radius='8px' />
							</div>
							<div style="width: 100%;">
								<div v-text="item.act_name" class="list_title"></div>
								<!-- <h4 v-text=""></h4> -->
								<div class="flex" style="    justify-content: space-between;">
									<!-- 标题下左右边两个div -->
									<div>
										<div class="flex">
											<img src="../../assets/PX/icon_kecheng@2x.png" alt="" style="width: 12px;height: 12px;margin-right: 0.5rem;">
											<div class="text" v-for="(items,i) in item.train_course" style="margin: 0;" :key="i">
												<span  v-text="items==2?'CPR+AED使用课程':items==3?'创伤救护课程':items==4?'导师线下培训课程':items==5?'其他':''"></span><span v-if="i!=item.train_course.length-1" >+</span>
											</div>
										</div>
										<!-- 左边时间 -->
										<div class="flex">
											<img src="../../assets/Train/icon_time@2x.png" alt="" style="width: 12px;height: 12px;">
											<div v-text="item.act_start_time" class="text"></div>
											<div class="label-tag" :class="{'ping-col': item.act_type == 1}" v-text="item.act_type == 1 ? '学员班' : '导师班'"></div>
										</div>
										<!-- 左边地址 -->
										<div class="flex">
											<img src="../../assets/Train/icon_xdizhi@2x.png" alt="" style="width: 12px;height: 14px;">
											<div v-text="item.city" class="text"></div>
										</div>
									</div>
									<!-- <div>
										<div :class="item.act_status==1?'btnimg1':item.act_status==2?'btnimg2':'btnimg3'" class="btnimg">
											<span v-text="item.act_status==1?'报名中':item.act_status==2?'已结束':item.act_status==3?'已下线':'已反馈'" class="btnimg_span"></span>
										</div>
									</div> -->
								</div>
							</div>
						</div>
					</van-cell>
				</van-list>
			</div>
			<div v-if="tab[active]=='文章'" :style="count==0?'':'padding: 15px;'">
				<!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
				<div v-if="count==0" class="white">
					<img src="../../assets/default/icon_zanwufbwz.png" alt="" width="150">
					<p style="margin-top: 15px;">暂无发布文章</p>
				</div>
				<van-list
				  v-model="loading"
				  :finished="finished"
				  finished-text=""
				  @load="onLoad"
				  style="text-align: left;"
				>
				<div v-for="(item,i) in list" :key="i" @click="$router.push({name:'viewArticles',query: { id: item.id}})">
					<div class="flex topBox">
						<div class="flex" style="justify-content: space-between;width: 100%;">
						<div class="flex">
							<div style="margin-right: 10px;">
								<img :src="item.userinfo.user_head_img" alt="" style="width: 41px;border-radius: 50%;">
							</div>
							<div>
								<p style="font-weight: Bold;"><span v-text="item.userinfo.user_name"></span></p>
								<p class="time" ><span v-text="setTime(item.createtime)"></span></p>
							</div>
						</div>
						
						</div>
						<div style="margin-top: 18px;width: 100%;" v-if="item.type==1"> <span v-text="item.title" style="font-weight: bold;"></span></div>
						<div class="van-multi-ellipsis--l2" style="margin: 16px 0;color: #767E8D;" v-if="item.type==1">
							<span v-text="deleteHtmlTag(item.content)"></span>
						</div>
						<div class="van-multi-ellipsis--l2" style="margin: 16px 0 0;color: #767E8D;" v-if="item.type==2">
							<span v-text="item.thank"></span>
						</div>
						<div v-if="item.type==1&&item.cover_picture">
							<!-- <img src="../../assets/123.jpg" alt="" style="height: 170px;width: 100%;border-radius: 6px;"> -->
							<img :src="item.cover_picture" alt="" style="height: 170px;width: 100%;">
						</div>
						<div v-if="item.type==2" class="article flex" @click.stop="urlClick(i)">
						
								<img :src="item.cover_picture?item.cover_picture:require('@/assets/bigSink/icon_moren200_pre@2x.png')" alt="" style="width: 40px;height: 40px;">
						
							<div style="padding:0px 12.5px;width: calc(100% - 50px);">
								<div v-text="item.share_title" style="font-weight: bold;margin-bottom: 7.5px;"></div>
								<div v-text="item.share_url" style="font-size: 10px;color: #767E8D;"></div>
							</div>
						</div>
						<div class="flex kan">
							<div>
								<span v-text="item.browse_num" class="number_0"></span> 人看过 · <span v-text="item.comment_num" class="number_0"></span> 评论
							</div>
							<div class="flex" >
								<span v-text="item.praise_num" class="number_0" v-if="item.praise_num"></span>
								<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;height: 20px;margin-left: 10px;" v-if="!item.is_praise" @click.stop="zanClick(i,1)">
								<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;height: 20px;margin-left: 10px;" v-if="item.is_praise" @click.stop="zanClick(i,2)">
							</div>
						</div>
					</div>
					
				</div>
				</van-list>
				<!-- </van-pull-refresh> -->
			</div>
			<div v-if="tab[active]=='演练视频'">
				<div v-if="count==0" class="white">
					<img src="../../assets/default/icon_ylsp.png" alt="" width="150">
					<p style="margin-top: 15px;">暂无演练视频</p>
				</div>
				<van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
					<div class="bigBox" v-for="(item,i) in list" v-if="list.length" :key="i" @click="$router.push({name:'lyc_details',query:{id:item.id}})">
						<div class="ylcBox" style="overflow: hidden;">
							<img src="../../assets/bigSink/bg_zhezhao@2x.png" alt="" class="top">
							<div class="filter" style="height: 100%;overflow: hidden;" :style="'background-image: url('+ item.coverpic +');'">
							</div>
							<img :src=" item.coverpic" alt="" style="height: 100%;margin:auto;display: flex;z-index: 2;position: relative;">
							<div class="flex top">
								<div><span v-text="item.title"></span></div>
								<div><span v-text="'【'+item.type+'】'"></span></div>
							</div>
							<div class="flex bot">
								<div>
									<span v-text="item.view_num" class="number_0"></span> 次播放
								</div>
								<div class="time">
									<span v-text="setMinute(item.video_time)"></span>
								</div>
							</div>
							<img src="../../assets/user/bofan.png" alt="" class="conter">
				
						</div>
						<div class="pingBox flex" style="justify-content: space-between;padding: 10px 15px;">
							<div class="flex">
								<div class="head" style="margin-right: 8px;">
									<img :src="item.userinfo.user_head_img" alt="" class="auto-img">
								</div>
								<div>
									<span v-text="item.userinfo.user_name"></span>
								</div>
							</div>
							<div class="flex" style="color: #919CB0;letter-spacing:0px;font-size: 12px;">
								<div class="flex" style="margin-right: 12px;">
									<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="!item.iszan">
									<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="item.iszan">
									<span v-text="item.praise_num"></span>
								</div>
								<div class="flex" style="margin-right: 12px;">
									<img src="../../assets/bigSink/icon_pinlun_s@2x.png" alt="" style="width: 20px;margin-right: 5px;">
				
									<span v-text="item.reply_num"></span>
								</div>
							</div>
						</div>
						<div style="width: 100%;background-color: #F7F8FF;height: 10px;"></div>
					</div>
				</van-list>
			</div>
			<div v-if="tab[active]=='急救视频'">
				<div v-if="count==0" class="white">
					<img src="../../assets/default/icon_ylsp.png" alt="" width="150">
					<p style="margin-top: 15px;">暂无急救视频</p>
				</div>
				<van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
					<div class="bigBox" style="padding-bottom: 0;"  v-for="(item,i) in list" v-if="list.length" :key="i" @click="$router.push({name:'lyc_details',query:{id:item.id}})">
						
						<div class="ylcBox"  style="height: auto;background-color: #fff;">
							<div class="" style="margin-bottom: 12px;padding: 15px 15px 0;background-color: #fff;">
								<div><span v-text="item.title" style="font-weight: bold;"></span></div>
							</div>
							<div style="position: relative;overflow: hidden;">
								<div class="filter" :style="'background-image: url('+  item.coverpic+');'">
								</div>
							<img :src=" item.coverpic" alt="" class="" style="height: 231px;margin:auto;display: flex;z-index: 2;position: relative;">
							
						<!-- 	<div class="flex bot">
								<div>
									<span v-text="item.view_num" class="number_0"></span> 次播放
								</div>
								<div class="time">
									<span v-text="setMinute(item.video_time)"></span>
								</div>
							</div> -->
							<img src="../../assets/user/bofan.png" alt="" class="conter">
							</div>
						</div>
						<div class="pingBox flex" style="justify-content: space-between;padding: 10px 15px;">
							<div class="flex">
								<div class="head" style="margin-right: 8px;">
									<img :src="item.userinfo.user_head_img" alt="" class="auto-img">
								</div>
								<div>
									<span v-text="item.userinfo.user_name"></span>
								</div>
							</div>
							<div class="flex" style="color: #919CB0;letter-spacing:0px;font-size: 12px;">
								<div class="flex" style="margin-right: 12px;">
									<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="!item.iszan">
									<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="item.iszan">
									<span v-text="item.praise_num"></span>
								</div>
								<div class="flex" style="margin-right: 12px;">
									<img src="../../assets/bigSink/icon_pinlun_s@2x.png" alt="" style="width: 20px;margin-right: 5px;">
				
									<span v-text="item.reply_num"></span>
								</div>
							</div>
						</div>
						<div style="width: 100%;background-color: #F7F8FF;height: 10px;"></div>
					</div>
				</van-list>
			</div>
		</div>
		
		</div>
</template>

<script>
	import {getInfo,postFollow,getActivityLists,getWeixinAidHotArticleList,getVideoList,postWeixinAidHotArticlePraise,JjgetVideoList} from '@/api'
	import { Toast } from 'vant';
	import { setTime,setMinute } from '@/util/setTime.js'
	export default {
		name: 'tutorHomepage',
		components: {},
		watch:{
			active(){
				console.log(this.active)
				this.list=[]
				this.page=1
				this.loading=true
				this.finished=false
				this.count=1
				this.onLoad()
				
			}
		},
		data() {
			return {
				count:1,
				user_type:localStorage.getItem('user_type'),
				page:1,
				limit:4,
				// 判断是不是本人
				user_id:JSON.parse(localStorage.getItem('user_data')).user_id,
				details:{},
				// 是否关注
				isFollow:false,
				tab:['培训课程','文章','演练视频','急救视频'],
				active:0,
				fileList:[],
				isgen:false,
				id: '2019170003542',
				grade: '导师',
				userImg:'https://img.yzcdn.cn/vant/cat.jpeg',
				// 标题
				title: '导师主页',
				name: '李冰',
				issud:2,
				autograph:'sssssssssssssssssssssss',
				zizhi:[
					
				],
				company:'',
				Label:'',
				list:[],
				list1: [
					// {
					// img:'https://img.yzcdn.cn/vant/cat.jpeg',
					// title:'成人心肺复苏培训',
					// time:'2019-11-20  19:00',
					// addres:'海口市',
					// type:3
					// /**
					//  * type==1 报名中
					//  * type==2 已结束
					//  * type==3 已反馈
					//  * */
					//  },
					//  {
					//  	img:'https://img.yzcdn.cn/vant/cat.jpeg',
					//  	title:'成人心肺复苏培训',
					//  	time:'2019-11-20  19:00',
					//  	addres:'海口市',
					//  	type:1
					//  	/**
					//  	 * type==1 报名中
					//  	 * type==2 已结束
					//  	 * type==3 已反馈
					//  	 * */
					//  }																	 
				],
				loading: false,
				finished: false,
			}
		},
		methods: {
			setMinute(time){
					  return setMinute(time)
			},
			//去除html标签
			deleteHtmlTag(str){
			 str = str.replace(/<[^>]+>|&[^>]+;/g,"").trim();//去掉所有的html标签和&nbsp;之类的特殊符合
			 return str;
			},
			// 格式化时间
			setTime(time){
						return setTime(time)
					},
			// 点赞
			zanClick(i,type){
					  
					  switch (this.active){
					  	case 0:
						postWeixinAidHotArticlePraise({hot_article_id:this.list1[i].id,type:type}).then(res=>{
							if(res.code==1&&type==1){
								Toast('点赞成功')
								this.list1[i].is_praise=1
								this.list1[i].praise_num=this.list1[i].praise_num+1
							}else if(res.code==1&&type==2){
								Toast('取消点赞成功')
								this.list1[i].is_praise=0
								this.list1[i].praise_num=this.list1[i].praise_num-1
							}else{
								Toast(res.msg)
							}		
						})
					  		break;
					  	case 1:
						postWeixinAidHotArticlePraise({hot_article_id:this.list[i].id,type:type}).then(res=>{
								Toast(res.msg)
								if(res.code==1&&type==1){
									Toast('点赞成功')
									this.list1[i].is_praise=1
									this.list1[i].praise_num=this.list1[i].praise_num+1
								}else if(res.code==1&&type==2){
									Toast('取消点赞成功')
									this.list1[i].is_praise=0
									this.list1[i].praise_num=this.list1[i].praise_num-1
								}else{
									Toast(res.msg)
								}			  
						})
					  		break;
					  }
					 
			},
			// 关注函数
			follow(){
				this.isFollow=!this.isFollow
				console.log({tutor_id:this.$route.query.id,is_follow:this.isFollow?1:0})
				postFollow({tutor_id:this.$route.query.id,is_follow:this.isFollow?1:0}).then(res=>{
					// console.log(res)
					if(res.code==1){
						Toast(res.msg);
					}
				})
			},
			onLoad() {
				if(this.tab[this.active]=='培训课程'){
					this.getActivityLists()
				}else if(this.tab[this.active]=='文章'){
					this.getWeixinAidHotArticleList()
				}else if(this.tab[this.active]=='演练视频'){
					this.getVideoList()
				}else if(this.tab[this.active]=='急救视频'){
					this.JjgetVideoList()
				}
			},
			JjgetVideoList(){
				JjgetVideoList({page:this.page,limit:this.limit,user_id:this.$route.query.id}).then(res=>{
					res.data=res.data?res.data:{
						count:0,
						list:[]
					}
					this.list.push(...res.data.list);
					this.page++
					this.count=res.data.count
					// 加载状态结束
					this.loading = false;
					this.refreshing=false
					console.log(this.loading)
					// 数据全部加载完成
					if (this.list.length >=  this.count) {
						this.finished = true;
					}
				})
			},
			
			open() {
				this.isopen = !this.isopen
			},
			tagg(){
				this.isgen=!this.isgen
			},
			getActivityLists(){
				getActivityLists({user_id:this.$route.query.id,page:this.page,limit:this.limit}).then(res=>{
						res.data=res.data?res.data:{
							count:0,
							list:[]
						},
						this.page++
						this.count=res.data.count

						let train_courseArr=[]
						res.data.list.forEach((items,i)=>{
							let arr =items.train_course.split('|');
							arr.forEach((item,i)=>{
								if(item){
									train_courseArr.push(item)
								}
							})
							items.train_course=train_courseArr
							if(items.train_course.includes('2') && items.train_course.includes('3')) {
								items.cover_img = require('../../assets/PX/pic_cprtrauma.png')
							} else if(items.train_course.includes('2')) {
								items.cover_img = require('../../assets/PX/pic_huodong@2x.png')
							} else if (items.train_course.includes('3')) {
								items.cover_img = require('../../assets/PX/pic_trauma.png')
							} else if(items.act_type == 2) {
								items.cover_img = require('../../assets/PX/pic_teacher.png')
							}
							train_courseArr=[]
						})

						this.list.push(...res.data.list);
						
						 // 加载状态结束
						 this.loading = false;
						 // 数据全部加载完成
						 if (this.list.length >= this.count) {
						  this.finished = true;
						 }
					
				})
			},
			getVideoList(){
				getVideoList({user_id:this.$route.query.id,page:this.page,limit:this.limit}).then(res=>{
					res.data=res.data?res.data:{
						count:0,
						list:[]
					},
					this.page++
					this.count=res.data.count
					 this.list.push(...res.data.list);
					 // 加载状态结束
					 this.loading = false;
					 // 数据全部加载完成
					 if (this.list.length >= this.count) {
					  this.finished = true;
					 }
				})
			},
			getWeixinAidHotArticleList(){
				getWeixinAidHotArticleList({user_id:this.$route.query.id,page:this.page,limit:this.limit}).then(res=>{
					this.count=res.data.count
					 this.list.push(...res.data.list);
					 // 加载状态结束
					 this.page++
					 this.loading = false;
					 // 数据全部加载完成
					 if (this.list.length >= this.count) {
					  this.finished = true;
					 }
				})
			},
			getWeixinAidHotArticleList(){
				console.log(this.list)
				getWeixinAidHotArticleList({user_id:this.$route.query.id,page:this.page,limit:this.limit}).then(res=>{
					this.count=res.data.count
					this.page++
					 this.list.push(...res.data.list);
					 // 加载状态结束
					 this.loading = false;
					 // 数据全部加载完成
					 if (this.list.length >= this.count) {
					  this.finished = true;
					 }
				})
			}
		},
		mounted() {
			document.title = this.title;
			
			getInfo({user_id:this.$route.query.id}).then(res=>{
				console.log(res)
				this.details=res.data
				if(res.data.user_type==1||res.data.user_type==0){
					this.tab=['文章','演练视频','急救视频']
				}
				this.userImg=res.data.user_head_img
				this.isFollow=res.data.is_follow
				// res.data.qualifications="国际创伤生命支持导师"
				// console.log(res.data.qualifications)
				this.zizhi=res.data.qualifications?res.data.qualifications.split(';'):[]
				
				// console.log(this.zizhi)
				
				
			})
		}
		
	}
</script>

<style lang="scss">
	.list_title {
		justify-content: space-between;
		background-color: #fff;
		
		// margin: 20px 0 20px;
		.list_title_left {
			width: 0.1875rem;
			height: 1.125rem;
			background: #0081FF;
			border-radius: 0 0.09375rem 0.09375rem 0;
			margin-right: 0.75rem;
		}
	
		.list_title_img {
			width: 0.4375rem;
			height: 0.6875rem;
			margin-right: 0.90625rem;
			margin-left: 0.28125rem;
		}
	
		.left {
			font-size: 1.125rem;
			color: #28334A;
			font-weight: bold;
		}
	
		.right {
			font-size: 0.9375rem;
			color: #0081FF;
		}
	}
	.px{
		.van-cell{
			padding-bottom: 10px !important;
			padding-top: 0;
		}
	}
	.list {
		flex-wrap: inherit;

		.label-tag {
			display: flex;
			justify-content: center;
			align-items: center;
			background: #ffb366;
			border-radius: 1rem;
			text-align: center;
			color: #fff;
			padding: 0.015rem 0.35rem;
			font-size: 0.75rem;
			margin-left: 1rem;
		}
		.ping-col {
			background: #0081FF;
		}
		
		.list_img {
			margin-right: 0.71875rem;
			.icon{
				position: absolute;
				top: 10px;
				left: -3px;
				z-index: 2;
			}
		}
	
		.list_title {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			font-weight: bold;
			font-size: 13px;
			color: #28334A;
		}
	
		.text {
			font-size: 0.75rem;
			color: #767e8d;
			margin-left: 0.5rem;
		}
	
		.btnimg {
			width: 3.3125rem;
			height: 1.625rem;
			text-align: center;
			line-height: 1.625rem;
			background: no-repeat center;
			background-size: 100% 100%;
			font-size: 0.6875rem;
			color: #FEFEFE;
		}
	
		.btnimg1 {
			background-image: url(../../assets/Train/label_blue@2x.png);
		}
	
		.btnimg2 {
			background-image: url(../../assets/Train/label_red@2x.png);
		}
	
		.btnimg3 {
			background-image: url(../../assets/Train/label_gray@2x.png);
			color: #919CB0;
		}
	
		.btnimg_span {
			margin-right: -5px;
	
		}
	}
	[class*=van-hairline]::after{
		border: 0;
	}
	.van-image__error,
	.van-image__loading {
		top: 6px;
		border-radius: 50%;
	}
	.van-cell{
		padding: 20px 16px;
	}
	.rowHeader img{
		border-radius: 50%;
	}
	.van-tabs__wrap{
		padding: 0 56px;
	}
	.van-tab--active{
		
		.van-tab-activation{
			font-size: 18px !important;
			color: #28334A !important;
		}
	}
	.van-tabs__line{
		width: 18px !important;
		background: #0081FF;
	}
	.hotText {
		background-color: #F7F8FF;
		[class*=van-hairline]::after{
			border: 0;
		}
	
		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}
	
		.van-tab {
			min-width: 85px;
		}
	
		.van-tab--active {
			font-size: 17px;
		}
		.van-multi-ellipsis--l2{
			width: 100%;
		}
	
		.topBox {
			padding: 15px;
			background-color: #fff;
			margin-bottom: 15px;
			height: auto;
			border-radius: 6px;
		}
	
		.bigBox {
			width: 100%;
			padding: 0px 0 10px;
			background-color: transparent;
			text-align: left;
	
			.topBox {
				justify-content: space-between;
				background-color: #fff;
				padding: 20px 15px 24px;
				border-radius: 6px;
				
				.btn {
					padding: 5px;
					background-color: #0081FF;
					border-radius: 50px;
					color: #fff;
					font-size: 12px;
					border: 1px solid transparent;
				}
				.btn1{
					background-color: #fff;
					color: #0081FF;
					border: 1px solid #0081FF;
				}
			}
		}
	
		.time {
			color: #fff;
			font-size: 12px;
			transform: scale(.9);
		}
	
		.kan {
			justify-content: space-between;
			width: 100%;
			font-size: 12px;
			color: #919CB0;
			margin-top: 25px;
		}
		.article {
			width: 100%;
			background-color: #F4F4F4;
			border-radius: 2px;
			text-align: left;
			padding: 5px;
			margin-top:16px;
		}
	
	}
	.tutorHomepage{
		min-height: 100vh;
		.bg{
			background-color: #fff;
			width: 100%;
			min-height: 30%;
			z-index: 1;
			border-top-left-radius: 0.375rem;
			border-top-right-radius: 0.375rem;
			position: absolute;
			top:75px;
			left: 0;
		}
		.containers{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 3;
			width: 100%;
			.right{
				width: calc(100% - 117px);
				    position: relative;
				    top: 0px;
				.top{
					color: #fff;
					margin-bottom: 30px;
					.number{
						font-weight: bold;
						font-size: 1.125rem;
					}
				}
				.bottom1{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					position: relative;
					top: -5px;
					.addBtn{
						width: 5.625rem;
						height: 29px;
						background: #0081FF;
						color: #fff;
						text-align: center;
						line-height: 29px;
						border-radius: 1.125rem;
						margin-right: 10px;
					}
					.No_addBtn{
						width: 5.625rem;
						height: 29px;
						background: #F4F4F4;
						color: #919CB0;
						text-align: center;
						line-height: 29px;
						border-radius: 1.125rem;
						margin-right: 10px;
					}
					.private{
						width: 30px;
						height: 30px;
						background: url(../../assets/Train/icon_sixin@2x.png) no-repeat center;
						background-size: 100% 100%;
						margin-right: 15px;
					}
				}
			}
			
		}
		.conten{
			background-color: #fff;
			text-align:left;
			padding: 15px;
			padding-top: 0;
			    margin-top: -28px;
			.name_top{
				padding-bottom: 1.25rem;
				.name{
					font-size: 1.125rem;
					font-weight: bold;
					margin-right: 17px;
				}
				.autograph{
					font-size: 0.875rem;
				}
			}
			.biao_top{
				padding: 1.25rem 0;
				position: relative;
				&::after{
					top: 0;
					left: 0;
					content: '';
					display: block;
					width: 100%;
					border: 1px solid #E5E5E5;
					position: absolute;
					transform: scaleY(.35);
				}
				&::before{
					bottom: 4px;
					left: 0;
					content: '';
					display: block;
					width: 100%;
					border: 1px solid #E5E5E5;
					position: absolute;
					transform: scaleY(.35);
				}
				.autograph{
					color: #919CB0;
					font-size: 0.75rem;
					div{
						margin-bottom: 0.46875rem;
					}
				}
				.margins{
					margin-top: 8px;
				}
			}
			
		}
		.ylcBox {
			width: 100%;
			height: 211px;
			position: relative;
		
			.top {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 5;
				    width: 100%;
		
			}
		
			.bot {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 6;
				font-size: 12px;
		
			}
		
			.bg {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		
			.flex {
				position: absolute;
		
				z-index: 6;
				color: #fff;
				justify-content: space-between;
				width: 100%;
				padding: 10px 15px;
			}
		
			.conter {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 50px;
				height: 50px;
				z-index: 9;
			}
		
			.time {
				background-color: rgba(0, 0, 0, .5);
				border-radius: 50px;
				padding: 1px 7px;
				transform: scale(.9);
			}
		}
		
		.pingBox {
			background-color: #fff;
			.head {
				border-radius: 50%;
				width: 2.0625rem;
				height: 2.0625rem;
		
				img {
					border-radius: 50%;
				}
			}
		}
		
		.bottom {
			height: 50px;
			box-shadow: 1px 5px 14px 1px rgba(35, 35, 35, 0.6);
		
			.btn1 {
				width: 63px;
				height: 30px;
				line-height: 30px;
				background-color: #0081FF;
				color: #fff;
				border-radius: 50px;
		
			}
		}
		
		.seach {
			position: absolute;
			top: -95px;
			left: -15px;
			padding: 0 10px;
			min-width: 100px;
			min-height: 70px;
			background-color: #F1F1F1;
			transition: all .5s;
			z-index: 5;
			color: #919CB0;
		
			div {
				padding: 10px 0;
				background-color: #F1F1F1;
		
			}
		}
		
		.isseach {
			visibility: visible;
			opacity: 1;
			top: -95px;
		}
		
		.isseach1 {
			visibility: hidden;
			opacity: 0;
			top: 95px;
		}
		.tag {
			// width: 3.5rem;
			height: 1.25rem;
			color: #0081FF;
			background: #F7F8FF;
			border-radius: 0.625rem;
			text-align: center;
			line-height: 1.25rem;
			margin-right: 0.625rem;
			justify-content: space-evenly;
			padding: 0 5px;
		
			.xin {
				width: 0.75rem;
				height: 0.75rem;
				margin-right: 5px;
			}
		}
		.imageVan{
			border: 3px solid #fff;position: absolute;top: 27px;left: 1.09375rem;background-color: #fff;width: 90px;height: 90px;border-radius: 50%;
			position: relative;
			
			
		}
		.imageVanNan::before{
			position: absolute;
			bottom: 0;
			right: 0;
			display: block;
			content: '';
			width: 25px;
			height: 25px;
			background-image: url(../../assets/Train/icon_nan_blue@2x.png);
			background-size: 100% 100%;
			z-index: 50;
		}
		.imageVannv::before{
			position: absolute;
			bottom: 0;
			right: 0;
			display: block;
			content: '';
			width: 25px;
			height: 25px;
			background-image: url(../../assets/Train/icon_nv_red@2x.png);
			background-size: 100% 100%;
			z-index: 50;
		}
		.filter{
			width: 100%;
			height: 231px;
			filter: blur(3px);
			background-position: -50px;
			position: absolute;
			top:0 ;
			left: 0;
			
		}
		
		.van-cell__value,.van-cell{
			overflow: initial;
		}
		.white{
			background-color: #fff;
			padding-bottom: 15px;
		}
	}
	
</style>
