<template>
	<div class="questions">

		<div class="bigBox">
			<div v-for="(item,i) in list" :key="i" @click="$router.push({name:'questionsDel',query:{ask_id:item.id}})" style="margin-bottom: 10px;width: 100%;">
				<div class="flex topBox" style="width: 100%;">

					<div class="flex topBox_flex" style="">

						<img src="../../assets/bigSink/icon_wen@2x.png" alt="" style="width: 20px;height: 20px;display: block;margin-right: 8.5px;">
						<div>
							<div class="flex">
								<!-- <p>类别：</p> -->
								<p v-text="item.wx_name"></p>
								<!-- <img src="../../assets/bigSink/daijieda.png" alt="" width="36" height="15px" style="margin-left: 12px;"> -->
							</div>
							<p style="font-weight: bold;padding-top: 16px;" v-text="item.contents">

							</p>
						</div>
					</div>
					<div style="margin: 18.5px 0 18px;width: 100%;" v-if="!item.reply_num">暂无回答</div>
					<div class="flex" style="flex-wrap: initial;align-items: end;padding: 16px 0;width: 100%;" v-if="item.reply_num">

						<img src="../../assets/bigSink/icon_da@2x.png" alt="" style="width: 20px;height: 20px;display: block;margin-right: 8.5px;">
						<div>
							<p style="font-weight: bold;" v-text="item.replylist[0].r_contents">

							</p>
						</div>
					</div>
					<div class="flex kan">
						<div>
							<span v-text="item.click_num"></span> 人看过 · <span v-text="item.reply_num"></span> 回答
						</div>
						<div class="flex">
							提问于 <span v-text="item.create_time"></span>
						</div>
					</div>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
	import { getUserQuestionAskList } from '@/api'
	export default {
  name: 'questions',
  components: {
  },
  data(){
	  return{
		  isseach:false,
		  seachText:'时间',
		images: [
		  'https://img.yzcdn.cn/vant/apple-1.jpg',
		  'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		active:1,
		list:[{
			id:1,
			wenName:'急救答疑',
			questions:'打羽毛球脚扭了，怎么处理比较好？休息一会觉得没事，但是一站起来走路都有困难。',
			da:'23234',
			seen:123,
			hui:12,
			time:'2019-12-28'
		},
		{
			id:1,
			wenName:'急救答疑',
			questions:'打羽毛球脚扭了，怎么处理比较好？休息一会觉得没事，但是一站起来走路都有困难。',
			da:'',
			seen:123,
			hui:12,
			time:'2019-12-28'
		}]
	  }
  },
  methods:{
	 
  },
  mounted() {
	 	let s 
  	document.title = '我的提问';   
	getUserQuestionAskList().then(res=>{

		this.list=res.data.list
this.list.forEach((item,index)=>{
				 s=item.create_time.split(' ')
				 this.list[index].create_time=s[0]
})
		
	})
  }
}
</script>
<style lang="scss" type="text/css">
	.questions {
		min-height: 100vh;
		background-color: #F7F8FF;

		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}

		.van-tab {
			min-width: 85px;
		}

		.van-tab--active {
			font-size: 17px;
		}

		.topBox {
			padding: 15px;
			background-color: #fff;

		}

		.bigBox {
			width: 100%;
			padding: 10px 15px;
			background-color: transparent;
			text-align: left;

			.topBox {
				justify-content: space-between;
				background-color: #fff;
				padding: 0px 15px 24px;
				border-radius: 6px;

				.btn {
					padding: 5px;
					background-color: #0081FF;
					border-radius: 50px;
					color: #fff;
					font-size: 12px;
					border: 1px solid transparent;
				}

				.btn1 {
					background-color: #fff;
					color: #0081FF;
					border: 1px solid #0081FF;
				}
			}
		}

		.time {
			color: #919CB0;
			font-size: 12px;
			transform: scale(.9);
		}

		.kan {
			justify-content: space-between;
			width: 100%;
			font-size: 12px;
			color: #919CB0;
			margin-top: 4px;
		}

		.bottom {
			height: 50px;
			box-shadow: 1px 5px 14px 1px rgba(35, 35, 35, 0.6);

			.btn1 {
				width: 63px;
				height: 30px;
				line-height: 30px;
				background-color: #0081FF;
				color: #fff;
				border-radius: 50px;

			}
		}

		.seach {
			position: absolute;
			top: -95px;
			left: -15px;
			padding: 0 10px;
			min-width: 100px;
			min-height: 70px;
			background-color: #F1F1F1;
			transition: all .5s;
			z-index: 5;
			color: #919CB0;

			div {
				padding: 10px 0;
				background-color: #F1F1F1;

			}
		}

		.isseach {
			visibility: visible;
			opacity: 1;
			top: -137px;
		}

		.isseach1 {
			visibility: hidden;
			opacity: 0;
			top: 95px;
		}
		.topBox_flex{
			flex-wrap: initial;align-items: end; border-bottom:1px solid #E5E5E5 ;padding: 16px 0;width: 100%;
		}
	}
</style>
