<template>
	<div class="bigSink" >
		<div v-if="!isda" style="width: 100%;">
			<img src="../../assets/bigSink/bg_xskt_top@2x.png" alt="" width="100%" height="200" style="position: absolute;top: 0;left: 0;">
			<div class="Bigbox">
				<div style="font-weight: bold;margin-bottom: 70px;">学习之前，需要先激活。请输入或扫描《现场急 救行动指南》书本封底左上角粘贴的数字条形码。 如果您没有本书，请点击“好书推荐”购买
</div>
				<div class="flex" style="width: 100%;">
					<div class="flex box" style="border: 1px solid #0081FF;border-radius: 6px;padding: 8px 12px;margin-right: 15px;">
						<input type="text" v-model="activationCode">
						<img src="../../assets/bigSink/icon_saoma@2x.png" width="24" height="24" alt="" @click="scanQRCodeClick">
					</div>
					<div class="jihuoBtn" :style="activationCode?'background:#0081FF':''" @click="jihuoClick">激活</div>
				</div>
			</div>
			<div class="delBox">
				<van-popup v-model="delShow">
					<div class="flex" style="width: 100%;height: 100%;flex-flow: column;justify-content: space-around;">
						<div style="font-size: 18px;font-weight: bold;">激活失败</div>
						<div v-text="isjihuo==1?'无效数字编码，激活失败':'该教材已被激活，无法重新激活'"></div>
						<div style="    text-align: right; width: 100%;">
							<span class="blue" @click="delShow=false">确定</span>
						</div>
					</div>
				</van-popup>
			</div>
			<div class="delBox1">
				<van-popup v-model="delShowDui">
					<div class="flex" style="width: 100%;height: 100%;flex-flow: column;justify-content: space-around;padding: 5px 0;">
						<div style="font-size: 18px;font-weight: bold;padding-bottom: 10px;">激活成功</div>
						<div>
							<span style="line-height: 24px;">您已成功激活线上课堂。线上课堂有两类课程:心肺复苏术及自动体外除颤仪使 用课程和急症急救及创伤救护课程，当 您观看完这两类课程中的某一类课程的教学视频，并回答完相应的习题，当您的答题正确率在80%以上时，可以获得心肺复苏术及自动体外除颤仪使用课程的《现场急救线上教学课程合格证》或急症急救及创伤救护课程的《现场急救线上教学课程合格证》，凭此证可以在2个月内申请线下教学课程。</span>
						</div>
						<div style="    text-align: right; width: 100%;">
							<span class="blue" @click="duiClick">确定</span>
						</div>
					</div>
				</van-popup>
			</div>
		</div>
	</div>
</template>

<script>
import {postActivitySecurityCode,getSign} from '@/api'
import { Toast } from 'vant';
  import wxJs from  '@/util/wx-sdk'
export default {
  name: 'bigSink',
  components: {
  },
  data(){
	  return{
		  user_id:0,
		  url_link:'',
		  // 激活码
		  activationCode:'',
		  // 激活是否成功
		 isda:false,
		 // 激活提醒
		 delShowDui:false,
		delShow:false,
		isjihuo:1,
		subject:[{
			title:'成人心肺复苏',
			time:'03:17',
			isover:1,
			imgUrl:"http://zhongan995.com/upfiles/a1/201910/30/a79fab473521bb552.jpg",
			vodieUrl:'',
			id:1
		},{
			title:'成人心肺复苏',
			time:'03:17',
			isover:1,
			imgUrl:"http://zhongan995.com/upfiles/a1/201910/30/a79fab473521bb552.jpg",
			vodieUrl:'',
			id:2
		},{
			title:'成人心肺复苏',
			time:'03:17',
			isover:0,
			imgUrl:"http://zhongan995.com/upfiles/a1/201910/30/a79fab473521bb552.jpg",
			vodieUrl:'',
			id:3
		}],
		// 激活码
		// jihuoCode:'123456',
		setLocation:{}
	  }
  },
  methods:{
	  // resolve(wx,res);
	  // 扫码
	  // this.$wx.scanQRCode({
	  //   needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
	  //   scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
	  //   success: function (res) {
	  //    _this.activationCode = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
	  //   }
	  // });
	
	  // 扫码
	  scanQRCodeClick(){
		  let _this=this
			
		  getSign({ page_url: location.href  })
		    .then(res => {
		      if (res.data.appId) {
		        this.$wx.config({
		          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
		          appId: res.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
		          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
		          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
		          signature: res.data.signature, // 必填，签名，见附录1
		          jsApiList: ['scanQRCode',
								'updateTimelineShareData',
								'updateAppMessageShareData',
								'onMenuShareAppMessage',
								'onMenuShareTimeline'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
		        });
		        this.$wx.ready(res => {  // 微信SDK准备就绪后执行的回调。
		     
				  this.$wx.scanQRCode({
				    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
				    scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
				    success: function (res) {
						// .split(',')[1]
				     _this.activationCode = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
				    }
				  });
		        });
		      }else{
		      }
		    });
	  },
	  duiClick(){
		  this.delShowDui=false
		  let user_id=localStorage.getItem('user_id')
		 this.$router.push({name:'answer',query:{id:1,user_id:user_id}})
	  },
	 jihuoClick(){
		 postActivitySecurityCode({serial_number:this.activationCode}).then(res=>{
			 // Toast(res.msg)
			 
				if(res.code==1){
					this.delShowDui=true
					 // if(this.activationCode==this.jihuoCode){
					 // }
					 // else{
						//  this.delShow=true
					 // }
				}else{
					 this.delShow=true
				}
		 })
		 
	 }
  },
  mounted() {
	  let _this=this
  	document.title = '线上课堂'; 
	this.user_id=JSON.parse(localStorage.getItem('user_data')).user_id
	  setTimeout(()=>{
		  getSign({ page_url: location.href  })
		    .then(res => {
		      if (res.data.appId) {
		        this.$wx.config({
		          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
		          appId: res.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
		          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
		          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
		          signature: res.data.signature, // 必填，签名，见附录1
		  		jsApiList: ['scanQRCode',
		  					'updateTimelineShareData',
		  					'updateAppMessageShareData',
		  					'onMenuShareAppMessage',
		  					'onMenuShareTimeline'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
		        });
		     //    this.$wx.ready(res => {  // 微信SDK准备就绪后执行的回调。
		     
		  			// 	  this.$wx.scanQRCode({
		  			// 	    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
		  			// 	    scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
		  			// 	    success: function (res) {
		  			// 	     _this.activationCode = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
		  			// 	    }
		  			// 	  });
		     //    });
		      }
		    });
	  },1000)
	
	
  },
  created() {
  	  const self = this;
  	      
  }
  
}
</script>
<style lang="scss" type="text/css" scoped>
	.bigSink {
		min-height: 100vh;
		background-color: #F7F8FF;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.jihuoBtn {
			background-color: #C6C8CA;
			width: 70px;
			height: 40px;
			border-radius: 6px;
			color: #fff;
			text-align: center;
			line-height: 40px;
		}

		.ylcBox {

			width: 100%;
			height: 211px;
			position: relative;

			.top {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 5;

			}

			.bot {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 6;
				font-size: 12px;

			}

			.bg {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}

			.flex {
				position: absolute;

				z-index: 6;
				color: #fff;
				justify-content: space-between;
				width: 100%;
				padding: 10px 15px;
			}

			.conter {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 50px;
				height: 50px;
			}

			.time {
				background-color: rgba(0, 0, 0, .5);
				border-radius: 50px;
				padding: 1px 7px;
				transform: scale(.9);
			}
		}

		.btn {
			border: 1px solid #0081FF;
			color: #0081FF;
			border-radius: 52px;
			width: 70px;
			font-size: 12px;
			height: 27px;
			line-height: 27px;
			text-align: center;
		}

		.btn1 {
			border: 1px solid #919CB0;
			color: #919CB0;
			border-radius: 52px;
			width: 70px;
			font-size: 12px;
			height: 27px;
			line-height: 27px;
			text-align: center;
		}

		.delBox {
			.van-popup {
				width: 300px;
				height: 190px;
				border-radius: 6px;
				padding: 0 1.25rem;
			}
		}
		.delBox1{
			.van-popup {
				width: 300px;
				border-radius: 6px;
				padding: 10px 1.25rem;
				letter-spacing: 1.5px;
				text-align: justify;
			}
		}

		.Bigbox {
			position: relative;
			width: 92%;
			height: 296px;
			margin: 100px auto 0;
			background-color: #fff;
			z-index: 3;
			border-radius: 6px;
			padding: 49px 15px 0;
			text-align: left;

			input {
				width: calc(100% - 24px)
			}

			.box {
				width: calc(100% - 15px - 70px);
			}
		}
	}
</style>
