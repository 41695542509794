<template>
  <div class="mechanismDetails">
	<div class="detail-header">
		<div class="flex" style="justify-content: center">
			<van-image :src="insDetails.logo_img" class="logo-img"  />
		</div>
		<div>
			<p class="train-title" v-text="insDetails.name"></p>
			<p v-if="insDetails.is_only">{{insDetails.cooperation_area}}唯一合作单位</p>
			<p v-if="insDetails.cooperation_date">合作期限：{{insDetails.cooperation_date}}</p>
			<p v-if="insDetails.cooperation_area">合作区域：{{insDetails.cooperation_area}}</p>
		</div>
		<div class="des-info flex">
			<div class="info-left flex">
				<img class="des-icon" src="@/assets/Train/com_data.png" alt="">
				<div class="des-dein">
					<p v-text="nums.train_counts"></p>
					<p>培训场次</p>
				</div>
			</div>
			<div class="info-center"></div>
			<div class="info-right flex">
				<img class="des-icon" src="@/assets/Train/com_num.png" alt="">
				<div class="des-dein">
					<p v-text="nums.train_students_counts"></p>
					<p>培训人数</p>
				</div>
			</div>
		</div>
	</div>

	<div class="contains">
		<van-tabs v-model="active">
			<van-tab v-for="(item,index) in tab" :key="index">
				<div slot="title" >
					<span v-text="item"	style="font-size: 15px;font-weight: bold;color: #919CB0;" class="van-tab-activation"></span>
				</div>
			</van-tab>
		</van-tabs>
		<div v-if="tab[active]=='机构主页'" class="fivrm">
			<div class="cards">
				<div class="cards-title">机构简介</div>
				<div class="cards-des">{{insDetails.desc || '无'}}</div>
			</div>
			<div class="cards">
				<div class="cards-title">机构图片</div>
				<div class="cards-img flex" v-if="insDetails.imgs && insDetails.imgs.length > 0">
					<van-image
					 width="80" 
					 height="80" 
					 class="va-img"
					 v-for="(item, index) in insDetails.imgs" 
					 :key="index" 
					 :src="item" />
				</div>
				<div v-else>无</div>
			</div>
			<div class="cards">
				<div class="cards-title">详细地址</div>
				<div class="cards-des">{{insDetails.address || '无'}}</div>
			</div>
			<div class="cards">
				<div class="cards-title">联系方式</div>
				<div class="cards-des">{{insDetails.phone || '无'}}</div>
			</div>
		</div>
		<div v-if="tab[active]=='培训课程'" class="fivrm">
			<div>
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list v-model="loading" :finished="finished" finished-text=" " @load="onLoad">
						<div v-if="count==0">
							<img src="../../assets/default/icon_nodota.png" alt="" style="width: 150px;">
							<p>暂无培训课程</p>
						</div>
						<van-cell v-for="(item,index) in list" :key="index" @click="$router.push({name:'courseDetails',query:{act_id:item.id}})">
							<div slot="default" class=" list" style="display: flex;">
								<div class="list_img" style="position: relative;">
									<img src="../../assets/PX/icon_baomz@2x.png" alt="" width="45" class="icon"  v-if="item.act_status==1">
									<img src="../../assets/PX/icon_yijieshu@2x.png" alt="" width="45" class="icon" v-else-if="item.act_status==2">
									<img src="../../assets/PX/icon_yifankui@2x.png" alt="" width="45" class="icon" v-else>
									<van-image width="100" height="100" :src="item.cover_img" radius='8px' />
								</div>
								<div style="width: 100%;">
									<div v-text="item.act_name" class="list_title"></div>
									<!-- <h4 v-text=""></h4> -->
									<div class="flex" style="    justify-content: space-between;">
										<!-- 标题下左右边两个div -->
										<div>
											<div class="flex">
												<img src="../../assets/PX/icon_kecheng@2x.png" alt="" style="width: 12px;height: 12px;margin-right: 0.5rem;">
												<div class="text" v-for="(items,i) in item.train_course" style="margin: 0;" :key="i">
													<span  v-text="items==2?'CPR+AED使用课程':items==3?'创伤救护课程':items==4?'导师线下培训课程':items==5?'主任导师线下培训课程':''"></span><span v-if="i!=item.train_course.length-1" >+</span>
												</div>
											</div>
											<!-- 左边时间 -->
											<div class="flex">
												<img src="../../assets/Train/icon_time@2x.png" alt="" style="width: 12px;height: 12px;">
												<div v-text="item.act_start_time" class="text"></div>
												<div class="label-tag" :class="{'ping-col': item.act_type == 1}" v-text="item.act_type == 1 ? '学员班' : '导师班'"></div>
											</div>
											<!-- 左边地址 -->
											<div class="flex">
												<img src="../../assets/Train/icon_xdizhi@2x.png" alt="" style="width: 12px;height: 14px;">
												<div v-text="item.city" class="text"></div>
											</div>
										</div>
										<!-- <div>
											<div :class="item.act_status==1?'btnimg1':item.act_status==2?'btnimg2':'btnimg3'" class="btnimg">
												<span v-text="item.act_status==1?'报名中':item.act_status==2?'已结束':item.act_status==3?'已下线':'已反馈'" class="btnimg_span"></span>
											</div>
										</div> -->
									</div>
								</div>
							</div>
						</van-cell>
					</van-list>
				</van-pull-refresh>
			</div>
		</div>
		<div v-if="tab[active]=='导师名单'" class="fivrm">
			<div>
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<div v-if="count==0">
						<img src="../../assets/default/icon_nodota.png" alt="" style="width: 150px;">
						<p>暂无</p>
					</div>
					<van-list v-model="loading" :finished="finished" finished-text=" " @load="onLoad">
						<van-cell v-for="(item,index) in list" :key="index" @click="$router.push({path:'/tutorHomepage',query:{id:item.user_id}})">
							<div slot="default" class="flex list">
								<div class="list_img list_img_pai " :class="['list_img_'+(index+1)]">
									<van-image width="60" height="60" :src="item.user_head_img" radius='50%' />
								</div>
								<div>
									<div class="list_title">
										<span v-text="item.user_name" style="font-size: 15px;"></span>
										<span v-text="item.user_type == 1 ? '普通用户' : item.user_type == 2 ? '导师' : '主任导师'" class="ellipse identity"></span>
									</div>
									<div v-text="item.user_signature">

									</div>
									<div class="flex" style="    justify-content: space-between;">
										<!-- 标题下左右边两个div -->
										<div>

											<div class="flex" v-if="item.institution">
												<img src="../../assets/Train/icon_xjigou@2x.png" alt="" style="width: 12px;height: 12px;">
												<div v-text="item.institution?item.institution:'暂无'" class="text"></div>
											</div>
											<div class="flex">
												<div class="flex" style="margin-right: 1.875rem;">
													<img src="../../assets/Train/icon_xdizhi@2x.png" alt="" style="width: 12px;height: :;px;">
													<div v-text="item.city?item.city:'暂无'" class="text"></div>
												</div>
												<div class="flex">
													<img src="../../assets/Train/icon_guanzhu@2x.png" alt="" style="width: 12px;height: 12px;">
													<div v-text="item.fans_num+'人关注'" class="text"></div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</van-cell>
					</van-list>
				</van-pull-refresh>
			</div>
		</div>
	</div>
	<div class="footers flex">
		<div class="footers-btn flex" @click="toInvitation">
			<img src="@/assets/Train/com_time.png" alt="">
			<span>培训邀约</span>
		</div>
		<div class="footers-btn flex" @click="toConSultionService">
			<img src="@/assets/Train/com_contact.png" alt="">
			<span>我要咨询</span>
		</div>
	</div>
  </div>
</template>

<script>
import { getActivityLists, getInsDetail, getTutorLists } from '@/api';
export default {
  name: 'mechanismDetails',
  data(){
	  return{
		tab:['机构主页','培训课程','导师名单'],
		active:1,
		list: [],
		insDetails: {},
		nums: {},

		ins_id: 0,
		refreshing: false,
		loading: false,
		finished: false,
		page: 1,
		limit: '8',
		count: 0,
	  }
  },
  watch: {
	active(){
		this.list=[]
		this.page=1
		this.loading=true
		this.finished=false
		this.count=1
		this.onLoad()
	}
  },
  methods:{
	onLoad() {
		if(this.tab[this.active] == '培训课程') {
			this.getActivityLists();
		} else if(this.tab[this.active] == '导师名单') {
			this.getTutorLists();
		}
	},
	getActivityLists() {
		getActivityLists({ins_id:this.ins_id,page:this.page,limit:this.limit}).then(res=>{
			if(res.code==1){
				this.count=res.data.count
				this.page++
				if (this.refreshing) {
				  this.list = [];
				  this.refreshing = false;
				}
				let train_courseArr=[]
				res.data.list.forEach((items,i)=>{
					let arr =items.train_course.split('|');
					arr.forEach((item,i)=>{
						if(item){
							train_courseArr.push(item)
						}
					})
					items.train_course=train_courseArr
					if(items.train_course.includes('2') && items.train_course.includes('3')) {
						items.cover_img = require('../../assets/PX/pic_cprtrauma.png')
					} else if(items.train_course.includes('2')) {
						items.cover_img = require('../../assets/PX/pic_huodong@2x.png')
					} else if (items.train_course.includes('3')) {
						items.cover_img = require('../../assets/PX/pic_trauma.png')
					} else if(items.act_type == 2) {
						items.cover_img = require('../../assets/PX/pic_teacher.png')
					}
					train_courseArr=[]
				})
				this.list.push(...res.data.list)
				  // 加载状态结束
				  this.refreshing=false
				  this.loading = false;
				// 数据全部加载完成
				if (this.list.length >= this.count) {
				 this.finished = true;
				}
			}else{
				this.list = [];
				this.count=0
				this.finished = true;
			}
		})
	},
	getTutorLists() {
		getTutorLists({ page: this.page, limit: this.limit, ins_id: this.ins_id }).then(res=>{
			if(res.data){
				this.page++
				this.count=res.data.count
				if (this.refreshing) {
					this.list = [];
					this.refreshing = false;
				}
				
				this.list.push(...res.data.list)
					// 加载状态结束
					this.refreshing=false
					this.loading = false;
				// 数据全部加载完成
				if (this.list.length >= this.count) {
					this.finished = true;
				}
			}else{
				this.list = [];
				this.count=0
				this.finished = true;
			}
		})
	},
	getInsDetail() {
		getInsDetail({ins_id: this.ins_id}).then(res => {
			if(res.code === 1) {
				this.insDetails = res.data.data;
				this.nums = {
					train_counts: res.data.train_counts,
					train_students_counts: res.data.train_students_counts,
				}
			}
		})
	},
	onRefresh() {
		this.finished = false;
		this.list=[]
		this.page=0
		// 重新加载数据
		// 将 loading 设置为 true，表示处于加载状态
		this.loading = true;
		this.onLoad();
	},
	toInvitation() {
		this.$router.push({
			name: 'trainInvitation',
			query: {
				id: this.ins_id
			}
		})
	},
	toConSultionService() {
		this.$router.push({
			name: 'toConsultingService',
			query: {
				id: this.ins_id
			}
		})
	}
  },
  mounted() {
  	document.title = '培训机构';
	this.ins_id = this.$route.query.id;
	this.getInsDetail();
  }
}
</script>
<style lang="scss" type="text/css" scoped>
   	$mainColor: rgb(28, 145, 255);
	.mechanismDetails{
		width: 100%;
		min-height: 100vh;
		background: #F7F8FF;
		.detail-header {
			background: #fff;
			.logo-img {
				height: 5rem;
				width: 5rem;
			}
			.train-title {
				font-size: 1.2rem;
				font-weight: bold;
				margin: 0.4rem 0;
			}
			.des-info {
				width: 90%;
				height: 3.5rem;
				margin: 0.5rem auto;
				/* padding-top: 0.5rem; */
				border-top: 1px solid #dfdede;
				justify-content: space-around;
				.des-icon {
					height: 1.7rem;
					width: 1.7rem;
				}
				.info-center {
					height: 60%;
					width: 1px;
					background: #dfdede;
				}
				.des-dein {
					margin-left: 1.2rem;
					p {
						&:first-child {
							color: $mainColor;
							font-size: 1.2rem;
						}
						&:last-child {
							color: rgb(188, 188, 188);
							font-size: 0.8rem;
						}
					}
				}
			}
		}
		.contains {
			.fivrm {
				padding-bottom: 5rem;
			}
			background: #fff;
			.van-tab--active{
				.van-tab-activation{
					font-size: 18px !important;
					color: #28334A !important;
				}
			}

			.identity {
				color: #28334A;
				font-size: 12px;
				margin-left: 1.6rem;
			}

			.cards {
				width: 100%;
				text-align: left;
				padding: 0.5rem 1rem;
				border-bottom: 1px solid #e3e3e3;
				.cards-title {
					font-size: 0.9rem;
					font-weight: 600;
					margin: 1rem 0 0.5rem;
				}
				.cards-des {
					font-size: 0.8rem;
					color: #666;
				}
				.cards-img {
					.va-img {
						margin-left: 0.2rem;
						margin-top: 0.2rem;
					}
				}
			}

			.list {
				flex-wrap: inherit;

				.label-tag {
					display: flex;
					justify-content: center;
					align-items: center;
					background: #ffb366;
					border-radius: 1rem;
					text-align: center;
					color: #fff;
					padding: 0.015rem 0.35rem;
					font-size: 0.75rem;
					margin-left: 1rem;
				}
				.ping-col {
					background: #0081FF;
				}

				.list_img {
					margin-right: 0.71875rem;
					.icon{
						position: absolute;
						top: 10px;
						left: -3px;
						z-index: 2;
					}
				}

				.list_title {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					font-weight: bold;
					font-size: 13px;
					color: #28334A;
				}

				.text {
					font-size: 0.75rem;
					color: #767e8d;
					margin-left: 0.5rem;
				}

				.btnimg {
					width: 3.3125rem;
					height: 1.625rem;
					text-align: center;
					line-height: 1.625rem;
					background: no-repeat center;
					background-size: 100% 100%;
					font-size: 0.6875rem;
					color: #FEFEFE;
				}

				.btnimg1 {
					background-image: url(../../assets/Train/label_blue@2x.png);
				}

				.btnimg2 {
					background-image: url(../../assets/Train/label_red@2x.png);
				}

				.btnimg3 {
					background-image: url(../../assets/Train/label_gray@2x.png);
					color: #919CB0;
				}

				.btnimg_span {
					margin-right: -5px;

				}
			}
		}
		.footers {
			background: #fff;
			width: 100%;
			position: fixed;
			bottom: 0;
			left: 0;
			z-index: 2;
			box-shadow: 0 0 10px #ccc;
			.footers-btn {
				width: 50%;
				height: 2.5rem;
				justify-content: center;
				&:first-child {
					background: $mainColor;
					color: #fff;
				}
				&:last-child {
					span {
						color: $mainColor;
					}
				}
				img {
					height: 1.4rem;
					width: 1.4rem;
				}
				span {
					margin-left: 1rem;
				}
			}
		}
	}
</style>
