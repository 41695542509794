<template>
	<div class="UsageRecord">
		<div v-if="!count" class="default">
			<img src="../../assets/default/icon_zanwusyjl@2x.png" alt="" class="default_img">
			<p>暂无使用记录</p>
		</div>
		
		<van-list
		  v-model="loading"
		  :finished="finished"
		  finished-text="没有更多了"
		  @load="onLoad"
		  v-if="count"
		>
		 <van-cell-group >
		   <van-cell :label="item.createtime" :value="(item.select_type==1?'+':item.select_type==2?'-':item.select_type==3?'-':item.select_type==4?'+':item.select_type==5?'+':'')+item.pay_money" :title="item.select_type==1?'充值':item.select_type==2?'提现':item.select_type==3?'购买支付':item.select_type==4?'退款':item.select_type==5?'佣金结算':''" v-for="(item,i) in list" :key="i"/>
		   <!-- <van-cell title="提现" value="-100.00" label="2019-10-11  09:20:00" /> -->
		 </van-cell-group>
		</van-list>
	</div>
</template>

<script>
	import {getClientUsersAccountRecord} from '@/api'
export default {
  name: 'UsageRecord',
  components: {
  },
  data(){
	  return{
		loading: false,
		finished: false,
		title:'使用记录',
		list:[],
		page:1,
		limit:12,
		count:1
	  }
  },
  methods:{
	 onLoad() {
	      // 异步更新数据
		  getClientUsersAccountRecord({page:this.page,limit:this.limit}).then(res=>{
			  if(res.code==1){
				this.count=res.data.count
				this.page++
				this.list.push(...res.data.list);
				// 加载状态结束
				this.loading = false;
				// 数据全部加载完成
				if (this.list.length >= this.count) {
				this.finished = true;
				}
			}else{
				this.count=0
				this.list=[]
			}
		  })
	        
	    },
  },
  
  mounted() {
  	document.title = this.title;   

	// this.gethotcity()
	// this.getActivityDataCount()
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.UsageRecord{
		width: 100%;
		min-height: 100vh;
		background-color: #F7F8FF;
		text-align: left;
		.van-cell:not(:last-child)::after{
			left:50%;
			transform: translateX(-50%);
			width: 95%;
				border-bottom: 1px solid #E5E5E5 !important;
			}
			.van-cell{
				align-items: center;
			}
			.van-cell__value{
				font-size: 1.25rem;
				color: #28334A;
				letter-spacing: 0px;
			}
		.van-cell__label{
			color: #919CB0;
		}
	}
</style>
