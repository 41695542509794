<template>
	<div class="certificateDet">
		<video width="100%" height="190" controls v-if="isvid" id="video1">
			<source src="https://ugcydzd.qq.com/uwMROfz2r5zEIaQXGdGnC2dfDmafRkP9ujxgqKjuATzMrE-2/m305749dm87.mp4?sdtfrom=v1010&guid=d7a81fecf453224fd71fcffc9c1db221&vkey=425F77B2ED0D48526C2DD5417740CC94A3A47AB45A4F157C556E99D4C05344B1D7102E2E3FE346CD3720667DBE7BDD0CC9099E69DA14F29A8363B7FD9E3F307F8A0922A63575A01C0BA7C8829179509C8A1E5DBDDB9035D299138BE3196BAB8ED566045F4193196307F5A30341C1D0A9D1854E45E8DE12311E8BABED4D2012A3" type="video/mp4">
			<source src="movie.ogg" type="video/ogg">
			您的浏览器不支持Video标签。
		</video>
		<div class="video" v-if="!isvid">
			<img src="../../assets/123.jpg" alt="" class="auto-img">
			<div class="bof" @click="VideoClick">
				<img src="../../assets/user/bofan.png" alt="" class="auto-img">
			</div>
		</div>
		
		<div class="bigBox">
			<div class="title">课程名称：<span v-text="name"></span></div>



			<van-row style="margin-bottom: 12px;">
				<van-col span="5" style="color:#919CB0"><span >培训时间</span></van-col>
				<van-col span="19"><span v-text="time"></span></van-col>
			</van-row>
			<van-row style="margin-bottom: 12px;">
				<van-col span="5" style="color:#919CB0"><span >培训地点</span></van-col>
				<van-col span="19"><span v-text="ads"></span></van-col>
			</van-row>
			<van-row style="margin-bottom: 12px;">
				<van-col span="5" style="color:#919CB0"><span >答题结果</span></van-col>
				<van-col span="19">答错 <span v-text="yes" style="color:#FF524D"></span> 题  答对 <span v-text="no" style="color: #00D97E;"></span> 题</van-col>
			</van-row>
			<van-row style="margin-bottom: 12px;">
				<van-col span="5" style="color:#919CB0"><span >考核结果</span></van-col>
				<van-col span="19"><span v-text="result" style="color: #00D97E;"></span></van-col>
			</van-row>
			<van-row >
				<van-col span="5" style="color:#919CB0"><span >备注</span></van-col>
				<van-col span="19"><span v-text="remarks"></span></van-col>
			</van-row>

		</div>
	</div>
</template>

<script>
	var myVideo=document.getElementById("video1"); 
	
	import { getMyOfficialCerts } from '@/api'
	export default {
  name: 'certificateDet',
  components: {
  },
  data(){
	  return{
		  isvid:false,
		  img:require('../../assets/1234.jpg'),
		title:'学员考核记录',
		name:'成人心肺复苏术',
		time:'2019-11-12  16:30',
		ads:'天鹰救援培训中心',
		yes:2,
		no:4,
		result:'达标',
		remarks:''
	  }
  },
  methods:{
	VideoClick(){
		this.isvid=true
		 myVideo.play(); 
		
	}
  },
  
  mounted() {
  	document.title = this.title;   
	getMyOfficialCerts({cert_id:this.$route.query.cert_id}).then(res=>{
		console.log(res)
		
	})
	// this.gethotcity()
	// this.getActivityDataCount()
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.certificateDet {
		width: 100%;
		min-height: 100vh;
		background: #F7F8FF;

		.bigBox {
			padding: 0 15px;
			background-color: #fff;
			padding-bottom: 20px;
			.title {
				font-size: 17px;
				text-align: left;
				padding: 20px 0;
				border-bottom: 1px solid #E5E5E5;
				margin-bottom: 15px;
			}
		}

		.van-col {
			text-align: left;
		}

		.bottom {
			box-shadow: 1px 1px 10px 5px #E5E5E5;
		}

		.van-image__img {
			border-radius: 8px;
		}
		.video{
			width: 100%;
			height: 190px;
			background-color: red;
			position: relative;
			.bof{
				width: 50px;
				height: 50px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
	}
</style>
