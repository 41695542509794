<template>
	<div class="evaluate">
		<div class="flex list" style="padding: 15px;background-color: #fff;    align-items: inherit;">
			<div class="list_img">
				<van-image width="106" height="76" :src="cover" radius='8px' />
			</div>
			<div style="width: 100%;">
				<div v-text="actList.act_name" class="list_title"></div>
				<div class="flex" style="    justify-content: space-between;">
					<!-- 标题下左右边两个div -->
					<div>
						<!-- 左边时间 -->
						<div class="flex" style="margin: 8px 0;">
							<img src="../../assets/Train/icon_time@2x.png" alt="" style="width: 12px;height: 12px;">
							<div v-text="actList.act_start_time" class="text"></div>
						</div>
						<!-- 左边地址 -->
						<div class="flex">
							<img src="../../assets/Train/icon_xdizhi@2x.png" alt="" style="width: 12px;height: 14px;">
							<div v-text="actList.act_addr" class="text"></div>
						</div>
						<div class="flex" style="margin-top: 10px;">
							<div class="text" style="margin-left: 0;">主讲：</div>
							<div v-text="actList.tutor_user_name" class="text"></div>
						</div>
						<div class="flex" style="margin-top: 10px;">
							<div class="text" style="margin-left: 0;">助教导师：</div>
							<div class="text">
								<span v-for="(item,i) in signup_tutor" v-text="item.user_name" style="margin-right: 5px;"></span>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<div style="width: 100%; background: #F7F8FF; height: 10px;"></div>
		<div style="text-align: left;padding: 20px 16px;background-color: #fff;">
			<div class="flex" style="justify-content: space-between;">
				<div style="color: #919CB0;">给培训课程评分</div>
				<div class="flex blue">
					<van-checkbox v-model="checked" class="blue" @click="checkAll">
						<span class="blue">默认5星好评</span>
					</van-checkbox>
				</div>
			</div>

			<div class="" style="margin-top: 12px; ">
				<div v-for="(item,i) in list" :key="i">
					<div style="" v-text="(i+1)+'、'+item.title"></div>
					<div style="text-align: center;">
						<van-rate v-model="score[i].score" gutter="20" touchable void-icon="star" style="margin: 20px 0;" />
					</div>
				</div>
			</div>

		</div>
		<div style="width: 100%; background: #F7F8FF; height: 10px;"></div>
		<div style="text-align: left;padding: 20px 16px;background-color: #fff;">
			<div style="color: #919CB0;font-size: 14px;">评价内容</div>
			<div style="margin-top: 12px;">
				<van-field v-model="content" rows="4" autosize type="textarea" placeholder="请输入内容" style="background-color: #F4F4F4;    font-size: 15px;" />
			</div>
			<div class="flex" style="justify-content: flex-end;margin-top: 11px;" @click="is_anonymousClick">
				<img src="../../assets/user/icon_dxk_nor@2x.png" alt="" style="width: 30px;height: 30px;" v-if="is_anonymous==0">
				<img src="../../assets/user/icon_dxk_pre@2x.png" alt="" style="width: 30px;height: 30px;" v-if="is_anonymous==1">
				<div style="margin-left: 8px;">匿名评价</div>
			</div>
		</div>
		<div style="text-align: left;padding: 20px 16px;background-color: #fff;">
			<div style="color: #919CB0;font-size: 14px;">意见或建议（选填）</div>
			<div class="flex" v-for="(item,i) in optional_list" :key="i">
				<div v-text="(i+1)+'、'+item.title" style="margin-top: 20px;">

				</div>
				<van-field v-model="optional_list_text[i].content" rows="1" autosize type="textarea" placeholder="请输入内容" style="background-color: #F4F4F4;margin-top: 20px;    font-size: 15px;min-height: 50px;" />
			</div>
		</div>
		<div class="bottom">
			<div class="btn" @click="CommentClick">
				发表评价
			</div>
		</div>

		<!-- <van-calendar v-model="isdata" @confirm="onConfirm" color="#0081FF" /> -->
		<!-- <van-calendar v-model="isdata_Section" type="range" @confirm="onConfirm_Section" color="#0081FF" /> -->
		<!-- <transition name="van-slide-up">
		  <div v-show="isAreaList" style="z-index: 3;position: fixed;bottom: 0;left: 0;width: 100%; ">
			 
		  </div>
		</transition> -->

	</div>
</template>

<script>
	import {postActivityComment,getActivity_t,getActivityCommentSubjectList,getActivity} from '@/api'
	import { Toast } from 'vant';
export default {
  name: 'evaluate',
  components: {
  }, 
  data(){
	  return{
		checked: false,
		signup_tutor:[],
		cover: "https://xcjj.tysos.cn/gzh/backend/public//uploads/act_default.png",
		outTime:'',
		message:'',
		value:'4',
		value1:'1',
		value2:'1',
		title:'发表评价',
		isacr:false,
		content:'',
		// 评论内容
		createtime:'',
		// 创建时间
		is_anonymous:0,
		// 是否匿名
		user_id:JSON.parse(localStorage.getItem('user_data')).user_id,
		actList:{
		},
		// 建议选填问题列表
		optional_list:[],
		// 评价问题列表
		list:{},
		// 评价
		score:[],
		record_score:[],
		optional_list_text:[]
	}
  },
  methods:{
	  
	   checkAll(){
		  
		  this.checked=!this.checked
		   if(this.checked){
			   console.log('全选')
			   this.list.forEach((item,i) => {
			   		this.score[i].score=5
			   })
		   }else{
			   console.log('bu全选')
			   this.list.forEach((item,i) => {
			   		this.score[i].score=0
			   })
			  
		   }
		  
	   },
	  //获取当前时间的标准格式
	getNowFormatDate() {
	  	let date = new Date();
	  	let seperator1 = "-";
	  	let seperator2 = ":";
	  	let month = date.getMonth() + 1<10? "0"+(date.getMonth() + 1):date.getMonth() + 1;
	  	let strDate = date.getDate()<10? "0" + date.getDate():date.getDate();
	  	let currentdate = date.getFullYear() + seperator1  + month  + seperator1  + strDate + " "  + date.getHours()  + seperator2  + date.getMinutes()
	  			
	  			+ seperator2 + date.getSeconds();
	  	return currentdate;
	  },
	CommentClick(){
		this.$Toast.loading({
		  message: '证书生成中...',
		  forbidClick: true,
		  mask:true,
		  forbidClick:false,
		  duration:0
		});
		let arr = {
			// JSON.stringify()
			act_id:this.$route.query.act_id,
			score:JSON.stringify(this.score),
			optional:JSON.stringify(this.optional_list_text),
			content:this.content,
			is_anonymous:this.is_anonymous,
			user_id:this.user_id
		}
		if(arr.content&&arr.score){
			postActivityComment(arr).then(res=>{
				Toast(res.msg);
				if(res.code==1){
					this.$Toast.clear()
					this.outTime=setTimeout(()=>{
						if(res.code==1){
							this.$router.back(-1)
						}
					},1000)
				}
			})
		}else{
			Toast('请输入内容或评分');
		}
		
	},
	is_anonymousClick(){
		this.isacr=!this.isacr
		this.is_anonymous=this.isacr?1:0
	}
  },
  beforeCreate() {
  	clearTimeout(this.outTime)
  },
  mounted() {
  	document.title = this.title;   
	// getActivity_t({act_id:this.$route.query.act_id}).then(res=>{
	// 	this.actList=res.data
	// 	console.log(res)
	// })
	getActivityCommentSubjectList({act_id:this.$route.query.act_id}).then(res=>{
		console.log(res.data)
		this.actList=res.data.act_info
		this.list=res.data.subject_list
		let arr = []
		this.list.forEach((item,i)=>{
			arr.push({
				subject_id:item.id,
				score:0
			})
		})
		this.score=arr
		this.signup_tutor=res.data.signup_tutor
		this.optional_list=res.data.optional_list
		this.optional_list.forEach(item => {
			this.optional_list_text.push({
				subject_id:item.id,
				content:''
			})
		})
	})
	
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.evaluate {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		padding-bottom: 80px;
		background-color: #F7F8FF;

		.ping {
			width: 4.375rem;
			height: 1.625rem;
			border: 1px solid #0081FF;
			border-radius: 20px;
			color: #fff;
			background-color: #0081FF;
			font-size: 0.75rem;
			line-height: 1.5rem;
			text-align: center;
			box-sizing: border-box;
			float: right;
			margin-left: 16px;
		}

		.Ping_hui {
			color: #919CB0;
			border-color: #919CB0;
			background-color: #fff;

		}

		.Ping_blue {
			color: #0081FF;
			border-color: #0081FF;
			background-color: #fff;
		}

		.vanSwipe {
			width: 92%;
			margin: 0 auto 0.9375rem;
			height: 7.5rem;

			img {
				border-radius: 8px;
			}

		}

		.btnBox {
			.btn {
				width: 7.1875rem;
				height: 5.625rem;
				background: no-repeat center;
				background-size: 109% 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: #FFFFFF;
				font-size: 12px;

				div.title {
					margin-bottom: 6px;
					margin-top: -10px;
				}

				div.btn_number {
					font-size: 28px;
					font-weight: bold;
				}
			}

			.btn1 {
				background-image: url(../../assets/Train/bg_yellow@2x.png);
			}

			.btn2 {
				background-image: url(../../assets/Train/bg_red@2x.png);
			}

			.btn3 {
				background-image: url(../../assets/Train/bg_blue@2x.png);
			}
		}

		.list_title {
			justify-content: space-between;
			text-align: left;

			// margin: 20px 0 20px;
			.list_title_left {
				width: 0.1875rem;
				height: 1.125rem;
				background: #0081FF;
				border-radius: 0 0.09375rem 0.09375rem 0;
				margin-right: 0.75rem;
			}

			.list_title_img {
				width: 0.4375rem;
				height: 0.6875rem;
				margin-right: 0.90625rem;
				margin-left: 0.28125rem;
			}

			.left {
				font-size: 1.125rem;
				color: #28334A;
				font-weight: bold;
			}

			.right {
				font-size: 0.9375rem;
				color: #0081FF;
			}
		}

		.list {
			flex-wrap: inherit;
			padding: 15px;

			.list_img {
				margin-right: 0.71875rem;
			}

			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}

			.text {
				font-size: 0.75rem;
				color: #919CB0;
				margin-left: 0.5rem;
			}

			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}

			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}

			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}

			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}

			.btnimg_span {
				margin-right: -5px;

			}
		}

		.bottom {
			box-shadow: 0px -6px 10px 0px rgba(145, 156, 176, 0.1);
			// position: relative;
			// bottom: 0;
			// left: 0;
			// width: 100%;
			// height: 3.75rem;
			// padding: 12px 0;
			// background: #F7F8FF;

			// .bottom_input {
			// 	width: 75%;
			// 	height: 2.625rem;
			// 	margin: 0 auto;
			// 	background: #0081FF;
			// 	border-radius: 50px;
			// 	position: relative;
			// 	text-align: left;
			// 	line-height: 2.625rem;
			// 	text-align: center;
			// 	color: #fff;
			// 	.bottom_input_img {
			// 		width: 16px;
			// 		height: 16px;
			// 		position: absolute;
			// 		right: 15px;
			// 		top: 50%;
			// 		transform: translateY(-50%);
			// 	}
			// }
		}

		.Slide_up {
			z-index: 111;
			position: fixed;
			bottom: 0;
			height: 90vh;
			background: #fff;
			width: 100%;
			padding: 0.9375rem;
			text-align: left;

			// overflow: auto;
			.Slide_up_header {
				position: relative;
				font-size: 14px;
				text-align: center;

				.Slide_up_img {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					width: 30px;
					height: 30px;
					cursor: pointer;
				}
			}

			.btn {
				border-radius: 0.375rem;
				padding: 9px 13px;
				background: #F4F4F4;
				color: #919CB0;
				border: 1px solid transparent;
				margin-right: 10px;
				margin-top: 1rem;

			}

			.btn.activation {
				border-color: #0081FF;
				background: #FFFFFF;
				color: #0081FF;
			}

			.Slide_up_title {
				margin: 27px 0 0;
			}

			.btnSeach {
				width: 8.75rem;
				height: 42px;
				text-align: center;
				line-height: 42px;
				background: #0081FF;
				color: #fff;
				margin: 20px auto;
				border-radius: 21px;
			}
		}

	}
</style>
