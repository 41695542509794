<template>
	<div class="flex topBox">
		<div class="flex" style="justify-content: space-between;width: 100%;">
		<div class="flex">
			<div style="margin-right: 10px;">
				<van-image
				  width="40"
				  height="40"
				  :src="item.userinfo.user_head_img"
				  style="border-radius: 50%;"
				/>
			</div>
			<div>
				<p style="font-weight: Bold;"><span v-text="item.userinfo.user_name"></span></p>
				<p class="time" ><span v-text="setTime(item.createtime)"></span></p>
			</div>
		</div>
		<div style="margin-right: 4px;" v-if="isuserList==1">
			<img src="@/assets/bigSink/icon_gd_nor@2x.png" alt="" width="20px" @click.stop="showClick">
		</div>
		<div class="btn" :class="item.is_follow?'btn1':''" @click.stop="followClick(i,active)" v-if="user_id!==item.user_id&&isuserList==2">
			<span v-text="item.is_follow?'已关注':'+ 关注'"></span>
		</div>
		</div>
		<div style="margin-top: 18px;width: 100%;" v-if="item.type==1"> <span v-text="item.title" style="font-weight: bold;"></span></div>
		<div class="van-multi-ellipsis--l2" style="margin: 16px 0;color: #767E8D;" v-if="item.type==1">
			<span v-html="deleteHtmlTag(item.content)"></span>
		</div>
		<div class="van-multi-ellipsis--l2" style="margin: 16px 0 0;color: #767E8D;" v-if="item.type==2">
			<span v-text="item.thank"></span>
		</div>
		<div v-if="item.type==1&&item.cover_picture">
	<!-- 		<img src="@/assets/123.jpg" alt="" style="height: 170px;width: 100%;bord er-radius: 6px;">-->
			<img :src="item.cover_picture" alt="" style="height: 170px;width: 100%;">
		</div>
		<div v-if="item.type==2" class="article flex" @click="urlClick(i)">
		
			<img :src="item.cover_picture?item.cover_picture:require('@/assets/bigSink/icon_moren200_pre@2x.png')" alt="" style="width: 40px;height: 40px;">
		
			<div style="padding:0px 12.5px;width: calc(100% - 60px);">
				<div v-text="item.share_title" style="font-weight: bold;margin-bottom: 7.5px;"></div>
				<div v-text="item.share_url" style="font-size: 10px;color: #767E8D;"></div>
			</div>
		</div>
		<div class="flex kan" :style="item.cover_picture&&item.type!==1?'':'margin-top:10px;'">
			<div>
				<span v-text="item.browse_num" class="number_0"></span> 人看过 · <span v-text="item.comment_num" class="number_0"></span> 评论
			</div>
			<div class="flex">
				<span v-text="item.praise_num" class="number_0" v-if="item.praise_num"></span>
				<img src="@/assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;height: 20px;margin-left: 10px;" v-if="!item.is_praise" @click.stop="zanClick">
				<img src="@/assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;height: 20px;margin-left: 10px;" v-if="item.is_praise" @click.stop="zanClick">
			</div>
		</div>
	</div>
</template>

<script>
	import  {setTime}  from '@/util/setTime.js'
	export default {
		props: {
			isuserList:{
				type:Number,
				
			},
			item: {
				type: Object
			},
		},
		data() {
			return {
				user_id:0
			}
		},
		methods:{
			showClick(){
				this.$emit('showClick')
			},
			setTime(time){
				return setTime(time)
			},
			zanClick(){
				this.$emit('zanClick')
			},
			//去除html标签
			deleteHtmlTag(str){
			 str = str.replace(/<[^>]+>|&[^>]+;/g,"").trim();//去掉所有的html标签和&nbsp;之类的特殊符合
			 return str;
			},
		},
		mounted() {
			this.user_id=JSON.parse(localStorage.getItem('user_data')).user_id
			// console.log(this.item.userinfo)
		}
	}
</script>

<style lang="scss">
	
	
		.topBox {
			padding: 15px;
			background-color: #fff;
			    margin-bottom: 15px;
	
		}
	
		.bigBox {
			width: 100%;
			padding: 15px;
			background-color: transparent;
			text-align: left;
			.van-multi-ellipsis--l2{
				width: 100%;
			}
			.topBox {
				justify-content: space-between;
				background-color: #fff;
				padding: 20px 15px 24px;
				border-radius: 6px;
				
				.btn {
					padding: 5px;
					background-color: #0081FF;
					border-radius: 50px;
					color: #fff;
					font-size: 12px;
					border: 1px solid transparent;
				}
				.btn1{
					background-color: #fff;
					color: #0081FF;
					border: 1px solid #0081FF;
				}
			}
		}
	
		.time {
			color: #919CB0;
			font-size: 12px;
			transform: scale(.9);
		}
	
		.kan {
			justify-content: space-between;
			width: 100%;
			font-size: 12px;
			color: #919CB0;
			margin-top: 25px;
		}
		.article {
			width: 100%;
			background-color: #F4F4F4;
			border-radius: 2px;
			text-align: left;
			padding: 5px;
			margin-top:16px;
		}
	
</style>
