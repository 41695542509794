<template>
	<div class="follow">
		<div v-if="!count" class="default">
			<img src="../../assets/default/icon_wodegz@2x.png" alt="" class="default_img">
			<p>暂未关注任何人</p>
		</div>
		<div style="width: 100%;" v-if="list.length">
			<van-list v-model="loading" :finished="finished" finished-text=" " @load="onLoad">
				<van-cell v-for="(item,index) in list" :key="index" style="padding: 15px 0 15px; border-bottom:1px solid #F4F4F4 ;">
					<div slot="default" class="flex list" @click="homepageClick(item)">
						<div class="list_img list_img_pai " :class="['list_img_'+item.ranking]" >
							<van-image width="60" height="60" :src="item.user_head_img" radius='50%' />

						</div>
						<div style="width: 100%;">
							<div class="list_title flex">
								<div v-text="item.user_name"  class="van-ellipsis" style="max-width: 30%;font-size: 13px;"></div>
								<div v-text="item.qualifications" class="ellipse identity van-ellipsis" style="max-width: 66%;"></div>
								
							</div>
							<div v-text="item.user_signature">

							</div>
							<div class="flex" style="    justify-content: space-between;">
								<!-- 标题下左右边两个div -->
								<div>

									<div class="flex" >
										<img src="../../assets/Train/icon_xjigou@2x.png" alt="" style="width: 12px;height: 12px;">
										<div v-text="item.institution" class="text"></div>
									</div>
									<div class="flex">
										<div class="flex" style="margin-right: 1.875rem;">
											<img src="../../assets/Train/icon_xdizhi@2x.png" alt="" style="width: 12px;height: :;px;">
											<div v-text="item.city" class="text"></div>
										</div>
										<div class="flex">
											<img src="../../assets/Train/icon_guanzhu@2x.png" alt="" style="width: 12px;height: 12px;">
											<div v-text="item.fans_num+'人关注'" class="text"></div>
										</div>
									</div>
								</div>
								<div class="followBtn" :class="isfollow?'':'no'"> <span v-text="isfollow?'已关注':'+ 关注'" @click.stop="followClick(item.tutor_user_id)"></span></div>
							</div>
						</div>
					</div>
					<div style="font-size: 14px;" v-if="item.last_activity.act_status">
						<van-row>
						  <van-col span="6" style="color:#919CB0;">【<span v-text="item.last_activity.act_status==1?'报名中':item.last_activity.act_status==2?'已结束':item.last_activity.act_status==3?'已下线':item.last_activity.act_status==4?'已反馈':''"></span>】</van-col>
						  <van-col span="17" style="margin-left: -5px;"> <div class="flex"><span>【</span> <span class="van-ellipsis" style="max-width: 85%;" v-text="item.last_activity.act_name"></span> <span>】</span></div></van-col>
						</van-row>
					</div>
					
					<!-- <div class="flex">
						<div style="width:25%;"></div>
						<div class="van-ellipsis" style="width: 70%;">【这是一段最多显示一行的文字，多余的内容会被省略】</div>
					</div> -->
				</van-cell>
			</van-list>
		</div>
		

		<!-- 弹窗内容 -->
		<!-- <van-popup v-model="isAreaList" position="bottom">
			<div style="width: 100%;">
				<van-area :area-list="areaList" :columns-num="2" title="地址" @confirm="confirm" @cancel="cancel" />
			</div>
		</van-popup> -->
		<!-- <van-calendar v-model="isdata" @confirm="onConfirm" color="#0081FF" /> -->
		<!-- <van-calendar v-model="isdata_Section" type="range" @confirm="onConfirm_Section" color="#0081FF" /> -->
		<!-- <transition name="van-slide-up">
		  <div v-show="isAreaList" style="z-index: 3;position: fixed;bottom: 0;left: 0;width: 100%; ">
			 
		  </div>
		</transition> -->

	</div>
</template>

<script>
	import { getBanner,myFollowing,postFollow  } from '@/api'
	import { Toast } from 'vant';
	import areaList from '../../area.js'
export default {
  name: 'follow',
  components: {
  },
  data(){
	  return{
		  onloog:false,
		  isfollow:true,
		 /*搜索的值*/
		 value:'',
		 
		// 控制搜索显示隐藏
		visible:false,
		title:'我的关注',
		images: [
		  'https://img.yzcdn.cn/vant/apple-1.jpg',
		  'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		total_number:100,
		signUp_number:100,
		student_number:1000,
		/*list为放到页面渲染的，list1为请求的所有数据做懒加载处理*/
		list:[],
		/**
		 * identity:身份
		 * autograph：签名
		 * follow：关注
		 * name：姓名
		 * img：头像
		 * addres：地址
		 * company：公司
		 * 
		 * */
		list1: [	
		],
		loading: false,
		finished: false,
		count:1,
		page:1,
		limit:'8',
		secah:{},
		
	  }
  },
  watch:{
	  'value'(a,b){
		  console.log(a,b)
		  this.page='1'
		  this.list=[]
		  this.loading=false,
		  this.finished=false,
		  this.myFollowing(this.secah,{page:String(this.page),limit:this.limit,user_name:a})
	  }
  },
  methods:{
	  // 跳转导师主页
	  homepageClick(item){
	  		  console.log(item)
	  		  if(item.user_type>1){
	  			  this.$router.push({name:'tutorHomepage',query:{id:item.id}})
	  		  }
	  },
	  followClick(id){
		  this.isfollow=!this.isfollow
		  postFollow({tutor_id:id,is_follow:this.isfollow?1:0}).then(res=>{
		  	// console.log(res)
		  	if(res.code==1){
		  		Toast(res.msg);
		  	}
		  })
	  },
	   getFocus() {
	          window.scroll(0, 0);
	      },
	  // 我的关注
	
	  
	 
	  onConfirm_Section(res){
		  this.isdata_Section = false;
		  
		  this.oneDay1 = this.formatDate(res[0]);
		   this.oneDay2 = this.formatDate(res[1]);
	  },
	  // 单个日期选择
	dataClick(){
		   if(this.activation!=='开课日期')return
		  this.isdata=true
	  },
	formatDate(date) {
		console.log(date)
		return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
	  },
	onConfirm(date) {
		this.isdata = false;
		this.oneDay = this.formatDate(date);
	  },
	  // 选择城市做是否选择处理
	provinceClick(){
		  if(this.activation!=='城市')return
		  this.isAreaList=true
	  },
	open(){
		  this.isopen=!this.isopen
	  },
	onLoad() {
					  // console.log(obj)
					  myFollowing({page:this.page,limit:8}).then(res=>{
							  this.count=res.data.count
							  this.page++
							  if(res.data&&this.list.length< this.count){
											  this.list.push(...res.data.list)
							  				 // 加载状态结束
							  				 this.loading = false;
							  				 
							  				 if (this.list.length >= this.count) {
							  				  this.finished = true;
							  				  } 
							  }else{
							  				  this.finished = true;
							  				  this.list=[]
							  }
					})		  			 
	      },
		  activationClick(text){
			  this.activation=text
		  },
		  cancel(){
			  console.log('222')
			  this.isAreaList=false
		  },
		  confirm(res){
			this.province=res[0].name
			this.city=res[1].name
			 this.isAreaList=false
		  }
  },
  
  mounted() {
  	document.title = this.title; 
	this.onLoad()
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.follow {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		padding:0 15px;
		padding-bottom: 60px;
		.followBtn{
			border: 1px solid #0081FF;
			color: #0081FF;
			border-radius: 20px;
			width: 3.4375rem;
			height: 1.625rem;
			text-align: center;
			line-height: 1.5rem;
			font-size: 0.6875rem;
		}
		.followBtn.no{
			background-color: #0081FF;
			color: #fff;
			border: none;
			line-height: 26px;
		}
		.vanSwipe {
			width: 92%;
			margin: 0 auto 0.9375rem;
			height: 7.5rem;

			img {
				border-radius: 8px;
			}

		}

		.identity {
			color: #919CB0;
			font-size: 12px;
			font-weight: 100;
			margin-left: 0.8125rem;
		}

		.btnBox {
			.btn {
				width: 7.1875rem;
				height: 5.625rem;
				background: no-repeat center;
				background-size: 109% 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: #FFFFFF;
				font-size: 12px;

				div.title {
					margin-bottom: 6px;
					margin-top: -10px;
				}

				div.btn_number {
					font-size: 28px;
					font-weight: bold;
				}
			}

			.btn1 {
				background-image: url(../../assets/Train/bg_yellow@2x.png);
			}

			.btn2 {
				background-image: url(../../assets/Train/bg_red@2x.png);
			}

			.btn3 {
				background-image: url(../../assets/Train/bg_blue@2x.png);
			}
		}

		.list_title {
			justify-content: end;
			
			// margin: 20px 0 20px;
			.list_title_left {
				width: 0.1875rem;
				height: 1.125rem;
				background: #0081FF;
				border-radius: 0 0.09375rem 0.09375rem 0;
				margin-right: 0.75rem;
			}

			.list_title_img {
				width: 0.4375rem;
				height: 0.6875rem;
				margin-right: 0.90625rem;
				margin-left: 0.28125rem;
			}

			.left {
				font-size: 1.125rem;
				color: #28334A;
				font-weight: bold;
			}

			.right {
				font-size: 0.9375rem;
				color: #0081FF;
			}
		}

		.list {
			flex-wrap: inherit;

			.list_img {
				margin-right: 0.71875rem;
				position: relative;
			}

			.list_img:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 6px;
				right: 0;
				width: 1.375rem;
				height: 1.25rem;
				background: no-repeat center;
				background-size: 100% 100%;
			}

			.list_img.list_img_1:after {

				background-image: url(../../assets/Train/icon_1@2x.png);

			}

			.list_img.list_img_2:after {

				background-image: url(../../assets/Train/icon_2@2x.png);

			}

			.list_img.list_img_3:after {

				background-image: url(../../assets/Train/icon_3@2x.png);

			}


			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}

			.text {
				font-size: 0.75rem;
				color: #919CB0;
				margin-left: 0.5rem;
			}

			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}

			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}

			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}

			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}

			.btnimg_span {
				margin-right: -5px;

			}
		}

		.bottom {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			background: #fff;
			height: 3.75rem;
			padding: 12px 0;
			box-shadow: 0px 3px 15px 0px #919CB0;

			.bottom_input {
				width: 95%;
				height: 2.25rem;
				margin: 0 auto;
				background: #F4F4F4;
				border-radius: 1.125rem;
				position: relative;
				text-align: left;
				line-height: 2.25rem;
				padding-left: 15px;

				.bottom_input_img {
					width: 16px;
					height: 16px;
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		.Slide_up {
			z-index: 2;
			position: fixed;
			bottom: 0;
			height: 90vh;
			background: #fff;
			width: 100%;
			padding: 0.9375rem;
			text-align: left;

			// overflow: auto;
			.Slide_up_header {
				position: relative;
				font-size: 14px;
				text-align: center;

				.Slide_up_img {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					width: 30px;
					height: 30px;
					cursor: pointer;
				}
			}

			.btn {
				border-radius: 0.375rem;
				padding: 9px 13px;
				background: #F4F4F4;
				color: #919CB0;
				border: 1px solid transparent;
				margin-right: 10px;
				margin-top: 1rem;

			}

			.btn.activation {
				border-color: #0081FF;
				background: #FFFFFF;
				color: #0081FF;
			}

			.Slide_up_title {
				margin: 27px 0 0;
			}

			.btnSeach {
				width: 8.75rem;
				height: 42px;
				text-align: center;
				line-height: 42px;
				background: #0081FF;
				color: #fff;
				margin: 20px auto;
				border-radius: 21px;
			}
		}

	}
</style>
