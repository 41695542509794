<template>
	<div class="letter">
		<div class="message" id="message">
			<div v-if="count>10&&!finished" class="long" @click="long">查看更多</div>
			<van-list v-model="loading" :finished="finished"  @load="onLoad" direction="up">
				<div v-for="(item,i) in list" :key="i">

					<div :class="item.from_user_id!==user_id?'send ':'show'">
						<div class="time margin" v-if="item.createtime">
							<span v-text="item.createtime" style="    letter-spacing: 0;"></span>
						</div>
						<div class="msg">
							<img :src="item.from_user_head_img" alt="" />
							<p :class="item.from_user_id!==user_id?'owns':'own'" v-text="item.content"></p>
						</div>
					</div>
				</div>
			</van-list>

			<!-- <div class="">
				<div class="time">05/22 06:30</div>
				<div class="msg ">
					<img src="images/touxiangm.png" alt="" />
					<p class="own">你好你好你好</p>
				</div>
			</div> -->
		</div>
		<div class="footer" style="padding-left:15px ;">
			<!-- <img src="images/hua.png" alt="" />
			<img src="images/xiaolian.png" alt="" /> -->
			<van-field rows="1" :autosize="{ minHeight: 26 }" type="textarea" v-model="text" placeholder="说点什么吧..." style=" background-color: #F4F4F4;margin-right: 5px;border-radius: 6px;padding: 5px 12px;" />
			<!-- <input type="text" placeholder="" v-model="text" /> -->
			<p @click="send">发送</p>
		</div>
	</div>
</template>

<script>
	import {
		postLetter,
		getContentLists
	} from '@/api';
	export default {
		name: 'letter',
		components: {},
		data() {
			return {
				loading:false,
				finished:false,
				text: '',
				title: '私信',
				list: [],
				user_id: '',
				user_data: {},
				count: 1,
				page: 1,
				msgTime: 0,
				
				msgArr: [],
				valTime: '',
				// 收件人id
				to_user_id: 0,
				isact:true,
			}
		},
		watch: {
			list() {
				if(this.isact){
					this.$nextTick(() => {
						let container = this.$el.querySelector(".message");
						container.scrollTop = container.scrollHeight;
					});
				}else{
					this.$nextTick(() => {
						let container = this.$el.querySelector(".message");
						container.scrollTop = 800;
					});
				}
				
			}
		},
		methods: {

			timeGe(time) {
				// let realy = time
				let nowTime = new Date(time);
				if (this.msgTime - nowTime.getTime() < -60000) {
					this.msgTime = nowTime.getTime();
					let nowMonth = nowTime.getMonth();
					let nowDay = nowTime.getDate();
					let nowHours = nowTime.getHours();
					let nowMinutes = nowTime.getMinutes();
					nowMonth = nowMonth < 10 ? "0" + (nowMonth + 1) : nowMonth + 1;
					nowDay = nowDay < 10 ? "0" + nowDay : nowDay;
					nowHours = nowHours < 10 ? "0" + nowHours : nowHours;
					nowMinutes = nowMinutes < 10 ? "0" + nowMinutes : nowMinutes;
					let nowT = `${nowTime.getFullYear()}/${nowMonth}/${nowDay}  ${nowHours}:${nowMinutes}`;
					this.list.push({
						content: this.text,
						createtime: nowT,
						from_user_head_img: this.user_data.user_head_img,
						from_user_id: this.user_data.user_id,
						from_user_name: this.user_data.user_name,
						leter_id: this.$route.query.id,
						to_user_id: 123,
					})

					// this.msgArr.push({myMsg: this.text, robot: realy, msgTime: nowT});
				} else {
					this.list.push({
						content: this.text,
						// createtime: nowT,
						from_user_head_img: this.user_data.user_head_img,
						from_user_id: this.user_data.user_id,
						from_user_name: this.user_data.user_name,
						leter_id: this.$route.query.id,
						to_user_id: 123,
					})
					//  this.msgArr.push({myMsg: this.text, robot: realy});
				}
				// console.log( this.ms gArr)
			},

			long() {
				// this.page++
				// getContentLists({
				// 	leter_id: String(this.$route.query.id),
				// 	page: this.page

				// }).then(res => {

				// 	this.count = res.data.count
				// 	this.list.push(...res.data.list)
				// 	this.$nextTick(() => {
				// 		let container = this.$el.querySelector(".message");
				// 		container.scrollTop = 0;
				// 	});
				// 	// console.log(this.list)

				// })
			},
			isst(text) {
				let time = new Date(text).getTime()
				return time
			},
			send() {
				let time = new Date()
			

				// this.changeToBottom()
				// this.text=''
				// this.list.push({
				// 	content: this.text,
				// 	createtime: "2020/01/31 17:09",
				// 	from_user_head_img: this.user_data.user_head_img,
				// 	from_user_id: this.user_data.user_id,
				// 	from_user_name: this.user_data.user_name,
				// 	leter_id: this.$route.query.id,
				// 	to_user_id: 123,
				// })
				// let tableScroll = document.querySelectorAll('.message') //获取table

				// this.$nextTick(() => {

				// 	console.log(tableScroll)

				// 	for (let i = 0; i < tableScroll.length; i++) {

				// 		//在滚动条出现的地方设置scrollTop值为scrollHeight

				// 		tableScroll[i].children[2].scrollTop = tableScroll[i].children[2].scrollHeight

				// 	}

				// })
				postLetter({
					user_id: this.from_user_id,
					content: this.text
				}).then(res => {
					// this.getContentLists()
					if (res.code == 1) {
						this.timeGe(time)
						this.text = ''
					}else{
						
					}
				})
				.catch(res=>{
					alert('发送失败')
				})
			},
			getContentLists() {
				getContentLists({
					leter_id: String(this.$route.query.id),
					page: 1

				}).then(res => {
					if(this.count!==res.data.count){
						console.log('更新了')
						
						let count=res.data.count-this.count
						this.count=res.data.count
						let list=res.data.list.slice(0,count)
						console.log(list)
						console.log(this.user_id , list[0].from_user_id)
						if(this.user_id !== list[0].from_user_id){
							this.list.push(...list)
						}
					}
				// 	this.count = res.data.count
				// 	this.list = res.data.list.reverse()
				
				// 	if(res.data.list[0].from_user_id==this.user_id){
				// 		this.from_user_id = res.data.list[0].to_user_id
				// 	}else{
				// 		this.from_user_id = res.data.list[0].from_user_id
				// 	}
				console.log(res)
				})
			},
			destroyed(){
			            if(this.valTime) { //如果定时器在运行则关闭
						this.list=[]
			                clearInterval(this.valTime); 
			            }
			},
			 onLoad() {
			      // 异步更新数据
			      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
				 //  getContentLists({
				 //  	leter_id: String(this.$route.query.id),
				 //  	page: this.page
				  
				 //  }).then(res => {
					// this.loading = false;
				 //  	this.count = res.data.count
				 //  	this.list.push(...res.data.list)
				 //  	this.$nextTick(() => {
				 //  		let container = this.$el.querySelector(".message");
				 //  		container.scrollTop = 0;
				 //  	});
					// if (this.list.length >= res.count) {
					//   this.finished = true;
					// }
				 //  	// console.log(this.list)
				  
				 //  })
				  if(this.page==3){
					  this.isact=false
				  }
				 getContentLists({
				 	leter_id: String(this.$route.query.id),
				 	page: this.page
					
				 }).then(res => {
					 if(res.code==0){
						 this.loading = false;
						 this.finished = true;
					 }
					 console.log(this.page)
					 console.log(res.data.list)
					 console.log(res.data)
					 res.data=res.data?res.data:{
						 count:0,
						 list:[]
					 },
					 console.log( res.data)
				 	this.count = res.data.count
					
				 	this.list.unshift(...res.data.list.reverse())
					this.loading = false;
					this.page++
				 	if(res.data.list[0].from_user_id==this.user_id){
				 		this.from_user_id = res.data.list[0].to_user_id
				 	}else{
				 		this.from_user_id = res.data.list[0].from_user_id
				 	}
					if (this.list.length >= res.data.count) {
					  this.finished = true;
					}
					console.log(	this.list,'查看列表数量')
				 })
			//       setTimeout(() => {
			//         for (let i = 0; i < 10; i++) {
			//           this.list.push(this.list.length + 1);
			//         }
			
			//         // 加载状态结束
			//         this.loading = false;
			
			//         // 数据全部加载完成
			//         if (this.list.length >= 40) {
			//           this.finished = true;
			//         }
			//       }, 1000);
			    }

		},
		mounted() {
			this.user_id = JSON.parse(localStorage.getItem('user_data')).user_id
			this.user_data = JSON.parse(localStorage.getItem('user_data'))
			this.from_user_id=this.$route.query.id
			console.log(this.user_id)
			document.title = this.$route.query.name ? this.$route.query.name : ' '
			// this.getContentLists()
			this.valTime = setInterval(() => {
				this.getContentLists()
			}, 5000)


		},
		destroyed() {
			console.log('我离开了')
			// if (this.valTime) {
				this.destroyed()
			// }
		}
	}
</script>

<style lang="scss" type="text/css">
	.long {
		margin-bottom: 10px;
	}

	.letter {
		width: 100%;
		/* height: 100vh; */
		background-color: #F4F4F4;
		padding-top: 1rem;

		height: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;

		.message {
			flex-grow: 1;
			overflow: auto;
			/* background-color: #f5f5f5; */
			height: 100vh;
			/* background-color: red; */
			/* background-color: #f1f1f1; */
			padding: 0rem 0.9375rem 1.1rem 0.9375rem;
			padding-bottom: 3.875rem;
		}

		.time {
			font-size: 0.625rem;
			color: #919CB0;
			margin: 1rem 0;
			text-align: center;
		}

		.margin {
			margin-top: 0;
		}

		.footer {
			position: fixed;
			bottom: 0;
			// height: 2.875rem;
			background-color: #fff;
			// line-height: 2.875rem;
			width: 100%;
			max-width: 100%;
			border-top: 1px solid #ddd;
			display: flex;
			align-items: center;
			padding: 7px 0;
		}

		.footer img {
			margin-left: 0.2rem;
			width: 0.5rem;
			border-radius: 50%;
		}

		.footer input {
			/* margin-left: 0.2rem;
		width: 80%;
		height: 2.0625rem;
		border-radius: 0.1rem;
		border: 0.01rem solid #ddd;
		padding: 0 0.15rem;
		background-color: #F4F4F4;
		padding: 0 12px;
		border-radius: 0.375rem;
 */
		}

		.footer p {
			width: 3.75rem;
			/* height:0.68rem; */
			font-size: 1rem;
			color: #0081FF;
			/* line-height:0.68rem; */
			text-align: center;
			/* border-radius: 0.1rem; */
			float: center;
			margin-top: 0.2rem;
			margin-right: 0.2rem;
		}

		.send:after,
		.show:after,
		.msg:after {
			content: "";
			clear: both;
			display: table;
		}

		.msg>img {
			width: 2.375rem;
			float: left;
			/* background-color: red; */
			height: 2.375rem;
			border-radius: 50%;
		}

		.msg>p {
			float: left;
			margin: 0 0.4rem;
			padding: 0.65rem 0.625rem;
			background: #fff;
			font-size: 0.9375rem;
			position: relative;
			border-radius: 0.375rem;
			max-width: 80%;
			/* min-height: 2.275rem; */
			box-sizing: border-box;
			text-align: left;
		}

		.msg .own {
			background-color: #0081FF;
			color: #fff;
			border-top-right-radius: 0;
		}

		.msg .owns {
			background-color: #fff;
			color: #28334A;
			border-top-left-radius: 0;
		}

		.msg_input {
			position: absolute;
			background: url(./images/msg-input.png) no-repeat;
			background-size: 0.31rem auto;
			width: 0.31rem;
			height: 0.51rem;
			left: -0.31rem;
			top: 0.25rem;
		}

		.show .msg img,
		.show .msg p,
		.show .msg {
			float: right;
		}


		.show .msg_input {
			left: auto;
			right: -0.11rem;
			transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			/* IE 9 */
			-moz-transform: rotate(180deg);
			/* Firefox */
			-webkit-transform: rotate(180deg);
			/* Safari 和 Chrome */
			-o-transform: rotate(180deg);
			/* Opera */
		}

		.send,
		.show {
			padding-bottom: 0.3rem;
		}

		.alert_novip,
		.flower_num,
		.give_flower {
			display: none;
			padding: 0.3rem 0.5rem;
			font-size: 0.28rem;
		}

		.alert_novip p,
		.flower_num p {
			margin-bottom: 0.45rem;

			.layui-layer-wrap button {
				font-size: 0.28rem;
				padding: 0.2rem 0.3rem;
				margin-top: 0.1rem;
				background: #f8f8f8;
				border-radius: 10px;
			}
		}

		.flower_num button {
			padding: 0.2rem 0.5rem;
			border-radius: 10px;
		}

		.layui-layer-wrap button:first-child {
			float: left;
		}

		.layui-layer-wrap button:last-child {
			float: right;
			background: #FF7171;
			color: #fff;
		}

		.alert_novip button {
			padding: 0.2rem 0.3rem;
			border-radius: 10px
		}

		.flower {
			width: 0.8rem;
			margin: 0 auto;
		}

		.give_flower {
			text-align: center;
		}

		.give_flower p {
			text-align: center;
			line-height: 1.5;
		}

		.give_flower input {
			width: 1rem;
			margin-right: 0.1rem;
			margin-top: 0.2rem;
		}

		.give_flower button {
			display: block;
			width: 3rem;
			font-size: 0.28rem;
			margin: 0 auto;
			margin-top: 0.6rem;
			float: none !important;
			line-height: 0.65rem;
			border-radius: 10px;
		}

		.van-cell {
			padding: 0;
		}
	}
</style>
