<template>
	<div class="lyc_details">

		<div class="viewArticlesTop">
			<div class="viewArticlesTop_flex" style="">
				<div class="flex" style="justify-content: space-between;">


					<div class="flex">
						<div style="margin-right: 10px;" @click="$router.push({path:'/tutorHomepage',query:{id:userinfo.user_id}})">
							<img :src="userinfo.user_head_img" alt="" style="width: 41px;border-radius: 50%;">
						</div>
						<div>
							<p style="font-weight: Bold;    align-items: flex-end;" class="flex"><span v-text="userinfo.user_name"></span>
								<span class="time" v-text="setTime(article.createtime)" style="margin-left: 10px;"></span></p>
							<p style="color: #919CB0;font-size: 10px;" v-text="userinfo.user_title"></p>
						</div>
					</div>
					<div :class="article.is_follow?'btn1':'btn '" v-if="user_id!==userinfo.user_id" @click="postFollow(userinfo.user_id,article.is_follow)">
						<span v-text="article.is_follow?'已关注':'+ 关注'"></span>
					</div>
				</div>
				<!-- <div>
					<video :src="article.url"></video>
				</div> -->
				<div class="flex top">
					<div><span v-text="article.title"></span></div>
					<div><span v-text="'【'+article.type+'】'"></span></div>
				</div>
				<div class="ylcBox">
					<img src="../../assets/bigSink/bg_zhezhao@2x.png" alt="" class="top" style="width: 100%;margin: 0;padding: 0;" v-if="!article.isplay">
					<div class="filter" v-if="!article.isplay" :style="'background-image: url('+ article.coverpic +');'">
					</div>
					<img :src="article.coverpic" alt="" class="bg" v-if="!article.isplay">
					<video :src="article.url" class="bg" v-show="article.isplay" id='videoId' controls></video>
					
					<div class="flex bot" style="justify-content: flex-end;" v-if="!article.isplay">
						
						<div class="time">
							<span v-text="setMinute(article.video_time)" class="number_0" style="color: #fff;"></span>
						</div>
					</div>
					<img src="../../assets/user/bofan.png" alt="" class="conter" @click="bofanClick" v-if="!article.isplay">
				
				</div>
				<div class="number_0Box">
					<span v-text="article.view_num" class="number_0"></span> 次播放
				</div>
			</div>
			<div class="bottom " style="min-height: 46px;box-shadow: rgb(148, 149, 155) 1px 5px 15px 1px;height: auto;" :style="isshu?'z-index:3000;':''">
				<div class="flex bottomBox">
					<!-- <input type="text" placeholder="说点什么吧" @focus="isshu=true" @blur="isshu=false" :style="isshu?'width:85%':'width:50%'"> -->
					<van-field
					  v-model="questionsTo"
					  rows="1"
					  :autosize="{maxHeight: 100}"
					  type="textarea"
					  :placeholder="placeholder"
					  class="input"
					 :style="isshu?'width:85%':'width:70%'"
					 @focus="isshu=true" 
					 ref="inputFocus"
					/>
					<div class="flex" style="justify-content: space-around;width: 30%;" v-if="!isshu">
						<!-- <div @click="postWeixinAidHotArticleUserCollectAdd(article.is_collect,article.id)">
							<img src="../../assets/bigSink/icon_shouc b_nor@2x.png" alt="" v-if="!article.is_collect">
							<img src="../../assets/bigSink/icon_shoucb_pre@2x.png" alt="" v-if="article.is_collect">
						</div> -->
						<div style="position: relative;">
							<img src="../../assets/bigSink/icon_pinlun_b@2x.png" alt="">
							<div class="follow number_0" v-text="replycount" v-if="replycount">
								
							</div>
						</div>
						<div style="position: relative;">
							<img src="../../assets/bigSink/icon_zanb_nor@2x.png" alt="" v-if="!article.is_praise" @click="postVideoPraise(article.id,1)">
							<img src="../../assets/bigSink/icon_zanb_pre1@2x.png" alt="" v-if="article.is_praise" @click="postVideoPraise(article.id,2)">
							<div class="follow number_0" v-text="article.praise_num" v-if="article.praise_num">
								
							</div>
						</div>
					</div>
					<div style="color: #0081FF;text-align: right;margin-left: 14px;" v-if="isshu" @click="sendOutClick">
						发送
					</div>
				</div>
			</div>
			
			<div style="height: 10px;background-color: #F7F8FF;width: 100%;"></div>
			<div style="margin-top: 10px;text-align: left;padding: 0 15px;">
				<van-row justify="space-between" style="padding: 20px 0;border-bottom: 1px solid #E5E5E5;">
					<van-col span="12">
						<span style="font-size: 17px; font-weight: bold;">评论<span v-text="replycount"></span></span>
					</van-col>
					<van-col span="12">
						<div style="color: #0081FF;font-weight: bold;justify-content: flex-end;" class="flex" v-if="iszuixin" @click="taggleZui('hot')">
							<img src="../../assets/bigSink/icon_zuixin@2x.png" alt="" style="width:16px;margin-right: 8px;">
							<div>最新</div>
						</div>
						<div style="color: #0081FF;font-weight: bold;justify-content: flex-end;" class="flex" v-if="!iszuixin" @click="taggleZui('time')">
							<img src="../../assets/bigSink/icon_redu@2x.png" alt="" style="width:16px;margin-right: 8px;">
							<div>热度</div>
						</div>
					</van-col>
				</van-row>
				<div class="defaultBox" v-if="!replycount">
					<img src="../../assets/default/icon_zanwusx@2x.png" alt="" class="default_img" />
					<p style="    padding-bottom: 40px;">暂无评论</p>
				</div>
				<van-row style="padding: 20px 0; border-bottom: 1px solid #E5E5E5;" v-for="(item,i) in replylist" :key="i" v-if="replycount" @click="isshu=false">
					<van-col span="3">
						<img :src="item.userinfo.user_head_img" alt="" style="border-radius: 50%;width: 32px;">
					</van-col>
					<van-col span="21">
						<div style="font-weight: bold;margin-bottom: 10px;" v-text="item.userinfo.user_name"></div>
						<div style="color: #767E8D;font-size: 13px;text-align: justify;" v-text="item.content"></div>
						<div style="margin: 16px 0 0;color: #919CB0;font-size: 12px;">
							<van-row type="flex" justify="space-between">
								<van-col span="12">
									<span class="number_0" v-text="setTime(new Date(item.create_time)/1000)">12-20 16:30</span>
								</van-col>
								<van-col span="12" class="flex" style="justify-content: flex-end;">
									<div class="number_0" v-text="item.praise_num"></div>
									<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" @click="postVideoReplyPraise(item.id,1)" v-if="!item.is_praise">
									<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" v-if="item.is_praise"  @click="postVideoReplyPraise(item.id,2)">
									<img src="../../assets/bigSink/icon_pinlun_s@2x.png" alt="" style="width: 20px;"  @click.stop="inputFocus(1,item)">
								</van-col>
							</van-row>
						</div>
						<div style="color: #0081FF;margin-top: 10px;" class="flex" @click="huiClick(i)" v-if="item.comment_count">
							<div style="font-size: 12px;"><span v-text="item.comment_count"></span>条回复</div><img src="../../assets/bigSink/icon_gengduo@2x.png"
							 alt="" style="width: 6px;height: 10px;margin-left: 5px;display: block;">
						</div>

					</van-col>

				</van-row>
			</div>
			<div style="margin:16px 0 25px ;text-align: center;font-size: 12px;" v-if="replycount&&replycount!==replylist.length" @click="moreClick">点击查看更多评论</div>
			<div class="van-list__finished-text" v-if="replycount&&replycount==replylist.length">没有更多了</div>
		</div>
		<van-popup v-model="show" closeable position="bottom" :style="{ height: '82%' }" v-if="replycount">
			<div style="margin: 16px 0 8.5px;">查看全部回复</div>
			<div style="text-align: left;    height: calc(100% - 60px); overflow: auto;">
				<van-row style="padding: 20px 15px; border-bottom: 1px solid #E5E5E5;">
					<van-col span="3">
						<img :src="replylist[index].userinfo.user_head_img" alt="" style="border-radius: 50%;width: 32px;">
					</van-col>
					<van-col span="21">
						<div style="font-weight: bold;margin-bottom: 10px;" v-text="replylist[index].userinfo.user_name"></div>
						<div style="color: #767E8D;font-size: 13px;text-align: justify;" v-text="replylist[index].content"></div>
						<div style="margin: 16px 0 0;color: #919CB0;font-size: 12px;">
							<van-row type="flex" justify="space-between">
								<van-col span="12">
									<span class="number_0" v-text="setTime( replylist[index].create_time)"></span>
								</van-col>
								<van-col span="12" class="flex" style="justify-content: flex-end;">
									<div class="number_0" v-text="replylist[index].praise_num"></div>
									<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" @click="postVideoReplyPraise(replylist[index].id,1)" v-if="!replylist[index].is_praise">
									<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" @click="postVideoReplyPraise(replylist[index].id,2)" v-if="replylist[index].is_praise">
									<img src="../../assets/bigSink/icon_pinlun_s@2x.png" alt="" style="width: 20px;" @click="inputFocus(1,replylist[index])">
								</van-col>
							</van-row>
						</div>


					</van-col>

				</van-row>
				<div style="padding: 28px 15px 0;">

					<div style="font-weight: bold;"> 全部回复 <span style="color: #767E8D;">(<span v-text="replylist[index].commentcount"></span>)</span>
					</div>
					<van-row style="padding: 20px 0px; border-bottom: 1px solid #E5E5E5;" v-for="(item,i) in replylistList_rep.commentlist" :key="i">
						<!-- {{JSON.stringify(item)}} -->
						<van-col span="3">
							<img :src="item.userinfo.user_head_img" alt="" style="border-radius: 50%;width: 32px;">
						</van-col>
						<van-col span="21">
							<div style="font-weight: bold;margin-bottom: 10px;" v-text="item.userinfo.user_name"></div>
							<div style="color: #767E8D;font-size: 13px;text-align: justify;" v-text="item.content"></div>
							<div style="margin: 16px 0 0;color: #919CB0;font-size: 12px;">
								<van-row type="flex" justify="space-between">
									<van-col span="12">
										<span class="number_0" v-text="setTime(item.createtime)"></span>
									</van-col>
									<van-col span="12" class="flex" style="justify-content: flex-end;">
										<div class="number_0" v-text="item.praise_num" v-if="item.praise_num"></div>
										<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" @click="postVideoReplyPraise(item.id,1)" v-if="!item.is_praise">
										<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" @click="postVideoReplyPraise(item.id,2)" v-if="item.is_praise">
										<img src="../../assets/bigSink/icon_pinlun_s@2x.png" alt="" style="width: 20px;" @click.stop="inputFocus(1,replylistList_rep.replyinfo)">
									</van-col>
								</van-row>
							</div>


						</van-col>

					</van-row>
				</div>

			</div>
		</van-popup>
		<van-popup v-model="show1" closeable position="bottom" :style="{ height: '82%' }">
			<div style="margin: 16px 0 8.5px;">查看分享视频</div>

			<div class="ifameClass">
				<!-- <div style="padding: 27px 0;font-weight: bold;border-bottom: 1px solid #EEEEEE;">
					<span v-text="article.title"></span>
				</div>
				<div style="font-size: 11px;padding: 9.5px 0 20px;">
					<span v-text="article.time" class="hui number_0" style="margin-right: 10px;" ></span>
					<span v-text="article.form" class="blue" ></span>
				</div>
				<img :src="article.img" alt="" style="width: 100%;">
				<div style="margin-top: 18px;color: #767E8D;">
					<p v-text="article.conten"></p>
				</div> -->
				<!-- <iframe src="http://192.168.5.10:8071/viewArticles?id=7" style="width: 100%;height: 100%;" frameborder="0"
				 scrolling="auto"></iframe> -->
			</div>
		</van-popup>

	</div>

</template>

<script>
	import {postVideoPraise,postWeixinAidHotArticlePraise,postWeixinAidHotArticleUserInformationReply,postWeixinAidHotArticleUserCollectAdd,getVideoCommentDetail, getVideoDetail ,getWeixinAidHotArticleCommentReplyList,postVideoReply,postFollow,postVideoReplyComment,postVideoReplyPraise,postVideoViewNum,getSign} from '@/api'
	import { Toast } from 'vant';
	import { setTime,setMinute } from '@/util/setTime.js'
	export default {
  name: 'lyc_details',
  components: {
  },
  watch:{
	  isshu(){
		  if(!this.isshu){
			  this.isCommentList=0
			  this.placeholder='说点什么吧'
		  }
	  },
	  show(){
		  if(!this.show){
			  this.isshu=false
		  }
	  }
  },
  data(){
	  return{
		  placeholder:'说点什么吧',
		  CommentIndex:0,
		  isCommentList:0,
		  // 自己的id
		  user_id:0,
		  page:1,
		  limit:8,
		  questionsTo:'',
		  iszuixin:true,
		  show1:false,
		  article:{
			  url:'https://www.sohu.com/a/190048557_349978',
			  title:'冠心病患者如何健康午睡？',
			  time:'2018-04-18',
			  form:'疾病库',
			  img:'http://img3.imgtn.bdimg.com/it/u=3089137238,4050596303&fm=11&gp=0.jpg',
			  conten:'你是不是看了好多篇“医疗人工智能应用十大方向”是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新'
		  },
		  show:false,
		  isshu:false,
		images: [
		  'https://img.yzcdn.cn/vant/apple-1.jpg',
		  'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		active:'',
		userinfo:{},
		replylist:[],
		replycount:0,
		index:0,
		replylistList_rep:[],
		// 评论的id
		Commentid:0
	  }
  },
  methods:{
	 // 播放
	 bofanClick(){
	 		  this.article.isplay=true
	 		  let video_id=document.getElementById("videoId")
			  postVideoViewNum({video_id:this.$route.query.id})
	 		 video_id.play()
	 },
	  // 视频点赞
	  postVideoPraise(id,type){
		  postVideoPraise({video_id:id,type:type}).then(res=>{
			   if(res.code==1){
			  	 this.getVideoDetail()
			   }
		  })
	  },
	  // // 点赞
	  postVideoReplyPraise(id,type){
		  
		  postVideoReplyPraise({reply_id:id,type:type}).then(res=>{
		  		// Toast(res.msg) 
				 if(res.code==1){
					 if(this.iszuixin){
					 	this.getVideoCommentDetail('time')
						// getWeixinAidHotArticleCommentReplyList({comment_id:this.replylist[i].id}).then(res=>{
						// 			  this.replylistList_rep=res.data
						// 			  console.log( this.replylistList_rep)
						// })
					 }else{
					 	this.getVideoCommentDetail('hot')
						// getWeixinAidHotArticleCommentReplyList({comment_id:this.replylist[i].id}).then(res=>{
						// 			  this.replylistList_rep=res.data
						// 			  console.log( this.replylistList_rep)
						// })
					 }
				 }
		  })
	  },
	  postWeixinAidHotArticleUserCollectAdd(is_collect,id){
		  postWeixinAidHotArticleUserCollectAdd({hot_article_id:id}).then(res=>{
			  if(res.code==1){
				  Toast(res.msg)
				 this.getVideoDetail()
			  }
		  })
	  },
	  // 点击评论 - 回复
	  inputFocus(index,item){
		  this.isCommentList=index
		  this.Commentid=item.id
		  this.placeholder=`回复：${item.userinfo.user_name}`
		  console.log('评论',item,this.Commentid)
		  this.$refs.inputFocus.focus()
	  },
	  // 点击关注
	  postFollow(id,isfollow){
	  		  let follow=isfollow==0?1:0
	  		  postFollow({tutor_id:id,is_follow:follow}).then(res=>{
	  			  Toast(res.msg)
				  if(follow==0){
					   this.article.is_follow=1
					   this.getVideoDetail()
				  }else{
					  this.article.is_follow=0
					   this.getVideoDetail()
				  }
	  		  })
	  },	
	  // 切换最新和热度
	  taggleZui(order){
		  console.log(order)
		  this.iszuixin=!this.iszuixin
		  this.getVideoCommentDetail(order)
	  },
	  // 评论文字
	  huiClick(i){
		  this.show=true
		  this.index=i
		  console.log(this.index)
		  this.replylistList_rep=this.replylist[i].comment_list
		  console.log(this.replylistList_rep)
		  console.log(this.replylist[this.index])
		  return
		  getWeixinAidHotArticleCommentReplyList({comment_id:this.replylist[i].id}).then(res=>{
			  this.replylistList_rep=res.data
			  console.log( this.replylistList_rep)
		  })
		 
	  },
	  // 获得视频详情
	getVideoDetail(){
		getVideoDetail({video_id:this.$route.query.id}).then(res=>{
			res.data.videoinfo.isplay=false
			this.article=res.data.videoinfo
			this.userinfo=res.data.userinfo
			this.article.is_collect=res.data.videoinfo.is_collect
			this.article.is_follow=res.data.videoinfo.is_follow
			this.article.is_praise=res.data.videoinfo.is_praise
			this.article.praise_num=res.data.videoinfo.praise_num
			// setTimeout(()=>{
			// 	var videoId = document.getElementById('videoId');
			// 	console.log(videoId.duration)
			// },500)
			
		})
		
	} ,
	// 获得视频评论列表
	getVideoCommentDetail(order){
		getVideoCommentDetail({order:order,video_id:this.$route.query.id,page:this.page,limit:this.limit}).then(res=>{
			
			this.replylist=res.data.replylist
			this.replycount=res.data.replycount
			console.log(this.replylist)
		})
	},
	// 查看跟多评论
	moreClick(){
		console.log(this.iszuixin)
		this.limit=this.replycount
		console.log(this.limit)
		if(this.iszuixin){
			this.getVideoCommentDetail('time')
		}else{
			this.getVideoCommentDetail('hot')
		}
	},
	// 发送
	sendOutClick(){
		switch (this.isCommentList){
			case 0:
			postVideoReply({video_id:this.$route.query.id,content:this.questionsTo}).then(res=>{
				Toast(res.msg)
				if(res.code==1){
					this.isshu=false
					this.questionsTo=''
					if(this.iszuixin){
						this.getVideoCommentDetail('time')
					}else{
						this.getVideoCommentDetail('hot')
					}
				}
			})
				break;
			case 1:
				postVideoReplyComment({reply_id:this.Commentid,content:this.questionsTo}).then(res=>{
					if(res.code==1){
						Toast(res.msg)
						this.isshu=false
						this.questionsTo=''
						this.isCommentList=0
						if(this.iszuixin){
							this.getVideoCommentDetail('time')
						}else{
							this.getVideoCommentDetail('hot')
						}
					}
				})
				break;
			default:
				break;
		}
		this.isshu=true
		
	},
	setTime(time){
		return setTime(time)
	},
	setMinute(time){
		return setMinute(time)
	}
	
  },
  mounted() {
  	document.title = '急救视频';   
	this.user_id=JSON.parse(localStorage.getItem('user_data')).user_id
	this.getVideoDetail()
	this.getVideoCommentDetail('time')

	if(localStorage.getItem('user_id')=='MzIw'){
		// setTimeout(()=>{
		// 		getSign({ page_url:  window.entryUrl})
		// 		  .then(res => {
		// 		    if (res.data.appId) {
		// 			this.$wx.config({
		// 				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
		// 				appId: res.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
		// 				timestamp: res.data.timestamp, // 必填，生成签名的时间戳
		// 				nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
		// 				signature: res.data.signature, // 必填，签名，见附录1
		// 				jsApiList: ['scanQRCode',
		// 							'updateTimelineShareData',
		// 							'updateAppMessageShareData',
		// 							'onMenuShareAppMessage',
		// 							'onMenuShareTimeline'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
		// 				});
					this.$wx.ready(res => {  // 微信SDK准备就绪后执行的回调。
					this.$wx.onMenuShareAppMessage({
						title: '现场急救行动指南', // 分享标题
						desc: '现场急救行动指南', // 分享描述
						link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
						type: 'link', // 分享类型,music、video或link，不填默认为link
						dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
						success: function () {
						// 用户点击了分享后执行的回调函数
						}
					});
					this.$wx.updateAppMessageShareData({
						title: '现场急救行动指南', // 分享标题
						desc: '现场急救行动指南', // 分享描述
						link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
						success: function(resq) {
							clearInterval(timeVal)
							// 设置成功
						},
						error: function(resw) {
							// alert('')
						}
					})
					// 分享朋友圈
					this.$wx.updateTimelineShareData({
						title: '现场急救行动指南', // 分享标题
						link:window.location.href , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
						success: function() {
							// 设置成功
						}
					});
					});
		// 		    }
		// 		  });
		// },1000)
		
		}
  }
}
</script>
<style lang="scss" type="text/css">
	.lyc_details {
		min-height: 100vh;
		background-color: #fff;
		padding: 0 0 46px;
		video{
			width:100%;
		}
		.input {
			width: calc(100% - 77px);
			min-height: 33px;
			line-height: 33px;
			background-color: #F4F4F4;
			option: none;
			border-radius: 6px;
			padding:0 10px;
			transform: all 1s;
				
		}
		.viewArticlesTop {
			width: 100%;
			text-align: left;
			img{
				max-width: 100%;
			}
			.viewArticlesTop_flex {

				justify-content: space-between;
				padding: 20px 15px 24px;
				border-radius: 6px;

				.time {
					color: #919CB0;
					font-size: 10px;
					font-weight: 100;
					display: block;
				}
			}
		}
		.top{
			margin-top: 20px;
			padding-bottom: 12px;
			justify-content: space-between;
			padding-top: 20px;
			border-top: 1px solid rgba(229,229,229,1);
		}
		.ylcBox {
			width: 100%;
			height: 211px;
			position: relative;
			overflow: hidden;
		
			.top {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 5;
		
			}
		
			.bot {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 6;
				font-size: 12px;
		
			}
		
			.bg {
				// width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}
		
			.flex {
				position: absolute;
		
				z-index: 6;
				color: #fff;
				justify-content: space-between;
				width: 100%;
				padding: 10px 15px;
			}
		
			.conter {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 50px;
				height: 50px;
			}
		
			.time {
				background-color: rgba(0, 0, 0, .5);
				border-radius: 50px;
				padding: 1px 7px;
				transform: scale(.9);
			}
		}
		.number_0Box{
			font-size:12px;
			font-family:PingFang SC;
			font-weight:500;
			color:rgba(145,156,176,1);
			margin-top: 19px;
		}
		.btn {
			padding: 5px 8.5px;
			background-color: #0081FF;
			border-radius: 50px;
			color: #fff;
			font-size: 12px;
			border: 1px solid transparent;
		}
		.btn1 {
			padding: 5px 8.5px;
			background-color: transparent;
			border-radius: 50px;
			color: #0081FF;
			font-size: 12px;
			border: 1px solid #0081FF;
		}

		.outerChain {}

		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}

		.van-tab {
			min-width: 85px;
		}

		.van-tab--active {
			font-size: 17px;
		}

		.topBox {
			padding: 15px;
			background-color: #fff;

		}

		.bottomBox {
			padding: 6.5px 15px;

			img {
				width: 20px;
				height: 20px;
			}

			input {
				width: 50%;
				height: 33px;
				line-height: 33px;
				background-color: #F4F4F4;
				option: none;
				border-radius: 6px;
				padding-left: 12px;
				transform: all 1s;

			}

			.follow {
				position: absolute;
				top: -7px;
				left: 100%;
				font-size: 10px;
				color: #919CB0;
			}
		}

		.article {
			width: 100%;
			background-color: #F4F4F4;
			border-radius: 2px;
			text-align: left;
			padding: 5px;
			margin: 20px 0;
		}

		.ifameClass {
			text-align: left;
			padding: 0 15px;
			height: calc(100% - 20px - 16px - 8.5px);
			overflow: auto;
		}
		.filter{
			width: 100%;
			height: 100%;
			filter: blur(3px);
			background-position: -50px;
			position: absolute;
			top:0 ;
			left: 0;
			
		}
	}
</style>
