<template>
	<div class="toUpdateDel">
		<div v-html="Delarr.posts" class="posts">
			
		</div>
	</div>
</template>

<script>
import {getKnowledgeDetail,knowledgeIsView} from '@/api'
	export default {
  name: 'toUpdateDel',
  components: {
  },
  data(){
	  return{
		 Delarr:{}
	  }
  },
  methods:{
	 
  },
  mounted() {
  	document.title = '知识更新';  
	 getKnowledgeDetail({kid:this.$route.query.tId}).then(res=>{
		 this.Delarr=res.data
		 console.log(this.Delarr)
		 knowledgeIsView({kid:this.$route.query.tId})
	 })
	
  }
}
</script>
<style lang="scss" type="text/css">
	.posts{
		width: 100%;
	}
	.toUpdateDel {
		min-height: 100vh;
		background-color: #fff;
		padding: 0 15px;
		text-align: left;
		max-width: 100%;
		 word-break:break-word;
		 .posts{
			 max-width: 100%;
		 }
		.posts strong img{
			width: 100% ;
		}
		.listBox {
			padding: 16px 0;
			border-bottom: 1px solid rgba(229, 229, 229, 1);

			.one {
				margin-bottom: 11.5px;
			}
			.tow{
				font-size: 12px;
			}

			.yuan {
				margin-right: 12.5px;
				position: relative;
				top: 5px;
				width: 8px;
				height: 8px;
				background-color: rgba(255, 82, 77, 1);
				border-radius: 50%;
			}
			.time{
				font-size: 12px;
				letter-spacing: 0;
			}
			.huiBg{
			background-color: #C6C8CA;
			}
		}
		img{
			width: 100% !important;
		}
	}
</style>
