<template>
	<div class="FirstAidVideo_list">
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
				<div class="" v-for="(item,i) in list" v-if="list.length" :key="i" @click="$router.push({name:'FirstAidVideo_details',query:{id:item.id}})">
					<div class="ylcBox">
						<div class="flex"   ><span v-text="item.title" style="font-weight: bold;color: #000;"></span></div>
						<div style="position: relative;height: 100%;overflow: hidden;" >
							<div class="filter" :style="'background-image: url('+ item.coverpic +');'">
							</div>
							<img :src="item.coverpic" alt="" class="bg">
						
					
						<img src="../../assets/user/bofan.png" alt="" class="conter">
						</div>
					</div>
					<div class="pingBox flex" style="justify-content: space-between;padding: 10px 15px;" v-if="item.user_id>0">
						<div class="flex">
							<div class="head" style="margin-right: 8px;">
								<img :src="item.userinfo.user_head_img" alt="" class="auto-img">
							</div>
							<div>
								<span v-text="item.userinfo.user_name"></span>
							</div>
						</div>
						<div class="flex" style="color: #919CB0;letter-spacing:0px;font-size: 12px;">
							<div class="flex" style="margin-right: 12px;" @click.stop="JjpostVideoPraise(item.id,item.is_praise?2:1,i)">
								<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="!item.is_praise">
								<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="item.is_praise">
								<span v-text="item.praise_num"></span>
							</div>
							<div class="flex" style="margin-right: 12px;">
								<img src="../../assets/bigSink/icon_gkrs@2x.png" alt="" style="width: 20px;margin-right: 5px;">

								<span v-text="item.reply_num"></span>
							</div>
						</div>
					</div>
					<div class="pingBox flex" style="justify-content: space-between;padding: 10px 15px;" v-if="item.user_id==0">
						<div class="flex">
							<div class="head" style="margin-right: 8px;">
								<img src="../../assets/user/icon_gflogo.png" alt="" class="auto-img">
							</div>
							<div>
								<span>现场急救科普工作室</span>
							</div>
						</div>
						<div class="flex" style="color: #919CB0;letter-spacing:0px;font-size: 12px;">
							<div class="flex" style="margin-right: 12px;" @click.stop="JjpostVideoPraise(item.id,item.is_praise?2:1,i)">
								<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="!item.is_praise">
								<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="item.is_praise">
								<span v-text="item.praise_num"></span>
							</div>
							<div class="flex" style="margin-right: 12px;">
								<img src="../../assets/bigSink/icon_gkrs@2x.png" alt="" style="width: 20px;margin-right: 5px;">
					
								<span v-text="item.view_num"></span>
							</div>
						</div>
					</div>
					<div style="width: 100%;background-color: #F7F8FF;height: 10px;"></div>
				</div>
			</van-list>
		</van-pull-refresh>
		<div v-if="!list.length" style="padding: 0 41px;">

			<img src="../../assets/bigSink/pic_nodota@2x.png" alt="" style="margin-top: 110px;width: 150px;margin-bottom: 40px;">
			<div style="text-align: center;font-size: 13px;">
				空空如也~
			</div>
		</div>
		<div class="flex bottom" style="justify-content: space-between;padding: 10px 15px;			z-index: 6;">
			<div class="flex" style="position: relative;" @click="isseach=!isseach">
				<!-- <div class="seach" :class="isseach?'isseach':'isseach1'">
					<div style="border-bottom: 1px solid #E5E5E5;" @click="seachText='time'">时间</div>
					<div  @click="seachText='hot'">热度</div>
				</div>
				<img src="../../assets/bigSink/icon_paixu@2x.png" alt="" style="width: 16px;margin:0 8.5px;">
				<div style="color: #0081FF;" v-text="seachText=='time'?'时间':'热度'"></div> -->
				<div style="color: #0081FF;" @click="$router.push({name:'FirstAidVideo_release',query:{active:1}})">我的视频</div>
			</div>
			<div class="btn1" @click="$router.push({name:'FirstAidVideo_release',query:{active:0}})">发布</div>
		</div>
	</div>
</template>

<script>
import {JjgetVideoList,JjpostVideoPraise} from '@/api'
	import { setMinute } from '@/util/setTime.js'
	export default {
  name: 'FirstAidVideo_list',
  components: {
  },
  data(){
	  return{
		  // 搜索
		  seachText:'time',
		  // 下拉刷新
		   refreshing: false,
		   // 加载状态
		   loading: false,
		   finished: false,
		   page:1,
		   limit:10,
		   count:0,
		  isseach:false,
		list:[
			
		]
	  }
  },
  watch:{
	  seachText(){
		  this.onRefresh()
	  }
  },
  methods:{
	  // 视频点赞
	  JjpostVideoPraise(id,type,i){
	  		  JjpostVideoPraise({video_id:id,type:type}).then(res=>{
	  			   if(res.code==1){
					 if(type==2){
						 this.list[i].is_praise=0
						 this.list[i].praise_num--
					 }else{
						 this.list[i].is_praise=1
						  this.list[i].praise_num++
					 }
	  			   }
	  		  })
	  },
	  setMinute(time){
		  return setMinute(time)
	  },
	 onRefresh(seachText) {
	      // 清空列表数据
	 			this.list=[]
	 			this.page=1
	      this.finished = false;
	      // 重新加载数据
	      // 将 loading 设置为 true，表示处于加载状态
	      this.loading = true;
	      this.onLoad();
	    },
	 onLoad() {
	      // 异步更新数据
	       JjgetVideoList({page:this.page,limit:this.limit,order:this.seachText}).then(res=>{
			   
	 				 this.list.push(...res.data.list);
	 				 this.page++
	 				 this.count=res.data.count
	 				  // 加载状态结束
	 				  this.loading = false;
	 				  this.refreshing=false
	 				console.log(this.loading)
	 				  // 数据全部加载完成
	 				  if (this.list.length >=  this.count) {
	 					this.finished = true;
	 				  }
	 			    })
	    },
  },
  mounted() {
  	document.title = '急救影院';   
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.FirstAidVideo_list {
		min-height: 100vh;
		background-color: #fff;
		padding-bottom: 70px;

		.ylcBox {
			width: 100%;
			position: relative;
			overflow: hidden;

			.top {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 5;

			}

			.bot {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 6;
				font-size: 12px;

			}

			.bg {
				// width: 100%;
				height: 231px;
				display: flex;
				margin: auto;
				z-index: 2;
				    position: relative;
				// position: absolute;
				// top: 0;
				// left: 50%;
				// transform: translateX(-50%);
			
			}

			.flex {
				position: relative;

				z-index: 6;
				color: #fff;
				justify-content: space-between;
				width: 100%;
				padding: 10px 15px;
			}

			.conter {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 50px;
				height: 50px;
				z-index: 3;
			}

			.time {
				background-color: rgba(0, 0, 0, .5);
				border-radius: 50px;
				padding: 1px 7px;
				transform: scale(.9);
			}
		}

		.pingBox {
			.head {
				border-radius: 50%;
				width: 2.0625rem;
				height: 2.0625rem;

				img {
					border-radius: 50%;
				}
			}
		}

		.bottom {
			height: 50px;
			box-shadow: 1px 5px 14px 1px rgba(35, 35, 35, 0.6);

			.btn1 {
				width: 63px;
				height: 30px;
				line-height: 30px;
				background-color: #0081FF;
				color: #fff;
				border-radius: 50px;

			}
		}

		.seach {
			position: absolute;
			top: -95px;
			left: -15px;
			padding: 0 10px;
			min-width: 100px;
			min-height: 70px;
			background-color: #F1F1F1;
			transition: all .5s;
			z-index: 5;
			color: #919CB0;

			div {
				padding: 10px 0;
				background-color: #F1F1F1;

			}
		}

		.isseach {
			visibility: visible;
			opacity: 1;
			top: -95px;
		}

		.isseach1 {
			visibility: hidden;
			opacity: 0;
			top: 95px;
		}
		.filter{
			width: 100%;
			height: 231px;
			filter: blur(3px);
			background-position: -50px;
			position: absolute;
			top:0 ;
			left: 0;
			overflow: hidden;
			
		}
	}
</style>
