<template>
  <div class="map-warper">
	 
    <iframe id="iframe" width="100%" height="100%" frameborder="0" scrolling="no"
      src="//apis.map.qq.com/tools/locpicker?total=0&amp;search=1&amp;type=1&amp;mapdraggable=1&amp;key=TO2BZ-EXTYI-47RG5-5QFZM-X6W6O-JBBYG&amp;referer=xcfirstaid">
    </iframe>
	<div class="bottom flex" >
		<div class="btn1" style="width: 45%;" @click="colse">
				取消
			</div>
			<div class="btn1" style="width: 45%;" @click="postAddrDate">
				确定
			</div>
	</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
		mapData:{}
    }
  },
  mounted () {
	let _that = this
    window.addEventListener('message', function (event) {
    	// 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
    	let loc = event.data
    	if (loc && loc.module === 'locationPicker') { // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
    	  // _that.postAddrDate(loc)
		  _that.mapData=event.data
    	}
    }, false)
  },
  methods: {
	  colse(){
		  this.$emit('colse')
	  },
    onChoiceAddr () {
		let _that = this
		var OnMessage = function (e) {
           console.log(e.data);
        }
        
        if (window.addEventListener) {  // all browsers except IE before version 9
			
            window.addEventListener("message", OnMessage, false);
        } else {
            if (window.attachEvent) {   // IE before version 9
            window.attachEvent("onmessage", OnMessage);
            }
        }
		// window.addEventListener('message', function (event) {
		// 	// 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
		// 	let loc = event.data
		// 	_that.mapData=event.data
		// 	console.log(_that.mapData)
		// 	if (loc && loc.module === 'locationPicker') { // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
		// 	  // _that.postAddrDate(loc)
		// 	}
		// }, false)
    },
    postAddrDate () {
			// window.postMessage(msg, domain);
      this.$emit('postAddrDate', this.mapData)
    }
  }
}
</script>

<style lang="scss">
.map-warper {
  height: 100%;
  .bottom{
	  justify-content: space-around;
  }
  .btn1{
	  height: 42px;
  	background:rgba(0,129,255,1);
  	border-radius:42px;
	line-height: 42px;
	color: #fff;
	
  }
}


</style>

