<template>
	<div class="test">
		<div class="bigBox">
			<div class="after">
				<span style="font-weight: bold;">剩余时间：</span>
				<span>{{num(minutes)}}:{{num(seconds)}}</span>
				<!-- <span style="color: #FF524D;"> </span> -->
			</div>
			
			<div class="hui" style="font-weight: bold;">
				<span class="blue" v-text="isIndex+1"></span>/<span v-text="count"></span>
			</div>
			<div style="font-weight: bold;margin: 28px 0;">
			<span v-if="bigList[isIndex].is_select1==1">【多选题】</span>	<span v-text="isIndex+1"></span>、<span v-text="bigList[isIndex].title"></span>			
			</div>
			<div v-if="bigList[isIndex].is_select==0">
				<div class="testBox" :class="item.clickTrue==0?'':item.clickTrue==1?'accontendui':item.clickTrue==10?'accontendui_1':'accontencuo'"
				 v-for="(item,i) in bigList[isIndex].contentList" @click="isClick(i,item.text)" :key="i">
					<!-- <div class="title"></div> -->
					<div class="conten"><span v-text="(i==0?'A、':i==1?'B、':i==2?'C、':'D、')+ item.text"></span></div>
				</div>
			</div>
			<div v-if="bigList[isIndex].is_select==1">
				<div class="testBox" :class="item.clickTrue==0?'':item.clickTrue==1?'accontendui':item.clickTrue==10?'accontendui_1':'accontencuo'"
				 v-for="(item,i) in bigList[isIndex].contentList" @click="selectClick(i,item.text)" :key="i">
					<!-- <div class="title"></div> -->
					<div class="conten"><span v-text="(i==0?'A、':i==1?'B、':i==2?'C、':'D、')+ item.text"></span></div>
				</div>
			</div>
			<div class="flex" style="justify-content: space-around;margin-top: 20px;" v-if="bigList[isIndex].is_select==0&&choiceList[isIndex]">
				<div class="btn" @click="next" v-if="isIndex>0">上一题</div>
				<div class="btn" style="color: #fff;background-color: rgba(0, 129, 255, 1);" @click="poxt" v-if="isIndex!==bigList.length-1">下一题</div>
				<div class="btn" style="color: #fff;background-color: rgba(0, 129, 255, 1);" @click="submission" v-if="isIndex==bigList.length-1">提交</div>
			</div>
			<div class="flex" style="justify-content: space-around;margin-top: 20px;" v-if="bigList[isIndex].is_select==1">
				<div class="btn" style="color: #fff;background-color: rgba(0, 129, 255, 1);" @click="selectSubmission">确定</div>
			</div>
		</div>
		<van-overlay :show="popupBox_guo" @click="popupBox_guo = false" />
		<transition name="van-fade">
			<div class="popupBox_guo" v-show="popupBox_guo">
				<div class="popupBox_guo_number"><span v-text="totalScore"></span></div>
				<div class="qualified"><span style="margin-right: 20px;">总共{{count}}题</span><span style="margin-right: 10px;">答对{{qualified}}题</span><span>答错{{count-qualified}}题</span></div>
				<div class="popupBox_guo_ntn" @click="good">我知道了</div>
			</div>
		</transition>
		<van-popup v-model="popupBox_gua" :round="true">
			<div class="popupBox">
				<div class="title">很抱歉</div>
				<div class="conten">本次答题成绩：<span v-text="totalScore"></span>分，您没有通过{{$route.query.title}}篇章考试，需重新学习答题</div>
				<div class="flex btnBox">
					<div class="suss" @click="good">好的</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import { getOnlineCourseQuestionsList,postOnlineCourseRecord } from '@/api'
	import { Toast } from 'vant';
	export default {
  name: 'test',
  components: {
  },
  data(){
	  return{
		// 及格弹出
		popupBox_guo:false,
		// 不及格弹出
		popupBox_gua:false,
		isIndex:0,
		// 分钟
		minutes:3,
		// 秒
		seconds:60,
		// 选择的数组
		choiceList:[],
		isdui:0,
		bigList:[{
		is_select:0,
		}
		],
		totalScore:0,
		selectList:[],
		count:0,
		qualified:0
	  }
  },
  watch: {
	  
	second: {
	  handler (newVal) {
		this.num(newVal)
		
	  }
	},
	minute: {
	  handler (newVal) {
		this.num(newVal)
	  }
	},
	},
  methods:{
	  // 考试不及格点击
	  good(){
		  this.popupBox_gua=false
		  this.popupBox_guo=false
		  this.$router.back(-1)
	  },
	  // 提交
	  submission(){
		  console.log(this.choiceList.length)
		  if(this.choiceList.length==this.count){
			  
			  let list = 0
			  this.choiceList.forEach((item,index)=>{
				  if(item==1){
					  list++
				  }
			  })
			  this.totalScore=parseInt((list/this.bigList.length)*100)
			  console.log(list)
			  this.qualified=list
			  postOnlineCourseRecord({chapter_id:this.$route.query.id,score:this.totalScore,answer_right:list}).then(res=>{
				  if (res.msg=='通过') {
					this.popupBox_guo=true
				  } else if(res.msg=="未通过"){
					this.popupBox_gua=true
				  }else{
					  Toast(res.msg)
				  }
			  })
		  }else{
			 alert('请认真完成每一道题再提交') 
		  }
		  
	  },
	  // 上一题
	  next(){
		  this.isIndex--
	  },
	  // 下一题
	  poxt(){
		   this.isIndex++
	  },
	  // 多选确认
	  selectSubmission(){
		  
		  let arr=[]
		  let selectArr=[]
		   this.bigList[this.isIndex].contentList.forEach((item,index)=>{
			   if(item.istrue==1){
				selectArr.push(item.istrue)
			   }
			   if(item.clickTrue==10&&item.istrue==2){
				   item.clickTrue=2
				    // console.log(item.istrue)
			   }else if(item.clickTrue==0&&item.istrue==1){
				   item.clickTrue=10
				   
			   }else if(item.clickTrue==10&&item.istrue==1){
				    item.clickTrue=1
					arr.push(item.clickTrue)
			   }
			   if(index==this.bigList[this.isIndex].contentList.length-1){
				     this.bigList[this.isIndex].is_select=0
				   if(selectArr.length==arr.length){
						this.choiceList.push(1)
				   }else{
					    this.choiceList.push(2)
					 
				   }
			   }
		   })
	  },
	  // 多选
	  selectClick(i,text){
		  let isover=true
		  let list = this.bigList[this.isIndex].contentList
		  console.log(list)
		  list.forEach((val,index)=>{
		  		if(index==i&&val.clickTrue==10){
		  			val.clickTrue=0
		  		}else if(index==i&&val.clickTrue==0){
					val.clickTrue=10
				}
		  })
	  },
	  isClick(i,text){
		  /**
		   * 拿到点击的数组，遍历后错误的正确标识出来，
		   * */
			let isover=true
			let list = this.bigList[this.isIndex].contentList
			list.forEach((val,index)=>{
				if(val.clickTrue){
					 Toast('已经选过了')
					 isover=false
					 return
				}else{
					if(val.istrue==1){
						
						val.clickTrue=10
					}
				}

			})
		  if(isover){
			 this.bigList[this.isIndex].contentList[i].clickTrue=this.bigList[this.isIndex].contentList[i].istrue
			 this.choiceList.push( this.bigList[this.isIndex].contentList[i].clickTrue)
		
		}
	  },
	 // 倒计时
	       num(n) {
	         return n < 10 ? '0' + n : '' + n
	       },
	        timer () {
	         var _this = this
	         var time = window.setInterval(function () {
	           if (_this.seconds === 0 && _this.minutes !== 0) {
	             _this.seconds = 59
				 
	             _this.minutes -= 1
	           } else if (_this.minutes === 0 && _this.seconds === 0) {
	             _this.seconds = 0
				 // _this.submission()
	             window.clearInterval(time)
	           } else {
	             _this.seconds -= 1
	           }
	         }, 1000)
	       },
		   onBridgeReady(){
		   	WeixinJSBridge.call('hideOptionMenu');
		   },
		   WeixinJSBridgeCode(){
		   	let _this=this
		   	if (typeof WeixinJSBridge == "undefined") {
		   		if (document.addEventListener) {
		   			document.addEventListener('WeixinJSBridgeReady', _this.onBridgeReady(), false);
		   		} else if (document.attachEvent) {
		   			document.attachEvent('WeixinJSBridgeReady', _this.onBridgeReady());
		   			document.attachEvent('onWeixinJSBridgeReady', _this.onBridgeReady());
		   		}
		   	} else {
		   		_this.onBridgeReady();
		   	}
		   }
  },
  
  mounted() {
  	document.title = '线上课堂';   
	this.timer()
	let arr=[]
	getOnlineCourseQuestionsList({chapter_id:this.$route.query.id}).then(res=>{
		console.log(res)
		
		this.minutes=res.data.count*2
		this.count=res.data.count
		this.seconds=0
		this.bigList=res.data.list
		this.bigList.forEach((item,index)=>{
			arr=[]
			for (let var1 in item.contentList) {
				arr.push(item.contentList[var1])
			}
			item.contentList=arr
			item.is_select1=item.is_select
		})
		console.log(this.bigList)
	})
	this.WeixinJSBridgeCode()
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.test {
		min-height: 100vh;
		width: 100%;
		background-image: linear-gradient(to bottom right, #569FFF, #8A7CFF);
		padding: 57px 15px 50px;

		.van-popup--center.van-popup--round {
			border-radius: 6px;
		}

		.popupBox_guo {
			width: 21.875rem;
			height: 21.875rem;
			background: url(../../assets/pic_tgcs@2x.png) no-repeat;
			background-size: 100% 100%;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -65%);
			z-index: 11;
			.qualified{
				width: 100%;
				color: #fff;
				position: absolute;
				bottom: 60px;
				left: 50%;
				transform: translateX(-50%);
				font-size: 15px;
				color: #FFFFFF;
			}
			.popupBox_guo_number {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -70%);
				font-size: 2.5rem;
				color: #FFFFFF;

			}

			.popupBox_guo_ntn {
				background-color: #FFBC00;
				width: 8.75rem;
				height: 2.5625rem;
				border-radius: 50px;
				text-align: center;
				line-height: 2.5625rem;
				color: #fff;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.popupBox {
			width: 300px;
			height: 200px;
			background-color: #fff;
			border-radius: 6px;
			padding: 0 20px 28px;

			.title {
				padding: 25px 0;
				font-size: 18px;
				font-weight: bold;
			}

			.conten {
				margin-bottom: 30px;
				text-align: justify;

			}

			.btnBox {
				justify-content: flex-end;

				div:first-child {
					// margin-right: 40px;
				}

				.suss {
					color: #0081FF;
				}
			}
		}

		.bigBox {
			width: 100%;
			height: 100%;
			min-height: calc(100vh - 107px);
			background-color: #fff;
			border-radius: 6px;
			position: relative;
			padding: 20px 15px;
			text-align: left;

			.after {
				position: absolute;
				top: -30px;
				right: 20px;
				width: 160px;
				height: 30px;
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
				background-color: #E1EFF8;
				line-height: 30px;
				text-align: center;

			}

			.testBox {
				background-color: #F7F8FF;
				// height: 60px;
				padding: 11px 40px 11px 12px;
				border-radius: 6px;
				display: flex;
				min-height: 60px;
				margin-bottom: 12px;
				align-items: center;
				text-align: justify;
				border: 1.5px solid transparent;

				.title {
					display: flex;
					align-items: center;

				}

				.conten {
					// display: flex;
					// align-items: center;
					text-indent: -13px;
					margin-left: 13px;
					font-size: 14px;
				}

			}

			.accontendui {
				background-color: rgba(0, 217, 126, .1);
				color: #00D97E;
				position: relative;

				&::after {
					content: '';
					display: block;
					width: 1.0625rem;
					height: 11.5px;
					position: absolute;
					top: 50%;
					right: 8px;
					transform: translateY(-50%);
					background-color: red;
					background: url(../../assets/bigSink/icon_dui@2x.png) no-repeat;
					background-size: 100% 100%;
				}
			}

			.accontencuo {
				background-color: rgba(255, 82, 77, .1);
				color: rgba(255, 82, 77, 1);
				position: relative;

				&::after {
					content: '';
					display: block;
					width: 13.5px;
					height: 13.5px;
					position: absolute;
					top: 50%;
					right: 8px;
					transform: translateY(-50%);
					background-color: red;
					background: url(../../assets/bigSink/icon_cuo@2x.png) no-repeat;
					background-size: 100% 100%;
				}
			}

			.accontendui_1 {
				background: rgba(247, 248, 255, 1);
				border: 1.5px solid rgba(0, 217, 126, 1);
				border-radius: 6px;
			}
		}

		.btn {
			width: 140px;
			height: 42px;
			border-radius: 50px;
			border: 1px solid rgba(0, 129, 255, 1);
			color: rgba(0, 129, 255, 1);
			text-align: center;
			line-height: 40px;


		}
	}
</style>
