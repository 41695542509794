<template>
	<div class="setPasswrod">
		<!-- 重置密码 -->
		<div >
			<div class="box">
				<van-row>
				  <van-col span="6">手机号码</van-col>
				  <van-col span="18" style="position: relative;">
					  <input type="text" :disabled="Daojis!=60" name="newPhone" disabled class="inputBox" v-model="newPhone" :placeholder="usering.user_phone?'请输入手机号码':'未绑定手机号码'" style="caret-color:#0081FF;">
					 
					  <div class="abs abs1" @click="miBilecode1" v-if="newPhone.length==11">{{Daojis==60?'获取验证码':Daojis+'S后重新发送'}}</div>
					  <div class="abs abs1" style="color: #767E8D;border-color: #767E8D;" v-if="newPhone.length!=11">{{Daojis==60?'获取验证码':Daojis+'S后重新发送'}}</div>
				</van-col>
				</van-row>
				<div style="height: 28px;">
					
				</div>
				<van-row style="margin-bottom: 28px;">
				  <van-col span="6">验证码</van-col>
				  <van-col span="18"><input type="number" class="inputBox" v-model="code" placeholder="请输入验证码"></van-col>
				</van-row>
				
				<van-row style="margin-bottom: 28px;">
				  <van-col span="6">新密码</van-col>
				  <van-col span="18"><input type="password" class="inputBox" v-model="new_user_passwd1" placeholder="请输入密码（6-18）位英文或数字"></van-col>
				</van-row>
				<van-row style="margin-bottom: 28px;">
				  <van-col span="6">确认密码</van-col>
				  <van-col span="18"><input type="password" class="inputBox" v-model="new_user_passwd" placeholder="请再次输入密码"></van-col>
				</van-row>
			</div>
			<div class="btn" @click="complete">
				提交
			</div>
		</div>


	</div>
</template>

<script>
	import {
		user,
		postUserInfo,
		postSetUserPhone,
		getMobileCode,
		getProvinces,
		getCities,
		getAreas,
		postQualifications,
		postClientUsersAccountPaymentcode,
		postChangeClientUsersAccountPaymentcode 
	} from '@/api';
	import {
		Toast
	} from 'vant'

	export default {
		name: 'setPasswrod',
		components: {},
		data() {
			return {
				new_user_passwd1:'',
				new_user_passwd:'',
				reset:false,
				// 旧支付密码
				lowPassword: '',
				// 正确的验证码
				isCode: '1234',
				// 支付密码
				password: '',
				newPassword: '',
				// 输入状态
				isfocus: false,
				isfocusIndex: 0,
				// 列表
				loading: false,
				finished: false,
				// 验证码
				code: '',
				// 倒计时
				Daojis: 60,
				// 新手机号码
				newPhone: '',
				// 性别,图标
				activeIcon: require('../../assets/Train/icon_nan_pre@2x.png'),
				inactiveIcon: require('../../assets/Train/icon_nan_nor@2x.png'),
				activeIcon1: require('../../assets/Train/icon_nv_pre@2x.png'),
				inactiveIcon1: require('../../assets/Train/icon_nv_nor@2x.png'),
				// 个人资料
				usering: {},
				// 标题
				title: '设置支付密码',
				getProvinces: [],
				getgetCities: [],
				getAreas: [],
				// 控制城市列表数据
				list1: [],
				// 渲染城市列表
				list: [],
				// 省份提交
				provinceid: '',
				// 城市提交
				cityValue: '',
				iscity: 1,
				// 删除资质状态
				isdel: false,
				qualifications: [
					"国际创伤生命支持导师",
					"国际创伤生命支持导师2",
					"国际创伤生命支持导师3"
				]

			}
		},
		methods: {
			resetClick(){
				this.reset=true
				
			},
			// 修改密码
			repairComplete(){
				if (this.password !== this.newPassword) {
					Toast('密码不一致')
					return
				} else {
					postChangeClientUsersAccountPaymentcode({
						new_payment_code: this.newPassword,
						old_payment_code: this.lowPassword,
					}).then(res => {
						if (res.code == 1) {
							Toast('密码修改成功')
							this.$router.back(-1)
						} else {
							Toast(res.msg)
						}
					})
				
				}
			},
			// 完成
			complete() {
				let formatPhone=0
				if(this.newPhone.indexOf('*')==-1){
							  formatPhone=this.newPhone
				}else{
							  formatPhone = this.$overPhone(this.newPhone,this.usering.user_phone)
				} 
				if (this.new_user_passwd !== this.new_user_passwd) {
					Toast('密码不一致')
					return
				} else {
					postClientUsersAccountPaymentcode({
						mobile: formatPhone,
						code: this.code,
						payment_code: this.new_user_passwd
					}).then(res => {
						if (res.code == 1) {
							Toast('密码重置成功')
							this.$router.go(-2)
						} else {
							Toast(res.msg)
						}
					})

				}
			},

			miBilecode() {
				if (this.Daojis < 60) return
				this.dao()
				getMobileCode({
					user_phone: this.usering.user_phone
				}).then(res => {
					// console.log(res)
					Toast.success(res.msg);
				})
			},
			dao() {
				// this.Daojis = localStorage.getItem('Daojis')<60?localStorage.getItem('Daojis'):60
				// console.log(this.Daojis)
				let set = setInterval(() => {
					if (this.Daojis == 1) {
						this.Daojis = 60
						localStorage.setItem('Daojis', this.Daojis)
						clearInterval(set)
					} else {
						this.Daojis--
						localStorage.setItem('Daojis', this.Daojis)
					}
					// console.log(this.Daojis)
				}, 1000)
			},
			miBilecode1() {
				let formatPhone=0
				if(this.newPhone.indexOf('*')==-1){
					formatPhone=this.newPhone
				}else{
					formatPhone = this.$overPhone(this.newPhone,this.usering.user_phone)
				}
				
				if(this.Daojis<60)return
				// console.log(Daojis)
						 
				getMobileCode({user_phone:formatPhone}).then(res=>{
							  // console.log(res)
							  
							  if(res.code==1){
								   this.dao()
								   Toast.success(res.msg);
							  }else{
									Toast(res.msg);
							  }
				})
			}

		},
		mounted() {


			user().then(res => {
				this.usering = res.data
				this.qualifications = res.data.qualifications
				this.newPhone=this.$starPhone(res.data.user_phone)
				console.log(this.qualifications)
			})
			document.title ='重置支付密码';
		}
	}
</script>
<style lang="scss">
	.van-cell:not(:last-child)::after {
		left: 0;
		width: 95%;
		margin: 0 auto;
	}

	.van-field__control {
		color: #000;
	}

	.focus {
		.van-field__control {
			color: #28334A;
			font-weight: bold;
		}
	}

	.setPasswrod {
		width: 100%;
		height: 100vh;
		background-color: #F7F8FF;

		.btn {
			width: 280px;
			height: 42px;
			line-height: 42px;
			background: rgba(0, 129, 255, 1);
			border-radius: 42px;
			margin: 30px auto 0;
			text-align: center;
			color: #fff;
		}

		.box {
			padding: 2.625rem 0.9375rem;
			background-color: #fff;

			.van-col {
				min-height: 36px;
				line-height: 36px;
				white-space: nowrap;
			}

			.inputBox {
				background-color: #F4F4F4;
				color: '#000';
				padding: 0 13px;
				height: 36px;
				line-height: 36px;
				width: 100%;
				border-radius: 0.375rem;
			}

			.abs {
				position: absolute;
				top: 50%;
				right: 10px;
				border: 1px solid #767E8D;
				color: #767E8D;
				font-size: 12px;
				min-width: 5rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.5rem;
				transform: translateY(-50%);
				border-radius: 6px;
				padding: 0 5px;


			}

			.abs1 {
				border-color: #0081FF;
				color: #0081FF;
			}
		}

		.icon_del {
			position: absolute;
			left: -33px;
			top: 50%;
			transform: translateY(-50%);
		}

	}
</style>
