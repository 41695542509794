<template>
	<div class="answer">
		<div style="width: 100%;height:calc(100vh - 60px) ;overflow: auto;" class="forBox">
			<div style="margin-top: 15px;padding-top: 0;padding-bottom: 0;" class="for" >
				<div class="flex">
					<div style="font-weight: bold;font-size: 14px;">现场急救序章</div>

				</div>
				<div class="flex">
					<div class="btn" @click="$router.push({name:'chapter_xu',query:{url:1}})">
						观看视频
					</div>

				</div>
			</div>
			<div v-for="(items, i) in list" :key="i" style="" class="for" >
				
				<div class="title_new" style="font-weight: bold;" ref="title">
					<div class="xian" :style="i==0?'width:80%':''">
						
					</div>
					<span class="title_new_box"  id="title_new_box" v-text="i==0?'必修课程':i==1?'心肺复苏及自动体外除颤仪使用课程':i==2?'急症急救及创伤救护课程':''">
						</span>
				</div>
				<div v-for="(item,index) in items" :key="index" :style="index==items.length-1?'':'border-bottom: 10px solid #F7F8FF;' " class="for_b">
				<div class="flex" style="align-items: start;">
					<div v-text="item.chapter_name + ' ' + item.chapter_title" style="font-weight: bold;width: calc(100% - 100px);text-align: left;font-size: 14px"></div>
					<div style="font-size: 12px;    line-height: 20px;" class="dot">
						学习进度：<span v-text="item.learnedcount" style="color:  #0081FF;"></span>/<span v-text="item.coursecount"></span>
					</div>
				</div>
				<div class="flex">
					<div class="btn" @click="
			  $router.push({
			    name: 'chapter',
			    query: {
			      chapter_id: item.id,
			      chapter_name: item.chapter_name,
			      chapter_title: item.chapter_title,
				  user_id:user_id
			    }
			  })
			">
						观看视频
					</div>
					<div v-if="item.learnedcount == item.coursecount" :class="
			  item.buttonstatus == 2
			    ? 'btn'
			    : item.buttonstatus == 4
			    ? 'btndui'
			    : item.buttonstatus == 3
			    ? 'btnCuo'
			    : ''
			"
					 @click="jump(item)">
						<span v-text="
			    item.buttonstatus == 2 
			      ? '开始答题'
			      : item.buttonstatus == 4
			      ?( '合格：'+item.answer_right + '/' + item.answer_total)
			      : item.buttonstatus == 3
			      ? ('不合格：'+item.answer_right+'/'+item.answer_total)
			      : ''
			  "></span>
					</div>
					<div v-if="item.learnedcount != item.coursecount" class="btn1">
						<span>开始答题</span>
					</div>
				</div>
				</div>
				<!-- 申请按钮四个状态 -->
			<div :class="beOverdue1==0?'applyBtn':beOverdue1==1?'applyBtnAct':beOverdue1==2?'applyBtn':beOverdue1==3?'applyBtnOver':beOverdue1==4?'applyBtnOver':''" v-if="i==1" v-text="beOverdue1==0?'申请证书':beOverdue1==1?'申请证书':beOverdue1==2?'证书已过期，请重新学习并答题':beOverdue1==4?'已申请证书':''" @click="IncompleteClick(beOverdue1,1)"></div>
			<div :class="beOverdue2==0?'applyBtn':beOverdue2==1?'applyBtnAct':beOverdue2==2?'applyBtn':beOverdue2==3?'applyBtnOver':beOverdue2==4?'applyBtnOver':''" v-if="i==2" v-text="beOverdue2==0?'申请证书':beOverdue2==1?'申请证书':beOverdue2==2?'证书已过期，请重新学习并答题':beOverdue2==4?'已申请证书':''" @click="IncompleteClick(beOverdue2,2)"></div>
			<div class="applyBtnAct" @click="demonstration(beOverdue1,2)" v-if="i==1">线下课程操作考核示范视频</div>
			<div class="applyBtnAct" @click="demonstration(beOverdue1,3)" v-if="i==2">线下课程操作考核示范视频</div>
			</div>
			<div style="margin-top: 15px;padding-top: 0;padding-bottom: 0;" class="for">
				<div class="flex">
					<div style="font-weight: bold;font-size: 14px;">现场急救终章</div>
				</div>
				<div class="flex">
					<div class="btn" @click="$router.push({name:'chapter_xu',query:{url:2}})">
						观看视频
					</div> 
			
				</div>
			</div>
			 
		</div>
		
		<van-popup v-model="delShowDui" :style="{ width:'80%' , borderRadius:'6px'}">
			<div class="qualifiedBox" style="padding: 22px 20px;text-align: justify;">
				<div class="title" style="margin-bottom: 22px 0;text-align: center;"> 课程视频观看说明 </div>
				<span style="line-height: 24px;font-size: 14px;">您已成功激活线上课堂。线上课堂有两类课程:心肺复苏术及自动体外除颤仪使 用课程和急症急救及创伤救护课程，当 您观看完这两类课程中的某一类课程的教学视频，并回答完相应的习题，当您的答题正确率在80%以上时，可以获得心肺复苏术及自动体外除颤仪使用课程的《现场急救线上教学课程合格证》或急症急救及创伤救护课程的《现场急救线上教学课程合格证》，凭此证可以在2个月内申请线下教学课程。</span>
				<div class="qualifiedBtn">
					<div class="btns" style="" @click="delShowDui=false">确定</div>

				</div>
			</div>
		</van-popup>
		<van-popup v-model="beOverdue" :style="{ width:'80%' , borderRadius:'6px'}">
			<div class="qualifiedBox" style="padding: 22px 20px;text-align: justify;">
				<div class="title" style="margin-bottom: 22px 0;text-align: center;"> 过期通知 </div>
				<span style="line-height: 24px;">您的《现场急救线上教学课程合格证》已过期失效,可重新观看教学视频并答题，全部试题答题正确率达到80%以上可重新获得《现场急救线上教学课程合格证》</span>
				<div class="qualifiedBtn">
					<div class="btns" style="" @click="beOverdue=false">确定</div>

				</div>
			</div>
		</van-popup>
		<van-popup v-model="isQualified" round  position="bottom" :style="{ height: 'auto' }" closeable>
			<div class="qualifiedBox">
				<!-- 弹窗提示证书不齐全 -->
				<transition name="van-fade">
				  <div v-show="!Incomplete" class="vanFadeBox">您本人的证书信息不全</div>
				</transition>
				<div class="title"> 生成合格证 </div>
				<div v-if="!isgenerate">
					<div class="qualifiedtext">
						<span style="font-weight: bold;margin-top: 25px;font-size: 15px;">您已答题通过<span v-text="IncompleteIndex==1?'心肺复苏术及自动体外除颤仪使用课程':IncompleteIndex==2?'急症急救及创伤救护课程':''"></span>， 可获得《现场急救线上教学课程合格证》， 请核对您本人的证书信息</span>
					</div>
					<div class="qualifiedtextflex">
						<div class="qualifiedtextflex_text">本人证件相片：</div>
						<img :src="UserCertsInfo.headimgurl" alt="" style="width: 69px;height: 93px;">
					</div>
					<div class="flex" style="margin-top: 20px;">
						<div style="flex:1;text-align: left;">
							<span>真实姓名：</span><span v-text="UserCertsInfo.user_name"></span>
						</div>
						<div style="flex:1;text-align: left;">
							<span>性别：</span><span v-text="UserCertsInfo.sex==1?'男':UserCertsInfo.sex==2?'女':''"></span>
						</div>
					</div>

					<div class="qualifiedBtn">
						<div class="qualifiedBtn_btn qualifiedBtn_btn1" @click="$router.push({name:'UserCertificate'})">修改本人证书信息</div>
						<div class="qualifiedBtn_btn" v-if="Incomplete" @click="generate">生成合格证</div>
						<div class="qualifiedBtn_btn qualifiedBtn_btn2" v-if="!Incomplete">生成合格证</div>
					</div>
				</div>
				<div v-if="isgenerate">
					<div class="qualifiedtext">
						<span style="font-weight: bold;margin-top: 25px;font-size: 15px;">生成成功，您可以在【我的宝藏】<van-icon name="down" style="transform: rotate(-90deg) translateX(-2px);"/>【培训证书】页面查看完整的合格证</span>
					</div>
					<img :src="img" alt="" style="width: 100%;margin-top: 10px;">
					<div class="qualifiedBtn">
						<div class="qualifiedBtn_btn" style="width: 90%;" @click="$router.push({name:'certificate_train'})">查看证书</div>
					</div>
				</div>
			</div>
		</van-popup>


		<div class="shuo bottom" @click="delShowDui=true"><span>课程视频观看说明</span>
		<i data-v-4c60d7c5="" class="van-icon van-icon-arrow"
			style="position: absolute;top: 50%;transform: translateY(-50%);"></i>
		</div>
	</div>
</template>

<script>
	import { getOnlineCourseList, getOnlineCourseChapterList,getUserCertsInfo,postOnlineCourseCert,getActivityCanSignup ,getOnlineCourseRecordCanCert,getOnlineCourseChapterListNew} from "@/api";
		import { Toast } from 'vant';
export default {
  name: "answer",
  components: {},
  data() {
    return {
		// 急救创伤证是否过期
		/**
		 * 0是没有证书
		 * 1是证书可以申请
		 * 2是证书过期
		 * 3已申请证书证书
		 * */
		beOverdue2:0,
		// 心肺复苏证是否过期
		beOverdue1:0,
		
		// 生成证书显示是acd考核还是急救创伤考核下标
		IncompleteIndex:0,
		// 没有证书
		noCertificate:false,
		img:require('../../assets/pic_zsmoban@2x.png'),
		// 是否已经生成
		isgenerate:false,
		// 是否可以生成证书
		Incomplete:true,
		isQualified:false,
		beOverdue:false,
		user_id:0,
		delShowDui:false,
		UserCertsInfo:{},
		// 屏幕宽度
      list: [
        
      ]
    };
  },
  methods: {
	  // 跳转视频视频页面
	  demonstration(beOverdue,index){
	  		 this.$router.push({
				 name:'demonstration',
				 query:{ course_id:index}
				 
			 })
	  },
	  // 点击申请证书
	  IncompleteClick(beOverdue,index){
		  if(beOverdue==1){
			  this.IncompleteIndex=index
			  this.isQualified=true
		  }
	  },
	  generate(){
		  let a =localStorage.getItem('user_type')
		  let arr={
			  headimgurl:this.UserCertsInfo.headimgurl,
			  user_name:this.UserCertsInfo.user_name,
			  sex:this.UserCertsInfo.sex,
			  type:1,
			  chapter_group:this.IncompleteIndex
			  // certtype:a>1?2:1
		  }
		  postOnlineCourseCert(arr).then(res=>{
			  this.$Toast(res.msg)
			  if(res.code==1){
				  this.img=res.data.frontimg
				  this.isgenerate=!this.isgenerate
				  if(this.IncompleteIndex==1){
					  this.beOverdue1=4
				  }else if(this.IncompleteIndex==2){
					   this.beOverdue4=4
				  }
			  }
		  })
	  },
    jump(item) {
		this.$router.push({ name: "test", query: { id: item.id,title: item.chapter_title,user_id:localStorage.getItem('user_id')} });
    },
	onBridgeReady(){
		WeixinJSBridge.call('hideOptionMenu');
	},
	WeixinJSBridgeCode(){
		let _this=this
		if (typeof WeixinJSBridge == "undefined") {
			if (document.addEventListener) {
				document.addEventListener('WeixinJSBridgeReady', _this.onBridgeReady(), false);
			} else if (document.attachEvent) {
				document.attachEvent('WeixinJSBridgeReady', _this.onBridgeReady());
				document.attachEvent('onWeixinJSBridgeReady', _this.onBridgeReady());
			}
		} else {
			_this.onBridgeReady();
		}
	},
	
  },
  //离开当前页面后执行
  destroyed: function () {
      // this.stopTimer();
  },
  mounted() {
	this.deviceWidth = document.documentElement.clientWidth;
	/**
	 * 记录答题完成的时间并合格
	 * 记录第一次可以生成证书的时间
	 * 如果第二次打开页面。是两个月后，就清除答题记录和时间
	 * 只要答题时间还生效，不更新答题时间
	 * */
    document.title = "线上课堂";
	/**
	 * getActivityCanSignup
	 * 1有证书
	 * 2.没有证书
	 * 3.有证书已过期
	 * 4.有证书超两个月
	 * 5.平均分没有80分
	 * 
	 * */
	let _this=this
	/**
	 * getActivityCanSignup 返回不是1的情况下。就说明目前没有证书
	 * 直接调用是否可以发证的接口getOnlineCourseRecordCanCert。查看用户答题情况。如果是1就可以进行发证
	 * getActivityCanSignup 返回3的时候。是说明证书过期。
	 * 问：超过六个月会不会执行了直接调起发证弹窗？
	 * 	证书过期。答题数据清空 ， getOnlineCourseRecordCanCert返回的是不可发证。 解决问题
	 * 问：答题时间超过两个月。会不会执行了直接调起发证弹窗？
	 * 答：答题时间超过两个月。会在列表判断是否超过两个月。超过了就会清空答题数据
	 * 希望：这里把列表接口放在getActivityCanSignup，etOnlineCourseRecordCanCert这两个接口后面。
	 * 做到：避免答题数据没清空
	 * 
	 * */ 
	const times = Toast.loading({
				duration: 0,
			message: '加载中...',
			forbidClick: true,
		});
    getOnlineCourseChapterListNew().then(res => {
			if(res.code == 1) {
				times.clear()
			}
      _this.list = res.data;
	  
	  let arr = JSON.parse(localStorage.getItem('user_data'))
	  // if(arr.user_id==31||arr.user_id==320||arr.user_id==841){
		  // 判断心肺复苏
		  getActivityCanSignup({type:1}).then(ress=>{
		  	switch (ress.data.status){
		  		case 3:
		  			_this.beOverdue1=2
		  			break;
				case 1 :
					_this.beOverdue1=4
		  	}
			// 如果是没有证书的情况下就执行是否可以发证的接口
		  		if(ress.data.status==2||ress.data.status==3){
		  			getOnlineCourseRecordCanCert({type:1}).then(resa=>{
		  				 switch (resa.data.status){
		  				 	case 1:
		  						_this.beOverdue1=1
								console.log('==1')
		  				 		break;
		  				 	default:
		  				 		break;
		  				 }
		  			})
		  		}
		  		
		  })
		  
		 getActivityCanSignup({type:2}).then(ress=>{
		 	switch (ress.data.status){
		 		case 3:
		 			_this.beOverdue2=2
		 			break;
		 				case 1 :
		 					_this.beOverdue2=4
		 	}
		 			// 如果是没有证书的情况下就执行是否可以发证的接口
		 		if(ress.data.status==2 || ress.data.status==3){
		 			getOnlineCourseRecordCanCert({type:2}).then(resa=>{
		 				 switch (resa.data.status){
		 				 	case 1:
		 						_this.beOverdue2=1
		 								console.log('==1')
		 				 		break;
		 				 	default:
		 				 		break;
		 				 }
		 			})
		 		}
		 		
		 })
    });
	// 判断个人证书信息是否齐全
	getUserCertsInfo().then(res=>{
		this.UserCertsInfo=res.data
		if(!this.UserCertsInfo.headimgurl||!this.UserCertsInfo.user_name||!this.UserCertsInfo.sex){
			this.Incomplete=false
		}
		// for(var item in this.UserCertsInfo){
		// 	console.log(item)
		// 	if(!this.UserCertsInfo[item]){
		// 		this.Incomplete=false
		// 	}else{
				
		// 	}
		// }
	})
	
	this.user_id=localStorage.getItem('user_id')
	this.WeixinJSBridgeCode()
	
  }
};
</script>
<style lang="scss" type="text/css" scoped>
	.answer {
		height: calc(100vh - 80px);
		background-color: #f7f8ff;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding:0px 0px 0;
		// padding-bottom: 20px;
		box-sizing: border-box;
		font-size: 0.5rem;
		.van-popup--bottom.van-popup--round{
			    border-radius: 10px 10px 0 0;
		}
		.dot {
			position: relative;

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: -10px;
				transform: translateY(-50%);
				width: 5px;
				height: 5px;
				background-color: #0081ff;
				border-radius: 50%;
			}
		}

		.flex {
			justify-content: space-between;
			padding: 15px 0;
			// .btn{
			// 	line-height: 42px;
			// 	width: 100px;
			// 	height:42px;
			// 	background:rgba(255,255,255,1);
			// 	border:1px solid rgba(0,129,255,1);
			// 	// border-radius:22px;
			// }
		}

		.jihuoBtn {
			background-color: #c6c8ca;
			width: 70px;
			height: 40px;
			border-radius: 6px;
			color: #fff;
			text-align: center;
			line-height: 40px;
		}

		.ylcBox {
			width: 100%;
			height: 211px;
			position: relative;

			.top {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 5;
			}

			.bot {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 6;
				font-size: 12px;
			}

			.bg {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}

			.flex {
				position: absolute;

				z-index: 6;
				color: #fff;
				justify-content: space-between;
				width: 100%;
				padding: 10px 15px;
			}

			.conter {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 50px;
				height: 50px;
			}

			.time {
				background-color: rgba(0, 0, 0, 0.5);
				border-radius: 50px;
				padding: 1px 7px;
				transform: scale(0.9);
			}
		}

		.btn {
			border: 1px solid #0081ff;
			color: #0081ff;
			border-radius: 60px;
			width: 41%;
			font-size: 12px;
			height: 30px;
			line-height: 30px;
			text-align: center;
		}

		.btn1 {
			border: 1px solid #919cb0;
			color: #919cb0;
			border-radius: 60px;
			width: 41%;
			font-size: 12px;
			height: 30px;
			line-height: 30px;
			text-align: center;
		}

		.btndui {
			border: 1px solid #00d97e;
			color: #00d97e;
			border-radius: 60px;
			width: 41%;
			font-size: 12px;
			height: 30px;
			line-height: 30px;
			text-align: center;
		}

		.btnCuo {
			border: 1px solid #ff524d;
			color: #ff524d;
			border-radius: 60px;
			width: 41%;
			font-size: 12px;
			height: 30px;
			line-height: 30px;
			text-align: center;
		}

		.delBox {
			.van-popup {
				width: 300px;
				height: 190px;
				border-radius: 6px;
				padding: 0 1.25rem;
			}
		}

		.Bigbox {
			position: relative;
			width: 92%;
			height: 296px;
			margin: 100px auto 0;
			background-color: #fff;
			z-index: 3;
			border-radius: 6px;
			padding: 49px 15px 0;
			text-align: left;

			input {
				width: calc(100% - 24px);
			}

			.box {
				width: calc(100% - 15px - 70px);
			}
		}

		video {
			width: 100%;
		}

		.forBox {
			.for {
				background-color: #fff;
				border-radius: 6px;
				margin: 0 15px 15px;
				padding:15px;
				padding-bottom: 30px;
			}
			.for_b{
			}

			.for:last-child {
				margin-bottom: 0;
			}
		}

		.shuo {
			bottom: 0;
			position: fixed;
			height: 50px;
			text-align: center;
			left: 50%;
			transform: translateX(-50%);
			color: #0081FF;
			line-height: 50px;
			font-size: 12px;
			box-shadow:0px -3px 5px 0px rgba(145,156,176,0.1);
		}

		.delBox1 {
			.van-popup {
				width: 300px;
				border-radius: 6px;
				padding: 10px 1.25rem;
				letter-spacing: 1.5px;
				text-align: justify;
			}
		}

		.qualifiedBox {
			padding: 10px 15px;
			border-radius: 6px;
			font-size: 14px;
			.title {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 10px;
			}

			.qualifiedtext {
				text-align: justify;

				p {
					margin: 15px 0;
				}
			}

			.qualifiedtextflex_text {
				// width: calc(100% - 250px);
				text-align: left;
			}

			.qualifiedtextflex {
				display: flex;
				margin-top: 15px;

			}

			.qualifiedBtn {
				display: flex;
				justify-content: space-around;
				margin-top: 10px;


				.qualifiedBtn_btn {
					width: 45%;
					height: 42px;
					line-height: 42px;
					// padding:8px 15px;
					border-radius: 50px;
					background-color: #0081FF;
					color: #fff;
					margin: 15px 0;
				}
				.qualifiedBtn_btn1{
					background-color: #fff;
					border: 1px solid #0081FF;
					color: #0081FF;
				}
				.qualifiedBtn_btn2{
					background-color: #C6C8CA;
				}

				.btns {
					text-align: right;
					color: #0081FF;
					width: 95%;
					font-weight: bold;
					font-size: 15px;
					margin-top: 10px;
				}
			}
		}
		.vanFadeBox{
			width: 50%;
			height: 70px;
			line-height: 70px;
			text-align: center;
			background-color: rgba(0,0,0,.8);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50% , -50%);
			color: #fff;
			border-radius: 6px;
		}
		.xian{
			width: 100%;
			height: 1px;
			background-color:rgb(255, 165, 0);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
		.title_new{
			position: relative;
			
			.title_new_box{
				position: relative;	
				background-color: #fff;
				z-index: 2;
				margin: 0 auto;
				padding: 0 10px;
				font-size: 1rem;
				color:rgb(255, 165, 0);
				    vertical-align: middle;
			}
			
			@media screen and (max-width: 370px) {
			    .title_new_box {
			        font-size: .8rem;
			    }
			}
		}
		.applyBtnAct{
			width:89%;
			height:42px;
			font-size:15px;
			font-family:PingFang SC;
			font-weight:500;
			color:rgba(255,255,255,1);
			line-height: 42px;
			text-align: center;
			background:rgba(0,129,255,1);
			border-radius:40px;
			margin: 10px auto 0;
		}
		.applyBtn{
			width:89%;
			height:42px;
			font-size:15px;
			font-family:PingFang SC;
			font-weight:500;
			color:rgba(255,255,255,1);
			line-height: 42px;
			text-align: center;
			background:#C6C8CA;
			border-radius:40px;
			margin: 10px auto 0;
		}
		.applyBtnOver{
			width:89%;
			height:42px;
			font-size:15px;
			font-family:PingFang SC;
			font-weight:500;
			color:rgba(255,255,255,1);
			line-height: 42px;
			text-align: center;
			background:#00DA7E;
			border-radius:40px;
			margin: 10px auto 0;
		}
		
		        
		      
	}
</style>
