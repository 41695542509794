<template>
	<div class="cooperative">
		<defaultVue v-if="!list.length"
			:defaultImg="require('@/assets/default/icon_zanwuwdpx@2x.png')"
			defaultText="暂无合作机构"
		></defaultVue>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
				<div v-for="(item,index) in list" :key="index">
					<van-cell  @click="$router.push({name:'mechanismDetails',query:{id:item.id}})" >
						<div slot="default" class="flex list" >
							<div class="list_img" style="position: relative;">
								<van-image width="60" height="60" :src="item.logo_img" radius='8px' />
							</div>
							<div style="width: 100%;" class="list-text">
								<div v-text="item.name" class="list_title"></div>
								<div class="text" style="margin: 0;" >
									<span v-text="item.cooperation_date" v-if="item.cooperation_date"></span>
								</div>
								<div class="text" style="margin: 0;">
									<span v-text="item.cooperation_area" v-if="item.cooperation_area"></span>
								</div>
							</div>
						</div>
					</van-cell>
				</div>
			</van-list>
		</van-pull-refresh>

		<div class="bottom">
			<div class="bottom_input">
				<input v-model="keywords" placeholder="" style="background-color: transparent;"  @blur="getFocus" />
				<div class="bottom_input_imgdiv">
					<div style="position: relative;width: 28px;height: 28px;">
						<img src="../../assets/Train/icon_seach@2x.png" alt="" class="bottom_input_img" @click="focus">
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import defaultVue from '@/components/default/index.vue'
import { getInstitutionList } from '@/api';
export default {
  name: 'cooperativeList',
  components: {
	defaultVue
  },
  data(){
	  return{
			list:[],
			refreshing: false,
			loading: false,
			finished: false,
			page:0,
			limit:'8',
			title:'培训机构',
			keywords: '',
	  }
  },
  methods:{
	onRefresh() {
		this.finished = false;
		this.list=[]
		this.page=0
		this.loading = true;
		this.onLoad();
	},
	getFocus() {
		window.scroll(0, 0);
	},
	focus(){
		this.page=0
		this.list=[]
		this.onRefresh()
	},
	onLoad() {
		this.page++;
		getInstitutionList({page: this.page, limit: this.limit, keywords: this.keywords}).then(res => {
			if(res.code === 1) {
				if (this.refreshing) {
				  this.list = [];
				  this.refreshing = false;
				}
				this.list.push(...res.data.list)
				this.refreshing=false
				this.loading = false;
				if (res.data.list == 0) {
				 this.finished = true;
				}
			} else{
				this.list = [];
				this.finished = true;
			}
		})
	  }
  },
  
  mounted() {
  	document.title = this.title;
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.cooperative {
		width: 100%;
		min-height: 100vh;
		background: #F7F8FF;
		padding-top: 0px;
		padding-bottom: 82px;
		.van-cell__value{
			overflow: initial;
		}
		.ping{
			width: 4.375rem;
			height: 1.625rem;
			border: 1px solid #0081FF;
			border-radius: 20px;
			color: #0081FF;
			background-color: #fff;
			font-size: 0.75rem;
			line-height: 1.5rem;
			text-align: center;
			box-sizing: border-box;
			float: right;
		}
		.vanSwipe {
			width: 92%;
			margin: 0 auto 0.9375rem;
			height: 7.5rem;

			img {
				border-radius: 8px;
			}

		}
		.list-text {
			>div {
				height: 20px;
			}
		}
		.list_title {
			justify-content: space-between;

			// margin: 20px 0 20px;
			.list_title_left {
				width: 0.1875rem;
				height: 1.125rem;
				background: #0081FF;
				border-radius: 0 0.09375rem 0.09375rem 0;
				margin-right: 0.75rem;
			}

			.list_title_img {
				width: 0.4375rem;
				height: 0.6875rem;
				margin-right: 0.90625rem;
				margin-left: 0.28125rem;
			}

			.left {
				font-size: 1.125rem;
				color: #28334A;
				font-weight: bold;
			}

			.right {
				font-size: 0.9375rem;
				color: #0081FF;
			}
		}

		.list {
			flex-wrap: inherit;
		
			.list_img {
				margin-right: 0.71875rem;
				.icon{
					position: absolute;
					top: 10px;
					left: -3px;
					z-index: 2;
				}
				.Zjicon{
					position: absolute;
					top: 0px;
					right: 0px;
					z-index: 2;
				}
			}
		
			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 13px;
				color: #28334A;
			}
		
			.text {
				font-size: 0.75rem;
				color: #767e8d;
				margin-left: 0.5rem;
			}
		}
		.bottom {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			background: #fff;
			height: 3.75rem;
			padding: 12px 0;
			box-shadow:0px -6px 10px 0px rgba(145,156,176,0.1);

			.bottom_input {
				width: 95%;
				height: 2.25rem;
				margin: 0 auto;
				background: #F4F4F4;
				border-radius: 1.125rem;
				position: relative;
				text-align: left;
				line-height: 2.25rem;
				padding-left: 15px;
				.bottom_input_imgdiv{
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);
					width: 28px;
					height: 28px;
					
				}
				.bottom_input_img {
					width: 16px;
					height: 16px;
					position: absolute;
					right: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
				}
			}
		}
	}
</style>
