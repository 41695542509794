<template>
	<div class="texts">未报名成功或活动未审核前无法查看</div>
</template>

<script>
export default {

  
  mounted() {
  	document.title = '跳转提示'; 
	//防止页面后退
	history.pushState(null, null, document.URL);
	window.addEventListener('popstate', function () {
	history.pushState(null, null, document.URL);
	})
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.texts {
		text-align: left;
		padding: 5px;
	}
</style>
