<template>
  <div class="InTheAssessment">
	  <div class="InTheAssessmentBox">
	  <div class="name">参考教师：<span class="text" style="" v-text="$route.query.sign_name"></span></div>
	   <div class="name"> 考试时间：<span  class="text"  v-text="$route.query.time"></span></div>
	    <!-- <div class="name"  style="flex-wrap: wrap;display: flex;"><div>考核项目：</div><div  class="text"  style="width: calc(100% - 80px);" v-text="$route.query.course_name"></div></div> -->
		<div class="name flex"><div>题目：</div><van-field v-model="course_title_name"  placeholder="" class="inputFiled"/></div>
</div>
	
	<div class="subjectBox InTheAssessmentBox" v-for="(items,i) in projectArr" :key="i">
		<div v-for="(item,index) in items" :key="index">
			<p class="subject_content" v-text="item.content" v-if="index==0"></p>
			<p class="subject_title"><span v-text="index+1"></span>、<span v-text="item.project"></span></p>
			<p class="subject_content"></p>
			<van-radio-group v-model="item.isComplete" class="flex" style="margin-left: 15px;" @change="change">
			  <van-radio name="1" style="margin-right:62px;" >
				<template #default="props">
				  <span :style="item.isComplete&&item.isComplete==1? 'color:#0081FF' : 'color:rgba(145,156,176,1)'">未完成</span>
				</template>
			    <template #icon="props">
			      <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" width="20" height="20"/>
			    </template>
			  </van-radio>
			  <van-radio name="2" >
				<template #default="props">
				  <span :style="item.isComplete&&item.isComplete==2? 'color:#0081FF' : 'color:rgba(145,156,176,1)'">完成</span>
				</template>
			    <template #icon="props">
			      <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" width="20" height="20"/>
			    </template>
			  </van-radio>
			</van-radio-group>
			<p class="subject_content"></p>
		</div>
		
	</div>
	<div style="padding-top: 20px;">
		<div class="pj">导师综合评价</div>
		<van-field
			 
		  v-model="message"
		  rows="5"
		  autosize
		  type="textarea"
		  placeholder="说点什么吧"
		  style="border: 1px solid #ddd;margin: 12px 0;"
		/>
		<!-- <div class="" style="display: flex;">
			<div>
				<img src="../../assets/icon_tips_red@2x.png" alt="" style="width: 12px;height: 12px;margin-right: 10px;">
			</div>
			<div style="font-size: 12px;text-align: justify;width: calc(100% - 19px);">1、参赛教师自行制作PPT及相关教具；2、要求参赛教师站立 讲课；参赛教师完成度不足80%为不合格，完成80%为合格。</div>
		</div> -->
		<div class="bottom flex" >
			<div>
				学员完成项：<span class="blue" v-text="qualified">1</span>/<span v-text="total"></span>
			</div>
			<div class="btnti" v-if="total!==tcounts||!course_title_name">
				提交
			</div>
			<div class="btnti btntiBlue" @click="Submit"  v-else>
				提交
			</div>
		</div>
	</div>
	
  </div>
</template>
<script>
	import { getOfficialCourse,getOnlineCourseExamineTitle,postOnlineCourseExamineResult,postCourseExamineResult,getCourseExamineTitle,postCourseExamineResultTutor } from '@/api'
export default {
  name: 'supervisorTheAssessment',
  components: {
  },
  data(){
	  return{
		  tcounts:30,
		  message:'',
		  inactiveIcon: require('../../assets/Train/icon1_dxk_nor@2x.png'),
		  activeIcon: require('../../assets/Train/icon_selectbox_pre@2x.png'),
		  radio:'1',
		  course_title_name:'',
		  list:[
			  {
				  title:'单人成人心肺复苏操作',
				  type:1
			  },
			  {
				  title:'单人成人心肺复苏操作',
				  type:1
			  }
		  ],
		projectArr:[],
		total:0,
		qualified:0
	  }
  },
  methods:{
	  Submit(){	
		this.$Toast.loading({
		  message: '提交中...',
		  forbidClick: true,
					overlay:true,
					forbidClick:false,
		  loadingType: 'spinner',
					duration:0
		});
		  let arr = []
		  this.projectArr.forEach((items,i)=>{
			  items.forEach((item,index)=>{
				  if(item.isComplete){
				  				  arr.push({
				  					  project_id:item.id,
				  					  result:item.isComplete-1
				  				  })
				  }else{
				  				  arr=[]
				  				  return
				  }
			  })
			  
		  })
		  let teacher_id =JSON.parse(localStorage.getItem('user_data')).user_id;
		  let obj={
			  teacher_id :teacher_id,
			  // 老师id
			  user_id  :this.$route.query.sign_id,
			  // 学生id
			  complete_count:this.qualified,
			   // 完成题目数量
			  comment  :this.message,
			  // 综合评价
			  course_id  :this.$route.query.courseId,
			  // 课程ID
			  act_id :this.$route.query.act_id,
			  // JSON.stringify(arr)
			  exam_result :JSON.stringify(arr),
			  train_course_id :this.$route.query.train_course_id,
				beizhu  :'ss',
				course_title_name:this.course_title_name
			  // 活动ID
		  }
		  console.log(obj)
		  postCourseExamineResultTutor(obj).then(res=>{
			  if(res.code==1){
				  this.$Toast.clear()
				  this.$router.go(-1)
			  }
		  })
		  console.log(arr,obj)
		
	  },
	  change(name){
		  // 总答题数
		  let a = 0 
		  // 合格题数
		  let b = 0
		  
		  this.projectArr.forEach((items,i)=>{
			items.forEach((item,i)=>{
				if(item.isComplete){
								  a++
								  if(item.isComplete==2){
									  b++
								  }
				}
			})
			  
		  })
		  this.qualified=b
		  this.total=a
	  },
	 btnClick(){
		 document.title = '需要设置的值'; 
	 },
	getCourseExamineTitle(){
		let courseId = this.$route.query.courseId
		let train_course_id = this.$route.query.train_course_id
		getCourseExamineTitle({course_id:courseId,train_course_id:train_course_id}).then(res=>{
			if(res.code==1){
			res.data.list.forEach((item,i)=>{
				item.isComplete=''
			})
			this.projectArr=res.data.list.reverse()
			this.tcounts=res.data.tcounts
			
			}
		})
	}
  },
  mounted() {
  	document.title = '督导评分';  
	 console.log()
	this.getCourseExamineTitle()
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.InTheAssessment{
		text-align: left;
		padding: 15px;
		padding-bottom:60px ;
		background-color: #fff;
		.van-radio__label{
			color: #919CB0;
		}
		.bottom{
			height:50px;
			background:rgba(243,243,243,1);
			justify-content: space-between;
			padding: 0 15px;
			.btnti{
				width:70px;
				height:31px;
				background:rgba(197,199,201,1);
				border-radius:15px;
				line-height: 31px;
				text-align: center;
				font-size:15px;
				font-family:PingFang SC;
				font-weight:500;
				color:rgba(255,255,255,1);
			}
			.btntiBlue{
				color:#fff;
				background:#0081FF;
			}
		}
		.name{
			color: #919CB0;
			// padding: 16px;
			margin-bottom: 15px;
		}
		.InTheAssessmentBox{
			border-bottom: 1px solid #E5E5E5;padding-bottom: 20px;
			.text{
				color: #273349;font-weight: bold;
			}
			.inputFiled{
				height:36px;padding: 0;line-height: 34px;padding-left: 13px;width: calc(100% - 80px);float: right;
				background:rgba(255,255,255,1);
				border:1px solid rgba(203,203,203,1);
				border-radius:6px;
			}
			
		}
		img.img-icon{
			    vertical-align: initial !important;
		}
		
		.bar{
			width:100%;
			height:30px;
			background:linear-gradient(90deg,rgba(86,159,255,1) 0%,rgba(138,124,255,1) 100%);
			text-align: center;
			color:rgba(255,255,255,1);
			line-height: 30px;
		}
		.subjectBox{
			padding-top: 20px;
			.subject_title{
				font-size:15px;
				font-family:PingFang SC;
				font-weight:500;
				color:rgba(39,51,73,1);	
			}
			.subject_content{
				font-size:15px;
				font-family:PingFang SC;
				font-weight:bold;
				color:rgba(39,51,73,1);
				margin: 15px 0 20px;
			}
		}
		.pj{
			font-size:14px;
			font-family:PingFang SC;
			font-weight:500;
			color:rgba(145,155,175,1);
		}
	}
</style>
