<template>
	<div class="mentorList">
		<div class="vanSwipe">
			<van-swipe :autoplay="3000" style="height: 7.5rem;" :indicator-color="'#fff'">
				<van-swipe-item v-for="(image, index) in images" :key="index">
					<img v-lazy="image.pic" class="auto-img" @click="jump(image.url)"/>
					<!-- <div style="background-color: #0081FF;height: 100%;border-radius: 6px;"></div> -->
				</van-swipe-item>
			</van-swipe>
		</div>
		<div>
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list v-model="loading" :finished="finished" finished-text=" " @load="onLoad">
				<van-cell v-for="(item,index) in list" :key="index" @click="$router.push({path:'/tutorHomepage',query:{id:item.user_id}})">
					<div slot="default" class="flex list">
						<div class="list_img list_img_pai " :class="['list_img_'+(index+1)]">
							<van-image width="60" height="60" :src="item.user_head_img" radius='50%' />

						</div>
						<div>
							<div class="list_title">
								<span v-text="item.user_name" style="font-size: 15px;"></span>
								<span v-text="item.user_type == 1 ? '普通用户' : item.user_type == 2 ? '导师' : '主任导师'" class="ellipse identity"></span>
							</div>
							<div v-text="item.user_signature">

							</div>
							<div class="flex" style="    justify-content: space-between;">
								<!-- 标题下左右边两个div -->
								<div>

									<div class="flex" v-if="item.institution">
										<img src="../../assets/Train/icon_xjigou@2x.png" alt="" style="width: 12px;height: 12px;">
										<div v-text="item.institution?item.institution:'暂无'" class="text"></div>
									</div>
									<div class="flex">
										<div class="flex" style="margin-right: 1.875rem;">
											<img src="../../assets/Train/icon_xdizhi@2x.png" alt="" style="width: 12px;height: :;px;">
											<div v-text="item.city?item.city:'暂无'" class="text"></div>
										</div>
										<div class="flex">
											<img src="../../assets/Train/icon_guanzhu@2x.png" alt="" style="width: 12px;height: 12px;">
											<div v-text="item.fans_num+'人关注'" class="text"></div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</van-cell>
			</van-list>
			</van-pull-refresh>
		</div>
		<div class="bottom">
			<div class="bottom_input">
				<input v-model="value" placeholder="" style="background-color: transparent;"  @blur="getFocus" ></input>
				<div class="bottom_input_imgdiv">
					<div style="position: relative;width: 28px;height: 28px;">
						<img src="../../assets/Train/icon_seach@2x.png" alt="" class="bottom_input_img" @click="focus">
					</div>
				</div>
			</div>
		</div>

		<!-- 弹窗内容 -->
		<!-- <van-popup v-model="isAreaList" position="bottom">
			<div style="width: 100%;">
				<van-area :area-list="areaList" :columns-num="2" title="地址" @confirm="confirm" @cancel="cancel" />
			</div>
		</van-popup> -->
		<!-- <van-calendar v-model="isdata" @confirm="onConfirm" color="#0081FF" /> -->
		<!-- <van-calendar v-model="isdata_Section" type="range" @confirm="onConfirm_Section" color="#0081FF" /> -->
		<!-- <transition name="van-slide-up">
		  <div v-show="isAreaList" style="z-index: 3;position: fixed;bottom: 0;left: 0;width: 100%; ">
			 
		  </div>
		</transition> -->

	</div>
</template>

<script>
	import { getBanner,getTutorLists } from '@/api'
	import areaList from '../../area.js'
export default {
  name: 'mentorList',
  components: {
  },
  data(){
	  return{
		  
		 /*搜索的值*/
		 value:'',
		 
		// 控制搜索显示隐藏
		visible:false,
		title:'导师榜单',
		images: [
		  'https://img.yzcdn.cn/vant/apple-1.jpg',
		  'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		total_number:100,
		signUp_number:100,
		student_number:1000,
		/*list为放到页面渲染的，list1为请求的所有数据做懒加载处理*/
		list:[],
		/**
		 * identity:身份
		 * autograph：签名
		 * follow：关注
		 * name：姓名
		 * img：头像
		 * addres：地址
		 * company：公司
		 * 
		 * */
		list1: [	
		],
		loading: false,
		finished: false,
		count:1,
		page:1,
		limit:'8',
		secah:{},
		refreshing:false
		
	  }
  },
  watch:{
	  'value'(a,b){
		  if(a==''){
			  this.page=1
			  this.list=[]
			  this.onRefresh()
		  }
	  }
  },
  methods:{
	  focus(){
		  console.log(this.value)
		 this.page=1
		 this.list=[]
		 // this.loading=false,
		 // this.finished=false,
		 this.onRefresh()
		 // this.getTutorLists({page:this.page,limit:this.limit,user_name:this.value}) 
	  },
	  jump(url){
		  window.location.href = url
	  },
	   getFocus() {
	          window.scroll(0, 0);
	      },
		onRefresh() {
			// 清空列表数据
			this.finished = false;
			this.list=[]
			this.page=1
			// 重新加载数据
			// 将 loading 设置为 true，表示处于加载状态
			this.loading = true;
			this.onLoad();
		},
	  // 导师榜单
	  getTutorLists(obj){
	  		  // console.log(obj)
	  		  getTutorLists(obj).then(res=>{
				  console.log(res)
				 if(res.data){
				 	this.page++
				 	this.count=res.data.count
				 	if (this.refreshing) {
				 	  this.list = [];
				 	  this.refreshing = false;
				 	}
				 	
				 	this.list.push(...res.data.list)
				 	  // 加载状态结束
				 	  this.refreshing=false
				 	  this.loading = false;
					  console.log(this.list)
				 	// 数据全部加载完成
				 	if (this.list.length >= this.count) {
				 	 this.finished = true;
				 	}
				 }else{
				 	 this.list = [];
				 	 this.count=0
				 	 this.finished = true;
				 }
		})		  			 
	  },
	  
	  // 搜索
	  btnSeachClick(){
		switch (this.activation){
			case '全部':
				this.value='全部'
				break;
			case '城市':
				this.value=this.province+','+this.city
				break;
			case '开课日期':
				this.value=this.oneDay
				break;
			case '开课时间段':
				this.value=this.oneDay1+','+this.oneDay2
				break;
			case '导师姓名':
				this.value=this.tutorName
				break;
			case '学员班':
				this.value=this.activation
				break;
			case '导师班':
				this.value=this.activation
				break;
			default:
				this.value=this.activation
				break;
		}
		// console.log(this.value)
		this.visible=false
	  },
	  // 日期区间选择
	  data_SectionClick(){
		  if(this.activation!=='开课时间段')return
		  this.isdata_Section=true
	  },
	  onConfirm_Section(res){
		  this.isdata_Section = false;
		  
		  this.oneDay1 = this.formatDate(res[0]);
		   this.oneDay2 = this.formatDate(res[1]);
	  },
	  // 单个日期选择
	dataClick(){
		   if(this.activation!=='开课日期')return
		  this.isdata=true
	  },
	formatDate(date) {
		console.log(date)
		return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
	  },
	onConfirm(date) {
		this.isdata = false;
		this.oneDay = this.formatDate(date);
	  },
	  // 选择城市做是否选择处理
	provinceClick(){
		  if(this.activation!=='城市')return
		  this.isAreaList=true
	  },
	open(){
		  this.isopen=!this.isopen
	  },
	onLoad() {
		console.log('sss')
			// 每页条数
		  this.getTutorLists({page:this.page,limit:this.limit,user_name:this.value})
			// // 页码
			// getTutorLists().then(res=>{
			// 	this.count=res.data.count
			// })
			// setTimeout(()=>{
			// 	this.page+=1
			// 	// 数据全部加载完成
			// 	if (this.list.length >= this.count) {
			// 	 this.finished = true;
			// 	}else{
			// 		  this.getTutorLists(false,{page:this.page,limit:this.limit,...this.secah})
			// 		  // console.log('dd')
			// 	}
			// 	// console.log(this.list)
			// },1000)
	      },
		  activationClick(text){
			  this.activation=text
		  },
		  cancel(){
			  console.log('222')
			  this.isAreaList=false
		  },
		  confirm(res){
			  // console.log(res)
			this.province=res[0].name
			this.city=res[1].name
			 this.isAreaList=false
		  }
  },
  
  mounted() {
  	document.title = this.title; 
	  // 轮播图片
	  getBanner({page_id:9}).then(res=>{
		  this.images=res.data.list
	  })
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.mentorList {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		padding-top: 15px;
		padding-bottom: 60px;

		.vanSwipe {
			width: 92%;
			margin: 0 auto 0.9375rem;
			height: 7.5rem;

			img {
				border-radius: 8px;
			}

		}

		.identity {
			color: #28334A;
			font-size: 12px;
			// font-weight: 100;
			margin-left: 1.6rem;
		}

		.btnBox {
			.btn {
				width: 7.1875rem;
				height: 5.625rem;
				background: no-repeat center;
				background-size: 109% 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: #FFFFFF;
				font-size: 12px;

				div.title {
					margin-bottom: 6px;
					margin-top: -10px;
				}

				div.btn_number {
					font-size: 28px;
					font-weight: bold;
				}
			}

			.btn1 {
				background-image: url(../../assets/Train/bg_yellow@2x.png);
			}

			.btn2 {
				background-image: url(../../assets/Train/bg_red@2x.png);
			}

			.btn3 {
				background-image: url(../../assets/Train/bg_blue@2x.png);
			}
		}

		.list_title {
			justify-content: space-between;

			// margin: 20px 0 20px;
			.list_title_left {
				width: 0.1875rem;
				height: 1.125rem;
				background: #0081FF;
				border-radius: 0 0.09375rem 0.09375rem 0;
				margin-right: 0.75rem;
			}

			.list_title_img {
				width: 0.4375rem;
				height: 0.6875rem;
				margin-right: 0.90625rem;
				margin-left: 0.28125rem;
			}

			.left {
				font-size: 1.125rem;
				color: #28334A;
				font-weight: bold;
			}

			.right {
				font-size: 0.9375rem;
				color: #0081FF;
			}
		}

		.list {
			flex-wrap: inherit;

			.list_img {
				margin-right: 0.71875rem;
				position: relative;
			}

			.list_img:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 6px;
				right: 0;
				width: 1.375rem;
				height: 1.25rem;
				background: no-repeat center;
				background-size: 100% 100%;
			}

			.list_img.list_img_1:after {

				background-image: url(../../assets/Train/icon_1@2x.png);

			}

			.list_img.list_img_2:after {

				background-image: url(../../assets/Train/icon_2@2x.png);

			}

			.list_img.list_img_3:after {

				background-image: url(../../assets/Train/icon_3@2x.png);

			}


			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}

			.text {
				font-size: 0.75rem;
				color: #919CB0;
				margin-left: 0.5rem;
			}

			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}

			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}

			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}

			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}

			.btnimg_span {
				margin-right: -5px;

			}
		}

		.bottom {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			background: #fff;
			height: 3.75rem;
			padding: 12px 0;
			box-shadow:0px -6px 10px 0px rgba(145,156,176,0.1);

			.bottom_input {
				width: 95%;
				height: 2.25rem;
				margin: 0 auto;
				background: #F4F4F4;
				border-radius: 1.125rem;
				position: relative;
				text-align: left;
				line-height: 2.25rem;
				padding-left: 15px;
				.bottom_input_imgdiv{
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);
					width: 28px;
					height: 28px;
					
				}
				.bottom_input_img {
					width: 16px;
					height: 16px;
					position: absolute;
					right: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
				}
			}
		}

		.Slide_up {
			z-index: 2;
			position: fixed;
			bottom: 0;
			height: 90vh;
			background: #fff;
			width: 100%;
			padding: 0.9375rem;
			text-align: left;

			// overflow: auto;
			.Slide_up_header {
				position: relative;
				font-size: 14px;
				text-align: center;

				.Slide_up_img {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					width: 30px;
					height: 30px;
					cursor: pointer;
				}
			}

			.btn {
				border-radius: 0.375rem;
				padding: 9px 13px;
				background: #F4F4F4;
				color: #919CB0;
				border: 1px solid transparent;
				margin-right: 10px;
				margin-top: 1rem;

			}

			.btn.activation {
				border-color: #0081FF;
				background: #FFFFFF;
				color: #0081FF;
			}

			.Slide_up_title {
				margin: 27px 0 0;
			}

			.btnSeach {
				width: 8.75rem;
				height: 42px;
				text-align: center;
				line-height: 42px;
				background: #0081FF;
				color: #fff;
				margin: 20px auto;
				border-radius: 21px;
			}
		}

	}
</style>
