<template>
	<div class="viewArticles">

		<div class="viewArticlesTop">
			<div class="viewArticlesTop_flex" style="">
				<div class="flex" style="justify-content: space-between;">


					<div class="flex">
						<div style="margin-right: 10px;">
							<img :src="userinfo.user_head_img" alt="" style="width: 41px;">
						</div>
						<div>
							<p style="font-weight: Bold;    align-items: flex-end;" class="flex"><span v-text="userinfo.user_name"></span>
								<span class="time" v-text="setTime(article.createtime)" style="margin-left: 10px;"></span></p>
							<p style="color: #919CB0;font-size: 10px;margin-top: 4px;" v-text="userinfo.user_title?userinfo.user_title:'暂无'" ></p>
						</div>
					</div>
					<div :class="article.is_follow?'btn1':'btn '" v-if="user_id!==userinfo.user_id" @click="postFollow(userinfo.user_id,article.is_follow)">
						<span v-text="article.is_follow?'已关注':'+ 关注'"></span>
					</div>
				</div>
				<div style="margin: 16px 0;color: #767E8D;" v-text="article.thank" v-if="article.type==2"></div>
				<div style="margin-top: 23px;font-weight: bold;" v-text="article.title" v-if="article.type==1"></div>
				<div style="margin: 16px 0;color: #767E8D;" v-html="article.content" v-if="article.type==1"></div>
				<div v-if="article.type==2" class="article flex" @click="show1=true">
					<img :src="article.share_picture?article.share_picture:require('@/assets/bigSink/icon_moren200_pre@2x.png')" alt="" style="width: 40px;height: 40px;">
					<div style="padding:0px 12.5px;width: calc(100% - 60px);" class="outerChain">
						<div v-text="article.share_title" style="font-weight: bold;margin-bottom: 7.5px;"></div>
						<div v-text="article.share_url" style="font-size: 10px;color: #767E8D;"></div>
					</div>
				</div>
				<!-- <div v-if="article.type==1&&article.cover_picture">
					<img :src="article.cover_picture" alt="" style="height: 170px;width: 100%;">
				</div> -->
				<!-- <div class="flex" style="justify-content: space-between;width: 100%;font-size: 12px;color: #919CB0;margin-top: 25px;">
					<div>
						<span class="number_0" v-text="article.browse_num"> </span>人看过 · <span class="number_0" v-text="article.comment_num"></span>
						评论
					</div>
					<div class="flex">
						<span class="number_0" v-text="article.praise_num"></span>
						<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 16px;height: 15px;margin-left: 10px;">
					</div>
				</div> -->
			</div>
			<div class="bottom " style="min-height: 46px;box-shadow: rgb(148, 149, 155) 1px 5px 15px 1px;height: auto;" :style="isshu?'z-index:3000;':''">
				<div class="flex bottomBox">
					<!-- <input type="text" placeholder="说点什么吧" @focus="isshu=true" @blur="isshu=false" :style="isshu?'width:85%':'width:50%'"> -->
					<van-field
					  v-model="questionsTo"
					  rows="1"
					  :autosize="{maxHeight: 100}"
					  type="textarea"
					  :placeholder="placeholder"
					  class="input"
					 :style="isshu?'width:85%':'width:50%'"
					 @focus="isshu=true" 
					 ref="inputFocus"
					/>
					<div class="flex" style="justify-content: space-around;width: 50%;" v-if="!isshu">
						<div @click="postWeixinAidHotArticleUserCollectAdd(article.is_collect,article.id)">
							<img src="../../assets/bigSink/icon_shouc b_nor@2x.png" alt="" v-if="!article.is_collect">
							<img src="../../assets/bigSink/icon_shoucb_pre@2x.png" alt="" v-if="article.is_collect">
						</div>
						<div style="position: relative;">
							<img src="../../assets/bigSink/icon_pinlun_b@2x.png" alt="">
							<div class="follow number_0" v-text="replycount" v-if="replycount">
								
							</div>
						</div>
						<div style="position: relative;">
							<img src="../../assets/bigSink/icon_zanb_nor@2x.png" alt="" v-if="!article.is_praise" @click="postWeixinAidHotArticlePraise(article.id,1)">
							<img src="../../assets/bigSink/icon_zanb_pre1@2x.png" alt="" v-if="article.is_praise" @click="postWeixinAidHotArticlePraise(article.id,2)">
							<div class="follow number_0" v-text="article.praise_num" v-if="article.praise_num">
								
							</div>
						</div>
					</div>
					<div style="color: #0081FF;text-align: right;margin-left: 14px;" v-if="isshu" @click="sendOutClick">
						发送
					</div>
				</div>
			</div>
			
			<div style="height: 10px;background-color: #F7F8FF;width: 100%;"></div>
			<div style="margin-top: 10px;text-align: left;padding: 0 15px;">
				<van-row justify="space-between" style="padding: 20px 0;border-bottom: 1px solid #E5E5E5;">
					<van-col span="12">
						<span style="font-size: 17px; font-weight: bold;">评论<span v-text="replycount"></span></span>
					</van-col>
					<van-col span="12">
						<div style="color: #0081FF;font-weight: bold;justify-content: flex-end;" class="flex" v-if="iszuixin" @click="taggleZui('hot')">
							<img src="../../assets/bigSink/icon_zuixin@2x.png" alt="" style="width:16px;margin-right: 8px;">
							<div>最新</div>
						</div>
						<div style="color: #0081FF;font-weight: bold;justify-content: flex-end;" class="flex" v-if="!iszuixin" @click="taggleZui('time')">
							<img src="../../assets/bigSink/icon_redu@2x.png" alt="" style="width:16px;margin-right: 8px;">
							<div>热度</div>
						</div>
					</van-col>
				</van-row>
				<div class="defaultBox" v-if="!replycount">
					<img src="../../assets/default/icon_zanwusx@2x.png" alt="" class="default_img" />
					<p style="    padding-bottom: 40px;">暂无评论</p>
				</div>
				<van-row style="padding: 20px 0; border-bottom: 1px solid #E5E5E5;" v-for="(item,i) in replylist" :key="i" v-if="replycount" @click="isshu=false">
					<van-col span="3">
						<img :src="item.userinfo.user_head_img" alt="" style="border-radius: 50%;width: 32px;">
					</van-col>
					<van-col span="21">
						<div style="font-weight: bold;margin-bottom: 10px;" v-text="item.userinfo.user_name"></div>
						<div style="color: #767E8D;font-size: 13px;text-align: justify;" v-text="item.comment_content"></div>
						<div style="margin: 16px 0 0;color: #919CB0;font-size: 12px;">
							<van-row type="flex" justify="space-between">
								<van-col span="12">
									<span class="number_0" v-text="setTime(item.createtime)">12-20 16:30</span>
								</van-col>
								<van-col span="12" class="flex" style="justify-content: flex-end;">
									<div class="number_0" v-text="item.praise_num"></div>
									<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" @click="postWeixinAidHotArticleCommentPraise(item.id,1)" v-if="!item.is_praise">
									<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" v-if="item.is_praise"  @click="postWeixinAidHotArticleCommentPraise(item.id,2)">
									<img src="../../assets/bigSink/icon_pinlun_s@2x.png" alt="" style="width: 20px;"  @click.stop="inputFocus(1,item)">
								</van-col>
							</van-row>
						</div>
						<div style="color: #0081FF;margin-top: 10px;" class="flex" @click="huiClick(i)" v-if="item.commentcount">
							<div style="font-size: 12px;"><span v-text="item.commentcount"></span>条回复</div><img src="../../assets/bigSink/icon_gengduo@2x.png"
							 alt="" style="width: 6px;height: 10px;margin-left: 5px;display: block;">
						</div>

					</van-col>

				</van-row>
			</div>
			<div style="margin:16px 0 25px ;text-align: center;font-size: 12px;" v-if="replycount&&replycount!==replylist.length" @click="moreClick">点击查看更多评论</div>
			<div class="van-list__finished-text" v-if="replycount&&replycount==replylist.length">没有更多了</div>
		</div>
		<van-popup v-model="show" closeable position="bottom" :style="{ height: '82%' }" v-if="replycount">
			<div style="margin: 16px 0 8.5px;">查看全部回复</div>
			<div style="text-align: left;    height: calc(100% - 60px); overflow: auto;">
				<van-row style="padding: 20px 15px; border-bottom: 1px solid #E5E5E5;">
					<van-col span="3">
						<img :src="replylist[index].userinfo.user_head_img" alt="" style="border-radius: 50%;width: 32px;">
					</van-col>
					<van-col span="21">
						<div style="font-weight: bold;margin-bottom: 10px;" v-text="replylist[index].userinfo.user_name"></div>
						<div style="color: #767E8D;font-size: 13px;text-align: justify;" v-text="replylist[index].comment_content"></div>
						<div style="margin: 16px 0 0;color: #919CB0;font-size: 12px;">
							<van-row type="flex" justify="space-between">
								<van-col span="12">
									<span class="number_0" v-text="setTime( replylist[index].createtime)"></span>
								</van-col>
								<van-col span="12" class="flex" style="justify-content: flex-end;">
									<div class="number_0" v-text="replylist[index].praise_num"></div>
									<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" @click="postWeixinAidHotArticleCommentPraise(replylist[index].id,1)" v-if="!replylist[index].is_praise">
									<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" @click="postWeixinAidHotArticleCommentPraise(replylist[index].id,2)" v-if="replylist[index].is_praise">
									<img src="../../assets/bigSink/icon_pinlun_s@2x.png" alt="" style="width: 20px;" @click="inputFocus(1,replylist[index])">
								</van-col>
							</van-row>
						</div>


					</van-col>

				</van-row>
				<div style="padding: 28px 15px 0;">

					<div style="font-weight: bold;"> 全部回复 <span style="color: #767E8D;">(<span v-text="replylist[index].commentcount"></span>)</span>
					</div>
					<van-row style="padding: 20px 0px; border-bottom: 1px solid #E5E5E5;" v-for="(item,i) in replylistList_rep.commentlist" :key="i">
						<!-- {{JSON.stringify(item)}} -->
						<van-col span="3">
							<img :src="item.userinfo.user_head_img" alt="" style="border-radius: 50%;width: 32px;">
						</van-col>
						<van-col span="21">
							<div style="font-weight: bold;margin-bottom: 10px;" v-text="item.userinfo.user_name"></div>
							<div style="color: #767E8D;font-size: 13px;text-align: justify;" v-text="item.comment_content"></div>
							<div style="margin: 16px 0 0;color: #919CB0;font-size: 12px;">
								<van-row type="flex" justify="space-between">
									<van-col span="12">
										<span class="number_0" v-text="setTime(item.createtime)"></span>
									</van-col>
									<van-col span="12" class="flex" style="justify-content: flex-end;">
										<div class="number_0" v-text="item.praise_num" v-if="item.praise_num"></div>
										<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" @click="postWeixinAidHotArticleCommentPraise(item.id,1)" v-if="!item.is_praise">
										<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" @click="postWeixinAidHotArticleCommentPraise(item.id,2)" v-if="item.is_praise">
										<img src="../../assets/bigSink/icon_pinlun_s@2x.png" alt="" style="width: 20px;" @click.stop="inputFocus(1,replylistList_rep.replyinfo)">
									</van-col>
								</van-row>
							</div>


						</van-col>

					</van-row>
				</div>

			</div>
		</van-popup>
		<van-popup v-model="show1" closeable position="bottom" :style="{ height: '82%' }">
			<div style="margin: 16px 0 8.5px;">查看分享文章</div>

			<div class="ifameClass">
				<!-- <div style="padding: 27px 0;font-weight: bold;border-bottom: 1px solid #EEEEEE;">
					<span v-text="article.title"></span>
				</div>
				<div style="font-size: 11px;padding: 9.5px 0 20px;">
					<span v-text="article.time" class="hui number_0" style="margin-right: 10px;" ></span>
					<span v-text="article.form" class="blue" ></span>
				</div>
				<img :src="article.img" alt="" style="width: 100%;">
				<div style="margin-top: 18px;color: #767E8D;">
					<p v-text="article.conten"></p>
				</div> -->
				<iframe :src="article.share_url" style="width: 100%;height: 100%;" frameborder="0"
				 scrolling="auto"></iframe>
			</div>
		</van-popup>

	</div>

</template>

<script>
	import {postWeixinAidHotArticleCommentPraise,postWeixinAidHotArticlePraise,postWeixinAidHotArticleUserInformationReply,postWeixinAidHotArticleUserCollectAdd,getWeixinAidHotArticleCommentOrder, getWeixinAidHotArticleDetail ,getWeixinAidHotArticleCommentReplyList,postWeixinAidHotArticleComment,postFollow,postWeixinAidHotArticleUserReply} from '@/api'
	import { Toast } from 'vant';
	import { setTime } from '@/util/setTime.js'
	export default {
  name: 'viewArticles',
  components: {
  },
  watch:{
	  isshu(){
		  if(!this.isshu){
			  this.isCommentList=0
			  this.placeholder='说点什么吧'
		  }
	  },
	  show(){
		  if(!this.show){
			  this.isshu=false
		  }
	  }
  },
  data(){
	  return{
		  placeholder:'说点什么吧',
		  CommentIndex:0,
		  isCommentList:0,
		  // 自己的id
		  user_id:0,
		  page:1,
		  limit:8,
		  questionsTo:'',
		  iszuixin:true,
		  show1:false,
		  article:{
			  url:'https://www.sohu.com/a/190048557_349978',
			  title:'冠心病患者如何健康午睡？',
			  time:'2018-04-18',
			  form:'疾病库',
			  img:'http://img3.imgtn.bdimg.com/it/u=3089137238,4050596303&fm=11&gp=0.jpg',
			  conten:'你是不是看了好多篇“医疗人工智能应用十大方向”是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新'
		  },
		  show:false,
		  isshu:false,
		images: [
		  'https://img.yzcdn.cn/vant/apple-1.jpg',
		  'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		active:'',
		userinfo:{},
		replylist:[],
		replycount:0,
		index:0,
		replylistList_rep:[],
		// 评论的id
		Commentid:0
	  }
  },
  methods:{
	  // 文章点赞
	  postWeixinAidHotArticlePraise(id,type){
		  postWeixinAidHotArticlePraise({hot_article_id:id,type:type}).then(res=>{
			   if(res.code==1){
			  	 this.getWeixinAidHotArticleDetail()
			   }
		  })
	  },
	  // 点赞
	  postWeixinAidHotArticleCommentPraise(id,type){
		  
		  postWeixinAidHotArticleCommentPraise({comment_id:id,type:type}).then(res=>{
		  		// Toast(res.msg) 
				 if(res.code==1){
					 if(this.iszuixin){
					 	this.getWeixinAidHotArticleCommentOrder('time')
						getWeixinAidHotArticleCommentReplyList({comment_id:this.replylist[i].id}).then(res=>{
									  this.replylistList_rep=res.data
									  console.log( this.replylistList_rep)
						})
					 }else{
					 	this.getWeixinAidHotArticleCommentOrder('hot')
						getWeixinAidHotArticleCommentReplyList({comment_id:this.replylist[i].id}).then(res=>{
									  this.replylistList_rep=res.data
									  console.log( this.replylistList_rep)
						})
					 }
				 }
		  })
	  },
	  postWeixinAidHotArticleUserCollectAdd(is_collect,id){
		  postWeixinAidHotArticleUserCollectAdd({hot_article_id:id}).then(res=>{
			  if(res.code==1){
				  Toast(res.msg)
				 this.getWeixinAidHotArticleDetail()
			  }
		  })
	  },
	  // 点击评论 - 回复
	  inputFocus(index,item){
		  this.isCommentList=index
		  this.Commentid=item.id
		  this.placeholder=`回复：${item.userinfo.user_name}`
		  console.log('评论',item,this.Commentid)
		  this.$refs.inputFocus.focus()
	  },
	  // 点击关注
	  postFollow(id,isfollow){
	  		  let follow=isfollow==0?1:0
	  		  postFollow({tutor_id:id,is_follow:follow}).then(res=>{
	  			  Toast(res.msg)
				  if(follow==0){
					   this.article.is_follow=1
					   this.getWeixinAidHotArticleDetail()
				  }else{
					  this.article.is_follow=0
					   this.getWeixinAidHotArticleDetail()
				  }
	  		  })
	  },	
	  // 切换最新和热度
	  taggleZui(order){
		  console.log(order)
		  this.iszuixin=!this.iszuixin
		  this.getWeixinAidHotArticleCommentOrder(order)
	  },
	  // 评论文字
	  huiClick(i){
		  this.show=true
		  this.index=i
		  getWeixinAidHotArticleCommentReplyList({comment_id:this.replylist[i].id}).then(res=>{
			  this.replylistList_rep=res.data
			  console.log( this.replylistList_rep)
		  })
		 
	  },
	  // 获得文章详情
	getWeixinAidHotArticleDetail(){
		getWeixinAidHotArticleDetail({hot_article_id:this.$route.query.id}).then(res=>{
			this.article=res.data.articleinfo
			this.userinfo=res.data.userinfo
			this.article.is_collect=res.data.is_collect
			this.article.is_follow=res.data.is_follow
			this.article.is_praise=res.data.is_praise
			this.article.praise_num=res.data.praise_num
		})
		
	} ,
	// 获得文章评论列表
	getWeixinAidHotArticleCommentOrder(order){
		getWeixinAidHotArticleCommentOrder({order:order,hot_article_id:this.$route.query.id,page:this.page,limit:this.limit}).then(res=>{
			
			this.replylist=res.data.replylist
			this.replycount=res.data.replycount
			console.log(this.replylist)
		})
	},
	// 查看跟多评论
	moreClick(){
		console.log(this.iszuixin)
		this.limit=this.replycount
		console.log(this.limit)
		if(this.iszuixin){
			this.getWeixinAidHotArticleCommentOrder('time')
		}else{
			this.getWeixinAidHotArticleCommentOrder('hot')
		}
	},
	// 发送
	sendOutClick(){
		switch (this.isCommentList){
			case 0:
			postWeixinAidHotArticleComment({hot_article_id:this.$route.query.id,comment_content:this.questionsTo}).then(res=>{
				Toast(res.msg)
				if(res.code==1){
					this.isshu=false
					this.questionsTo=''
					if(this.iszuixin){
						this.getWeixinAidHotArticleCommentOrder('time')
					}else{
						this.getWeixinAidHotArticleCommentOrder('hot')
					}
				}
			})
				break;
			case 1:
				postWeixinAidHotArticleUserReply({comment_id:this.Commentid,comment_content:this.questionsTo}).then(res=>{
					if(res.code==1){
						Toast(res.msg)
						this.isshu=false
						this.questionsTo=''
						this.isCommentList=0
						if(this.iszuixin){
							this.getWeixinAidHotArticleCommentOrder('time')
						}else{
							this.getWeixinAidHotArticleCommentOrder('hot')
						}
					}
				})
				break;
			default:
				break;
		}
		this.isshu=true
		
	},
	setTime(time){
		return setTime(time)
	}
	
  },
  mounted() {
  	document.title = '花样文章';   
	this.user_id=JSON.parse(localStorage.getItem('user_data')).user_id
	this.getWeixinAidHotArticleDetail()
	this.getWeixinAidHotArticleCommentOrder('time')
  }
}
</script>
<style lang="scss" type="text/css">
	.viewArticles {
		min-height: 100vh;
		background-color: #fff;
		padding: 0 0 46px;
		.input {
			width: calc(100% - 77px);
			min-height: 33px;
			line-height: 33px;
			background-color: #F4F4F4;
			option: none;
			border-radius: 6px;
			padding:0 10px;
			transform: all 1s;
				
		}
		.viewArticlesTop {
			width: 100%;
			text-align: left;
			img{
				max-width: 100%;
			}
			.viewArticlesTop_flex {

				justify-content: space-between;
				padding: 20px 15px 24px;
				border-radius: 6px;

				.time {
					color: #919CB0;
					font-size: 10px;
					font-weight: 100;
					display: block;
				}
			}
		}

		.btn {
			padding: 5px 8.5px;
			background-color: #0081FF;
			border-radius: 50px;
			color: #fff;
			font-size: 12px;
			border: 1px solid transparent;
		}
		.btn1 {
			padding: 5px 8.5px;
			background-color: transparent;
			border-radius: 50px;
			color: #0081FF;
			font-size: 12px;
			border: 1px solid #0081FF;
		}

		.outerChain {}

		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}

		.van-tab {
			min-width: 85px;
		}

		.van-tab--active {
			font-size: 17px;
		}

		.topBox {
			padding: 15px;
			background-color: #fff;

		}

		.bottomBox {
			padding: 6.5px 15px;

			img {
				width: 20px;
				height: 20px;
			}

			input {
				width: 50%;
				height: 33px;
				line-height: 33px;
				background-color: #F4F4F4;
				option: none;
				border-radius: 6px;
				padding-left: 12px;
				transform: all 1s;

			}

			.follow {
				position: absolute;
				top: -7px;
				left: 100%;
				font-size: 10px;
				color: #919CB0;
			}
		}

		.article {
			width: 100%;
			background-color: #F4F4F4;
			border-radius: 2px;
			text-align: left;
			padding: 5px;
			margin: 20px 0;
		}

		.ifameClass {
			text-align: left;
			padding: 0 15px;
			height: calc(100% - 20px - 16px - 8.5px);
			overflow: auto;
		}
	}
</style>
