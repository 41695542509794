<template>
	<div class="mechanism" :style="'padding-bottom: '+   offsetHeight+'px'">
		<div>
			<div class="title">
				已加入的培训机构
			</div>
			<div v-if="insUserAddList.length==0">
				<img src="../../assets/default/icon_nodota.png" alt="" style="width: 150px;">
				<p style="color: #919CB0;">暂无培训课程</p>
			</div>
			<div class="listBoxs"  v-else>
				<div class="flex listBox" style="justify-content: space-between;" v-for="(item,i) in insUserAddList" :key="i">
					<div v-text="item.name"></div>
					<div class="btn" style="background:transparent;border: 1px solid #FF524D; color: #FF524D;"  @click="closeClick(item)">退出机构</div>
				</div>
			</div>
		</div>
		<div class="fen"></div>
		<div>
			<div class="title">
				可申请加入的培训机构
			</div>
			<div class="listBoxs">
				<div class="flex listBox" style="justify-content: space-between;" v-for="(item,i) in institution" :key="i">
					<div v-text="item.name"></div>
					<div class="btn" v-if="item.apply_status=='未申请'" @click="institutionClick(item)">申请加入</div>
					<div class="btn" style="background:rgba(255,188,0,1);" v-if="item.apply_status=='申请中'">申请中</div>
				</div>
			
			</div>
		</div>
		<div class="bottom mechanism_bottom" ref="mechanism_bottom" style="text-align: left;font-size: 12px;height: auto;">
			
			 <div class="" style="display: flex;">
			 	<div>
			 		<img src="../../assets/icon_tips_red@2x.png" alt="" style="width: 12px;height: 12px;margin-right: 10px;">
			 		
			 	</div>
			 	<div style="font-size: 12px;text-align: justify;width: calc(100% - 19px);"> 提示：申请加入某个培训机构后，可以以这个培训机构的名义发布培训通知，发证的时候也是盖这个培训中心的印章</div>
			 	
			 </div>
		</div>
		<van-popup v-model="show" closeable position="bottom" :style="{ height: '90vh' }">
			<div class="popupConten">
				<div class="header" style="padding-bottom: 5px;">申请加入</div>

				<div class="form" style="height:calc(82vh - 30px - 52px ) ;overflow: auto;padding: 0 15px;">
					<div class="flex">
						<div class="left">
							机构：<span v-text="institutionName"></span>
						</div>
					</div>
					<van-row class="row">
						<van-col span="8">
							<div class="left">
								真实姓名<span style="color: red;">*</span>
							</div>
						</van-col>
						<input type="text" placeholder="请输入姓名" v-model="sign_name" style="
						height:35px;
						background:rgba(244,244,244,1);
						border-radius:6px;
						padding-left: 12px;
						" />
					</van-row>
					<van-row class="row">
						<van-col span="8">
							<div class="left">
                                身份证人像面<span style="color: red;">*</span>
							</div>
						</van-col>
						<van-uploader :after-read="afterRead" v-model="sfz_image" :max-count="1" @delete="beforedelete(0)">

							<img src="../../assets/mechanism/pic_sfz@2x.png" alt="" icon="photo" style="width: 56%;">
						</van-uploader>
					</van-row>
					<van-row class="row">
						<van-col span="8">
							<div class="left">
								医疗资质证书
							</div>
						</van-col>
						<van-uploader :after-read="afterRead" v-model="ylzz_image" :max-count="1" @delete="beforedelete(1)">
					
							<img src="../../assets/mechanism/pic_zhengjian@2x.png" alt="" icon="photo" style="width: 56%;">
						</van-uploader>
					</van-row>
					<van-row class="row">
						<van-col span="8">
							<div class="left">
								培训资质证书
							</div>
						</van-col>
						<van-uploader :after-read="afterRead" v-model="pxzz_image" :max-count="1" @delete="beforedelete(2)">
					
							<img src="../../assets/mechanism/pic_zhengjian@2x.png" alt="" icon="photo" style="width: 56%;">
						</van-uploader>
					</van-row>
				</div>


				<div style="
			width:95%;
			margin: 0 auto 20px;
			height:1px;
			background:rgba(229,229,229,1);
			"></div>
				<div class="formBtn">
					<div class="btn" @click="formClick" style="padding: 0;">
						提交
					</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="show1" :round="true" class="daoshi" style="border-radius: 6px !important;">
			<div class="popupBox">
				<div class="title" style="padding-bottom: 20px;padding-top: 20px;">
					退出机构
				</div>
				<div class="" style="text-align: left;">确定要退出：<span v-text="closeName"></span>？</div>
				<div class="flex btnBox">
					<div @click="show1=false">取消</div>
					<div class="suss" @click="ownClick" v-if="isone">确定</div>
					<div class="suss" v-if="!isone">确定</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import {institutionList,insUserAdd,institutionApply,institutionOut} from '@/api'
	import defaultVue from '@/components/default/index.vue'
	export default {
  name: 'mechanism',
  components: {
	  defaultVue
  },
  data(){
	  return{
		  isone:true,
		  count:0,
		show:false,
		sign_name:'',
		pxzz_image:[],
		ylzz_image:[],
		sfz_image:[],
		issan:false,
		// 可申请加入的培训机构
		institution:[],
		offsetHeight:0,
		insUserAddList:[],
		ins_id:'',
		close_id:'',
		show1:false,
		institutionName:'',
		closeName:''
	  }
  },
  methods:{
	  // 退出机构
	  ownClick(){
		  
		  institutionOut({ins_id:this.close_id}).then(res=>{
			  
			  this.isone=true
			  if(res.code==1){
				  this.insUserAddList.forEach((item,i)=>{
					  if(item.id==this.close_id){
						  this.insUserAddList.splice(i,1)
					  }
				  })
				 this.show1=false
				 this.institutionList()
				 this.insUserAdd()
			  }
		  })
	  },
		closeClick(item){
			this.close_id=item.ins_id
			this.closeName=item.name
			this.show1=true
			// 显示不等重复点击
		},
	 
	  institutionClick(item){
		  this.ins_id=item.id
		  this.show=true
		  this.institutionName=item.name
	  },
	  beforedelete(){
		  
	  },
	  formClick(){
		  if(this.sfz_image[0]&&this.sign_name){
			  let arr = {
					ins_id:this.ins_id,
					real_name:this.sign_name,
					sfz_image:this.sfz_image[0].content,
					ylzz_image:this.ylzz_image.length?this.ylzz_image[0].content:'',
					pxzz_image:this.pxzz_image.length?this.pxzz_image[0].content:''
			  }
			  this.institutionApply(arr)
		  }else{
			  this.$Toast('请完善必填项')
		  }
	  },
	  afterRead(file) {
			var _this=this
			file.status = 'uploading';
			file.message = '上传中...';
			// 如果上传成功
			if (file.file) {
				const data = new FormData()
				data.append('imgurl', file.file)
				data.append('token', sessionStorage.getItem('token'))
				this.$axios.post('/gzh/backend/public/index.php/api/user/postFeedbackImg', data,{
								headers:{'Content-Type':'multipart/form-data'}
				}).then(function (res) {
					file.content=res.data
					file.status = '';
					file.message = '上传成功';
				})
				.catch(function (error) {
				  console.log(error);
				});
			} else {
				// 提示信息，需引入Message
				Message.error('图片插入失败')
			}
			
		},
		institutionList(){
			institutionList().then(res=>{
				console.log(res)
				if(res.code==1){
					this.institution=[]
					this.institution=res.data
				}
			}) 
		},
		insUserAdd(){
			insUserAdd().then(res=>{
				if(res.code==1){
					this.insUserAddList=[]
					this.insUserAddList=res.data.list
					console.log(res.data)
				}
			})
		},
		institutionApply(arr){
			institutionApply(arr).then(res=>{
				console.log(res.data)
				if(res.code==1){
					this.show=false
					// 重新获得数据
					this.institutionList()
					this.insUserAdd()

				}
			})
		}
		
  },
  mounted() {
  	document.title = '已加入的发证机构';   
	this.institutionList()
	this.insUserAdd()
	this.offsetHeight= this.$refs.mechanism_bottom.offsetHeight
  }
}
</script>
<style lang="scss" type="text/css" >
	.mechanism {
		background-color: #fff;
		min-height: 100vh ;
		.mechanism_bottom {
			padding: 15px ;
		}
		.fen{
			width: 100%;
			height:10px;
			background:rgba(247,248,255,1);
		}
		.daoshi {
			.van-popup--center.van-popup--round{
				
			}
			.popupBox {
				width: 300px;
				height: 11.875rem;
				background-color: #fff;
				border-radius: 3px;
				padding: 0 20px 28px;
				text-align: center;
				position: relative;
		
				.title {
					padding: 42px 0;
					line-height: 23px;
				}
		
				.btnBox {
					position: absolute;
					bottom: 28px;
					right: 34px;
					justify-content: flex-end;
					color: #919CB0;
		
					div:first-child {
						margin-right: 40px;
					}
		
					.suss {
						color: #0081FF;
					}
				}
			}
		}
		
		.listBoxs{
			padding: 0 15px;
			.listBox{
				justify-content: space-between;
				padding: 16px 0;
				border-bottom: 1px solid #E5E5E5;
				&:last-child{
					border-bottom: 1px solid transparent;
				}
				.btn{
					width:70px;
					height:26px;
					background:rgba(0,129,255,1);
					border-radius:13px;
					font-size:12px;
					font-family:PingFang SC;
					font-weight:500;
					color:rgba(255,255,255,1);
					text-align: center;
					line-height: 25px;
				}
			}
		}
		
		
		.formBtn {
			padding: 10px 0;

			.btn {
				border-radius: 21px;
				width: 8.75rem;
				height: 2.625rem;
				background-color: #0081ff;
				color: #fff;
				line-height: 2.625rem;
				text-align: center;
				margin: 0 auto;
			}
		}

		p {
			margin-bottom: 28px;
		}

		.header {
			margin-top: 15px;
			font-size: 18px;
		}

		.van-row {
			margin: 20px 0;
		}

		.van-col {
			margin-bottom: 15px;
			text-align: left;
		}
		.van-uploader__preview-image{
			width: 56% !important;
			height: auto !important;
			margin: 0 auto;
			
		}
		.title{
			font-size:18px;
			font-family:PingFang SC;
			font-weight:800;
			color:rgba(51,51,51,1);
			padding: 15px 0;
		}
	}
</style>
