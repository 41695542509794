import { render, staticRenderFns } from "./ScanCodeLogin.vue?vue&type=template&id=dea20770&scoped=true&"
import script from "./ScanCodeLogin.vue?vue&type=script&lang=js&"
export * from "./ScanCodeLogin.vue?vue&type=script&lang=js&"
import style0 from "./ScanCodeLogin.vue?vue&type=style&index=0&id=dea20770&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dea20770",
  null
  
)

export default component.exports