<template>
  <div class="inAassessment">
	  <div class="name">学员：<span style="color: #273349;font-weight: bold;" v-text="$route.query.sign_name"></span></div>
	<div class="bar">考核项目</div>
	<div>
		<div class="box" v-for="(item,i) in train_courseArr" :key="i">
			<p class="title">{{item.course_name}}</p>
			<div class="btn" v-if="item.score==0" @click="btnClick(item)">
				开始考核
			</div>
			<div class="btn qualified" v-if="item.score=='良好'" @click="btnClickOK(item)">
				已考核：良好
			</div>
			<div class="btn qualified" v-if="item.score=='合格'" @click="btnClickOK(item)">
				已考核：合格
			</div>
			<div class="btn qualified" v-if="item.score=='优秀'" @click="btnClickOK(item)">
				已考核：优秀
			</div>
			<div class="btn unqualified" v-if="item.score=='不合格'&&$route.query.hide" @click="btnClickOK(item)">
				不合格，重新考核
			</div>
			<div class="btn unqualified" v-if="item.score=='不合格'&&!$route.query.hide" @click="btnClick(item)">
				不合格，重新考核
			</div>
		</div>
	</div>
  </div>
</template>

<script>
	import { getOfficialCourse,getCourseExamineTitleCourseInfo ,getActivity_is} from '@/api'
	import {dateFormat} from '@/util/setTime.js'
export default {
  name: 'inAassessment',
  components: {
  },
  data(){
	  return{
		list:[
		 {
			name:'单人成人心肺复苏操作' ,
			id:1,
		 }
		],
		train_courseArr:[],
		conData:{}
	  }
  },
  methods:{
	  // 去除空白数组下标
	  skipEmptyElementForArray(arr) {
	    var a = [];
	    for (let i = 0; i < arr.length; i++) {
	      if (arr[i]) {
	        a.push(arr[i])
	      }
	    }
	    return a;
	  },
	 btnClick(item){
		 if(!this.$route.query.hide){
			let date = new Date()
			let time = dateFormat('Y-m-d',date)
			this.$router.push({name:'InTheAssessment',query:{sign_name:this.$route.query.sign_name,time:time,course:item.name,courseId:item.id,sign_id:this.$route.query.sign_id,act_id:this.$route.query.act_id,train_course_id: item.train_course_id,courseId:item.course_id,course_name:item.course_name,act_type:this.$route.query.act_type}}) 
		 }else{
			 this.$Toast('未考核')
			 }
		 
	 },
	 btnClickOK(item){
		 
		 let date = new Date()
		 let time = dateFormat('Y-m-d',date)
		 this.$router.push({name:'InTheAssessmentOK',query:{sign_name:this.$route.query.sign_name,time:time,course:item.name,courseId:item.id,sign_id:this.$route.query.sign_id,act_id:this.$route.query.act_id,train_course_id:item.train_course_id,courseId:item.course_id,course_name:item.course_name,score:item.score,act_type:this.$route.query.act_type}})
	 },
	// 先获得课程。避免课程慢得到
	getCourseExamineTitleCourseInfo() {
		let arr = this.skipEmptyElementForArray(this.$route.query.train_course.split('|'))
		let _this =this
		arr.forEach((item,i)=>{
			getCourseExamineTitleCourseInfo({act_type:this.$route.query.act_type,train_course_id:item,student_id:this.$route.query.sign_id,act_id:this.$route.query.act_id}).then(resd => {
			 resd.data.forEach(items=>{
				 items.train_course_id=item
				
				 // items.score=20
				 
			 })
			 this.train_courseArr.push(...resd.data)
				console.log(this.train_courseArr)
			})
		})

	}
  },
  mounted() {
  	document.title = '考核';  
	 console.log()
	this.getCourseExamineTitleCourseInfo()
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.inAassessment{
		text-align: left;
		.name{
			color: #919CB0;
			padding: 16px;
		}
		.bar{
			width:100%;
			height:30px;
			background:linear-gradient(90deg,rgba(86,159,255,1) 0%,rgba(138,124,255,1) 100%);
			text-align: center;
			color:rgba(255,255,255,1);
			line-height: 30px;
		}
		.box{
			width: 92%;
			height:124px;
			margin: 10px auto 0;
			background:rgba(255,255,255,1);
			border-radius:6px;
			text-align: center;
			.title{
				font-size:16px;
				font-family:PingFang SC;
				font-weight:bold;
				color:rgba(39,51,73,1);
				margin-bottom: 28px;
				padding-top: 20px;
			}
			.btn{
				width:140px;
				height:31px;
				background:rgba(255,255,255,1);
				border:1px solid rgba(0,129,255,1);
				border-radius:15px;
				margin: 0 auto;
				font-size:14px;
				line-height: 29px;
				font-family:PingFang SC;
				font-weight:500;
				color:rgba(0,129,255,1);
				box-sizing:border-box;
			}
			.unqualified{
				background:rgba(255,255,255,1);
				border:1px solid rgba(255,82,77,1);
				border-radius:31px;
				color:rgba(255,82,77,1);
			}
			.qualified{
				background:rgba(0,217,126,.1);
				color:rgba(0,217,126,1);
				border-color: transparent;
			}
		}
	}
</style>
