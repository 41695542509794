<template>
	<div class="traindal">
		<div class="imgBox">
			<img src="../../assets/Train/bg_bmtop@2x.png" alt="" class="auto-img">
		</div>
		<div style="padding: 15px 15px 10px;">
			<div class="container1">
				<!-- <div class="header">现场急救培训-广东软件园</div> -->
				<div class="header_title">
					<span v-text="conData.act_name"></span>
					<!-- <van-divider /> -->
				</div>
				<div class="conData">

					<van-row style='margin: 0;'>
						<van-col span="4">
							<span class="title">时间</span>
						</van-col>
						<van-col span="18">
							<div class="flex">
								<div>
									<div>
										<span v-text="conData.act_start_time"></span>
									</div>
									<div>
										<span v-text="conData.act_end_time"></span>
									</div>
								</div>
								<div class="zhi">至</div>
							</div>
						</van-col>
					</van-row>
					<!-- <van-row>
					<van-col span="9">
						<span class="title">考核开始时间</span>
					</van-col>
					<van-col span="15">
						<span v-text="conData.check_start_time"></span>
					</van-col>
				</van-row> -->
					<van-row>
						<van-col span="6">
							<span class="title">培训地点</span>
						</van-col>
						<van-col span="18">
							<span v-text="conData.act_addr"></span>
						</van-col>
					</van-row>
					<van-row>
						<van-col span="6">
							<span class="title">培训课程</span>
						</van-col>
						<van-col span="18">
							<span v-for="(item,i) in courseList" :key="i" v-text="i==courseList.length-1?item:item+','"></span>
						</van-col>
					</van-row>

					<van-row>
						<van-col span="6">
							<span class="title">考核课程</span>
						</van-col>
						<van-col span="18">
							<span v-if="conData.act_type==2">授课技巧考核</span>
							<!-- <span v-for="(item,i) in courseList_kao" :key="i" v-text="i==courseList_kao.length-1?item:item+','"></span> -->
						</van-col>
					</van-row>
					<div class="img">
						<VueQr :logSrc="config.logo" :text="config.value" :margin="0" style="width: 100%;height: 100%;"></VueQr>
					</div>
					<div style="text-align: center;margin-top: 10px;">
						<p>报名二维码</p>
                      <p>长按保存图片分享</p> 
					</div>
					<div class="dashed">
						<van-divider :style="{ borderColor: '#F7F8FF'}" :hairline="false"></van-divider>
					</div>
					<div class="flex" style="justify-content: space-around;" v-if="conData.act_status==1||conData.act_status==3">
						<div class="btn" @click="changeActivityStatus"><span v-text="this.conData.act_status==1?'下线':this.conData.act_status==3?'上线':''"></span></div>
						<div class="btn" @click="editClick">编辑</div>
					</div>
					<!-- <div class="flex" style="justify-content: space-around;" v-if="conData.act_status==2">

						<div class="btn" @click="$router.push({name:'feedbackFrom',query:{id:$route.query.act_id}})" style="background-color: #0081FF;color: #fff;">发布培训反馈</div>
					</div> -->
					<div class="flex" style="justify-content: space-around;" v-if="conData.act_status==4">

						<div class="btn" style="background-color: #0081FF;color: #fff; " @click="$router.push({name:'lookFeedbackFrom',query:{act_id:$route.query.act_id}})">查看反馈</div>
					</div>

				</div>
			</div>
		</div>
		<!-- <div style="width: 100%;height: 15px;"></div> -->
		<div>
			<van-tabs title-inactive-color="#919CB0" @click="onClick" v-model="tabIndex">

				<van-tab v-for="index in list_title" :title="index" :key="index">
					<div v-if="!tab_list.length">
						暂无数据
					</div>
					<div v-for="(item,i) in tab_list" class="List_row" v-if="index!=='证书发放'&&tab_list.length&&index!=='学员签到'" :key="i">
						<div class="list_row_row">
							<div><span v-text="item.sign_name"></span></div>
							<div><span v-text="item.sign_phone"></span></div>
							<div v-if="index=='导师签到' && list1.is_zhujiang_teacher == 1"><span v-text="item.dis_counts == 0 ? '未分配' : item.dis_counts + '人'"></span></div>
							<div style="text-align: right;">
								<span v-text="
								item.teacher_type==0?'助教导师':item.teacher_type==1?'主讲导师':''
								" v-if="index=='导师签到'"></span>
								<span v-text="i+1" v-if="index=='助教导师报名'"></span>
								<span v-else v-text="item.sex==1?'男':item.sex==2?'女':''"></span>
							</div>
						</div>
					</div>

					<van-checkbox-group v-model="result" @change="checkClick" class="List_row" v-if="index=='学员签到'&&tab_list.length">
						<van-checkbox :name="i" v-for="(item,i) in tab_list" :key="i">
							<div class="list_row_row" style="margin-left: 15px;margin-bottom: 7px;">
								<div><span v-text="item.sign_name"></span></div>
								<div><span v-text="item.sign_phone"></span></div>
								<div style="text-align: right;">
									<div class="kaobtn " @click.stop="kao(item)" v-if="!item.is_complete">考核</div>
									<div class="kaobtn kaobtn_Act " @click.stop="kao_act(item)" v-if="item.is_complete">已考核</div>
								</div>
							</div>
						</van-checkbox>
					</van-checkbox-group>

					<van-row v-for="(item,i) in tab_list" class="List_row" v-if="index=='证书发放'&&tab_list.length" :key="i" >
						<van-col span="6" style="text-align: left;"><span v-text="item.sign_name"></span></van-col>
						<van-col span="6"><span v-text="item.sign_phone"></span></van-col>
						<van-col span="6" style="text-align: right;">
							<span ></span>
						</van-col> 
						<van-col span="6" style="text-align: right;" @click="stateClick(i,item.is_complete)"  v-if="!item.is_certs"><span 
							 :style="item.is_certs?'color:#00D97E':'color:#919CB0'">未发证</span>	
							<van-icon name="arrow" :color="item.is_certs?'#00D97E':'#919CB0'" />
						</van-col>
						<van-col span="6" style="text-align: right;"  v-if="item.is_certs"><span
							 :style="item.is_certs?'color:#00D97E':'color:#919CB0'">已发证</span>	
							<van-icon name="arrow" :color="item.is_certs?'#00D97E':'#919CB0'" />
						</van-col>
					</van-row>
				</van-tab>
			</van-tabs>
		</div>
		<!-- <div class="bottom" v-if="tabIndex==0">
			报名总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人，全天班：<span class="fontWeight"  v-text="list1.count1?list1.count1:0">123</span>人，考证班：<span class="fontWeight"  v-text="list1.count2?list1.count2:0">132</span>人 -->
		<!-- </div> -->

		<div class="bottom bot-flex">
			<div v-if="tabIndex==1||tabIndex==0||tabIndex==2">
				报名总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人
			</div>
			<div class="signup-button" v-if="isShowStudentGroupButtom && list1.is_zhujiang_teacher == 1" @click.stop="$router.push({
				name: 'distributionAssistant',
				query: { act_id, resultGroupStr }
				})">学员分组</div>
		</div>
		<div class="bottom" v-if="tabIndex==3">
			签到总人数：<span class="fontWeight" v-text="list1.count?list1.count:0"></span>人，已发证：<span class="fontWeight" v-text="list1.count1?list1.count1:0"></span>人，未发证：<span
			 class="fontWeight" v-text="list1.count2?list1.count2:0"></span>人
		</div>
		<!-- <div class="fu flex">
			<img src="../../assets/Train/icon_tips@2x.png" alt="">
			<div>
				付费协议
			</div>
		</div>
		<div class="bottom flex" style="padding:0 30px ;">
			<div style="width: 50%;position: relative;">
			<div class="btn cancel" @click="$router.go(-1)">
				取消
			</div>
			</div>
			<div style="width: 50%;position: relative;">
			<div class="btn" @click="Success">
				立即付费
			</div>
			</div>
		</div> -->
		<van-popup v-model="show" :round="true">
			<div class="popupBox">
				<div class="title">确定要申请主任导师督导吗？申请后如果有主任导师接受了督导邀请，您还需要对督导费进行付费。</div>
				<div class="flex btnBox">
					<div @click="show=false">暂不取消</div>
					<div class="suss">确定</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import VueQr from 'vue-qr'
	import {
		getActivity,
		getActivityCommentLists,
		getActivitySignupLists,
		getActivitySignupTutorLists,
		getActivitySigninLists,
		getActivitySigninTutorLists,
		changeActivityStatus,
		getOfficialCourse,
		getActivitySigninCertsLists
	} from '@/api/index.js'
	import {
		Toast
	} from 'vant';
	export default {
		name: 'traindal',

		components: {
			VueQr
		},
		data() {
			return {
				// 二维码
				config:{
					  logo:'',
					  value:'https://xcjj.tysos.cn/courseDetails?act_id='+this.$route.query.act_id
				},
				// 标签页标识符
				tabIndex: 0,
				// isOver
				act_id: 0,
				//申请监督
				show: false,

				title: '培训详情及管理',
				conData: {
					start: '2019-12-10 16:00',
					end: ' 2019-12-10 13:00',
					kaoStart: ' 2019-12-10 13:00',
					supervisor: 1,
					PXcourse: '心肺复苏术、AED使用、创伤包扎',
					KHcourse: '心肺复苏术、AED使用、创伤包扎'

				},
				list_title: ['学员报名', '学员签到', '导师签到', '证书发放'],
				tab_list: [{
						name: 'xxxx',
						phone: 123456789,
						type: '123',
						state: 0
					},
					{
						name: 'xxxx',
						phone: 123456789,
						type: '123',
						state: 1
					},
					{
						name: 'xxxx',
						phone: 123456789,
						type: '12',
						state: '阿斯顿'
					},
					{
						name: 'xxxx',
						phone: 123456789,
						type: '312',
						state: 1
					},
					{
						name: 'xxxx',
						phone: 123456789,
						type: '123',
						state: 0
					}
				],
				list1: {
					img: 'https://img.yzcdn.cn/vant/cat.jpeg',
					place: '广州市萝岗区科学城科珠路201号广东软件园G栋401室',
					keName: '成人心肺复苏及自动体外除颤器（AED）使用方法 ',
					time: '2017-12-07  13:30',
					site: '天鹰救援培训中心-广州分中心',
					cost: 132
				},
				list1: [],
				list2: [],
				list3: [],
				list4: [],
				list5: [],
				// 培训课程
				courseList: [],
				// 考核课程
				courseList_kao: [],
				result: [],
				isShowStudentGroupButtom: false,
				resultGroupStr: '',
				activityAuthCheck: {},
			}
		},
		methods: {
			changeActivityStatus() {
				let act_statusIng = this.conData.act_status == 1 ? 3 : this.conData.act_status == 3 ? 1 : 0
				console.log(act_statusIng)
				changeActivityStatus({
					act_id: this.$route.query.act_id,
					act_status: act_statusIng
				}).then(res => {
					this.conData.act_status = res.data
					Toast(res.msg);
				})
			},
			// 多选报名学员
			checkClick() {
				// console.log(this.result);
				let resultGroup = [];
				for (let i = 0; i < this.result.length; i++) {
					resultGroup.push(this.tab_list[this.result[i]].user_id);
				}
				console.log(resultGroup);
				this.isShowStudentGroupButtom = resultGroup.length > 0 ? true : false;
				this.resultGroupStr = resultGroup.join(',');
			},
			onClick(name, title) {
				console.log(name, title)
				// this.tab_list=[]
				switch (name) {
					case 0:
						this.getActivitySignupLists()
						break;
					case 1:
						this.getActivitySigninLists()
						break;
					case 2:
						this.getActivitySigninTutorLists()
						break;
					case 3:
						this.getActivitySigninCertsLists()
					break;
				}
			},
			getActivitySigninCertsLists(){
				getActivitySigninCertsLists({
					act_id: this.$route.query.act_id,
					act_type: this.$route.query.act_type
				}).then(res => {
					if (res.code == 1) {
						this.list1 = res.data
						this.tab_list = this.list1.list
					} else {
						this.tab_list = []
						this.list1 = []
					}
				})
			},
			// 培训详情的学员报名数据
			getActivitySignupLists() {
				getActivitySignupLists({
					act_id: this.$route.query.act_id,
					act_type: this.$route.query.act_type
				}).then(res => {
					if (res.code == 1) {
						this.list1 = res.data
						this.tab_list = this.list1.list
					} else {
						this.tab_list = []
						this.list1 = []
					}

				})
			},
			// 培训详情的学员签到列表
			getActivitySigninLists() {
				getActivitySigninLists({
					act_id: this.$route.query.act_id,
					act_type: this.$route.query.act_type
				}).then(res => {
					if (res.code == 1) {
						this.list1 = res.data
						this.tab_list = this.list1.list
					} else {
						this.tab_list = []
						this.list1 = []
					}
				})
			},
			// 培训详情的助教导师签到列表
			getActivitySigninTutorLists() {
				getActivitySigninTutorLists({
					act_id: this.$route.query.act_id,
					act_type: this.$route.query.act_type
				}).then(res => {
					if (res.code == 1) {
						this.list1 = res.data
						this.tab_list = this.list1.list
					} else {
						this.tab_list = []
						this.list1 = []
					}
				})
			},
			// 培训详情的助教导师报名列表
			getActivitySignupTutorLists() {
				getActivitySignupTutorLists({
					act_id: this.$route.query.act_id,
					act_type: this.$route.query.act_type
				}).then(res => {
					if (res.code == 1) {
						this.list1 = res.data
						this.tab_list = this.list1.list
					} else {
						this.tab_list = []
						this.list1 = []
					}

				})
			},
			stateClick(i) {
				if (this.tab_list[i].state) {
					this.$router.push({
						name: 'userCertificate',
						query: {
							id: 1
						}
					})
				}
			},
			editClick() {
				console.log(this.conData)
				this.$router.push({
					name: 'setTrain',
					query: {
						act_id: this.act_id,
						act_type: 2,
						count:this.list1.count
					}
				})
			},
			// 跳转考核页面
			kao(item){
				if(this.conData.act_status==1){
					this.$router.push({name:'tutorInAassessment',query:{train_course:this.conData.train_course,sign_name:item.sign_name,course:this.conData.course,sign_id:item.user_id,act_id:this.$route.query.act_id}})
				}else{
					alert('活动已结束')
				}
				
			},
			Success() {},
			showPopup() {
				this.show = true;
			},
			// 去除空白数组下标
			skipEmptyElementForArray(arr) {
				var a = [];
				for (let i = 0; i < arr.length; i++) {
					if (arr[i]) {
						a.push(arr[i])
					}
				}
				return a;
			},
			// courseList
			// courseList_kao
			// 先获得课程。避免课程慢得到
			getOfficialCourse() {
				getOfficialCourse({
					act_type: 2
				}).then(resd => {
					const list = resd.data
					getActivity({
						act_id: this.act_id
					}).then((res) => {
						this.conData = res.data
						console.log(this.conData)
						// 培训课程
						// let list1=this.skipEmptyElementForArray(this.conData.train_course.split('|'))
						let list1 = this.conData.train_course
						// 考核课程
						let list2 = this.conData.course
						for (let i = 0; i < list.length; i++) {
							// 先判断是否有选中的培训课程
							for (let j = 0; j < list1.length; j++) {
								if (list[i].id == list1[j]) {
									this.courseList.push(list[i].name)
								}
							}
							for (let j = 0; j < list[i].course.length; j++) {
								for (let q = 0; q < list2.length; q++) {

									if (list[i].course[j].id == list2[q]) {
										this.courseList_kao.push(list[i].course[j].name)
									}
								}
							}


						}
					})
				})
			},
			// 发证
			stateClick(i,item) {
				console.log(item)
				if(item==0){
					alert('未考核')
					
					return
				}else{
					if (this.tab_list.is_certs) {
					  this.$router.push({
					    name: 'emUserCertificate',
					    query: {
					      user_id: this.tab_list.user_id,
								act_id: this.$route.query.act_id,
								act_type:this.tab_list.act_type,
								user_name:this.tab_list.sign_name,
								train_course:this.conData.train_course
					    } 
					  })
					}else{
							  this.$router.push({
							    name: 'confirmation_card',
							    query: {
									user_id: this.tab_list[i].user_id,
									act_id: this.$route.query.act_id,
									act_type:this.tab_list[i].act_type,
									user_name:this.tab_list[i].sign_name,
									train_course:this.conData.train_course
							    }
							  })
					}
				}
				}
		},
		mounted() {
			document.title = this.title;
			this.act_id = this.$route.query.act_id
			this.getOfficialCourse()

			this.getActivitySignupLists()


		}
	}
</script>

<style lang="scss">
	// .van-divider::after,
	// .van-divider::before {
	// 	border-width: 2px 0 0;
	// }

	.traindal {
		padding-bottom: 30px;

		.van-popup--center.van-popup--round {
			border-radius: 6px;
			text-align: left;
		}

		.van-tab--active {
			transform: scale(1.1);
		}

		.fontWeight {
			font-weight: bold;
		}

		.popupBox {
			width: 300px;
			height: 11.875rem;
			background-color: #fff;
			border-radius: 8px;
			padding: 0 20px 28px;

			.title {
				padding: 42px 0;
				line-height: 23px;
			}

			.btnBox {
				justify-content: flex-end;

				div:first-child {
					margin-right: 40px;
				}

				.suss {
					color: #0081FF;
				}
			}
		}

		.bottom {
			position: fixed;
			bottom: 0;
			left: 0;
			background: #F4F4F4;
			width: 100%;
			height: 30px;
			font-size: 12px;
			color: #28334A;
			line-height: 30px;
			text-align: left;
			padding-left: 15px;
			    z-index: 2;
		}

		.bot-flex {
			display: flex;
			justify-content: space-between;
			align-items:center;
		}

		.van-tabs__line {
			background-color: #0081FF;
			width: 1.125rem !important;
		}

		.van-tabs__content {
			background-color: #fff;
		}

		.van-tab__text {
			font-weight: bold;
			font-size: 15px;
		}

		.List_row {
			padding: 16px 0;
			margin: 0 15px;
			border-bottom: 1px solid #E5E5E5;
			color: #767E8D;
		}
		.list_row_row {
			display:flex;
			justify-content: space-between;
			align-items: center;
		}

		.imgBox {
			// position: absolute;
			// top: 0;
			// left: 0;
			// height: 200px;
			// width: 100%;
			z-index: -1;
			position: absolute;
			top: 0;
		}

		.list {
			flex-wrap: inherit;
			padding: 15px;
			padding-top: 0;

			.list_img {
				margin-right: 0.71875rem;
				position: relative;
			}

			.list_img:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 6px;
				right: 0;
				width: 1.375rem;
				height: 1.25rem;
				background: no-repeat center;
				background-size: 100% 100%;
			}

			.list_img.list_img_1:after {

				background-image: url(../../assets/Train/icon_1@2x.png);

			}

			.list_img.list_img_2:after {

				background-image: url(../../assets/Train/icon_2@2x.png);

			}

			.list_img.list_img_3:after {

				background-image: url(../../assets/Train/icon_3@2x.png);

			}


			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}

			.text {
				font-size: 0.75rem;
				color: #919CB0;
				// margin-left: 0.5rem;
			}

			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}

			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}

			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}

			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}

			.btnimg_span {
				margin-right: -5px;

			}
		}

		.container1 {
			// margin: 15px;
			background-color: #fff;
			// height: 280px;
			z-index: 2;
			border-radius: 0.375rem;
			text-align: left;
			position: relative;
			width: 100%;

			// position: absolute;
			// top: 15px;
			// left: 50%;
			// transform: translateX(-50%);
			// margin-top: -50px;
			padding-bottom: 10px;

			// margin-bottom: 15px;
			.header {
				text-align: left;
				font-size: 1.125rem;
				font-weight: bold;
				padding-top: 20px;
			}

			.header_title {
				font-weight: bold;
				font-size: 17px;
				padding: 15px 11px;

			}

			.conData {
				// padding: 28px 0px;
				font-size: 14px;

			}

			.cost {
				color: #0081FF;
			}

			.title {
				color: #919CB0;
			}

			.van-row {
				margin: 14px 0;
				padding: 0 15px;
			}

			.dashed {
				position: relative;

				// &::after {
				// 	position: absolute;
				// 	top: -8px;
				// 	left: 0px;
				// 	width: 8px;
				// 	height: 16px;
				// 	content: '';
				// 	display: block;
				// 	background: url(../../assets/Train/bg_yuan_left@2x.png) no-repeat center;
				// 	background-size: 100% 100%;
				// }

				// &::before {
				// 	position: absolute;
				// 	top: -8px;
				// 	right: 0px;
				// 	width: 8px;
				// 	height: 16px;
				// 	content: '';
				// 	display: block;
				// 	background: url(../../assets/Train/bg_yuan_right@2x.png) no-repeat center;
				// 	background-size: 100% 100%;
				// }
			}
		}

		.fu {
			padding: 15px;
			color: #919CB0;

			img {
				width: 0.75rem;
				height: 0.75rem;
				margin-right: 0.5625rem;
			}
		}

		.cancel {
			background-color: #FFFFFF;
			border: 1px solid #0081FF;
			color: #0081FF;
			font-size: 15px;
		}

		.zhi {
			margin-left: 17px;
			color: #919CB0;
			font-size: 15px;
		}

		.btn {
			border: 1px solid #0081FF;
			width: 8.75rem;
			height: 40px;
			color: #0081FF;
			font-size: 15px;
			text-align: center;
			line-height: 38px;
			border-radius: 50px;
		}
		.img{
			width: 50%;
			margin: 0 auto;
		}
		.kaobtn{
			width:70px;
			height:26px;
			background:rgba(255,255,255,1);
			border:1px solid rgba(0,129,255,1);
			border-radius:13px;
			margin: 0;
			text-align: center;
			float: right;
			line-height: 24px;
			font-size:12px;
			font-family:PingFang SC;
			font-weight:500;
			color:rgba(0,129,255,1);
		}
		.kaobtn_Act{
			background:rgba(0,217,126,.11);
			border:1px solid transparent;
			font-size:12px;
			font-family:PingFang SC;
			font-weight:500;
			color:rgba(0,217,126,1);
		}
	}
</style>
