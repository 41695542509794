<template>
	<div class="setHead">
		<div class="headImg">
			<img :src="imgsrc" alt="" style="object-fit: contain;" class="auto-img" v-if="!visible">
			<!-- <div class="mask"></div> -->
			<image-clipper ref="clipper" v-if="visible" :img="imgsrc1" :clipper-img-width="100" :clipper-img-height="100" @ok="sure"
			 @loadError="loadError" @loadComplete="loadComplete"></image-clipper>

		</div>
		<div class="bottom" v-if="!visible">
			<div class="btn" @click.stop="uploadHeadImg"  v-if="!over">
				<div class="caption" v-if="!over">更改头像</div>
			</div>
			<div class="btn" @click="sure" v-if="over">
				<div class="caption" >完成上传</div>
			</div>
		</div>
		<div class="loading" v-if="showLoading">
			<van-loading size="24px" vertical color="#72A0EF" text-color="#72A0EF"> 上传中...</van-loading>
		</div>
		<h5-cropper class="hiddenInput" :option="option" @getbase64Data="getbase64Data" @getblobData="getblobData" @getFile="getFile"></h5-cropper>
	</div>
</template>

<script>
	// beforeLoad	no	图片加载前触发
	// loadSuccess	no	图片加载成功触发
	// loadError	no	图片加载失败触发
	// loadComplete	no	图片加载完成触发
	// import imageClipper from '@/components/imageClipper.vue';
	import {
		postUserHeadImg
	} from '@/api/index.js'
	import { Toast } from 'vant';
	// import vConsole from '@/util/vconsole.js'
	export default {
		name: 'setHead',
		data() {
			return {
				visible: false,
				option:{
					fixedBox:false,
					centerBox:false
				},
				over:false,
				imgsrc: '',
				imgsrc1: '',
				showLoading: false
			}
		},
		components: {
			// imageClipper
		},
		methods: {
			getbase64Data(data) {
			      this.imgsrc = data;
				  this.over=true
			    },
			getblobData(){},
			getFile(){},
			loadComplete(e) {
				console.log(e)
			},
			loadError(e) {
				console.log(e)
			},
			upLoad() {
				// 触发上传图片按钮
				this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
			},
			sure() {
				let _this=this
				let data = this.imgsrc
				console.log( this.imgsrc)
				// let imgUrl = this.$refs.clipper.getBase64(data);
				// var myform = new FormData();
				// myform.append('imgurl',data)
				// myform.append('token',localStorage.getItem('token'))
			
			// 	this.$axios({
			// 	    method: 'post',
			// 	    url: '/gzh/backend/public/index.php/api/user/postUserHeadImg',
			// 	    data:myform
			// 	})
			// 	.then(function (response) {
			// 	    console.log(response);
			// 	})
			// 	.catch(function (error) {
			// 	    console.log(error);
			// 	});
				this.showLoading = true
				postUserHeadImg({
						imgurl: data
					})
					.then(res => {
						console.log(res)
						if (res.code==1) {
							this.showLoading = false
							_this.$router.go(-1)
							Toast.success(res.msg);
							
						} else {
							Toast.fail(res.msg);
						}

					}).catch(e => {
						Toast.fail('上传失败');
						this.showLoading = false
					})
			},
			// 打开图片上传
			uploadHeadImg: function() {
				this.$el.querySelector('.upbtn>.upbtn').click()
				// this.visible = true
				// this.imgsrc1 = 'http://img1.imgtn.bdimg.com/it/u=293643936,2020316857&fm=26&gp=0.jpg'
				// console.log(this.imgsrc1, '父组件')
			},
			// 将头像显示
			handleFile: function(e) {
				let $target = e.target || e.srcElement
				let file = $target.files[0]
				var reader = new FileReader()
				reader.onload = (data) => {
					let res = data.target || data.srcElement
					this.imgsrc1 = res.result

					this.visible = true
				}
				reader.readAsDataURL(file)
			},
		},
		mounted() {
			this.imgsrc = this.$route.query.type1;
			document.title = '头像'
		}
	}
</script>
<style lang="scss" scoped>
	.setHead {
		width: 100%;
		background-color: #000;
		min-height: 100vh;
		position: relative;
		padding-bottom: 82px;

		.headImg {
			padding-bottom: 82px;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		.mask {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, .5);
		}

		.loading {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		
	}
</style>
