<template>
	<div class="goodBookDetails">
		<div class="GoodBook_Content">
			<img src="../../assets/user/pic_top@2x.png" alt="" class="auto-img">
			<div class="GoodBook_ContentI">
				<p class="title">现场急救教学用书-《现场急救行动 指南》</p>
				<img src="../../assets/user/pic_01@2x.png" alt="">
				<div class="del">
					<!-- <p>主编：付杰</p> -->
					<p>定价：86.00元</p>
					<!-- <p class="fen1">推广优惠价：68元/本（包邮）</p> -->
					<p>出版时间：2020年3月</p>
					<p>现场急救科普工作室   编著</p>
				</div>
				<div class="titleImg">
					<img src="../../assets/user/pic_titliecsbj@2x.png" alt="">
				</div>
				<p class="p">
					目前,中国每年有超过54万人出现心脏骤停,相当于大约每1分钟就有人因为心脏骤停而突然倒下。日常生活中,各种急症、意外伤害和灾害经常突然发生,在家中,在路途中、在工作场所和公共场所,在你我身边。紧急情況下,我们该怎么办?是束手无策、不懂施救,还是惊慌失措、错误施救?为此,我们必须人人学习急救,为个人、为家庭、为社会,为了让世界充满爱
				</p class="p">
				<div class="titleImg">
					<img src="../../assets/user/pic_titlienrjj@2x.png" alt="">
				</div>
				<p class="p">
					我国54家急救中心的72名急救医学培训专家联合编写了《现场急救行动指南》,这本书对心脏骤停、气道异物梗阻、常见急症、创伤、中毒、动物咬伤等情况下如何进行正确的现场处置,以及灾害时如何自救、互救进行了深入浅出和图文并茂的介绍,为人们了解、学习现场急救知识提供了正确、实用的参考书,也为开展急救知识普及培训工作提供了良好的宣传、培训资料
				</p>
				<img src="../../assets/user/pic_02@2x.png" alt="">
				<img src="../../assets/user/pic_03@2x.png" alt="">
				<img src="../../assets/user/pic_04@2x.png" alt="">
				<img src="../../assets/user/pic_05@2x.png" alt="">
				<img src="../../assets/user/pic_06@2x.png" alt="">
				<img src="../../assets/user/pic_07@2x.png" alt="">
				<img src="../../assets/user/pic_08@2x.png" alt="">
				<img src="../../assets/user/pic_09@2x.png" alt="">
				<div class="titleImg">
					<img src="../../assets/user/pic_titlieyzsd@2x.png" alt="">
				</div>
				<img src="../../assets/user/pic_10@2x.png" alt="">
				<img src="../../assets/user/pic_11@2x.png" alt="">
				<img src="../../assets/user/pic_12@2x.png" alt="">
				<img src="../../assets/user/pic_13@2x.png" alt="">
				<p class="p">
					本书微信公众号已经上线，一书一个注册号，可观看全部教学视频。
				</p>
				<img src="../../assets/user/pic_141@2x.png" alt="">
				<p class="p">
					观看完全部视频，并且正确答题后，可以获得如下电子证书：
				</p>
				<img src="../../assets/user/pic_16@2x.png" alt="">
				<img src="../../assets/user/pic_17@2x.png" alt="">
				<img src="../../assets/user/pic_14@2x.png" alt="">
				
			</div>
		</div>
		<!-- 底部 -->
		<div class="bottom" >
			<van-stepper v-model="value" />
			<div class="btn1" @click="$router.push({name:'goodBookOrder',query:{orderNumebr:value,tips:$route.query.tips,uniqid:$route.query.uniqid}})">
				立即购买
			</div>
			<!-- <div class="btn1" @click="$router.push({name:'404'})">
				立即购买
			</div> -->
		</div>
		<van-overlay :show="primaryShow" style="text-align: center;">
			<div class="flex" style="justify-content: flex-end;margin: 15px 30px;"><img src="../../assets/user/icon_zhishi@2x.png"
				 alt="" width="80" style="">
			</div>
			<div class="flex bottomBox_content_fen">
				<img src="../../assets/user/pic_xfengm80@2x.png" alt="" width="40">
				<div>好书推荐《现场急救行动指南》</div>
		
			</div>
			<div class="text1">立即分享给好友吧</div>
			<div class="text2">点击屏幕右上角将页面分享给好友</div>
			<div style="margin-top:60px;" @click="primaryShow = false">
				<img src="../../assets/user/hstj_icon_close@2x.png" alt="" width="32">
			</div>
		</van-overlay>
	</div>
</template>

<script>
import { getSign  } from '@/api';
import { Toast } from 'vant'
import areaList from '../../area.js'

export default {
  name: 'goodBookDetails',
  components: {
  },
  data(){
	  return{
		  value:0,
		show:false,
		primaryShow:false
	  }
  },

  methods:{
	  shareClick(){
		  this.show=false
		  this.primaryShow=true
	  }
  },
  mounted() {
	  
	  	document.title ='好书推荐《现场急救行动指南》';  
		 this.$wx.ready(res => {  
			 this.$wx.updateAppMessageShareData({
			 	title: '好书推荐《现场急救行动指南》', // 分享标题
			 	desc: '好书推荐《现场急救行动指南》', // 分享描述
			 	link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			 	imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
			 	success: function(resq) {
			 		// 设置成功
			 	},
			 	error: function(resw) {
			 		alert(JSON.stringify(resw))
			 	}
			 })
			 // 分享朋友圈
			 this.$wx.updateTimelineShareData({
			 	title: '好书推荐《现场急救行动指南》', // 分享标题
			 	link:location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			 	imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
			 	success: function() {
			 		// 设置成功
			 	}
			 });
		 })
		
		console.log(this.$route.query)
		if(this.$route.query.from && localStorage.getItem('user_type')==1){
			this.primaryShow = false
		}else if(JSON.stringify(this.$route.query)=='{}'&& localStorage.getItem('user_type')==1){
			this.primaryShow = false
		}else if(localStorage.getItem('user_type')!=1){
			this.primaryShow = true
		}
  }
}
</script>
<style lang="scss">

	.goodBookDetails{
		width: 100%;
		// height: 100vh;
		background-color: #F7F8FF;
		padding-bottom: 50px;
		.GoodBook_Content{
			background:rgba(251,244,247,1);
			.title{
				font-size:21px;
				font-family:PingFang SC;
				font-weight:bold;
				color:rgba(13,33,56,1);
				line-height:30px;
				margin-bottom: 50px;
				
			}
		}
		.del{
			height:181px;
			background:rgba(255,255,255,1);
			display: flex;
			flex-flow: column;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 32px;
			padding: 32px 0;
		}
		.fen1{
			color: #FF524D;
		}
		.GoodBook_ContentI{
			text-align: center;
			padding: 15px;
			img{
				width: 100%;
				margin-bottom: 20px;
			}
			.titleImg{
				width: 40%;
				margin: 0 auto;
			}
			.p{
				font-size:17px;
				font-family:PingFang SC;
				font-weight:bold;
				color:rgba(21,100,250,1);
				line-height:25px;
				margin-bottom: 30px;
				text-indent:25px;
				text-align: justify;
			}
		}
		.bottom{
			height: 50px;
			line-height: 50px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 15px;
			box-shadow:0px -6px 10px 0px rgba(145,156,176,0.1);
			.van-stepper__minus, .van-stepper__plus{
				width: 32px;
				height: 32px;
				line-height: 32px;
			}
			.van-stepper__input{
				width: 40px;
				height: 32px;
				    line-height: 32px;
			}
			.btn1{
				width: 120px;
				height: 36px;
				line-height: 36px;
				background:rgba(0,129,255,1);
				border-radius:18px;
				font-size:15px;
				font-family:PingFang SC;
				font-weight:500;
				color:rgba(255,255,255,1);
				line-height:36px;
			}
		}
		.bottomBox_content_fen {
			width: 84%;
			margin: 15px auto;
			height: 60px;
			background: rgba(255, 255, 255, 1);
			border-radius: 6px;
			padding: 0 12px;
		
			img {
				margin-right: 12px;
			}
		}
		
		.fen {
			width: 100%;
			height: 1px;
			background: rgba(211, 210, 209, 1);
			margin: 28px 0 20px;
		}
		.GoodBook_Content_zhu {
			padding: 10px;
			background: rgba(245, 245, 245, 1);
			border-radius: 3px;
			margin-top: 7px;
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(40, 51, 74, 1);
			line-height: 20px;
		
			.zhu {
				margin-bottom: 10px;
			}
		
			.GoodBook_Content_zhu_content {
				align-items: inherit;
				margin-bottom: 5px
			}
		}
		
		.zhutuImg {
			margin: 20px 0 0px;
			padding-bottom: 15px;
		}
		
		.fen {
			margin: 16px 0;
			width: 100%;
			height: 1px;
			background: rgba(229, 229, 229, 1);
		}
		
		.recommendationBox {
			height: 50px;
			background: rgba(0, 129, 255, 0.05);
			color: #28334A;
			border-radius: 6px;
			line-height: 50px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}
		
		.text1 {
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: bold;
			color: rgba(255, 255, 255, 1);
			line-height: 24px;
		}
		
		.text2 {
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(255, 255, 255, 1);
			line-height: 24px;
		}
	}
	
	
</style>
