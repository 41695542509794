<template>
	<div class="userSink">
		<div class="userSink_flex" :style="active==2?'border-bottom:1px solid #f4f4f4':''">
			<van-tabs v-model="active" style="padding: 0 60px;">
				<van-tab title="我的发布"></van-tab>
				<van-tab title="我的收藏"></van-tab>
				<van-tab title="我的关注"></van-tab>
			</van-tabs>
		</div>
		<div class="bigBox" v-if="active==0">
			<defaultVue v-if="!Article_parameter.count"
						:defaultImg="require('@/assets/default/icon_zanwufbwz.png')"
						defaultText="暂无发布文章"
			></defaultVue>
			<van-pull-refresh v-model="Article_parameter.refreshing" @refresh="Article_parameter_onRefresh">
			<van-list
			  v-model="Article_parameter.loading"
			  :finished="Article_parameter.finished"
			  finished-text="没有更多了"
			  @load="onLoad"
			v-if="Article_parameter.count">
				<div v-for="(item,i) in list" :key="i" style="margin-top: 10px;" @click="$router.push({name:'viewArticles',query: { id: item.id}})" v-if="count">
					<ArticllList :item="item" @showClick="showClick(i,item.id)" :isuserList="1" @zanClick="zanClick(i)"> </ArticllList>
				</div>
			</van-list>
			</van-pull-refresh>
		</div>
		
		<div class="bigBox" v-if="active==1">
			<defaultVue v-if="!Collection_parameter.count"
						:defaultImg="require('@/assets/default/icon_zanwufbwz.png')"
						defaultText="暂无收藏文章"
			></defaultVue>
			<van-pull-refresh v-model="Collection_parameter.refreshing" @refresh="Collection_parameter_onRefresh">
			<van-list
			  v-model="Collection_parameter.loading"
			  :finished="Collection_parameter.finished"
			  finished-text="没有更多了"
			  @load="onLoad1"
			v-if="Collection_parameter.count">
				<div v-for="(item,i) in collectionList" :key="i" style="margin-top: 10px;" @click="$router.push({name:'viewArticles',query: { id: item.id}})">
					<ArticllListSc :item="item" :isuserList="2" @zanClick="zanClick(i)" @followClick="followClickSc(i,active)"> </ArticllListSc>
				</div>
			</van-list>
			</van-pull-refresh>
		</div>
		
		<div class="bigBox" v-if="active==2" style="background-color: #fff;">
			<defaultVue v-if="!follow_parameter.count"
						:defaultImg="require('@/assets/default/icon_wodegz@2x.png')"
						defaultText="暂无关注"
			></defaultVue>
			<van-pull-refresh v-model="follow_parameter.refreshing" @refresh="onRefresh">
			<van-list v-model="follow_parameter.loading" :finished="follow_parameter.finished" finished-text=" " @load="onLoad2">
				<van-cell v-for="(item,index) in followList" :key="index" class="cancellBox">
					<div slot="default" class="flex list" @click="homepageClick(item)">
						<div class="list_img list_img_pai " :class="['list_img_'+item.ranking]" >
							<van-image width="60" height="60" :src="item.user_head_img" radius='50%' />
			
						</div>
						<div style="width: 100%;">
							<div class="list_title">
								<span v-text="item.user_name" style="font-size: 15px;"></span>
								<span v-text="item.qualifications" class="ellipse identity" style="font-size:12px;color:rgba(145,156,176,1);margin-left: 12px;"></span>
							</div>
							<div v-text="item.user_signature" style="font-size: 12px;">
			
							</div>
							<div class="flex" style="    justify-content: space-between;">
								<!-- 标题下左右边两个div -->
								<div>
			
									<div class="flex" >
										<img src="../../assets/Train/icon_xjigou@2x.png" alt="" style="width: 12px;height: 12px;">
										<div v-text="item.institution" class="text"></div>
									</div>
									<div class="flex">
										<div class="flex" style="margin-right: 1.875rem;">
											<img src="../../assets/Train/icon_xdizhi@2x.png" alt="" style="width: 12px;height: :;px;">
											<div v-text="item.city" class="text"></div>
										</div>
										<div class="flex">
											<img src="../../assets/Train/icon_guanzhu@2x.png" alt="" style="width: 12px;height: 12px;">
											<div v-text="item.fans_num+'人关注'" class="text"></div>
										</div>
									</div>
								</div>
								<div class="followBtn" :class="item.isfollow?'':'no'"> <span v-text="item.isfollow?'已关注':'+ 关注'" @click.stop="followClick(index)"></span></div>
							</div>
						</div>
					</div>
					<div style="font-size: 14px;" v-if="item.last_activity.act_status">
						<van-row>
						  <van-col span="6" style="color:#919CB0;">【<span v-text="item.last_activity.act_status==1?'报名中':item.last_activity.act_status==2?'已结束':item.last_activity.act_status==3?'已下线':item.last_activity.act_status==4?'已反馈':''"></span>】</van-col>
						  <van-col span="17" style="margin-left: -5px;"> <div class="flex"><span>【</span> <span class="van-ellipsis" style="max-width: 85%;" v-text="item.last_activity.act_name"></span> <span>】</span></div></van-col>
						</van-row>
					</div>
					
					<!-- <div class="flex">
						<div style="width:25%;"></div>
						<div class="van-ellipsis" style="width: 70%;">【这是一段最多显示一行的文字，多余的内容会被省略】</div>
					</div> -->
				</van-cell>
			</van-list>
			</van-pull-refresh>
		</div>
		<div class="dian">
			<van-popup v-model="show">
				<div class="popupBox" @click="$router.push({name:'FBArticle_xiu',query:{ type:modifyType,id:modifyId}})">
					<img src="../../assets/bigSink/icon_bianji@2x.png" alt="">
					<span>编辑这篇文章</span>
				</div>
				<div class="popupBox" @click="delShow=true">
					<img src="../../assets/bigSink/icon_shanchu@2x.png" alt="">
					<span>删除这篇文章</span>
				</div>
			</van-popup>
		</div>
		<div class="delBox">
			<van-popup v-model="delShow">
				<div class="flex" style="width: 100%;height: 100%;flex-flow: column;justify-content: space-around;">
					<div style="font-size: 18px;font-weight: bold;">删除文章</div>
					<div>您确定要删除本篇文章？</div>
					<div style="    text-align: right; width: 100%;">
						<span class="hui" style="margin-right: 41px;" @click.stop="delShow=false">取消</span>
						<span class="blue" style="margin-right: 34px;" @click="DetermineClick">确定</span>
					</div>
				</div>
			</van-popup>
		</div>
	</div>
</template>

<script>
import { getUserWeixinAidHotArticleList,getBanner,myFollowing,postFollow ,getWeixinAidHotArticleUserCollectList,postWeixinAidHotArticleDel,postWeixinAidHotArticlePraise  } from '@/api'
	import { Toast } from 'vant';
	import defaultVue   from '@/components/default/index.vue'
import ArticllList from '@/components/Articll_list.vue'
import ArticllListSc from '@/components/Articll_listSc.vue'
	export default {
  name: 'userSink',
  components: {
	  ArticllList,defaultVue,ArticllListSc
  },
  data(){
	  return{
		  // 下拉刷新
		    refreshing: false,
		  // 编辑文章或者分享
		  modifyType:1,
		  // 编辑文章的id
		  modifyId:1,
		collectionList:[],
		isfollow:true,
		/*
		* 发布查询
		*/
	   Article_parameter:{
		   limit:5,
		   page:0,
		   loading:false,
		   finished:false,
		   count:1,
		   refreshing:false
	   },
	   Collection_parameter:{
	   		   limit:5,
	   		   page:0,
	   		   loading:false,
	   		   finished:false,
			   count:1,
			   refreshing:false
	   },
	   follow_parameter:{
		   limit:5,
		   page:0,
		   loading:false,
		   finished:false,
		   count:1,
		   refreshing:false
	   },
		
		delShow:false,
		show:false,
		index:0,
		images: [
		'https://img.yzcdn.cn/vant/apple-1.jpg',
		'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		active:0,
		list:[],
		followList:[],
		// 文章id
		articleids:0,
		count:1
	  }
  },
  watch:{
	  /**
	   * 监听tab栏值的变化，改变数据
	   * */
	  active(){
		this.page=0
		this.count=1
		this.loading=false
		this.finished=false
		
		// this.onLoad()
	  }
  },
  methods:{
	  Article_parameter_onRefresh(){
		  // 清空列表数据
		  this.list=[]
		  this.Article_parameter.finished = false;
		  this.Article_parameter.page=0
		  // 重新加载数据
		  // 将 loading 设置为 true，表示处于加载状态
		  this.Article_parameter.loading = true;
		  this.onLoad();
	  },
	  Collection_parameter_onRefresh(){
		  // 清空列表数据
		  this.collectionList=[]
		  this.Collection_parameter.finished = false;
		  this.Collection_parameter.page=0
		  // 重新加载数据
		  // 将 loading 设置为 true，表示处于加载状态
		  this.Collection_parameter.loading = true;
		  this.onLoad1();
	  },
	  
	  // 下拉刷新
	  onRefresh() {
	        // 清空列表数据
			this.followList=[]
	        this.follow_parameter.finished = false;
			this.follow_parameter.page=0
	        // 重新加载数据
	        // 将 loading 设置为 true，表示处于加载状态
	        this.follow_parameter.loading = true;
	        this.onLoad2();
	      },
	  // 点赞功能
	  zanClick(i){
		switch (this.active){
			case 0:
			let zanType=this.list[i].is_praise==0?1:2
				postWeixinAidHotArticlePraise({hot_article_id:this.list[i].id,type:zanType}).then(res=>{
					if(res.code==1&&zanType==1){
						Toast('点赞成功')
						this.list[i].is_praise=1
						this.list[i].praise_num=this.list[i].praise_num+1
						}else if(res.code==1&&zanType==2){
							Toast('取消点赞成功')
							this.list[i].is_praise=0
							this.list[i].praise_num=this.list[i].praise_num-1
						}else{
							Toast(res.msg)
						}
				})
			break;
			case 1:
			let collectionListZanType=this.collectionList[i].is_praise==0?1:2
				postWeixinAidHotArticlePraise({hot_article_id:this.collectionList[i].articleinfo.id,type:collectionListZanType}).then(res=>{
					
					if(res.code==1&&collectionListZanType==1){
						Toast('点赞成功')
						this.collectionList[i].is_praise=1
						this.collectionList[i].praise_num=this.collectionList[i].praise_num+1
						}else if(res.code==1&&collectionListZanType==2){
							Toast('取消点赞成功')
							this.collectionList[i].is_praise=0
							this.collectionList[i].praise_num=this.collectionList[i].praise_num-1
						}else{
							Toast(res.msg)
						}
				})
			break;
		}
	},
	  // 删除文章
	 DetermineClick(){
		 postWeixinAidHotArticleDel({hot_article_id:this.articleids}).then(res=>{
			if(res.code==1){
				Toast(res.msg)
				this.delShow=false
				this.show=false
				this.list.splice(this.index,1)
				 getUserWeixinAidHotArticleList({page:this.page,limit:this.limit}).then(res=>{
					 this.count=res.data.count
				 })
			}
		 })
	 },
	  followClickSc(i){
			let isfollow=!this.collectionList[i].is_follow
			let list=this.collectionList
			postFollow({tutor_id:this.collectionList[i].userinfo.user_id,is_follow:isfollow?1:0}).then(res=>{
				// console.log(res)
				Toast(res.msg);
				if(res.code==1&&isfollow==1){
					console.log(this.collectionList[i].is_follow,'关注成功')
					list[i].is_follow=1
					this.collectionList=[]
					this.collectionList=list
				}else if(res.code==1&&isfollow==0){
					console.log(this.collectionList[i].is_follow,'取消成功')
					list[i].is_follow=0
					this.collectionList=[]
					this.collectionList=list
					
				}
			})
	  },
	  followClick(i){
	  			let isfollow=!this.followList[i].isfollow
				let list=this.followList
	  			postFollow({tutor_id:this.followList[i].tutor_user_id,is_follow:isfollow?1:0}).then(res=>{
	  			// console.log(res)
	  			if(res.code==1){
	  				this.follow_parameter.count=res.data.count
	  				list[i].isfollow=isfollow
					this.followList=[]
					this.followList=list
	  				Toast(res.msg);
	  			}
	  			})
	  },
	  // 跳转导师主页
	  homepageClick(item){
	  		  console.log(item)
	  		  // if(item.user_type>1){
	  			  this.$router.push({name:'tutorHomepage',query:{id:item.tutor_user_id,tabAct:1}})
	  		  // }
	  },
	  //我的关注列表点击右上角三个点
	 showClick(i,ids){
		this.show=true
		this.index=i
		this.articleids=ids
		this.modifyType=this.list[i].type
		this.modifyId=this.list[i].id
		// console.log(this.list[i].type)
	 },
	 // 我的关注 
	 myFollowing(issecr,obj){
			// console.log(obj)
			myFollowing(obj).then(res=>{
			  this.follow_parameter.count=res.data.count;
			  this.follow_parameter.loading = false;
			  res.data.list.forEach(item=>{
				  item.isfollow=true
			  })
			  this.follow_parameter.refreshing=false
			  this.followList=res.data.list
			  // 数据全部加载完成
			  if (this.followList.length >= this.follow_parameter.count ) {
				this.follow_parameter.finished = true;
			  }
			})		  			 
	 },
	 getUserWeixinAidHotArticleList(obj){
		 getUserWeixinAidHotArticleList(obj).then(res=>{
			this.list.push(...res.data.list)
			this.Article_parameter.count=res.data.count
			// 加载状态结束
			this.Article_parameter.loading = false;
			this.Article_parameter.refreshing=false
			// 数据全部加载完成
			if (this.list.length >= this.Article_parameter.count ) {
				this.Article_parameter.finished = true;
			}
		 })
	 },
	 onLoad(){
		 // if(){
			 
		 // }
			this.Article_parameter.page++
			this.getUserWeixinAidHotArticleList({page:this.Article_parameter.page,limit:this.Article_parameter.limit})
		
	},
	onLoad1(){
		console.log('123')
			this.Collection_parameter.page++
			this.getWeixinAidHotArticleUserCollectList({page:this.Collection_parameter.page,limit:this.Collection_parameter.limit})
		
	},
	onLoad2(){
			this.follow_parameter.page++
			this.myFollowing({page:this.follow_parameter.page,limit:this.follow_parameter.limit})
		
	},
	 getWeixinAidHotArticleUserCollectList(obj){
		getWeixinAidHotArticleUserCollectList(obj).then(res=>{
			this.collectionList.push(...res.data.list)
			this.Collection_parameter.count=res.data.count
			// 加载状态结束
			this.Collection_parameter.loading = false;
			this.Collection_parameter.refreshing=false
			// 数据全部加载完成
			if (this.collectionList.length >= this.Collection_parameter.count ) {
				this.Collection_parameter.finished = true;
			}
		})		  			 
	 },
  },
  mounted() {
  	document.title = '我的';  
	 this.onLoad1()
	 this.onLoad2()
  }
}
</script>
<style lang="scss" type="text/css">
	.userSink {
		min-height: 100vh;
		background-color: #F7F8FF;
		padding-top: 68px;
		.followBtn{
			border: 1px solid #0081FF;
			color: #0081FF;
			border-radius: 20px;
			width: 3.4375rem;
			height: 1.625rem;
			text-align: center;
			line-height: 1.5rem;
			font-size: 0.6875rem;
		}
		.followBtn.no{
			background-color: #0081FF;
			color: #fff;
			border: none;
			line-height: 26px;
		}
		.delBox {
			.van-popup {
				width: 300px;
				height: 190px;
				border-radius: 6px;
			}
		}
		.cancellBox{
			padding: 15px 0 15px;
			 border-bottom:1px solid #F4F4F4 ;
			
		}
		.list {
			flex-wrap: inherit;
			
			.list_img {
				margin-right: 0.71875rem;
				position: relative;
			}
		
			.list_img:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 6px;
				right: 0;
				width: 1.375rem;
				height: 1.25rem;
				background: no-repeat center;
				background-size: 100% 100%;
			}
		
			.list_img.list_img_1:after {
		
				background-image: url(../../assets/Train/icon_1@2x.png);
		
			}
		
			.list_img.list_img_2:after {
		
				background-image: url(../../assets/Train/icon_2@2x.png);
		
			}
		
			.list_img.list_img_3:after {
		
				background-image: url(../../assets/Train/icon_3@2x.png);
		
			}
		
		
			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}
		
			.text {
				font-size: 0.75rem;
				color: #919CB0;
				margin-left: 0.5rem;
			}
		
			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}
		
			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}
		
			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}
		
			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}
		
			.btnimg_span {
				margin-right: -5px;
		
			}
		}

		.userSink_flex {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 68px;
			background-color: #fff;
			z-index: 2000;
			
		}

		.dian {
			.van-popup {
				width: 300px;
				height: 102px;
				border-radius: 6px;

				.popupBox {
					padding: 15px 0;
					display: flex;
					color: #28334A;

					&:first-child {
						border-bottom: 1px solid #E5E5E5;
					}

					img {
						width: 20px;
						height: 20px;
						margin-right: 10px;
						margin-left: 20px;
					}
				}
			}
		}

		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}

		.van-tab {
			min-width: 85px;
		}

		.van-tab--active {
			font-size: 17px;
		}

		.topBox {
			padding: 15px;
			background-color: #fff;

		}

		.bigBox {
			width: 100%;
			padding: 15px;
			padding-top: 0;
			background-color: transparent;
			text-align: left;

			.topBox {
				justify-content: space-between;
				background-color: #fff;
				padding: 20px 15px 24px;
				border-radius: 6px;

				.btn {
					padding: 5px;
					background-color: #0081FF;
					border-radius: 50px;
					color: #fff;
					font-size: 12px;
					border: 1px solid transparent;
				}

				.btn1 {
					background-color: #fff;
					color: #0081FF;
					border: 1px solid #0081FF;
				}
			}
		}

		.time {
			color: #919CB0;
			font-size: 12px;
			transform: scale(.9);
		}

		.kan {
			justify-content: space-between;
			width: 100%;
			font-size: 12px;
			color: #919CB0;
			margin-top: 25px;
		}

		.van-popup--center {
			transform: translate(-50%, -70%);
		}
		img{
			max-width: 100%;
		}
	}
</style>
