<template>
	<div class="viewArticles">

		<div class="viewArticlesTop">
			<div class="viewArticlesTop_flex" style="">
				<div class="flex" style="justify-content: space-between;">
					<div class="flex" v-if="article.user_id==0">
						
						<div style="margin-right: 10px;" >
							<img src="../../assets/user/icon_gflogo.png" alt="" style="width: 41px; border-radius: 50%;" >
						</div>
						<div class="flex" style="justify-content: space-between;">
							<p style="font-weight: Bold;    align-items: flex-end;" class="flex"><span>现场急救科普工作室</span>
								</p>
						
						</div>
					</div>

					<div class="flex"  v-if="article.user_id>0">
						<div style="margin-right: 10px;" @click="$router.push({path:'/tutorHomepage',query:{id:userinfo.user_id}})">
							<img :src="userinfo.user_head_img" alt="" style="width: 41px; border-radius: 50%;" >
						</div>
						<div class="flex" style="justify-content: space-between;">
							<p style="font-weight: Bold;    align-items: flex-end;" class="flex"><span v-text="userinfo.user_name"></span>
								</p>
						
						</div>
						
					</div>
					<div style="color: #919CB0;font-size: 10px;float: right;" v-text="setTime(article.create_time) "></div>
					<!-- <div :class="article.is_follow?'btn1':'btn '" v-if="user_id!==userinfo.user_id" @click="postFollow(userinfo.user_id,article.is_follow)">
						<span v-text="article.is_follow?'已关注':'+ 关注'"></span>
					</div> -->
				</div>
				<!-- <div>
					<video :src="article.url"></video>
				</div> -->
				<div class="flex top">
					<div><span v-text="article.title" style="font-weight: bold;"></span></div>
				</div>
				<div class="ylcBox">
					<!-- <img src="../../assets/bigSink/bg_zhezhao@2x.png" alt="" class="top" style="width: 100%;margin: 0;padding: 0;" v-if="!article.isplay"> -->
					<div class="filter" v-if="!article.isplay" :style="'background-image: url('+ article.coverpic +');'">
					</div>
					<img :src="article.coverpic" alt="" class="bg" v-if="!article.isplay">
					<video :src="article.url" class="bg" v-show="article.isplay" id='videoId' controls></video>
					
					<!-- <div class="flex bot" style="justify-content: flex-end;" v-if="!article.isplay"> -->
						
					<!-- 	<div class="time">
							<span v-text="setMinute(article.video_time)" class="number_0" style="color: #fff;"></span>
						</div> -->
					<!-- </div> -->
					<img src="../../assets/user/bofan.png" alt="" class="conter" @click="bofanClick" v-if="!article.isplay">
				
				</div>
				<div class="flex" style="color: #919CB0;letter-spacing:0px;font-size: 12px;justify-content: space-between;margin-top: 17px;">
					
					<div class="flex" style="margin-right: 12px;">
						<img src="../../assets/bigSink/icon_gkrs@2x.png" alt="" style="width: 20px;margin-right: 5px;">
				
						<span v-text="article.view_num"></span>
					</div>
					<div class="flex" style="margin-right: 12px;" @click="JjpostVideoPraise(article.id,article.is_praise?2:1)">
						<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="!article.is_praise">
						<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin-right: 5px;" v-if="article.is_praise">
						<span v-text="article.praise_num"></span>
					</div>
				</div>
			</div>
		</div>

	</div>

</template>

<script>
	import {JjpostVideoPraise,postWeixinAidHotArticlePraise,postWeixinAidHotArticleUserInformationReply,postWeixinAidHotArticleUserCollectAdd,getVideoCommentDetail, JjgetVideoDetail ,getWeixinAidHotArticleCommentReplyList,postVideoReply,postFollow,postVideoReplyComment,postVideoReplyPraise,postVideoViewNum,getSign } from '@/api'
	import { Toast } from 'vant';
	import { setTime,setMinute } from '@/util/setTime.js'
	export default {
  name: 'viewArticles',
  components: {
  },
  watch:{
	  isshu(){
		  if(!this.isshu){
			  this.isCommentList=0
			  this.placeholder='说点什么吧'
		  }
	  },
	  show(){
		  if(!this.show){
			  this.isshu=false
		  }
	  }
  },
  data(){
	  return{
		  placeholder:'说点什么吧',
		  CommentIndex:0,
		  isCommentList:0,
		  // 自己的id
		  user_id:0,
		  page:1,
		  limit:8,
		  questionsTo:'',
		  iszuixin:true,
		  show1:false,
		  article:{
			  url:'https://www.sohu.com/a/190048557_349978',
			  title:'冠心病患者如何健康午睡？',
			  time:'2018-04-18',
			  form:'疾病库',
			  img:'http://img3.imgtn.bdimg.com/it/u=3089137238,4050596303&fm=11&gp=0.jpg',
			  conten:'你是不是看了好多篇“医疗人工智能应用十大方向”是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新是不明白？我们来换一个方法学习一下，根据创新'
		  },
		  show:false,
		  isshu:false,
		images: [
		  'https://img.yzcdn.cn/vant/apple-1.jpg',
		  'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		active:'',
		userinfo:{},
		replylist:[],
		replycount:0,
		index:0,
		replylistList_rep:[],
		// 评论的id
		Commentid:0
	  }
  },
  methods:{
	 // 播放
	 bofanClick(){
	 		  this.article.isplay=true
	 		  let video_id=document.getElementById("videoId")
			  postVideoViewNum({video_id:this.$route.query.id})
	 		 video_id.play()
	 },
	  // 视频点赞
	  JjpostVideoPraise(id,type){
		  JjpostVideoPraise({video_id:id,type:type}).then(res=>{
			   if(res.code==1){
			  	 this.JjgetVideoDetail()
			   }
		  })
	  },
	  // // 点赞
	  postVideoReplyPraise(id,type){
		  
		  postVideoReplyPraise({reply_id:id,type:type}).then(res=>{
		  		// Toast(res.msg) 
				 if(res.code==1){
					 if(this.iszuixin){
					 	this.getVideoCommentDetail('time')
						// getWeixinAidHotArticleCommentReplyList({comment_id:this.replylist[i].id}).then(res=>{
						// 			  this.replylistList_rep=res.data
						// 			  console.log( this.replylistList_rep)
						// })
					 }else{
					 	this.getVideoCommentDetail('hot')
						// getWeixinAidHotArticleCommentReplyList({comment_id:this.replylist[i].id}).then(res=>{
						// 			  this.replylistList_rep=res.data
						// 			  console.log( this.replylistList_rep)
						// })
					 }
				 }
		  })
	  },
	  postWeixinAidHotArticleUserCollectAdd(is_collect,id){
		  postWeixinAidHotArticleUserCollectAdd({hot_article_id:id}).then(res=>{
			  if(res.code==1){
				  Toast(res.msg)
				 this.JjgetVideoDetail()
			  }
		  })
	  },
	  // 点击评论 - 回复
	  inputFocus(index,item){
		  this.isCommentList=index
		  this.Commentid=item.id
		  this.placeholder=`回复：${item.userinfo.user_name}`
		  console.log('评论',item,this.Commentid)
		  this.$refs.inputFocus.focus()
	  },
	  // 点击关注
	  postFollow(id,isfollow){
	  		  let follow=isfollow==0?1:0
	  		  postFollow({tutor_id:id,is_follow:follow}).then(res=>{
	  			  Toast(res.msg)
				  if(follow==0){
					   this.article.is_follow=1
					   this.JjgetVideoDetail()
				  }else{
					  this.article.is_follow=0
					   this.JjgetVideoDetail()
				  }
	  		  })
	  },	
	  // 切换最新和热度
	  taggleZui(order){
		  console.log(order)
		  this.iszuixin=!this.iszuixin
		  this.getVideoCommentDetail(order)
	  },
	  // 评论文字
	  huiClick(i){
		  this.show=true
		  this.index=i
		  console.log(this.index)
		  this.replylistList_rep=this.replylist[i].comment_list
		  console.log(this.replylistList_rep)
		  console.log(this.replylist[this.index])
		  return
		  getWeixinAidHotArticleCommentReplyList({comment_id:this.replylist[i].id}).then(res=>{
			  this.replylistList_rep=res.data
			  console.log( this.replylistList_rep)
		  })
		 
	  },
	  // 获得视频详情
	JjgetVideoDetail(){
		JjgetVideoDetail({video_id:this.$route.query.id}).then(res=>{
			res.data.videoinfo.isplay=false
			
			this.userinfo=res.data.userinfo
			res.data.videoinfo.create_time=new Date(res.data.videoinfo.create_time).getTime()/1000
			this.article=res.data.videoinfo
			this.article.is_collect=res.data.videoinfo.is_collect
			this.article.is_follow=res.data.videoinfo.is_follow
			this.article.is_praise=res.data.videoinfo.is_praise
			this.article.praise_num=res.data.videoinfo.praise_num
			// setTimeout(()=>{
			// 	var videoId = document.getElementById('videoId');
			// 	console.log(videoId.duration)
			// },500)
			
		})
		
	} ,
	// 获得视频评论列表
	getVideoCommentDetail(order){
		getVideoCommentDetail({order:order,video_id:this.$route.query.id,page:this.page,limit:this.limit}).then(res=>{
			
			this.replylist=res.data.replylist
			this.replycount=res.data.replycount
			console.log(this.replylist)
		})
	},
	// 查看跟多评论
	moreClick(){
		console.log(this.iszuixin)
		this.limit=this.replycount
		console.log(this.limit)
		if(this.iszuixin){
			this.getVideoCommentDetail('time')
		}else{
			this.getVideoCommentDetail('hot')
		}
	},
	// 发送
	sendOutClick(){
		switch (this.isCommentList){
			case 0:
			postVideoReply({video_id:this.$route.query.id,content:this.questionsTo}).then(res=>{
				Toast(res.msg)
				if(res.code==1){
					this.isshu=false
					this.questionsTo=''
					if(this.iszuixin){
						this.getVideoCommentDetail('time')
					}else{
						this.getVideoCommentDetail('hot')
					}
				}
			})
				break;
			case 1:
				postVideoReplyComment({reply_id:this.Commentid,content:this.questionsTo}).then(res=>{
					if(res.code==1){
						Toast(res.msg)
						this.isshu=false
						this.questionsTo=''
						this.isCommentList=0
						if(this.iszuixin){
							this.getVideoCommentDetail('time')
						}else{
							this.getVideoCommentDetail('hot')
						}
					}
				})
				break;
			default:
				break;
		}
		this.isshu=true
		
	},
	setTime(time){
		return setTime(time)
	},
	setMinute(time){
		return setMinute(time)
	}
	
  },
  mounted() {
  	document.title = '急救影院';   
	this.user_id=JSON.parse(localStorage.getItem('user_data')).user_id
	this.JjgetVideoDetail()
	this.getVideoCommentDetail('time')
	if(localStorage.getItem('user_id')=='MzIw'){
		setTimeout(()=>{
				getSign({ page_url:  window.entryUrl})
				  .then(res => {
				    if (res.data.appId) {
					this.$wx.config({
						debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
						appId: res.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
						timestamp: res.data.timestamp, // 必填，生成签名的时间戳
						nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
						signature: res.data.signature, // 必填，签名，见附录1
						jsApiList: ['scanQRCode',
									'updateTimelineShareData',
									'updateAppMessageShareData',
									'onMenuShareAppMessage',
									'onMenuShareTimeline'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
						});
					this.$wx.ready(res => {  // 微信SDK准备就绪后执行的回调。
					this.$wx.onMenuShareAppMessage({
						title: '现场急救行动指南', // 分享标题
						desc: '现场急救行动指南', // 分享描述
						link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
						type: 'link', // 分享类型,music、video或link，不填默认为link
						dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
						success: function () {
						// 用户点击了分享后执行的回调函数
						}
					});
					this.$wx.updateAppMessageShareData({
						title: '现场急救行动指南', // 分享标题
						desc: '现场急救行动指南', // 分享描述
						link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
						success: function(resq) {
							clearInterval(timeVal)
							// 设置成功
						},
						error: function(resw) {
							// alert('')
						}
					})
					// 分享朋友圈
					this.$wx.updateTimelineShareData({
						title: '现场急救行动指南', // 分享标题
						link:location.href , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: 'https://xcjj.tysos.cn/gzh/backend/public/uploads/user_photo/2020-06/1593499437jhofvxhz.png', // 分享图标
						success: function() {
							// 设置成功
						}
					});
					});
				    }
				  });
		},1000)
		
		}
	
  }
}
</script>
<style lang="scss" type="text/css">
	.viewArticles {
		min-height: 100vh;
		background-color: #fff;
		padding: 0 0 46px;
		video{
			width:100%;
		}
		.input {
			width: calc(100% - 77px);
			min-height: 33px;
			line-height: 33px;
			background-color: #F4F4F4;
			option: none;
			border-radius: 6px;
			padding:0 10px;
			transform: all 1s;
				
		}
		.viewArticlesTop {
			width: 100%;
			text-align: left;
			img{
				max-width: 100%;
			}
			.viewArticlesTop_flex {

				justify-content: space-between;
				padding: 20px 15px 24px;
				border-radius: 6px;

				.time {
					color: #919CB0;
					font-size: 10px;
					font-weight: 100;
					display: block;
				}
			}
		}
		.top{
			margin-top: 20px;
			padding-bottom: 12px;
			justify-content: space-between;
			padding-top: 20px;
			border-top: 1px solid rgba(229,229,229,1);
		}
		.ylcBox {
			width: 100%;
			height: 232px;
			position: relative;
			overflow: hidden;
		
			.top {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 5;
		
			}
		
			.bot {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 6;
				font-size: 12px;
			}
			.bg {
				// width: 100%;
				height: 231px;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}
			.flex {
				position: absolute;
				z-index: 6;
				color: #fff;
				justify-content: space-between;
				width: 100%;
				padding: 10px 15px;
			}
			.conter {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 50px;
				height: 50px;
			}
		
			.time {
				background-color: rgba(0, 0, 0, .5);
				border-radius: 50px;
				padding: 1px 7px;
				transform: scale(.9);
			}
		}
		.number_0Box{
			font-size:12px;
			font-family:PingFang SC;
			font-weight:500;
			color:rgba(145,156,176,1);
			margin-top: 19px;
		}
		.btn {
			padding: 5px 8.5px;
			background-color: #0081FF;
			border-radius: 50px;
			color: #fff;
			font-size: 12px;
			border: 1px solid transparent;
		}
		.btn1 {
			padding: 5px 8.5px;
			background-color: transparent;
			border-radius: 50px;
			color: #0081FF;
			font-size: 12px;
			border: 1px solid #0081FF;
		}

		.outerChain {}

		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}

		.van-tab {
			min-width: 85px;
		}

		.van-tab--active {
			font-size: 17px;
		}

		.topBox {
			padding: 15px;
			background-color: #fff;

		}

		.bottomBox {
			padding: 6.5px 15px;

			img {
				width: 20px;
				height: 20px;
			}

			input {
				width: 50%;
				height: 33px;
				line-height: 33px;
				background-color: #F4F4F4;
				option: none;
				border-radius: 6px;
				padding-left: 12px;
				transform: all 1s;

			}

			.follow {
				position: absolute;
				top: -7px;
				left: 100%;
				font-size: 10px;
				color: #919CB0;
			}
		}

		.article {
			width: 100%;
			background-color: #F4F4F4;
			border-radius: 2px;
			text-align: left;
			padding: 5px;
			margin: 20px 0;
		}

		.ifameClass {
			text-align: left;
			padding: 0 15px;
			height: calc(100% - 20px - 16px - 8.5px);
			overflow: auto;
		}
		.filter{
			width: 100%;
			height: 231px;
			filter: blur(3px);
			background-position: -50px;
			position: absolute;
			top:0 ;
			left: 0;
			overflow: hidden;
			
		}
	}
</style>
