<template>
	<div class="questionsDel">
		
		<div style="width: 100%;text-align: left;">
			<div class="flex" style="justify-content: space-between;padding: 20px 15px 24px;border-radius: 6px;">
				<div style="width: 100%;justify-content: space-between;" class="flex">
					<div class="flex">
						<div style="margin-right: 10px;">
							<img src="../../assets/bigSink/icon_wen@2x.png" alt="" style="width: 20px;vertical-align: top;">
						</div>
						<div v-text="ask_info.wx_name">

						</div>
					</div>
					<div style="font-size: 12px;color: #919CB0;">
						提问于 <span v-text="FormatTime(ask_info.create_time)"></span>
					</div>
				</div>
				<div style="margin-top: 23px;" v-text="ask_info.contents">

				</div>

			</div>

			<div style="height: 10px;background-color: #F7F8FF;width: 100%;"></div>
			<div style="margin-top: 10px;text-align: left;padding: 0 15px;">
				<van-row justify="space-between" style="padding: 20px 0;">
					<van-col span="12">
						<span style="font-size: 17px; font-weight: bold;">当前有<span v-text="ask_info.reply_num"></span>个回答</span>
					</van-col>
					<!-- <van-col span="12">
						<div style="color: #0081FF;font-weight: bold;justify-content: flex-end;" class="flex">
							<img src="../../assets/bigSink/icon_zuixin@2x.png" alt="" style="width:16px;margin-right: 8px;">
							<div>最新</div>
						</div>
					</van-col> -->
				</van-row>
				<div style="text-align: center;" v-if="!ask_info.reply_num">
					<img src="../../assets/default/icon_zanwusx@2x.png" alt="" style="width: 150px;">
					<div>暂无回答</div>
				</div>
				<div v-if="ask_info.reply_num">
					<div v-for="(item,i) in answer" style="padding-bottom: 20px;" :key="i" class="answerBox">
						<div class="flex" style="justify-content: space-between;padding: 20px 0 24px;border-radius: 6px;">
							<div class="flex" style="width: 100%;justify-content: space-between;">
								<div class="flex">
									<div style="margin-right: 10px;">
										<img src="../../assets/bigSink/icon_da@2x.png" alt="" style="width: 20px;vertical-align: top;">
									</div>
									<div v-text="item.r_name">

									</div>
								</div>
								<div style="font-size: 12px;color: #919CB0;">
									<span v-text="FormatTime(item.create_time)"></span>
								</div>
							</div>
							<div style="margin-top: 23px;" v-text="item.r_contents">
							</div>

						</div>

						<div style="justify-content:space-between;" class="flex">
							<div class="flex" @click="replyClick(i)">
								<div class="flex" v-if="item.comment_count">
									<div style="color: #0081FF;"> <span v-text="item.comment_count"></span> 条回复</div>
									<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" style="width: 20px;height: 20px;margin-left: 5px;display: block;"
									 v-if="!item.show">
									<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" style="width: 20px;height: 20px;margin-left: 5px;display: block;"
									 v-if="item.show">
								</div>
							</div>
							<div class="flex" style="justify-content: flex-end;">
								<div :style="item.is_support?'color: #0081FF;':'color: #919CB0;'" v-text="item.support_num" ></div>
								<img src="../../assets/bigSink/icon_zans_nor@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;" @click="thumbsUpClick(item.id)" v-if="!item.is_support">
								<img src="../../assets/bigSink/icon_zans_pre@2x.png" alt="" style="width: 20px;margin: 0 16px 0 8px;"  v-if="item.is_support">
								<img src="../../assets/bigSink/icon_pinlun_s@2x.png" alt="" style="width: 20px;" v-if="!isuser" @click="commentClick(i)">
								<span v-if="isuser" style="color: #0081FF;" @click="commentClick(i)">追问</span>
							</div>
						</div>
						<div class="xialaBox" v-if="item.show">
							<div v-for="(items,index) in item.comment_info" :key="index">
								<div class="flex" style="justify-content: space-between;padding-top: 16px;">
									<div>
										<span v-text="items.comment_person"></span>
										<span class="blue" style="margin:0 10px;" v-text="items.comment_user_id==ask_info.user_id?'追问':'回复'" ></span>
										<span v-text="items.person" v-if="!(items.comment_user_id==ask_info.user_id)"></span>
									</div>
									<div style="font-size: 12px;color: #919CB0;">
										<span v-text="FormatTime(items.createtime)"></span>
									</div>
								</div>
								<div class="flex aaa" @click="commentClick1(index,items)">
									<div style="color: #767E8D;" v-text="items.content"></div>
									<div>
										<img src="../../assets/bigSink/icon_pinlun_s@2x.png" alt="" width="20px">
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<van-overlay :show="isshu" @click="isshu = false" />
		<div class="bottom " style="box-shadow: rgb(148, 149, 155) 1px 5px 15px 1px;z-index: 2;" :style="isshu?'height:auto':'height: 82px;'"
		 v-if="isuser&&isshu">
			<div class="flex bottomBox" v-if="isshu">
				<!-- <input type="text" placeholder="我来回答" @focus="isshu=true" @blur="isshu=false" :style="isshu?'width:85%':'width:50%'"> -->
				<van-field v-model="questionsTo" rows="1" :autosize="{ minHeight: 26 }" type="textarea" :placeholder="commentPlaceholder" class="input" />

				<div :class="!ishuida?'btn2':' btn1'" v-if="isshu" v-text="ishuida?'提问':'发送'" @click="answerInputClick">
				</div>
			</div>
		</div>
		<div class="bottom " style="box-shadow: rgb(148, 149, 155) 1px 5px 15px 1px;z-index: 2;" :style="isshu?'height:auto':'height: 82px;'"
		 v-if="!isuser">
			<div class="flex bottomBox" v-if="isshu">
				<!-- <input type="text" placeholder="我来回答" @focus="isshu=true" @blur="isshu=false" :style="isshu?'width:85%':'width:50%'"> -->
				<van-field v-model="questionsTo" rows="1" :autosize="{ minHeight: 26 }" type="textarea" :placeholder="commentPlaceholder" class="input" />
				<div :class="!ishuida?'btn2':' btn1'" v-if="isshu" v-text="ishuida?'发送':'提问'" @click="answerInputClick">
				</div>
			</div>
			<div class="btn" v-if="!isshu" @click="answerClick">我来回答</div>
		</div>




	</div>

</template>

<script>
	import {getQuestionAskDetail,postQuestionReply,postAuestionReplyComment,postQuestionReplySupport} from '@/api'
	import {
		Toast
	} from 'vant';
	export default {
  name: 'questionsDel',
  components: {
  },
  computed:{
	  
  },
  data(){
	  return{
		  commentPlaceholder:'我来回答',
		  /*
		  ishuida:true 代表是点击我要回答
		  false代表回复评论
		  */
		  ishuida:false,
		  questionsTo:'',
		  show1:false,
		  answer:[{
			  create_time:'2019-12-20 17:00',
				name:'李彬',
				time:'2019-12-20  17:00',
				conten:'要是病情不严重的话，可以自行在家进行处理。扭伤处理原则是RICE（大米原则），即休息—冰敷—加压包扎—抬高下肢。',
				fabulous:132,
				show:false,
				replyList:[{
					name:'张珊',
					replyName:'',
					time:'2019-12-20  17:30',
					conten:'冰敷要多久？'
				},
				{
					name:'张珊',
					replyName:'李师',
					time:'2019-12-20  17:31',
					conten:'3小时即可'
				}]
			  
		  },{
				name:'李彬',
				  create_time:'2019-12-20 17:00',
				conten:'要是病情不严重的话，可以自行在家进行处理。扭伤处理原则是RICE（大米原则），即休息—冰敷—加压包扎—抬高下肢。',
				fabulous:132,
				show:false,
				replyList:[]
			  
		  }],
		  show:false,
		  isshu:false,
		images: [
		  'https://img.yzcdn.cn/vant/apple-1.jpg',
		  'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		active:'',
		ask_info:{},
		isuser:true,
		answerID:'',
	  }
  },
  methods:{
	  thumbsUpClick(id){
		  postQuestionReplySupport({reply_id:id}).then(res=>{
			  console.log(res)
			   this.getQuestionAskDetail()
		  })
	  },
	  FormatTime(time){
		  let timeText=[]
		  if(time){
			  timeText=time.split(' ')
		  }
		  return timeText[0]
		 
	  },
	  commentClick(i){
		  if(this.isuser){
			  console.log(this.answer[i].r_name)
			  this.answerID=this.answer[i].id
			  this.commentPlaceholder='追问 '+this.answer[i].r_name
			  this.isshu=true
			  this.ishuida=false
		  }else{
			  this.answerID=this.answer[i].id
			  this.commentPlaceholder='我要回答'
			  this.isshu=true
			  this.ishuida=false
		  }
		 
	  },
	  commentClick1(i,item){
		  console.log(item)
		  if(!this.isuser){
			  console.log(item)
			  this.answerID=item.comment_user_id
			  this.commentPlaceholder='回复 '+item.comment_person
			  this.isshu=true
			  this.ishuida=false
		  }else{
			  this.answerID=item.reply_id
			  this.commentPlaceholder='追问 '+this.answer[i].r_name
			  this.isshu=true
			  this.ishuida=false
		  }
	  		  
	  },
	  answerInputClick(){
		  // let userids=JSON.parse(localStorage.getItem('user_data')).user_id
		  // if(userids==this.ask_info.user_id){
			 //  Toast('本人不能评论自己的提问')
		  // }else{
			  if(this.ishuida){
				  postQuestionReply({q_id:this.ask_info.id,r_contents:this.questionsTo}).then(res=>{
				  			  Toast(res.msg)
				  			  if(res.code==1){
				  				  this.getQuestionAskDetail()
								   this.isshu=false
								   this.questionsTo=''
				  			  }
				  })
			  }else{
				  console.log({reply_id:this.answerID,content:this.questionsTo})
				 postAuestionReplyComment({reply_id:this.answerID,content:this.questionsTo}).then(res=>{
				 		Toast(res.msg)
				 		if(res.code==1){
				 			this.getQuestionAskDetail()
							this.isshu=false
							this.questionsTo=''
				 		}	  
				 }) 
			  }
		
		  
		  // }
	  },
	  answerClick(){
		  let userids=JSON.parse(localStorage.getItem('user_data')).user_id
		  if(userids==this.ask_info.user_id){
		  			  Toast('本人不能评论自己的提问')
		  }else{
			  this.isshu=true
			  this.ishuida=true
		  }
		  
	  },
	 replyClick(i){
		 console.log(this.answer[i])
		 this.answer[i].show=!this.answer[i].show
		 let list=this.answer
		 this.answer=[]
		 this.answer=list
	 },
	 getQuestionAskDetail(){
		 let s=[]
		 getQuestionAskDetail({ask_id:this.$route.query.ask_id}).then(res=>{
			 let userids=JSON.parse(localStorage.getItem('user_data')).user_id
			
		 	this.ask_info=res.data.ask_info
			if(userids==this.ask_info.user_id){
				this.isuser=true
			}else{
				this.isuser=false
			}
		 	// s=this.ask_info.create_time.split(' ')
		 	// this.ask_info.create_time=s[0]
			
		 	this.answer=res.data.reply_info
		 	this.answer.forEach((item,index)=>{
		 		this.answer[index].show=false
		 	})
		 })
	 }
  },
  mounted() {
  	document.title = '问题详情';  
	 this.getQuestionAskDetail()
  }
}
</script>
<style lang="scss" type="text/css">
	.questionsDel {
		min-height: 100vh;
		background-color: #fff;
		padding: 0 0 90px;

		.answerBox {
			border-bottom: 1px solid #E5E5E5;
			.aaa {
				justify-content: space-between;
				padding: 18px 0;
				border-bottom: 1px solid #E5E5E5;
				// &:last-child{
				// 	border-bottom: none;
				// }
			}
		}

		
		

		.answerBox:last-child {
			border-bottom: none;
		}

		.van-tabs__wrap.van-hairline--top-bottom {
			padding: 0 !important;
		}

		.van-tab {
			min-width: 85px;
		}

		.van-tab--active {
			font-size: 17px;
		}

		.topBox {
			padding: 15px;
			background-color: #fff;

		}

		.bottomBox {
			padding: 6.5px 15px;

			img {
				width: 20px;
				height: 20px;
			}

			.input {
				width: calc(100% - 85px);
				min-height: 33px;
				line-height: 33px;
				background-color: #F4F4F4;
				option: none;
				border-radius: 6px;
				padding: 0 10px;
				transform: all 1s;

			}

			.follow {
				position: absolute;
				top: -7px;
				right: -20px;
				font-size: 12px;
				transform: scale(.7);
				color: #919CB0;
			}
		}

		.article {
			width: 100%;
			background-color: #F4F4F4;
			border-radius: 2px;
			text-align: left;
			padding: 5px;
			margin: 20px 0;
		}

		.xialaBox {
			margin-top: 16px;
			padding: 0px 16px 20px 12px;
			background-color: #F7F8FF;
		}

		.btn1 {
			background-color: #0081FF;
			color: #fff;
			border: 1px solid #0081FF;
			width: 65px;
			border-radius: 50px;
			height: 30px;
			line-height: 30px;
			margin-left: 14px;
		}

		.btn2 {
			height: 30px;
			line-height: 30px;
			width: 65px;
			background-color: #fff;
			border: 1px solid transparent;
			color: #0081FF;
			text-align: center;
			margin-left: 14px;
		}
	}
</style>
