<template>
	<div class="default">
		<img :src=" defaultImg" alt="" class="default_img" />
		<p v-text="defaultText"></p>
	</div>
</template>

<script>
	export default {
		props: {
			count: {
				type: Number,
				default: 1
			},
			defaultImg: {
				type: String,
				default: '../../assets/default/icon_zanwuwdpx@2x.png'
				// require: true // 必填
			},
			defaultText: {
				type: String,
				default: '暂无发布导师班培训活动'
				// require: true // 必填
			},
		},
		data() {
			return {

			}
		},
	}
</script>

<style>
</style>
