<template>
	<div class="train_index">
		<div class="vanSwipe">
			<van-swipe :autoplay="3000" style="height: 7.5rem;">
				<van-swipe-item v-for="(image, index) in images" :key="index">
					<img v-lazy="image.pic" class="auto-img"  @click="jump(image.url)"/>
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="btnBox">
			<van-row type="flex" justify="space-around">
				<van-col span="1">

				</van-col>
				<van-col span="7">
					<div class="btn btn1">
						<div class="title">
							培训总场数
						</div>
						<div v-text="total_number" class="btn_number">
						</div>
					</div>
				</van-col>
				<van-col span="1">

				</van-col>
				<van-col span="7">
					<div class="btn btn2">
						<div class="title">
							报名中场数
						</div>
						<div v-text="signUp_number" class="btn_number">
						</div>
					</div>
				</van-col>
				<van-col span="1">

				</van-col>
				<van-col span="7">
					<div class="btn btn3">
						<div class="title">
							培训学员(人)
						</div>
						<div v-text="student_number" class="btn_number">
						</div>
					</div>
				</van-col>
				<van-col span="1">

				</van-col>
			</van-row>
		</div>
		<div class="flex list_title" style="margin-top: 15px;margin-bottom: 10px;margin-right: 16px;">
			<div class="flex left">
				<div class="list_title_left"></div>
				培训课程
			</div>
			<div class="flex left" @click="$router.push({name:'cooperativeList'})">培训机构</div>
			<div class="flex left" @click="$router.push({name:'mentorList'})">导师榜单</div>
			<!-- <div class="flex right" > -->
				<!-- <div style="padding:3px 5px;border: 1px solid #0081FF;border-radius: 5px;margin-right: 5px;"  @click="$router.push({name:'cooperative'})">合作机构</div> -->
				<!-- <div style="padding:3px 5px;border: 1px solid #0081FF;border-radius: 5px;" @click="$router.push({name:'mentorList'})">导师榜单</div>
			</div> -->
		</div>
		<div>
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list v-model="loading" :finished="finished" finished-text=" " @load="onLoad">
				<div v-if="count==0">
					<img src="../../assets/default/icon_nodota.png" alt="" style="width: 150px;">
					<p>暂无培训课程</p>
				</div>
				<van-cell v-for="(item,index) in list" :key="index" @click="$router.push({name:'courseDetails',query:{act_id:item.id}})">
					<div slot="default" class=" list" style="display: flex;">
						<div class="list_img" style="position: relative;">
							<img src="../../assets/PX/icon_baomz@2x.png" alt="" width="45" class="icon"  v-if="item.act_status==1">
							<img src="../../assets/PX/icon_yijieshu@2x.png" alt="" width="45" class="icon" v-else-if="item.act_status==2">
							<img src="../../assets/PX/icon_yifankui@2x.png" alt="" width="45" class="icon" v-else>
							<van-image width="100" height="100" :src="item.cover_img" radius='8px' />
						</div>
						<div style="width: 100%;">
							<div v-text="item.act_name" class="list_title"></div>
							<!-- <h4 v-text=""></h4> -->
							<div class="flex" style="    justify-content: space-between;">
								<!-- 标题下左右边两个div -->
								<div>
									<div class="flex">
										<img src="../../assets/PX/icon_kecheng@2x.png" alt="" style="width: 12px;height: 12px;margin-right: 0.5rem;">
										<div class="text" v-for="(items,i) in item.train_course" style="margin: 0;" :key="i">
											<span  v-text="items==2?'CPR+AED使用课程':items==3?'创伤救护课程':items==4?'导师线下培训课程':items==5?'主任导师线下培训课程':''"></span><span v-if="i!=item.train_course.length-1" >+</span>
										</div>
									</div>
									<!-- 左边时间 -->
									<div class="flex">
										<img src="../../assets/Train/icon_time@2x.png" alt="" style="width: 12px;height: 12px;">
										<div v-text="item.act_start_time" class="text"></div>
										<div class="label-tag" :class="{'ping-col': item.act_type == 1}" v-text="item.act_type == 1 ? '学员班' : '导师班'"></div>
									</div>
									<!-- 左边地址 -->
									<div class="flex">
										<img src="../../assets/Train/icon_xdizhi@2x.png" alt="" style="width: 12px;height: 14px;">
										<div v-text="item.city" class="text"></div>
									</div>
								</div>
								<!-- <div>
									<div :class="item.act_status==1?'btnimg1':item.act_status==2?'btnimg2':'btnimg3'" class="btnimg">
										<span v-text="item.act_status==1?'报名中':item.act_status==2?'已结束':item.act_status==3?'已下线':'已反馈'" class="btnimg_span"></span>
									</div>
								</div> -->
							</div>
						</div>
					</div>
				</van-cell>
			</van-list>
			</van-pull-refresh>	
		</div>
		<div class="bottom">
			<div class="bottom_input" @click="visible=true">
				<span v-text="value"></span>
				<img src="../../assets/Train/icon_seach@2x.png" alt="" class="bottom_input_img">
			</div>
		</div>
		<!-- <van-overlay :show="visible" @click="visible = false" />
		<transition name="van-slide-up"> -->
			<van-popup v-model="visible" closeable position="bottom" :style="{ height: '95%' }">
			<div  class="Slide_up">
				<div class="header" style="position: absolute; top: -18px;left: 50%; transform: translateX(-50%);">搜索</div>
				<!-- <div class="Slide_up_header">
					搜索
					<div class="Slide_up_img">
						<img src="../../assets/Train/icon_close@2x.png" class="auto-img" alt="" @click="visible=false">
					</div>
				</div> -->
				<div style="overflow: auto;height: 87%;padding-bottom: 10px;">
					<div>
						<div class="flex">
							<div class="btn" @click="activationClick('全部')" :class="activation=='全部'?'activation':''">全部</div>
						</div>
					</div>
					<div>
						<div class="Slide_up_title">
							热门城市
						</div>
						<div class="flex">
							<div class="btn" v-for="(item,i) in popular" :key='i' v-text="item.city" @click="activationClick(item.city,item.cityid)"
							 :class="activation==item.city?'activation':''"></div>
						</div>
					</div>
					<div>
						<div class="Slide_up_title">
							选择城市
						</div>
						<div class="flex">
							<van-radio-group v-model="activation" style="margin-top: 16px; margin-right: 16px;">
								<van-radio name="城市"></van-radio>
							</van-radio-group>
							<div class="btn" @click="provinceClick" :style="activation=='城市'?'color: #28334A;':''">
								<span v-text="province"></span>
								<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" v-if="activation!=='城市'" style="height: 20px;width: 20px;">
								<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" v-if="activation=='城市'" style="height: 20px;width: 20px;">
							</div>
							<div class="btn" @click="provinceClick" :style="activation=='城市'?'color: #28334A;':''">
								<span v-text="city"></span>
								<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" v-if="activation!=='城市'" style="height: 20px;width: 20px;">
								<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" v-if="activation=='城市'" style="height: 20px;width: 20px;">
							</div>
						</div>
					</div>
					<div>
						<div class="Slide_up_title">
							选择开课日期
						</div>
						<div class="flex">
							<van-radio-group v-model="activation" style="margin-top: 16px; margin-right: 16px;">
								<van-radio name="开课日期"></van-radio>
							</van-radio-group>
							<div class="btn" @click="dataClick" :style="activation=='开课日期'?'color: #28334A;':''">
								<span v-text="oneDay"></span>
								<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" v-if="activation!=='开课日期'" style="height: 20px;width: 20px;">
								<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" v-if="activation=='开课日期'" style="height: 20px;width: 20px;">
							</div>

						</div>
					</div>
					<div>
						<div class="Slide_up_title">
							选择开课时间段
						</div>
						<div class="flex">
							<van-radio-group v-model="activation" style="margin-top: 16px; margin-right: 16px;">
								<van-radio name="开课时间段"></van-radio>
							</van-radio-group>
							<div class="btn" @click="data_SectionClick('1')" :style="activation=='开课时间段'?'color: #28334A;':''">
								<span v-text="oneDay1"></span>
								<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" v-if="activation!=='开课时间段'" style="height: 20px;width: 20px;">
								<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" v-if="activation=='开课时间段'" style="height: 20px;width: 20px;">
							</div>
							<div style="margin-top: 16px; margin-right: 16px;">
								至
							</div>
							<div class="btn" @click="data_SectionClick('2')" :style="activation=='开课时间段'?'color: #28334A;':''">
								<span v-text="oneDay2"></span>
								<img src="../../assets/Train/icon_xiala_nor@2x.png" alt="" v-if="activation!=='开课时间段'" style="height: 20px;width: 20px;">
								<img src="../../assets/Train/icon_xiala_pre@2x.png" alt="" v-if="activation=='开课时间段'" style="height: 20px;width: 20px;">
							</div>

						</div>
					</div>
					<div>
						<div class="Slide_up_title">
							按导师姓名搜索
						</div>
						<div class="flex">
							<van-radio-group v-model="activation" style="margin-top: 16px; margin-right: 16px;">
								<van-radio name="导师姓名"></van-radio>
							</van-radio-group>
							<div class="btn" @click="provinceClick" :class="activation=='导师姓名'?'activation':''">
								<input type="text" v-model="tutorName" placeholder="请输入导师姓名" style="background-color: transparent;" :disabled="activation!=='导师姓名'">
							</div>

						</div>
					</div>
					<!-- <div>
						<div class="Slide_up_title">
							按课程类型搜索
						</div>
						<div class="flex">
							<van-radio-group v-model="activation" style="margin-top: 16px; margin-right: 16px;">
								<van-radio name="" v-if="activation!=='学员班'&&activation!=='导师班'"></van-radio>
							  <van-radio name="学员班" v-if="activation=='学员班'"></van-radio>
							  <van-radio name="导师班" v-if="activation=='导师班'"></van-radio>
							</van-radio-group>
							<div class="btn" @click="activationClick('学员班')" :class="activation=='学员班'?'activation':''">
								学员班
							</div>
							<div class="btn" @click="activationClick('导师班')" :class="activation=='导师班'?'activation':''">
								导师班
							</div>
							
						</div>
					</div> -->
				</div>
				<div class="btnSeach" @click="btnSeachClick">
					开始搜索
				</div>
			</div>
		</van-popup>
		<!-- 弹窗内容 -->
		<van-popup v-model="isAreaList" position="bottom">
			<div style="width: 100%;">
				<van-area :area-list="areaList" :columns-num="2" title="地址" @confirm="confirm" @cancel="cancel" />
			</div>
		</van-popup>
		<van-popup v-model="isDate" position="bottom">
			<div style="width: 100%;">
				<van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate" :formatter="formatter"
				 @confirm="dateConfirm" @cancel="dateCancel" />
			</div>
		</van-popup>
		<!-- <van-calendar v-model="isdata" @confirm="onConfirm" color="#0081FF" /> -->
		<!-- <van-calendar v-model="isdata_Section" type="range" @confirm="onConfirm_Section" color="#0081FF" /> -->
		<!-- <transition name="van-slide-up">
		  <div v-show="isAreaList" style="z-index: 3;position: fixed;bottom: 0;left: 0;width: 100%; ">
			 
		  </div>
		</transition> -->

	</div>
</template>

<script>
	import { getBanner,getActivityDataCount,getActivityLists,gethotcity ,getOfficialCourse} from '@/api';
import areaList from '../../area.js'
import { Toast } from 'vant';
export default {
  name: 'train_index',
  components: {
  },
  data(){
	  return{
		   refreshing: false,
		 /*搜索的值*/
		 value:'',
		 /*导师*/
		 tutorName:'',
		/*选择日期区间*/
		isdata_Section:false,
		oneDay1:'2019-10-10',
		oneDay2:'2019-10-10',
		/*-------*/
		 /*单个日期选择*/
		 isdata:false,
		 oneDay:'2019-10-10',
		/*选中的城市*/
		province:'广东',
		city:'广州市',
		/* ---- */ 
		/* 热门城市*/
		popular:['广州','海口','北京','上海'],
		/**/
		/*打开选择地址*/
		isAreaList:false,
		areaList:areaList,
		/**/
		// 搜索页面选中
		activation:'全部',
		// 控制搜索显示隐藏
		visible:false,
		title:'培训报名',
		images: [
		  // 'https://img.yzcdn.cn/vant/apple-1.jpg',
		  // 'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		total_number:100,
		signUp_number:100,
		student_number:1000,
		/*list为放到页面渲染的，list1为请求的所有数据做懒加载处理*/
		list:[],
		list1: [
			// {
			// img:'https://img.yzcdn.cn/vant/cat.jpeg',
			// title:'120急救普及培训-海口市民间灾害应急救队安全屋急救培训',
			// time:'2019-11-20  19:00',
			// addres:'海口市',
			// type:3
			// /**
			//  * type==1 报名中
			//  * type==2 已结束
			//  * type==3 已反馈
			//  * */
			//  },
			//  {
			//  	img:'https://img.yzcdn.cn/vant/cat.jpeg',
			//  	title:'120急救普及培训-海口市民间灾害应急救队安全屋急救培训',
			//  	time:'2019-11-20  19:00',
			//  	addres:'海口市',
			//  	type:1
			//  	/**
			//  	 * type==1 报名中
			//  	 * type==2 已结束
			//  	 * type==3 已反馈
			//  	 * */
			//  }																	 
		],
		loading: false,
		finished: false,
		count:0,
		page:0,
		limit:'8',
		secah:{},
		cityid:'',
		// 搜索日期
		isDate: false,
		minDate: new Date(2000,0,1),
		maxDate: new Date(2025, 10, 1),
		currentDate: new Date(),
		isDates:'',
		isones:false,
		act_type:1,
		OfficialCourseaArr:[]
	  }
  },
  watch:{
	  list(){
		  let time=[]
		  this.list.forEach((item,index)=>{
			  // item.act_start_time=this.format1(new Date( item.act_start_time))
			 time= item.act_start_time.split(':')
			  item.act_start_time=`${time[0]}:${time[1]}`
		  })
	  }
  },
  methods:{
	   jump(url){
		  window.location.href = url
},
	    onRefresh() {
	        // 清空列表数据
	        this.finished = false;
			this.list=[]
			this.page=0
	        // 重新加载数据
	        // 将 loading 设置为 true，表示处于加载状态
	        this.loading = true;
	        this.onLoad();
	      },
	  dateConfirm(res) {
	  	let date = this.format(res)
		console.log(date)
		let timestamp1= (new Date(this.oneDay1)).getTime()/1000;
		let timestamp2= (new Date(this.oneDay2)).getTime()/1000;
	  	switch (this.activation) {
	  		case '开课时间段':
	  			// this.px_startTime = date
	  			// this.SubmitData.act_start_time = date
				
				  if(this.isDates==1){
					  let date1 = this.format(res,true)
					  this.oneDay1=date
					  this.oneDay2=date1
					  this.isones=true
					 timestamp1= (new Date(this.oneDay1)).getTime()/1000;
					 console.log(timestamp1,timestamp2)
				  }else{
					  timestamp2= (new Date(date)).getTime()/1000;
					  if(timestamp2-timestamp1<0){
						  Toast('结束时间要大于开始时间')
					  }else{
						  this.oneDay2=date
					  }
					  console.log(timestamp1,timestamp2)
				  }
	  			break;
	  		case '开课日期':
				this.oneDay=date
	  			// this.kh_startTime = date
	  			// this.SubmitData.check_start_time = date
	  			break;
	  	
	  		default:
	  			break;
	  	}
	  	this.isDate = false
	  },
	  dateCancel(res) {
	  	this.isDate = false
	  },
	  formatter(type, value) {
	  	if (type === 'year') {
	  		return `${value}年`;
	  	} else if (type === 'month') {
	  		return `${value}月`
	  	} else if (type === 'day') {
	  		return `${value}日`
	  	}
		 // else if (type === 'hour') {
	  // 		return `${value}时`
	  // 	} else if (type === 'minute') {
	  // 		return `${value}分`
	  // 	}
	  	return value;
	  },
	  // 时间格式化
	  bu(date,isone) {
		  if(isone){
			  return date < 10 ? '0' + Number(date)+1 :Number(date)+1
		  }else{
			  return date < 10 ? '0' + date : date
		  }
	  },
	  format(date,isones) {
	  	let year = date.getFullYear().toString()
	  	let mouth = (date.getMonth() + 1).toString()
	  	let day = date.getDate().toString()
	  	let hours = date.getHours().toString()
	  	let minutes = date.getMinutes().toString()
	  	return `${year}-${this.bu(mouth)}-${this.bu(day,isones)}`
		 // ${this.bu(hours)}:${this.bu(minutes)}
	  },
	  format1(date,isones) {
	  	let year = date.getFullYear().toString()
	  	let mouth = (date.getMonth() + 1).toString()
	  	let day = date.getDate().toString()
	  	let hours = date.getHours().toString()
	  	let minutes = date.getMinutes().toString()
	  	return `${year}-${this.bu(mouth)}-${this.bu(day,isones)} ${this.bu(hours)}:${this.bu(minutes)}`
	  },
	  
	  // 搜索
	  btnSeachClick(){
		switch (this.activation){
			case '全部':
				this.value='全部'
				this.secah={
					
				}
				break;
			case '城市':
				this.value=this.province+','+this.city
				this.secah={
					cityid:this.cityid
				}
				break;
			case '开课日期':
				this.value=this.oneDay
				this.secah={
					act_start_time:this.oneDay
				}
				
				break;
			case '开课时间段':
				this.value=this.oneDay1+','+this.oneDay2
				this.secah={
					act_start_time_begin:this.oneDay1,
					act_start_time_end:this.oneDay2,
				}
				break;
			case '导师姓名':
				this.value=this.tutorName
				this.secah={
					act_author:this.value
				}
				break;
			case '学员班':
				this.value=this.activation
				this.secah={
					act_type:this.act_type
				}
				break;
			case '导师班':
				this.value=this.activation
				this.secah={
					act_type:2
				}
				break;
			default:
				this.value=this.activation
				this.secah={
					cityid:this.cityid
				}
				break;
		}
		this.visible=false
		this.onRefresh()
		// console.log(this.secah)
		// console.log({page:String(this.page),limit:this.limit},this.secah)
		// this.page=1
		// this.list=[]
		// this.loading=false,
		// this.finished=false,
		// this.getActivityLists(this.secah,{page:String(this.page),limit:this.limit,...this.secah,act_type:this.act_type})
	  },
	  // 日期区间选择
	  data_SectionClick(type){
		  this.isDates=type
		  if(this.activation!=='开课时间段')return
		  this.isDate=true
	  },
	  onConfirm_Section(res){
		  this.isdata_Section = false;
		  
		  this.oneDay1 = this.formatDate(res[0]);
		   this.oneDay2 = this.formatDate(res[1]);
	  },
	  // 单个日期选择
	dataClick(){
		console.log(this.activation)
		   if(this.activation!=='开课日期')return
		   this.isDate=true
	  },
	formatDate(date) {
		console.log(date)
		return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
	  },
	onConfirm(date) {
		this.isdata = false;
		this.oneDay = this.formatDate(date);
	  },
	  // 选择城市做是否选择处理
	provinceClick(){
		  if(this.activation!=='城市')return
		  this.isAreaList=true
	  },
	open(){
		  this.isopen=!this.isopen
	  },
	onLoad() {
		console.log('asa')
		// 每页条数
		
		// 页码
		this.page++
		getActivityLists({...this.secah,act_type:this.act_type,page:this.page,limit:this.limit}).then(res=>{
			if(res.code==1){
				
				this.count=res.data.count
				if (this.refreshing) {
				  this.list = [];
				  this.refreshing = false;
				}
				let train_courseArr=[]
				res.data.list.forEach((items,i)=>{
					let arr =items.train_course.split('|');
					arr.forEach((item,i)=>{
						if(item){
							train_courseArr.push(item)
						}
					})
					items.train_course=train_courseArr
					if(items.train_course.includes('2') && items.train_course.includes('3')) {
						items.cover_img = require('../../assets/PX/pic_cprtrauma.png')
					} else if(items.train_course.includes('2')) {
						items.cover_img = require('../../assets/PX/pic_huodong@2x.png')
					} else if (items.train_course.includes('3')) {
						items.cover_img = require('../../assets/PX/pic_trauma.png')
					} else if(items.act_type == 2) {
						items.cover_img = require('../../assets/PX/pic_teacher.png')
					}
					train_courseArr=[]
				})
				this.list.push(...res.data.list)
				  // 加载状态结束
				  this.refreshing=false
				  this.loading = false;
				// 数据全部加载完成
				if (this.list.length >= this.count) {
				 this.finished = true;
				}
			}else{
				 this.list = [];
				 this.count=0
				 this.finished = true;
			}
		})
	  },
	  activationClick(text,cityid){
		  this.activation=text
		  this.cityid=cityid
		  
	  },
	  cancel(){
		  console.log('222')
		  this.isAreaList=false
	  },
	  confirm(res){
		  console.log(res)
		this.province=res[0].name
		this.city=res[1].name
		this.cityid=res[1].code
		 this.isAreaList=false
	  },
	  getActivityDataCount(){
		  getActivityDataCount().then(res=>{
			  this.total_number=res.data.all_count
			  this.signUp_number=res.data.signup_count
			  this.student_number=res.data.student_count
		  })
	  },
	  getActivityLists(issecr,obj){
		  console.log(this.list.length,this.count)
		  getActivityLists(obj).then(res=>{
			  this.count=res.data.count
			  if(res.data&&this.list.length< this.count){
				 if(issecr){
				 				  this.list=res.data.list?res.data.list:[]
				 }else{
				 				  this.list.push(...res.data.list)
				 }
				 // 加载状态结束
				 this.loading = false;
				 
				 if (this.list.length >= this.count) {
				  this.finished = true;
				  } 
			  }else{
				  this.finished = true;
				  this.count=0
				  this.list=[]
			  }
			 
			  
		  })
	  },
	  gethotcity(){
		  gethotcity().then(res=>{
			  this.popular=res.data
		  })
	  }
  },
  
  mounted() {
  	document.title = this.title;   
	getBanner({page_id:8}).then(res=>{
		this.images=res.data.list
		
	})
	this.gethotcity()
	this.getActivityDataCount()
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.train_index {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		padding-top: 15px;
		padding-bottom: 60px;
		font-size: 13px;
		.van-cell__value{
			overflow: initial;
		}
		.vanSwipe {
			width: 92%;
			margin: 0 auto 0.9375rem;
			height: 7.5rem;

			img {
				border-radius: 8px;
			}

		}

		.btnBox {
			.btn {
				// width: 7.1875rem;
				height: 5.625rem;
				background: no-repeat center;
				background-size: 109% 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: #FFFFFF;
				font-size: 12px;

				div.title {
					margin-bottom: 6px;
					margin-top: -10px;
				}

				div.btn_number {
					font-size: 28px;
					// font-weight: bold;
				}
			}

			.btn1 {
				background-image: url(../../assets/Train/bg_yellow@2x.png);
			}

			.btn2 {
				background-image: url(../../assets/Train/bg_red@2x.png);
			}

			.btn3 {
				background-image: url(../../assets/Train/bg_blue@2x.png);
			}
		}

		.list_title {
			justify-content: space-between;

			// margin: 20px 0 20px;
			.list_title_left {
				width: 0.1875rem;
				height: 1.125rem;
				background: #0081FF;
				border-radius: 0 0.09375rem 0.09375rem 0;
				margin-right: 0.75rem;
			}

			.list_title_img {
				width: 0.4375rem;
				height: 0.6875rem;
				margin-right: 0.90625rem;
				margin-left: 0.28125rem;
			}

			.left {
				font-size: 1.125rem;
				color: #28334A;
				font-weight: bold;
			}

			.right {
				font-size: 0.875rem;
				color: #0081FF;
			}
		}

		.list {
			flex-wrap: inherit;

			.label-tag {
				display: flex;
				justify-content: center;
				align-items: center;
				background: #ffb366;
				border-radius: 1rem;
				text-align: center;
				color: #fff;
				padding: 0.015rem 0.35rem;
				font-size: 0.75rem;
				margin-left: 1rem;
			}
			.ping-col {
				background: #0081FF;
			}

			.list_img {
				margin-right: 0.71875rem;
				.icon{
					position: absolute;
					top: 10px;
					left: -3px;
					z-index: 2;
				}
			}

			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 13px;
				color: #28334A;
			}

			.text {
				font-size: 0.75rem;
				color: #767e8d;
				margin-left: 0.5rem;
			}

			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}

			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}

			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}

			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}

			.btnimg_span {
				margin-right: -5px;

			}
		}

		.bottom {
			z-index: 10;
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			background: #fff;
			height: 3.75rem;
			padding: 12px 0;
			box-shadow: 0px 3px 15px 0px #919CB0;

			.bottom_input {
				width: 95%;
				height: 2.25rem;
				margin: 0 auto;
				background: #F4F4F4;
				border-radius: 1.125rem;
				position: relative;
				text-align: left;
				line-height: 2.25rem;
				padding-left: 15px;

				.bottom_input_img {
					width: 16px;
					height: 16px;
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		.Slide_up {
			z-index: 111;
			position: fixed;
			bottom: 0;
			height: 90vh;
			background: #fff;
			width: 100%;
			padding: 0.9375rem;
			text-align: left;

			// overflow: auto;
			.Slide_up_header {
				position: relative;
				font-size: 14px;
				text-align: center;

				.Slide_up_img {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					width: 30px;
					height: 30px;
					cursor: pointer;
				}
			}

			.btn {
				border-radius: 0.375rem;
				padding: 9px 13px;
				background: #F4F4F4;
				color: #919CB0;
				border: 1px solid transparent;
				margin-right: 10px;
				margin-top: 1rem;

			}

			.btn.activation {
				border-color: #0081FF;
				background: #FFFFFF;
				color: #0081FF;
			}

			.Slide_up_title {
				margin: 27px 0 0;
			}

			.btnSeach {
				width: 8.75rem;
				height: 42px;
				text-align: center;
				line-height: 42px;
				background: #0081FF;
				color: #fff;
				margin: 20px auto;
				border-radius: 21px;
			}
		}

	}
</style>
