<template>
	<div class="about">
		<div class="img">
			<van-image width="100%" :src="img" />
		</div>
		<div class="container">
			<span class="title" v-text="title"> </span>
			<div class="flex" style="margin: 16px 0; ">
				<div class="tag" v-text="" v-for="(item,i) in tagList" :key='i' v-text="item"></div>
			</div>
			<van-row class="color">
				<van-col span="2">
					<div class="flex">
						<img src="../../assets/PX/xm_icon_dianhua@2x.png" alt="" style="width: 18px"></img>
						<!-- <van-image src="../assets/PX/xm_icon_dianhua.png"></van-image> -->
					</div>
				</van-col>
				<van-col span="22">
					<span v-text="phone"></span>
				</van-col>
			</van-row>
			<van-row class="color">
				<van-col span="2">
					<div class="flex">
						<img src="../../assets/PX/xm_icon_dizhi@2x.png" alt="" style="width: 18px"></img>
						<!-- <van-image src="../assets/PX/xm_icon_dianhua.png"></van-image> -->
					</div>
				</van-col>
				<van-col span="22">
					<span v-text="address"></span>
				</van-col>
			</van-row>
			<!-- <van-cell title="单元格" :icon="icon" /> -->
		</div>
		<div class="container " style="padding-top: 0;">
			<span class="title"> 培训中心简介</span>
			<div class="van-hairline--top-bottom" style="margin-top:10px ;"></div>
			<div class="conten" v-text="conten" style="margin-top: 25px;"></div>
		</div>
	</div>
</template>

<script>

export default {
  name: 'about',
  components: {
  },
  data() {
	  return {
		  address: '中国海南海口市龙华区椰海大道390号',
		  phone: '0898-36633559',
		  icon: '~assets/PX/xm_icon_dianhua.png',
		  // 标题
		  title: '现场急救概念：什么是现场急救新概念?',
		  // 图片
		  img: 'https://img.yzcdn.cn/vant/cat.jpeg',
		  // 内容
		  conten: '在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科在现代社会发展和人类生活新的模式结构下，利用科',
		  // 展开
		  isopen: false,
		  // 培训中心列表
		  tagList: ['标签1', '标签1', '标签1']
	  }
  },
  methods: {
	  open() {
		  this.isopen=!this.isopen
	  }
  },
  mounted() {
  	document.title = this.title;
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	// .item {
	//     position: relative;
	//     &:before{
	//         content: '';
	//         position: absolute;
	//         left: 0;
	//         top: 0;
	//         bottom: auto;
	//         right: auto;
	//         width: 1px;
	//         height: 100%;
	//          padding: 0px; color: green;">@color;
	//         display: block;
	//         z-index: 1;
	//         html.pixel-ratio-2 & {
	//           .transform(scaleX(0.5));
	//         }
	//         html.pixel-ratio-3 & {
	//           .transform(scaleX(0.33));
	//         }
	//     }
	// }
	.about {
		.fg{
			width:100%;
			height:.5px;
			background:#E5E5E5;
		}
		.color {
			color: #999999;
			margin: 0.875rem 0;
		}

		.container1 {
			height: 12.5rem;
			/*  */
		}

		.title {
			font-size: 18px;
			font-weight: bold;
		}

		.conten {
			text-align: left;
			font-size: 18px;
			color: #767E8D;
			// height: 148px;
			// overflow: hidden;
			position: relative;
			transition: 2s;

		}

		.img {
			// margin: 20px 0;
		}

		.btnZk {
			position: absolute;
			right: 0;
			bottom: 0;
			background: #fff;
			box-shadow: -15px 0 14px 10px #fff;
			color: #0081FF;

		}

		.list {
			color: #0081FF;
			font-size: 16px;
			font-weight: bold;
			justify-content: space-between;
			padding: 15px 0 0;
		}

		.listBox {
			justify-content: space-between;
		}

		.tag {
			width: 3.75rem;
			height: 1.25rem;
			color: #0081FF;
			background: #F7F8FF;
			border-radius: 0.625rem;
			text-align: center;
			line-height: 1.25rem;
			margin-right: 0.625rem;
		}
	}
</style>
