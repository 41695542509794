<template>
	<div class="wallet">
		<div class="numberBox">
			<div class="numberBox_bg">
				<div class="title">钱包余额（元）</div>
				<div class="number" v-text="walletObj.loose_change?walletObj.loose_change:0"></div>
				<div class="flex" style="justify-content: space-evenly;">
					<div class="btn" @click="$router.push({name:'recharge',query:{type:1}})">充值</div>
					<div class="fen"></div>
					<div class="btn" @click="$router.push({name:'recharge',query:{type:2}})">提现</div>
				</div>
			</div>
		</div>
		<van-cell>
			<template slot="title">
				<div class="flex" style="text-align: left;" @click="$router.push({name:'UsageRecord'})">
					<div class="listImg ">
						<img :src=" require('../../assets/user/icon_syjl@2x.png')" alt="" class="auto-img">
					</div>
					<span class="custom-title listTitle">使用记录</span>
				</div>
			</template>
			<van-icon slot="right-icon" name="arrow" style="line-height: inherit;" />

		</van-cell>
		<van-cell :border="false">
			<template slot="title">
				<div class="flex" style="text-align: left;" @click="$router.push({name:'setPasswrod',query:{is_paymentcode:is_paymentcode}})">
					<div class="listImg ">
						<img :src=" require('../../assets/user/icon_zfmm@2x.png')" alt="" class="auto-img">
					</div>
					<span class="custom-title listTitle">支付密码</span>
				</div>
			</template>
			<van-icon slot="right-icon" name="arrow" style="line-height: inherit;" />

		</van-cell>
		<div style="height: 10px;"></div>
		<van-cell :border="false">
			<template slot="title">
				<div class="flex" style="text-align: left;" @click="$router.push({name:'allowance'})">
					<div class="listImg ">
						<img :src=" require('../../assets/user/icon_jintie@2x.png')" alt="" class="auto-img">
					</div>
					<span class="custom-title listTitle">津贴发放记录</span>
				</div>
			</template>
			<van-icon slot="right-icon" name="arrow" style="line-height: inherit;" />

		</van-cell>
	</div>
</template>

<script>
	import {getClientUsersAccount} from '@/api'
	export default {
  name: 'wallet',
  components: {
  },
  data(){
	  return{
		  is_paymentcode:JSON.parse(localStorage.getItem('user_data')).is_paymentcode,
		title:'我的钱包',
		walletObj:{}
	  }
  },
  methods:{
	getClientUsersAccount(){
		getClientUsersAccount().then(res=>{
			this.walletObj=res.data
		})
	}
  },
  
  mounted() {
  	document.title = this.title;   
	this.getClientUsersAccount()
	// this.gethotcity()
	// this.getActivityDataCount()
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.wallet {
		width: 100%;
		min-height: 100vh;
		background-color: #F7F8FF;

		.van-cell:not(:last-child)::after {
			border-bottom: 1px solid #E5E5E5 !important;
		}

		.numberBox {
			padding: 15px;

			.numberBox_bg {
				width: 100%;
				// height: 9.5625rem;
				background: url(../../assets/user/bg_wdqb@2x.png) no-repeat center;
				background-size: 100% 100%;
				padding: 20px 0;
				box-sizing: border-box;
				color: #fff;

				.title {
					font-size: 0.75rem;
				}

				.number {
					font-size: 1.75rem;
					margin: 26px 0;
				}

				.fen {
					width: 1px;
					height: 1.25rem;
					background-color: #fff;
				}

				.btn {
					width: 5rem;
					height: 26px;
					border: 1px solid #fff;
					text-align: center;
					line-height: 1.5rem;
					border-radius: 20px;
				}
			}
		}

		.listImg {
			width: 1.5625rem;
			height: 1.5625rem;
			margin-right: 1rem;
		}
	}
</style>
