<template>
	<div class="ApplicationDet">
		<div class="imgBox">
			<img src="../../assets/Train/bg_bmcg@2x.png" alt="" class="auto-img">
		</div>
		<div class="container1"> 
			<div class="header">恭喜您报名成功！</div>
			<div class="header_title">
				报名信息
				<van-divider />
			</div>
			<div class="conData">
				
				<van-row style='margin: 0;'>
				  <van-col span="6">
					  <span class="title">姓名</span>
					</van-col>
				  <van-col span="18">
					  <span v-text="conData.name"></span>
				  </van-col>
				</van-row>
				<van-row>
				  <van-col span="6">
					  <span class="title">联系电话</span>
					</van-col>
				  <van-col span="18">
					  <span v-text="conData.phone"></span>
				  </van-col>
				</van-row>
			
				<div class="dashed">
					<van-divider dashed :style="{ borderColor: '#F7F8FF'}" :hairline="false"></van-divider>
				</div>
				<div class="header_title">
					活动信息
					<van-divider />
				</div>
				<div slot="default" class="flex list">
					<div class="list_img list_img_pai ">
						<van-image width="100" height="75" :src="list1.img" />
						
					</div>
					<div>
						<div class="list_title">
							<span v-text="list1.keName"class=" identity"></span>
						</div>
						
						<div class="flex" style="    justify-content: space-between;">
							<!-- 标题下左右边两个div -->
							<div>
								<!-- 左边时间 -->
								<div class="flex">
									<div v-text="list1.time" class="text"></div>
								</div>
								<!-- 左边地址 -->
								<div class="flex">
									<div class="flex" >
										<div v-text="list1.site" class="text"></div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>	
		<!-- <div class="fu flex">
			<img src="../../assets/Train/icon_tips@2x.png" alt="">
			<div>
				付费协议
			</div>
		</div>
		<div class="bottom flex" style="padding:0 30px ;">
			<div style="width: 50%;position: relative;">
			<div class="btn cancel" @click="$router.go(-1)">
				取消
			</div>
			</div>
			<div style="width: 50%;position: relative;">
			<div class="btn" @click="Success">
				立即付费
			</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: 'ApplicationDet',
		components: {},
		data() {
			return {
				title:'报名详情',
				conData:{
					name:'',
					phone:1313214656
					
				},
				list1: {
						img:'https://xcjj.tysos.cn/gzh/backend/public/uploads/act_default.png',
						place:'广州市萝岗区科学城科珠路201号广东软件园G栋401室',
						keName:'成人心肺复苏及自动体外除颤器（AED）使用方法 ',
						time:'2017-12-07  13:30',
						site:'天鹰救援培训中心-广州分中心',
						cost:132
					 }	
				,
			}
		},
		methods: {
			  Success(){}
		},
		mounted() {
			document.title = this.title;
			let arr=JSON.parse(localStorage.getItem('arr'))
			this.conData.name=arr.name
			this.conData.phone=arr.phone
			this.list1.img=arr.img?arr.img:'https://xcjj.tysos.cn/gzh/backend/public/uploads/act_default.png'
			this.list1.place=arr.place
			this.list1.keName=arr.keName
			this.list1.time=arr.time
			this.list1.site=arr.site
			this.list1.cost=arr.cost
			
		}
	}
</script>

<style lang="scss">
	.van-divider::after, .van-divider::before{
		border-width:2px 0 0;
	}
	.ApplicationDet{
		.imgBox{
			// position: absolute;
			// top: 0;
			// left: 0;
			// height: 200px;
			// width: 100%;
			z-index: -1;
			position: relative;
		}
		.list {
			flex-wrap: inherit;
			padding: 15px;
			padding-top: 0;
			.list_img {
				margin-right: 0.71875rem;
				position: relative;
			}
			.list_img:after{
				content: '';
				display: block;
				position: absolute;
				bottom: 6px;
				right: 0;
				width: 1.375rem;
				height: 1.25rem;
				background:  no-repeat center;
				background-size: 100% 100%;
			}
			.list_img.list_img_1:after{
				
				background-image: url(../../assets/Train/icon_1@2x.png);
				
			}
			.list_img.list_img_2:after{
				
				background-image: url(../../assets/Train/icon_2@2x.png);
				
			}
			.list_img.list_img_3:after{
				
				background-image: url(../../assets/Train/icon_3@2x.png);
				
			}
			
		
			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}
		
			.text {
				font-size: 0.75rem;
				color: #919CB0;
				// margin-left: 0.5rem;
			}
		
			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}
		
			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}
		
			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}
		
			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}
		
			.btnimg_span {
				margin-right: -5px;
		
			}
		}
		.container1{
			margin: 15px;
			background-color: #fff;
			// height: 280px;
			z-index: 2;
			border-radius: 0.375rem;
			text-align: left;
			margin-top: -50px;
			
			.header{
				text-align: center;
				font-size: 1.125rem;
				font-weight: bold;
				padding-top: 20px;
			}
			.header_title{
				font-weight: bold;
				font-size: 15px;
				padding: 15px 11px;
				
			}
			.conData{
				// padding: 28px 0px;
				font-size: 15px;
				
			}
			.cost{
				color: #0081FF;
			}
			.title{
				color: #919CB0;
			}
			.van-row{
				margin: 14px 0;
				padding: 0 15px;
			}
			.dashed{
				position: relative;
				&::after{
					position: absolute;
					top: -8px;
					left: 0px;
					width: 8px;
					height: 16px;
					content: '';
					display: block;
					background: url(../../assets/Train/bg_yuan_left@2x.png) no-repeat center;
					background-size: 100% 100%;
				}
				&::before{
					position: absolute;
					top: -8px;
					right: 0px;
					width: 8px;
					height: 16px;
					content: '';
					display: block;
					background: url(../../assets/Train/bg_yuan_right@2x.png) no-repeat center;
					background-size: 100% 100%;
				}
			}
		}
		.fu{
			padding: 15px;
			color:#919CB0;
			img{
				width: 0.75rem;
				height: 0.75rem;
				margin-right: 0.5625rem;
			}
		}
		.cancel{
			background-color: #FFFFFF;
			border: 1px solid #0081FF;
			color: #0081FF;
			font-size: 15px;
		}
	}
</style>
