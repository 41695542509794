<template>
  <div class="consulting-service-wrap">
    <van-form @submit="onSubmit">
      <van-cell-group>
        <van-field
          v-model="formObj.uphone"
          name="phone"
          label="联系电话"
          placeholder="请输入联系电话"
          :rules="[{ pattern, required: true, message: '请输入11位手机电话' }]"
        />
        <van-field
          class="textarea-fidle"
          v-model="formObj.question"
          rows="2"
          name="question"
          autosize
          type="textarea"
          maxlength="50"
          placeholder="请输入问题内容，不少于10字"
          :rules="[{ required: true, message: '请填写内容' }]"
          show-word-limit
        />
      </van-cell-group>
      <div class="look-msg" @click="lookMsgList">查看消息列表>></div>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { userInsConsultant } from '@/api';
export default {
  name: "toConsultingService",
  data() {
    return {
      title: "我要咨询",
      ins_id: 0,
      pattern: /^1[3-9]\d{9}$/,
      formObj: {
        question: '',
        phone: ''
      },
    };
  },
  methods: {
    onSubmit(values) {
      if(values.question.length < 10) {
        return Toast('输入内容应不少于10字')
      }
      userInsConsultant({ ...values, ins_id: this.ins_id }).then(res => {
        if(res.code === 1) {
          Toast('提交成功');
          this.lookMsgList();
          this.formObj.question = '';
          this.formObj.phone = '';
          // setTimeout(() => {
          //   this.$router.go(-1);
          // }, 1000)
        } else {
          Toast(res.msg);
        }
      })
    },
    lookMsgList() {
      this.$router.push({
        name: 'consultingServiceList'
      })
    },
  },
  mounted() {
    document.title = this.title;
    this.ins_id = this.$route.query.id;
  },
};
</script>

<style lang="scss" scoped>
.consulting-service-wrap {
  width: 100%;
  min-height: 100vh;
  background: #f7f8ff;
  /* padding: 0.5rem; */
  .textarea-fidle {
    margin-top: 0.5rem;
  }
  .look-msg {
    color: #ffad55;
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }
}
</style>