<template>
    <div class="train-invitation-wrap">
        <p class="explain">若有救护培训相关需求，您可填写该申请表，预约机构培训，机构会尽快与您联系</p>
        <div class="in-form">
            <van-form @submit="onSubmit">
                <van-cell-group>
                    <van-field
                        v-model="formObj.uname"
                        name="uname"
                        label="姓名"
                        placeholder="请输入姓名"
                        :rules="[{ required: true, message: '请输入姓名' }]"
                    />
                    <van-field
                        v-model="formObj.uphone"
                        name="uphone"
                        label="联系电话"
                        placeholder="请输入联系电话"
                        :rules="[{ pattern, required: true, message: '请输入11位手机电话' }]"
                    />
                    <van-field
                        is-link
                        readonly
                        name="date"
                        :value="formObj.date"
                        label="日期"
                        placeholder="点击选择日期"
                        :rules="[{ required: true, message: '请选择日期' }]"
                        @click="showCalendar = true"
                    />
                    <van-calendar v-model="showCalendar" @confirm="onConfirm" />
                    <van-field
                        v-model="formObj.address"
                        name="address"
                        label="邀约地点"
                        placeholder="请输入邀约地点"
                        :rules="[{ required: true, message: '请填写邀约地点' }]"
                    />
                    <van-field
                        v-model="formObj.remark"
                        name="remark"
                        label="备注"
                        placeholder="请输入备注"
                    />
                </van-cell-group>
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">
                    提交
                    </van-button>
                </div>
                </van-form>
        </div>
    </div>
</template>

<script>
import { insTrainInvited } from '@/api';
import { Toast } from 'vant';
export default {
    name: 'trainInvitation',
    data() {
        return {
            title: '培训邀约',
            ins_id: 0,
            showCalendar: false,
            pattern: /^1[3-9]\d{9}$/,
            formObj: {
                uname: '',
                uphone: '',
                date: '',
                address: '',
                remark: ''
            },
        }
    },
    methods: {
        onConfirm(date) {
            this.showCalendar = false;
            this.formObj.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        },
        onSubmit(values) {
            insTrainInvited({ ...values,  ins_id: this.ins_id}).then(res => {
                if(res.code === 1) {
                    Toast('提交成功');
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000)
                } else {
                    Toast(res.msg);
                }
            })
        }
    },
    mounted() {
  	    document.title = this.title;
        this.ins_id = this.$route.query.id;
    }
}
</script>

<style lang="scss" scoped>

.train-invitation-wrap {
    width: 100%;
    min-height: 100vh;
    background: #F7F8FF;

    .explain {
        color: rgb(153, 153, 153);
        font-size: 1rem;
        text-align: left;
        padding: 0.5rem 1rem;
        background: #fff;
    }
    .in-form {
        width: 100%;
    }
    
}
</style>
<style lang="scss">
$mainColor: rgb(28, 145, 255);
.van-calendar__selected-day {
    background: $mainColor;
}
.van-button--danger {
    background: $mainColor;
    border: 1px solid $mainColor;
}
</style>