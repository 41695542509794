<template>
  <div class="audit-wrap">
    
    <div class="radios" v-if="actTeacherList.length > 0">
        <van-radio-group v-model="result" @change="radioClick">
            <van-radio :name="i" v-for="(item,i) in actTeacherList" :key="i" :style="i==actTeacherList.length-1?'':'margin-bottom:10px ;'">
                <img :src="item.user_head_img" alt="">
                <span v-text="`${item.sign_name}（${item.is_zhujiang_teacher == 1 ? '主讲' : '助教'}）`"></span>
            </van-radio>
        </van-radio-group>
    </div>
    <div v-else>暂无数据~</div>

    <div class="bottom" v-if="actTeacherList.length > 0">
        <div class="common" :class="[itemSelected  ? 'bottom_input' : 'disable']" @click="sure">确定
        </div>
      </div>

  </div>
</template>


<script>
import { getActTeacherList, distributionStudents } from '@/api';
import { Toast } from 'vant';
export default {
  name: 'distributionAssistant',
  data(){
	  return{
      title: "选择助教",
      act_id: '',
      resultGroupStr: '',
      actTeacherList: [],
      result: [],
      itemSelected: '',
	  }
  },
  methods:{
	getActTeacherListInfo() {
      getActTeacherList({act_id: this.act_id }).then(res => {
        if(res.code === 1) {
          this.actTeacherList = res.data;
        }
      })
    },
    radioClick() {
        this.itemSelected = this.actTeacherList[this.result].user_id;
    },
    sure() {
      if(!this.itemSelected) return;
      distributionStudents({
          act_id: this.act_id,
          teacher_id: this.itemSelected,
          user_ids: this.resultGroupStr,
        }).then(res => {
            Toast(res.msg)
            if(res.code == 1) {
                setTimeout(() => {
                    this.$router.go(-1);
                }, 1000)
            }
        })
    }
  },
  
  mounted() {
    this.act_id = this.$route.query.act_id;
    this.resultGroupStr =  this.$route.query.resultGroupStr;
  	document.title = this.title;
    this.getActTeacherListInfo();
  }
}
</script>
<style lang="scss" type="text/css" scoped>
.audit-wrap {
  padding-top: 1rem;
  min-height: 100vh;
  background: #F7F8FF;
  
  .radios {
    padding: 0 15px;
    img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin: 0 10px;
    }
  }
  .bottom {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			background: #fff;
			height: 5rem;
			z-index: 66;
			box-shadow: 0px 3px 15px 0px #919CB0;
      .common {
        font-size: 14px;
				width: 75%;
				height: 2.625rem;
				margin: 0 auto;
        border-radius: 50px;
				position: relative;
				text-align: left;
				line-height: 2.625rem;
				text-align: center;
				color: #fff;
				transform: translateY(50%);
      }
			.bottom_input {
				background: #0081FF;
			}
      .disable {
				background: #919CB0;
			}
		}
}
</style>