<template>
	<div class="ScanCodeLogin">
		<div v-if="status==99">
			<img src="../../assets/user/pic_glptlogo@2x.png" alt="" width="48%" style="margin: 36px 0;">
			<div style="font-weight: bold;">现场急救教学管理平台登录确认</div>
			<div class="loginName">
				登录导师：<span v-text="name"></span>
			</div>
			<div class="btnBox">
				<div class="loginBtnDe" @click="loginBtnDe">确定登录</div>
				<div class="loginBtnCancel" @click="close">取消</div>
			</div>
		</div>
		<div v-if="status==1">
			<img src="../../assets/user/icon_jinggao@2x.png" alt="" width="50" style="margin: 36px 0;">
			<div style="font-weight: bold;width: 60%;text-align: center;margin: 0 auto;">您的帐号身份不是导师，未能登录现场急救教学管理平台</div>
		</div>
		<div v-if="status==2">
			<img src="../../assets/user/icon_jinggao@2x.png" alt="" width="50" style="margin: 36px 0;">
			<div style="font-weight: bold;width: 60%;text-align: center;margin: 0 auto;">登录超时，请重新扫码</div>
		</div>
		<div v-if="status==0">
			<img src="../../assets/user/icon_jinggao@2x.png" alt="" width="50" style="margin: 36px 0;">
			<div style="font-weight: bold;width: 60%;text-align: center;margin: 0 auto;">登录失败 <span v-text="'('+ msg +')'"></span>，请重新扫码登录</div>
		</div>
		<div v-if="status==3">
			<img src="../../assets/user/icon_jinggao@2x.png" alt="" width="50" style="margin: 36px 0;">
			<div style="font-weight: bold;width: 60%;text-align: center;margin: 0 auto;">该帐号已登录，请勿重复登录</div>
		</div>
		<div v-if="status==5">
			<img src="../../assets/user/icon_dlcg@2x.png" alt="" width="50" style="margin: 36px 0;">
			<div style="font-weight: bold;width: 60%;text-align: center;margin: 0 auto;">登录成功</div>
		</div>
	</div>
</template>

<script>
	import {getLoginResult} from '@/api'
	import { Toast } from 'vant';
	export default {
		name: 'ScanCodeLogin',
		data() {
			return {
				name:'',
				isTutor:1,
				status:99,
				msg:''
			}
		},
		components: {
			// imageClipper
		},
		methods: {
			loginBtnDe(){
				getLoginResult({logincode:this.$route.query.code}).then(res=>{
					console.log(res)
					if(res.code==1){
						this.status=res.status
					}else if(res.code==0){
						this.status=0
						this.msg=res.msg
					}
				})
			},
			close(){
				
			}
		},
		
		mounted() {
			this.name=localStorage.getItem('user')
			// this.isTutor=localStorage.getItem('user_type')<2?2:this.isTutor
			document.title = '扫码登录'
			//监听返回键
		}
		
	}
</script>
<style lang="scss" scoped>
	.ScanCodeLogin {
		width: 100%;
		background-color: #fff;
		min-height: 100vh;
		position: relative;
		padding-bottom: 82px;
		.loginName{
			font-size:14px;
			font-family:PingFang SC;
			font-weight:500;
			color:rgba(117,125,141,1);
			margin-top: 16px;
		}
		.btnBox{
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 0 15px;
			.loginBtnDe{
				width:100%;
				height:42px;
				background:rgba(0,129,255,1);
				box-shadow:0px 5px 10px 0px rgba(0,129,255,0.3);
				border-radius:42px;
				color:rgba(255,255,255,1);
				line-height: 42px;
			}
			.loginBtnCancel{
				width:100%;
				height:70px;
				color:rgba(117,125,141,1);
				line-height: 70px;
			}
		}
		
	}
</style>
