<template>
	<div class="toUpdate">
		<div class="flex listBox" style="justify-content: space-between;align-items: initial;	" v-for="(item,i) in list" :key="i" v-if="list.length" @click="$router.push({name:'toUpdateDel',query:{tId:item.id}})">
			<div class="flex" style="align-items: initial;width: calc(100% - 100px);flex-wrap: nowrap;">
				<div class="yuan" :class="item.red_sign?'huiBg':''"></div>
				<div style="width: 100%;" >
					<!-- <p class="one" ></p>
					<p class="tow" ></p> -->
					<div class=" one"  v-text="item.desc"></div>
					
				</div>
			</div>
			<div class="hui time" v-text="item.updatetime">
			</div>
			<div class="van-multi-ellipsis--l2 tow" v-text="item.asct">
			</div>
		</div>
		<div style="text-align: center;" v-if="!list.length">
			暂无数据
		</div>
	</div>
</template>

<script>
import {getKnowledge} from '@/api'
import { dateFormat } from '@/util/setTime.js'
	export default {
  name: 'toUpdate',
  components: {
  },
  data(){
	  return{
		 list:[
			 
		 ]
	  }
  },
  methods:{
	 dateFormat(date){
		 return dateFormat('YYYY-mm-dd',date)
	 }
  },
  mounted() {
  	document.title = '知识更新';  
	 getKnowledge().then(res=>{
		 this.list=res.data
		 this.list.forEach(item=>{
			 item.updatetime=item.updatetime.split(' ')[0]
		 })
	 })
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.toUpdate {
		min-height: 100vh;
		background-color: #fff;
		padding: 0 15px;
		text-align: left;

		.listBox {
			padding: 16px 0;
			border-bottom: 1px solid rgba(229, 229, 229, 1);

			.one {
				margin-bottom: 11.5px;
			}
			.tow{
				font-size: 12px;
				padding-left: 20px;
			}

			.yuan {
				margin-right: 12.5px;
				position: relative;
				flex: 0 0 8px;
				top: 5px;
				height: 8px;
				background-color: rgba(255, 82, 77, 1);
				border-radius: 50%;
			}
			.time{
				font-size: 12px;
				letter-spacing: 0;
				top: 5px;
				position: relative;
			}
			.huiBg{
				
			background-color: #C6C8CA;
			}
		}
	}
</style>
