<template>
	<div class="signSuccess">
		<div class="imgBox">
			<img src="../../assets/Train/bg_bmtop@2x.png" alt="" class="auto-img">
		</div>
		<div class="container1">
			<div class="conData">
				<van-row style='margin: 0;'>
				  <van-col span="6">
					  <span class="title">承办站点</span>
					</van-col>
				  <van-col span="18">
					  <span v-text="conData.site"></span>
				  </van-col>
				</van-row>
				<van-row>
				  <van-col span="6">
					  <span class="title">培训课程</span>
					</van-col>
				  <van-col span="18">
					  <span v-text="conData.keName"></span>
				  </van-col>
				</van-row>
				<van-row>
				  <van-col span="6">
					  <span class="title">培训时间</span>
					</van-col>
				  <van-col span="18">
					  <span v-text="conData.time"></span>
				  </van-col>
				</van-row>
				<van-row>
				  <van-col span="6">
					  <span class="title">培训地点</span>
					</van-col>
				  <van-col span="18">
					  <span v-text="act_addr"></span>
				  </van-col>
				</van-row>
				<div class="dashed">
					<van-divider dashed :style="{ borderColor: '#F7F8FF'}" :hairline="false"></van-divider>
				</div>
				<van-row style="margin-top: 25px;">
				  <van-col span="6">
					  <span class="title">培训费用</span>
					</van-col>
				  <van-col span="18">
					  <span v-text="'￥'+conData.cost+'/人'" class="cost"></span>
				  </van-col>
				</van-row>
			</div>
		</div>	
		<!-- <div class="fu flex">
			<img src="../../assets/Train/icon_tips@2x.png" alt="">
			<div>
				付费协议
			</div>
		</div> -->
		<div class="bottom flex" style="padding:0 30px ;">
			<div style="width: 50%;position: relative;">
			<div class="btn cancel" @click="$router.go(-1)">
				取消
			</div>
			</div>
			<div style="width: 50%;position: relative;">
			<div class="btn" @click="Success">
				立即付费
			</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {signUpDetail,userRechargeUniOrder,getActAddress} from '@/api'
	// import vConsole from '@/util/vconsole.js'
	import {
	  Toast
	} from 'vant';
	export default {
		name: 'signSuccess',
		components: {},
		data() {
			return {
				userDel:{},
				// 订单号
				order_id:'',
				title:'报名详情',
				act_addr:'',
				conData:{
					/**
					 * place:'地点'
					 * keName:课程名字
					 * time:'时间'
					 * site：站点
					 * Cost：费用
					 * */
					place:'广州市萝岗区科学城科珠路201号广东软件园G栋401室',
					keName:'成人心肺复苏及自动体外除颤器（AED）使用方法 ',
					time:'2017-12-07  13:30',
					site:'天鹰救援培训中心-广州分中心',
					cost:132
					
				}
			}
		},
		methods: {
			  Success(){
				  userRechargeUniOrder({total_fee:this.conData.cost,select_type:3,order_id:this.order_id}).then(res=>{
				  			  // console.log(res)
				  			  this.jsApiCall(res.data)
				  })
			  },
			  jsApiCall(jsApiParameters){
				  let _this=this
			  	// console.log(jsApiParameters);return;
			  	// alert(2);
			  	let jsondata = JSON.parse(jsApiParameters);
			  	// console.log('thisssss'+jsApiParameters);
			  	WeixinJSBridge.invoke(
			  		'getBrandWCPayRequest',
			  		 jsondata,
			  		function(res){
					  if(res.err_msg == "get_brand_wcpay_request:ok"){
						  let arr={
								name:_this.userDel.sign_name,
								phone:_this.userDel.sign_phone,
								img:'https://xcjj.tysos.cn/gzh/backend/public/uploads/act_default.png',
								place:_this.$route.query.place,
								keName:_this.$route.query.keName,
								time:_this.$route.query.time,
								site:_this.$route.query.site,
								cost:_this.$route.query.cost
							}
						  Toast("微信支付成功!");
						  _this.$router.push({name:'signSuccess'})
						  localStorage.setItem('arr',JSON.stringify(arr))
						}else if(res.err_msg == "get_brand_wcpay_request:cancel"){
							 Toast("用户取消支付!");
							 
						}else{
							Toast("支付失败!");
						}
			  	// 		WeixinJSBridge.log(res.err_msg);
						// console.log(res)
						// Toast(res.err_code+res.err_desc+res.err_msg)
			  	// 		// alert(res.err_code+res.err_desc+res.err_msg);
			  	// 			this.getOfficialActivityTutorApplyDetail()
			  		}
			  	);
			  },
			  
		},
		mounted() {
			
			document.title = this.title;
			signUpDetail({sign_id:this.$route.query.signin_id}).then(res=>{
				this.order_id=res.data.order_id
				this.userDel=res.data
			})
			
			this.conData={
				place:this.$route.query.place,
				keName:this.$route.query.keName,
				time:this.$route.query.time,
				site:this.$route.query.site,
				cost:this.$route.query.cost
			}
			this.conData.cost=this.conData.cost?this.conData.cost:0.01
			getActAddress({act_id:this.$route.query.act_id}).then(res=>{
				this.act_addr=res.data
			})
		}
	}
</script>

<style lang="scss">
	.van-divider::after, .van-divider::before{
		border-width:2px 0 0;
	}
	.signSuccess{
		.imgBox{
			position: absolute;
			top: 0;
			left: 0;
			height: 200px;
			width: 100%;
			z-index: -1;
		}
		.container1{
			margin: 15px;
			background-color: #fff;
			height: 280px;
			z-index: 2;
			border-radius: 0.375rem;
			text-align: left;
			.conData{
				padding: 28px 0px;
				font-size: 15px;
				
			}
			.cost{
				color: #0081FF;
			}
			.title{
				color: #919CB0;
			}
			.van-row{
				margin: 14px 0;
				padding: 0 15px;
			}
			.dashed{
				position: relative;
				&::after{
					position: absolute;
					top: -8px;
					left: 0px;
					width: 8px;
					height: 16px;
					content: '';
					display: block;
					background: url(../../assets/Train/bg_yuan_left@2x.png) no-repeat center;
					background-size: 100% 100%;
				}
				&::before{
					position: absolute;
					top: -8px;
					right: 0px;
					width: 8px;
					height: 16px;
					content: '';
					display: block;
					background: url(../../assets/Train/bg_yuan_right@2x.png) no-repeat center;
					background-size: 100% 100%;
				}
			}
		}
		.fu{
			padding: 15px;
			color:#919CB0;
			img{
				width: 0.75rem;
				height: 0.75rem;
				margin-right: 0.5625rem;
			}
		}
		.cancel{
			background-color: #FFFFFF;
			border: 1px solid #0081FF;
			color: #0081FF;
			font-size: 15px;
		}
	}
</style>
