<template>
	<div class="mentorList">
		<div v-if="!count" class="default">
			<img src="../../assets/default/icon_zanwuwdxy@2x.png" alt="" class="default_img">
			<p>暂无学员</p>
		</div>
		<div>
			<!-- @load="onLoad" -->
			<van-list v-model="loading" :finished="finished" @load="onLoad">
				<van-cell v-for="(item,index) in list" :key="index" :style="index!==1?'padding-top:0':''" style="padding-bottom: 0;" @click="homepageClick(item)">
					<div slot="default" class="flex list" style="align-items: end;">
						<div class="list_img list_img_pai " :class="['list_img_'+(item.sex+1)]">
							<van-image width="60" height="60" :src="item.user_head_img" radius='50%'>
								<div slot="error">
									<img :src="item.sex==1?nan:nv" alt="" width="60">
								</div>
							</van-image>
							<div class="sexImg">
								<img src="../../assets/Train/icon_nv_red@2x.png" alt="" class="auto-img" v-if="item.sex==2">
								<img src="../../assets/Train/icon_nan_blue@2x.png" alt="" class="auto-img"  v-if="item.sex==1">
							</div>
						</div>

						<div>
							<div class="list_title">
								<span v-text="item.sign_name" style="font-size: 15px;"></span>
							</div>


							<div class="flex" style="font-size: 12px;    justify-content: space-between;">
								<div>
									<span class="hui">
										当前身份：
									</span>
									<span v-text="item.user_type==1?'学员':item.user_type==2?'导师':item.user_type==3?'主任导师':'普通用户'">

									</span>
								</div>
								<div style="margin-left: 5px;">
									<span class="hui">
										所在城市：
									</span>
									<span v-text="item.city">

									</span>
								</div>
							</div>
							<div style="font-size: 12px;padding-bottom: 20px;" @click.stop="$router.push({name:'traindal',query:{act_id:item.act_id}})">
								<span class="hui">
									参与课程：
								</span>
								<span v-text="item.act_name" class="blue" >

								</span>
							</div>
						</div>
					</div>
				</van-cell>
			</van-list>
		</div>
		<div class="bottom " style="height: 44px;" v-if="isDirector==3">
			<!-- <input v-model="value" placeholder="" style="background-color: transparent;"  @blur="getFocus"></input>
				<img src="../../assets/Train/icon_seach@2x.png" alt="" class="bottom_input_img"> -->
			<div @click="isac=!isac" class="flex">
				<div class="xue " :style="isac?'color: #28334A;':''"><span v-text="act_type==1?'学员班':'导师班'"></span></div>
				<div>
					<img src="../../assets/user/icon_slak_nor@2x.png" alt="" width="14" v-if="!isac">
					<img src="../../assets/user/icon_slak_pre@2x.png" alt="" width="14" v-if="isac">
				</div>
			</div>
			<div class="abslo" :style="isac?'top:-194%':'top:100%'">
				<div @click="screenClick(1)">学员班</div>
				<div @click="screenClick(2)">导师班</div>
			</div>
		</div>

		<!-- 弹窗内容 -->
		<!-- <van-popup v-model="isAreaList" position="bottom">
			<div style="width: 100%;">
				<van-area :area-list="areaList" :columns-num="2" title="地址" @confirm="confirm" @cancel="cancel" />
			</div>
		</van-popup> -->
		<!-- <van-calendar v-model="isdata" @confirm="onConfirm" color="#0081FF" /> -->
		<!-- <van-calendar v-model="isdata_Section" type="range" @confirm="onConfirm_Section" color="#0081FF" /> -->
		<!-- <transition name="van-slide-up">
		  <div v-show="isAreaList" style="z-index: 3;position: fixed;bottom: 0;left: 0;width: 100%; ">
			 
		  </div>
		</transition> -->

	</div>
</template>

<script>
	import { getBanner,getStudentLists } from '@/api'
export default {
  name: 'mentorList',
  components: {
  },
  data(){
	  return{
		  isDirector:localStorage.getItem('user_type'),
		  isac:false,
		  nan:require('../../assets/user/icon_mrtx_nan@2x.png'),
		  nv:require('../../assets/user/icon_mrtx_nv@2x.png'),
		  sex:0,
		 /*搜索的值*/
		 value:'',
		 
		// 控制搜索显示隐藏
		visible:false,
		title:'我的学员',
		images: [
		  'https://img.yzcdn.cn/vant/apple-1.jpg',
		  'https://img.yzcdn.cn/vant/apple-2.jpg'
		],
		total_number:100,
		signUp_number:100,
		student_number:1000,
		/*list为放到页面渲染的，list1为请求的所有数据做懒加载处理*/
		list:[],
		/**
		 * identity:身份
		 * autograph：签名
		 * follow：关注
		 * name：姓名
		 * img：头像
		 * addres：地址
		 * company：公司
		 * 
		 * */
		list1: [	
		],
		loading: false,
		finished: false,
		count:1,
		page:0,
		limit:'8',
		secah:{},
		
		act_type:1
	  }
  },
  watch:{
	  'value'(a,b){
		  console.log(a,b)
		  this.page='1'
		  this.list=[]
		  this.loading=false,
		  this.finished=false,
		  this.getStudentLists(this.secah,{page:String(this.page),limit:this.limit,user_name:a})
	  }
  },
  methods:{
	  // 跳转导师主页
	  homepageClick(item){
		  console.log(item)
		  if(item.user_type>1){
			  this.$router.push({name:'tutorHomepage',query:{id:item.user_id}})
		  }
	  },
	  // 筛选学员班and导师班
	  screenClick(act_type){
		  console.log(act_type)
		  this.act_type=act_type
		  this.page=0
		  this.list=[]
		  if(this.page==0){
			  this.onLoad()
		  }
		  this.isac=false
	  },
	  // 我的学员
	  getStudentLists(issecr,obj){
	  		  // console.log(obj)
	  		  getStudentLists(obj).then(res=>{
				  this.count=res.data.count
				  if(res.data&&this.list.length< this.count){
				  				 if(issecr){
				  				 				  this.list=res.data.list?res.data.list:[]
				  				 }else{
				  				 				  this.list.push(...res.data.list)
				  				 }
				  				 // 加载状态结束
				  				 this.loading = false;
				  				 
				  				 if (this.list.length >= this.count) {
				  				  this.finished = true;
				  				  } 
				  }else{
				  				  this.finished = true;
				  				  this.list=[]
				  }
		})		  			 
	  },
	onLoad() {
			// 每页条数
			
			// 页码
			getStudentLists({act_type:this.act_type}).then(res=>{
				this.count=res.data.count
			})
			setTimeout(()=>{
				this.page+=1
				// 数据全部加载完成
				if (this.list.length >= this.count) {
				 this.finished = true;
				}else{
					  this.getStudentLists(false,{page:this.page,limit:this.limit,...this.secah,act_type:this.act_type})
					  // console.log('dd')
				}
				// console.log(this.list)
			},1000)
	      },
		  activationClick(text){
			  this.activation=text
		  },
		  cancel(){
			  console.log('222')
			  this.isAreaList=false
		  },
		  confirm(res){
			  // console.log(res)
			this.province=res[0].name
			this.city=res[1].name
			 this.isAreaList=false
		  }
  },
  
  mounted() {
  	document.title = this.title; 
	  
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.mentorList {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		padding-bottom: 60px;

		.vanSwipe {
			width: 92%;
			margin: 0 auto 0.9375rem;
			height: 7.5rem;

			img {
				border-radius: 8px;
			}

		}

		.identity {
			color: #919CB0;
			font-size: 12px;
			font-weight: 100;
			margin-left: 0.8125rem;
		}

		.btnBox {
			.btn {
				width: 7.1875rem;
				height: 5.625rem;
				background: no-repeat center;
				background-size: 109% 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: #FFFFFF;
				font-size: 12px;

				div.title {
					margin-bottom: 6px;
					margin-top: -10px;
				}

				div.btn_number {
					font-size: 28px;
					font-weight: bold;
				}
			}

			.btn1 {
				background-image: url(../../assets/Train/bg_yellow@2x.png);
			}

			.btn2 {
				background-image: url(../../assets/Train/bg_red@2x.png);
			}

			.btn3 {
				background-image: url(../../assets/Train/bg_blue@2x.png);
			}
		}

		.list_title {
			justify-content: space-between;

			// margin: 20px 0 20px;
			.list_title_left {
				width: 0.1875rem;
				height: 1.125rem;
				background: #0081FF;
				border-radius: 0 0.09375rem 0.09375rem 0;
				margin-right: 0.75rem;
			}

			.list_title_img {
				width: 0.4375rem;
				height: 0.6875rem;
				margin-right: 0.90625rem;
				margin-left: 0.28125rem;
			}

			.left {
				font-size: 1.125rem;
				color: #28334A;
				font-weight: bold;
			}

			.right {
				font-size: 0.9375rem;
				color: #0081FF;
			}
		}

		.list {
			flex-wrap: inherit;
			border-bottom: 1px solid #F4F4F4;
			// padding-bottom: 15px;
			padding-top: 20px;

			.list_img {
				margin-right: 0.71875rem;
				position: relative;
				.sexImg{
					position: absolute;
					bottom: 5px;
					right: 0px;
					width: 1.25rem;
					height: 1.25rem;
				}
			}

			.list_img:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 6px;
				right: 0;
				width: 1.375rem;
				height: 1.25rem;
				background: no-repeat center;
				background-size: 100% 100%;
			}

			// .list_img.list_img_1:after {

			// 	background-image: url(../../assets/Train/icon_nan_blue@2x.png);

			// }

			// .list_img.list_img_2:after {

			// 	background-image: url(../../assets/Train/icon_nv_red@2x.png);

			// }

			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}

			.text {
				font-size: 0.75rem;
				color: #919CB0;
				margin-left: 0.5rem;
			}

			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}

			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}

			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}

			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}

			.btnimg_span {
				margin-right: -5px;

			}
		}

		.bottom {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			background: #fff;
			height: 3.75rem;
			padding: 12px 15px;
			box-shadow: 0px 3px 6px 0px #919CB0;

			.xue {
				text-align: left;
				color: #919CB0;
				margin-right: 8px;
				position: relative;
			}

			.abslo {
				width: 90px;
				padding: 0 5px;
				background-color: #F1F1F1;
				color: #919CB0;
				position: absolute;
				// top: 100%;
				left: 0;
				transition: all .5;
				z-index: -1;

				div {
					padding: 11px 0;
					border-bottom: 1px solid #E5E5E5;

				}
			}

			.abslo_ac {
				top: -194%;
			}
		}

		.Slide_up {
			z-index: 2;
			position: fixed;
			bottom: 0;
			height: 90vh;
			background: #fff;
			width: 100%;
			padding: 0.9375rem;
			text-align: left;

			// overflow: auto;
			.Slide_up_header {
				position: relative;
				font-size: 14px;
				text-align: center;

				.Slide_up_img {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					width: 30px;
					height: 30px;
					cursor: pointer;
				}
			}

			.btn {
				border-radius: 0.375rem;
				padding: 9px 13px;
				background: #F4F4F4;
				color: #919CB0;
				border: 1px solid transparent;
				margin-right: 10px;
				margin-top: 1rem;

			}

			.btn.activation {
				border-color: #0081FF;
				background: #FFFFFF;
				color: #0081FF;
			}

			.Slide_up_title {
				margin: 27px 0 0;
			}

			.btnSeach {
				width: 8.75rem;
				height: 42px;
				text-align: center;
				line-height: 42px;
				background: #0081FF;
				color: #fff;
				margin: 20px auto;
				border-radius: 21px;
			}
		}

	}
</style>
