<template>
	<div class="supervisionNotice">
		<div class="super">
			<!-- <van-row class="vanRowBox"> -->
			<!--  <van-col span="8"> <span class="hui">督导预约状态</span></van-col>
			  <van-col span="16" v-if="ApplyDetail.tutor_res_status!==3"><span class="bold" v-text="ApplyDetail.tutor_status==2?'未预约':ApplyDetail.tutor_status==3?'已接受，预约人未付款':ApplyDetail.tutor_status==4?'预约成功':''"></span></van-col>
			  <van-col span="16" v-if="ApplyDetail.tutor_res_status==3"><span class="bold refuse">已拒绝</span></van-col>
			</van-row> -->
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">活动名称</span></van-col>
			  <van-col span="16">【<span v-text="ApplyDetail.act_name"></span>】</van-col>
			</van-row>
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">活动时间</span></van-col>
			  <van-col span="16"><span v-text="ApplyDetail.act_start_time"></span></van-col>
			</van-row>
			<van-row class="vanRowBox">
			  <van-col span="8"> <span class="hui">开课培训地点</span></van-col>
			  <van-col span="16"><span v-text="ApplyDetail.act_addr"></span></van-col>
			</van-row>
			<div class="van-hairline--top" style="width: 100%;height: 1px;background-color: #E5E5E5;"></div>
			<div class="flex" style="justify-content: center;margin-top: 18px;">
				<span class="user_name" v-text="orginfo.user_name"></span><span style="margin: 0 16px;color: #0081FF;">替换</span><span class="user_name" v-text="newinfo.user_name"></span>
			</div>
		</div>
		<van-popup v-model="show" :round="true" class="dudao">
			<div class="popupBox">
				<div class="title">
					拒绝原因
				</div>
				<van-field
				  v-model="message"
				  rows="2"
				  type="textarea"
				  placeholder="请填写拒绝原因"
				  style="border-radius: 6px;background-color: #F4F4F4;"
				/>
				<div class="flex btnBox">
					<div @click="show=false">暂不取消</div>
					<div class="suss" @click="refuseClick">确定</div>
				</div>
			</div>
		</van-popup>
		<!-- 顶部 -->
		
		<div class="bottom" v-if="!isUser&&is_accept!==2">
			<div class="btn btn1 btnBor" @click="refuseClick">
				拒绝
			</div>
			<div class="btn btn1" @click="acceptClick">
				接受邀请
			</div>
		</div>
		<div class="bottom" v-if="isUser&&is_accept!==2">
			<div class="btn btn1 btnBor" style="background-color: #DDDDDD;border-color: #DDDDDD;color: #fff;">
				拒绝
			</div>
			<div class="btn btn1" style="background-color: #DDDDDD;">
				接受邀请
			</div>
		</div>
		<div class="bottom" v-if="isUser&&is_accept==1" >
			<div class="btn btn1 act_btn">
				已接受邀请
			</div>
		</div>
		<div class="bottom" v-if="isUser&&is_accept==2" >
			<div class="btn btn1 act_btn" >
				已拒绝邀请
			</div>
		</div>
	</div>
</template>

<script>
import { getChangeInfoOfficialSignupTutor,getChangeOfficialSignupTutor } from '@/api';
import { Toast } from 'vant'
import areaList from '../../area.js'

export default {
  name: 'supervisionNotice',
  components: {
  },
  data(){
	  return{
		  tutorlist:[],
		  buttonable:0,
		  message:'',
		show:false,
		// 主任导师督导状态
		tutor_res_status:1,
		// 活动督导状态
		tutor_status:1,
		ApplyDetail:[],
		//替换人 
		orginfo:{},
		//被替换人 
		newinfo:{},
		// 用户接受或拒绝状态
		 is_accept:0,
		 isUser:false
	  }
  },

  methods:{
	  acceptClick() {
	    getChangeOfficialSignupTutor({ act_id: this.$route.query.act_id, action_type: 1,org_user_id:this.$route.query.org_user_id ,note_id:this.$route.query.note_id}).then(res => {
	  	 Toast(res.msg);
	      if (res.code==1) {
			  this.is_accept=1
			  this.isUser=true
	        // this.ApplyDetail.tutor_status=3
	      }
	    })
	  },
	  refuseClick() {
	    getChangeOfficialSignupTutor({ act_id: this.$route.query.act_id, action_type: 2, org_user_id:this.$route.query.org_user_id ,note_id:this.$route.query.note_id}).then(res => {
	  	 Toast(res.msg);
	      if (res.code==1) {
			  this.show=false
			  this.isUser=true
			  this.is_accept=2
	        // this.ApplyDetail.tutor_res_status=3
			
	      }
	    })
	  },
  },
  mounted() {
	  	document.title ='助教导师替换';   
		getChangeInfoOfficialSignupTutor({act_id:this.$route.query.act_id,org_user_id:this.$route.query.org_user_id,note_id:this.$route.query.note_id}).then(res=>{
			console.log(res)
			this.ApplyDetail=res.data.actinfo
			this.orginfo=res.data.orginfo
			this.newinfo=res.data.newinfo
			this.is_accept=res.data.is_accept
			this.buttonable=res.data.buttonable
			this.tutorlist=res.data.tutorlist
			let user_id=JSON.parse(localStorage.getItem('user_data')).user_id
			let new_user_id = this.$route.query.new_user_id
			this.tutorlist.forEach((item,i)=>{
				if(new_user_id==item&&user_id==item){
					this.isUser=true
				}else{
					this.is_accept=0
				}
			})
			console.log(this.isUser)
		})
		
  }
}
</script>
<style lang="scss">

	.supervisionNotice{
		width: 100%;
		height: 100vh;
		background-color: #F7F8FF;
		padding: 15px;
		.bold{
			font-weight: bold;
		}
		.van-col{
			text-align: justify;
		}
		.refuse{
			color: #FF524D;
		}
		.van-popup--center.van-popup--round{
			border-radius: 6px;
		}
		.user_name{
			font-size: 18px;
			font-weight: bold;
		}
		.dudao {
			.popupBox {
				width: 300px;
				height: 200px;
				background-color: #fff;
				border-radius: 6px;
				padding: 0 20px 28px;
				text-align: center;
				position: relative;
				.van-cell {
				    padding: 11px 16px;
				}
				.title{
					font-size: 18px;
					color: #28334A;
					text-align: center;
					width: 100%;
					padding: 20px 0;
					font-weight: bold;
				}
		
				.btnBox {
					position: absolute;
					bottom: 20px;
					right: 34px;
					justify-content: flex-end;
					color: #919CB0;
		
					div:first-child {
						margin-right: 40px;
					}
		
					.suss {
						color: #0081FF;
					}
				}
			}
		}
		.bottom{
			display: flex;
			box-shadow:0px -6px 10px 0px rgba(145,156,176,0.1);
			justify-content: space-around;
			align-items: center;
			.btn1{
				position: relative;
				top: 0;
				left: 0;
				transform: translate(0);
				max-width:140px ;
			}
			.btnBor{
				background-color: #fff;
				border: 1px solid #0081FF;
				color: #0081FF;
			}
			.act_btn{
				background-color: #C6C8CA;
				width: 75%;
				max-width:75%;
			}
		}
		.super{
			width: 100%;
			background-color: #fff;
			border-radius: 6px;
			text-align: left;
			padding: 20px 15px;
			.vanRowBox{
				margin-bottom: 16px;
			}
		}
		
	}
	
	
</style>
