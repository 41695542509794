<template>
  <div class="home">
	  <div class="container">
		<!-- <span class="title" v-text="title"> </span> -->
		
		<!-- <div class="img">
			<van-image
			  width="100%"
			  :src="img"
			/>
		</div> -->
		<div class="conten" style="color:#000">
			<p>目前，中国每年有超过54万人出现心脏骤停，相当于大约每1分钟就有人因为心脏骤停而突然倒下。在发达国家，心脏骤停患者的总体抢救成功率为5%～15％，个别先进的地方甚至超过50%。在我国，心脏骤停患者的抢救成功率仅1%，心肺复苏术普及率也只有1%。而这只是我国急救知识普及面临的严峻挑战的一个方面。对于常见急症的识别与急救、创伤救护、灾难的自救互救等人人都应当掌握的知识，人们同样知之甚少。</p>
			<p>中国特色社会主义进入了新时代。党的十九大提出“实施健康中国战略”，国务院印发了《国务院关于实施健康中国行动的意见》，要求实施健康知识普及行动，面向家庭和个人普及紧急救援的知识与技能，引导居民学习掌握心肺复苏等自救、互救知识技能。为此，我们必须大力开展全民急救知识普及工作，通过多角度、全方位的宣传培训，提高急救知识普及率，增加现场急救成功率，减少危急重症患者的死亡和伤残。</p>
			<p>我国54家急救中心的72名急救医学培训专家联合编写了《现场急救行动指南》，这本书对心脏骤停、气道异物梗阻、常见急症、创伤、中毒、动物咬伤等情况下如何进行正确的现场处置，以及灾害时如何自救、互救进行了深入浅出和图文并茂的介绍，为人们了解、学习现场急救知识提供了正确、实用的参考书，也为开展急救知识普及培训工作提供了良好的宣传、培训资料。</p>
			<p>以这本书为参考，现场急救科普工作室拍摄了教学视频，将书中的知识通过动听的讲解、标准的技能示范一一展示出来。</p>
			<p>为做好现场急救科普工作，现场急救科普工作室和广州天鹰救援技术服务有限公司合作，构建了相应现场急救科普工作体系，打造了《现场急救行动指南》微信公众号。</p>
			<p>我们首先将全部的教学视频放到了微信公众号上。《现场急救行动指南》的每本书都有相应的序列号，购书的读者关注公众号、输入序列号后，即可免费观看全部教学视频。如果通过了线上考核，可以申请《现场急救线上教学课程》合格证书。读者还可以在微信公众号上预约培训导师，有偿参加线下教学和考核，以获得《心肺复苏术及自动体外除颤仪使用课程》和《急症急救及创伤救护课程》合格证书。</p>
			<p>同时，微信公众号具有开展培训的导师管理、学员管理、课程管理等全部功能，如果您想成为一名现场急救知识和技能的推广者、传播者和引领者，如果您想成为现场急救科普工作室的合伙人，请联系我们咨询详情。</p>
			联系电话：<a href="tel:02032371988" style="color: #0081FF;" class="call">02032371988</a>
		</div>
		<!-- <div  class="conten" :style="isopen?'':'height: 148px;overflow: hidden;'">
			
			<div class="btnZk" @click="open" v-text="isopen?'收起':'展开'" :style="isopen?'box-shadow:none':''"></div>
		</div> -->
	  </div>
	  <div style="height: 20px;"></div>
	<!--  <div class="container container1">
	  		<span class="title" @click="$router.push('about')"> 培训中心</span>
			<div class="flex listBox">
				<span v-for="(item,i) in pxList" :key="i"  class="list" v-text="item" @click="$router.push({path:'about',query: {selected: '2'}})">
					
				</span>
			</div>
	  </div> -->
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  },
  data(){
	  return{
		  // 标题
		  title:'现场急救概念：什么是现场急救新概念?',
		  // 图片
		  img:'https://img.yzcdn.cn/vant/cat.jpeg',
		  // 内容
		  conten:"",
		  // 展开
		  isopen:false,
		  // 培训中心列表
		  pxList:['海口市120急救培训中心','海口市120急救培训中心','海口市120急救培训中心']
	  }
  },
  methods:{
	  open(){
		  this.isopen=!this.isopen
	  }
  },
  mounted() {
  	document.title = '科普联盟';   
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.home{
		.container1{
			height: 12.5rem;/*  */
		}
		.title{
			font-size: 18px;
			font-weight: bold;
		}
		.conten{
			text-align: left;
			font-size:18px;
			color: #767E8D;
			// height: 148px;
			// overflow: hidden;
			position: relative;
			transition: 2s;
			// margin: 20px 0;
			
		}
		p{
			text-indent:25px;
			margin-bottom: 10px;
			line-height: 28px;
		}
		.img{
			margin: 20px 0;
		}
		.btnZk{
			position: absolute;
			right: 0;
			bottom: 0;
			background: #fff;
			box-shadow: -15px 0 14px 10px #fff;
			color: #0081FF;
			
		}
		.list{
			color: #0081FF;
			font-size: 16px;
			font-weight: bold;
			justify-content: space-between;
			padding: 15px 0 0;
		}
		.listBox{
			justify-content: space-between;
		}
	}
</style>
