<template>
	<div class="news">
		<van-list
		  v-model="loading"
		  :finished="finished"
		  finished-text="没有更多了"
		  @load="onLoad"
		>
			<div style="padding: 16px 0;border-bottom: 1px solid #eee;" v-for="(item,i) in list" :key="i">
				<van-row>
					<van-col span="4">
						<!-- <img :src="item.commentuserinfo.user_head_img" alt="" width="41px" style="border-radius: 50%;"> -->
						<van-image
						  width="40"
						  height="40"
						  :src="item.commentuserinfo.user_head_img"
						  style="border-radius: 50%;"
						/>
					</van-col>
					<van-col span="20">
						<div class="flex" style="justify-content: space-between;">
							<div style="font-weight: bold;"><span v-text="item.commentuserinfo.user_name"></span><span v-if="item.commentuserinfo.user_id===item.articleinfo.user_id">（作者）</span></div>
							<div style="color: #919CB0;font-size: 12px;"><span v-text="setTime(item.articleinfo.createtime)"></span></div>
						</div>
						<div style="color: #767E8D;font-size: 14px;padding: 11px 0 16px;">
							回复了我的评论：
							<span v-text="item.commentinfo.comment_content"></span>
						</div>
					</van-col>
				</van-row>
				<div class="van-ellipsis" style="width: 100%;padding: 12.5px;background-color: #F4F4F4;" @click="$router.push({name:'viewArticles',query:{id:item.articleinfo.id}})">
					<span v-text="item.articleinfo.type==1?item.articleinfo.title:item.articleinfo.share_title"></span>
				</div>
				<div class="flex" style="padding: 12px 0; justify-content: flex-end;" @click="replyClick(i)">
					<div class="btn" :class="item.isshu==1?'btn1':item.isshu==2?'btn2':'btn'">
						<span v-text="item.isshu==1?'回复中':item.isshu==2?'已回复':'回复'"></span>
					</div>
				</div>
				<div class="bottom " style="height:auto;min-height: 46px;box-shadow: rgb(148, 149, 155) 1px 5px 15px 1px;    z-index: 111;" v-if="item.isshu==1">
					<div class="flex bottomBox">
						<!-- <input type="text" placeholder="说点什么吧" @focus="item.isshu=1"> -->
						<van-field
						  v-model="questionsTo"
						  rows="1"
						  :autosize="{maxHeight: 100}"
						  type="textarea"
						  placeholder="说点什么吧"
						  class="input"
						
						 @focus="item.isshu=1" 
						 ref="inputFocus"
						/>
						<div style="color: #0081FF;text-align: right;margin-left: 14px;" @click="senCLick(i)">
							发送
						</div>
					</div>
				</div>
			</div>
		</van-list>
	</div>
</template>

<script>
import { setTime } from '@/util/setTime.js'
import {getWeixinAidHotArticleUserInformationUndeal,postWeixinAidHotArticleUserInformationReply} from '@/api'
import { Toast } from 'vant';
	export default {
  name: 'news',
  components: {
  },
  data(){
	  return{
		  loading:false,
		  finished:false,
		  questionsTo:'',
		  isshu:false,
		  list:[],
		  count:1,
		  page:0,
		  limit:8,
		}
  },
  methods:{
	  onLoad(){
		  getWeixinAidHotArticleUserInformationUndeal().then(res=>{
			  // console.log(res.data)
			  // res.data.list.forEach(item=>{
			  // 	item.isshu=0
			  // })
			  console.log(res.data.list)
			  for (let var1 in res.data.list) {
			  	
				this.list.push(res.data.list[var1])
			  }
				// this.list.forEach((item,i)=>{
				// 	if(item.article_comment_id==this.list[i].article_comment_id){
				// 		item.isshu=0
				// 	}
				// })
		  		this.count=this.list.length
				// 加载状态结束
				this.loading = false;
				 if (this.list.length >= this.count) {
				  this.finished = true;
				}
				console.log(this.list)
		  })
	  },
	  replyClick(i){
		  // getWeixinAidHotArticleUserInformationUndeal().then(res=>{
		  // 		this.list=res.data.list
		  		this.list.forEach(item=>{
					console.log(item.article_comment_id==this.list[i].article_comment_id)
					if(item.article_comment_id==this.list[i].article_comment_id){
						this.list[i].isshu=1
					}
		  		})
				let listarr=this.list
				this.list=[]
				this.list=listarr
		  console.log(this.list[i])
	  },
	 senCLick(i){
		 postWeixinAidHotArticleUserInformationReply({information_id:this.list[i].id,comment_content:this.questionsTo}).then(res=>{
			 if(res.code==1){
				 this.list[i].isshu=2
				 let listarr=this.list
				 this.list=[]
				 this.list=listarr
				 this.questionsTo=''
				 Toast(`回复${this.list[i].commentuserinfo.user_name}成功`);
			 }
		 })
		
	 },
	 setTime(time){
		return setTime(time)
	 }
  },
  mounted() {
  	document.title = '消息';  
	
  }
}
</script>
<style lang="scss" type="text/css">
	.news {
		padding: 0 15px;
		background-color: #fff;
		min-height: 100vh;
		text-align: left;
		.input {
			width: calc(100% - 60px);
			min-height: 33px;
			line-height: 33px;
			background-color: #F4F4F4;
			option: none;
			border-radius: 6px;
			padding:0 10px;
			transform: all 1s;
			z-index: 1111;
			position: relative;
			max-height: 120px;
				
		}
		.btn{
			height: 30px;
			border: 1px solid #0081FF;
			width: 65px;
			border-radius: 50px;
			line-height: 30px;
			text-align: center;
			color: #0081FF;
			
		}
		.btn1{
			height: 30px;
			border: 1px solid transparent;
			background-color: #0081FF;
			width: 85px;
			border-radius: 50px;
			line-height: 30px;
			text-align: center;
			color: #fff;
		}
		.btn2{
			height: 30px;
			border: 1px solid #919CB0;
			width: 65px;
			border-radius: 50px;
			line-height: 30px;
			text-align: center;
			color: #919CB0;
		}
		.bottomBox{
			height: 100%;
			padding: 6.5px 15px;
			input {
				width: 85%;
				height: 33px;
				line-height: 33px;
				background-color: #F4F4F4;
				option: none;
				border-radius: 6px;
				padding-left: 12px;
				transform: all 1s;
			
			}
		}
	}
</style>
