<template>
	<div class="cancel">
		<div class="flex list" style="padding: 15px;background-color: #fff;align-items: center;">
			<div class="list_img">
				<van-image width="106" height="76" :src="list.cover" radius='8px' />
			</div>
			<div style="width: 100%;height: 80%;">
				<div v-text="list.act_name" class="list_title"></div>
				<div class="flex" style="    justify-content: space-between;">
					<!-- 标题下左右边两个div -->
					<div>
						<!-- 左边时间 -->
						<div class="flex" style="    margin: 10px 0;">
							<img src="../../assets/Train/icon_time@2x.png" alt="" style="width: 12px;height: 12px;">
							<div v-text="list.act_start_time" class="text"></div>
						</div>
						<!-- 左边地址 -->
						<div class="flex">
							<img src="../../assets/Train/icon_xdizhi@2x.png" alt="" style="width: 12px;height: 14px;">
							<div v-text="list.city" class="text"></div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<div style="width: 100%; background: #F7F8FF; height: 10px;"></div>
		<div style="text-align: left;padding: 20px 16px;background-color: #fff;">
			<div style="color: #919CB0;font-size: 14px;">取消报名原因</div>
			<div style="margin-top: 12px;">
				<van-field
				  v-model="message"
				  rows="4"
				  :autosize="{ minHeight: 106 }"
				  type="textarea"
				  placeholder="请输入留言"
				  style="background-color: #F4F4F4;"
				/>
			</div>
			
		</div>
		<div class="bottom">
			<div class="bottom_input" @click="cancelClick">
				取消报名
			</div>
		</div>
		
		<!-- <van-calendar v-model="isdata" @confirm="onConfirm" color="#0081FF" /> -->
		<!-- <van-calendar v-model="isdata_Section" type="range" @confirm="onConfirm_Section" color="#0081FF" /> -->
		<!-- <transition name="van-slide-up">
		  <div v-show="isAreaList" style="z-index: 3;position: fixed;bottom: 0;left: 0;width: 100%; ">
			 
		  </div>
		</transition> --> 

	</div>
</template>

<script>
	import {postCancelSignup} from '@/api'
	import { Toast } from 'vant';
export default {
  name: 'cancel',
  components: {
  },
  data(){
	  return{
		  message:'',
		title:'取消报名',
		list:
			{
                "id": 8,
                "act_name": "测试1234",
                "complete_addr": "123",
                "act_start_time": "2019-01-01 10:10:10",
                "act_end_time": "2019-01-01 10:10:10",
                "act_type": 2,
                "city": "",
                "act_status": 2,
                "cover": "https://xcjj.tysos.cn/gzh/backend/public//uploads/act_default.png",
                "has_comment": 0,
                "is_cancel": 0,
            
		},
		
	  }
  },
  methods:{
	cancelClick(){
		postCancelSignup({signup_id:this.$route.query.signup_id,reason:this.message}).then(res=>{
			Toast(res.msg)
			if(res.code==1){
				this.$router.back(-1)
			}
		})
	}
  },
  
  mounted() {
  	document.title = this.title;   
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.cancel {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		padding-bottom: 60px;
		background-color: #F7F8FF;
		.ping{
			width: 4.375rem;
			height: 1.625rem;
			border: 1px solid #0081FF;
			border-radius: 20px;
			color: #fff;
			background-color: #0081FF;
			font-size: 0.75rem;
			line-height: 1.5rem;
			text-align: center;
			box-sizing: border-box;
			float: right;
			margin-left: 16px;
		}
		.Ping_hui{
			color: #919CB0;
			border-color:#919CB0;
			background-color: #fff;
			
		}
		.Ping_blue{
			color: #0081FF;
			border-color:#0081FF;
			background-color: #fff;
		}
		.vanSwipe {
			width: 92%;
			margin: 0 auto 0.9375rem;
			height: 7.5rem;

			img {
				border-radius: 8px;
			}

		}

		.btnBox {
			.btn {
				width: 7.1875rem;
				height: 5.625rem;
				background: no-repeat center;
				background-size: 109% 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: #FFFFFF;
				font-size: 12px;

				div.title {
					margin-bottom: 6px;
					margin-top: -10px;
				}

				div.btn_number {
					font-size: 28px;
					font-weight: bold;
				}
			}

			.btn1 {
				background-image: url(../../assets/Train/bg_yellow@2x.png);
			}

			.btn2 {
				background-image: url(../../assets/Train/bg_red@2x.png);
			}

			.btn3 {
				background-image: url(../../assets/Train/bg_blue@2x.png);
			}
		}

		.list_title {
			justify-content: space-between;
			text-align: left;
			// margin: 20px 0 20px;
			.list_title_left {
				width: 0.1875rem;
				height: 1.125rem;
				background: #0081FF;
				border-radius: 0 0.09375rem 0.09375rem 0;
				margin-right: 0.75rem;
			}

			.list_title_img {
				width: 0.4375rem;
				height: 0.6875rem;
				margin-right: 0.90625rem;
				margin-left: 0.28125rem;
			}

			.left {
				font-size: 1.125rem;
				color: #28334A;
				font-weight: bold;
			}

			.right {
				font-size: 0.9375rem;
				color: #0081FF;
			}
		}

		.list {
			flex-wrap: inherit;
			padding: 15px;

			.list_img {
				margin-right: 0.71875rem;
			}

			.list_title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-weight: bold;
				font-size: 0.9375rem;
				color: #28334A;
			}

			.text {
				font-size: 0.75rem;
				color: #919CB0;
				margin-left: 0.5rem;
			}

			.btnimg {
				width: 3.3125rem;
				height: 1.625rem;
				text-align: center;
				line-height: 1.625rem;
				background: no-repeat center;
				background-size: 100% 100%;
				font-size: 0.6875rem;
				color: #FEFEFE;
			}

			.btnimg1 {
				background-image: url(../../assets/Train/label_blue@2x.png);
			}

			.btnimg2 {
				background-image: url(../../assets/Train/label_red@2x.png);
			}

			.btnimg3 {
				background-image: url(../../assets/Train/label_gray@2x.png);
				color: #919CB0;
			}

			.btnimg_span {
				margin-right: -5px;

			}
		}

		.bottom {
			position: relative;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 3.75rem;
			padding: 12px 0;
			background: #F7F8FF;

			.bottom_input {
				width: 75%;
				height: 2.625rem;
				margin: 0 auto;
				background: #0081FF;
				border-radius: 50px;
				position: relative;
				text-align: left;
				line-height: 2.625rem;
				text-align: center;
				color: #fff;
				.bottom_input_img {
					width: 16px;
					height: 16px;
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		.Slide_up {
			z-index: 111;
			position: fixed;
			bottom: 0;
			height: 90vh;
			background: #fff;
			width: 100%;
			padding: 0.9375rem;
			text-align: left;

			// overflow: auto;
			.Slide_up_header {
				position: relative;
				font-size: 14px;
				text-align: center;

				.Slide_up_img {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					width: 30px;
					height: 30px;
					cursor: pointer;
				}
			}

			.btn {
				border-radius: 0.375rem;
				padding: 9px 13px;
				background: #F4F4F4;
				color: #919CB0;
				border: 1px solid transparent;
				margin-right: 10px;
				margin-top: 1rem;

			}

			.btn.activation {
				border-color: #0081FF;
				background: #FFFFFF;
				color: #0081FF;
			}

			.Slide_up_title {
				margin: 27px 0 0;
			}

			.btnSeach {
				width: 8.75rem;
				height: 42px;
				text-align: center;
				line-height: 42px;
				background: #0081FF;
				color: #fff;
				margin: 20px auto;
				border-radius: 21px;
			}
		}

	}
</style>
