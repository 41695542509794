<template>
	<div class="statistics">
		<div class="numberBox">
			<div class="title">总体统计</div>
			<van-row class="row">
				<van-col span="8" class="col1" key="1">
					<div class="chen"><span v-text="value"></span></div>
					<div class="text">培训场数总计</div>
					<div style="position: absolute;width: 1px;height: 25px;top: 50%;right: 0;transform: translateY(-50%);" class="van-hairline--right"></div>
				</van-col>
				<van-col span="8" class="col2" key="2">
					<div class="chen"><span v-text="value1"></span></div>
					<div class="text">培训学员总计</div>
					<div style="position: absolute;width: 1px;height: 25px;top: 50%;right: 0;transform: translateY(-50%);" class="van-hairline--right"></div>
				</van-col>
				<van-col span="8" style="border-right: 0;" class="col3" key="3">
					<div class="chen"><span v-text="value2"></span></div>
					<div class="text">发证量总计</div>
				</van-col>
			</van-row>

			<van-row class="row" style="margin-bottom: 10px;">
				<van-col span="8">
					<div class="blue"><span v-text="value3"></span></div>
					<div class="text">今年培训场数总计</div>
					<div style="position: absolute;width: 1px;height: 25px;top: 50%;right: 0;transform: translateY(-50%);" class="van-hairline--right"></div>
				</van-col>
				<van-col span="8">
					<div class="blue"><span v-text="value4"></span></div>
					<div class="text">今年培训学员总计</div>
					<div style="position: absolute;width: 1px;height: 25px;top: 50%;right: 0;transform: translateY(-50%);" class="van-hairline--right"></div>
				</van-col>
				<van-col span="8" style="border-right: 0;">
					<div class="blue"><span v-text="value5"></span></div>
					<div class="text">今年发证量总计</div>
				</van-col>
			</van-row>

		</div>
		<div style="height: 10px; width: 100%;background-color: #F7F8FF;"></div>
		<div style="padding: 0 15px;">
			<div id="myChart" :style="{width: '100%', height: '300px'}" style="margin: 20px 0;"></div>
			<div id="myChart1" :style="{width: '100%', height: '300px'}"></div>
		</div>
	</div>
</template>

<script>
	import { getActivityDataCount_tutor } from '@/api'
	export default {
  name: 'statistics',
  components: {
  },
  data(){
	  return{
		  value:'4',
		  value1:'1',
		  value2:'1',
		  value3:'4',
		  value4:'1',
		  value5:'1',
		title:'培训统计',
		/* 每年培训场次数组_年份 */
		data_count_years:[],
		/* 每年培训场次数组_年份 */
		data_count_conten:[],
		/* 每年培训学员数/每年发证数数组_年份 */
		data_stu_count_years:[],
		/* 每年培训学员数/每年发证数数组_年份 */
		data_stu_count_conten:[],
		
		
		
	  }
  },
  methods:{
	drawLine(){
		console.log(this)
	        // 基于准备好的dom，初始化echarts实例
	        let myChart = this.$echarts.init(document.getElementById('myChart'))
	        // 绘制图表
	        myChart.setOption({
				 color: ['#0081FF'],
	            title: {
					text: '每年开展培训场数对比统计图',
					x:'center',
					y:'top',
					textAlign:'left',
					textStyle:{
						fontSize:15
					}
				},
				
				
	            tooltip: {},
				textStyle:{
				                fontSize:12,
				                color:'#919CB0'
				            },
	            xAxis: {
	                data: this.data_count_years,
					axisLine: {
							lineStyle: {
								color: '#F4F4F4',
								width: 1, //这里是为了突出显示加上的  
							}
						},
					
	            },
	            yAxis: {
					 shadowColor: 'rgba(0, 0, 0, 0.5)',
					name:'（单位：场次）',
					 axisLine: {
							lineStyle: {
								color: '#F4F4F4',
								width: 1, //这里是为了突出显示加上的  
							}
						},
					 splitLine:{   
							show:true, 
							lineStyle:{
								color:'#F4F4F4',
								width:1,
								type:'solid',          //---类型
							},
						},
					             
				},
	            series: [{
	                name: '数量',
	                type: 'bar',
	                data: this.data_count_conten,
					 barWidth : 30,//柱图宽度
					 
					
	            }]
	        });
	    },
		drawLine1(){
			console.log(this)
		        // 基于准备好的dom，初始化echarts实例
		        let myChart = this.$echarts.init(document.getElementById('myChart1'))
		        // 绘制图表
		        myChart.setOption({
					 // color: ['#0081FF','#00D97E'],
		            title: {
						text: '每年培训学员总数和发证总数统计图',
						x:'center',
						y:'top',
						textAlign:'left',
						textStyle:{
							fontSize:15
						}
					},
					 grid:{
		                top:'30%',//距上边距
		
		              },
					legend: {
						itemGap:60,
						top:30,
						bottom:30,
					    itemWidth: 12,
					    itemHeight: 12,
						borderRadius:[0,0,0,0],
					    textStyle: {
					        color:'#333'
					    },
					    data: ['培训学员总数','发证总数']
					},
		            tooltip: {},
					textStyle:{
					                fontSize:12,
					                color:'#919CB0'
					            },
		            xAxis: {
		                data: this.data_count_years,
						axisLine: {
								lineStyle: {
									color: '#F4F4F4',
									width: 1, //这里是为了突出显示加上的  
								}
							},
						
		            },
		            yAxis: {
						splitLine:{
									show:true, 
									lineStyle:{
										color:'#F4F4F4',
										width:1,
										type:'solid',          //---类型
									},
								},
						 axisLine: {
								lineStyle: {
									color: '#F4F4F4',
									width: 1, //这里是为了突出显示加上的  
								}
							},
					},
		            series: [{
		                name: '培训学员总数',
		                type: 'bar',
		                data: this.data_count_conten,
						 barWidth : '30',//柱图宽度
						  // barCategoryGap:'0',
						  color:'#0081FF',
						   barGap:'0%',
						  label: {
							  show: true,
							  position: 'top'
						  }
						
		            },{
		                name: '发证总数',
		                type: 'bar',
		                data: this.data_count_conten,
						 barWidth : '30',//柱图宽度
						 color:'#00D97E',
						   barGap:'0%',
						  label: {
							  show: true,
							  position: 'top'
						  },
		            }]
		        });
		    }
  },
  
  mounted() {
  	document.title = this.title;   
	getActivityDataCount_tutor().then(res=>{
		this.value=res.msg.act_count
		this.value1=res.msg.student_count
		this.value2=res.msg.cert_count
		this.value3=res.msg.act_count_year
		this.value4=res.msg.student_count_year
		this.value5=res.msg.cert_count_year
		let data_count=res.msg.data_count
		let data_stu_count=res.msg.data_stu_count
		console.log(res.msg)
		data_count.forEach(obj => {
			
		    this.data_count_years.push(obj.year+'年');
		    this.data_count_conten.push(obj.act_count_year);
			this.drawLine()
			this.drawLine1()
		})
	})
	
	// this.gethotcity()
	// this.getActivityDataCount()
	
  }
}
</script>
<style lang="scss" type="text/css" scoped>
	.statistics {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		padding-bottom: 60px;
		color: #28334A;

		.chen {
			color: #FED200;
			font-size: 1.125rem;
		}

		.blue {
			color: #0081FF;
			font-size: 1.125rem;
		}

		.numberBox {
			font-size: 0.75rem;

			.row {
				padding: 13.5px 0;
				margin: 0 15px;
				border-top: 1px solid #F4F4F4;
			}

			.van-col {
				position: relative;
				&::after{
					// z-index: 100;
					// display: block;
					// content: '';
					// height: 25px;
					// width: .5px;
					// position: absolute;
					// top: 50%;
					// right: 0;
					// transform: translateY(-50%);
					// background-color: #F4F4F4;
				}
				// border-right: 1px solid #F4F4F4;
			}

			// .van-col::last-child {
			// 	border-right: 0;
			// }

			.title {
				text-align: left;
				font-size: 1.125rem;
				position: relative;
				font-weight: 500;
				padding: 20px 12px;

				&::before {
					display: block;
					content: '';
					width: 3px;
					height: 16px;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					background-color: #0081FF;
					border-top-right-radius: 2px;
					border-bottom-right-radius: 2px;
				}
			}
		}
	}
</style>
