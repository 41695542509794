<template>
	<div class="setHead">
		<div class="headImg">
			<img :src="imgsrc" alt="" style="object-fit: contain;width: 138px;" class="auto-img" v-if="!visible">
			<!-- <div class="mask"></div> -->
			<!-- <image-clipper ref="clipper" v-if="visible" :img="imgsrc1" :clipper-img-width="138" :clipper-img-height="186" @ok="sure"
			 @loadError="loadError" @loadComplete="loadComplete"></image-clipper>
 -->
		</div>
		<div class="bottom" v-if="!visible">
			<div class="btn" @click.stop="uploadHeadImg"  v-if="!over">
				<div class="caption" v-if="!over">更改头像</div>
			</div>
			<div class="btn" @click="sure" v-if="over">
				<div class="caption" >完成上传</div>
			</div>
		</div>
		<h5-cropper class="hiddenInput" :option="option" @getbase64Data="getbase64Data" @getblobData="getblobData" @getFile="getFile"></h5-cropper>
	</div>
</template>

<script>
	// beforeLoad	no	图片加载前触发
	// loadSuccess	no	图片加载成功触发
	// loadError	no	图片加载失败触发
	// loadComplete	no	图片加载完成触发
	// import imageClipper from '@/components/imageClipper.vue';
	import {
		postUserCertsInfo,
		postTransformationImg
	} from '@/api/index.js'
	import { Toast } from 'vant';
	export default {
		name: 'setHead',
		data() {
			return {
				visible: false,
				option:{
					fixedBox:true,
					centerBox:false,
					fixedNumber:[1, 1.4],
					autoCropHeight:186,
					autoCropWidth:138
				},
				over:false,
				imgsrc: '',
				imgsrc1: '',
			}
		},
		components: {
			// imageClipper
		},
		methods: {
			checkImgWidth (fileUrl) {
			    return new Promise((resolve, reject) => {
			        let img = new Image();
			        let res = {}
			
			        img.onload = function () {
			            res = {
			                width: this.width,
			                height: this.height
			            }
			            resolve(res)
			        }
			        img.src = fileUrl
			    })
			},

			getbase64Data(data) {
				let _this=this
				
				let imgurl =''
			      this.over=true
				  var img = new Image;
				  var width = 138, //图像大小
					  height = 184,
					  quality = 0.8, //图像质量
					  canvas = document.createElement("canvas"),
					  drawer = canvas.getContext("2d");
				  img.src = data;
				  img.onload = function () {
					  canvas.width = width;
					  canvas.height = height;
					  drawer.drawImage(img, 0, 0, canvas.width, canvas.height);
					  _this.imgsrc = canvas.toDataURL("image/png", quality);
					  _this.checkImgWidth(imgurl).then(res=>{
					  })
				  }
				  
			    },
			getblobData(){
				
			},
			getFile(){},
			loadComplete(e) {
				console.log(e)
			},
			loadError(e) {
				console.log(e)
			},
			upLoad() {
				// 触发上传图片按钮
				this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
			},
			sure() {
				let _this=this
				let data = this.imgsrc
				
				postTransformationImg({imgurl:data}).then(res=>{
					Toast(res.msg)
					if(res.code==1){
						_this.imgsrc = res.data.headimgurl;
						_this.$router.go(-1)
					}
				})
			},
			// 打开图片上传
			uploadHeadImg: function() {
				this.$el.querySelector('.upbtn>.upbtn').click()
				// this.visible = true
				// this.imgsrc1 = 'http://img1.imgtn.bdimg.com/it/u=293643936,2020316857&fm=26&gp=0.jpg'
				// console.log(this.imgsrc1, '父组件')
			},
			// 将头像显示
			handleFile: function(e) {
				let $target = e.target || e.srcElement
				let file = $target.files[0]
				var reader = new FileReader()
				reader.onload = (data) => {
					let res = data.target || data.srcElement
					this.imgsrc1 = res.result
					this.visible = true
				}
				reader.readAsDataURL(file)
			},
		},
		mounted() {
			this.imgsrc = this.$route.query.type1;
			this.uploadHeadImg()
			document.title = '证书头像'
		}
	}
</script>
<style lang="scss" scoped>
	.setHead {
		width: 100%;
		background-color: #000;
		min-height: 100vh;
		position: relative;
		padding-bottom: 82px;

		.headImg {
			padding-bottom: 82px;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		.mask {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, .5);
		}
		
	}
</style>
